import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import overviewcss from './overviewcss';
import theme from '../../../utils/theme';
import { PieChart } from 'react-minimal-pie-chart';
// import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';

const { Paper, withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid container {...props} />;

const CardsDetailsContainer = ({ classes, graphData, location, region }) => {
  const [, dispatch] = useStateValue();
  const [employeeData, setEmployeesData] = useState([]);

  const getNominationemployee = () => {
    const resultRegion = region === 'all' ? '' : `region=${region}`;
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;
    API_GET(`travelpackage/getNominationCounts?${resultRegion}&${resultLocation}`)
      .then((res) => {
        setEmployeesData(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getNominationemployee();
  }, [location, region]);

  const handleDownload = (name) => {
    if (name === 'Aadhaar Card') {
      API_GET(`travelpackage/downloadPanReport?docType=aadhaar`)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res?.message || 'Success'
          });
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else if (name === 'Pan Card') {
      API_GET(`travelpackage/downloadPanReport?docType=pan`)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res?.message || 'Success'
          });
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      API_GET(`travelpackage/downloadNominatedReport`)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res?.message || 'Success'
          });
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  const resulData = [...graphData];
  resulData.push(employeeData);

  return (
    <Container className={classes.RiskCardContainer} spacing={2} mt={0.5}>
      {resulData &&
        resulData?.map((item, index) => {
          return (
            <Item xs key={index}>
              <Paper className={classes.ContainerCard1} sx={theme.card}>
                <Item className={classes.headingContainer}>
                  <Item>
                    <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
                      {item.name}
                    </Typography>
                  </Item>
                  <Item>
                    <FileDownloadIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDownload(item?.name)}
                    />
                  </Item>
                </Item>
                <Item
                  className={classes.centerAlign}
                  sx={{
                    justifyContent: 'space-evenly',
                    height: '40px',
                    p: 1,
                    gap: '70px'
                  }}>
                  <Item className={classes.centerAlign} sx={{ gap: '5px' }}>
                    <Item
                      className={classes.cardsPieText}
                      sx={{
                        backgroundColor: item.data && item.data[1].color
                      }}></Item>
                    <Item sx={{ fontSize: '14px' }}>
                      {item?.name === 'Nominations' ? 'Yet To Travel' : 'Total uploaded'}
                    </Item>
                  </Item>
                  <Item className={classes.centerAlign} sx={{ gap: '5px' }}>
                    <Item
                      className={classes.cardsPieText}
                      sx={{
                        backgroundColor: item.data1 && item.data1[1].color
                      }}></Item>
                    <Item sx={{ fontSize: '14px' }}>
                      {item?.name === 'Nominations' ? 'Travelled' : 'Pending'}
                    </Item>
                  </Item>
                </Item>
                <Item
                  className={classes.centerAlign}
                  sx={{
                    height: '150px',
                    width: '100%'
                  }}>
                  <Item sx={{ height: '100px', width: '50%' }}>
                    <PieChart
                      data={item.data}
                      lineWidth={40}
                      label={({ dataEntry }) =>
                        dataEntry.label === 'Total Uploded' ? `${dataEntry.value}%` : ''
                      }
                      labelStyle={{
                        fontSize: '20px',
                        fontFamily: theme.palette.font.fontFamily,
                        fill: item.data && item.data[1].color,
                        textAlign: 'center'
                      }}
                      labelPosition={0}
                    />
                  </Item>
                  <Item sx={{ height: '100px', width: '50%' }}>
                    <PieChart
                      data={item.data1}
                      lineWidth={40}
                      label={({ dataEntry }) =>
                        dataEntry.label === 'Pending' ? `${dataEntry.value}%` : ''
                      }
                      labelStyle={{
                        fontSize: '20px',
                        fontFamily: theme.palette.font.fontFamily,
                        fill: item.data1 && item.data1[1].color,
                        textAlign: 'center'
                      }}
                      labelPosition={0}
                    />
                  </Item>
                </Item>
              </Paper>
            </Item>
          );
        })}
    </Container>
  );
};

export default withStyles(overviewcss)(CardsDetailsContainer);
