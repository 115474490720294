const styles = (theme) => {
  return {
    title: {
      backgroundColor: theme.palette.primary.tableHeadingColor,
      fontFamily: theme.palette.font.fontFamily,
      color: 'white',
      textAlign: 'center',
      borderRadius: '10px 10px 0 0',
      padding: '4px 10px',
      display: 'flex',
      justifyContent: 'space-between'
    },
    subContainer: {
      // overflow: 'auto',
      border: '1px solid lightGrey'
    },
    image_container: {
      height: '92%',
      overflow: 'auto',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    image: {
      border: '1px solid lightGrey',
      borderRadius: '5px',
      height: '100px',
      width: '100px'
    },
    icon: { width: '75%', margin: 'auto', marginTop: '5px' }
  };
};

export default styles;
