const head = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'uniqueId',
    label: 'Emp Id',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'name',
    label: 'Emp Name',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  // {
  //   id: 'more',
  //   label: '',
  //   minWidth: 80,
  //   width: 80,
  //   maxWidth: 80,
  //   align: 'center'
  // },
  {
    id: 'mobileNumber',
    label: 'Phone No',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  // {
  //   id: 'alertActionStatus',
  //   label: 'Status',
  //   minWidth: 120,
  //   width: 120,
  //   maxWidth: 120,
  //   align: 'center'
  // },

  {
    id: 'location',
    label: 'Location',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'officeName',
    label: 'Office',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'department',
    label: 'Department',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'doctor',
    label: 'Doctor',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'appointmentDate',
    label: 'Appointment Date',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'slotTimeString',
    label: 'Appointment Time',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'declineReason',
    label: 'Remark',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'actionTime',
    label: 'Declined On',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  }
  // {
  //   id: 'action',
  //   label: 'Action',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 100,
  //   align: 'center'
  // }
];

export default head;
