import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';

const {
  withStyles,
  Grid,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Paper,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox
} = Components;

const Item = (props) => <Grid item {...props} />;

const relationArray = ['Father', 'Mother', 'Sibling', 'Spouse', 'Others'];

function PrefiledSelfDecleration({
  classes,
  handleClose,
  declerationData,
  value,
  emergencyContacts
}) {
  return (
    <form>
      <Item className={classes.selfDecleartionContain} md={12} p={2}>
        <Typography sx={{ fontSize: '0.9rem', fontWeight: '500' }}>
          {declerationData?.description}
        </Typography>
        <Typography sx={{ fontSize: '1rem', fontWeight: '800' }} mt={1.5}>
          I declare that (Please tick the box)
        </Typography>
        <ol>
          {value &&
            value?.map((item, index) => {
              return (
                <li key={index}>
                  <Typography sx={{ fontSize: '0.9rem', fontWeight: '500' }}>
                    {item?.questionText}
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={item?.answer}>
                      {item?.options?.map((itemOption) => {
                        return (
                          <FormControlLabel
                            value={itemOption?.optionText}
                            control={<Radio />}
                            label={itemOption?.optionText}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </li>
              );
            })}
        </ol>
        <Item mt={3}>
          <Item sx={{ display: 'flex' }}>
            <Typography style={{ textAlign: 'left', fontWeight: '600', fontSize: '18px' }}>
              People can be contacted in case of emergency
            </Typography>
          </Item>
          <Item sx={{ overflowX: 'hidden' }}>
            {emergencyContacts?.map((item, pkindex) => {
              return (
                <Paper
                  elevation={2}
                  key={pkindex}
                  sx={{
                    p: '10px',
                    mt: 2,
                    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
                    borderRadius: '10px',
                    position: 'relative',
                    border: '1px solid lightgrey'
                  }}>
                  <Item className={classes.addPackageContainer} mt={2} md={12} xs={12} lg={12}>
                    <Item md={3.8} xs={12} lg={3.8} mt={2}>
                      <TextField
                        variant="outlined"
                        name="name"
                        size="small"
                        label="Name"
                        required={true}
                        value={item?.name}
                        className={classes.formControl}
                        type="text"
                        fullWidth
                      />
                    </Item>
                    <Item md={3.8} xs={12} lg={3.8} mt={2}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Relation</InputLabel>
                        <Select
                          name="relation"
                          label="Relation"
                          required={true}
                          value={item?.relation}>
                          {relationArray?.map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Item>
                    <Item md={3.8} xs={12} lg={3.8} mt={2}>
                      <TextField
                        variant="outlined"
                        name="mobileNumber"
                        size="small"
                        label="Phone"
                        required={true}
                        value={item?.mobileNumber}
                        className={classes.formControl}
                        type="number"
                        fullWidth
                      />
                    </Item>
                    <Item md={3.8} xs={12} lg={3.8} mt={2}>
                      <TextField
                        variant="outlined"
                        name="city"
                        size="small"
                        label="City"
                        required={true}
                        value={item?.city}
                        className={classes.formControl}
                        type="text"
                        fullWidth
                      />
                    </Item>
                    <Item md={3.8} xs={12} lg={3.8} mt={2}>
                      <TextField
                        variant="outlined"
                        name="state"
                        size="small"
                        label="State"
                        required={true}
                        value={item?.state}
                        className={classes.formControl}
                        type="text"
                        fullWidth
                      />
                    </Item>
                    <Item md={3.8} xs={12} lg={3.8} mt={2}>
                      <TextField
                        variant="outlined"
                        name="pincode"
                        size="small"
                        label="PinCode"
                        required={true}
                        value={item?.pinCode}
                        className={classes.formControl}
                        type="number"
                        fullWidth
                      />
                    </Item>
                  </Item>
                </Paper>
              );
            })}
          </Item>
        </Item>
        <Item>
          <Item sx={{ mt: 1 }}>
            <FormControlLabel
              control={<Checkbox checked={true} required={true} name="disclimberValue" />}
              label={declerationData?.disclaimer}
            />
          </Item>
        </Item>
      </Item>

      <Item sx={{ display: 'flex' }}>
        <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '10px', p: 1 }}>
          <Item>
            <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
              Cancel
            </Button>
          </Item>
        </Item>
      </Item>
    </form>
  );
}

export default withStyles(styles)(PrefiledSelfDecleration);
