const styles = (theme) => {
  return {
    login_label: {
      justifyContent: 'center',
      letterSpacing: '5px',
      color: '#555555'
    },
    inputBox: {
      borderRadius: '15px',
      '& .MuiInputBase-input': {
        fontSize: '1rem', // set the font size to 1.5rem
        padding: '0.8rem' // add extra padding
      },
      '& .MuiInputBase-root': {
        '& fieldset': {
          // borderColor: 'lightGrey',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '7px'
        },
        '&:hover fieldset': {
          borderColor: '#f3f3f3',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '7px'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#f3f3f3',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '7px'
        },
        '&.Mui-focused:after': {
          borderRightColor: '#FF0000',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '7px'
        }
      }
    },
    squareButton: {
      borderRadius: '0',
      backgroundColor: '#FF6760'
    },
    indoIcon: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: '#FF6760',
      height: 50,
      width: 70,
      marginRight: '-13px',
      borderRadius: '8px'
    }
  };
};

export default styles;
