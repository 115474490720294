import React, { useState, useEffect, useRef } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from './styles';
import theme from '../../../utils/theme';
import { useStateValue } from '../../../utils/store';
import { nameValidate, emailValidate, mobileNumberValidate } from '../../../utils/formValidation';
import { API_GET, API_HANDLE_ERROR, API_PATCH, PRE_SEND_URL } from '../../../utils/api';
import moment from 'moment';
import axios from 'axios';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import { GetAllCity, GetAllState, GetAllCountry } from '../../../utils/getCountryStateCity';
// import profileImg from '../../../Assets/settingsIcon/1DB954.svg';

const { withStyles, Grid, Paper, Typography, TextField, Button, Select, MenuItem, Autocomplete } =
  Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Profile = ({ classes, data, getUserData }) => {
  const [, dispatch] = useStateValue();
  const [status, setStatus] = useState(false);
  const [allAdmins, setAllAdmins] = useState([]);
  const [selectedLocationAllOffices, setSelectedLocationAllOffices] = useState([]);
  const [offices, setOffices] = useState([]);
  const [allDepartment, setAllDepartment] = useState([]);
  const hiddenDocumentInput = useRef(null);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [sendData, setSendData] = useState({
    name: '',
    dateOfBirth: '',
    emailId: '',
    address: '',
    phone: '',
    alternatePhone: '',
    id: '',
    employeeId: '',
    designation: '',
    department: '',
    reportingTo: '',
    alternateEmail: '',
    location: '',
    officeId: '',
    profileImage: [],
    city: '',
    country: { name: '' },
    state: { name: '' },
    stateCode: '',
    countryCode: '',
    pinCode: ''
  });

  useEffect(() => {
    if (status) {
      setSendData({
        ...sendData,
        name: data?.fullName,
        dateOfBirth: moment(data?.dateOfBirth).format('YYYY-MM-DD'),
        emailId: data?.email,
        address: data?.currentAddress?.address,
        phone: Number(data?.mobileNumber),
        alternatePhone: Number(data?.alternateNumber),
        id: data?._id,
        employeeId: data?.employeeId,
        designation: data?.designation,
        department: data?.departmentId,
        reportingTo: data?.reportingTo,
        alternateEmail: data?.alternateEmail,
        location: data?.location,
        officeId: data?.officeId,
        profileImage: [data.profileImage],
        city: data?.currentAddress?.city,
        country: { name: data?.currentAddress?.country },
        state: { name: data?.currentAddress?.state },
        stateCode: data?.stateCode,
        countryCode: data?.countryIsoCode,
        pinCode: data?.currentAddress?.pinCode
      });
    }
  }, [status]);

  useEffect(() => {
    const resultState = GetAllState(sendData?.countryCode);
    setStateData(resultState);

    const resultCity = GetAllCity(sendData?.countryCode, sendData?.stateCode);
    setCityData(resultCity);
  }, [sendData?.country, sendData?.state]);

  const handleStateChange = (key, value) => {
    if (key === 'name') {
      if (nameValidate(value) === false) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Special characters are not allowed'
        });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'phone') {
      if (value < 0) {
        setSendData({ ...sendData, phone: '' });
      } else if (value.length <= 10) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'alternatePhone') {
      if (value < 0) {
        setSendData({ ...sendData, alternatePhone: '' });
      } else if (value.length <= 10) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'pinCode') {
      if (value < 0) {
        setSendData({ ...sendData, pinCode: '' });
      } else if (value.length <= 6) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'dateOfBirth') {
      const currentDate = new Date();
      const selectedDate = new Date(value);
      if (selectedDate > currentDate) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Invalid Date of birth'
        });
        setSendData({ ...sendData, dateOfBirth: moment(new Date()).format('YYYY-MM-DD') });
      } else {
        setSendData({
          ...sendData,
          dateOfBirth: moment(selectedDate).format('YYYY-MM-DD')
        });
      }
    } else {
      setSendData((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  const getAllReportingManegers = () => {
    API_GET(`user/getEmployees?onlyManagers=true`)
      .then((res) => {
        setAllAdmins(res?.data?.employees);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const getAllLocation = () => {
    API_GET(`broadCastMessage/getAllLocations`)
      .then((res) => {
        setOffices(res?.officesByCrporateId);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    if (sendData?.location) {
      API_GET(`broadCastMessage/getAllLocations?cities[]=${[sendData?.location]}`)
        .then((res) => {
          setSelectedLocationAllOffices(
            res?.officesByCrporateId && res?.officesByCrporateId[0]?.offices
          );
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  }, [sendData.location]);

  useEffect(() => {
    getAllReportingManegers();
    getAllLocation();
  }, []);

  useEffect(() => {
    if (sendData?.officeId) {
      API_GET(`department/getAllDepartment?officeId=${sendData?.officeId}`)
        .then((res) => {
          setAllDepartment(res?.found);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  }, [sendData.officeId]);

  const handleEdit = () => {
    setStatus(true);
  };
  const handleClose = () => {
    setStatus(false);
  };

  const handleInputClick = (item) => {
    hiddenDocumentInput.current.click();
  };

  const precenturl = async (type, name) => {
    try {
      const data = await PRE_SEND_URL('user/incident/preSignedMediaUrl', {
        fileType: type,
        fileName: name.split('.')[0],
        featureType: 'doctorProfileImage'
      });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleimage = async (status, img) => {
    const arrayImage = [];

    for (let i = 0; i < img.length; i++) {
      const docsType = img[i]?.type?.split('/');
      const name = img[i]?.name;
      const url = await precenturl(docsType[1], name);
      const file = img[i];

      const config = {
        method: 'put',
        url: url.signedUrl,
        headers: {
          'x-ms-blob-content-type': img[i].type,
          'content-type': 'application/octet-stream',
          'x-ms-blob-type': 'BlockBlob'
        },
        data: file
      };

      axios(config)
        .then(function (response) {
          arrayImage.push(url.mediaUrl);
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: response?.data?.message || 'upload Successfully'
          });
        })
        .catch(function (error) {
          API_HANDLE_ERROR(error, dispatch);
        });
    }
    handleStateChange('profileImage', arrayImage);
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    const postData = {
      fullName: sendData?.name,
      mobileNumber: Number(sendData?.phone),
      alternateNumber: Number(sendData?.alternatePhone),
      dateOfBirth: sendData?.dateOfBirth,
      email: sendData?.emailId,
      currentAddress: {
        country: sendData?.country?.name || '',
        city: sendData?.city,
        state: sendData.state?.name || '',
        pinCode: sendData?.pinCode,
        address: sendData?.address
      },
      role: data?.role,
      corporateId: data?.corporateId,
      alternatePhone: sendData.alternatePhone,
      employeeId: sendData?.employeeId,
      designation: sendData?.designation,
      departmentId: sendData?.department,
      reportingTo: sendData?.reportingTo,
      alternateEmail: sendData?.alternateEmail,
      officeId: sendData?.officeId,
      location: sendData?.location,
      stateCode: sendData?.stateCode,
      countryIsoCode: sendData?.countryCode,
      profileImage: sendData?.profileImage && sendData?.profileImage[0]
    };
    if (emailValidate(sendData?.emailId) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct email'
      });
    } else if (mobileNumberValidate(sendData?.phone) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct mobile number'
      });
    } else {
      API_PATCH(`user/updateUser?userId=${sendData.id}`, postData)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          getUserData();
          handleClose();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  const renderObj = {
    'Employee Id': data?.employeeId,
    Name: data?.fullName,
    Email: data?.email,
    'Phone number': data?.mobileNumber,
    'Date Of Birth': moment(data?.dateOfBirth).format('DD-MM-YYYY'),
    'Job title': data?.designation,
    Office: data?.location,
    Branch: data?.branchName,
    Department: data?.deptInfo?.department,
    'Reporting to': ConvertToUpperCase(data?.reportingToInfo?.fullName),
    'Alternate Email': data?.alternateEmail,
    'Alternate Contact No.': data?.alternateNumber === '0' ? '-' : data?.alternateNumber,

    Address: [
      data?.currentAddress?.address,
      data?.currentAddress?.city,
      data?.currentAddress?.state,
      data?.currentAddress?.country
    ]
      ?.filter((item) => item)
      ?.join(', '),
    Pincode: data?.currentAddress?.pinCode,
    '': ''
  };

  const countryData = GetAllCountry();

  const str = theme.palette.primary.main;
  const result = str.slice(1);

  return (
    <Container sx={{ padding: theme.spacing(2, 0) }}>
      <Item md={12} sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
        <img
          src={require(`../../../Assets/settingsIcon/${result}.svg`).default}
          alt="icon"
          style={{ width: '100%' }}
        />

        <Item md={12} xs={12} sx={{ position: 'absolute', top: '100px' }}>
          <Paper
            sx={{
              ...theme.card,
              minHeight: '70vh',
              width: '75vw',
              display: 'flex',
              justifyContent: 'center',
              mb: 2,
              pb: 2
            }}>
            <Item
              sx={{
                position: 'absolute',
                right: 0,
                mt: 2,
                mr: 2
              }}>
              {!status && (
                <Button sx={{ ...theme.button.cancel, width: '70px' }} onClick={handleEdit}>
                  Edit
                </Button>
              )}
            </Item>

            <Item
              sx={{
                position: 'absolute',
                m: 'auto',
                marginTop: '-70px'
              }}>
              {status && (
                <Typography sx={{ ...theme.typography.tableHeading, mb: 1, p: 0, ml: -1 }}>
                  Your Profile Picture
                </Typography>
              )}

              {!status ? (
                <img
                  src={data?.profileImage || sendData?.profileImage?.[0]}
                  alt="profile"
                  className={classes.imgProfile}
                />
              ) : (
                <Item>
                  {sendData?.profileImage?.[0] !== '' ? (
                    <Item onClick={() => handleInputClick()} sx={{ cursor: 'pointer' }}>
                      <img
                        src={sendData?.profileImage?.[0] || data?.profileImage}
                        alt="profile"
                        className={classes.imgProfile}
                      />
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: '12px',
                          textAlign: 'center',
                          mt: 1,
                          ml: -1
                        }}>
                        Upload your photo
                      </Typography>
                    </Item>
                  ) : (
                    <Item
                      className={classes.imageContain}
                      sx={{ flexDirection: 'column' }}
                      onClick={() => handleInputClick()}>
                      <React.Fragment>
                        <img
                          style={{ height: '35px', width: '35px' }}
                          src={require(`../../../Assets/settingsIcon/profileUpload.svg`).default}
                          alt="icon"
                        />

                        <Typography
                          sx={{
                            color: theme.typography.tableHeading.color,
                            fontSize: '15px',
                            textAlign: 'center',
                            mt: 1
                          }}>
                          Upload your photo
                        </Typography>
                      </React.Fragment>
                    </Item>
                  )}
                  <input
                    ref={hiddenDocumentInput}
                    accept=".jpeg, .jpg, .png"
                    type="file"
                    multiple
                    size="small"
                    onChange={(e) => handleimage('profile', e.target.files)}
                    style={{ display: 'none' }}
                  />
                </Item>
              )}
            </Item>
            {!status ? (
              <Item className={classes.inputContainer} md={12} xs={12} mt={4}>
                {Object.keys(renderObj)?.map((item) => {
                  return (
                    <Item md={3.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>{item}</Typography>
                      <Typography className={classes.inputText1}>{renderObj[item]}</Typography>
                    </Item>
                  );
                })}
              </Item>
            ) : (
              <Item md={12} xs={12}>
                <form onSubmit={onHandleConfirm}>
                  <Item className={classes.inputContainer} md={12} xs={12} mt={status ? 7 : 4}>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        Employee Id<span className={classes.starIcon}>*</span>
                      </Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          name="name"
                          size="small"
                          disabled={sendData.employeeId === '' ? false : status}
                          placeholder="Please enter your employee Id"
                          value={sendData.employeeId}
                          type="text"
                          fullWidth
                        />
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        Name<span className={classes.starIcon}>*</span>
                      </Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          name="name"
                          disabled={status}
                          placeholder="Please enter your Name"
                          onChange={(e) => handleStateChange('name', e.target.value)}
                          size="small"
                          value={sendData.name}
                          type="text"
                          fullWidth
                        />
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        Email Id<span className={classes.starIcon}>*</span>
                      </Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          name="emailId"
                          disabled={status}
                          placeholder="Please enter your email"
                          required={true}
                          size="small"
                          onBlur={(e) => {
                            if (
                              e.target.value !== '' &&
                              emailValidate(sendData?.emailId) === false
                            ) {
                              dispatch({
                                type: 'ALERT_OPEN',
                                severity: 'error',
                                isOpen: true,
                                label: 'Email is not valid'
                              });
                            }
                          }}
                          value={sendData.emailId}
                          // onChange={(e) => handleStateChange('emailId', e.target.value)}
                          type="email"
                          fullWidth
                        />
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        Phone Number<span className={classes.starIcon}>*</span>
                      </Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          name="phone"
                          size="small"
                          placeholder="Please enter your phone number"
                          value={sendData?.phone}
                          required={true}
                          onBlur={(e) => {
                            if (
                              e.target.value !== '' &&
                              mobileNumberValidate(sendData?.phone) === false
                            ) {
                              dispatch({
                                type: 'ALERT_OPEN',
                                severity: 'error',
                                isOpen: true,
                                label: 'Please enter correct phone number'
                              });
                            }
                          }}
                          onChange={(e) => handleStateChange('phone', e.target.value)}
                          type="number"
                          fullWidth
                        />
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}> Date Of Birth</Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          name="dateOfBirth"
                          disabled={!status}
                          size="small"
                          value={sendData.dateOfBirth || moment(new Date()).format('YYYY-MM-DD')}
                          required={true}
                          onChange={(e) => handleStateChange('dateOfBirth', e.target.value)}
                          type="date"
                          fullWidth
                        />
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>Job title</Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          name="name"
                          size="small"
                          placeholder="Please enter your Job title"
                          onChange={(e) => handleStateChange('designation', e.target.value)}
                          value={sendData.designation}
                          type="text"
                          fullWidth
                        />
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        Office<span className={classes.starIcon}>*</span>
                      </Typography>
                      <Item mt={0.8}>
                        <Select
                          className={classes.number}
                          size="small"
                          placeholder="Please Select your Office"
                          name="location"
                          fullWidth
                          value={sendData?.location}
                          onChange={(e) => {
                            handleStateChange('location', e.target.value);
                          }}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined">
                          {offices?.map((item, index) => {
                            return (
                              <MenuItem value={item?._id} key={index}>
                                {`${ConvertToUpperCase(item?._id)}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        Branch<span className={classes.starIcon}>*</span>
                      </Typography>
                      <Item mt={0.8}>
                        <Select
                          className={classes.number}
                          size="small"
                          placeholder="Please Select your Branch"
                          name="officeId"
                          value={sendData?.officeId}
                          fullWidth
                          onChange={(e) => {
                            handleStateChange('officeId', e.target.value);
                          }}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined">
                          {selectedLocationAllOffices?.map((item, index) => {
                            return (
                              <MenuItem value={item?._id} key={index}>
                                {`${ConvertToUpperCase(item?.name)}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        Department<span className={classes.starIcon}>*</span>
                      </Typography>

                      <Item mt={0.8}>
                        <Select
                          className={classes.number}
                          size="small"
                          placeholder="Please Select your department"
                          fullWidth
                          value={sendData?.department}
                          onChange={(e) => {
                            handleStateChange('department', e.target.value);
                          }}>
                          {allDepartment?.map((item, index) => {
                            return (
                              <MenuItem value={item?._id} key={index}>
                                {`${ConvertToUpperCase(item?.department)} `}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>Reporting to</Typography>
                      <Item mt={0.8}>
                        <Select
                          className={classes.number}
                          size="small"
                          placeholder="Please select your reporting manager"
                          fullWidth
                          value={sendData?.reportingTo}
                          onChange={(e) => {
                            handleStateChange('reportingTo', e.target.value);
                          }}>
                          {allAdmins?.map((item, index) => {
                            return (
                              <MenuItem value={item?._id} key={index}>
                                {`${ConvertToUpperCase(item?.fullName)}, ${ConvertToUpperCase(
                                  item?.designation
                                )}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>Home Address</Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          name="address"
                          size="small"
                          sx={{ wordBreak: 'break-all' }}
                          placeholder="Please enter your Address"
                          value={sendData?.address}
                          required={true}
                          onChange={(e) => {
                            if (e.target.value.length <= 250) {
                              handleStateChange('address', e.target.value);
                            }
                          }}
                          type="text"
                          fullWidth
                        />
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        Country<span className={classes.starIcon}>*</span>
                      </Typography>
                      <Item mt={0.8}>
                        <Autocomplete
                          id="combo-box-demo"
                          size="small"
                          required={true}
                          onChange={(e, value) => {
                            if (value === null) {
                              handleStateChange('country', '');
                            }
                            setSendData({
                              ...sendData,
                              countryCode: value?.countryCode
                            });

                            handleStateChange('country', value);
                          }}
                          options={countryData}
                          value={sendData?.country}
                          color="#134CDF"
                          getOptionLabel={(option) => {
                            return option?.name;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Country"
                              required={true}
                              variant="outlined"
                              type="text"
                            />
                          )}
                        />
                      </Item>
                    </Item>

                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        State<span className={classes.starIcon}>*</span>
                      </Typography>

                      <Item mt={0.8}>
                        <Autocomplete
                          id="combo-box-demo"
                          size="small"
                          onChange={(e, value) => {
                            if (value === null) {
                              handleStateChange('state', '');
                            }
                            setSendData({ ...sendData, stateCode: value?.stateCode });
                            handleStateChange('state', value);
                          }}
                          options={stateData}
                          value={sendData?.state}
                          color="#134CDF"
                          getOptionLabel={(option) => {
                            return option?.name;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Country"
                              variant="outlined"
                              type="text"
                              required={true}
                            />
                          )}
                        />
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>
                        City<span className={classes.starIcon}>*</span>
                      </Typography>

                      <Item mt={0.8}>
                        <Autocomplete
                          id="combo-box-demo"
                          size="small"
                          onChange={(e, value) => {
                            if (value === null) {
                              handleStateChange('city', '');
                            }
                            handleStateChange('city', value);
                          }}
                          options={cityData}
                          value={sendData?.city}
                          color="#134CDF"
                          getOptionLabel={(option) => {
                            return option;
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" type="text" required={true} />
                          )}
                        />
                      </Item>
                    </Item>

                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>Pincode</Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          size="small"
                          name="pincode"
                          placeholder="Please enter Pincode"
                          value={sendData.pinCode}
                          onChange={(e) => handleStateChange('pinCode', e.target.value)}
                          type="number"
                          fullWidth
                        />
                      </Item>
                    </Item>

                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>Alternate Contact No.</Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder="Please enter your Contact no."
                          name="alternatePhone"
                          onBlur={(e) => {
                            if (
                              e.target.value !== '' &&
                              mobileNumberValidate(sendData?.alternatePhone) === false
                            ) {
                              dispatch({
                                type: 'ALERT_OPEN',
                                severity: 'error',
                                isOpen: true,
                                label: 'Please enter correct phone number'
                              });
                            }
                          }}
                          value={sendData?.alternatePhone === 0 ? '' : sendData?.alternatePhone}
                          onChange={(e) => handleStateChange('alternatePhone', e.target.value)}
                          type="number"
                          disabled={!status}
                          fullWidth
                        />
                      </Item>
                    </Item>
                    <Item md={5.8} xs={12} mt={3}>
                      <Typography className={classes.inputText}>Alternate Email</Typography>

                      <Item mt={0.8}>
                        <TextField
                          variant="outlined"
                          name="emailId"
                          size="small"
                          placeholder="Please enter your alternate email"
                          onBlur={(e) => {
                            if (
                              e.target.value !== '' &&
                              emailValidate(sendData?.alternateEmail) === false
                            ) {
                              dispatch({
                                type: 'ALERT_OPEN',
                                severity: 'error',
                                isOpen: true,
                                label: 'Email is not valid'
                              });
                            }
                          }}
                          value={sendData.alternateEmail}
                          onChange={(e) => handleStateChange('alternateEmail', e.target.value)}
                          type="email"
                          fullWidth
                        />
                      </Item>
                    </Item>
                  </Item>
                  <Container>
                    {status && (
                      <Item
                        sx={{
                          display: 'inline-flex',
                          ml: 'auto',
                          gap: '20px',
                          p: theme.spacing(2, 5)
                        }}>
                        <Item>
                          <Button
                            onClick={handleClose}
                            sx={{
                              ...theme.button.cancel
                            }}>
                            Cancel
                          </Button>
                        </Item>
                        <Item>
                          <Button
                            type="submit"
                            style={{
                              ...theme.button.submit,
                              backgroundColor: theme.palette.primary.main
                            }}>
                            {`Update`}
                          </Button>
                        </Item>
                      </Item>
                    )}
                  </Container>
                </form>
              </Item>
            )}
          </Paper>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Profile);
