const head = [
  {
    id: 'srNo',
    label: 'Sr.no',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center'
  },
  {
    id: 'department',
    label: 'Department',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'title',
    label: 'Internal Incident',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'officeCity',
    label: 'Location',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'requestedBudget',
    label: 'Requested Budget',
    minWidth: 160,
    width: 160,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'approvedBudget',
    label: 'Approved Budget',
    minWidth: 160,
    width: 160,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
