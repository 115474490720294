import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import { useStateValue } from '../../utils/store';
import { API_POST, API_HANDLE_ERROR } from '../../utils/api';
import { cookieSave, decodeToken } from '../../utils/cookie';
import styles from './styles';
import theme from '../../utils/theme';
import PasswordReset from '../PasswordReset';
import { useNavigate } from 'react-router-dom';

const { withStyles, Grid, TextField, Button, Typography, InputAdornment, IconButton } = Components;
const { VisibilityOffIcon, VisibilityIcon, MailOutlineIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Login = ({ classes }) => {
  const [store, dispatch] = useStateValue();
  const [loading, setIsLoading] = useState(false);
  const [isOpenPasswordResetScreen, setIsOpenPasswordReset] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const forgetWord = window.location.pathname?.split('/');
  const navigate = useNavigate();

  useEffect(() => {
    if (forgetWord?.[2] === 'forgetPassword') {
      setIsOpenPasswordReset(true);
    }
  }, [forgetWord]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const redirect = {
    admin: store?.corporateId === theme.corporate.mliCorporateId ? '/travel-management' : '/home',
    superAdmin: '/dashboard',
    sosAdmin: '/sosDashboard',
    agent: '/Agent-Panel',
    user: '/Employee-Panel'
  };

  useEffect(() => {
    if (store.isLogin === true) {
      window.location.href = redirect[`${store.role}`];
    }
  }, [store.isLogin]);

  const [userData, setUserData] = useState({
    email: '',
    password: ''
  });

  const handleStateChange = (key, value) => {
    setUserData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const handleIsOpenPasswordReset = () => {
    setIsOpenPasswordReset(true);
  };
  const handleCloseAll = () => {
    setIsOpenPasswordReset(false);
  };
  const handleBack = () => {
    setIsOpenPasswordReset(false);
    navigate('/login');
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    API_POST('auth/login', userData)
      .then((res) => {
        const { role, _id, corporateId } = decodeToken(res.data.token);
        cookieSave(res.data.token);
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message || 'Success'
        });
        dispatch({
          type: 'IS_LOGGED_IN',
          isLogin: true,
          role: role,
          personId: _id
        });
        dispatch({
          type: 'CORPORATE_ID',
          corporateId: corporateId
        });
        setIsLoading(false);
        localStorage.setItem('isLogin', true);

        setTimeout(() => {
          window.location.href = redirect[`${role}`];
        }, 1500);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
        setIsLoading(false);
      });
  };

  return (
    <Container direction={'column'} className={classes.login_page}>
      <Item
        md={12}
        xs={12}
        className={classes.formContainer}
        sx={{ flexDirection: 'column' }}
        mt="-25px">
        <Item sx={{ height: '60px' }}>
          <img
            style={{ height: '60px' }}
            src="https://drorlabstorage.blob.core.windows.net/dror/lythouse-logo.png"
            alt="Login"
          />
        </Item>
        <Item mt={1}>
          <Typography sx={{ fontWeight: '600', color: '#555555', fontSize: '1.6rem' }}>
            Safety & Happiness
          </Typography>
        </Item>
        <Item mt={1}>
          {isOpenPasswordResetScreen ? (
            <Item>
              <PasswordReset handleBack={handleBack} handleCloseAll={handleCloseAll} />
            </Item>
          ) : (
            <Item>
              <Typography
                className={classes.login_label}
                sx={{ fontSize: '1.2rem', color: '#FF6760', textAlign: 'center' }}>
                Login into your account
              </Typography>

              <form onSubmit={onHandleConfirm}>
                <Container direction={'column'} spacing={4}>
                  <Item mt={2}>
                    <Typography mb={0.5} sx={{ color: '#555555' }}>
                      Email Id
                    </Typography>
                    <TextField
                      variant="outlined"
                      name="email"
                      required={true}
                      size="small"
                      placeholder="Enter your Email"
                      className={classes.inputBox}
                      onChange={(e) => handleStateChange('email', e.target.value)}
                      type="email"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <div className={classes.indoIcon}>
                            <InputAdornment position="end">
                              <IconButton>
                                <MailOutlineIcon style={{ color: '#FFFFFF' }} />
                              </IconButton>
                            </InputAdornment>
                          </div>
                        )
                      }}
                    />
                  </Item>
                  <Item>
                    <Typography mb={0.5} sx={{ color: '#555555' }}>
                      Password
                    </Typography>

                    <TextField
                      variant="outlined"
                      name="password"
                      required={true}
                      size="small"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      className={classes.inputBox}
                      onChange={(e) => handleStateChange('password', e.target.value)}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <div className={classes.indoIcon}>
                            <InputAdornment position="end">
                              <IconButton onClick={handleTogglePassword}>
                                {showPassword ? (
                                  <VisibilityIcon style={{ color: '#FFFFFF' }} />
                                ) : (
                                  <VisibilityOffIcon style={{ color: '#FFFFFF' }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          </div>
                        )
                      }}
                    />

                    <Item mt={1} sx={{ float: 'right' }}>
                      <Typography
                        onClick={handleIsOpenPasswordReset}
                        // to="/passwordReset"
                        style={{
                          color: '#FF6760',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          fontSize: '0.8rem'
                        }}>
                        Forgot Password/Generate Password
                      </Typography>
                    </Item>
                  </Item>

                  <Item mt={2}>
                    <Button
                      style={{
                        ...theme.button.submit,
                        fontWeight: '600',
                        padding: '20px 5px',
                        backgroundColor: '#FF6760'
                      }}
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={loading}
                      className={classes.button}>
                      {`Login now`}
                    </Button>
                  </Item>
                </Container>
              </form>
            </Item>
          )}
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Login);
