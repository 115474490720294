import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import SosDetailsTable from '../../../components/organism/Table/SosDetailsTable';
import SosReviewTable from './SosReviewTable';
import SosDistribuion from './sosDistribuion';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';

const { withStyles, Grid, Typography, Paper, MenuItem, FormControl, InputLabel, Select } =
  Components;

const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const SosReports = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [location, setlocation] = React.useState('all');
  const [offices, setOffices] = React.useState(['all']);

  const [selectedToDate1, setSelectedToDate1] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedFromDate1, setSelectedFromDate1] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );

  const handleDate1 = (date) => {
    if (date) {
      setSelectedFromDate1(moment(date[0]).format('YYYY-MM-DD'));
      setSelectedToDate1(moment(date[1]).format('YYYY-MM-DD'));
    }
  };

  const getAllOffices = () => {
    API_GET('report-incident/getSosCitiesForCorporate')
      .then((res) => {
        setOffices([...offices, ...res?.data?.cities]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getAllOffices();
  }, []);

  const handleExport = () => {
    API_GET(`reports/downloadSosReport?fromDate=${selectedFromDate1}&toDate=${selectedToDate1}`)
      .then((res) => {
        if (res?.data?.downloadData.length === 0) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'No data found for download'
          });
        } else {
          downloadDataToExcelFile(
            res?.data?.downloadData,
            `SOS details_(${selectedFromDate1} To ${selectedToDate1})`
          );
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <Container direction="column" className={classes.newUser}>
      <Typography
        className={classes.cardHeading}
        sx={{ backgroundColor: `${theme.palette.primary.main}` }}
        variant="h6">
        SOS Report
      </Typography>
      <Item className={classes.tableContainer1}>
        {/* <Typography
          classsName={classes.containHeading}
          sx={{ ...theme.typography.tableHeading, color: theme.palette.primary.main, pl: 0 }}>
          Heading
  </Typography> */}
        <p style={{ fontSize: '1rem', marginTop: '8px', fontWeight: '500' }}>
          Employee safety should be the utmost priority of the organization. Tracking how many
          employees were unsafe and what kind of emergency service they require will help in better
          incident management in the future.
        </p>

        <Item md={12} xs={12} sx={{ ...theme.card, height: 'auto', p: 1.5, mt: 1.5 }}>
          <Item xs={12} md={12} sx={{ display: 'flex', gap: '10px', p: 1 }}>
            <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '10px', flexWrap: 'wrap' }}>
              <DateRangePicker
                onChange={(date) => handleDate1(date)}
                value={[new Date(selectedFromDate1), new Date(selectedToDate1)]}
              />
            </Item>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Location </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={location}
                  sx={{ width: '200px' }}
                  label=" Location "
                  onChange={(e) => setlocation(e.target.value)}>
                  {offices?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {ConvertToUpperCase(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
            <Item>
              <FileDownloadIcon
                style={{ cursor: 'pointer', color: 'grey' }}
                onClick={handleExport}
              />
            </Item>
          </Item>
          <Paper elevation={2} sx={theme.card}>
            <SosDetailsTable
              selectedFromDate={selectedFromDate1}
              selectedToDate={selectedToDate1}
              location={location}
            />
          </Paper>
        </Item>

        <Item xs={12} md={12} mt={1}>
          <Item
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px'
            }}>
            <Item md={6} xs={12} sx={{ mt: 1.5 }}>
              <Paper
                sx={{
                  height: '300px',
                  ...theme.card
                }}>
                <Item sx={{ p: 0.5 }}>
                  <SosDistribuion
                    selectedFromDate={selectedFromDate1}
                    selectedToDate={selectedToDate1}
                    location={location}
                  />
                </Item>
              </Paper>
            </Item>

            <Item md={6} xs={12} sx={{ mt: 1.5 }}>
              <Paper
                sx={{
                  height: '300px',
                  ...theme.card,
                  width: '100%'
                }}>
                <SosReviewTable
                  selectedFromDate={selectedFromDate1}
                  selectedToDate={selectedToDate1}
                  location={location}
                />
              </Paper>
            </Item>
          </Item>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(SosReports);
