import { Button, Typography } from '@mui/material';
import React from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';

const { withStyles, Grid } = Components;
const { CancelRoundedIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function DetailViewModal({ classes, handleClose, data, isIncident }) {
  console.log('data', data);
  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: '800px', height: '40vh' }}>
      <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
        <Button onClick={() => handleClose()}>
          <CancelRoundedIcon sx={{ cursor: 'pointer' }} color="black" />
        </Button>
      </Item>
      <Item
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'space-between',
          width: '800px',
          height: '30vh',
          mt: -3
        }}>
        <Item sx={{ width: '40%', mt: '6vh', height: '30vh' }}>
          <img
            src={
              data?.imageLinks?.length !== 0
                ? data?.imageLinks[0]
                : 'https://i.ibb.co/tZQWWY8/download.jpg'
            }
            alt="news"
            width="100%"
            height="100%"
          />
        </Item>
        <Item sx={{ width: '58%', height: '38vh', mt: '1vh' }}>
          <Button
            style={{
              color: '#FFFFFF',
              backgroundColor: data?.alertColor || 'lightBlue',
              width: '150px',
              height: '30px'
            }}>
            {!data?.alertColor
              ? `Severity - ${data?.emergencySeverityLevel || data?.severity}`
              : data?.emergencySeverity || 'All'}
          </Button>
          <br></br>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: theme.palette.font.fontFamily,
              mt: 0.5
            }}>
            {data?.incidentTitle || data?.safetyIssue || data?.event || data?.title}
          </Typography>
          <Item sx={{ width: '100%', height: '11vh', overflow: 'hidden' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontFamily: theme.palette.font.fontFamily
              }}>
              {data?.incidentDescription}
            </Typography>
          </Item>
          <Item sx={{ fontSize: '15px', fontWeight: '600', mt: 1 }}>
            Location-
            {isIncident
              ? ConvertToUpperCase(data?.incidentLocation)
              : ConvertToUpperCase(data?.officeAddress)}
          </Item>
        </Item>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(DetailViewModal);
