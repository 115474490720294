import React, { useState, useEffect } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';

import SeverityIncidentGraph from './SeverityIncidentGraph';
import InternalIncidentGraph from './InternalIncidentGraph';
import InternalOfficeVulnerabilityGraph from './InternalOfficeVulnerabilityGraph';
import InternalDepartmentProgressBar from './InternalDepartmentProgressbar';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import CustomTooltip from './CustomToolTip/customToolTip';

const { withStyles, Grid, MenuItem, Select, InputLabel, FormControl } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

// const categoryArray = ['All', 'health', 'economy', 'pandamic', 'political', 'weather'];

const InterRiskContainer = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [location, setlocation] = React.useState('all');
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const [offices, setOffices] = React.useState([{ city: 'All', _id: 'all' }]);

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return {
            city: item.city,
            _id: item._id
          };
        });

        setOffices([...offices, ...resultData]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllOffices();
  }, []);

  const handleDate = (date) => {
    if (date) {
      setSelectedFromDate(moment(date[0]).format('YYYY-MM-DD'));
      setSelectedToDate(moment(date[1]).format('YYYY-MM-DD'));
    }
  };
  return (
    <Container>
      <Item sx={{ display: 'flex', p: '5px 0px', gap: '10px' }}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <Item>
            <DateRangePicker
              onChange={(date) => handleDate(date)}
              value={[new Date(selectedFromDate), new Date(selectedToDate)]}
            />
          </Item>
        </Item>
        <Item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> Location </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={location}
              sx={{ width: '250px' }}
              label=" Location "
              onChange={(e) => setlocation(e.target.value)}>
              {offices?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {ConvertToUpperCase(item?.city)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
      </Item>
      <Item md={12} sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
        <Item md={5} sx={theme.card} className={classes.graphContainer}>
          <Item sx={{ p: 0.5 }}>
            <SeverityIncidentGraph
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              location={location}
            />
          </Item>
        </Item>

        <Item md={7} sx={theme.card} className={classes.graphContainer}>
          <Item sx={{ p: 0.5 }}>
            <InternalOfficeVulnerabilityGraph
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              location={location}
            />
          </Item>
        </Item>
      </Item>
      <Item md={12} sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between', mt: 2 }}>
        <Item md={5} sx={theme.card} className={classes.graphContainer}>
          <Item sx={{ p: '2px 10px' }}>
            <InternalDepartmentProgressBar
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              location={location}
            />
          </Item>
        </Item>

        <Item md={7} sx={theme.card} className={classes.graphContainer}>
          <Item sx={{ p: 0.5 }}>
            <CustomTooltip>
              <InternalIncidentGraph
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                location={location}
              />
            </CustomTooltip>
          </Item>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(InterRiskContainer);
