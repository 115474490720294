import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import AddAgent from './AddAgentDummy';
import Dependent from './Dependent';
import Packges from './Package';
import Confimation from './Confimation';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import SelfDeclerationFrom from './SelfDeclerationFrom';

const { withStyles, Grid, Typography, FormControl, Select, MenuItem } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const { CancelRoundedIcon } = Icons;
const AddAgentModel = ({ classes, handleClose, data, status, EditAgentStatus }) => {
  const [, dispatch] = useStateValue();
  const [formType, setFromType] = useState('Agent Registration Form');
  const [step, setstep] = useState(1);
  const [userData, setUserData] = useState({});
  const [userId, setuserId] = useState(data?._id);
  const [dependentStatus, setDependentStatus] = useState('Add Dependent');
  const [isEdit, setIsEdit] = useState('add');
  const [allPackageIds, setAllPackageIds] = useState([]);
  const [isOpenDeclerationFrom, setIsOpenDeclerationFrom] = useState(false);
  const [declerationData, setDeclerationData] = useState([]);
  const [ParentId, setParentId] = useState(data?._id);

  const handleStep = (step, data, Id) => {
    setstep(step);
    setUserData(data);
    setuserId(Id);
  };

  const handleParentId = (id) => {
    setParentId(id);
  };

  const handleDependent = () => {
    setstep(1);
    setFromType('Family Registration Form');
    setIsEdit('add');
  };

  // check 1st time
  const handleDependentClick = () => {
    if (userId) {
      setFromType('Family Registration Form');
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Add Agent'
      });
    }
  };

  const EditDependent = () => {
    setFromType('Family Registration Form');
    setDependentStatus('Add Dependent');
    setIsEdit('edit');
  };

  const addEditDepedentStatus = () => {
    setIsEdit('edit');
  };

  const renderStep = () => {
    if (formType === 'Agent Registration Form') {
      return (
        <AddAgent
          handleClose={handleClose}
          status={status}
          data={data}
          formType={formType}
          handleStep={handleStep}
          handleParentId={handleParentId}
          EditAgentStatus={EditAgentStatus}
          // handleSampleDocsModal={handleSampleDocsModal}
        />
      );
    } else if (formType === 'Family Registration Form') {
      return (
        <Dependent
          handleClose={handleClose}
          status={status}
          data={data}
          dataId={ParentId}
          formType={formType}
          dependentStatus={dependentStatus}
          handleDependentClick={handleDependentClick}
          EditDependent={EditDependent}
          handleStep={handleStep}
          isEdit={isEdit}
          addEditDepedentStatus={addEditDepedentStatus}
          // handleSampleDocsModal={handleSampleDocsModal}
        />
      );
    } else {
      return (
        <Packges
          handleClose={handleClose}
          status={status}
          data={data}
          formType={formType}
          userId={userId}
          allPackageIds={allPackageIds}
          handleDecleartion={handleDecleartion}
        />
      );
    }
  };

  // check packages

  const getAllPackageId = () => {
    userId &&
      API_GET(`travelPackage/getAllNominatedEmployees?userId=${userId}`)
        .then((res) => {
          setAllPackageIds(res?.data);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  useEffect(() => {
    getAllPackageId();
  }, [userId, step]);

  const handlePackage = () => {
    if (allPackageIds.length === 0) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'This Employee/Agent is not nominated with any Group ID'
      });
    } else {
      setFromType('Group');
    }
  };

  const handleDecleartionFrom = () => {
    setIsOpenDeclerationFrom(false);
  };

  const handleDecleartion = (item) => {
    setIsOpenDeclerationFrom(true);
    setDeclerationData(item?.formData);
  };

  return (
    <React.Fragment>
      {isOpenDeclerationFrom ? (
        <SelfDeclerationFrom
          handleClose={handleDecleartionFrom}
          declerationData={declerationData}
          userId={userId}
          getAllPackageId={getAllPackageId}
        />
      ) : (
        <Container direction="column" wrap="nowrap" className={classes.mainContainer}>
          <Item className={classes.title}>
            <Item>
              <img
                src="https://drorlabstorage.blob.core.windows.net/dror/lythouse-logo.png"
                alt="logo"
                width="124px"
                height="35px"
                style={{ filter: 'brightness(0) invert(1)' }}
              />
            </Item>
            {step !== 4 ? (
              <Item
                sx={{
                  display: 'flex',
                  gap: '30px',
                  justifyContent: 'space-evenly',
                  alignItems: 'center'
                }}>
                <Typography
                  sx={{ fontSize: '1.2rem', fontWeight: '700' }}
                  className={classes.head}
                  onClick={() => setFromType('Agent Registration Form')}>
                  Agent
                </Typography>
                <Item sx={{ width: '200px' }} onClick={() => handleDependentClick()}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      size="small"
                      sx={{
                        color: '#FFFFFF',
                        fontWeight: '600',
                        fontSize: '1.2rem',
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 }
                      }}
                      value={dependentStatus}
                      onChange={(e) => setDependentStatus(e.target.value)}>
                      <MenuItem
                        value="Add Dependent"
                        onClick={() => {
                          setIsEdit('add');
                        }}>
                        Add Dependent
                      </MenuItem>
                      <MenuItem value="View Dependent">View Dependent</MenuItem>
                    </Select>
                  </FormControl>
                </Item>

                <Typography
                  sx={{ fontSize: '1.2rem', fontWeight: '700' }}
                  className={classes.head}
                  onClick={() => handlePackage()}>
                  Group
                </Typography>
                <CancelRoundedIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Item>
            ) : (
              <CancelRoundedIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClose();
                }}
              />
            )}
          </Item>

          <Item className={classes.formContainer1} md={12} m={1}>
            {step === 4 ? (
              <Item sx={{ width: '50vw' }}>
                <Confimation
                  userData={userData}
                  userId={userId}
                  status={status}
                  handleDependent={handleDependent}
                />
              </Item>
            ) : (
              <Item>{renderStep(handleDecleartion)}</Item>
            )}
          </Item>
        </Container>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(AddAgentModel);
