import React, { useState, useEffect, useRef } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { saveAs } from 'file-saver';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_HANDLE_ERROR, PRE_SEND_URL, API_PATCH } from '../../../../utils/api';
import axios from 'axios';
import downloadMultipleFiles from '../../../../utils/downloadMultipleFiles';
import { FileIcon, defaultStyles } from 'react-file-icon';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
// import SinglePage from './singlePage';

const { withStyles, Grid, Button, Typography } = Components;
const { CancelRoundedIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function DocumentsModel({ classes, handleClose, documentId }) {
  const [isOpenFullView, setIsOpenFullView] = useState(false);
  const [getImage, setGetImage] = useState('');
  const [documentData, setDocumentData] = useState([]);
  const [, dispatch] = useStateValue();
  const hiddenDocumentInput = useRef(null);
  const [documentType, setDocumentType] = useState('');
  const [flag, setFlag] = useState(false);

  const getAllDocuments = () => {
    documentId &&
      API_GET(`budgetApproval/getDocuments?incidentId=${documentId}`)
        .then((res) => {
          Object.keys(res?.data).map((item) => {
            if (res?.data[item].length > 0) {
              setFlag(true);
            }
            return null;
          });
          // const result = Object.values(res?.data);

          // const result = Object.values(res.data).map((item) => {
          //   return item?.map((data)=>{
          //     return data
          //   }
          // })

          setDocumentData(res?.data);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  useEffect(() => {
    getAllDocuments();
  }, [dispatch, documentId]);

  const handleFullImage = (img) => {
    setIsOpenFullView(true);
    setGetImage(img);
  };

  const handleCloseFullImage = () => {
    setIsOpenFullView(false);
  };

  const downloadImage = () => {
    const extension = getImage && getImage?.split('.');
    const exlength = extension.length;
    const resultExtension = extension[exlength - 1];
    saveAs(getImage, `image.${resultExtension}`);
  };

  const downloadAllImages = () => {
    const dataLink = [];
    Object.keys(documentData).map((category) => {
      const resultData = documentData[category];

      resultData.map((link) => {
        dataLink.push(link.link);
        return null;
      });
      return null;
    });
    downloadMultipleFiles(dataLink);
  };

  const handleDownload = (imgUrl) => {
    const extension = imgUrl && imgUrl?.split('.');
    const exlength = extension.length;
    const resultExtension = extension[exlength - 1];
    saveAs(imgUrl, `image.${resultExtension}`);
    // downloadMultipleFiles([imgUrl]);
  };

  const handleVisa = (item) => {
    hiddenDocumentInput.current.click();
    setDocumentType(item);
  };

  const precenturl = async (type, name) => {
    try {
      const data = await PRE_SEND_URL('user/incident/preSignedMediaUrl', {
        fileType: type,
        fileName: name.split('.')[0],
        featureType: 'budgetDocs'
      });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleimage = async (img) => {
    const arrayImage = [];

    for (let i = 0; i < img.length; i++) {
      const docsType = img[i]?.type?.split('/');
      const name = img[i]?.name;
      const url = await precenturl(docsType[1], name);
      const file = img[i];

      const config = {
        method: 'put',
        url: url.signedUrl,
        headers: {
          'x-ms-blob-content-type': img[i].type,
          'content-type': 'application/octet-stream',
          'x-ms-blob-type': 'BlockBlob'
        },
        data: file
      };

      axios(config)
        .then(function (response) {
          arrayImage.push(url.mediaUrl);
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: response?.data?.message || 'upload Successfully'
          });
          setTimeout(() => {
            addMoreImage(arrayImage);
          }, 1000);
        })
        .catch(function (error) {
          API_HANDLE_ERROR(error, dispatch);
        });
    }
  };

  function addMoreImage(arrayImage) {
    const postData = {};
    postData[documentType] = arrayImage;

    API_PATCH(`budgetApproval/additionalDocumentUpload?incidentId=${documentId}`, {
      documents: postData
    })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        getAllDocuments();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }

  const imageDocs = (link) => {
    const extension = link && link?.split('.');
    const exlength = extension.length;
    const resultExtension = extension[exlength - 1];

    return (
      <>
        {resultExtension === 'jpeg' || resultExtension === 'jpg' || resultExtension === 'png' ? (
          <img src={link} width="100%" height="100%" alt="docs" />
        ) : (
          <div className={classes.icon}>
            <FileIcon extension={resultExtension || 'jpeg'} {...defaultStyles.docx} />
          </div>
        )}
      </>
    );
  };

  const extension = getImage && getImage?.split('.');
  const exlength = extension.length;
  const resultExtension = extension[exlength - 1];

  return (
    <Container
      direction="column"
      sx={{ width: 'auto', height: '90vh', maxWidth: '90vw', minWidth: '80vw' }}
      wrap="nowrap"
      className={classes.popupContainer}>
      {isOpenFullView ? (
        <Item className={classes.title}>
          <Item>
            <ArrowBackIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={handleCloseFullImage}
            />
          </Item>
          <Item>
            <DownloadForOfflineIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={downloadImage}
            />
          </Item>
        </Item>
      ) : (
        <Item className={classes.title}>
          <Item>
            <Typography p={1}>Documents</Typography>
          </Item>
          <Item>
            <CancelRoundedIcon
              fontSize="medium"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClose()}
            />
          </Item>
        </Item>
      )}

      {isOpenFullView ? (
        <Item md={12} sx={{ ...theme.card, m: 1, display: 'flex', overflow: 'auto' }}>
          <Item sx={{ height: '70vh', width: '100%', minWidth: '80vw' }}>
            {resultExtension === 'jpeg' ||
            resultExtension === 'jpg' ||
            resultExtension === 'png' ? (
              <img src={getImage} width="100%" alt="docs" />
            ) : (
              <FileIcon extension={resultExtension} {...defaultStyles.docx} />
            )}
          </Item>
        </Item>
      ) : (
        <Item
          md={12}
          sx={{ ...theme.card, m: 1, display: 'flex', overflow: 'auto', justifyContent: 'center' }}>
          {Object.keys(documentData)?.map((item, index) => {
            return (
              <Item
                // md={3}
                sx={{ minWidth: '180px' }}
                className={classes.subContainer}
                key={index}
                xs={12}
                ms={6}>
                <Item className={classes.title}>
                  <Item>
                    <Typography>{ConvertToUpperCase(item)}</Typography>
                  </Item>
                  <Item>
                    <AddCircleOutlinedIcon
                      onClick={() => handleVisa(item)}
                      fontSize="medium"
                      sx={{ cursor: 'pointer' }}
                    />
                    <input
                      type="file"
                      ref={hiddenDocumentInput}
                      multiple
                      accept="image/*,.pdf"
                      onChange={(e) =>
                        // handleStateChange('img', e.target.files[0].name)
                        handleimage(e.target.files)
                      }
                      style={{ display: 'none' }}
                    />
                  </Item>
                </Item>
                <Item md={12} className={classes.image_container}>
                  {documentData[item]?.map((linkData) => {
                    return (
                      <Item>
                        <Item
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            // position: 'sticky',
                            // top: 0,
                            mt: 1
                          }}>
                          <Item
                            sx={{
                              fontSize: '13px',
                              fontWeight: '600'
                            }}>{`Uploded by ${linkData?.user}`}</Item>
                          <Item>
                            <DownloadForOfflineIcon
                              fontSize="small"
                              onClick={() => handleDownload(linkData?.link)}
                              sx={{
                                cursor: 'pointer',
                                color: theme.palette.primary.tableHeadingColor
                              }}
                            />
                          </Item>
                        </Item>
                        <Item
                          className={classes.image}
                          md={8}
                          mt={1}
                          sx={{ cursor: 'pointer', m: 'auto' }}
                          onClick={() => handleFullImage(linkData?.link)}>
                          {imageDocs(linkData?.link)}
                        </Item>
                      </Item>
                    );
                  })}
                </Item>
              </Item>
            );
          })}
        </Item>
      )}
      {!isOpenFullView ? (
        <Item sx={{ display: 'flex', p: 0.5 }}>
          <Item sx={{ display: 'inline-flex', m: 'auto' }}>
            {flag === true ? (
              <Button
                style={{
                  ...theme.button.submit,
                  backgroundColor: theme.palette.primary.main
                }}
                onClick={() => downloadAllImages()}>
                Download Zip
              </Button>
            ) : (
              ''
            )}
          </Item>
        </Item>
      ) : (
        ''
      )}
    </Container>
  );
}

export default withStyles(styles)(DocumentsModel);
