const styles = (theme) => {
  return {
    newUser: {
      minWidth: '75vw',
      maxWidth: '75vw',
      padding: theme.spacing(2, 2),
      overflow: 'auto'
    },

    label: {
      color: '#868686',
      fontSize: '1.1rem',
      fontWeight: '600'
    },
    formContainer: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    subContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px'
    },
    reciepentContainer: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      height: 'auto',
      display: 'flex',
      gap: '10px',
      overflow: 'auto',
      flexWrap: 'wrap',
      padding: theme.spacing(1)
    },

    saveContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItem: 'center',
      flexWrap: 'wrap'
    },

    title: {
      color: '#868686',
      fontWeight: '500',
      fontSize: '1.2rem'
    }
  };
};

export default styles;
