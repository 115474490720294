import { ResponsivePieCanvas } from '@nivo/pie';
import React, { useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;
const Item = (props) => <Grid item {...props} />;

const MyResponsivePieCanvas = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = React.useState([]);
  const [CheckEmptyData, setCheckEmptyData] = React.useState([]);

  const data = [
    {
      label: 'No Data found',
      value: 1,
      color: '#DCDCDC',
      id: 'No Data Found'
    }
  ];

  useEffect(() => {
    API_GET(
      `reports/getSosUsageDistribution?fromDate=${selectedFromDate}&toDate=${selectedToDate}&city=${location}`
    )
      .then((res) => {
        const result = res?.SosUsageDistributionData?.map((item) => {
          return {
            label: item?.label,
            value: item?.value,
            color: item?.color,
            id: item?.id
          };
        });
        setRows(result);
        const checkData = result.filter((item) => {
          return item.value !== 0 && item.value;
        });
        setCheckEmptyData(checkData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location]);

  const handleExport = () => {
    API_GET(
      `reports/downloadSosUsageDistribution?fromDate=${selectedFromDate}&toDate=${selectedToDate}&city=${location}`
    )
      .then((res) => {
        downloadDataToExcelFile(
          res?.SosUsageDistributionData,
          `Sos Usage Distribution -${location} data (${selectedFromDate} To ${selectedToDate})`
        );
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            SOS Usage distribution
          </Typography>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '230px' }}>
        <ResponsivePieCanvas
          theme={{
            fontSize: 14,
            fontWeight: '600'
          }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          // valueFormat=" >-1.1%"
          data={CheckEmptyData?.length !== 0 ? rows : data}
          margin={{ top: 10, right: 200, bottom: 20, left: 10 }}
          innerRadius={0.4}
          // padAngle={0.0}
          cornerRadius={0}
          activeOuterRadiusOffset={8}
          colors={{ datum: 'data.color' }}
          arcLinkLabelsSkipAngle={0}
          arcLinkLabelsText={false}
          arcLinkLabelsTextColor="black"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={5}
          arcLabelsTextColor="black"
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 130,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 90,
              itemHeight: 28,
              itemTextColor: 'black',
              itemDirection: 'left-to-right',

              symbolSize: 15
            }
          ]}
        />
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
