import React from 'react';
import { Components, Icons } from '../../utils/material-ui';
import { getTime } from '../../utils/moment';
import styles from './styles';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
const { withStyles, Typography, Grid } = Components;
const { Phone, RoomIcon, ApartmentIcon, AccountCircleIcon, AccessTimeIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function IncidentCard(props) {
  const { data, classes } = props;

  const showInMapClicked = (lat, lng) => {
    window.open(`https://maps.google.com?q=${lat},${lng}`);
  };

  return (
    <Container md={12} direction="column">
      <Item>
        <Container alignItems="center" justifyContent="space-between">
          <Item>
            <Typography
              fontWeight={700}
              fontSize={24}
              sx={{ color: data.offlineSos ? '#E51111' : '#098F4C' }}>
              SOS
            </Typography>
          </Item>
          <Item ml={2}>{data.timer}</Item>
        </Container>
      </Item>
      {[
        {
          icon: AccountCircleIcon,
          text: `${data.name}, ${ConvertToUpperCase(data.gender)}`
        },
        { icon: AccessTimeIcon, text: getTime(data.recieved) },
        { icon: Phone, text: data.number },
        {
          icon: RoomIcon,
          text: data.location,
          onClick: () => showInMapClicked(data.lat, data.lng)
        },
        { icon: ApartmentIcon, text: data.corporateName }
      ].map((item) => (
        <Item mt={1} sx={{ display: 'flex' }}>
          <item.icon className={classes.icon}></item.icon>
          <Typography ml={1} onClick={item.onClick}>
            {item.text}
          </Typography>
        </Item>
      ))}
    </Container>
  );
}

export default withStyles(styles)(IncidentCard);
