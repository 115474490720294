const styles = (theme) => {
  return {
    root: {
      width: '100%'
    },
    container: {
      maxHeight: 500,
      overflow: 'auto'
    },
    tableHeader: {},
    actionBtn: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& a': {
        textDecoration: 'none',
        color: theme.palette.common.white
      }
    },

    boldFont: {
      fontWeight: 'bold'
    },
    heading: {
      fontSize: '16px',
      color: theme.palette.primary.textTableColor,
      fontWeight: '500',
      padding: theme.spacing(2, 1)
    },
    headers: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center'
    }
  };
};

export default styles;
