const DownloadTextFile = (data) => {
  const file = new Blob([data], { type: 'text/plain' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = 'error.txt';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default DownloadTextFile;
