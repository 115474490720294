import React, { useState, useEffect } from 'react';
import styles from './styles';
import StyledTableCell from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../utils/store';
import { API_GET } from '../../../../utils/api';
import { Components } from '../../../../utils/material-ui';
import { getDateTime } from '../../../../utils/moment';
import tableColor from '../../../../utils/tableColor';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import theme from '../../../../utils/theme';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button
} = Components;

const ClosedIncidentTable = ({
  classes,
  selectedType,
  incidentDate,
  incidentToDate,
  informedTo,
  filter,
  type,
  searchText,
  corporate,
  city,
  attendedBy,
  service
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalIncidents, settotalIncidents] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    API_GET(
      `report-incident/ended?incidentType=${
        type || selectedType
      }&incidentDate=${incidentDate}&incidentToDate=${incidentToDate}&informedTo=${filter}&limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}&corporate=${corporate}&city=${city}&closedBy=${attendedBy}&service=${service}`
    )
      .then((res) => {
        settotalIncidents(res.data.count);
        setRows(res.data.incidents);
      })
      .catch((err) => {
        err.status === 404
          ? dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Network Error'
            })
          : dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: err.data.errors[0]?.message ?? 'Oops!!, Please try again'
            });
      });
  }, [
    dispatch,
    page,
    rowsPerPage,
    incidentDate,
    incidentToDate,
    selectedType,
    informedTo,
    searchText,
    corporate,
    city,
    attendedBy,
    service
  ]);

  const showInMapClicked = (lat, lng) => {
    window.open(`https://maps.google.com?q=${lat},${lng}`);
  };

  const getAllDates = (column, type, item) => {
    if (column === 'closedAt' && type === 'unknown') {
      return '-';
    } else if (column === 'adminClosedAt' && type === 'fake') {
      return '-';
    } else {
      return getDateTime(item);
    }
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(index) }}>
                    {columns &&
                      columns.map((column) => {
                        const value = row[column.id] ?? '--';

                        if (
                          column.id === 'incomingTime' ||
                          column.id === 'closureTime' ||
                          column.id === 'closedAt' ||
                          column.id === 'adminClosedAt'
                        ) {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {getAllDates(column.id, selectedType, value)}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'acknowledgedAt') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {row[column.id] ? getDateTime(row[column.id]) : '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'offlineSos') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {row.offlineSos ? 'true' : 'false'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'viewOnMap') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              <Button
                                className={classes.viewButton}
                                style={{ color: theme.palette.primary.sosDashboard }}
                                onClick={() => showInMapClicked(row.lat, row.lng)}>
                                View
                              </Button>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'informedTo') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {ConvertToUpperCase(row?.informedTo) || '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'service') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {Array.isArray(value) && value.join(', ')}
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalIncidents && totalIncidents ? totalIncidents : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default withStyles(styles)(ClosedIncidentTable);
