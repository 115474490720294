const styles = (theme) => {
  return {
    acknowledgementModal: {
      height: 'auto'
    },
    cardHeading: {
      padding: '7px 20px',
      backgroundColor: theme.palette.primary.sosDashboard,
      borderRadius: '5px 5px 0 0',
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    middleContainer: {
      maxHeight: '90vh',
      overflow: 'auto'
    },
    formRow: {
      maxWidth: '70vw',
      padding: theme.spacing(1, 4)
    },
    firstRow: {
      marginTop: '10px'
    },
    confirmButton: {
      marginBottom: '8px'
    }
  };
};

export default styles;
