const styles = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(2)
    },

    tableContainer2: {
      height: 'auto'
    },

    locationCardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'auto',
      justifyContent: 'space-between'
    },

    cardsContainer: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      maxHeight: '70vh',
      overflow: 'auto'
    },
    inputLable: {
      fontFamily: theme.palette.font.fontFamily,
      color: theme.palette.primary.tableHeadingColor,
      margin: '10px'
    },

    notificationLabel: {
      fontFamily: theme.palette.font.fontFamily,
      fontSize: '12px'
    },
    newsContainer: {
      height: '225px',
      maxHeight: '220px',
      overflow: 'auto'
    },

    cardDataConatiner: {
      height: '320px',
      minWidth: '300px',
      display: 'flex',
      borderRadius: '10px',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
    },

    occupancyChart: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(0.5)
    },
    mapContainer: {
      height: '100%',
      width: '100%'
    },
    tagingContainer: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      overflow: 'auto',
      height: 'auto',
      padding: theme.spacing(1.5, 0),
      backgroundColor: '#FFFFFF',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'
    },
    hospitalTag: {
      height: '30px',
      borderRadius: '15px',
      fontSize: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
      cursor: 'pointer'
    },
    addressFiled: {
      display: 'flex',
      alignItems: 'center',
      gap: '7px',
      fontSize: '13px',
      color: theme.typography.tableHeading.color,
      marginTop: theme.spacing(0.7)
    },
    iconContain: {
      height: '22px',
      width: '22px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    icon: {
      color: '#FFFFFF'
    },
    carousalContainer: {
      display: 'flex',
      maxWidth: '85vw',
      overflow: 'hidden',
      alignItems: 'center'
    }
  };
};

export default styles;
