const head = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'alertCategory',
    label: 'Category',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'title',
    label: 'External Incident',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'cityFromDror',
    label: 'Location',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  // {
  //   id: 'rPridection',
  //   label: 'Risk Prediction',
  //   minWidth: 120,
  //   width: 120,
  //   maxWidth: 200,
  //   align: 'center'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: 'revenue',
    label: 'Revenue',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'humanWeightage',
    label: 'Human',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'infraWeightage',
    label: 'Infra',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
    width: 120,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'revEarned',
    label: 'Rev. Earned',
    minWidth: 120,
    width: 120,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
