const styles = (theme) => {
  return {
    root: {
      width: '100%',
      padding: theme.spacing(0.5),
      margin: '0.5%'
    },
    container: {
      height: 280,
      overflow: 'auto'
    },
    tablesearchrelative: {
      position: 'relative',
      alignitems: 'center',
      display: 'flex',
      float: 'left'
    },
    tablesearchsearchIcon: {
      position: 'absolute',
      margin: '3px 2px',
      paddingleft: '2%'
    },
    tablesearch: {
      height: '35px',
      border: 'solid 0.5px #707070',
      padding: ' 0 0 0 35px',
      outline: 'none',
      color: '#000000',
      width: '300px'
    },
    viewButton: {
      color: 'blue',
      padding: theme.spacing(1, 2),
      '& a': {
        textDecoration: 'none',
        color: theme.palette.common.white
      }
    },
    actionBtn: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      padding: theme.spacing(1, 2),
      '& a': {
        textDecoration: 'none',
        color: theme.palette.common.white
      }
    },
    searchContainer: {
      padding: theme.spacing(1)
    },
    button: {
      backgroundColor: 'blue',
      padding: theme.spacing(1, 2),
      color: theme.palette.common.white
    }
  };
};

export default styles;
