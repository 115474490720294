import React from 'react';
import styles from './styles';
import { Components } from '../../../../utils/material-ui';
import columns from './head';
import { StyledTableCell } from './StyledTableCell';
import theme from '../../../../utils/theme';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import tableColor from '../../../../utils/tableColor';
import { getDate } from '../../../../utils/moment';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  // TablePagination,
  Grid,
  Button,
  TableRow,
  TableCell
} = Components;

const Item = (props) => <Grid item {...props} />;
const VehicleDetails = ({ classes, handleClose, data }) => {
  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {data && data !== null
              ? data?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row?._id}
                      style={{ backgroundColor: tableColor(index) }}>
                      {columns &&
                        columns.map((column) => {
                          const value = row && row !== null ? row[column.id] : '';

                          if (column.id === 'registerationNo') {
                            return (
                              <StyledTableCell align="left" key={column.id}>
                                {row?.regNumber || '--'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'chassisNo') {
                            return (
                              <StyledTableCell align="center" key={column.id}>
                                {row?.chassisNumber || '--'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'registrationDate') {
                            return (
                              <StyledTableCell align="center" key={column.id}>
                                {row?.registrationDate ? getDate(row?.registrationDate) : '--'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'manufactureDate') {
                            return (
                              <StyledTableCell align="center" key={column.id}>
                                {row?.manufactureDate ? getDate(row?.manufactureDate) : '--'}
                              </StyledTableCell>
                            );
                          }
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : ConvertToUpperCase(value)}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })
              : ''}
          </TableBody>
        </Table>
      </TableContainer>

      <Item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1 }}>
        <Button
          size="small"
          onClick={() => handleClose()}
          style={{
            display: 'flex',
            backgroundColor: theme.palette.primary.tableHeadingColor,
            color: '#FFFFFF',
            fontSize: '12px'
          }}>
          Close
        </Button>
      </Item>
    </Card>
  );
};

export default withStyles(styles)(VehicleDetails);
