import React, { useState, useRef, useEffect } from 'react';
import styles from './styles';
import { Components, Icons } from '../../../../utils/material-ui';
import columns from './head';
import { StyledTableCell } from './StyledTableCell';
import { useStateValue } from '../../../../utils/store';
import { API_UPLOAD_FILE, API_HANDLE_ERROR, API_GET, API_PATCH } from '../../../../utils/api';
import tableColor from '../../../../utils/tableColor';
import DownloadDataToExcelFile from '../../../../utils/downloadDataToExcelFile';
import theme from '../../../../utils/theme';
import Dialog from '../../../../components/atom/Dialog';
import AddRevenueData from '../../Modal/AddRevenueData';
const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Grid,
  TableRow,
  TableCell,
  Button,
  CircularProgress,
  Typography,
  TextField,
  InputAdornment
} = Components;

const { EditIcon, SearchIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

const UploadDataTable = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const hiddenFileInput = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const [totolRevenue, setTotalRevenue] = useState(0);
  const [globalStatus, setGlobalStatus] = useState(false);
  const [rows, setRows] = useState([]);
  const [setSearch, setSearchText] = useState('');

  // get revenue data

  const getRevenueData = () => {
    API_GET(`corporateFinance/getCorporateFinance?searchText=${setSearch}`)
      .then((res) => {
        const result = res?.data?.map((item) => {
          return {
            ...item,
            status: false
          };
        });
        setRows(result);
        setTotalRevenue(res?.totalRevenue);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getRevenueData();
  }, [dispatch, setSearch]);

  // maneage state

  const setStateData = (id, data) => {
    const result = rows?.map((item) => {
      return item?._id === id
        ? {
            ...item,
            employeePercentage: data?.employeeRatio,
            revenuePercentage: data?.revenueGenration,
            infrastructurePercentage: data?.infraDistrution,
            yearlyRevenue: data?.revenueTotal,
            status: true
          }
        : item;
    });
    setRows(result);
  };

  // handle Add office

  const handleAddOfficeModal = (status, data) => {
    setIsOpen(true);
    setGlobalStatus(true);
    setSelectedData(data);
    setStatus(status);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  // Upload Data

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    uploadfile(fileUploaded);
    event.target.value = '';
  };

  const uploadfile = (fileUploaded) => {
    setLoading(true);
    API_UPLOAD_FILE(`corporateFinance/importCorporateFinance`, fileUploaded)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        getRevenueData();
        setLoading(false);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);

        setLoading(false);
      });
  };

  // submit all data value to check percentage

  const handleSubmitData = () => {
    const totalRevenueGenration = rows.reduce((a, b) => {
      return Number(a) + Number(b.revenuePercentage);
    }, 0);
    const totalemployeeRatio = rows.reduce((a, b) => {
      return Number(a) + Number(b.employeePercentage);
    }, 0);
    const totalinfraDistribution = rows.reduce((a, b) => {
      return Number(a) + Number(b.infrastructurePercentage);
    }, 0);

    const str = `Total Revenue Ratio is ${Number(totalRevenueGenration).toFixed(
      2
    )},Employee Ratio is ${Number(totalemployeeRatio).toFixed(2)},Infra Ratio is ${Number(
      totalinfraDistribution
    ).toFixed(2)}, The Individual ratios of Employees, Revenue and Infra must be 100`;

    if (Math.floor(totalRevenueGenration) !== 100) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: str
      });
    } else if (Math.floor(totalemployeeRatio) !== 100) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: str
      });
    } else if (Math.floor(totalinfraDistribution) !== 100) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: str
      });
    } else {
      const resultData = rows
        ?.filter((item) => {
          return item?.status === true;
        })
        .map((item) => {
          return {
            revenuePercentage: item?.revenuePercentage,
            infrastructurePercentage: item?.infrastructurePercentage,
            employeePercentage: item?.employeePercentage,
            // yearlyRevenue: item?.yearlyRevenue,
            _id: item?._id
          };
        });

      API_PATCH(`corporateFinance/updateCorporateFinance`, { financeData: resultData })
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          getRevenueData();
          setGlobalStatus(false);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  // Export Data

  const handleDownload = () => {
    const resultData = rows?.map((item, index) => {
      return {
        SrNo: index + 1,
        Location: item?.city,
        Office: item?.officeName,
        'Employees Ratio (%)': item?.employeePercentage,
        'Revenue Generation (%)': item?.revenuePercentage,
        'Infrastructure Distribution (%)': item?.infrastructurePercentage,
        'Revenue In': item?.yearlyRevenue
      };
    });
    DownloadDataToExcelFile(resultData, 'Revenue Details');
  };

  return (
    <Item sx={{ p: theme.spacing(0, 2) }}>
      <Item
        md={12}
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
        <Item md={4} xs={12}>
          <TextField
            placeholder="Search by Location and Office Name"
            size="small"
            variant="outlined"
            fullWidth
            sx={theme.customTextFiled}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Item>
        <Item
          sx={{
            display: 'inline-flex',
            ml: 'auto',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}>
          {loading ? (
            <Button
              size="small"
              style={{
                ...theme.button.filterButton
              }}>
              <CircularProgress sx={{ color: 'red' }} size={20} />
              Importing
            </Button>
          ) : (
            <Button
              size="small"
              style={{
                ...theme.button.filterButton
              }}
              onClick={handleClick}>
              Import
            </Button>
          )}
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept=".xlsx, .xls, .csv"
          />
          <Button
            onClick={handleDownload}
            size="small"
            style={{
              ...theme.button.filterButton
            }}>
            Export
          </Button>
          {globalStatus && (
            <Button
              onClick={handleSubmitData}
              size="small"
              style={{
                ...theme.button.filterButton
              }}>
              Submit
            </Button>
          )}
        </Item>
      </Item>
      <Item mt={2}>
        <Typography
          sx={{
            color: theme.typography.tableHeading.heading,
            fontSize: '18px'
          }}>{`Upload Data`}</Typography>
        <Typography
          sx={{
            color: theme.typography.tableHeading.color,
            fontSize: '14px',
            mt: 0.5
          }}>{`(Total Revenue - Rs${totolRevenue || 0})`}</Typography>
      </Item>
      <Item mt={2}>
        <Card className={classes.root} sx={theme.tableBorder}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.head}>
                <TableRow>
                  {columns &&
                    columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </StyledTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows == null ||
                  (rows?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        {'No Records found'}
                      </TableCell>
                    </TableRow>
                  ))}
                {rows && rows !== null
                  ? rows?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row?._id}
                          style={{ backgroundColor: tableColor(index) }}>
                          {columns &&
                            columns.map((column) => {
                              const value = row && row !== null ? row[column.id] : '';

                              if (column.id === 'status') {
                                return (
                                  <StyledTableCell key={column.id} align={column.align}>
                                    {row?.status === 'forwarded' ? 'pending' : row?.status || '--'}
                                  </StyledTableCell>
                                );
                              }
                              if (column.id === 'yearlyRevenue') {
                                return (
                                  <StyledTableCell key={column.id} align={column.align}>
                                    {Number(row?.yearlyRevenue).toFixed(2) || '--'}
                                  </StyledTableCell>
                                );
                              }
                              if (column.id === 'infrastructurePercentage') {
                                return (
                                  <StyledTableCell key={column.id} align={column.align}>
                                    {Number(row?.infrastructurePercentage).toFixed(2) || '--'}
                                  </StyledTableCell>
                                );
                              }
                              if (column.id === 'employeePercentage') {
                                return (
                                  <StyledTableCell key={column.id} align={column.align}>
                                    {Number(row?.employeePercentage).toFixed(2) || '--'}
                                  </StyledTableCell>
                                );
                              }
                              if (column.id === 'revenuePercentage') {
                                return (
                                  <StyledTableCell key={column.id} align={column.align}>
                                    {Number(row?.revenuePercentage).toFixed(2) || '--'}
                                  </StyledTableCell>
                                );
                              }
                              if (column.id === 'edit') {
                                return (
                                  <StyledTableCell key={column.id} align={column.align}>
                                    <EditIcon
                                      onClick={() => handleAddOfficeModal('edit', row)}
                                      sx={{ cursor: 'pointer' }}
                                    />
                                  </StyledTableCell>
                                );
                              }

                              return (
                                <StyledTableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </StyledTableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })
                  : ''}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            isOpen={isOpen}
            children={
              <AddRevenueData
                handleClose={handleClose}
                status={status}
                selectedData={selectedData}
                totolRevenue={totolRevenue}
                setStateData={setStateData}
                id={selectedData?._id}
              />
            }></Dialog>
        </Card>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(UploadDataTable);
