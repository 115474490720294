import React from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import moment from 'moment';
import { getTime } from '../../../../utils/moment';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';

const { withStyles, Grid, Typography } = Components;
const { DeleteIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

const formatDate = (date) => {
  return moment(date).format('Do MMMM YYYY');
};

const formatMealType = (mealType) => {
  const result = mealType?.map((item) => {
    return ConvertToUpperCase(item[0]);
  });
  return result;
};

const PackageDetails = ({ classes, allPackageIds, data, familyDetails, handleDelete }) => {
  return (
    <Item>
      <Item md={12} xs={12} mt={0.5} className={classes.packageDetails_container}>
        <Item>
          <Typography
            className={classes.package_subHeading}
            sx={{ fontSize: '1rem', fontWeight: '600' }}>
            {`Itinieary Details (${data?.packageName}) `}
          </Typography>
          <Item sx={{ maxHeight: '50vh', overflow: 'auto' }} mt={1}>
            <table>
              <tr>
                <th className={classes.tableRow1}>{`Date  (From - To)`}</th>
                <th className={classes.tableRow1}>Arrival Time</th>
                <th className={classes.tableRow1}>Departure time</th>
                <th className={classes.tableRow1}>Hotel</th>
                <th className={classes.tableRow1}>Place</th>
                <th className={classes.tableRow1}>Total Days</th>
                <th className={classes.tableRow1}>Meals (BLDS)</th>
                <th className={classes.tableRow1}>Mode of Transport</th>
              </tr>
              {data?.packageDetails?.length === 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>No Data Found </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
              {data?.packageDetails?.map((packagedata, index) => {
                return (
                  <tr key={index}>
                    <td className={classes.tableRow}>{`${formatDate(
                      packagedata?.arrivalTime
                    )} - ${formatDate(packagedata?.departureTime)}`}</td>
                    <td className={classes.tableRow}>{getTime(packagedata?.arrivalTime)}</td>
                    <td className={classes.tableRow}>
                      {getTime(packagedata?.departureTime) || '-'}
                    </td>
                    <td className={classes.tableRow}>{packagedata?.hotelName || '-'}</td>
                    <td className={classes.tableRow}>{packagedata?.place || '-'}</td>
                    <td className={classes.tableRow}>{packagedata?.totalDays || '-'}</td>
                    <td className={classes.tableRow}>
                      {packagedata?.mealType.length !== 0
                        ? formatMealType(packagedata?.mealType)
                        : '-'}
                    </td>
                    <td className={classes.tableRow}>
                      {packagedata?.modeOfTransport.join(',') || '-'}
                    </td>
                  </tr>
                );
              })}
            </table>
          </Item>
        </Item>
        <Item mt={2}>
          <Typography
            className={classes.package_subHeading}
            sx={{ fontSize: '1rem', fontWeight: '600' }}>
            Family Members Details
          </Typography>
          <Item sx={{ maxHeight: '50vh', overflow: 'auto' }} mt={1}>
            <table>
              <tr>
                <th className={classes.tableRow1}>Name</th>
                <th className={classes.tableRow1}>Relation</th>
                <th className={classes.tableRow1}>Ph No.</th>
                <th className={classes.tableRow1}>Pending docs</th>
                <th className={classes.tableRow1}>To be Expire</th>
                <th className={classes.tableRow1}>Expired</th>
                <th className={classes.tableRow1}>Action</th>
              </tr>
              {familyDetails?.length === 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>No Data Found </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
              <React.Fragment>
                {familyDetails?.map((item, index) => {
                  return (
                    <tr>
                      <td className={classes.tableRow}>{ConvertToUpperCase(item?.name)}</td>
                      <td className={classes.tableRow}>
                        {ConvertToUpperCase(item?.relation?.relation)}
                      </td>
                      <td className={classes.tableRow}>{item?.mobileNumber}</td>
                      <td
                        className={classes.tableRow}>{`${item?.docsCount?.pendingDocsCount}/7`}</td>
                      <td
                        className={
                          classes.tableRow
                        }>{`${item?.docsCount?.toBeExpiredDocsCount}/7`}</td>
                      <td
                        className={classes.tableRow}>{`${item?.docsCount?.expiredDocsCount}/7`}</td>
                      <td className={classes.tableRow}>
                        <DeleteIcon
                          onClick={() => handleDelete(item?.userId)}
                          style={{ cursor: 'pointer' }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </React.Fragment>
            </table>
          </Item>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(PackageDetails);
