import React, { useState } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from './styles';
import theme from '../../../utils/theme';
import { API_PATCH, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';

const {
  withStyles,
  Grid,
  Button,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const colorArray = [
  { color: 'FFA12F', lightColor: 'F9DCB9' },
  { color: 'FF5722', lightColor: 'FFD6C9' },
  { color: 'FF6760', lightColor: 'FFD1CF' },
  { color: 'F8306D', lightColor: 'FFBDD2' },
  { color: 'FF00FC', lightColor: 'FFC6FF' },
  { color: '7B68EE', lightColor: 'D8D1FF' },
  { color: '4169E1', lightColor: 'C7D5FF' },
  { color: '5F81FF', lightColor: 'CBD7FF' },
  { color: '0AB4FF', lightColor: 'AFE7FF' },
  { color: '08C7E0', lightColor: 'ABEDF5' },
  { color: '07A092', lightColor: 'B4E8E3' },
  { color: '1DB954', lightColor: 'BBE9CB' },
  { color: '2EA52C', lightColor: 'B7E0B6' },
  { color: '757380', lightColor: 'D8D7DD' },
  { color: '202020', lightColor: 'CDCDCD' }
];

const getColor = JSON.parse(localStorage.getItem('color')) || 'FF6760';
const getLightColor = JSON.parse(localStorage.getItem('lightcolor')) || 'FFD1CF';

const Customization = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [selectedColor, setSeletctedColor] = useState(getColor);
  const [selectedLightColor, setSeletctedLightColor] = useState(getLightColor);
  const [selectedTheme, setSelectedTheme] = useState('light');

  const handleSetColor = (item) => {
    setSeletctedColor(item.color);
    setSeletctedLightColor(item.lightColor);
  };

  const handleStateChange = (value) => {
    setSelectedTheme(value);
  };

  const handleSubmit = () => {
    API_PATCH(`corporateThemeConfig/mutateCorporateTheme`, {
      themeColor: selectedColor,
      theme: selectedTheme,
      lightThemeColor: selectedLightColor
    })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message || 'Success'
        });

        localStorage.setItem('color', JSON.stringify(selectedColor || 'FF6760'));
        localStorage.setItem('lightcolor', JSON.stringify(selectedLightColor || 'FFD1CF'));
        const globalColor = selectedColor;
        theme.button.noAction.color = `#${globalColor}`;
        theme.headingBgColor = `#${selectedLightColor}`;
        theme.button.noAction.border = `1px solid #${globalColor}`;
        theme.button.forward.color = `#${globalColor}`;
        theme.button.initiate.backgroundColor = `#${globalColor}`;
        theme.button.trash.color = `#${globalColor}`;
        theme.button.trash.border = `1px solid #${globalColor}`;
        // theme.button.submit.backgroundColor = `#${globalColor}`;
        theme.button.cancel.color = `${globalColor}`;
        theme.button.cancel.border = `1px solid #${globalColor}`;
        theme.palette.primary.main = `#${globalColor}`;
        theme.palette.primary.secondtheme = `#${globalColor}`;
        theme.palette.primary.tableHeadingColor = `#${globalColor}`;
        theme.palette.primary.maintextColor = `#${globalColor}`;
        theme.countsCard.selectedCardColor.backgroundColor = `#${globalColor}`;
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <Container spacing={1} className={classes.mainContainer}>
      <Item md={12} xs={12}>
        <Paper sx={theme.card}>
          <Item sx={{ minHeight: '70vh', p: 3 }}>
            <Item md={12} xs={12}>
              <Typography
                sx={{
                  ...theme.typography.tableHeading,
                  fontWeight: '600',
                  p: 0,
                  fontSize: '16px'
                }}>
                Select Mode
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  color: '#565555',
                  mt: 1
                }}>
                Customizing your workspace, make it more enjoyable and comfortable to work !
              </Typography>
            </Item>
            <Item md={6} xs={12} mt={2} className={classes.cardsContainer}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={selectedTheme}
                  onChange={(e) => handleStateChange(e.target.value)}>
                  <Item sx={{ display: 'flex', gap: '40px', flexWrap: 'wrap' }}>
                    <Item className={classes.themeContainer}>
                      <img
                        className={classes.themeImg}
                        src={require(`../../../Assets/settingsIcon/light.svg`).default}
                        alt="icon"
                      />
                      <Item p="5px 10px">
                        <FormControlLabel
                          value="light"
                          style={{ color: theme.typography.tableHeading.color }}
                          control={<Radio style={{ color: theme.palette.primary.main }} />}
                          label="System (Light mode)"
                        />
                      </Item>
                    </Item>
                    {/*   <Item className={classes.themeContainer}>
                      <img
                        className={classes.themeImg}
                        src={require(`../../../Assets/settingsIcon/dark.svg`).default}
                        alt="icon"
                      />
                      <Item p="5px 10px">
                        <FormControlLabel
                          value="dark"
                          style={{ color: theme.typography.tableHeading.color }}
                          control={<Radio style={{ color: theme.palette.primary.main }} />}
                          label="System (Dark mode)"
                        />
                      </Item>
              </Item> */}
                  </Item>
                </RadioGroup>
              </FormControl>
            </Item>

            <Item md={12} xs={12} mt={6}>
              <Typography
                sx={{
                  ...theme.typography.tableHeading,
                  fontWeight: '600',
                  p: 0,
                  fontSize: '16px'
                }}>
                Select Color Theme
              </Typography>
              <Item className={classes.cardsContainer} sx={{ justifyContent: 'flex-start' }}>
                {colorArray?.map((item) => {
                  return (
                    <Item mt={2} md={1} xs={1}>
                      <Item
                        onClick={() => handleSetColor(item)}
                        className={classes.colorPointer}
                        sx={{
                          backgroundColor: `#${item.color}`,
                          border: selectedColor === item.color && `2px solid black`
                        }}></Item>
                    </Item>
                  );
                })}
              </Item>
            </Item>
          </Item>
          <Container>
            <Item
              sx={{
                display: 'inline-flex',
                ml: 'auto',
                gap: '20px',
                p: 3
              }}>
              {/* <Item>
                <Button
                  sx={{
                    ...theme.button.cancel
                  }}>
                  Cancel
                </Button>
                </Item> */}
              <Item>
                <Button
                  onClick={handleSubmit}
                  style={{ ...theme.button.submit, backgroundColor: theme.palette.primary.main }}>
                  {`Save`}
                </Button>
              </Item>
            </Item>
          </Container>
        </Paper>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Customization);
