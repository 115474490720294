import React, { useState, useEffect } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import OfficeVaccinationGraph from './OfficeVaccinationGraph';
import OverallVaccinationStatusGraph from './OverallVaccinationStatusGraph';
import LastMonthVaccinationStatus from './LastMonthVaccinationStatus';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';

const { withStyles, Grid, MenuItem, Select, InputLabel, FormControl, Typography } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const VaccinationOverview = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [selectedDepartment, setSelectedDepartment] = useState('all');
  const [allDepartments, setAllDepartments] = useState([
    {
      _id: 'all',
      department: 'All'
    }
  ]);
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [location, setlocation] = React.useState('all');
  const [offices, setOffices] = React.useState([{ city: 'All', _id: 'all' }]);
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const handleDate = (date) => {
    if (date) {
      setSelectedFromDate(moment(date[0]).format('YYYY-MM-DD'));
      setSelectedToDate(moment(date[1]).format('YYYY-MM-DD'));
    }
  };

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return {
            city: item.city,
            _id: item._id
          };
        });
        setOffices([...offices, ...resultData]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const getAllDepartments = () => {
    API_GET(`reports/getAllDepartments`)
      .then((res) => {
        setAllDepartments([...allDepartments, ...res?.data]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllDepartments();
    getAllOffices();
  }, [dispatch]);

  return (
    <Container>
      <Item sx={{ display: 'flex', p: '5px 0px', gap: '10px' }}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <DateRangePicker
            onChange={(date) => handleDate(date)}
            value={[new Date(selectedFromDate), new Date(selectedToDate)]}
          />
        </Item>
        <Item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> Location </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={location}
              sx={{ width: '120px' }}
              label=" Location "
              onChange={(e) => setlocation(e.target.value)}>
              {offices?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {ConvertToUpperCase(item?.city)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
        <Item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> Department </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={selectedDepartment}
              sx={{ width: '120px' }}
              label=" Department "
              onChange={(e) => setSelectedDepartment(e.target.value)}>
              {allDepartments?.sort()?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {ConvertToUpperCase(item?.department)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
      </Item>
      <Item md={12} sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
        <Item md={6} sx={theme.card} className={classes.graphContainer}>
          <Item sx={{ p: 0.5 }}>
            <OverallVaccinationStatusGraph
              selectedToDate={selectedToDate}
              selectedFromDate={selectedFromDate}
              location={location}
              offices={offices}
            />
          </Item>
        </Item>

        <Item md={6} sx={theme.card} className={classes.graphContainer}>
          <Item className={classes.headingContainer}>
            <Item>
              <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
                Last month Vaccination Status
              </Typography>
            </Item>
            {/* <Item>
              <FileDownloadIcon style={{ cursor: 'pointer' }} />
            </Item> */}
          </Item>
          <Item sx={{ p: 0.5 }}>
            <LastMonthVaccinationStatus
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              location={location}
              selectedDepartment={selectedDepartment}
            />
          </Item>
        </Item>
      </Item>
      <Item md={12} sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between', mt: 2 }}>
        <Item md={12} sx={theme.card} className={classes.graphContainer}>
          <Item sx={{ p: 0.5 }}>
            <OfficeVaccinationGraph
              selectedToDate={selectedToDate}
              selectedFromDate={selectedFromDate}
              location={location}
              selectedDepartment={selectedDepartment}
              allDepartments={allDepartments}
            />
          </Item>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(VaccinationOverview);
