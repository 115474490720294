import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import ClosedIncidentTable from '../../components/organism/Table/ClosedIncidentTable';
import moment from 'moment';
import IncidentTypeCard from './IncidentTypeCard';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import theme from '../../utils/theme';
import ExportTable from './exportTable';
import ExportToExcel from '../../utils/downloadMergeColumnData';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import { sosServices } from '../../config/index';

const { withStyles, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } =
  Components;

const { SearchIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const incidentType = [
  // { label: 'Reported', value: 'reported' },
  { label: 'Genuine', value: 'genuine' },
  // { label: 'Informed L1 & L2', value: 'l1l2' },
  { label: 'End by User', value: 'unknown' },
  { label: 'Fake', value: 'fake' }
];

const Incidents = ({ classes }) => {
  const [selectedIncidentType, setIncidenType] = useState('genuine');
  const [counts, setCounts] = useState({});
  const [, dispatch] = useStateValue();
  const [incidentDate, setIncidentDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [incidentToDate, setIncidentToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [, setInformedTo] = useState('');
  const [exportData, setExportData] = useState([]);

  const [allCities, setAllCities] = useState(['all']);
  const [location, setlocation] = useState('all');
  const [allCorporates, setAllCorporates] = useState([{ label: 'all', id: 'all' }]);
  const [corporate, setCorporate] = useState('all');
  const [sosAdmins, setSosAdmins] = useState([{ fullName: 'all', _id: 'all' }]);
  const [attendedBy, setAttendedBy] = useState('all');
  const [service, setService] = useState('all');

  const [searchText, setSearchText] = useState('');

  const filters = [
    { name: 'L1', value: 'l1' },
    { name: 'L1&L2', value: 'l1l2' },
    { name: 'None', value: 'none' }
  ];

  useEffect(() => {
    API_GET(`report-incident/getSosCitiesForCorporate?disableCorporateMatch=true`)
      .then((res) => {
        setAllCities([...allCities, ...res?.data?.cities]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  useEffect(() => {
    API_GET(`report-incident/getSosAdmins`)
      .then((res) => {
        setSosAdmins([...sosAdmins, ...res?.data?.sosAdmins]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  useEffect(() => {
    API_GET(`corporate/getAllCorporate`)
      .then((res) => {
        setAllCorporates([
          ...allCorporates,
          ...res.allCorporates.map((item) => {
            return { label: item.name, id: item._id };
          })
        ]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  const [filter, setFilter] = useState('l1');

  useEffect(() => {
    API_GET(`report-incident/counts?incidentDate=${incidentDate}&incidentToDate=${incidentToDate}`)
      .then((res) => {
        setCounts(res.data.counts);
      })
      .catch((err) => {
        err.status === 404
          ? dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Network Error'
            })
          : dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: err.data.errors[0]?.message ?? 'Oops!!, Please try again'
            });
      });
  }, [dispatch, incidentDate, incidentToDate, searchText]);

  const onTypeChange = (type) => {
    if (type === 'l1l2') {
      setInformedTo('l1l2');
      setIncidenType(type);
    } else {
      setInformedTo('');
      setIncidenType(type);
    }
  };

  const onDateChange = (date) => {
    setIncidentDate(moment(date.target.value).format('YYYY-MM-DD'));
  };

  const onToDateChange = (date) => {
    setIncidentToDate(moment(date.target.value).format('YYYY-MM-DD'));
  };

  const handleExport = () => {
    API_GET(
      `report-incident/ended?incidentType=${selectedIncidentType}&incidentDate=${incidentDate}&incidentToDate=${incidentToDate}&informedTo=${filter}&searchText=${searchText}&corporate=${corporate}&city=${location}&closedBy=${attendedBy}&service=${service}`
    )
      .then((res) => {
        setExportData(res?.data?.incidents);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
    let corporateName;
    allCorporates.forEach((item) => {
      if (item.id === corporate) {
        corporateName = item.label;
      }
    });
    setTimeout(() => {
      ExportToExcel(
        'Sos_Close_Table_Data',
        'xlsx',
        `Sos_${selectedIncidentType}_${location}_${corporateName} (${incidentDate} To ${incidentToDate})).xlsx`
      );
    }, 300);
  };

  const CloseTableData = () => {
    return (
      <div id="Sos_Close_Table_Data" style={{ display: 'none' }}>
        <ExportTable data={exportData} selectedIncidentType={selectedIncidentType} />
      </div>
    );
  };
  return (
    <Container direction={'column'} className={classes.newsPage}>
      <Container direction="row" sx={{ alignItems: 'center' }}>
        <Item xs={5}>
          <div className={classes.tablesearchrelative}>
            <SearchIcon className={classes.tablesearchsearchIcon} />
            <input
              type="tablesearch"
              className={classes.tablesearch}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Search by Name or PhoneNumber"
            />
          </div>
        </Item>
        <Item>
          <Item sx={{ display: 'flex', gap: '10px' }}>
            <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
              <form noValidate>
                <TextField
                  id="date"
                  label="From Date"
                  type="date"
                  size="small"
                  defaultValue={incidentDate}
                  className={classes.datePicker}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onDateChange(e);
                  }}
                />
              </form>
            </Item>
            <Item>
              <form noValidate>
                <TextField
                  id="date"
                  size="small"
                  label="To Date"
                  type="date"
                  defaultValue={incidentToDate}
                  className={classes.datePicker}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onToDateChange(e);
                  }}
                />
              </form>
            </Item>
          </Item>
        </Item>

        <Item ml={1}>
          {CloseTableData()}
          <Button
            style={{
              backgroundColor: 'white',
              color: theme.palette.primary.sosDashboard,
              border: `1px solid ${theme.palette.primary.sosDashboard}`
            }}
            onClick={handleExport}>
            Export
          </Button>
        </Item>
      </Container>

      <Container direction="row" alignItems="center">
        <Item xs={8} className={classes.incidentTypeContainer}>
          {incidentType.map((item) => (
            <IncidentTypeCard
              data={item}
              count={counts}
              key={item.label}
              current={selectedIncidentType}
              onClick={onTypeChange}
            />
          ))}
        </Item>
        {selectedIncidentType === 'genuine' && (
          <Item xs={3}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth="true"
              className={classes.formControl}>
              <InputLabel id="select">Escalated To</InputLabel>
              <Select
                labelId="select"
                id="organization-select"
                value={filter}
                label="Escalated To"
                onChange={(e) => setFilter(e.target.value)}>
                {filters.map((item) => (
                  <MenuItem key={item.name} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        )}
        <Container spacing={1}>
          <Item>
            <FormControl>
              <InputLabel id="demo-simple-select-label"> Corporate </InputLabel>
              <Select
                variant="outlined"
                size="small"
                value={corporate}
                sx={{ fontSize: '12px', width: '200px' }}
                label="Corporate"
                onChange={(e) => setCorporate(e.target.value)}>
                {allCorporates?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {ConvertToUpperCase(item.label)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Item>
          <Item>
            <FormControl>
              <InputLabel id="demo-simple-select-label"> Location </InputLabel>
              <Select
                variant="outlined"
                size="small"
                value={location}
                sx={{ fontSize: '12px', width: '200px' }}
                label=" Location "
                onChange={(e) => setlocation(e.target.value)}>
                {allCities?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {ConvertToUpperCase(item)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Item>

          <Item>
            <FormControl>
              <InputLabel id="demo-simple-select-label"> Closed By </InputLabel>
              <Select
                variant="outlined"
                size="small"
                value={attendedBy}
                sx={{ fontSize: '12px', width: '200px' }}
                label=" Closed By "
                onChange={(e) => setAttendedBy(e.target.value)}>
                {sosAdmins?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item._id}>
                      {ConvertToUpperCase(item.fullName)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Item>
          <Item ml={1}>
            <FormControl>
              <InputLabel id="demo-simple-select-label"> Service </InputLabel>
              <Select
                variant="outlined"
                size="small"
                value={service}
                sx={{ fontSize: '12px', width: '200px' }}
                label=" Service "
                onChange={(e) => setService(e.target.value)}>
                {['all', ...sosServices].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {ConvertToUpperCase(item)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Item>
        </Container>
      </Container>

      <Item mt={1} className={classes.incidentTable}>
        <Container>
          <ClosedIncidentTable
            selectedType={selectedIncidentType}
            incidentDate={incidentDate}
            informedTo={filter}
            incidentToDate={incidentToDate}
            filter={filter}
            searchText={searchText}
            corporate={corporate}
            city={location}
            attendedBy={attendedBy}
            service={service}
          />
        </Container>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Incidents);
