import React, { useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import CountCard from './CountsCard';
import companyProfileDetail from './companyProfileDetail';
import Branches from './branches';
import UploadData from './UploadData';

const { withStyles, Grid, Box } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const CompanyProfile = ({ classes }) => {
  const cards = [
    { value: 'profile', label: 'Company Profile', table: companyProfileDetail },
    {
      value: 'customize',
      label: 'Branches',
      table: Branches
    },
    { value: 'appointment', label: 'Upload Data', table: UploadData }
  ];

  const [selectedCard, setSelectedCard] = useState(cards[0]);

  return (
    <Container spacing={1}>
      <Box sx={{ flexGrow: 1, ml: 1 }}>
        <Container
          justify="space-between"
          alignItems="center"
          mt={1}
          className={classes.mainContainer}>
          {cards.map((item) => (
            <Item key={item.value}>
              <CountCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
            </Item>
          ))}
        </Container>
      </Box>
      <Item md={12} xs={12}>
        <selectedCard.table></selectedCard.table>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(CompanyProfile);
