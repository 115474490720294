import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect, useState } from 'react';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';

const MyResponsivePieCanvas = ({ selectedFromDate, selectedToDate, isIncident }) => {
  const [, dispatch] = useStateValue();
  const [data, setData] = useState([]);

  const graphData = [
    {
      Country: 'Completed',
      Completed: data && data[0]?.count
    },
    {
      Country: 'No Action',
      'No Action': data && data[1]?.count
    },
    {
      Country: 'Accepted',
      Accepted: data && data[2]?.count
    },
    {
      Country: 'Delayed',
      Delayed: data && data[3]?.count
    },
    {
      Country: 'Pending',
      Pending: data && data[4]?.count
    },
    {
      Country: 'Assigned',
      Assigned: data && data[5]?.count
    },
    {
      Country: 'Comp. with delay',
      'Completed with delay': data && data[6]?.count
    }
  ];

  const getPieChartData = () => {
    API_GET(
      `incident/getIndividualStatusDistributionOfIncidents?fromDate=${selectedFromDate}&toDate=${selectedToDate}&historyType=${
        isIncident === true ? 'alert' : 'incident'
      }&alertNewsStatus=${isIncident}`
    )
      .then((res) => {
        setData(res?.constants);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getPieChartData();
  }, [selectedToDate, selectedFromDate, isIncident]);

  return (
    <ResponsiveBar
      data={(graphData && graphData) || []}
      theme={{
        fontSize: 11,
        fontWeight: '600'
      }}
      keys={[
        'Completed',
        'No Action',
        'Accepted',
        'Delayed',
        'Pending',
        'Assigned',
        'Completed with delay'
      ]}
      indexBy="Country"
      margin={{ top: 20, right: 160, bottom: 50, left: 70 }}
      padding={0.5}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#60C121', '#EB3434', '#10B1B1', '#CD5C5C', '#7084F3', '#FFD580', '#EE82EE']}
      enableLabel={false}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 110,
          translateY: 30,
          itemsSpacing: 1,
          itemWidth: 100,
          itemHeight: 25,
          itemDirection: 'left-to-right',
          itemOpacity: 5,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 3
              }
            }
          ]
        }
      ]}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Frequency',
        legendPosition: 'middle',
        legendOffset: -40
      }}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        legend: 'Status',
        legendPosition: 'middle',
        legendOffset: 45
      }}
    />
  );
};

export default MyResponsivePieCanvas;
