/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';
import { useStateValue } from '../../utils/store';
import moment from 'moment';
import { useLocation } from 'react-router';
import DeletedDoctorTable from '../../components/organism/Table/DeletedDoctorTable';
import SortButtonModal from '../../utils/sortButton';
import AppointmentFilter from './appointmentFilter';
import { FilterButton, SortButton } from '../../utils/ButtonDiff';
import Filter from '../../utils/filters';

const { withStyles, Grid, TextField, InputAdornment, Button, Typography } = Components;

const { SearchIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const sortArray = [
  { name: 'Asc - Des', value: 1 },
  { name: 'Des- Asc', value: -1 }
];

const DeletedDoctor = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [searchText, setSearchText] = useState('');
  const getSearch = useLocation().search;
  const searchByParams = new URLSearchParams(getSearch).get('searchBy');
  const [sort, setSort] = useState('');
  const [isOpenSortModal, setIsOpenSortModal] = useState(false);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [filtersCategory, setFilterCategory] = useState([]);
  const [checkedItems, setCheckedItems] = useState([
    { label: 'General Physician', name: 'generalphysician', value: false, type: 'Category' },
    { label: 'Psychiatric', name: 'psychiatric', value: false, type: 'Category' }
  ]);

  useEffect(() => {
    if (searchByParams) {
      setSearchText(searchByParams);
    }
  }, []);

  const [selectedFromDate, setSelectedFromDate] = useState('Invalid date');
  const [selectedToDate, setSelectedToDate] = useState('Invalid date');

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const handleOpenSortModal = () => {
    setIsOpenSortModal(!isOpenSortModal);
    setIsOpenFilterModal(false);
  };

  const handleSort = (value) => {
    setSort(value);
    setIsOpenSortModal(false);
  };

  const handleClose = () => {
    setIsOpenFilterModal(false);
  };

  const handleOpenFilterModal = () => {
    setIsOpenFilterModal(!isOpenFilterModal);
    setIsOpenSortModal(false);
  };

  const handleClearAll = () => {
    setSort('');
    const result = checkedItems?.map((data) => {
      return { ...data, value: false };
    });
    setCheckedItems(result);
  };

  const handleRemove = (item, status) => {
    if (status === 'sort') {
      setSort('');
    }
    if (status === 'filter') {
      const result = filtersCategory?.filter((data) => {
        return data !== item;
      });
      setFilterCategory(result);
    }
  };

  const handleFilterCategory = (value) => {
    setFilterCategory(value);
  };

  const handleChange = (item) => {
    const result = checkedItems?.map((subItem) => {
      return subItem.name === item.name ? { ...subItem, value: !subItem?.value } : { ...subItem };
    });
    setCheckedItems(result);
  };

  return (
    <Container>
      <Item
        md={12}
        xs={12}
        mt={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
        <Item md={4} xs={12}>
          <TextField
            placeholder="Search by doctor name"
            size="small"
            variant="outlined"
            fullWidth
            sx={theme.customTextFiled}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Item>
        <Item
          sx={{
            display: 'inline-flex',
            ml: 'auto',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}>
          <Item sx={{ position: 'relative' }}>
            <SortButton handleOpenSortModal={handleOpenSortModal} />
            {isOpenSortModal && (
              <SortButtonModal handleSort={handleSort} sortArray={sortArray} sort={sort} />
            )}
          </Item>
          <Item sx={{ position: 'relative' }}>
            <FilterButton handleOpenFilterModal={handleOpenFilterModal} />
            {isOpenFilterModal && (
              <AppointmentFilter
                checkedItems={checkedItems}
                handleClose={handleClose}
                handleChange={handleChange}
                handleFilterCategory={handleFilterCategory}
              />
            )}
          </Item>

          <Item>
            <form noValidate>
              <TextField
                id="datetime-local"
                label="From"
                type="date"
                size="small"
                value={selectedFromDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onFromDateChange(e);
                }}
              />
            </form>
          </Item>
          <Item>
            <form noValidate>
              <TextField
                id="date"
                label="To"
                type="date"
                size="small"
                value={selectedToDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onToDateChange(e);
                }}
              />
            </form>
          </Item>
        </Item>
      </Item>
      {(sort !== '' || filtersCategory?.length > 0) && (
        <Filter
          filtersCategory={filtersCategory}
          sort={sort}
          handleClearAll={handleClearAll}
          handleRemove={handleRemove}
        />
      )}
      <Item md={12} xs={12} mt={3} mb={1}>
        <DeletedDoctorTable
          searchText={searchText}
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
          sort={sort}
          filtersCategory={filtersCategory}
        />
      </Item>
    </Container>
  );
};

export default withStyles(styles)(DeletedDoctor);
