import { Typography } from '@mui/material';
import React from 'react';
import { Components, Icons } from '../../../../../utils/material-ui';
import styles from './styles';

const { withStyles, Grid } = Components;
const { ClearIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function BroadcastSentToModal({ classes, handleClose, sentToData }) {
  console.log('sentToData', sentToData);

  const SmallCard = (item, type) => {
    let result = [];
    if (type === 'Office Location') {
      result = `${item}, `;
    } else if (type === 'Office') {
      result = `(${item?.name}, ${item?.city}), `;
    } else if (type === 'Department') {
      result = `(${item?.department}), `;
    } else if (type === 'Current City') {
      result = `${item}, `;
    } else if (type === 'groups') {
      result = `${item?.groupName}, `;
    }
    return <Item sx={{ fontSize: '0.9rem', color: '#868686' }}>{result}</Item>;
  };

  const previewData = {
    'Office Location': sentToData?.individuals?.officeLocations,
    Office: sentToData?.individuals?.offices,
    Department: sentToData?.individuals?.departments,
    'Current City': sentToData?.currentCities
  };

  return (
    <Container direction="column" wrap="nowrap" className={classes.popupContainer}>
      <Item sx={{ display: 'flex', alignItems: 'center' }}>
        <Item sx={{ ml: 'auto' }}>
          <ClearIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
        </Item>
      </Item>
      <Item mt={1.5}>
        {'individuals' in sentToData && (
          <Item>
            <Typography className={classes.title}>Individual</Typography>
            <Item md={12} xs={12} className={classes.indiviualSelectContainer}>
              {Object.keys(previewData)?.map((item) => {
                return (
                  <Item md={12} xs={12} sx={{ display: 'flex' }} mt={1}>
                    <Item md={1.5} xs={12} className={classes.text}>
                      {previewData[`${item}`]?.length > 0 && `${item} :`}
                    </Item>
                    <Item md={10.5} xs={12} className={classes.previewData}>
                      {previewData[`${item}`]?.length > 0 &&
                        previewData[`${item}`]?.map((newItem) => {
                          return <React.Fragment>{SmallCard(newItem, item)}</React.Fragment>;
                        })}
                    </Item>
                  </Item>
                );
              })}
            </Item>
          </Item>
        )}
        {sentToData?.broadCastGroups?.length > 0 && (
          <Item>
            <Typography className={classes.title} mt={2}>
              Group
            </Typography>
            <Item md={12} xs={12} className={classes.groupSelectContainer}>
              <Item className={classes.previewData}>
                {sentToData?.broadCastGroups?.length > 0 &&
                  sentToData?.broadCastGroups?.map((newItem) => {
                    return <React.Fragment>{SmallCard(newItem, 'groups')}</React.Fragment>;
                  })}
              </Item>
            </Item>
          </Item>
        )}
      </Item>
    </Container>
  );
}

export default withStyles(styles)(BroadcastSentToModal);
