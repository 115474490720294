import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_POST } from '../../../../utils/api';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import theme from '../../../../utils/theme';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';

const {
  withStyles,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  TextField,
  Checkbox,
  Autocomplete,
  Paper
} = Components;

const { EmailIcon, WhatsAppIcon, TextsmsIcon, CloseIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const currentDate = moment(new Date()).format('YYYY-MM-DD');

function AccepetAndForwardModal({ classes, handleClose, verifyId, status, isIncident, data }) {
  const [, dispatch] = useStateValue();
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployeeArray, setSelectedEmployeeArray] = useState([]);
  const [offices, setOffices] = React.useState([]);
  const [personName, setPersonName] = React.useState([]);

  const suggestedDate = moment(data?.suggestedDate).format('YYYY-MM-DD');

  const [sendData, setSendData] = useState({
    remark: '',
    email: false,
    whatsApp: false,
    textm: false,
    app: false,
    // selectedOffice: '',
    startDate: suggestedDate < currentDate ? suggestedDate : moment(new Date()).format('YYYY-MM-DD')
  });
  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleStateChange = (key, value) => {
    if (key === 'startDate') {
      if (value < currentDate) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Proposed Date must be greater than or equal to current Date'
        });
        setSendData((prevState) => {
          prevState[`${key}`] = currentDate;
          return { ...prevState };
        });
      } else if (value > suggestedDate) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: `Proposed Date must be smaller than or equal to completion Date`
        });
        setSendData((prevState) => {
          prevState[`${key}`] = currentDate;
          return { ...prevState };
        });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else {
      setSendData((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  const getEmployeeDetails = () => {
    const resultOffice = personName?.map((item) => {
      return item?._id;
    });

    let params = '';
    resultOffice.forEach((element) => {
      params += `&officeIdsArray[]=${element}`;
    });
    resultOffice.length > 0 &&
      API_GET(`incident/getAllEmployees?skip=0&limit=90000000000&${params}`)
        .then((res) => {
          setEmployeeList(res?.data);
        })
        .catch((err) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: err[0]?.message || 'Oops!!, Please try again'
          });
        });
  };
  useEffect(() => {
    getEmployeeDetails();
  }, [personName]);

  const onHandleConfirm = (e) => {
    e.preventDefault();

    const filteredKeys = [];

    const object = {
      email: sendData.email,
      whatsApp: sendData.whatsApp,
      textm: sendData.textm,
      app: sendData.app

      // app: sendData.app
    };

    for (const key in object) {
      if (object[key] === true) {
        filteredKeys.push(key);
      }
    }

    const postdata = {
      employeeIdsArray: selectedEmployeeArray.map((item) => item._id),
      proposedDate: sendData?.startDate,
      notificationMedium: filteredKeys,
      actionMessage: sendData.remark,
      // officeId: sendData?.selectedOffice,
      incidentActionStatus: 'accepted',
      acceptWithForward: 'true'
    };
    if (status === 'accepted') {
      const resultPostdata = {
        proposedDate: sendData?.startDate,
        employeeIdsArray: [],
        incidentActionStatus: 'accepted',
        actionMessage: sendData.remark
      };
      API_POST(
        `incident/dynamicIncidentAssignment/${verifyId}?historyType=${
          isIncident ? 'alert' : 'incident'
        }`,
        resultPostdata
      )
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: err?.data?.errors[0]?.message || 'Oops!!, Please try again'
          });
        });
    } else {
      API_POST(
        `incident/dynamicIncidentAssignment/${verifyId}?historyType=${
          isIncident ? 'alert' : 'incident'
        }`,
        postdata
      )
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: err?.data?.errors[0]?.message || 'Oops!!, Please try again'
          });
        });
    }
  };

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        setOffices(res?.data);
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: err?.data?.errors[0]?.message || 'Oops!!, Please try again'
        });
      });
  };
  useEffect(() => {
    getAllOffices();
  }, []);

  const handleSelectChange = (value) => {
    if (value !== null) {
      const existData = selectedEmployeeArray.includes(value);
      if (existData) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'employee already selected'
        });
      } else {
        if (selectedEmployeeArray.length >= 2) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'Can not select multiple user'
          });
        } else {
          setSelectedEmployeeArray([...selectedEmployeeArray, value]);
        }
      }
    }
  };

  const handleDelete = (data) => {
    const resultArray = selectedEmployeeArray.filter((item) => {
      return item._id !== data._id;
    });
    setSelectedEmployeeArray(resultArray);
  };

  function getStyles(name, personName, theme) {
    return {
      backgroundColor: personName.indexOf(name) === -1 ? 'white' : theme.palette.primary.main
    };
  }

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: '400px' }}>
      <Item className={classes.title}>
        <Typography style={{ textAlign: 'left' }}>
          {status === 'accepted' ? 'Accepted' : 'Accept&Forward'}
        </Typography>
      </Item>
      <form onSubmit={onHandleConfirm}>
        <Item sx={{ p: 1, m: 0.5 }}>
          {status === 'accepted' ? (
            ''
          ) : (
            <Item className={classes.input}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">Location</InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  variant="outlined"
                  value={personName}
                  // required="true"
                  label="location"
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip key={value} label={value.name} />
                      ))}
                    </div>
                  )}>
                  {offices.map((name, index) => (
                    <MenuItem key={index} value={name} style={getStyles(name, personName, theme)}>
                      {`${name.name}, ${ConvertToUpperCase(name.city)}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          )}
          <Item mt={1.5}>
            <TextField
              id="date"
              label="Suggested Completion Date"
              value={moment(data?.suggestedDate).format('DD-MM-YYYY')}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Item>
          {suggestedDate < currentDate ? (
            <Item mt={1.5}>
              <TextField
                id="date"
                label="Proposed Date"
                value={moment(suggestedDate).format('DD-MM-YYYY')}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            </Item>
          ) : (
            <Item mt={1.5}>
              <TextField
                id="date"
                label="Proposed Date"
                type="date"
                size="medium"
                value={sendData?.startDate}
                className={classes.textField}
                onChange={(e) => {
                  handleStateChange('startDate', e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            </Item>
          )}
          <Item sx={{ mt: 1 }}>
            <Item>
              <TextField
                variant="outlined"
                name="remark"
                label="Remark"
                required={true}
                multiline
                rows={3}
                onChange={(e) => handleStateChange('remark', e.target.value)}
                type="text"
                fullWidth
              />
            </Item>
          </Item>
          <Item sx={{ mt: 1 }}>
            {status === 'accepted' ? (
              ''
            ) : (
              <Item>
                <Autocomplete
                  id="combo-box-demo"
                  className={classes.number}
                  onChange={(e, value) => {
                    handleSelectChange(value);
                  }}
                  options={employeeList}
                  color="#134CDF"
                  getOptionLabel={(option) => `${option?.fullName}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={true}
                      label="Recipient List"
                      variant="outlined"
                      type="text"
                      size="medium"
                    />
                  )}
                />
              </Item>
            )}
            <Paper sx={{ maxHeight: '200px', mt: 1, overflow: 'auto' }}>
              {selectedEmployeeArray?.map((item) => {
                return (
                  <Item
                    sx={{
                      height: '40px',
                      border: '1px solid grey',
                      mt: 1,
                      display: 'flex',
                      p: 1,
                      justifyContent: 'space-between'
                    }}>
                    <Item>{item?.fullName.toUpperCase()}</Item>
                    <Item>
                      <CloseIcon onClick={() => handleDelete(item)} />
                    </Item>
                  </Item>
                );
              })}
            </Paper>
          </Item>
          {status === 'accepted' ? (
            ''
          ) : (
            <Item
              sx={{
                display: 'flex',

                justifyContent: 'space-between'
              }}>
              <Item>
                <div
                  style={{
                    fontFamily: 'system-ui',
                    width: '70px',
                    height: '80px',
                    border: sendData.email ? `2px solid  #227BE5` : '1px solid #251515',
                    borderRadius: '5px'
                  }}>
                  <Item sx={{ ml: '44px', mt: '-9px' }}>
                    <Checkbox
                      size="extraSmall"
                      checked={sendData.email}
                      onChange={(e) => handleStateChange('email', e.target.checked)}
                    />
                  </Item>

                  <Item className={classes.icon1}>
                    <EmailIcon
                      fontSize="small"
                      sx={{
                        mt: -2,
                        color: sendData.email ? `#227BE5` : '#251515'
                      }}
                    />
                    <p
                      className={classes.iconLabel1}
                      style={{ color: sendData.email ? `#227BE5` : '#251515' }}>
                      Email
                    </p>
                  </Item>
                </div>
              </Item>
              <Item>
                <Item>
                  <div
                    style={{
                      fontFamily: 'system-ui',
                      width: '70px',
                      height: '80px',
                      borderRadius: '5px',
                      border: sendData.whatsApp ? `2px solid  #227BE5` : '1px solid #251515'
                    }}>
                    <Item sx={{ ml: '43px', mt: '-9px' }}>
                      <Checkbox
                        size="extraSmall"
                        checked={sendData.whatsApp}
                        onChange={(e) => handleStateChange('whatsApp', e.target.checked)}
                      />
                    </Item>

                    <Item className={classes.icon1}>
                      <WhatsAppIcon
                        fontSize="small"
                        sx={{
                          mt: -2,
                          color: sendData.whatsApp ? `#227BE5` : '#251515'
                        }}
                      />
                      <p
                        className={classes.iconLabel1}
                        style={{
                          color: sendData.whatsApp ? `#227BE5` : '#251515'
                        }}>
                        WhatsApp
                      </p>
                    </Item>
                  </div>
                </Item>
              </Item>
              <Item>
                <Item>
                  <div
                    style={{
                      fontFamily: 'system-ui',
                      width: '70px',
                      height: '80px',
                      border: sendData.textm ? `2px solid  #227BE5` : '1px solid #251515',
                      borderRadius: '5px'
                    }}>
                    <Item sx={{ ml: '43px', mt: '-9px' }}>
                      <Checkbox
                        size="extraSmall"
                        checked={sendData.textm}
                        onChange={(e) => handleStateChange('textm', e.target.checked)}
                      />
                    </Item>

                    <Item className={classes.icon1}>
                      <TextsmsIcon
                        fontSize="small"
                        sx={{
                          mt: -2,
                          color: sendData.textm ? `#227BE5` : '#251515'
                        }}
                      />
                      <p
                        className={classes.iconLabel1}
                        style={{
                          color: sendData.textm ? `#227BE5` : '#251515'
                        }}>
                        SMS
                      </p>
                    </Item>
                  </div>
                </Item>
              </Item>
              <Item>
                <Item>
                  <div
                    style={{
                      fontFamily: 'system-ui',
                      width: '70px',
                      height: '80px',
                      border: sendData.app ? `2px solid  #227BE5` : '1px solid #251515',
                      borderRadius: '5px'
                    }}>
                    <Item sx={{ ml: '43px', mt: '-9px' }}>
                      <Checkbox
                        size="extraSmall"
                        checked={sendData.app}
                        onChange={(e) => handleStateChange('app', e.target.checked)}
                      />
                    </Item>

                    <Item className={classes.icon1}>
                      <img src="./lythouse.png" alt="lythouse" width="15px" height="20px" />

                      <p
                        className={classes.iconLabel1}
                        style={{
                          color: sendData.app ? `#227BE5` : '#251515'
                        }}>
                        In App
                      </p>
                    </Item>
                  </div>
                </Item>
              </Item>
            </Item>
          )}
        </Item>
        <Item sx={{ display: 'flex', p: 1, gap: 1 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
              Cancel
            </Button>
          </Item>

          <Item sx={{ mr: 1 }}>
            <Button
              type="submit"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              {status === 'accepted' ? 'Accept' : 'Accept&Forward'}
            </Button>
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(AccepetAndForwardModal);
