import React, { useEffect, useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import moment from 'moment';
import dateDiffInDays from '../../utils/dateDiffernce';
import theme from '../../utils/theme';
import data from './scattersampleData';

const { withStyles, Grid, Typography, FormControl, Select, MenuItem, InputLabel } = Components;
const Item = (props) => <Grid item {...props} />;

const ScatterPlot = ({ classes, selectedFromDate, selectedToDate }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [category, setCategory] = useState('All');
  const [offices, setOffices] = React.useState([{ _id: { city: 'All', _id: 'all' } }]);

  console.log('rows', rows);
  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        setOffices([...offices, ...res?.citiesArray]);
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: err[0]?.message || 'Oops!!, Please try again'
        });
      });
  };
  useEffect(() => {
    getAllOffices();
  }, []);

  useEffect(() => {
    let date = moment();
    const convertDate = moment(date).format('YYYY-MM-DD');

    let resultSelectedFromDate;
    let resultSelectedToDate;

    if (convertDate === selectedFromDate && convertDate === selectedToDate) {
      date = date.subtract(7, 'days').format('YYYY-MM-DD');
      resultSelectedFromDate = date;
      resultSelectedToDate = selectedToDate;
    } else if (dateDiffInDays(selectedToDate, selectedFromDate) >= 30) {
      resultSelectedFromDate = selectedFromDate;
      resultSelectedToDate = moment(selectedFromDate).add(30, 'days').format('YYYY-MM-DD');
    } else {
      resultSelectedFromDate = selectedFromDate;
      resultSelectedToDate = selectedToDate;
    }

    const resultLocation = category === 'All' ? '' : category;

    API_GET(
      `alert/socialCategoryGraph?fromDate=${resultSelectedFromDate}&toDate=${resultSelectedToDate}&location=${resultLocation}`
    )
      .then((res) => {
        const resultData = res?.map((item) => {
          return {
            id: ConvertToUpperCase(item?.id),
            color: item?.color,
            data: item?.data
          };
        });
        setRows(resultData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, category]);

  const MyTooltip = (payload) => {
    const { node } = payload;
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '8px',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          gap: '5px',
          alignItems: 'center'
        }}>
        <div style={{ height: '17px', width: '17px', backgroundColor: node?.color }}></div>
        <Typography>{`${node?.serieId} :`}</Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: '700' }}>{`Day : ${moment(
          node?.data?.x
        ).format('YYYY-MM-DD')},`}</Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '700'
          }}>{`Frequency : ${node?.data?.y}`}</Typography>
      </div>
    );
  };

  return (
    <Item md={12} xs={12} sx={{ width: '100%', height: '35vh', p: 1, display: 'flex' }}>
      <Item md={10} style={{ width: '100%', height: '32vh' }}>
        <Item md={12} xs={12}>
          <Typography sx={{ ...theme.typography.tableHeading, p: 0.5 }}>Event Analytics</Typography>
        </Item>
        <ResponsiveScatterPlot
          data={rows.length > 0 ? rows : data}
          theme={{
            fontSize: 13.5
          }}
          margin={{ top: 15, right: 20, bottom: 80, left: 60 }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            precision: 'day'
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{ type: 'linear', min: 0, max: 'auto' }}
          yFormat=">-.2f"
          blendMode="multiply"
          nodeSize={10}
          enableGridX={false}
          isInteractive={true}
          tooltip={(node) => MyTooltip(node)}
          colors={{ scheme: 'set1' }}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Frequency',
            legendPosition: 'middle',
            legendOffset: -50
          }}
          axisBottom={{
            format: '%b %d',
            tickValues: 'every 3 days',
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 10,
            tickRotation: -25,
            legend: 'Day',
            legendPosition: 'middle',
            legendOffset: 60
          }}
        />
      </Item>
      <Item md={2} style={{ height: '35vh' }}>
        <Item md={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Location</InputLabel>
            <Select
              size="small"
              value={category}
              sx={{ fontSize: '14px' }}
              label="Location"
              onChange={(e) => setCategory(e.target.value)}>
              {offices?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id?.city}>
                    {ConvertToUpperCase(item?._id?.city)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
        <Item sx={{ width: '100%', maxHeight: '28vh', overflow: 'auto', p: 1 }}>
          {rows &&
            rows?.map((item) => {
              return (
                <Item className={classes.graphLabelContainer}>
                  <Item
                    className={classes.graphLabel}
                    style={{
                      backgroundColor: item?.color
                    }}></Item>
                  <Item sx={{ fontSize: '14px' }}> {ConvertToUpperCase(item?.id)}</Item>
                </Item>
              );
            })}
          <Item className={classes.graphLabelContainer}>
            <Item
              className={classes.graphLabel}
              style={{
                backgroundColor: 'Black'
              }}></Item>
            <Item sx={{ fontSize: '14px' }}> Overlap</Item>
          </Item>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(ScatterPlot);
