import React from 'react';
import HeaderWithDrawer from '../components/organism/HeaderWithDrawer';
import { Components } from './material-ui';

const { Box, Toolbar } = Components;

const drawerWidth = 250;
const Screen = (title, Component) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <HeaderWithDrawer label={title} drawerWidth={drawerWidth} />
      <Box
        component="main"
        style={{
          pt: 1,
          minWidth: `calc(100% - ${drawerWidth}px)`,
          height: 'auto',
          minHeight: '100vh',
          backgroundColor: '#F9F9F9'
        }}>
        <div>
          <Toolbar />
          {Component}
        </div>
      </Box>
    </Box>
  );
};

export default Screen;
