import React, { useState, useEffect, useRef } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import {
  API_HANDLE_ERROR,
  API_POST,
  API_GET,
  API_PATCH,
  PRE_SEND_URL
} from '../../../../utils/api';
import { useStateValue } from '../../../../utils/store';
import moment from 'moment';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import theme from '../../../../utils/theme';
import {
  nameValidate,
  emailValidate,
  mobileNumberValidate,
  isValidPincode
} from '../../../../utils/formValidation';
import axios from 'axios';
import { GetAllState, GetAllCity, GetAllCountry } from '../../../../utils/getCountryStateCity';

const {
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete
} = Components;

const { CancelRoundedIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const genderArray = [
  { name: 'Male', value: 'male' },
  { name: 'Female', value: 'female' },
  { name: 'Other', value: 'other' }
];

const FuelTypeArray = [
  { name: 'Petrol', value: 'petrol' },
  { name: 'Diesel', value: 'diesel' },
  { name: 'CNG', value: 'cng' },
  { name: 'Electrical', value: 'electrical' }
];
const TransmissionTypeArray = [
  { name: 'Automatic', value: 'automatic' },
  { name: 'Manual', value: 'manual' }
];
const VehicleTypeArray = [
  { name: '2 Wheeler', value: '2 Wheeler' },
  { name: '4 Wheeler', value: '4 Wheeler' }
];
const AddOffices = ({ classes, handleClose, isEditModal, data, activated }) => {
  const [, dispatch] = useStateValue();
  const [offices, setOffices] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [allDepartment, setAllDepartment] = useState([]);
  const [selectedLocationAllOffices, setSelectedLocationAllOffices] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isOpenVehicleDetailModel, setIsOpenVehicleDetailModel] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [editedIndex, setEditedIndex] = useState();
  const [sendData, setSendData] = useState({
    name: isEditModal ? data?.fullName : '',
    role: isEditModal ? data?.role : '',
    employeeId: isEditModal ? data?.employeeId : '',
    designation: isEditModal ? data?.designation : '',
    department: isEditModal ? data?.departmentId : '',
    emailId: isEditModal ? data?.email : '',
    address: isEditModal ? data?.currentAddress?.address : '',
    city: isEditModal ? data?.currentAddress?.city || '' : '',
    country: { name: isEditModal ? data?.currentAddress?.country || '' : '' },
    state: { name: isEditModal ? data?.currentAddress?.state || '' : '' },
    pincode: isEditModal ? data?.currentAddress?.pinCode : '',
    profileImage: isEditModal ? [data?.profileImage] : [],
    phone: isEditModal ? Number(data?.mobileNumber) : '',
    gender: isEditModal ? data?.gender : '',
    office: isEditModal ? data?.officeId : '',
    reportingTo: isEditModal ? data?.reportingTo : '',
    dateOfBirth: isEditModal
      ? moment(data?.dateOfBirth).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD'),
    location: isEditModal ? data?.officeCity : '',
    hasVehicle: isEditModal ? (data?.hasVehicles === true ? 'true' : false) : false,
    stateCode: isEditModal ? data?.stateCode : '',
    countryCode: isEditModal ? data?.countryIsoCode : ''
  });

  console.log('sendData', sendData);

  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [brands, setBrands] = useState([]);
  const [vehicleDataStatus, setVehicleDataStatus] = useState('');
  const [vehicleData, setVehicleData] = useState({
    regNumber: '',
    brand: '',
    model: '',
    fuelType: '',
    transmission: '',
    chassisNumber: '',
    vehicleType: '',
    registrationDate: '',
    manufactureDate: ''
  });

  useEffect(() => {
    if (isEditModal === true) {
      setVehicleDetails(data?.vehiclesOwned);
    }
  }, []);

  const handleStateChange = (key, value) => {
    if (key === 'name') {
      if (nameValidate(value) === false) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Special charaters are not allowed'
        });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'phone') {
      if (value < 0) {
        setSendData({ ...sendData, phone: '' });
      } else if (value.length <= 10) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'pincode') {
      if (value < 0) {
        setSendData({ ...sendData, pincode: '' });
      } else if (value.length <= 6) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'dateOfBirth') {
      if (value > moment(new Date()).format('YYYY-MM-DD')) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Please select Valid date'
        });
        setSendData({ ...sendData, dateOfBirth: moment(new Date()).format('YYYY-MM-DD') });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else {
      setSendData((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  const handleVehicleStateChange = (key, value) => {
    setVehicleData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const getAllLocation = () => {
    API_GET(`broadCastMessage/getAllLocations`)
      .then((res) => {
        setOffices(res?.officesByCrporateId);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const getAllBrands = () => {
    API_GET(`user/getVehicleBrands`)
      .then((res) => {
        setBrands(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const getAllReportingManegers = () => {
    API_GET(`user/getEmployees?onlyManagers=true`)
      .then((res) => {
        setAllAdmins(res?.data?.employees);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllLocation();
    getAllBrands();
    getAllReportingManegers();
  }, []);

  useEffect(() => {
    if (sendData?.location) {
      API_GET(`broadCastMessage/getAllLocations?cities[]=${[sendData?.location]}`)
        .then((res) => {
          setSelectedLocationAllOffices(
            res?.officesByCrporateId && res?.officesByCrporateId[0]?.offices
          );
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  }, [sendData.location]);

  useEffect(() => {
    API_GET(`user/getAvailableRoles`)
      .then((res) => {
        setRoles(res?.roles);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  useEffect(() => {
    if (sendData?.office) {
      API_GET(`department/getAllDepartment?officeId=${sendData?.office}`)
        .then((res) => {
          setAllDepartment(res?.found);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  }, [sendData.office]);

  useEffect(() => {
    const resultState = GetAllState(sendData?.countryCode);
    setStateData(resultState);

    const resultCity = GetAllCity(sendData?.countryCode, sendData?.stateCode);
    setCityData(resultCity);
  }, [sendData?.country, sendData?.state]);

  // upload photo

  const hiddenDocumentInput = useRef(null);

  const handleInputClick = (item) => {
    hiddenDocumentInput.current.click();
  };

  const handleimage = async (status, img) => {
    const arrayImage = [];

    for (let i = 0; i < img.length; i++) {
      const docsType = img[i]?.type?.split('/');
      const name = img[i]?.name;
      const url = await precenturl(docsType[1], name);
      const file = img[i];

      const config = {
        method: 'put',
        url: url.signedUrl,
        headers: {
          'x-ms-blob-content-type': img[i].type,
          'content-type': 'application/octet-stream',
          'x-ms-blob-type': 'BlockBlob'
        },
        data: file
      };

      axios(config)
        .then(function (response) {
          arrayImage.push(url.mediaUrl);
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: response?.data?.message || 'upload Successfully'
          });
        })
        .catch(function (error) {
          API_HANDLE_ERROR(error, dispatch);
        });
    }
    handleStateChange('profileImage', arrayImage);
  };

  const precenturl = async (type, name) => {
    try {
      const data = await PRE_SEND_URL('user/incident/preSignedMediaUrl', {
        fileType: type,
        fileName: name.split('.')[0],
        featureType: 'doctorProfileImage'
      });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddVehicle = () => {
    setIsOpenVehicleDetailModel(true);
    setVehicleDataStatus('addNewVehicle');
  };

  const resetData = () => {
    return setVehicleData({
      regNumber: '',
      brand: '',
      model: '',
      fuelType: '',
      transmission: '',
      chassisNumber: '',
      vehicleType: '',
      registrationDate: '',
      manufactureDate: ''
    });
  };

  const onHandleVehicleDetails = (e) => {
    e.preventDefault();
    if (vehicleDataStatus === 'editVehicle') {
      setVehicleDetails((current) =>
        current?.map((obj, index) => {
          if (index === editedIndex) {
            return {
              ...obj,
              regNumber: vehicleData?.regNumber,
              brand: vehicleData?.brand,
              model: vehicleData?.model,
              fuelType: vehicleData?.fuelType,
              transmission: vehicleData?.transmission,
              chassisNumber: vehicleData?.chassisNumber,
              vehicleType: vehicleData?.vehicleType,
              registrationDate: moment(vehicleData?.registrationDate).format('YYYY-MM-DD'),
              manufactureDate: moment(vehicleData?.manufactureDate).format('YYYY-MM-DD')
            };
          }
          return obj;
        })
      );

      setIsOpenVehicleDetailModel(false);
      resetData();
    } else {
      let result = [];
      if (vehicleDetails) {
        result = [...vehicleDetails];
      }
      result.push(vehicleData);
      setVehicleDetails(result);
      setIsOpenVehicleDetailModel(false);
      resetData();
    }
  };

  const handleEditVehicle = (item, index) => {
    setVehicleDataStatus('editVehicle');
    setIsOpenVehicleDetailModel(true);
    setEditedIndex(index);
    setVehicleData({
      regNumber: item?.regNumber,
      brand: item?.brand,
      model: item?.model,
      fuelType: item?.fuelType,
      transmission: item?.transmission,
      chassisNumber: item?.chassisNumber,
      vehicleType: item?.vehicleType,
      registrationDate: moment(item?.registrationDate).format('YYYY-MM-DD'),
      manufactureDate: moment(item?.manufactureDate).format('YYYY-MM-DD')
    });
  };

  const handleRemoveVehicle = (index) => {
    const resultVehicles = vehicleDetails.filter((item, rindex) => {
      return rindex !== index;
    });
    if (resultVehicles?.length === 0) {
      setSendData({ ...sendData, hasVehicle: false });
    }
    setVehicleDetails(resultVehicles);
  };

  const handleCancleVehicle = () => {
    setIsOpenVehicleDetailModel(false);
    resetData();
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    const postData = {
      fullName: sendData?.name,
      mobileNumber: sendData?.phone,
      profileImage: sendData?.profileImage && sendData?.profileImage[0],
      employeeId: sendData?.employeeId,
      email: sendData?.emailId,
      role: sendData?.role,
      officeId: sendData?.office,
      gender: sendData?.gender,
      designation: sendData?.designation,
      departmentId: sendData?.department,
      dateOfBirth: sendData?.dateOfBirth === 'Invalid date' ? null : sendData?.dateOfBirth,
      location: sendData?.location,
      reportingTo: sendData?.reportingTo,
      currentAddress: {
        country: sendData?.country?.name || '',
        city: sendData?.city,
        state: sendData.state?.name || '',
        pinCode: sendData?.pincode,
        address: sendData?.address
      },
      hasVehicles: sendData?.hasVehicle,
      vehiclesOwned: vehicleDetails,
      stateCode: sendData?.stateCode,
      countryIsoCode: sendData?.countryCode
    };
    if (postData.reportingTo === '') {
      delete postData.reportingTo;
    }
    if (emailValidate(sendData?.emailId) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct email'
      });
    } else if (mobileNumberValidate(sendData?.phone) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct mobile number'
      });
    } else if (isValidPincode(sendData?.pincode) === false && sendData?.pincode !== undefined) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct pincode'
      });
    } else {
      if (isEditModal) {
        const result = activated ? { ...postData, activated: true } : postData;
        API_PATCH(`user/updateUser?userId=${data?._id}`, result)
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            handleClose();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      } else {
        API_POST('user/addUser', postData)
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            handleClose();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      }
    }
  };

  const countryData = GetAllCountry();

  return (
    <Container
      direction="column"
      className={classes.newUser}
      sx={{ width: isOpenVehicleDetailModel ? '40vw' : '80vw' }}>
      {isOpenVehicleDetailModel ? (
        <React.Fragment>
          <Typography className={classes.cardHeading} variant="h6">
            {isEditModal ? 'Edit Vehicle Details' : 'Add Vehicle Details'}
          </Typography>
          <form onSubmit={onHandleVehicleDetails}>
            <Item className={classes.formContainer} md={12} xs={12}>
              <Item
                md={12}
                xs={12}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-evenly',
                  gap: '10px',
                  mt: 0.5,
                  maxHeight: '80vh',
                  overflow: 'auto'
                }}>
                <Item md={3.8} xs={12}>
                  <Typography className={classes.inputText}>
                    Vehicle Type<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                      <Select
                        value={vehicleData?.vehicleType}
                        required={true}
                        size="small"
                        onChange={(e) => handleVehicleStateChange('vehicleType', e.target.value)}>
                        {VehicleTypeArray?.map((item, index) => {
                          return (
                            <MenuItem value={item?.value} key={index}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>
                <Item md={3.8} xs={12}>
                  <Typography className={classes.inputText}>Manufacture Date</Typography>
                  <Item mt={0.8}>
                    <TextField
                      type="date"
                      size="small"
                      fullWidth
                      value={vehicleData?.manufactureDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        handleVehicleStateChange('manufactureDate', e.target.value);
                      }}
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12}>
                  <Typography className={classes.inputText}>Registration Date </Typography>
                  <Item mt={0.8}>
                    <TextField
                      type="date"
                      size="small"
                      fullWidth
                      value={vehicleData?.registrationDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        handleVehicleStateChange('registrationDate', e.target.value);
                      }}
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Registration No<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      name="name"
                      size="small"
                      required={true}
                      value={vehicleData.regNumber}
                      onChange={(e) => handleVehicleStateChange('regNumber', e.target.value)}
                      type="text"
                      fullWidth
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Brand<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={vehicleData?.brand}
                        required={true}
                        onChange={(e) => handleVehicleStateChange('brand', e.target.value)}>
                        {brands?.map((item, index) => {
                          return (
                            <MenuItem value={item?.name} key={index}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Model<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required={true}
                      value={vehicleData.model}
                      onChange={(e) => handleVehicleStateChange('model', e.target.value)}
                      type="text"
                      fullWidth
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Fuel Type<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={vehicleData?.fuelType}
                        required={true}
                        size="small"
                        onChange={(e) => handleVehicleStateChange('fuelType', e.target.value)}>
                        {FuelTypeArray?.map((item, index) => {
                          return (
                            <MenuItem value={item?.value} key={index}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Transmission<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={vehicleData?.transmission}
                        required={true}
                        size="small"
                        onChange={(e) => handleVehicleStateChange('transmission', e.target.value)}>
                        {TransmissionTypeArray?.map((item, index) => {
                          return (
                            <MenuItem value={item?.value} key={index}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Chassis No<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={vehicleData.chassisNumber}
                      onChange={(e) => handleVehicleStateChange('chassisNumber', e.target.value)}
                      type="text"
                      fullWidth
                    />
                  </Item>
                </Item>
              </Item>
            </Item>
            <Container container p="10px 20px" pb={3}>
              <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '20px' }}>
                <Item>
                  <Button style={{ ...theme.button.cancel }} onClick={() => handleCancleVehicle()}>
                    Cancel
                  </Button>
                </Item>
                <Item>
                  <Button
                    type="submit"
                    style={{
                      ...theme.button.submit,
                      backgroundColor: theme.palette.primary.main
                    }}>{`Save`}</Button>
                </Item>
              </Item>
            </Container>
          </form>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography className={classes.cardHeading} variant="h6">
            {isEditModal ? 'Update Employee' : 'Add Employee'}
          </Typography>

          <Item className={classes.formContainer} md={12} xs={12}>
            <form onSubmit={onHandleConfirm}>
              <Item md={12} xs={12} className={classes.form}>
                <Item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Item>
                    {sendData?.profileImage?.[0] !== undefined ? (
                      <Item onClick={() => handleInputClick()} sx={{ cursor: 'pointer' }}>
                        <img
                          src={sendData?.profileImage?.[0] || data?.profileImage}
                          alt="profile"
                          className={classes.imgProfile}
                        />
                        <Typography
                          sx={{
                            color: theme.typography.tableHeading.color,
                            fontSize: '12px',
                            textAlign: 'center',
                            mt: 1,
                            ml: -1
                          }}>
                          Upload your photo
                        </Typography>
                      </Item>
                    ) : (
                      <Item
                        className={classes.imageContain}
                        sx={{ flexDirection: 'column' }}
                        onClick={() => handleInputClick()}>
                        <React.Fragment>
                          <img
                            style={{ height: '35px', width: '35px' }}
                            src={
                              require(`../../../../Assets/settingsIcon/profileUpload.svg`).default
                            }
                            alt="icon"
                          />

                          <Typography
                            sx={{
                              color: theme.typography.tableHeading.color,
                              fontSize: '15px',
                              textAlign: 'center',
                              mt: 1
                            }}>
                            Upload your photo
                          </Typography>
                        </React.Fragment>
                      </Item>
                    )}
                    <input
                      ref={hiddenDocumentInput}
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      multiple
                      size="small"
                      onChange={(e) => handleimage('profile', e.target.files)}
                      style={{ display: 'none' }}
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={2}>
                  <Typography className={classes.inputText}>
                    Employee Id<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Enter the employee id"
                      disabled={data?.employeeId}
                      value={sendData.employeeId}
                      required={true}
                      onChange={(e) => handleStateChange('employeeId', e.target.value)}
                      type="text"
                      fullWidth
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={2}>
                  <Typography className={classes.inputText}>
                    Name<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      placeholder="Enter the employee name"
                      size="small"
                      disabled={isEditModal}
                      value={sendData.name}
                      required={true}
                      onChange={(e) => handleStateChange('name', e.target.value)}
                      type="text"
                      fullWidth
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={2}>
                  <Typography className={classes.inputText}>
                    Email<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      placeholder="Enter the email"
                      size="small"
                      disabled={isEditModal}
                      required={true}
                      onBlur={(e) => {
                        if (e.target.value !== '' && emailValidate(sendData?.emailId) === false) {
                          dispatch({
                            type: 'ALERT_OPEN',
                            severity: 'error',
                            isOpen: true,
                            label: 'Email is not valid'
                          });
                        }
                      }}
                      value={sendData.emailId}
                      onChange={(e) => handleStateChange('emailId', e.target.value)}
                      type="email"
                      fullWidth
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Mobile Number<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      placeholder="Enter the phone number"
                      size="small"
                      onBlur={(e) => {
                        if (
                          e.target.value !== '' &&
                          mobileNumberValidate(sendData?.phone) === false
                        ) {
                          dispatch({
                            type: 'ALERT_OPEN',
                            severity: 'error',
                            isOpen: true,
                            label: 'Please enter correct phone number'
                          });
                        }
                      }}
                      value={sendData?.phone}
                      required={true}
                      onChange={(e) => handleStateChange('phone', e.target.value)}
                      type="number"
                      fullWidth
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>Date Of Birth</Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={sendData.dateOfBirth || moment(new Date()).format('YYYY-MM-DD')}
                      onChange={(e) => handleStateChange('dateOfBirth', e.target.value)}
                      type="date"
                      fullWidth
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>Gender</Typography>
                  <Item mt={0.8}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        defaultValue={sendData?.gender}
                        size="small"
                        onChange={(e) => handleStateChange('gender', e.target.value)}>
                        {genderArray?.map((item, index) => {
                          return (
                            <MenuItem value={item?.value} key={index}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>

                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Role<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <FormControl fullWidth size="small">
                      <Select
                        name="Role"
                        size="small"
                        required={true}
                        fullWidth
                        value={sendData?.role}
                        onChange={(e) => {
                          handleStateChange('role', e.target.value);
                        }}>
                        {roles?.map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {`${ConvertToUpperCase(item)}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>

                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>Reporting To</Typography>
                  <Item mt={0.8}>
                    <FormControl fullWidth size="small">
                      <Select
                        fullWidth
                        size="small"
                        value={sendData?.reportingTo}
                        onChange={(e) => {
                          handleStateChange('reportingTo', e.target.value);
                        }}>
                        {allAdmins?.map((item, index) => {
                          return (
                            <MenuItem value={item?._id} key={index}>
                              {`${ConvertToUpperCase(item?.fullName)}, ${ConvertToUpperCase(
                                item?.designation
                              )}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>

                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Branch<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <FormControl fullWidth size="small">
                      <Select
                        fullWidth
                        size="small"
                        required={true}
                        value={sendData?.location}
                        onChange={(e) => {
                          handleStateChange('location', e.target.value);
                        }}>
                        {offices?.map((item, index) => {
                          return (
                            <MenuItem value={item?._id} key={index}>
                              {`${ConvertToUpperCase(item?._id)}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>

                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Office<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <FormControl fullWidth size="small">
                      <Select
                        size="small"
                        required={true}
                        value={sendData?.office}
                        fullWidth
                        onChange={(e) => {
                          handleStateChange('office', e.target.value);
                        }}>
                        {selectedLocationAllOffices?.map((item, index) => {
                          return (
                            <MenuItem value={item?._id} key={index}>
                              {`${ConvertToUpperCase(item?.name)}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>

                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Department<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <FormControl fullWidth size="small">
                      <Select
                        size="small"
                        required={true}
                        value={sendData?.department}
                        onChange={(e) => {
                          handleStateChange('department', e.target.value);
                        }}>
                        {allDepartment?.map((item, index) => {
                          return (
                            <MenuItem value={item?._id} key={index}>
                              {`${ConvertToUpperCase(item?.department)} `}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>

                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>Designation</Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Enter the designation"
                      value={sendData.designation}
                      onChange={(e) => handleStateChange('designation', e.target.value)}
                      type="text"
                      fullWidth
                    />
                  </Item>
                </Item>

                <Item md={7.9} xs={12} mt={3}>
                  <Typography className={classes.inputText}>Address</Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Enter the address"
                      value={sendData.address}
                      onChange={(e) => {
                        handleStateChange('address', e.target.value);
                      }}
                      type="text"
                      fullWidth
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Country<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <Autocomplete
                      size="small"
                      onChange={(e, value) => {
                        if (value === null) {
                          handleStateChange('country', '');
                        }
                        setSendData({
                          ...sendData,
                          countryCode: value?.countryCode
                        });

                        handleStateChange('country', value);
                      }}
                      options={countryData}
                      value={sendData?.country}
                      getOptionLabel={(option) => {
                        return option?.name;
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" type="text" required={true} />
                      )}
                    />
                  </Item>
                </Item>

                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    State<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <Autocomplete
                      onChange={(e, value) => {
                        if (value === null) {
                          handleStateChange('state', '');
                        }
                        setSendData({ ...sendData, stateCode: value?.stateCode });
                        handleStateChange('state', value);
                      }}
                      options={stateData}
                      value={sendData?.state}
                      color="#134CDF"
                      size="small"
                      getOptionLabel={(option) => {
                        return option?.name;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          type="text"
                          size="small"
                          required={true}
                        />
                      )}
                    />
                  </Item>
                </Item>
                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    City<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <Autocomplete
                      id="combo-box-demo"
                      onChange={(e, value) => {
                        if (value === null) {
                          handleStateChange('city', '');
                        }
                        handleStateChange('city', value);
                      }}
                      options={cityData}
                      value={sendData?.city}
                      color="#134CDF"
                      size="small"
                      fullWidth
                      getOptionLabel={(option) => {
                        return option;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          type="text"
                          size="small"
                          required={true}
                        />
                      )}
                    />
                  </Item>
                </Item>

                <Item md={3.8} xs={12} mt={3}>
                  <Typography className={classes.inputText}>
                    Pincode<span className={classes.starIcon}>*</span>
                  </Typography>
                  <Item mt={0.8}>
                    <TextField
                      variant="outlined"
                      name="pincode"
                      placeholder="Enter Pincode"
                      size="small"
                      required={true}
                      value={sendData.pincode}
                      onChange={(e) => handleStateChange('pincode', e.target.value)}
                      type="number"
                      fullWidth
                    />
                  </Item>
                </Item>

                <Item md={7.8} sx={{ minHeight: '4vh', p: 0.5 }} mt={3}>
                  <Item md={12} sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <Item
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '80px'
                      }}>
                      <Typography>Vehicle</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={sendData?.hasVehicle}
                          onChange={(e) => handleStateChange('hasVehicle', e.target.value)}>
                          <FormControlLabel value={true} control={<Radio />} label="Yes" />
                          <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </Item>
                    <Item>
                      {sendData?.hasVehicle === 'true' && (
                        <Item
                          style={{
                            ...theme.button.cancel,
                            width: 'auto',
                            border: 'none',
                            fontWeight: '500'
                          }}
                          onClick={handleAddVehicle}>
                          Add Vehicle Details
                        </Item>
                      )}
                    </Item>
                  </Item>
                  <Item md={12}>
                    {vehicleDetails?.map((item, index) => {
                      return (
                        <Item
                          mt={1}
                          sx={{
                            height: '45px',
                            width: 'auto',
                            boxShadow: '0px 0px 2px grey',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '0px 10px'
                          }}>
                          <Item
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => handleEditVehicle(item, index)}>
                            {item?.regNumber}
                          </Item>
                          <Item
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleRemoveVehicle(index)}>
                            <CancelRoundedIcon
                              style={{ color: theme.typography.tableHeading.color }}
                            />
                          </Item>
                        </Item>
                      );
                    })}
                  </Item>
                </Item>
              </Item>

              <Container container>
                <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '20px', pt: 2 }}>
                  <Item>
                    <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
                      Cancel
                    </Button>
                  </Item>
                  <Item>
                    <Button
                      type="submit"
                      style={{
                        ...theme.button.submit,
                        backgroundColor: theme.palette.primary.main
                      }}>
                      {`Submit`}
                    </Button>
                  </Item>
                </Item>
              </Container>
            </form>
          </Item>
        </React.Fragment>
      )}
    </Container>
  );
};

export default withStyles(styles)(AddOffices);
