import React, { useState, useEffect, useRef } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { Components, Icons } from '../../../../../utils/material-ui';
import tableColor from '../../../../../utils/tableColor';
import InfoModal from '../../../Modal/InfoModal/InfoModal';
import DeleteConfirmation from '../../../Modal/DeleteConfirmation';
import Dialog from '../../../../atom/Dialog';
import theme from '../../../../../utils/theme';
import { API_HANDLE_ERROR, API_GET, API_PATCH, API_UPLOAD_FILE } from '../../../../../utils/api';
import { useStateValue } from '../../../../../utils/store';
import './style.css';
import RemainderModel from '../../../Modal/RemainderModel';
import AddAgent from '../../../Modal/AddAgent';
import AgentsDocsDetailsModel from '../../../Modal/AgentsDocsDetailsModel';
import { getDate, getDateTime } from '../../../../../utils/moment';
import DocsDownloadModel from '../../../Modal/DocsDownloadModel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import DownloadTextFile from '../../../../../utils/DownloadTextFile';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  Grid,
  TextField,
  Button,
  CircularProgress,
  TablePagination,
  Checkbox
} = Components;

const { WatchIcon, AddCircleIcon, ShareIcon, EditIcon, DeleteIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const ListOfAgents = ({ classes, location, sort, region, filter }) => {
  const [, dispatch] = useStateValue();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [checkboxData, setCheckboxData] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalEmployees, setTotalEmployees] = React.useState(0);
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isRemainderModelOpen, setIsRemainderModelOpen] = useState(false);
  const [isOpenAddEmpModal, setIsOpenAddEmpModal] = useState(false);
  const [status, setStatus] = useState('');
  const [editData, setEditData] = useState('');
  const [docsData, setDocsData] = useState({});
  const [isOpenDocsModel, setIsOpenDocsModel] = useState(false);
  const [docsStatus, setDocsStatus] = useState('');
  const [remainderIds, setRemainderIds] = useState([]);
  const [isLoadingDocs, setIsLoadingDocs] = useState(false);
  const [isOpenMultipleDocsModel, setIsOpenMultipleDocsModel] = useState(false);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // get all employees and agent

  const getAllUserDetail = () => {
    const resultRegion = region === 'all' ? '' : `region=${region}`;
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;
    const resultRole = filter === 'all' ? '' : `role=${filter}`;
    API_GET(
      `user/getEmployees?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&${resultRegion}&${resultLocation}&searchText=${searchText}&dynamicSort=${sort}&${resultRole}`
    )
      .then((res) => {
        if (searchText) {
          setPage(0);
        }
        const checkboxObject = {};
        res?.data?.employees?.map((item, index) => (checkboxObject[index] = false));
        setCheckboxData(checkboxObject);
        setSelectAll(false);
        setRows(res?.data?.employees);
        setTotalEmployees(res?.data?.totalEmployees);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllUserDetail();
  }, [dispatch, page, rowsPerPage, searchText, isOpenAddEmpModal, region, location, sort, filter]);

  let selectedCount = 0;
  if (checkboxData) {
    Object.keys(checkboxData).map((item) =>
      checkboxData[`${item}`] === true ? selectedCount++ : selectedCount
    );
  }

  const handleSelectAll = (event) => {
    const boolean = event.target.checked;
    const keys = Object.keys(checkboxData);
    const object = {};
    keys.map((item) => {
      return (object[item] = boolean);
    });
    setCheckboxData(object);
    setSelectAll(boolean);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxData({
      ...checkboxData,
      [event.target.name]: event.target.checked
    });
    setSelectAll(false);
  };

  function pendingDocuments(docs) {
    const data = [
      {
        name: 'Passport',
        count: docs?.numberOfPassportDocs
      },
      {
        name: 'Aadhaar',
        count: docs?.numberOfAadhaarCardDocs
      },
      // {
      //   name: 'Visa',
      //   count: docs?.numberOfVisaDocs
      // }

      {
        name: 'Pan card',
        count: docs?.numberOfPanCardDocs
      }
      // {
      //   name: 'Vaccination',
      //   count: docs?.numberOfVaccineDocs
      // }
    ];
    // const data =
    // store?.corporateId === theme.corporate.mliCorporateId
    //   ? resultData
    //   : [
    //       ...resultData,
    //       {
    //         name: 'Travel Insurance',
    //         count: docs?.numberOfTravelInsuranceDocs
    //       },
    //       {
    //         name: 'Health Insurance',
    //         count: docs?.numberOfHealthInsuranceDocs
    //       }
    //     ];

    const resData = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i].count === 0 || data[i].count === undefined) {
        resData.push(data[i]);
      }
    }

    return (
      <Container
        direction="column"
        wrap="nowrap"
        className={classes.popupContainer}
        sx={{ width: '30vw' }}>
        <Item className={classes.title}>
          <Typography variant="h5" sx={{ fontWeight: '600' }}>
            Pending Document
          </Typography>
        </Item>
        <Item
          p={1}
          className="infoTable"
          style={{ borderColor: `1px solid ${theme.palette.primary.main}` }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell sx={{ fontSize: '18px', textAlign: 'center' }}>Sr No.</TableCell>
                <TableCell sx={{ fontSize: '18px', textAlign: 'center' }}>Doc Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resData?.map((item, index) => {
                return (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{item?.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Item>
      </Container>
    );
  }

  function toBeExpiredDocuments(data) {
    return (
      <Container direction="column" wrap="nowrap" className={classes.popupContainer}>
        <Item className={classes.title}>
          <Typography variant="h5" sx={{ fontWeight: '600' }}>
            To Be Expired Document
          </Typography>
        </Item>
        <Item
          p={1}
          className="infoTable"
          style={{ borderColor: `1px solid ${theme.palette.primary.main}` }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell>Doc Type</TableCell>
                <TableCell>Expiry Date</TableCell>
                <TableCell>Days left</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{getDate(item?.expiryDate)}</TableCell>
                    <TableCell>{item?.daysLeft}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Item>
      </Container>
    );
  }

  function expiredDocuments(data) {
    return (
      <Container direction="column" wrap="nowrap" className={classes.popupContainer}>
        <Item className={classes.title}>
          <Typography variant="h5" sx={{ fontWeight: '600' }}>
            Expired Document
          </Typography>
        </Item>
        <Item
          p={1}
          className="infoTable"
          style={{ borderColor: `1px solid ${theme.palette.primary.main}` }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell sx={{ color: 'black', fontSize: '18px' }}>Doc Type</TableCell>
                <TableCell sx={{ color: 'black', fontSize: '18px' }}>Expired On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{getDate(item?.expiryDate)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Item>
      </Container>
    );
  }

  const addressData = (currentAddress = '', permanentAddress = '--') => {
    return (
      <Container
        direction="column"
        wrap="nowrap"
        className={classes.popupContainer}
        sx={{ minWidth: '20vw' }}>
        <Item className={classes.title}>
          <Typography variant="h5" sx={{ fontWeight: '600' }}>
            Address
          </Typography>
        </Item>
        <Item p={1}>
          <Typography sx={{ fontSize: '16px' }}>Current Add. :{currentAddress}</Typography>
          <Typography sx={{ fontSize: '16px' }}>Permanent Add. : {permanentAddress}</Typography>
        </Item>
      </Container>
    );
  };

  const handleRemove = (item) => {
    setIsOpenDeleteModel(true);
    setDeleteId(item?._id);
  };

  function handleClose() {
    setIsOpenDeleteModel(false);
    setIsRemainderModelOpen(false);
    setIsOpenAddEmpModal(false);
    setIsOpenDocsModel(false);
    setIsOpenMultipleDocsModel(false);
  }

  const handleDocsModel = (status, data) => {
    setIsOpenDocsModel(true);
    setDocsData(data);
    setDocsStatus(status);
  };

  function handleOpenAgentModal(reStatus, row) {
    setIsOpenAddEmpModal(true);
    setStatus(reStatus);
    setEditData(row);
  }

  const EditAgentStatus = () => {
    setStatus('edit');
  };

  // Remainder notification

  const handleRemainder = () => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const approveDeclinedIds = filteredKeys.map((item) => rows[item]._id).flat();
    if (approveDeclinedIds.length > 0) {
      setIsRemainderModelOpen(true);
      setRemainderIds(approveDeclinedIds);
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Select atleast one Entry !'
      });
    }
  };

  // import file

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    uploadfile(fileUploaded);
    event.target.value = '';
  };

  const uploadfile = (fileUploaded) => {
    setLoading(true);
    API_UPLOAD_FILE(`travelPackage/importAgents`, fileUploaded)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success',
          time: 10000
        });
        getAllUserDetail();
        setLoading(false);
        if (res?.errors.length > 0) {
          const errorText = res?.errors
            ?.map((error) => `Row ${error.row}: ${error.errorMessage}\n`)
            .join('');
          DownloadTextFile(errorText);
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
        setLoading(false);
      });
  };

  // delete item

  const onHandleCancel = (deletedItem) => {
    API_PATCH(`user/deleteUser`, { userIds: [deletedItem] })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        setTimeout(() => {
          getAllUserDetail();
        }, 1000);
        setIsOpenDeleteModel(false);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleDownload = () => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const selectedEmployeeData = filteredKeys.map((item) => rows[item]?._id).flat();
    let str = '';
    for (const id of selectedEmployeeData) {
      str += `&userIds[]=${id}`;
    }
    API_GET(`travelpackage/downloadDocumentReport?${str}`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res?.message || 'Success'
        });
        setIsActive(false);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
        setIsActive(false);
      });
  };

  // docs loader

  const handleLoader = (status) => {
    setIsLoadingDocs(status);
  };

  // handleMultiple docs Download

  const handleMultipleDocsDownload = () => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const selectedEmployeeData = filteredKeys.map((item) => rows[item]).flat();
    if (selectedEmployeeData.length === 0) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Select atleast one Entry !'
      });
      setIsActive(false);
    } else if (selectedEmployeeData.length > 20) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'For documents download min. 1 and max. 20 employees/agents must be selected.'
      });
      setIsActive(false);
    } else {
      setIsOpenMultipleDocsModel(true);
      setIsActive(false);
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.root} sx={theme.tableBorder}>
        <Item className={classes.filterBar}>
          <Item>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: '600'
              }}>{`List of Agents/Employees`}</Typography>
            <Typography sx={{ fontSize: '12px' }} variant="h4">
              {`Total no of agents and employee (${totalEmployees})`}
            </Typography>
          </Item>
          <Item>
            <TextField
              id="outlined-basic"
              label="Search by Id and Name"
              size="small"
              value={searchText}
              variant="outlined"
              sx={{ width: '325px' }}
              fullWidth
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Item>
          <Item sx={{ display: 'flex', gap: '10px' }}>
            <WatchIcon className={classes.icon} fontSize="large" onClick={handleRemainder} />

            {loading ? (
              <Button
                size="small"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white
                }}>
                <CircularProgress sx={{ color: '#FFFFFF' }} size={20} />
                Importing
              </Button>
            ) : (
              <ShareIcon className={classes.icon} fontSize="large" onClick={handleClick} />
            )}
            <AddCircleIcon
              className={classes.icon}
              fontSize="large"
              onClick={() => handleOpenAgentModal('add', '')}
            />

            <div className="menu-container">
              <button
                onClick={() => setIsActive(!isActive)}
                className="menu-trigger"
                style={{
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`
                }}>
                <span>Download</span>
                <div
                  className="menu-Icon"
                  style={{ color: '#FFFFFF', backgroundColor: theme.palette.primary.main }}>
                  {isActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </div>
              </button>
              <Item ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
                <ul>
                  <li>
                    <Item className="list" onClick={handleDownload}>
                      Excel File
                    </Item>
                  </li>
                  <li>
                    <Item className="list" onClick={handleMultipleDocsDownload}>
                      Documents
                    </Item>
                  </li>
                </ul>
              </Item>
            </div>

            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
              accept=".xlsx, .xls, .csv"
            />
          </Item>
        </Item>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.head}>
              <TableRow>
                {columns &&
                  columns.map((columns) => (
                    <StyledTableCell
                      key={columns.id}
                      align={columns.align}
                      style={{ minWidth: columns.minWidth }}>
                      {columns.id === 'select' ? (
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          style={{ color: 'white' }}
                        />
                      ) : (
                        columns.label
                      )}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {'No Records found'}
                  </TableCell>
                </TableRow>
              )}
              {rows &&
                rows?.map((row, rowindex) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowindex}
                      style={{ backgroundColor: tableColor(rowindex) }}>
                      {columns &&
                        columns.map((column, index) => {
                          const value = row[column.id] ?? '-';

                          if (column.id === 'uniqueId') {
                            return (
                              <StyledTableCell key={index} align="center">
                                {row?.role !== 'agent' ? (
                                  <Item
                                    sx={{
                                      textDecoration: 'underline',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handleDocsModel('Emp Id', row)}>
                                    {row?.employeeId || row?.uniqueId}
                                  </Item>
                                ) : (
                                  <Item>-</Item>
                                )}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'updatedAt') {
                            return (
                              <StyledTableCell key={index} align="center">
                                {row?.updatedAt && getDateTime(row?.updatedAt)}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'agentId') {
                            return (
                              <StyledTableCell key={index} align="center">
                                {row?.role === 'agent' ? (
                                  <Item
                                    sx={{
                                      textDecoration: 'underline',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handleDocsModel('Agent Id', row)}>
                                    {row?.agentId}
                                  </Item>
                                ) : (
                                  <Item>-</Item>
                                )}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'select') {
                            return (
                              <StyledTableCell align="left" key={column.id}>
                                <Checkbox
                                  color="primary"
                                  checked={checkboxData[`${rowindex}`]}
                                  onChange={handleCheckboxChange}
                                  name={`${rowindex}`}
                                />
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'currentAddress') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                <InfoModal
                                  component={addressData(
                                    value?.address,
                                    row?.permanentAddress?.address
                                  )}
                                  item={
                                    <img
                                      width={'20px'}
                                      height={'20px'}
                                      src="https://cdn-icons-png.flaticon.com/512/63/63830.png"
                                      alt="iugsdf"
                                    />
                                  }
                                />
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'currentCity') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.currentAddress?.city || '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'action') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                <EditIcon
                                  onClick={() => {
                                    handleOpenAgentModal('edit', row);
                                  }}
                                  sx={{ cursor: 'pointer' }}
                                />
                                <DeleteIcon
                                  onClick={() => {
                                    handleRemove(row);
                                  }}
                                  sx={{ color: 'red', cursor: 'pointer' }}
                                />
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'pending') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                <InfoModal
                                  component={pendingDocuments(row?.docsCount)}
                                  item={
                                    Object.keys(row?.docsCount).length === 0 || !row?.docsCount
                                      ? 3
                                      : row?.docsCount?.pendingDocsCount
                                  }
                                />
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'toBeExpireDocs') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.docsCount?.toBeExpiredDocsCount ? (
                                  <InfoModal
                                    component={toBeExpiredDocuments(row?.toBeExpiredDocsList)}
                                    item={row?.docsCount?.toBeExpiredDocsCount}
                                  />
                                ) : (
                                  '-'
                                )}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'expired') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.docsCount?.expiredDocsCount ? (
                                  <InfoModal
                                    component={expiredDocuments(row?.expiredDocsList)}
                                    item={row?.docsCount?.expiredDocsCount}
                                  />
                                ) : (
                                  '-'
                                )}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'email') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {value} <br />
                                {row?.alternateEmail ? `${row?.alternateEmail}` : ''}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'nominated') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.nominatedCount || 0}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'vaccineStatus') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.currentVaccinationStatus || '-'}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'mobileNumber') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {value} <br />
                                {row?.alternateNumber ? `${row?.alternateNumber}` : ''}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'gender') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {ConvertToUpperCase(value)}
                                {row?.dateOfBirth ? `/${row?.age || '-'}` : ''}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'tShirtSize') {
                            return (
                              <StyledTableCell
                                sx={{ textTransform: 'uppercase' }}
                                key={column.id}
                                align={column.align}>
                                {value}
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              sx={{ textTransform: 'capitalize' }}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalEmployees && totalEmployees ? totalEmployees : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Dialog
          isOpen={isOpenDeleteModel}
          children={
            <DeleteConfirmation
              handleClose={handleClose}
              deleteId={deleteId}
              onHandleDelete={onHandleCancel}
            />
          }></Dialog>
        <Dialog
          isOpen={isRemainderModelOpen}
          children={
            <RemainderModel handleClose={handleClose} remainderIds={remainderIds} />
          }></Dialog>
        <Dialog
          isOpen={isOpenMultipleDocsModel}
          children={
            <DocsDownloadModel
              handleClose={handleClose}
              checkboxData={checkboxData}
              rows={rows}
              handleLoader={handleLoader}
              isLoadingDocs={isLoadingDocs}
            />
          }></Dialog>
        <Dialog
          isOpen={isOpenDocsModel}
          children={
            <AgentsDocsDetailsModel
              handleClose={handleClose}
              docsData={docsData}
              docsStatus={docsStatus}
            />
          }></Dialog>
        <Dialog
          isOpen={isOpenAddEmpModal}
          fullWidth={true}
          maxWidth={'xl'}
          children={
            <AddAgent
              handleClose={handleClose}
              status={status}
              data={editData}
              EditAgentStatus={EditAgentStatus}
            />
          }></Dialog>
      </Card>
    </React.Fragment>
  );
};

export default withStyles(styles)(ListOfAgents);
