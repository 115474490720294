import React, { useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import CountsCard from './countsCard';
import Agent from './Agents/Agents';
// import Mismatched from './Mismatched/Mismatched';
import Packages from './Pakages/Packages';
import Nominated from './Nominated/Nominated';
// import Overview from './OverviewOld/Overview';
import Overview from './Overview/Overview';

const { withStyles, Grid, Box } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const TravelManegement = ({ classes }) => {
  const cards = [
    { value: 'Overview', label: 'Overview', table: Overview },
    { value: 'agents', label: 'Agent', table: Agent },
    // { value: 'mismatched', label: 'Mismatched', table: Mismatched },
    { value: 'packages', label: 'Groups', table: Packages },
    { value: 'nominated', label: 'Nominated', table: Nominated }
  ];
  const [selectedCard, setSelectedCard] = useState(cards[0]);

  return (
    <Container direction="column" className={classes.mainContainer}>
      <Box sx={{ flexGrow: 1 }}>
        <Container justify="space-between" alignItems="center" spacing={2} mt={0.1}>
          {cards.map((item) => (
            <Item key={item.value}>
              <CountsCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
            </Item>
          ))}
        </Container>
      </Box>
      <Item xs={12}>
        <selectedCard.table></selectedCard.table>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(TravelManegement);
