import React, { useState, useEffect } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import InfiniteScroll from 'react-infinite-scroller';
import { API_HANDLE_ERROR, API_GET, API_PATCH } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import ListItem from './ListItem';

const { withStyles, Grid, CircularProgress, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

function InfiniteList({ classes, handleNotification, handleTractNotifation }) {
  const [, dispatch] = useStateValue();
  const [notifications, setNotifications] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [status, setStatus] = useState(false);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);

  // let currentOffset = 0;

  const getTwentyNotifications = () => {
    const TwentyNotifications = [];
    API_GET(`notification/getPushNotification?limit=5&skip=${skip}`)
      .then((res) => {
        res?.data?.notifications?.forEach((p) => TwentyNotifications.push(p));
        const resultData = [...notifications, ...TwentyNotifications];
        setNotifications(resultData);
        setSkip(skip + 1);
        setHasMore(res.data.hasMore);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const getNotificationCount = () => {
    API_GET(`notification/getPushNotification`)
      .then((res) => {
        setCount(res?.data?.unreadNotificationCount);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getNotificationCount();
  }, [status]);

  const handleRemove = (item) => {
    item?._id &&
      API_PATCH(`notification/markNotificationAsRead?notificationId=${item?._id}`)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          const result = notifications?.filter((notification) => {
            return notification?._id !== item?._id;
          });
          setNotifications(result);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };
  const handleAllRemove = () => {
    API_PATCH(`notification/markAllNotificationAsRead`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        setNotifications([]);
        setStatus(!status);
        handleTractNotifation();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className={classes.notificationHeading}>Notifications</Typography>
        {count === 0 ? (
          ''
        ) : (
          <Typography
            className={classes.notificationHeading}
            sx={{ fontSize: '13px', cursor: 'pointer' }}
            onClick={() => handleAllRemove()}>
            Mark all as read
          </Typography>
        )}
      </Item>

      <Item className={classes.notificationContainer}>
        <InfiniteScroll
          pageStart={0}
          loadMore={getTwentyNotifications}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0} style={{ margin: '10px 45%' }}>
              <CircularProgress />
            </div>
          }
          useWindow={false}>
          {notifications?.map((item, index) => {
            return (
              <ListItem
                item={item}
                index={index}
                key={index}
                handleNotification={handleNotification}
                handleRemove={handleRemove}
              />
            );
          })}
        </InfiniteScroll>
      </Item>
    </React.Fragment>
  );
}

export default withStyles(styles)(InfiniteList);
