import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../utils/store';
import { API_WELLNESS_GET, API_HANDLE_ERROR } from '../../utils/api';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';

const { withStyles, Grid, Typography, Select, MenuItem } = Components;

const { ArrowBackIosIcon, ArrowForwardIosIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

// const graphData = [
//   {
//     date: 'Sun',
//     count: 12
//   },
//   {
//     date: 'Mon',
//     count: 18
//   },
//   {
//     date: 'Tue',
//     count: 14
//   },
//   {
//     date: 'Wed',
//     count: 12
//   },
//   {
//     date: 'Thus',
//     count: 11
//   },
//   {
//     date: 'Fri',
//     count: 2
//   },
//   {
//     date: 'Sat',
//     count: 16
//   }
// ];

const BarGraph = ({ classes, tracker }) => {
  const [, dispatch] = useStateValue();
  const [data, setData] = useState([]);
  const [selectValue, setSelectValue] = useState('week');
  const [skip, setSkip] = useState(0);
  const [limit] = useState(7);
  const [totalCount, setTotalCount] = useState(0);

  // console.log(data);

  useEffect(() => {
    API_WELLNESS_GET(
      `wellness/getGenericTrackerData?key=${tracker.type}&interval=${selectValue}&skip=${skip}&limit=${limit}`
    )
      .then((res) => {
        setData(res?.data);
        setTotalCount(res?.count);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [selectValue, skip, limit]);

  const handlePre = () => {
    if (skip === 0) {
      setSkip(0);
    } else {
      setSkip(skip - 1);
    }
  };
  const handleNext = () => {
    setSkip(skip + 1);
  };

  return (
    <Item md={3.8} xs={12}>
      <Typography sx={{ color: tracker?.textColor, fontSize: '0.9rem', fontWeight: '600' }}>
        {tracker?.name}
      </Typography>
      <Item
        mt={1}
        sx={{
          borderRadius: '10px',
          boxShadow: '0px 0px 5.8325px rgba(0, 0, 0, 0.25)',
          p: 1,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <Select
            value={selectValue}
            onChange={(e) => setSelectValue(e.target.value)}
            style={{
              fontSize: '14px',
              borderRadius: '5px',
              height: '25px',
              padding: '5px 0px',
              border: '1px solid lightGrey'
            }}>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="week">Last 7 Days</MenuItem>
            <MenuItem value="month">Last 30 Days</MenuItem>
          </Select>
        </Item>
        <Item sx={{ height: '230px' }}>
          <ResponsiveBar
            data={data || []}
            keys={['count']}
            indexBy="date"
            margin={{ top: 30, right: 0, bottom: 40, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={tracker?.barColor}
            enableLabel={false}
            borderRadius={3}
            gridYValues={4}
            enableGridY={true}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: tracker?.verticalText,
              legendPosition: 'middle',
              legendOffset: -55
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              format: (value) => moment(value).format('DD MMM'),
              tickValues: 'every 1 month',
              tickFormat: (value) => value
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 11,
                    fill: '#001F45'
                  }
                }
              }
            }}
          />
        </Item>
        <Item
          sx={{
            display: 'flex',
            gap: '10px',
            m: 0.5,
            alignItem: 'center'
          }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto', fontSize: '12px' }}>
            {totalCount === 0
              ? `${skip}-${0} of ${totalCount}`
              : `${skip * limit + 1}-${
                  skip * limit + limit >= totalCount ? totalCount : skip * limit + limit
                } of ${totalCount}`}
          </Item>
          <Item>
            {skip === 0 ? (
              <ArrowBackIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowBackIosIcon fontSize="10px" sx={{ cursor: 'pointer' }} onClick={handlePre} />
            )}
          </Item>
          <Item>
            {totalCount - skip * limit < 10 ? (
              <ArrowForwardIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowForwardIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleNext}
              />
            )}
          </Item>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(BarGraph);
