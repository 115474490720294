import React from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';

const { withStyles, Typography, Grid, Button } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function DeleteModal({ classes, handleClose, deleteId, onHandleDelete }) {
  return (
    <Container direction="column" className={classes.newUser}>
      <Item>
        <Typography variant="body2" sx={{ fontSize: '19px', p: 1 }}>
          Are you sure you want to delete?
        </Typography>
      </Item>
      <Item>
        <Item sx={{ display: 'flex', gap: '15px', mt: 2 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button onClick={() => handleClose()} style={{ ...theme.button.cancel }}>
              NO
            </Button>
          </Item>
          <Item>
            <Button
              type="submit"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}
              onClick={() => {
                onHandleDelete(deleteId);
              }}>
              YES
            </Button>
          </Item>
        </Item>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(DeleteModal);
