import React from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import CircularProgress from '@mui/material/CircularProgress';

const { withStyles, Grid, Typography, Button } = Components;

const { SendIcon, CloseIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const ReplySection = ({
  selectedFiles,
  handleFileRemove,
  chatData,
  handleAddBySearch,
  handleFileInput,
  hiddenFileInput,
  handleFile,
  handleStateChange,
  inputText,
  isEdit,
  isLoading
}) => {
  const getButtonStyle = () => {
    const style = {
      color: theme.palette.primary.tableHeadingColor,
      fontSize: '30',
      marginTop: '0.15%',
      cursor: 'pointer'
    };
    if (isEdit) {
      style.backgroundColor = theme.palette.primary.main;
      style.color = 'white';
      style.height = '25px';
      style.fontSize = '15';
    }
    return style;
  };
  return (
    <Container>
      {selectedFiles[0] && (
        <Item sx={{ width: '94%', m: '2% 3%' }}>
          <Container gap={1}>
            {selectedFiles &&
              selectedFiles.length > 0 &&
              selectedFiles.map((item, index) => {
                return (
                  <Item
                    key={index}
                    sx={{
                      height: 'auto',
                      border: '1px solid lightGrey',
                      borderRadius: '10px',
                      p: 1,
                      backgroundColor: '#FFFFFF',
                      fontSize: '11px',
                      fontWeight: '600',
                      lineHeight: '15px',
                      display: 'flex'
                    }}
                    alignItems={'center'}>
                    <Typography variant="subtitle1" noWrap={true} style={{ maxWidth: '300px' }}>
                      {item.name}
                    </Typography>
                    <CloseIcon
                      onClick={() => handleFileRemove(index)}
                      style={{ color: 'grey', fontSize: '15px', marginLeft: '5px' }}
                    />
                  </Item>
                );
              })}
          </Container>
        </Item>
      )}
      <Item sx={{ height: '8vh', width: '94%', m: '2% 3%' }}>
        {chatData && chatData?.incidentActionStatus === 'completed' ? (
          ''
        ) : (
          <form
            onSubmit={handleAddBySearch}
            style={{
              border: '1px solid grey',
              fontSize: '17px',
              borderRadius: '15px',
              display: 'flex'
            }}>
            <Container justify="space-between" alignItems="center" spacing={1}>
              <Item xs={1.5} onClick={handleFileInput}>
                <img
                  src="https://i.ibb.co/ch5t9S3/Vector-2.png"
                  alt="cc"
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px'
                  }}
                />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  accept=".xls,.xlsx,.pdf,.ppt,.pptx,.doc,.docx,.jpg,.jpeg,.webp,.png"
                  multiple
                  onChange={handleFile}
                  style={{ display: 'none' }}
                />
              </Item>
              <Item xs={isEdit ? 7.5 : 8.5}>
                <input
                  onChange={(e) => handleStateChange(e)}
                  value={inputText}
                  style={{
                    height: '30px',
                    width: '100%',
                    border: 'none',
                    outline: 'none'
                  }}
                  placeholder="Remarks"
                />
              </Item>
              <Item xs={isEdit ? 2.5 : 1.5}>
                {isLoading ? (
                  <CircularProgress size={25} style={{ marginTop: '0.20%', marginLeft: '15px' }} />
                ) : (
                  <Button type="submit" style={getButtonStyle()}>
                    {isEdit ? 'Done' : <SendIcon onClick={handleAddBySearch} />}
                  </Button>
                )}
              </Item>
            </Container>
          </form>
        )}
      </Item>
    </Container>
  );
};

export default withStyles(styles)(ReplySection);
