import React from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';

const { withStyles, Grid, Typography, useMediaQuery } = Components;
const { CancelRoundedIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

const SampleDocsFile = ({ classes, handleSampleDocsClose, sampleDocsType }) => {
  const scrrenSizeSmall = useMediaQuery(theme.breakpoints.up('sm'));

  function aadharPhoto() {
    return (
      <Item md={12} xs={12}>
        <Typography variant="h6" sx={{ fontWeight: '600' }}>
          Aadhaar card Sample file
        </Typography>
        <Item className={classes.mainContainer} m={1}>
          <Item md={5} xs={12} sx={{ height: '200px' }}>
            <img
              height="100%"
              width="100%"
              src={require(`../../../../Assets/TravelIcon/aadhaarFront.svg`).default}
              alt="front"
            />
          </Item>
          <Item md={5} xs={12} sx={{ height: '200px' }}>
            <img
              height="100%"
              width="100%"
              src={require(`../../../../Assets/TravelIcon/aadhaarback.svg`).default}
              alt="front"
            />
          </Item>
        </Item>
      </Item>
    );
  }
  function panPhoto() {
    return (
      <Item md={12} xs={12}>
        <Typography variant="h6" sx={{ fontWeight: '600' }}>
          Pan card Sample file
        </Typography>
        <Item className={classes.mainContainer} m={1}>
          <Item md={5} xs={12} sx={{ height: '200px' }}>
            <img
              height="100%"
              width="100%"
              src={require(`../../../../Assets/TravelIcon/pancard.svg`).default}
              alt="front"
            />
          </Item>
        </Item>
      </Item>
    );
  }
  function passportPhoto() {
    return (
      <Item md={12} xs={12}>
        <Typography variant="h6" sx={{ fontWeight: '600' }}>
          Passport Sample file
        </Typography>
        <Item className={classes.mainContainer} m={1}>
          <Item md={5} xs={12} sx={{ height: '200px' }}>
            <img
              height="100%"
              width="100%"
              src={require(`../../../../Assets/TravelIcon/passportFront.svg`).default}
              alt="front"
            />
          </Item>
          <Item md={5} xs={12} sx={{ height: '200px' }}>
            <img
              height="100%"
              width="100%"
              src={require(`../../../../Assets/TravelIcon/passportBack.svg`).default}
              alt="front"
            />
          </Item>
        </Item>
      </Item>
    );
  }

  function samplePhoto(name) {
    return (
      <Item md={12} xs={12}>
        {name === 'aadhaar' ? aadharPhoto() : name === 'pancard' ? panPhoto() : passportPhoto()}
        <Item>
          <Typography variant="h7" sx={{ fontWeight: '600' }}>
            Steps to upload file
          </Typography>
          <ol>
            <li>Click "Upload" to select your file.</li>
            <li>{` Once you have chosen the files you must select
          "Upload File(s)" to successfully complete the process.`}</li>
          </ol>

          <Typography variant="h7" sx={{ fontWeight: '600' }}>
            Guidelines
          </Typography>
          <Typography>
            Must be a valid photo identity card clearly showing the all the details and photo of
            candidate.
          </Typography>
        </Item>
      </Item>
    );
  }

  function ProfileImage() {
    return (
      <Item md={12} xs={12}>
        <Typography variant="h6" sx={{ fontWeight: '600' }}>
          Profile Sample file
        </Typography>
        <Item className={classes.mainContainer} m={1}>
          <Item sx={{ height: '230px', width: '180px' }}>
            <img
              height="100%"
              width="100%"
              src={require(`../../../../Assets/TravelIcon/ProfilePhoto.jpeg`).default}
              alt="front"
            />
          </Item>
        </Item>
        <Item>
          <Typography variant="h7" sx={{ fontWeight: '600' }}>
            Steps to upload file
          </Typography>
          <ol>
            <li>Click "Upload File" to select your file.</li>
            <li>{` Once you have chosen the files you must select
          "Upload File(s)" to successfully complete the process.`}</li>
          </ol>

          <Typography variant="h7" sx={{ fontWeight: '600' }}>
            Guidelines
          </Typography>
          <Typography>
            <ol>
              <li>The photo print should be clear and with a continuous tone quality. </li>
              <li>It should have full face, front view, eyes open.</li>
              <li>The Photo should present full head from top of hair to bottom of chin.</li>
              <li>Centre head within frame.</li>
              <li>The background should be a plain white or off-white.</li>
              <li>There should not be any distracting shadows on the face or on the background.</li>
              <li>
                Head coverings are not permitted except for religious reasons, but the facial
                features from bottom of chin to top of forehead and both edges of the face must be
                clearly shown.
              </li>
              <li>
                The expression on the face should look natural. Have someone else take your photo.
                No selfies.
              </li>
              <li> Take off your eyeglasses for your photo.</li>
            </ol>
          </Typography>
        </Item>
      </Item>
    );
  }

  const render = (name) => {
    let content = null;
    switch (name) {
      case 'aadhaar':
        content = <div>{samplePhoto(name)}</div>;
        break;
      case 'pancard':
        content = <div>{samplePhoto(name)}</div>;
        break;
      case 'passport':
        content = <div>{samplePhoto(name)}</div>;
        break;
      case 'photo':
        content = <div>{ProfileImage()}</div>;
        break;

      default:
        content = <div>Error</div>;
        break;
    }
    return content;
  };

  return (
    <React.Fragment>
      <Item sx={{ display: 'flex' }} md={12} xs={12}>
        <Item sx={{ display: 'inline-flex', ml: 'auto', cursor: 'pointer' }}>
          <CancelRoundedIcon onClick={() => handleSampleDocsClose()} />
        </Item>
      </Item>
      <Item
        md={12}
        xs={12}
        sx={{
          p: '10px 30px',
          maxHeight: '90vh',
          overflow: 'auto',
          maxWidth: scrrenSizeSmall ? '70vw' : '100vw'
        }}>
        {render(sampleDocsType)}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(SampleDocsFile);
