import React, { useState, useEffect } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import CountsCard from './countsCard';
import PendingStatus from './PendingStatus';
import ApprovedStatus from './ApprovedStatus';
import DeclinedStatus from './DeclinedStatus';
import DoctorProfile from './DoctorProfile';
import { useLocation } from 'react-router';
import DeletedDoctor from './DeletedDoctor';

const { withStyles, Grid, Box } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Alerts = ({ classes }) => {
  const cards = [
    { value: 'pending', label: 'Pending', table: PendingStatus },
    { value: 'approved', label: 'Approved', table: ApprovedStatus },
    { value: 'declined', label: 'Declined', table: DeclinedStatus },
    { value: 'docProfile', label: 'Dr. Profile', table: DoctorProfile },
    { value: 'deletedDoctor', label: 'Deleted Doctor', table: DeletedDoctor }
  ];

  const [selectedCard, setSelectedCard] = useState(cards[0]);

  const getSearch = useLocation().search;
  const name = new URLSearchParams(getSearch).get('tab');

  useEffect(() => {
    if (name) {
      cards?.map((item) => {
        if (item?.value === name) {
          setSelectedCard(item);
        }
        return null;
      });
    }
  }, []);

  return (
    <Container direction="column" className={classes.mainContainer}>
      <Box sx={{ flexGrow: 1 }}>
        <Container justify="space-between" alignItems="center" spacing={2} sx={{ mt: 0.5 }}>
          {cards.map((item) => (
            <Item key={item.value}>
              <CountsCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
            </Item>
          ))}
        </Container>
      </Box>

      <Item xs={12}>
        <selectedCard.table></selectedCard.table>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Alerts);
