const head = [
  {
    id: 'eventDate',
    label: 'Event Date & Time',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'createdAt',
    label: 'Pub. Date&Time',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'title',
    label: 'Incident',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'left'
  },

  {
    id: 'location',
    label: 'Location',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  }
];

export default head;
