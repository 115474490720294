import React, { useState } from 'react';
import { Components } from '../../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../../utils/theme';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import { CreateMultipleTime } from '../../../../../utils/moment';
import moment from 'moment';
import { API_PATCH, API_HANDLE_ERROR } from '../../../../../utils/api';
import { useStateValue } from '../../../../../utils/store';

const {
  withStyles,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  TextField,
  FormControlLabel
  // Radio,
  // RadioGroup
} = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

// const lifeSpanArray = [
//   { name: 'Forever', value: 'forever' },
//   { name: 'For 1 hour', value: 1 },
//   { name: 'For 2 hour', value: 2 },
//   { name: 'For 4 hour', value: 4 },
//   { name: 'For 6 hour', value: 6 },
//   { name: 'For 8 hour', value: 8 }
// ];

const repeatArray = [
  { name: 'Daily', value: 'daily' },
  { name: 'Week Days', value: 'week' }
];
function ScheduledEdit({
  classes,
  handleClose,
  data,
  getAllScheduled,
  type,
  getAllActiveBroadcast
}) {
  const [, dispatch] = useStateValue();

  const resultSeduledTime = data?.scheduledDateTime?.split('T')?.[1];
  const resultRepeateOption = Array.isArray(data?.repeatOptions)
    ? data?.repeatOptions?.[0]
    : 'week';

  const [sendData, setSendData] = useState({
    timeStatus: data?.isRecurring ? 'recurring' : 'later',
    lifeSpan: data?.lifeSpan || 'forever',
    laterDate: data?.scheduledDateTime ? moment(data?.scheduledDateTime).format('YYYY-MM-DD') : '',
    laterTime: data?.scheduledDateTime ? resultSeduledTime : '',
    recurringRepeat: resultRepeateOption === 'daily' ? 'daily' : 'week',
    recurringStartDate: data?.recurringStartDate || '',
    recurringEndDate: data?.recurringEndDate || '',
    recurringTime: data?.sendTime || ''
  });

  const [weekDays, setWeekDays] = useState({
    Sun: data?.repeatOptions?.includes(0) || false,
    Mon: data?.repeatOptions?.includes(1) || false,
    Tue: data?.repeatOptions?.includes(2) || false,
    Wed: data?.repeatOptions?.includes(3) || false,
    Thur: data?.repeatOptions?.includes(4) || false,
    Fri: data?.repeatOptions?.includes(5) || false,
    Sat: data?.repeatOptions?.includes(6) || false
  });

  const [checkData, setCheckData] = useState({
    // 'Send as Anonymous Message': data?.isAnonymous || false,
    // 'Key Management Employees': data?.oneTimeKeyManagement || false,
    // 'Add to template': false,
    'Include Family member': data?.includeFamilyMembers || false
  });

  const handleStateChange = (key, value) => {
    setSendData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const handleCheckData = (key, value) => {
    setCheckData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };
  const handleDailyCheckData = (key, value) => {
    setWeekDays((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    const resultSeduledTime = `${sendData.laterDate}T${sendData.laterTime}`;
    const resultweekNumber = [];

    Object.keys(weekDays)?.map((item, index) => {
      if (weekDays[item]) {
        resultweekNumber.push(index);
      }
      return null;
    });
    const resultRepeatOption = sendData.recurringRepeat === 'daily' ? ['daily'] : resultweekNumber;

    const recurringObj = {
      includeFamilyMembers: checkData['Include Family member'],
      repeatOptions: resultRepeatOption,
      startDate: sendData.recurringStartDate,
      endDate: sendData.recurringEndDate,
      sendTime: sendData.recurringTime
    };
    const scheduledObj = {
      scheduledDateTime: resultSeduledTime,
      includeFamilyMembers: checkData['Include Family member']
    };

    const resultPostObj = sendData?.timeStatus === 'recurring' ? recurringObj : scheduledObj;
    const resultRecc = sendData?.timeStatus === 'recurring' ? `isRecurring=true` : '';
    API_PATCH(
      `broadCastMessage/updateScheduledBroadCasts/${data?._id}?${resultRecc}`,
      resultPostObj
    )
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        if (type === 'Active') {
          getAllActiveBroadcast();
        } else {
          getAllScheduled();
        }
        handleClose();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <Container direction="column" className={classes.newUser}>
      <form onSubmit={onHandleConfirm}>
        <Item md={12} xs={12} mt={3}>
          <Item md={12} xs={12}>
            <Typography variant="h7" className={classes.label} sx={{ fontSize: '17px' }}>
              {sendData?.timeStatus === 'later' ? 'Update Later' : 'Update Recurring'}
            </Typography>
          </Item>
          <Item md={12} xs={12}>
            <Item className={classes.saveContainer}>
              <Item>
                {/* <RadioGroup
                  className={classes.label}
                  row
                  value={sendData.timeStatus}
                  onChange={(e) => handleStateChange('timeStatus', e.target.value)}>
                  <FormControlLabel value="now" control={<Radio />} label="Now" />
                  <FormControlLabel value="later" control={<Radio />} label="Later" />
                  <FormControlLabel value="recurring" control={<Radio />} label="Recurring" />
</RadioGroup> */}
              </Item>

              {/*  <Item sx={{ display: 'flex', gap: '10px', width: '350px', alignItems: 'center' }}>
                <Typography className={classes.title} sx={{ width: '100px' }}>
                  Life span
                </Typography>
                <FormControl fullWidth size="small">
                  <Select
                    value={sendData?.lifeSpan}
                    // required={true}
                    onChange={(e) => {
                      handleStateChange('lifeSpan', e.target.value);
                    }}>
                    {lifeSpanArray?.map((item, index) => {
                      return (
                        <MenuItem value={item.value} key={index}>
                          {ConvertToUpperCase(item.name)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                  </Item> */}
            </Item>
          </Item>
          <Item md={12} xs={12}>
            {sendData.timeStatus === 'later' && (
              <Item md={12} xs={12} className={classes.formContainer} mt={2}>
                <Item md={3.6} xs={12} className={classes.subContainer}>
                  <Typography className={classes.label} mr={2}>
                    Date
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="laterDate"
                    size="small"
                    value={sendData.laterDate}
                    required={true}
                    onChange={(e) => handleStateChange('laterDate', e.target.value)}
                    type="date"
                    fullWidth
                  />
                </Item>
                <Item md={3.6} xs={12} className={classes.subContainer}>
                  <Typography className={classes.label} mr={2}>
                    Time
                  </Typography>
                  <Select
                    value={sendData.laterTime}
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      handleStateChange('laterTime', e.target.value);
                    }}>
                    {CreateMultipleTime()?.map((item, index) => {
                      return (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Item>
                <Item md={4} xs={12} className={classes.subContainer}></Item>
              </Item>
            )}
            {sendData.timeStatus === 'recurring' && (
              <React.Fragment>
                <Item md={12} xs={12}>
                  <Item className={classes.saveContainer}>
                    <Item
                      mt={2}
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        width: '350px',
                        alignItems: 'center'
                      }}>
                      <Typography className={classes.title} sx={{ width: '100px' }}>
                        Repeat
                      </Typography>
                      <FormControl fullWidth size="small">
                        <Select
                          value={sendData?.recurringRepeat}
                          required={true}
                          onChange={(e) => {
                            handleStateChange('recurringRepeat', e.target.value);
                          }}>
                          {repeatArray?.map((item, index) => {
                            return (
                              <MenuItem value={item.value} key={index}>
                                {ConvertToUpperCase(item.name)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Item>
                    {sendData.recurringRepeat === 'week' && (
                      <Item
                        mt={2}
                        sx={{ height: 'auto' }}
                        className={classes.reciepentContainer}
                        md={7}
                        xs={12}>
                        {Object.keys(weekDays)?.map((item) => {
                          return (
                            <FormControlLabel
                              sx={{ color: '#868686' }}
                              control={
                                <Checkbox
                                  size="medium"
                                  checked={weekDays[item]}
                                  onChange={(e) => handleDailyCheckData(item, e.target.checked)}
                                />
                              }
                              label={ConvertToUpperCase(item)}
                            />
                          );
                        })}
                      </Item>
                    )}
                  </Item>
                </Item>
                <Item md={12} xs={12} className={classes.formContainer} mt={3}>
                  <Item md={3.6} xs={12} className={classes.subContainer}>
                    <Typography className={classes.label} mr={1} sx={{ width: '100px' }}>
                      Start Date
                    </Typography>
                    <TextField
                      variant="outlined"
                      name="recurringStartDate"
                      size="small"
                      value={sendData.recurringStartDate}
                      required={true}
                      onChange={(e) => handleStateChange('recurringStartDate', e.target.value)}
                      type="date"
                      fullWidth
                    />
                  </Item>
                  <Item md={3.6} xs={12} className={classes.subContainer}>
                    <Typography className={classes.label} mr={1} sx={{ width: '100px' }}>
                      End Date
                    </Typography>
                    <TextField
                      variant="outlined"
                      name="recurringEndDate"
                      size="small"
                      value={sendData.recurringEndDate}
                      required={true}
                      onChange={(e) => handleStateChange('recurringEndDate', e.target.value)}
                      type="date"
                      fullWidth
                    />
                  </Item>
                  <Item md={3.6} xs={12} className={classes.subContainer}>
                    <Typography className={classes.label} mr={2} sx={{ width: '100px' }}>
                      Time
                    </Typography>
                    <Select
                      value={sendData.recurringTime}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        handleStateChange('recurringTime', e.target.value);
                      }}>
                      {CreateMultipleTime()?.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Item>
                </Item>
              </React.Fragment>
            )}
          </Item>
          <Item md={12} xs={12} className={classes.saveContainer} mt={2}>
            {Object.keys(checkData)?.map((item) => {
              return (
                <FormControlLabel
                  sx={{ color: '#868686' }}
                  control={
                    <Checkbox
                      size="medium"
                      value={checkData[item]}
                      checked={checkData[item]}
                      onChange={(e) => handleCheckData(item, e.target.checked)}
                    />
                  }
                  label={ConvertToUpperCase(item)}
                />
              );
            })}
          </Item>
        </Item>

        <Item sx={{ float: 'right' }} mt={0.5}>
          <Button
            onClick={handleClose}
            style={{
              border: `1px solid ${theme.palette.primary.tableHeadingColor}`,
              color: theme.palette.primary.tableHeadingColor,
              backgroundColor: '#FFFFFF',
              width: '100px',
              marginRight: '10px'
            }}
            variant="contained"
            fullWidth>
            cancel
          </Button>
          <Button
            style={{
              backgroundColor: theme.palette.primary.tableHeadingColor,
              color: '#FFFFFF',
              width: '100px'
            }}
            type="submit"
            variant="contained"
            fullWidth>
            Submit
          </Button>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(ScheduledEdit);
