const head = [
  {
    id: 'srNo',
    label: 'Sr.No',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center'
  },
  {
    id: 'dateTime',
    label: 'Date&Time',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'header',
    label: 'Broadcast Header',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'cta1',
    label: 'CTA1',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'cta1Value',
    label: 'Response Received',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'cta2',
    label: 'CTA2',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'cta2Value',
    label: 'Response Received',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'cta3',
    label: 'CTA3',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'cta3Value',
    label: 'Response Received',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'noResponse',
    label: 'No Response',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 130,
    width: 130,
    maxWidth: 130,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
