import { Components, Icons } from '../../../utils/material-ui';
import React, { useState, useEffect } from 'react';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';
import theme from '../../../utils/theme';
import { useStateValue } from '../../../utils/store';
import { API_HANDLE_ERROR, API_GET } from '../../../utils/api';
import { useTooltipContext } from './CustomToolTip/customToolTip';

import { BarChart, Bar, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import styles from '../styles';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

// const data = [
//   {
//     value: 12,
//     name: 'Completed with Delay',
//     fill: '#DB3480'
//   },
//   {
//     // name: 'Page B',
//     value: 8,
//     fill: '#5768BC',
//     name: 'Unassigned'
//   },

//   {
//     // name: 'Page D',
//     value: 16,
//     fill: '#EC7A1A',
//     name: 'Pending'
//   },
//   {
//     // name: 'Page E',
//     value: 9,
//     fill: '#FCB94D',
//     name: 'Delay'
//   },
//   {
//     // name: 'Page F',
//     value: 10,
//     fill: '#2C9AFF',
//     name: 'Ongoing'
//   },
//   {
//     // name: 'Page G',
//     value: 4,
//     fill: '#1A9518',
//     name: 'Completed'
//   },
//   {
//     // name: 'Page G',
//     value: 5,
//     fill: '#979797',
//     name: 'Suspended'
//   }
// ];

// const exportData = [
//   {
//     'Sr No': 1,
//     Date: '22-05-2022',
//     Completed: 10,
//     Pending: 12,
//     Delayed: 15,

//     Suspended: 17,
//     Unassigned: 10,
//     Ongoing: 15,
//     'Completed with Delay': 15
//   },
//   {
//     'Sr No': 2,
//     Date: '23-05-2022',
//     Completed: 10,
//     Pending: 12,
//     Delayed: 15,

//     Suspended: 17,
//     Unassigned: 10,
//     Ongoing: 15,
//     'Completed with Delay': 15
//   }
// ];

const Barcharts = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const { openTooltip, closeTooltip } = useTooltipContext();

  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState();

  const tooltipContent = (e) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <div style={{ height: '16px', width: '16px', backgroundColor: e.fill }}></div>
        {`${e.name} :` + e.count}
      </div>
    );
  };

  const tooltipStyle = {
    backgroundColor: 'white',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '1%',
    fontFamily: 'helvetica, sans-serif',
    fontSize: '16px',
    padding: '.5%'
  };

  useEffect(() => {
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;
    API_GET(
      `reports/internalIncidentWorkFlow?fromDate=${selectedFromDate}&toDate=${selectedToDate}&downloadReport=false&${resultLocation}`
    )
      .then((res) => {
        setRows(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location]);

  const handleExport = () => {
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;
    API_GET(
      `reports/internalIncidentWorkFlow?fromDate=${selectedFromDate}&toDate=${selectedToDate}&downloadReport=true&${resultLocation}`
    )
      .then((res) => {
        if (res?.data.length === 0) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'No data found for download'
          });
        } else {
          downloadDataToExcelFile(
            res?.data,
            `Int. work flow_${location}_(${selectedFromDate} To ${selectedToDate})`
          );
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Internal Incident work-flow Status
          </Typography>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>

      <Item sx={{ height: '130px', width: '100%' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            // width={600}
            height={350}
            data={rows}
            margin={{
              top: 20,
              right: 10,
              left: -20,
              bottom: 0
            }}>
            <CartesianGrid strokeDasharray="2 2" />
            <YAxis />
            <Bar
              dataKey="count"
              stackId="count"
              fill="fill"
              barSize={30}
              radius={[0, 0, 0, 0]}
              label={{ position: 'top' }}
              onMouseEnter={(e) =>
                openTooltip({
                  content: tooltipContent(e),
                  style: tooltipStyle
                })
              }
              onMouseLeave={() => closeTooltip()}
            />
          </BarChart>
        </ResponsiveContainer>
      </Item>

      <Item
        md={12}
        style={{
          height: '90px',
          width: '100%',
          overflow: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          padding: '5px 10px 5px 40px'
        }}>
        {rows?.map((item) => {
          return (
            <Item md={4}>
              <Item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  flexDirection: 'row',
                  height: '25px'
                }}>
                <Item
                  style={{
                    width: '0.9rem',
                    height: '0.9rem',
                    borderRadius: 2,
                    backgroundColor: item?.fill
                  }}></Item>
                <h4 style={{ fontSize: '11px', marginLeft: '0.2rem' }}>{item?.name}</h4>
              </Item>
            </Item>
          );
        })}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(Barcharts);
