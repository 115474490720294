import { Components, Icons } from '../../../utils/material-ui';
import React, { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import styles from '../styles';
import ExportToExcel from '../../../utils/downloadMergeColumnData';
import theme from '../../../utils/theme';

const { withStyles, Grid, Typography } = Components;
const { ArrowBackIosIcon, ArrowForwardIosIcon } = Icons;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
// const Container = (props) => <Grid container {...props} />;

const axisBottom = {
  tickSize: 10,
  tickPadding: 6,
  tickRotation: -25,
  legendPosition: 'middle',
  legendOffset: 45
};

const axisLeft = {
  tickSize: 10,
  tickPadding: 0,
  tickRotation: 0,
  legend: 'No. of Hours',
  legendPosition: 'middle',
  legendOffset: -45
};

const themer = {
  background: '#FFFFFF',
  axis: {
    fontSize: '18px',
    tickColor: 'black'
  },
  grid: {
    line: {
      stroke: '#555555'
    }
  }
};

const legends = [
  {
    dataFrom: 'keys',
    anchor: 'top-center',
    direction: 'column',
    justify: false,
    translateX: 650,
    translateY: 20,
    itemsSpacing: 10,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: 'left-to-right',
    itemOpacity: 1,
    itemTextColor: 'black',
    symbolSize: 20,
    symbolShape: 'circle',
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1
        }
      }
    ]
  }
];

const Barcharts = ({
  classes,
  selectedFromDate,
  selectedToDate,
  selectedDepartment,
  allDepartments
}) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [count] = React.useState(8);
  const [totalCount, setTotalCount] = useState(0);
  const [exportResultData, setExportResultData] = useState([]);
  const [reportLocation, setReportsLocation] = useState([]);
  const [departmentName, setDepartmentName] = useState('All');

  const handleForward = () => {
    setPage(page + 1);
  };

  const handleBackward = () => {
    if (page === 0) {
      setPage(0);
    } else {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    const skip = count * page > totalCount ? (count * page) % count : count * page;
    const resultDepartment =
      selectedDepartment === 'all' ? '' : `departmentId=${selectedDepartment}`;

    API_GET(`reports/getOfficeWiseVaccineStatus?limit=${count}&skip=${skip}&${resultDepartment}`)
      .then((res) => {
        setRows(res?.data);
        setTotalCount(res?.count);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, count, page, selectedDepartment]);

  useEffect(() => {
    allDepartments.map((item) => {
      return item?._id === selectedDepartment ? setDepartmentName(item?.department) : '';
    });
  }, [selectedDepartment]);

  const handleExport = () => {
    const resultDepartment =
      selectedDepartment === 'all' ? '' : `departmentId=${selectedDepartment}`;

    API_GET(
      `reports/downloadOfficeWiseVaccineReport?fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultDepartment}`
    )
      .then((res) => {
        setExportResultData(res?.data);
        setReportsLocation(res?.offices);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    setTimeout(() => {
      ExportToExcel(
        'off. Wise vacc. Status_dept',
        'xlsx',
        `off. Wise vacc. Status_${departmentName}_(${selectedFromDate} To ${selectedToDate}).xlsx`
      );
    }, 450);
  };

  const TableData = () => {
    return (
      <div id="off. Wise vacc. Status_dept" style={{ display: 'none' }}>
        <table border="1">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Date</th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return <th colSpan={4}>{item}</th>;
                })}
            </tr>
            <tr>
              <th></th>
              <th></th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return (
                    <React.Fragment>
                      <th>1st dose</th>
                      <th>2nd dose</th>
                      <th>No dose</th>
                      <th>Booster</th>
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {exportResultData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.SrNo}</td>
                  <td>{item?.date}</td>
                  {Object.keys(item).map((subData) => {
                    return (
                      <React.Fragment>
                        {subData !== 'SrNo' && subData !== 'date' ? (
                          <React.Fragment>
                            <td>{item[subData]['1st dose']}</td>
                            <td>{item[subData]['2nd dose']}</td>
                            <td>{item[subData]['No dose']}</td>
                            <td>{item[subData].Booster}</td>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '16px' }}>
            Office wise Vaccination Status
          </Typography>
        </Item>
        <Item>
          {TableData()}
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '200px', width: '100%', display: 'flex' }}>
        <ResponsiveBar
          margin={{ top: 10, right: 200, bottom: 50, left: 40 }}
          data={rows}
          keys={['2nd dose', '1st dose', 'No dose', 'Booster']}
          colors={['#1DA63F', '#2C9AFF', '#EBA10F', '#EC7A1A']}
          indexBy="city"
          labelTextColor="#FFFFFF"
          labelSkipWidth={5}
          labelSkipHeight={10}
          enableGridX={true}
          enableGridY={true}
          gridYValues={5}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          // colors={({ id }) => (id === 'Booked' ? '#ED3E3C' : '#0BAFA0')}
          theme={themer}
          legends={legends}
          padding={0.55}
          borderRadius="2px"
          enableLabel={false}
        />
      </Item>
      <Item md={12} sx={{ display: 'flex' }}>
        {rows?.length !== 0 ? (
          <Item sx={{ display: 'inline-flex', ml: 'auto', mr: 1 }}>
            {page === 0 ? (
              <ArrowBackIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowBackIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleBackward}
              />
            )}
            <Typography sx={{ fontSize: '12px' }}>
              {`${rows && rows.length}-${totalCount}`}
            </Typography>
            {count * page >= totalCount ? (
              <ArrowForwardIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowForwardIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleForward}
              />
            )}
          </Item>
        ) : (
          ''
        )}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(Barcharts);
