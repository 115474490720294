import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Components, Icons } from '../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import theme from '../../../utils/theme';
import styles from '../styles';
import ExportToExcel from '../../../utils/downloadMergeColumnData';
// import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';

const { Grid, Typography, withStyles } = Components;
const { FileDownloadIcon, ArrowBackIosIcon, ArrowForwardIosIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

// const data = [
//   {
//     id: 'value',
//     data: [
//       {
//         x: 'Delhi',
//         y: 1
//       },
//       {
//         x: 'Chennai',
//         y: 2
//       },
//       {
//         x: 'Gurgoan',
//         y: 0
//       },
//       {
//         x: 'Banglore',
//         y: 0
//       },
//       {
//         x: 'Pune',
//         y: 0
//       },
//       {
//         x: 'Mumbai',
//         y: 0
//       },
//       {
//         x: 'Patna',
//         y: 0
//       }
//     ]
//   }
// ];

const Line = ({ selectedFromDate, selectedToDate, classes }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [exportResultData, setExportResultData] = useState([]);
  const [reportLocation, setReportsLocation] = useState([]);
  const [page, setPage] = React.useState(0);
  const [count] = React.useState(8);
  const [totalCount, setTotalCount] = useState(0);

  const handleForward = () => {
    setPage(page + 1);
  };

  const handleBackward = () => {
    if (page === 0) {
      setPage(0);
    } else {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    const skip = count * page > totalCount ? (count * page) % count : count * page;

    API_GET(
      `reports/getInternalOfficeWiseVulnerability?fromDate=${selectedFromDate}&toDate=${selectedToDate}&limit=${count}&skip=${skip}`
    )
      .then((res) => {
        const result = res?.officeWiseVulnerability?.map((item) => {
          return {
            x: item?._id,
            y: item?.count
          };
        });
        const resultData = [
          {
            id: 'value',
            data: result
          }
        ];
        setTotalCount(res?.count);
        setRows(resultData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, count, page]);

  const handleExport = () => {
    API_GET(
      `reports/downloadInternalOfficeWiseVulnerability?fromDate=${selectedFromDate}&toDate=${selectedToDate}`
    )
      .then((res) => {
        setExportResultData(res?.data);
        setReportsLocation(res?.locations);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    setTimeout(() => {
      ExportToExcel(
        'Internal_office_wise_vel',
        'xlsx',
        `Int. Off_wise_vulnerability (${selectedFromDate} To ${selectedToDate}).xlsx`
      );
    }, 450);
  };

  const TableData = () => {
    return (
      <div id="Internal_office_wise_vel" style={{ display: 'none' }}>
        <table border="1">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Date</th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return <th colSpan={5}>{item}</th>;
                })}
            </tr>
            <tr>
              <th></th>
              <th></th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return (
                    <React.Fragment>
                      <th>Severity10</th>
                      <th>Severity9</th>
                      <th>Severity8</th>
                      <th>Severity7</th>
                      <th>Severity6</th>
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {exportResultData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.SrNo}</td>
                  <td>{`${item?.date}`}</td>
                  {Object.keys(item).map((subData) => {
                    return (
                      <React.Fragment>
                        {subData !== 'SrNo' && subData !== 'date' ? (
                          <React.Fragment>
                            <td>{item[subData]['Severity 10']}</td>
                            <td>{item[subData]['Severity 9']}</td>
                            <td>{item[subData]['Severity 8']}</td>
                            <td>{item[subData]['Severity 7']}</td>
                            <td>{item[subData]['Severity 6']}</td>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          {TableData()}
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Office Wise Vulnerability
          </Typography>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>

      <Item sx={{ height: '210px', width: '100%' }}>
        <ResponsiveLine
          data={rows}
          margin={{
            top: 10,
            right: 10,
            bottom: 50,
            left: 50
          }}
          stacked={false}
          enableGridX={false}
          curve="linear"
          lineWidth="3px"
          colors="#4BA04B"
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -25,
            legendOffset: 36,
            legendPosition: 'center'
          }}
          enableDotLabel={true}
        />
      </Item>
      <Item md={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {rows?.length !== 0 ? (
          <Item sx={{ display: 'inline-flex', ml: 'auto', mr: 1 }}>
            {page === 0 ? (
              <ArrowBackIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowBackIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleBackward}
              />
            )}
            <Typography sx={{ fontSize: '13px' }}>
              {`${rows && rows[0]?.data?.length}-${totalCount}`}
            </Typography>
            {count * page >= totalCount ? (
              <ArrowForwardIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowForwardIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleForward}
              />
            )}
          </Item>
        ) : (
          ''
        )}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(Line);
