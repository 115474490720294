import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import Progressbar from './progressbar';
import BugetEvaluationTable from '../../../components/organism/Table/BugetEvaluationTable';
import RiskEvaluationTable from '../../../components/organism/Table/RiskEvaluationTable';
import DepartementWiseBudget from './DepartementWiseBudget';
import LocationWiseRevenue from './LocationWiseRevenue';
import CategoryWiseRevenue from './CategoryWiseRevenue';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';

const { withStyles, Grid, Typography, Paper, FormControl, InputLabel, MenuItem, Select } =
  Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const categoryArray = ['All', 'emergency', 'critical', 'warning', 'notice', 'info'];

const RevenueReport = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [category, setCategory] = React.useState('All');
  const [allCategory, setAllCategory] = useState([{ _id: 'All' }]);

  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedFromDate1, setSelectedFromDate1] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [selectedToDate1, setSelectedToDate1] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [budgetCount, setBudgetCount] = useState();
  const [riskCount, setRiskCount] = useState();
  const [offices, setOffices] = React.useState([{ _id: { city: 'All', _id: 'all' } }]);
  const [offices1, setOffices1] = React.useState([{ city: 'All', _id: 'all' }]);
  const [location, setlocation] = React.useState('All');
  const [location1, setlocation1] = React.useState('all');

  const handleDate = (date) => {
    if (date) {
      setSelectedFromDate(moment(date[0]).format('YYYY-MM-DD'));
      setSelectedToDate(moment(date[1]).format('YYYY-MM-DD'));
    }
  };
  const handleDate1 = (date) => {
    if (date) {
      setSelectedFromDate1(moment(date[0]).format('YYYY-MM-DD'));
      setSelectedToDate1(moment(date[1]).format('YYYY-MM-DD'));
    }
  };

  const handleExportBudgetEvalution = () => {
    API_GET(`reports/getBudgetEvaluation?fromDate=${selectedFromDate}&toDate=${selectedToDate}`)
      .then((res) => {
        const resultReport = res?.data?.map((item) => {
          return {
            Department: item?.category,
            'Internal Incident': ConvertToUpperCase(item?.title),
            Location: ConvertToUpperCase(item?.officeCity),
            'Requested Budget': item?.requestedBudget,
            'Approved Budget': item?.approvedBudget
          };
        });
        downloadDataToExcelFile(
          resultReport,
          `Budget Evaluation_Location (${selectedFromDate} To ${selectedToDate})`
        );
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const handleExportRiskEvalution = () => {
    const resultCategory = category === 'All' ? '' : `category=${category}`;
    API_GET(
      `reports/getRiskEvaluation?fromDate=${selectedFromDate1}&toDate=${selectedToDate1}&${resultCategory}`
    )
      .then((res) => {
        const resultReport = res?.data?.map((item) => {
          return {
            Date: moment(item?.createdAt).format('DD-MM-YYYY'),
            Category: item?.alertCategory,
            'External Incident': item?.title,
            Location: item?.cityFromDror,
            'Revenue($)': item?.revenue,
            'Human(%)': item?.humanWeightage,
            'Infra(%)': item?.infraWeightage,
            Status: item?.status,
            'Rev. Earned($)': item?.revEarned
          };
        });
        downloadDataToExcelFile(
          resultReport,
          `Risk Evaluation_Location (${selectedFromDate1} To ${selectedToDate1})`
        );
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return {
            city: item.city,
            _id: item._id
          };
        });

        setOffices1([...offices1, ...resultData]);
        setOffices([...offices, ...res?.citiesArray]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const getAllCategory = () => {
    API_GET('reports/getCategories')
      .then((res) => {
        setAllCategory([...allCategory, ...res?.data]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllCategory();
    getAllOffices();
  }, []);

  const getBudgetData = (data) => {
    setBudgetCount(data?.totalEvaluation && data?.totalEvaluation[0]);
  };
  const getRiskData = (data) => {
    setRiskCount(data?.totalEvaluation && data?.totalEvaluation[0]);
  };

  return (
    <Container direction="column" className={classes.newUser}>
      <Typography className={classes.cardHeading} sx={{ backgroundColor: '#4BA04B' }} variant="h6">
        Revenue Report
      </Typography>
      <Container className={classes.tableContainer1}>
        <Item md={12} xs={12}>
          <Item className={classes.heading}>Revenue Earned</Item>
        </Item>
        <Item md={12} xs={12} sx={{ height: '60px', mt: 1.5 }}>
          <Progressbar riskCount={riskCount} />
        </Item>
        <Item md={12} xs={12} sx={{ ...theme.card, height: 'auto', mt: 1, p: 1 }}>
          <Item sx={{ display: 'flex', alignItems: 'center', p: 1, flexWrap: 'wrap' }}>
            <Item>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bolder',
                  color: 'black'
                }}>
                {`Total Revenue Loss   $${riskCount?.totalRevLoss?.toFixed(1) || 0}`}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 'bolder',
                  fontSize: '12px',
                  color: 'black'
                }}>
                {`Total Revenue Earned   $${riskCount?.totalRevEarned?.toFixed(1) || 0}`}
              </Typography>
            </Item>
            <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '10px', flexWrap: 'wrap' }}>
              <Item>
                <DateRangePicker
                  onChange={(date) => handleDate1(date)}
                  value={[new Date(selectedFromDate1), new Date(selectedToDate1)]}
                />
              </Item>
              <Item>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"> Location </InputLabel>
                  <Select
                    variant="outlined"
                    size="small"
                    value={location}
                    sx={{ width: '120px' }}
                    label=" Location "
                    onChange={(e) => setlocation(e.target.value)}>
                    {offices?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id?.city}>
                          {ConvertToUpperCase(item?._id?.city)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Item>
              <Item>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    variant="outlined"
                    size="small"
                    value={category}
                    sx={{ width: '100px' }}
                    label=" Category"
                    onChange={(e) => setCategory(e.target.value)}>
                    {categoryArray?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {ConvertToUpperCase(item)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Item>

              <Item>
                <FileDownloadIcon
                  style={{ cursor: 'pointer', color: 'grey' }}
                  onClick={handleExportRiskEvalution}
                />
              </Item>
            </Item>
          </Item>

          <Paper elevation={2} sx={theme.card}>
            <RiskEvaluationTable
              selectedFromDate={selectedFromDate1}
              selectedToDate={selectedToDate1}
              getRiskData={getRiskData}
              category={category}
              location={location}
            />
          </Paper>
          <Item
            md={12}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px'
            }}>
            <Item md={5} xs={12} sx={{ mt: 1.5 }}>
              <Paper
                sx={{
                  height: '300px',
                  ...theme.card
                }}>
                <LocationWiseRevenue
                  selectedFromDate={selectedFromDate1}
                  selectedToDate={selectedToDate1}
                  category={category}
                />
              </Paper>
            </Item>

            <Item md={7} xs={12} sx={{ mt: 1.5 }}>
              <Paper
                sx={{
                  height: '300px',
                  ...theme.card
                }}>
                <CategoryWiseRevenue
                  selectedFromDate={selectedFromDate1}
                  selectedToDate={selectedToDate1}
                  category={category}
                />
              </Paper>
            </Item>
          </Item>
        </Item>

        <Item md={12} xs={12} sx={{ ...theme.card, height: 'auto', mt: 1, p: 1 }}>
          <Item sx={{ display: 'flex', alignItems: 'center', p: 1, flexWrap: 'wrap' }}>
            <Item>
              <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                {`Total Approved Budget   $${budgetCount?.totalApprovedBudget?.toFixed(1) || 0}`}
              </Typography>
              <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>
                {`Total Requested Budget   $${budgetCount?.totalRequestedBudget?.toFixed(1) || 0}`}
              </Typography>
            </Item>
            <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '10px', flexWrap: 'wrap' }}>
              <Item>
                <DateRangePicker
                  onChange={(date) => handleDate(date)}
                  value={[new Date(selectedFromDate), new Date(selectedToDate)]}
                />
              </Item>
              <Item>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"> Location </InputLabel>
                  <Select
                    variant="outlined"
                    size="small"
                    value={location1}
                    sx={{ width: '120px' }}
                    label=" Location "
                    onChange={(e) => setlocation1(e.target.value)}>
                    {offices1?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {ConvertToUpperCase(item?.city)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Item>
              <Item>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Department</InputLabel>
                  <Select
                    variant="outlined"
                    size="small"
                    value={category}
                    sx={{ width: '100px' }}
                    label=" Department"
                    onChange={(e) => setCategory(e.target.value)}>
                    {allCategory?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?._id}>
                          {item?._id}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Item>

              <Item>
                <FileDownloadIcon
                  style={{ cursor: 'pointer', color: 'grey' }}
                  onClick={handleExportBudgetEvalution}
                />
              </Item>
            </Item>
          </Item>
          <Paper elevation={2} sx={theme.card}>
            <BugetEvaluationTable
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              getBudgetData={getBudgetData}
              location={location1}
              category={category}
            />
          </Paper>
          <Item
            md={12}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px'
            }}>
            <Item md={12} xs={12} sx={{ mt: 1.5 }}>
              <Paper
                sx={{
                  height: '300px',
                  ...theme.card
                }}>
                <Item sx={{ p: 0.5 }}>
                  <DepartementWiseBudget
                    selectedFromDate={selectedFromDate}
                    selectedToDate={selectedToDate}
                  />
                </Item>
              </Paper>
            </Item>
          </Item>
        </Item>
      </Container>
    </Container>
  );
};

export default withStyles(styles)(RevenueReport);
