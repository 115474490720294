const head = [
  {
    id: 'date',
    label: 'Pub. Date',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },

  {
    id: 'title',
    label: 'Incidents',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'prediction',
    label: 'Prediction',
    minWidth: 70,
    width: 70,
    maxWidth: 70,
    align: 'left'
  },
  {
    id: 'locationFromDror',
    label: 'Location',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'revenue',
    label: 'Potential Rev. Loss',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  }
];

export default head;
