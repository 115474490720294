import { Typography } from '@mui/material';
import React from 'react';
import { Components } from '../../../utils/material-ui';
// import { useStateValue } from '../../../utils/store';
// import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import theme from '../../../utils/theme';
import overviewcss from './overviewcss';

const { withStyles, Grid, Paper } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

// const sampleData = [
//   { name: 'Total Agents', value: 4000, color: '#3B9DF7', id: 'totalAgent' },
//   { name: 'Pending Docs', value: 2000, color: '#FCB94D', id: 'pendingDocs' },
//   { name: 'Expired Docs', value: 20, color: '#ED3E3C', id: 'expiredDocs' },
//   { name: 'To be Expire docs', value: 500, color: '#34B53A', id: 'tobeExpiredDocs' }
// ];

const allocationCardText = {
  textAlign: 'center',
  fontSize: '15px',
  fontWeight: 'bold',
  color: '#FFFFFF'
};
const allocationCardValue = {
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: 'bold',
  color: '#FFFFFF'
};

const AllocationCard = ({ classes, cardsData }) => {
  return (
    <Container spacing={2} columns={8}>
      {cardsData &&
        cardsData?.map((item, index) => {
          return (
            <Item mt={1} xs={12} sm={4} md={3} lg={2} key={index}>
              <Paper sx={{ ...theme.card, backgroundColor: item?.color }}>
                <Container className={classes.cardsContain}>
                  <Item
                    sx={{
                      display: 'flex',
                      height: '130px',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Item sx={{ width: '170px' }}>
                      <Typography sx={allocationCardValue}>{item.value}</Typography>
                      <Typography sx={allocationCardText}>{item?.name}</Typography>
                    </Item>
                    <Item sx={{ width: '70px' }}>
                      <img
                        style={{ height: '60px', width: '60px' }}
                        src={
                          require(`../../../Assets/TravelAllocationCardIcon/${item?.id}.svg`)
                            .default
                        }
                        alt="distribution icon"
                      />
                    </Item>
                  </Item>
                </Container>
              </Paper>
            </Item>
          );
        })}
    </Container>
  );
};

export default withStyles(overviewcss)(AllocationCard);
