const head = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 120,
    width: 120,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'safetyCategory',
    label: 'Category',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'ticketNumber',
    label: 'Ticket No',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'event',
    label: 'Incident',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'severity',
    label: 'Severity',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'center'
  },
  {
    id: 'approvalStatus',
    label: 'Approval status',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'docs',
    label: 'Docs',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'officeAddress',
    label: 'Location',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'forwardedBy',
    label: 'Pri. Initiator',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'forwardedTo',
    label: 'Incident Log',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'actualDate',
    label: 'Completion Date',
    minWidth: 140,
    width: 140,
    maxWidth: 150,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'planDays',
    label: 'Plan Days',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'totalDays',
    label: 'Total Days',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'extraDays',
    label: 'Extra Days',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'incidentActionStatus',
    label: 'Global Status',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
