import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import { ResponsiveLine } from '@nivo/line';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import theme from '../../../utils/theme';
import ExportToExcel from '../../../utils/downloadMergeColumnData';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon, ArrowBackIosIcon, ArrowForwardIosIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
// const Container = (props) => <Grid item {...props} />;

// const data = [
//   {
//     id: 'japan',
//     data: [
//       {
//         x: 'Delhi',
//         y: 130
//       },
//       {
//         x: 'Chennai',
//         y: 240
//       },
//       {
//         x: 'Gurgaon',
//         y: 276
//       },
//       {
//         x: 'Bengaluru',
//         y: 370
//       },
//       {
//         x: 'Pune',
//         y: 306
//       },
//       {
//         x: 'Mumbai',
//         y: 200
//       },
//       {
//         x: 'Patna',
//         y: 444
//       }
//     ]
//   },
//   {
//     id: 'france',
//     data: [
//       {
//         x: 'Delhi',
//         y: 300
//       },
//       {
//         x: 'Chennai',
//         y: 100
//       },
//       {
//         x: 'Gurgaon',
//         y: 100
//       },
//       {
//         x: 'Bengaluru',
//         y: 230
//       },
//       {
//         x: 'Pune',
//         y: 180
//       },
//       {
//         x: 'Mumbai',
//         y: 400
//       },
//       {
//         x: 'Patna',
//         y: 300
//       }
//     ]
//   }
// ];

const MyResponsivePieCanvas = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [count] = React.useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [exportResultData, setExportResultData] = useState([]);
  const [reportLocation, setReportsLocation] = useState([]);

  const handleForward = () => {
    setPage(page + 1);
  };

  const handleBackward = () => {
    if (page === 0) {
      setPage(0);
    } else {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    const skip = count * page > totalCount ? (count * page) % count : count * page;
    API_GET(
      `reports/getDepartmentWiseAttendance?fromDate=${selectedFromDate}&toDate=${selectedToDate}&limit=${count}&skip=${skip}&city=${location}`
    )
      .then((res) => {
        setTotalCount(2);
        const result = res?.attendance?.map((item) => {
          return item.id === 'present' ? { color: 'green', ...item } : { color: 'red', ...item };
        });
        setRows(result);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location]);

  const handleExport = () => {
    API_GET(
      `reports/downloadDepartmentWiseAttendance?fromDate=${selectedFromDate}&toDate=${selectedToDate}&city=${location}`
    )
      .then((res) => {
        setExportResultData(res?.data);
        setReportsLocation(res?.departments);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    setTimeout(() => {
      ExportToExcel(
        'Department_wiseAttendance',
        'xlsx',
        `Dept. wise attendance_${location} (${selectedFromDate} To ${selectedToDate}).xlsx`
      );
    }, 450);
  };

  const TableData = () => {
    return (
      <div id="Department_wiseAttendance" style={{ display: 'none' }}>
        <table border="1">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Date</th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return <th colSpan={2}>{item}</th>;
                })}
            </tr>
            <tr>
              <th></th>
              <th></th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return (
                    <React.Fragment>
                      <th>Present</th>
                      <th>Absent</th>
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {exportResultData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.SrNo}</td>
                  <td>{item?.date}</td>

                  {Object.keys(item).map((subData) => {
                    return (
                      <React.Fragment>
                        {subData !== 'SrNo' && subData !== 'date' ? (
                          <React.Fragment>
                            <td>{item[subData]?.present}</td>
                            <td>{item[subData]?.absent}</td>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Department Wise Attendance
          </Typography>
        </Item>
        <Item>
          {TableData()}
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '200px' }}>
        <ResponsiveLine
          data={rows}
          margin={{ top: 25, right: 20, bottom: 30, left: 50 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -20,
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          enableGridX={false}
          enableGridY={true}
          colors={(d) => d.color}
          enablePoints={false}
          pointSize={12}
          pointBorderWidth={4}
          pointLabelYOffset={-12}
          areaBlendMode="darken"
          useMesh={true}
          legends={[
            {
              anchor: 'top-right',
              direction: 'row',
              justify: false,
              translateX: 10,
              translateY: -25,
              itemsSpacing: 1,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 2,
              symbolSize: 16,
              symbolShape: 'square',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
      </Item>

      <Item md={12} sx={{ display: 'flex' }}>
        {rows?.length !== 0 ? (
          <Item sx={{ display: 'inline-flex', ml: 'auto', mr: 1 }}>
            {count * page <= count ? (
              <ArrowBackIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowBackIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleBackward}
              />
            )}
            <Typography sx={{ fontSize: '12px' }}>
              {`${rows && rows.length}-${totalCount}`}
            </Typography>
            {count * page >= totalCount || count * page <= count ? (
              <ArrowForwardIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowForwardIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleForward}
              />
            )}
          </Item>
        ) : (
          ''
        )}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
