const head = [
  {
    id: 'incidentAutoId',
    label: 'SOS ID',
    minWidth: 80,
    width: 80,
    maxWidth: 80
  },
  {
    id: 'corporateName',
    label: 'Company Name',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'incomingTime',
    label: 'Event Time',
    minWidth: 130,
    width: 130,
    maxWidth: 130
  },
  {
    id: 'acknowledgedAt',
    label: 'Attended At',
    align: 'left',
    maxWidth: 130,
    minWidth: 130,
    width: 130
  },
  {
    id: 'closureTime',
    label: 'Confirmed At',
    align: 'left',
    maxWidth: 130,
    minWidth: 130,
    width: 130
  },
  {
    id: 'attendedBy',
    label: 'Attended By',
    align: 'left',
    maxWidth: 130,
    minWidth: 130,
    width: 130
  },
  {
    id: 'offlineSos',
    label: 'Offline SOS',
    align: 'left',
    maxWidth: 130,
    minWidth: 130,
    width: 130
  },
  // {
  //   id: 'closedBy',
  //   label: 'Closed By',
  //   align: 'center',
  //   maxWidth: 130,
  //   minWidth: 130,
  //   width: 130,
  // },
  {
    id: 'type',
    label: 'Type',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'gender',
    label: 'Gender',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'internalId',
    label: 'Internal Id',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'role',
    label: 'Role',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 60,
    width: 60,
    maxWidth: 60,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'address',
    label: 'Address',
    minWidth: 350,
    width: 350,
    maxWidth: 350,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'viewOnMap',
    label: 'Map View',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'informedTo',
    label: 'Escalated To',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'emergencyContact',
    label: 'Emergency Contact',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },

  {
    id: 'comment',
    label: 'Comments',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'service',
    label: 'Service',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  // {
  //   id: 'remark',
  //   label: 'Remark',
  //   minWidth: 200,
  //   width: 200,
  //   maxWidth: 200,
  //   align: 'center',
  //   format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    format: (value) => value.toLocaleString('en-US')
  }
];

export default head;
