const head = [
  {
    id: 'select',
    label: 'Select',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'header',
    label: 'Header',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'message',
    label: 'Message',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'date',
    label: 'Date/Time',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'sentVia',
    label: 'Sent via',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'sentBy',
    label: 'Sent By',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },

  {
    id: 'sentTo',
    label: 'Sent to',
    minWidth: 70,
    width: 70,
    maxWidth: 70,
    align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'ctaName',
    label: 'CTA Name',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'center'
  }
];

export default head;
