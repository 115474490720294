import { Components } from './material-ui';
import theme from './theme';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const { Button, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

const SortButton = ({ handleOpenSortModal }) => {
  return (
    <Button sx={theme.button.filterButton} onClick={handleOpenSortModal}>
      <img
        style={{ height: '22px', width: '20px', marginRight: '10px', fontWeight: '400' }}
        src={require(`../Assets/settingsIcon/sort.svg`).default}
        alt="distribution icon"
      />
      Sort
    </Button>
  );
};
const FilterButton = ({ handleOpenFilterModal }) => {
  return (
    <Button sx={theme.button.filterButton} onClick={handleOpenFilterModal}>
      <img
        style={{ height: '22px', width: '20px', marginRight: '10px', fontWeight: '400' }}
        src={require(`../Assets/settingsIcon/filter.svg`).default}
        alt="distribution icon"
      />
      Filter
    </Button>
  );
};

const TotalCount = (value, count) => {
  return (
    <Item>
      <Typography
        sx={{
          color: theme.typography.tableHeading.heading,
          fontSize: '18px'
        }}>
        {value}
      </Typography>
      <Typography
        sx={{
          color: theme.typography.tableHeading.color,
          fontSize: '14px',
          mt: 0.5
        }}>
        {count}
      </Typography>
    </Item>
  );
};

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}));

export { SortButton, FilterButton, Android12Switch, TotalCount };
