const head = [
  {
    id: 'city',
    label: 'Location',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'officeName',
    label: 'Office',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'employeePercentage',
    label: `Employee's Ratio`,
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'revenuePercentage',
    label: 'Revenue Ratio',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'infrastructurePercentage',
    label: 'Infra Ratio',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'yearlyRevenue',
    label: 'Revenue',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'edit',
    label: 'Edit',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  }
];

export default head;
