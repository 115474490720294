import React from 'react';
import theme from '../../utils/theme';
import { Components } from '../../utils/material-ui';

const { Grid, Paper, Typography, Button, FormControlLabel, Checkbox } = Components;

const Item = (props) => <Grid item {...props} />;

const AppointmentFilter = ({ handleClose, handleFilterCategory, checkedItems, handleChange }) => {
  const handleSubmit = () => {
    const result = checkedItems?.filter((item) => {
      return item.value;
    });

    handleFilterCategory(result);
    handleClose();
  };

  return (
    <Paper
      sx={{
        position: 'absolute',
        top: 40,
        right: 0,
        zIndex: 99,
        p: 2,
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
      }}>
      <Item sx={{ maxHeight: '60vh', overflow: 'auto' }}>
        <Typography sx={{ ...theme.typography.tableHeading, p: 0, fontSize: '16px' }}>
          Filter By
        </Typography>
        <Item>
          {checkedItems?.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={item?.value}
                  onChange={() => handleChange(item)}
                  name={item?.name}
                  color="primary"
                />
              }
              sx={{ color: theme.typography.tableHeading.color, fontSize: '15px' }}
              label={item?.label}
            />
          ))}
        </Item>
      </Item>
      <Item sx={{ display: 'flex', gap: '10px' }} mt={2}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <Button style={{ ...theme.button.cancel }} onClick={handleClose}>
            Cancel
          </Button>
        </Item>
        <Item>
          <Button
            onClick={handleSubmit}
            style={{
              ...theme.button.submit,
              backgroundColor: theme.palette.primary.main
            }}>
            {`Apply`}
          </Button>
        </Item>
      </Item>
    </Paper>
  );
};

export default AppointmentFilter;
