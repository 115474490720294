import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../../utils/theme';
import { Typography } from '@mui/material';
import { API_POST, API_HANDLE_ERROR, API_PATCH } from '../../../../../utils/api';
import { useStateValue } from '../../../../../utils/store';

const { withStyles, Grid, Button, TextField } = Components;
const { CancelRoundedIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function AddTemplate({ classes, handleClose, getAllTemplate, editData, status }) {
  const [, dispatch] = useStateValue();
  const [sendData, setSendData] = useState({
    header: '',
    message: ''
  });

  const [ctaArray, setCtaArray] = useState([{ position: 1, ctaName: '' }]);

  useEffect(() => {
    if (status === 'edit') {
      setSendData({
        ...sendData,
        header: editData?.header,
        message: editData?.message
      });
      setCtaArray(editData?.ctanames);
    }
  }, [status]);

  const handleStateChange = (key, value, index) => {
    setSendData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };
  const handleCTAChange = (key, value, index) => {
    const result = ctaArray?.map((item, newIndex) => {
      return newIndex === index ? { ...item, ctaName: value } : item;
    });
    setCtaArray(result);
  };

  const handleAddMoreCTA = () => {
    setCtaArray([...ctaArray, { position: ctaArray?.length + 1, ctaName: '' }]);
  };

  const handleRemoveCta = (index) => {
    const result = ctaArray.filter((id, resindex) => {
      return resindex !== index;
    });
    setCtaArray(result);
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();

    const deletedItem = [];

    const result = ctaArray
      ?.filter((item) => {
        if (item?.ctaName === '') {
          deletedItem.push(item?._id);
        }
        return item?.ctaName !== '';
      })
      .map((item, index) => {
        return { ...item, position: index + 1 };
      });

    const postData = {
      header: sendData.header,
      message: sendData.message,
      ctaArray: result,
      deletedItem
    };
    if (status === 'edit') {
      API_PATCH(`broadCastMessage/updateHeader/${editData?._id}`, postData)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
          getAllTemplate();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      API_POST('broadCastMessage/addHeader', postData)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
          getAllTemplate();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };
  return (
    <Container direction="column" className={classes.newUser}>
      <form onSubmit={onHandleConfirm}>
        <Item md={12} xs={12} className={classes.formContainer}>
          <Item md={5.8} xs={12}>
            <Typography className={classes.label} mb={1}>
              Header *
            </Typography>
            <TextField
              variant="outlined"
              name="header"
              size="small"
              value={sendData.header}
              placeholder="Template Header"
              required={true}
              onChange={(e) => handleStateChange('header', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>

          <Item md={5.8} xs={12}>
            <Typography className={classes.label} mb={1}>
              Message *
            </Typography>

            <TextField
              variant="outlined"
              name="message"
              placeholder="Template Message"
              size="small"
              value={sendData.message}
              required={true}
              onChange={(e) => handleStateChange('message', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>
        </Item>
        <Item md={12} xs={12} className={classes.formContainer} mt={4}>
          <Item
            md={12}
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography className={classes.label}>Add CTAs </Typography>
            {ctaArray?.length < 3 && (
              <Button
                style={{
                  ...theme.button.submit,
                  backgroundColor: theme.palette.primary.main
                }}
                onClick={handleAddMoreCTA}>
                Add More CTA
              </Button>
            )}
          </Item>
          {ctaArray?.map((item, index) => {
            return (
              <Item
                style={{ position: 'relative' }}
                md={ctaArray?.length <= 2 ? 5.8 : 3.6}
                xs={12}
                className={classes.subContainer}>
                <Typography className={classes.label}>{index + 1}</Typography>
                <CancelRoundedIcon
                  onClick={() => handleRemoveCta(index)}
                  style={{
                    position: 'absolute',
                    marginLeft: '98%',
                    marginTop: '-35px',
                    cursor: 'pointer',
                    zIndex: 1
                  }}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  value={item?.ctaName}
                  onChange={(e) => handleCTAChange('ctaName', e.target.value, index)}
                  type="text"
                  fullWidth
                />
              </Item>
            );
          })}
        </Item>
        <Item sx={{ display: 'flex', gap: '15px', mt: 2 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button onClick={() => handleClose()} style={{ ...theme.button.cancel }}>
              NO
            </Button>
          </Item>
          <Item>
            <Button type="submit" style={theme.button.submit}>
              YES
            </Button>
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(AddTemplate);
