const head = [
  {
    id: 'gender',
    label: 'Gender',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'dateOfBirth',
    label: 'DOB',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'designation',
    label: 'Designation',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'address',
    label: 'Address',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'reportingTo',
    label: 'Reporting To',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  }
];

export default head;
