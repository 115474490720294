const styles = (theme) => {
  return {
    card: {
      padding: '15px',
      margin: '10px',
      width: '150px',
      height: '150px'
    },
    addCompanyCard: {
      color: theme.palette.primary.main,
      padding: '15px',
      margin: '10px',
      width: '150px',
      height: '150px'
    }
  };
};

export default styles;
