const styles = (theme) => {
  return {
    newUser: {
      minWidth: '75vw',
      maxWidth: '75vw',
      height: '92vh',
      padding: theme.spacing(2, 2),
      overflow: 'auto'
    },

    title: {
      color: '#868686',
      fontSize: '1.2rem',
      fontWeight: '600'
    },
    text: {
      color: '#717171',
      fontSize: '0.9rem',
      fontWeight: '600'
    },
    formContainer: {
      height: '80vh',
      overflow: 'auto'
    },
    saveContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItem: 'center',
      flexWrap: 'wrap',
      gap: '10px'
    },
    formControl: {
      margin: theme.spacing(1),
      width: 300
    },
    indeterminateColor: {
      color: '#f50057'
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    indiviualSelectContainer: {
      height: 'auto',
      maxHeight: '180px',
      minHeight: '180px',
      overflow: 'auto',
      padding: theme.spacing(0.5),
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      border: '0.2px solid lightGrey'
    },
    groupSelectContainer: {
      height: 'auto',
      maxHeight: '80px',
      minHeight: '80px',
      overflow: 'auto',
      padding: theme.spacing(0.5),
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      border: '0.2px solid lightGrey'
    },
    smallContainer: {
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '8px',
      alignItems: 'center',
      color: theme.palette.primary.main,
      minWidth: '100px',
      padding: theme.spacing(0.5),
      borderRadius: '10px'
    },
    previewData: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap'
    }
  };
};

export default styles;
