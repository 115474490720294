import React, { useEffect, useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../utils/store';
import { API_WELLNESS_GET, API_HANDLE_ERROR } from '../../utils/api';
import BarGraph from './BarGraph';

const { withStyles, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

// const sampleData = [
//   { name: 'totalStep', label: 'Total Steps Walker', value: '8,00000', color: '#E4E0F9' },
//   { name: 'totalWater', label: 'Total Water ', value: '100 Lt.', color: '#D2EBFF' },
//   { name: 'avgWaterTracker', label: 'Avg. Rating water Tracker', value: '3.9/5', color: '#FFDFCF' },
//   {
//     name: 'avgStepTracker',
//     label: 'Avg. Rating steps tracker',
//     value: '8,00000',
//     color: '#FFD9E6'
//   },
//   { name: 'totalMeditation', label: 'Total Meditation Mins', value: '4.5/5', color: '#CFEFDA' }
// ];

const FitnessActivity = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [genericData, setGenericData] = useState([]);
  const [trackerData] = useState([
    {
      name: 'Water Tracker',
      textColor: '#0F78CD',
      verticalText: 'No. of Ltrs',
      barColor: ['#C0E5FF'],
      type: 'water'
    },
    {
      name: 'Step Tracker',
      textColor: '#E36414',
      verticalText: 'No. of Steps',
      barColor: ['#F2DAC6'],
      type: 'steps'
    },
    {
      name: 'Meditation Tracker',
      textColor: '#EC443C',
      verticalText: '',
      barColor: ['#E3BCBC'],
      type: 'meditation'
    }
  ]);

  useEffect(() => {
    API_WELLNESS_GET(`wellness/getGenericTotals`)
      .then((res) => {
        setGenericData(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  const fitnessCard = (item) => {
    return (
      <Item className={classes.fitnessCard} md={1.9} xs={12} p={1.2}>
        <Item className={classes.subFitnessCard} md={12} xs={12}>
          <Item md={8.5}>
            <Typography sx={{ fontSize: '1rem' }} mt={1.2}>
              {item?.label}
            </Typography>
          </Item>
          <Item
            md={3.5}
            sx={{
              height: '45px',
              width: '40px',
              borderRadius: '50%',
              backgroundColor: item?.color,
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              boxShadow:
                'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px'
            }}>
            <img
              height="30px"
              width="30px"
              style={{ margin: 'auto' }}
              src={require(`../../Assets/Happiness/${item?.name}.svg`).default}
              alt="=icon"
            />
          </Item>
        </Item>
        <Typography sx={{ fontSize: '1.1rem', color: '#4F4F4F', fontWeight: '600' }}>
          {item?.value === 0 ? 0 : Number(item?.value)?.toFixed(2)}
        </Typography>
      </Item>
    );
  };

  return (
    <Item md={12} xs={12}>
      <Item mt={1} md={12} xs={12}>
        <Item className={classes.fitnessContainer}>
          {genericData?.map((item, index) => {
            return <React.Fragment key={index}> {fitnessCard(item)}</React.Fragment>;
          })}
        </Item>
      </Item>

      <Item mt={4} md={12} xs={12}>
        <Item className={classes.fitnessContainer} md={12} xs={12}>
          {trackerData?.map((item, index) => {
            return <BarGraph tracker={item} key={index} />;
          })}
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(FitnessActivity);
