const styles = (theme) => {
  return {
    incidentAlert: {
      position: 'absolute',
      top: '60px',
      right: '35px',
      width: '450px',
      height: '62vh',
      borderRadius: '8px',
      justifyContent: 'center',
      padding: theme.spacing('10px'),
      margin: theme.spacing('10px'),
      zIndex: theme.zIndex.snackbar,
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 8px grey'
    },
    notificationContainer: {
      width: '100%',
      height: '60vh',
      maxHeight: '55vh',
      overflow: 'auto'
    },
    notificationHeading: {
      color: theme.palette.primary.tableHeadingColor
    },
    subContainer: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      border: '1px solid #DCDCDC',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.2, 1)
    },
    img: {
      width: '15%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
      // border: '1px solid red'
    },
    titleContainer: {
      width: '80%',
      height: 'auto',
      alignItems: 'center',
      justifyContent: 'left',
      cursor: 'pointer'
      // border: '1px solid blue'
      // lineHeight: '15px'
    },
    title: {
      fontSize: '12px',
      flexWrap: 'wrap',
      lineHeight: '18px'
    },
    dropdown: {
      width: '3%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    action: {
      width: '5%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };
};

export default styles;
