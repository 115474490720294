const styles = (theme) => {
  return {
    root: {
      width: '100%',
      margin: 'auto', // paddingTop: theme.spacing(0.5),
      marginTop: theme.spacing(2.5),
      border: '1px solid lightGrey'
    },
    container: {
      height: 500,
      overflow: 'auto'
    }
  };
};

export default styles;
