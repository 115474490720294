const head = [
  {
    id: 'select',
    label: 'Select',
    minWidth: 70,
    width: 70,
    maxWidth: 70,
    align: 'center'
  },
  {
    id: 'agentId',
    label: 'Agent Id',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'uniqueId',
    label: 'Emp Id',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'updatedAt',
    label: 'Last Updated On',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
  },
  {
    id: 'fullName',
    label: 'Name',
    minWidth: 30,
    width: 30,
    maxWidth: 30,
    align: 'center'
  },
  {
    id: 'gender',
    label: 'Gender/Age',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'email',
    label: 'Email Id / Alt. email id',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'center'
  },
  {
    id: 'mobileNumber',
    label: 'Phone no./ Alt. Ph. no.',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'currentAddress',
    label: 'Address',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'currentCity',
    label: 'Current City',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'region',
    label: 'Zone',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'branchName',
    label: 'Branch',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'vaccineStatus',
    label: 'Vaccination status',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'mealpreference',
    label: 'Meal Preference',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  // {
  //   id: 'smokingStatus',
  //   label: 'Smoking Status',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 200,
  //   align: 'center'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: 'freefield1',
    label: 'Free Field 1',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'freefield2',
    label: 'Free Field 2',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'freefield3',
    label: 'Free Field 3',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'pending',
    label: 'Pending Docs',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'toBeExpireDocs',
    label: 'To be Expire Docs',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'expired',
    label: 'Expired Docs',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'tShirtSize',
    label: 'T-Shirt Size',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'nominated',
    label: 'Nominated',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  }
];

export default head;
