import React, { useState } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../../../utils/store';
import { API_HANDLE_ERROR, API_PATCH } from '../../../../utils/api';
import theme from '../../../../utils/theme';

const { withStyles, Grid, Button, Typography, TextField } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function RejectApproval({ classes, handleChangeStatus, rejectId, getallAction }) {
  const [, dispatch] = useStateValue();

  const [rejectData, setRejectData] = useState({
    reasonsToReject: ''
  });

  const handleStateChange = (key, value) => {
    setRejectData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();

    API_PATCH(
      `budgetApproval/approveBudget?incidentForwardHistoryId=${rejectId}&rejectReason=${rejectData.reasonsToReject}`,
      { action: 'rejected' }
    )
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        handleChangeStatus();
        getallAction();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: '350px' }}>
      <Item className={classes.title}>
        <Typography style={{ textAlign: 'center' }}>Reject</Typography>
      </Item>
      <form onSubmit={onHandleConfirm}>
        <Item sx={{ p: 1 }}>
          <Item>
            <TextField
              variant="outlined"
              name="reasonsToReject"
              label="Reason"
              required={true}
              className={classes.formControl}
              multiline
              rows={5}
              onChange={(e) => handleStateChange('reasonsToReject', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>
        </Item>
        <Item sx={{ display: 'flex', gap: '10px', p: 1 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button style={{ ...theme.button.cancel }} onClick={() => handleChangeStatus()}>
              Cancel
            </Button>
          </Item>
          <Item>
            <Button
              type="submit"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              {`Reject`}
            </Button>
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(RejectApproval);
