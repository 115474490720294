import React from 'react';
import styles from './styles';
import { Components } from '../../../../../utils/material-ui';
import columns from './head';
import { StyledTableCell } from './StyledTableCell';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';

const { withStyles, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } =
  Components;

const FamilyMemberDetails = ({ classes, data }) => {
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {data &&
              data !== null &&
              data?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row?._id}>
                    {columns &&
                      columns.map((column) => {
                        const value = row && row !== null ? row[column.id] : '';

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default withStyles(styles)(FamilyMemberDetails);
