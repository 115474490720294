import React, { useState, useEffect } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import ProgressBar from '@ramonak/react-progress-bar';
import { API_WELLNESS_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';

const { withStyles, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

// const data = [
//   {
//     name: 'Total Happiness assessment test',
//     value: 85,
//     color: '#EC443C',
//     backgroundColor: '#FFD8D8'
//   },
//   {
//     name: 'Daily Happiness assessment test',
//     value: 15,
//     color: '#E36414',
//     backgroundColor: '#FEE6D2'
//   },
//   {
//     name: 'Total Re-Happiness assessment test',
//     value: 55,
//     color: '#208250',
//     backgroundColor: '#BCF5D5'
//   },
//   {
//     name: 'Avg. Happiness assessment test Rating',
//     value: '3.8/5',
//     color: '#0F78CD',
//     backgroundColor: '#C0E5FF'
//   }
// ];

const HappinessActivity = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [happinessIndex, setHappinessindex] = useState(0);

  useEffect(() => {
    API_WELLNESS_GET(`wellness/getHappinessActivity`)
      .then((res) => {
        setRows(res?.data);
        const resultIndex = Number(res?.overallHappinessIndex)?.toFixed(2);
        setHappinessindex(resultIndex);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch]);

  const Card = (item) => {
    return (
      <Item
        className={classes.cards}
        sx={{
          backgroundColor: item?.backgroundColor,
          color: item?.color,
          flexDirection: 'column',
          boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px'
        }}
        md={2.5}
        xs={12}
        p={1}>
        <Item sx={{ height: '30px' }}>
          <Typography sx={{ textAlign: 'center', fontSize: '1.6rem', fontWeight: '600' }}>
            {item?.value}
          </Typography>
        </Item>
        <Item mt={1}>
          <Typography sx={{ textAlign: 'center', fontSize: '0.9rem', fontWeight: '600' }}>
            {item?.name}
          </Typography>
        </Item>
      </Item>
    );
  };
  return (
    <Item md={12} xs={12}>
      <Item mt={1} md={12} xs={12}>
        <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>
          Overall Happiness Index of the Company
        </Typography>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: '600',
            ml: `${happinessIndex <= 5 ? happinessIndex : happinessIndex - 3}%`
          }}
          m={1}>
          {`${happinessIndex}/100`}
        </Typography>

        <ProgressBar
          completed={happinessIndex}
          bgColor="linear-gradient(90.07deg, #E92F08 0.12%, #E9A336 51.27%, #EECC38 100.69%)"
          height="45px"
          borderRadius="12px"
          isLabelVisible={false}
          labelColor="#e80909"
          transitionDuration="10"
          transitionTimingFunction="linear"
          maxCompleted={100}
        />
      </Item>

      <Item mt={3} md={12} xs={12}>
        <Item className={classes.centerAlign}>
          <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>
            Happiness Assesment Test
          </Typography>
        </Item>

        <Item mt={1} md={12} xs={12} className={classes.boxContainer}>
          {rows?.map((item) => {
            return Card(item);
          })}
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(HappinessActivity);
