import React, { useState, useEffect } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import Chip from '@mui/material/Chip';
import {
  API_HANDLE_ERROR,
  API_PATCH,
  API_POST,
  PRE_SEND_URL,
  API_GET
} from '../../../../utils/api';
import { useStateValue } from '../../../../utils/store';
import theme from '../../../../utils/theme';
import {
  isValidPincode,
  mobileNumberValidate,
  emailValidate,
  nameValidate
} from '../../../../utils/formValidation';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
// import countryList from '../../../../utils/ListOfAllCountry';
import axios from 'axios';
import { GetAllCity, GetAllCountry, GetAllState } from '../../../../utils/getCountryStateCity';

const {
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete
} = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const officeType = [
  {
    name: 'Branch Office',
    value: 'Branch Office'
  },
  {
    name: 'Head Office',
    value: 'Head Office'
  },
  {
    name: 'Main Office',
    value: 'Main Office'
  },
  {
    name: 'Corporate Office',
    value: 'Corporate Office'
  }
];

const countryData = GetAllCountry();

const AddOffices = ({ classes, handleClose, editData, edit }) => {
  const [, dispatch] = useStateValue();
  const [departmentArray, setDepartmentArray] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [sendData, setSendData] = useState({
    name: '',
    // officeType: '',
    officeCode: '',
    emailId: '',
    website: '',
    address: '',
    country: { name: '' },
    lat: '',
    lng: '',
    city: '',
    state: { name: '' },
    pincode: '',
    phone: '',
    companyImage: '',
    department: [],
    countryCode: '',
    stateCode: ''
  });

  useEffect(() => {
    if (edit) {
      setSendData({
        name: editData?.name,
        lat: editData?.location?.coordinates && editData?.location?.coordinates[1],
        officeCode: editData?.officeCode,
        lng: editData?.location?.coordinates && editData?.location?.coordinates[0],
        emailId: editData?.emailId,
        address: editData?.address,
        city: editData?.city,
        state: { name: editData?.state },
        country: { name: editData?.country },
        countryCode: editData?.countryCode,
        stateCode: editData?.stateCode,
        pincode: editData?.pincode,
        website: editData?.website,
        phone: editData?.phone,
        companyImage: [editData?.branchImage],
        department: editData?.deptInfo || []
      });
    }
  }, [editData]);

  // auto populate city and state
  useEffect(() => {
    const resultState = GetAllState(sendData?.countryCode);
    setStateData(resultState);

    const resultCity = GetAllCity(sendData?.countryCode, sendData?.stateCode);
    setCityData(resultCity);
  }, [sendData?.country, sendData?.state]);

  const getAllDepartments = () => {
    API_GET('corporate/getCorporateDepartments')
      .then((res) => {
        setDepartmentArray(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllDepartments();
  }, []);

  const handleStateChange = (key, value) => {
    if (key === 'name') {
      if (nameValidate(value) === false) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Special charaters are not allowed'
        });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'phone') {
      if (value < 0) {
        setSendData({ ...sendData, phone: '' });
      } else if (value.length <= 10) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'pincode') {
      if (value < 0) {
        setSendData({ ...sendData, pincode: '' });
      } else if (value.length <= 6) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else {
      setSendData((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();

    const resultPostData = {
      name: sendData?.name,
      // officeType: sendData?.officeType,
      officeCode: sendData?.officeCode,
      emailId: sendData?.emailId,
      website: sendData?.website,
      address: sendData?.address,
      city: sendData?.city,
      state: sendData.state?.name,
      country: sendData?.country?.name,
      pincode: sendData?.pincode,
      phone: sendData?.phone,
      branchImage: sendData?.companyImage && sendData?.companyImage[0],
      location: {
        type: 'Point',
        coordinates: [sendData?.lng, sendData?.lat]
      },
      departmentArr: sendData?.department,
      countryCode: sendData?.countryCode,
      stateCode: sendData?.stateCode
    };

    if (emailValidate(sendData?.emailId) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct email'
      });
    } else if (mobileNumberValidate(sendData?.phone) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct mobile number'
      });
    } else if (isValidPincode(sendData?.pincode) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct pincode'
      });
    } else {
      if (edit) {
        API_PATCH(`office/updateOffice/${editData?._id}`, resultPostData)
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            handleClose();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      } else {
        API_POST('office/addOffice', resultPostData)
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            handleClose();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      }
    }
  };

  // upload image

  const handleimage = async (status, event) => {
    const arrayImage = [];
    const img = event.target.files;

    for (let i = 0; i < img.length; i++) {
      const docsType = img[i]?.type?.split('/');
      const name = img[i]?.name;
      const url = await precenturl(docsType[1], name);
      const file = img[i];

      const config = {
        method: 'put',
        url: url.signedUrl,
        headers: {
          'x-ms-blob-content-type': img[i].type,
          'content-type': 'application/octet-stream',
          'x-ms-blob-type': 'BlockBlob'
        },
        data: file
      };

      axios(config)
        .then(function (response) {
          arrayImage.push(url.mediaUrl);
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: response?.data?.message || 'upload Successfully'
          });
        })
        .catch(function (error) {
          API_HANDLE_ERROR(error, dispatch);
        });
    }
    if (status === 'logo') {
      handleStateChange('logo', arrayImage);
    } else if (status === 'companyImage') {
      handleStateChange('companyImage', arrayImage);
    }
  };

  const precenturl = async (type, name) => {
    try {
      const data = await PRE_SEND_URL('user/incident/preSignedMediaUrl', {
        fileType: type,
        fileName: name.split('.')[0],
        featureType: 'doctorProfileImage'
      });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  // delete departement

  const handleRemove = (option) => {
    option &&
      API_GET(`department/checkUserBeforeDelete?type=department&departmentId=${option}`)
        .then((res) => {
          if (res?.success) {
            const result = sendData?.department?.filter((item) => {
              return item?._id !== option;
            });
            setSendData({ ...sendData, department: result });
          } else {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Hi, before deletion, please re-assign the employees related to this branch.'
            });
          }
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  return (
    <Container direction="column" className={classes.newUser}>
      <Typography className={classes.cardHeading} variant="h6">
        {edit ? 'Update Office' : ' Add Office'}
      </Typography>

      <Item p={2}>
        <form onSubmit={onHandleConfirm}>
          <Item className={classes.formContainer}>
            {/* <Item mt={1}>
              <TextField
                variant="outlined"
                name="name"
                label="Company Name"
                sx={{ width: '300px' }}
                value={sendData.name}
                required={true}
                onChange={(e) => handleStateChange('name', e.target.value)}
                type="text"
                size="small"
                fullWidth
              />
  </Item> */}
            <Item mt={2}>
              <FormControl fullWidth size="small" sx={{ width: '300px' }}>
                <InputLabel id="demo-simple-select-label"> Type Of Office </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={sendData.name}
                  label="Type Of Office"
                  onChange={(e) => handleStateChange('name', e.target.value)}>
                  {officeType?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {ConvertToUpperCase(item?.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
            <Item mt={2}>
              <TextField
                variant="outlined"
                name="officeCode"
                label="Branch Code"
                sx={{ width: '300px' }}
                required={true}
                value={sendData.officeCode}
                onChange={(e) => {
                  if (sendData.officeCode.length < 10) {
                    handleStateChange('officeCode', e.target.value);
                  }
                }}
                type="text"
                size="small"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="emailId"
                label="Email"
                sx={{ width: '300px' }}
                required={true}
                size="small"
                value={sendData.emailId}
                onBlur={(e) => {
                  if (e.target.value !== '' && emailValidate(sendData?.emailId) === false) {
                    dispatch({
                      type: 'ALERT_OPEN',
                      severity: 'error',
                      isOpen: true,
                      label: 'Email is not valid'
                    });
                  }
                }}
                onChange={(e) => handleStateChange('emailId', e.target.value)}
                type="email"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="website"
                label="Website"
                sx={{ width: '300px' }}
                required={true}
                value={sendData.website}
                onChange={(e) => handleStateChange('website', e.target.value)}
                type="text"
                size="small"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="address"
                label="Company Address"
                sx={{ width: '300px' }}
                required={true}
                size="small"
                value={sendData.address}
                onChange={(e) => {
                  if (sendData.address.length < 20) {
                    handleStateChange('address', e.target.value);
                  }
                }}
                type="text"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <Autocomplete
                id="combo-box-demo"
                onChange={(e, value) => {
                  if (value === null) {
                    handleStateChange('country', '');
                  }
                  setSendData({
                    ...sendData,
                    countryCode: value?.countryCode
                  });

                  handleStateChange('country', value);
                }}
                options={countryData}
                size="small"
                sx={{ width: '300px' }}
                value={sendData?.country}
                color="#134CDF"
                getOptionLabel={(option) => {
                  return option?.name;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    name="Country"
                    required={true}
                    variant="outlined"
                    type="text"
                  />
                )}
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <Autocomplete
                id="combo-box-demo"
                onChange={(e, value) => {
                  if (value === null) {
                    handleStateChange('state', '');
                  }
                  setSendData({ ...sendData, stateCode: value?.stateCode });
                  handleStateChange('state', value);
                }}
                options={stateData}
                size="small"
                sx={{ width: '300px' }}
                value={sendData?.state}
                color="#134CDF"
                getOptionLabel={(option) => {
                  return option?.name;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    name="Country"
                    required={true}
                    variant="outlined"
                    type="text"
                  />
                )}
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <Autocomplete
                id="combo-box-demo"
                onChange={(e, value) => {
                  if (value === null) {
                    handleStateChange('city', '');
                  }
                  handleStateChange('city', value);
                }}
                options={cityData}
                size="small"
                sx={{ width: '300px' }}
                value={sendData?.city}
                color="#134CDF"
                getOptionLabel={(option) => {
                  return option;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="City"
                    required={true}
                    name="Country"
                    variant="outlined"
                    type="text"
                  />
                )}
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="pincode"
                label="Pincode"
                size="small"
                sx={{ width: '300px' }}
                required={true}
                onBlur={(e) => {
                  if (e.target.value !== '' && isValidPincode(sendData?.pincode) === false) {
                    dispatch({
                      type: 'ALERT_OPEN',
                      severity: 'error',
                      isOpen: true,
                      label: 'Please enter correct pincode'
                    });
                  }
                }}
                value={sendData.pincode}
                onChange={(e) => handleStateChange('pincode', e.target.value)}
                type="number"
                fullWidth
              />
            </Item>
            <Item mt={2}>
              <TextField
                variant="outlined"
                name="phone"
                label="Phone"
                sx={{ width: '300px' }}
                size="small"
                value={sendData.phone}
                required={true}
                onBlur={(e) => {
                  if (e.target.value !== '' && mobileNumberValidate(sendData?.phone) === false) {
                    dispatch({
                      type: 'ALERT_OPEN',
                      severity: 'error',
                      isOpen: true,
                      label: 'Please enter correct phone number'
                    });
                  }
                }}
                onChange={(e) => handleStateChange('phone', e.target.value)}
                type="number"
                fullWidth
              />
            </Item>

            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="lat"
                size="small"
                label="latitude"
                sx={{ width: '300px' }}
                required={true}
                value={sendData.lat}
                onChange={(e) => {
                  if (e.target.value > 0) {
                    handleStateChange('lat', e.target.value);
                  } else {
                    handleStateChange('lat', '');
                  }
                }}
                type="number"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="lng"
                label="longitude"
                sx={{ width: '300px' }}
                required={true}
                size="small"
                value={sendData.lng}
                onChange={(e) => {
                  if (e.target.value > 0) {
                    handleStateChange('lng', e.target.value);
                  } else {
                    handleStateChange('lng', '');
                  }
                }}
                type="text"
                fullWidth
              />
            </Item>
            <Item>
              <Typography sx={theme.typography.typographyFont}>Company Image</Typography>
              <Item
                sx={{
                  height: '35px',
                  border: '1px solid grey',
                  display: 'flex',
                  flexwrap: 'nowrap',
                  width: '300px'
                }}>
                <input
                  style={{ padding: '5px' }}
                  variant="outlined"
                  accept=".jpeg, .jpg, .png"
                  type="file"
                  multiple
                  size="small"
                  onChange={(e) => handleimage('companyImage', e)}
                />
                {sendData?.companyImage && (
                  <Item>
                    <img alt="" src={sendData?.companyImage} height="100%" width="40px" />
                  </Item>
                )}
              </Item>
            </Item>
            <Item
              sx={{
                height: '35px',
                display: 'flex',
                width: '300px'
              }}></Item>
            <Item sx={{ width: '630px' }}>
              {edit ? (
                <Autocomplete
                  multiple
                  id="combo-box-demo"
                  limitTags={5}
                  size="small"
                  fullWidth
                  value={sendData?.department}
                  onChange={(e, value) => {
                    handleStateChange('department', value);
                  }}
                  getOptionSelected={(option, value) => option?.department === value}
                  options={departmentArray}
                  disableClearable
                  getOptionLabel={(option) => option?.department}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option?.department}
                        {...getTagProps({ index })}
                        onDelete={() => handleRemove(option?._id)}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="city"
                      variant="outlined"
                      fullWidth
                      label="Department"
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  multiple
                  id="combo-box-demo"
                  limitTags={5}
                  size="small"
                  value={sendData?.department}
                  onChange={(e, value) => {
                    handleStateChange('department', value);
                  }}
                  getOptionSelected={(option, value) => option?.department === value}
                  options={departmentArray}
                  disableClearable
                  shouldItemRender={(item, value) => {
                    return !sendData?.department?.includes(item);
                  }}
                  getOptionLabel={(option) => option?.department}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option?.department}
                        {...getTagProps({ index })}
                        onDelete={() => handleRemove(option?._id)}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="city"
                      variant="outlined"
                      fullWidth
                      label="Department"
                    />
                  )}
                />
              )}
            </Item>
          </Item>

          <Container container>
            <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '20px', pt: 2 }}>
              <Item>
                <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
                  Cancel
                </Button>
              </Item>
              <Item>
                <Button
                  type="submit"
                  style={{
                    ...theme.button.submit,
                    backgroundColor: theme.palette.primary.main
                  }}>
                  {`Submit`}
                </Button>
              </Item>
            </Item>
          </Container>
        </form>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(AddOffices);
