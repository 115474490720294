import React, { useState } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import DoctorsData from '../DoctorsData';
import styles from './styles';
import theme from '../../utils/theme';

const { withStyles, Grid, TextField, InputAdornment } = Components;
const { SearchIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const DoctorProfile = ({ classes }) => {
  const [searchText, setSearchText] = useState('');

  return (
    <Container>
      <Item md={12} xs={12} mt={3}>
        <Item md={4} xs={12}>
          <TextField
            placeholder="Search by doctor name"
            size="small"
            variant="outlined"
            fullWidth
            sx={theme.customTextFiled}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Item>
      </Item>
      <Item md={12}>
        <DoctorsData searchText={searchText} />
      </Item>
    </Container>
  );
};

export default withStyles(styles)(DoctorProfile);
