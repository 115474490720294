import React, { useState } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import RenderDocuments from './RenderDocument';
import { useStateValue } from '../../../../utils/store';
import DownloadMultipleNestedFolderAsZip from '../../../../utils/downloadMultipleNestedFolderAsZip';

const {
  withStyles,
  Grid,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  CircularProgress
} = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const docs = [
  { id: 1, checked: false, name: 'Passport' },
  // { id: 2, checked: false, name: 'Visa' },
  { id: 3, checked: false, name: 'PAN Card' },
  { id: 4, checked: false, name: 'Aadhar card' }
  // { id: 5, checked: false, name: 'Vaccination' },
  // { id: 6, checked: false, name: 'Health Insurance' },
  // { id: 7, checked: false, name: 'Travel Insurance' },
  // { id: 8, checked: false, name: 'Additional Docs 1' },
  // { id: 9, checked: false, name: 'Photo' }
];

function DocsDownloadModel({
  classes,
  handleClose,
  checkboxData,
  rows,
  handleLoader,
  isLoadingDocs
}) {
  const [, dispatch] = useStateValue();
  const [allChecked, setAllChecked] = useState(false);
  // const [store] = useStateValue();
  // const resultDocs =
  //   store?.corporateId === theme.corporate.mliCorporateId
  //     ? docs
  //     : [
  //         ...docs,
  //         { id: 6, checked: false, name: 'Health Insurance' },
  //         { id: 7, checked: false, name: 'Travel Insurance' }
  //       ];

  const resultDocs = docs;

  const [checkboxes, setCheckboxes] = useState(resultDocs);

  const handleSelectAll = () => {
    setCheckboxes(checkboxes.map((cb) => ({ ...cb, checked: !allChecked })));
    setAllChecked(!allChecked);
  };

  const handleCheckboxChange = (id) => {
    setCheckboxes(checkboxes.map((cb) => (cb.id === id ? { ...cb, checked: !cb.checked } : cb)));
    const checkedBoxes = checkboxes.filter((cb) => cb.checked === true);
    if (checkedBoxes.length === checkboxes.length) setAllChecked(true);
    else setAllChecked(false);
  };

  // download data logic

  const handleDownload = () => {
    const resultDocs = checkboxes
      ?.filter((item) => item?.checked !== false)
      ?.map((item) => {
        return item?.name;
      });

    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const selectedEmployeeData = filteredKeys.map((item) => rows[item]).flat();

    const resultEmployee = selectedEmployeeData?.map((item) => {
      return {
        name: item?.fullName || item?.name,
        id: item?.agentId || item?.employeeId || item?.uniqueId,
        folders: RenderDocuments(resultDocs, item)
      };
    });

    if (resultDocs?.length === 0) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Choose atleast one document'
      });
    } else {
      DownloadMultipleNestedFolderAsZip(resultEmployee, 'Employee Docs', handleLoader, handleClose);
    }
  };

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: '430px' }}>
      <Item
        className={classes.remainderBell}
        sx={{
          backgroundColor: theme.palette.primary.main
        }}>
        <img
          style={{ height: '70px', width: '70px' }}
          src={require(`../../../../Assets/TravelIcon/downloadIcon.png`).default}
          alt="Download icon"
        />
      </Item>
      <Item sx={{ p: 1 }}>
        <Typography sx={{ ml: 2, mt: 1, fontWeight: '600' }}>
          Please choose the Docs for Download
        </Typography>
        <Item sx={{ display: 'flex' }}>
          <FormControl sx={{ m: 2 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={allChecked && checkboxes.filter((cb) => !cb.checked).length > 0}
                    checked={allChecked}
                    onChange={handleSelectAll}
                    name="Select All"
                  />
                }
                label="Select All"
              />
              {checkboxes.map((cb, index) => {
                return index !== 0 && index % 2 === 0 ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={cb.id}
                        checked={cb.checked}
                        onChange={() => handleCheckboxChange(cb.id)}
                      />
                    }
                    label={cb.name}
                  />
                ) : (
                  ''
                );
              })}
            </FormGroup>
          </FormControl>
          <FormControl sx={{ m: 2 }}>
            <FormGroup>
              {checkboxes.map((cb, index) => {
                return index === 0 || index % 2 === 1 ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={cb.id}
                        checked={cb.checked}
                        onChange={() => handleCheckboxChange(cb.id)}
                      />
                    }
                    label={cb.name}
                  />
                ) : (
                  ''
                );
              })}
            </FormGroup>
          </FormControl>
        </Item>
      </Item>

      <Container sx={{ display: 'flex', gap: '20px', p: 1 }}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <Button style={theme.button.cancel} onClick={() => handleClose()}>
            Cancel
          </Button>
        </Item>
        <Item>
          {isLoadingDocs ? (
            <Button
              size="small"
              style={{
                backgroundColor: theme.palette.primary.tableHeadingColor,
                color: theme.palette.common.white
              }}>
              <CircularProgress sx={{ color: '#FFFFFF' }} size={20} />
              Downloding
            </Button>
          ) : (
            <Button
              onClick={handleDownload}
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              Download
            </Button>
          )}
        </Item>
      </Container>
    </Container>
  );
}

export default withStyles(styles)(DocsDownloadModel);
