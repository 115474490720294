const styles = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(0, 2, 2, 2),
      marginTop: theme.spacing(1),
      width: '100%'
    },

    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px',
      flexWrap: 'wrap'
    },

    cards: {
      minHeight: '120px',
      maxHeight: '150px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    fitnessContainer: {
      display: 'flex',
      gap: '7px',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly'
    },
    fitnessCard: {
      minHeight: '120px',
      maxHeight: '150px',
      minWidth: '180px',
      backgroundColor: '#FDFDFF',
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px'
    },
    subFitnessCard: {
      height: '75%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '15px'
      // flexWrap: 'wrap'
    },
    healthCard: {
      height: '250px',
      backgroundColor: '#FDFDFF',
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      display: 'flex'
    }
  };
};

export default styles;
