const styles = (theme) => {
  return {
    newUser: {
      width: 'auto',
      height: 'auto',
      boxShadow: '0px 1px 5px grey',
      borderRadius: theme.spacing(2),
      padding: theme.spacing(1, 0, 0, 0)
    },
    cardHeading: {
      padding: '8px 20px',
      borderRadius: '8px 8px 0 0',
      color: theme.palette.primary.tableHeadingColor
    },

    formContainer: {
      padding: theme.spacing(2.5)
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      maxHeight: '80vh',
      overflow: 'auto'
    },
    vehicle: {
      width: '45%'
    },
    inputText1: {
      color: '#454545',
      marginTop: theme.spacing(0.5)
    },
    imageContain: {
      height: '120px',
      width: '120px',
      borderRadius: '25px',
      border: '2px dotted grey',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      cursor: 'pointer'
    },
    imgProfile: {
      objectFit: 'cover',
      height: '120px',
      width: '120px',
      borderRadius: '25px',
      border: `4px solid ${theme.headingBgColor}`
    },
    starIcon: {
      color: '#FF6760'
    }
  };
};

export default styles;
