const styles = (theme) => {
  return {
    doctorConatiner: {
      height: 'auto',
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '2%',
      paddingBottom: theme.spacing(2)
    },
    addDoctorCard: {
      height: '300px',
      // minWidth: '260px',
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      display: 'flex',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.26)',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      cursor: 'pointer',
      position: 'sticky'
    },
    doctorInfoCard: {
      height: '300px',
      // minWidth: '270px',
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.26)'
    },
    logoContainer: {
      display: 'flex'
    },
    heading: {
      color: '#979797'
    },
    imgContainer: {
      height: '60px',
      width: '60px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center'
    },
    icons: {
      color: '#9F9F9F',
      cursor: 'pointer',
      fontSize: '14px'
    },
    subHeading: {
      marginTop: theme.spacing(0.3),
      fontSize: '14px',
      fontWeight: '600',
      textAlign: 'center',
      color: '#818181'
    },
    subHeading1: {
      fontSize: '14px',
      color: '#818181'
    },
    containIcon: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      borderRadius: '50%',
      height: '20px',
      width: '15px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
};

export default styles;
