const styles = (theme) => {
  return {
    newUser: {
      minWidth: '60vw',
      maxWidth: '60vw',
      padding: theme.spacing(3)
    },
    cardHeading: {
      padding: '7px 20px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px 5px 0 0',
      color: theme.palette.common.white
    },
    formContainer: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    subContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px'
    },
    label: {
      color: '#868686',
      fontSize: '1.1rem'
    }
  };
};

export default styles;
