const head = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    width: 100,
    maxWidth: 100
  },

  {
    id: 'email',
    label: 'Email',
    minWidth: 250,
    width: 250,
    maxWidth: 250,
    align: 'left'
  },
  {
    id: '',
    label: '',
    minWidth: 250,
    width: 250,
    maxWidth: 250,
    align: 'center'
  },

  {
    id: 'action',
    label: 'Action',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  }
];

export default head;
