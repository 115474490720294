const styles = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(0, 2),
      justifyContent: 'space-between'
    },
    filterContainer: {
      width: '100%',
      height: 'auto'
    },

    tableContainer: {
      width: '100%',
      height: 'auto',
      paddingBottom: theme.spacing(1),
      marginTop: theme.spacing(2)
    },
    headingContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0.2, 0.5)
      // flexWrap: 'wrap'
    }
  };
};

export default styles;
