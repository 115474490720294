import { TableContainer } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from '../styles';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';

const { withStyles } = Components;

const SosReviewTable = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState({});

  const getSosAnalytics = () => {
    API_GET(
      `reports/getSosReview?fromDate=${selectedFromDate}&toDate=${selectedToDate}&city=${location}`
    )
      .then((res) => {
        setRows(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getSosAnalytics();
  }, [selectedFromDate, selectedToDate, location]);

  // const handleExport = () => {
  //   downloadDataToExcelFile(
  //     [rows],
  //     `SOS Review Data_${location}_(${selectedFromDate} To ${selectedToDate})`
  //   );
  // };

  return (
    <table style={{ width: '100%' }}>
      <tr>
        <th className={classes.tableHead}>SOS Review</th>
      </tr>
      <TableContainer
        style={{
          height: '250px',
          overflow: 'auto'
        }}>
        {Object.keys(rows)?.map((item) => {
          return (
            <tr>
              <td className={classes.tablerow2}>{item}</td>
              <td className={classes.tablerow4}>{rows[item]}</td>
            </tr>
          );
        })}
      </TableContainer>
    </table>
  );
};
export default withStyles(styles)(SosReviewTable);
