import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import React, { useState } from 'react';
import { getDocsDate } from '../../../../utils/moment';
import DownloadMultipleFolderAsZip from '../../../../utils/downloadMultipleFlderAsZip';
import { useStateValue } from '../../../../utils/store';
import theme from '../../../../utils/theme';

const { Grid, Typography, withStyles, TextField } = Components;
const { CancelIcon, ArrowBackIosIcon, DownloadForOfflineIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid container item {...props} />;

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 155px)',
  gridAutoRows: '145px',
  gap: '10px',
  color: 'white',
  fontWeight: 'bold',
  justifyContent: 'center',
  boxSizing: 'border-box',
  padding: '20px'
};

const gridItemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  flexDirection: 'column',
  cursor: 'pointer'
};

// all type of documents

const resultDocs = [
  {
    name: 'Passport',
    imageName: 'Passport',
    color: '#07A0C3'
  },
  // {
  //   name: 'Vaccination',
  //   imageName: 'Vaccination',
  //   color: '#4B8B8B'
  // },
  {
    name: 'Aadhaar Card',
    imageName: 'Aadhar',
    color: '#82AF6E'
  },
  // {
  //   name: 'Visa',
  //   imageName: 'Visa',
  //   color: '#786ECA'
  // },
  {
    name: 'Pan Card',
    imageName: 'Pan',
    color: '#F76889'
  }

  // {
  //   name: 'Add. Document 1',
  //   imageName: 'AddDoc1',
  //   color: '#FFC857'
  // },
  // {
  //   name: 'Photo',
  //   imageName: 'AddDoc2',
  //   color: '#894E40'
  // }
];

function EmpDocModal({ classes, docsData, handleClose, docsStatus }) {
  const [isOpenLarge, setOpenLarge] = useState(false);
  const [name, setName] = useState('');
  const [, dispatch] = useStateValue();

  // const docsArr =
  //   store?.corporateId === theme.corporate.mliCorporateId
  //     ? resultDocs
  //     : [
  //         ...resultDocs,
  //         {
  //           name: 'Health Insurance',
  //           imageName: 'Health',
  //           color: '#EF805C'
  //         },
  //         {
  //           name: 'Travel Insurance',
  //           imageName: 'Travel',
  //           color: '#EE964B'
  //         }
  //       ];

  const docsArr = resultDocs;

  // set previous Data

  const passportD = {
    country: docsData?.passportDocs && docsData?.passportDocs[0]?.country,
    passportNo: docsData?.passportDocs && docsData?.passportDocs[0]?.passportNo,
    issueDate: docsData?.passportDocs && docsData?.passportDocs[0]?.issueDate,
    expiryDate: docsData?.passportDocs && docsData?.passportDocs[0]?.expiryDate,
    passportFrontLink: [
      docsData?.passportDocs &&
        docsData?.passportDocs[0]?.links &&
        docsData?.passportDocs[0]?.links[0]
    ],
    passportBackLink: [
      docsData?.passportDocs &&
        docsData?.passportDocs[0]?.links &&
        docsData?.passportDocs[0]?.links[1]
    ]
  };

  const AaadhaarD = {
    aadhaarNo: docsData?.aadhaarCardDocs && docsData?.aadhaarCardDocs[0]?.aadhaarNo,
    aadhaarAddress: docsData?.aadhaarCardDocs && docsData?.aadhaarCardDocs[0]?.aadhaarAddress,
    dateOfBirth: docsData?.aadhaarCardDocs && docsData?.aadhaarCardDocs[0]?.dateOfBirth,
    aadhaarFrontLink: [
      docsData?.aadhaarCardDocs &&
        docsData?.aadhaarCardDocs[0]?.links &&
        docsData?.aadhaarCardDocs[0]?.links[0]
    ],
    aadhaarBackLink: [
      docsData?.aadhaarCardDocs &&
        docsData?.aadhaarCardDocs[0]?.links &&
        docsData?.aadhaarCardDocs[0]?.links[1]
    ]
  };

  const VisaD = {
    country: docsData?.visaDocs && docsData?.visaDocs[0]?.country,
    visaNo: docsData?.visaDocs && docsData?.visaDocs[0]?.visaNo,
    visaType: docsData?.visaDocs && docsData?.visaDocs[0]?.visaType,
    entry: docsData?.visaDocs && docsData?.visaDocs[0]?.entry,
    issueDate: docsData?.visaDocs && docsData?.visaDocs[0]?.issueDate,
    expiryDate: docsData?.visaDocs && docsData?.visaDocs[0]?.expiryDate,
    visaLink: docsData?.visaDocs && docsData?.visaDocs[0]?.links
  };

  const TravelD = {
    country: docsData?.travelInsuranceDocs && docsData?.travelInsuranceDocs[0]?.country,
    registerationNo:
      docsData?.travelInsuranceDocs && docsData?.travelInsuranceDocs[0]?.registerationNo,
    issueDate: docsData?.travelInsuranceDocs && docsData?.travelInsuranceDocs[0]?.issueDate,
    expiryDate: docsData?.travelInsuranceDocs && docsData?.travelInsuranceDocs[0]?.expiryDate,
    travelInsuranceLink: docsData?.travelInsuranceDocs && docsData?.travelInsuranceDocs[0]?.links
  };

  const HealthD = {
    companyName: docsData?.healthInsuranceDocs && docsData?.healthInsuranceDocs[0]?.companyName,
    registerationNo:
      docsData?.healthInsuranceDocs && docsData?.healthInsuranceDocs[0]?.registerationNo,
    issueDate: docsData?.healthInsuranceDocs && docsData?.healthInsuranceDocs[0]?.issueDate,
    expiryDate: docsData?.healthInsuranceDocs && docsData?.healthInsuranceDocs[0]?.expiryDate,
    healthInsuranceLink: docsData?.healthInsuranceDocs && docsData?.healthInsuranceDocs[0]?.links
  };

  const PanD = {
    panNumber: docsData?.panCardDocs && docsData?.panCardDocs[0]?.panNumber,
    dateOfBirth: docsData?.panCardDocs && docsData?.panCardDocs[0]?.dateOfBirth,
    panCardLink: docsData?.panCardDocs && docsData?.panCardDocs[0]?.links
  };
  const AdditionalDocs1D = {
    additionalDocslink:
      docsData?.additionalDoc1 &&
      (docsData?.additionalDoc1 !== undefined || docsData?.additionalDoc1 !== '')
        ? Object.keys(docsData?.additionalDoc1)?.length !== 0
          ? [docsData?.additionalDoc1]
          : []
        : []
  };
  const AdditionalDocs2D = {
    additionalDocslink:
      docsData?.additionalDoc2 &&
      (docsData?.additionalDoc2 !== undefined || docsData?.additionalDoc2 !== '')
        ? Object.keys(docsData?.additionalDoc2)?.length !== 0
          ? [docsData?.additionalDoc2]
          : []
        : []
  };

  const VaccinationD = {
    vaccination1stDocs: {
      vaccinationStatus:
        docsData?.vaccinationDocs && docsData?.vaccinationDocs[0]?.vaccinationStatus,
      vaccineType: docsData?.vaccinationDocs && docsData?.vaccinationDocs[0]?.vaccineType,
      nextDate: docsData?.vaccinationDocs && docsData?.vaccinationDocs[0]?.nextDate,
      currentDate: docsData?.vaccinationDocs && docsData?.vaccinationDocs[0]?.currentDate,
      links: docsData?.vaccinationDocs && docsData?.vaccinationDocs[0]?.links
    },
    vaccination2ndDocs: {
      vaccinationStatus:
        docsData?.vaccinationDocs && docsData?.vaccinationDocs[1]?.vaccinationStatus,
      vaccineType: docsData?.vaccinationDocs && docsData?.vaccinationDocs[1]?.vaccineType,
      nextDate: docsData?.vaccinationDocs && docsData?.vaccinationDocs[1]?.nextDate,
      currentDate: docsData?.vaccinationDocs && docsData?.vaccinationDocs[1]?.currentDate,
      links: docsData?.vaccinationDocs && docsData?.vaccinationDocs[1]?.links
    },
    vaccinationBoosterDocs: {
      vaccinationStatus:
        docsData?.vaccinationDocs && docsData?.vaccinationDocs[2]?.vaccinationStatus,
      vaccineType: docsData?.vaccinationDocs && docsData?.vaccinationDocs[2]?.vaccineType,
      nextDate: docsData?.vaccinationDocs && docsData?.vaccinationDocs[2]?.nextDate,
      currentDate: docsData?.vaccinationDocs && docsData?.vaccinationDocs[2]?.currentDate,
      links: docsData?.vaccinationDocs && docsData?.vaccinationDocs[2]?.links
    }
  };

  // document wise input boxex

  const aadhaarCardDocs = () => {
    return (
      <Item className={classes.tableContainer}>
        <table border={1} style={{ borderColor: `${theme.palette.primary.main}` }}>
          <tr>
            <th className={classes.tableHead}>Document photo</th>
            <th className={classes.tableHead}>Document Details</th>
          </tr>
          <tr>
            <td className={classes.equalWidth}>
              <Item className={classes.imgContainer}>
                {AaadhaarD?.aadhaarFrontLink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="Front" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}

                {AaadhaarD?.aadhaarBackLink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
              </Item>
            </td>

            <td className={classes.equalWidth}>
              <Item>
                <Item>
                  <TextField
                    variant="outlined"
                    name="agentId"
                    label="Aadhaar No."
                    size="small"
                    sx={{ width: '300px' }}
                    value={AaadhaarD?.aadhaarNo}
                    className={classes.formControl}
                    // onChange={(e) => handleStateChange('agentId', e.target.value)}
                    type="text"
                    fullWidth
                  />
                </Item>
                {/* <Item mt={2}>
                  <TextField
                    variant="outlined"
                    name="packageId"
                    label="Address"
                    size="small"
                    multiline
                    value={AaadhaarD?.aadhaarAddress}
                    rows={2}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    // onChange={(e) => handleStateChange('packageId', e.target.value)}
                    type="text"
                    fullWidth
                  />
              </Item> */}
              </Item>
            </td>
          </tr>
        </table>
      </Item>
    );
  };
  const passportDocs = () => {
    return (
      <Item className={classes.tableContainer}>
        <table border={1} style={{ borderColor: `${theme.palette.primary.main}` }}>
          <tr>
            <th className={classes.tableHead}>Document photo</th>
            <th className={classes.tableHead}>Document Details</th>
          </tr>
          <tr>
            <td className={classes.equalWidth}>
              <Item className={classes.imgContainer}>
                {passportD?.passportFrontLink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}

                {passportD?.passportBackLink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
              </Item>
            </td>

            <td className={classes.equalWidth}>
              <Item mt={2}>
                <Item>
                  <TextField
                    variant="outlined"
                    label="Passport No."
                    size="small"
                    sx={{ width: '300px' }}
                    value={passportD?.passportNo}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Country"
                    size="small"
                    value={passportD?.country}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Issue Date"
                    size="small"
                    value={getDocsDate(passportD?.issueDate)}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Expiry Date"
                    size="small"
                    value={getDocsDate(passportD?.expiryDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
              </Item>
            </td>
          </tr>
        </table>
      </Item>
    );
  };
  const visaDocs = () => {
    return (
      <Item className={classes.tableContainer}>
        <table border={1} style={{ borderColor: `${theme.palette.primary.main}` }}>
          <tr>
            <th className={classes.tableHead}>Document photo</th>
            <th className={classes.tableHead}>Document Details</th>
          </tr>
          <tr>
            <td className={classes.equalWidth}>
              <Item className={classes.imgContainer}>
                {VisaD?.visaLink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
              </Item>
            </td>

            <td className={classes.equalWidth}>
              <Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Visa No."
                    size="small"
                    value={VisaD?.visaNo}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Country"
                    size="small"
                    value={VisaD?.country}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Visa Type"
                    size="small"
                    value={VisaD?.visaType}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Entry"
                    size="small"
                    value={VisaD?.entry}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Issue Date"
                    size="small"
                    value={getDocsDate(VisaD?.issueDate)}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Expiry Date"
                    size="small"
                    value={getDocsDate(VisaD?.expiryDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
              </Item>
            </td>
          </tr>
        </table>
      </Item>
    );
  };
  const TravelInsurance = () => {
    return (
      <Item className={classes.tableContainer}>
        <table border={1} style={{ borderColor: `${theme.palette.primary.main}` }}>
          <tr>
            <th className={classes.tableHead}>Document photo</th>
            <th className={classes.tableHead}>Document Details</th>
          </tr>
          <tr>
            <td className={classes.equalWidth}>
              <Item className={classes.imgContainer}>
                {TravelD?.travelInsuranceLink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
              </Item>
            </td>

            <td className={classes.equalWidth}>
              <Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Registeration No."
                    size="small"
                    value={TravelD?.registerationNo}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Country"
                    size="small"
                    value={TravelD?.country}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Issue Date"
                    size="small"
                    value={getDocsDate(TravelD?.issueDate)}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Expiry Date"
                    size="small"
                    value={getDocsDate(TravelD?.expiryDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Company"
                    size="small"
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
              </Item>
            </td>
          </tr>
        </table>
      </Item>
    );
  };
  const HealthInsuarance = () => {
    return (
      <Item className={classes.tableContainer}>
        <table border={1} style={{ borderColor: `${theme.palette.primary.main}` }}>
          <tr>
            <th className={classes.tableHead}>Document photo</th>
            <th className={classes.tableHead}>Document Details</th>
          </tr>
          <tr>
            <td className={classes.equalWidth}>
              <Item className={classes.imgContainer}>
                {HealthD?.healthInsuranceLink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
              </Item>
            </td>

            <td className={classes.equalWidth}>
              <Item>
                <Item>
                  <TextField
                    variant="outlined"
                    label="Registeration No."
                    size="small"
                    value={HealthD?.registerationNo}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>

                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Issue Date"
                    size="small"
                    value={getDocsDate(HealthD?.issueDate)}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Expiry Date"
                    size="small"
                    value={getDocsDate(HealthD?.expiryDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Company"
                    value={HealthD?.companyName}
                    size="small"
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
              </Item>
            </td>
          </tr>
        </table>
      </Item>
    );
  };
  const AdditionalDocs1 = () => {
    return (
      <Item className={classes.tableContainer}>
        <table border={1} style={{ borderColor: `${theme.palette.primary.main}` }}>
          <tr>
            <th className={classes.tableHead}>Document photo</th>
            <th className={classes.tableHead}>Document Details</th>
          </tr>
          <tr>
            <td className={classes.equalWidth}>
              <Item className={classes.imgContainer}>
                {AdditionalDocs1D?.additionalDocslink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
              </Item>
            </td>

            <td className={classes.equalWidth}>
              <Typography>Additional Docs</Typography>
            </td>
          </tr>
        </table>
      </Item>
    );
  };
  const AdditionalDocs2 = () => {
    return (
      <Item className={classes.tableContainer}>
        <table border={1} style={{ borderColor: `${theme.palette.primary.main}` }}>
          <tr>
            <th className={classes.tableHead}>Document photo</th>
            <th className={classes.tableHead}>Document Details</th>
          </tr>
          <tr>
            <td className={classes.equalWidth}>
              <Item className={classes.imgContainer}>
                {AdditionalDocs2D?.additionalDocslink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
              </Item>
            </td>

            <td className={classes.equalWidth}>
              <Typography>Additional Docs</Typography>
            </td>
          </tr>
        </table>
      </Item>
    );
  };

  const PancardDocs = () => {
    return (
      <Item className={classes.tableContainer}>
        <table border={1} style={{ borderColor: `${theme.palette.primary.main}` }}>
          <tr>
            <th className={classes.tableHead}>Document photo</th>
            <th className={classes.tableHead}>Document Details</th>
          </tr>
          <tr>
            <td className={classes.equalWidth}>
              <Item className={classes.imgContainer}>
                {PanD?.panCardLink?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
              </Item>
            </td>

            <td className={classes.equalWidth}>
              <Item>
                <Item>
                  <TextField
                    variant="outlined"
                    label="Pan No."
                    size="small"
                    value={PanD?.panNumber}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>

                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="DOB"
                    value={getDocsDate(PanD?.dateOfBirth)}
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
              </Item>
            </td>
          </tr>
        </table>
      </Item>
    );
  };
  const VaccinationDocs = () => {
    return (
      <Item className={classes.tableContainer}>
        <table border={1} style={{ borderColor: `${theme.palette.primary.main}` }}>
          <tr>
            <th className={classes.tableHead}>Document photo</th>
            <th className={classes.tableHead}>Document Details</th>
          </tr>
          <tr>
            <td className={classes.equalWidth}>
              <Item className={classes.imgContainer}>
                {VaccinationD?.vaccination1stDocs?.links?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
                {VaccinationD?.vaccination2ndDocs?.links?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
                {VaccinationD?.vaccinationBoosterDocs?.links?.map((item, index) => {
                  return (
                    <React.Fragment>
                      {!item ? (
                        ''
                      ) : (
                        <img key={index} src={item?.uri} alt="back" width="275px" height="150px" />
                      )}
                    </React.Fragment>
                  );
                })}
              </Item>
            </td>

            <td className={classes.equalWidth}>
              <Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="vaccination status"
                    size="small"
                    value={VaccinationD?.vaccination1stDocs?.vaccinationStatus}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Vaccination Type"
                    size="small"
                    value={VaccinationD?.vaccination1stDocs?.vaccineType}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>

                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Issue Date"
                    value={getDocsDate(VaccinationD?.vaccination1stDocs?.currentDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Expiry Date"
                    value={getDocsDate(VaccinationD?.vaccination1stDocs?.nextDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
              </Item>
              <Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="vaccination status"
                    size="small"
                    value={VaccinationD?.vaccination2ndDocs?.vaccinationStatus}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Vaccination Type"
                    size="small"
                    value={VaccinationD?.vaccination2ndDocs?.vaccineType}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>

                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Issue Date"
                    value={getDocsDate(VaccinationD?.vaccination2ndDocs?.currentDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Expiry Date"
                    value={getDocsDate(VaccinationD?.vaccination2ndDocs?.nextDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
              </Item>
              <Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="vaccination status"
                    size="small"
                    value={VaccinationD?.vaccinationBoosterDocs?.vaccinationStatus}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    label="Vaccination Type"
                    size="small"
                    value={VaccinationD?.vaccinationBoosterDocs?.vaccineType}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="text"
                    fullWidth
                  />
                </Item>

                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Issue Date"
                    value={getDocsDate(VaccinationD?.vaccinationBoosterDocs?.currentDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Expiry Date"
                    value={getDocsDate(VaccinationD?.vaccinationBoosterDocs?.nextDate)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{ width: '300px' }}
                    className={classes.formControl}
                    type="date"
                    fullWidth
                  />
                </Item>
              </Item>
            </td>
          </tr>
        </table>
      </Item>
    );
  };

  const handleDetails = (doc) => {
    setOpenLarge(true);
    setName(doc?.name);
  };

  const handlecloseLarge = () => {
    setOpenLarge(false);
  };

  // render data according to docs type

  const render = (name) => {
    let content = null;
    switch (name) {
      case 'Passport':
        content = <div>{passportDocs()}</div>;
        break;
      case 'Vaccination':
        content = <div>{VaccinationDocs()}</div>;
        break;
      case 'Aadhaar Card':
        content = <div>{aadhaarCardDocs()}</div>;
        break;
      case 'Pan Card':
        content = <div>{PancardDocs()}</div>;
        break;
      case 'Visa':
        content = <div>{visaDocs()}</div>;
        break;
      case 'Travel Insurance':
        content = <div>{TravelInsurance()}</div>;
        break;
      case 'Health Insurance':
        content = <div>{HealthInsuarance()}</div>;
        break;
      case 'Add. Document 1':
        content = <div>{AdditionalDocs1()}</div>;
        break;
      case 'Photo':
        content = <div>{AdditionalDocs2()}</div>;
        break;
      default:
        content = <div>Error</div>;
        break;
    }
    return content;
  };

  // download multiple folder as zip

  const aadhaarF = AaadhaarD?.aadhaarFrontLink && AaadhaarD?.aadhaarFrontLink[0];
  const aadhaarB = AaadhaarD?.aadhaarBackLink && AaadhaarD?.aadhaarBackLink[0];
  const passportF = passportD?.passportFrontLink && passportD?.passportFrontLink[0];
  const passportB = passportD?.passportBackLink && passportD?.passportBackLink[0];
  const visaL = VisaD?.visaLink && VisaD?.visaLink[0];
  const panL = PanD?.panCardLink && PanD?.panCardLink[0];
  const additionalD1 =
    AdditionalDocs1D?.additionalDocslink && AdditionalDocs1D?.additionalDocslink[0];
  const additionalD2 =
    AdditionalDocs2D?.additionalDocslink && AdditionalDocs2D?.additionalDocslink[0];
  const travelL = TravelD?.travelInsuranceLink && TravelD?.travelInsuranceLink[0];
  const healthL = HealthD?.healthInsuranceLink && HealthD?.healthInsuranceLink[0];
  const vaccine1stL =
    VaccinationD?.vaccination1stDocs?.links && VaccinationD?.vaccination1stDocs?.links[0];
  const vaccine2ndL =
    VaccinationD?.vaccination2ndDocs?.links && VaccinationD?.vaccination2ndDocs?.links[0];
  const vaccineBL =
    VaccinationD?.vaccinationBoosterDocs?.links && VaccinationD?.vaccinationBoosterDocs?.links[0];

  const aadhar = {
    name: aadhaarF?.mediaType || aadhaarB?.mediaType ? 'Adhar Card' : '',
    files: [
      {
        name: aadhaarF?.mediaType ? `AdharFront.${aadhaarF?.mediaType}` : '',
        url: aadhaarF?.uri
      },
      {
        name: aadhaarB?.mediaType ? `AdharBack.${aadhaarB?.mediaType}` : '',
        url: aadhaarB?.uri
      }
    ]
  };

  const passport = {
    name: passportF?.mediaType || passportB?.mediaType ? 'Passport' : '',
    files: [
      {
        name: passportF?.mediaType ? `Passport Front.${passportF?.mediaType}` : '',
        url: passportF?.uri
      },
      {
        name: passportB?.uri ? `Passport Back.${passportB?.mediaType}` : '',
        url: passportB?.uri
      }
    ]
  };

  const pan = {
    name: panL?.mediaType ? 'Pan card' : '',
    files: [
      {
        name: panL?.mediaType ? `Pan Card.${panL?.mediaType}` : '',
        url: panL?.uri
      }
    ]
  };

  const visa = {
    name: visaL?.mediaType ? 'Visa' : '',
    files: [
      {
        name: visaL?.mediaType ? `Visa.${visaL?.mediaType}` : '',
        url: visaL?.uri
      }
    ]
  };

  const travel = {
    name: travelL?.mediaType ? 'Travelled Insurance' : '',
    files: [
      {
        name: travelL?.mediaType ? `Travel Insurance.${travelL?.mediaType}` : '',
        url: travelL?.uri
      }
    ]
  };

  const additionalDocs2 = {
    name: additionalD2?.mediaType ? 'Photo' : '',
    files: [
      {
        name: additionalD2?.mediaType ? `Additional Docs 2.${additionalD1?.mediaType}` : '',
        url: additionalD2?.uri
      }
    ]
  };

  const additionalDocs1 = {
    name: additionalD1?.mediaType ? 'Additional Docs 1' : '',
    files: [
      {
        name: additionalD1?.mediaType ? `Additional Docs 1.${additionalD1?.mediaType}` : '',
        url: additionalD1?.uri
      }
    ]
  };

  const health = {
    name: healthL?.mediaType ? 'Health Insurance' : '',
    files: [
      {
        name: healthL?.mediaType ? `Health Insurance.${healthL?.mediaType}` : '',
        url: healthL?.uri
      }
    ]
  };

  const vaccination = {
    name:
      vaccine1stL?.mediaType || vaccine2ndL?.mediaType || vaccineBL?.mediaType ? 'Vaccination' : '',
    files: [
      {
        name: vaccine1stL?.mediaType ? `Vaccination 1st Dose.${vaccine1stL?.mediaType}` : '',
        url: vaccine1stL?.uri
      },
      {
        name: vaccine2ndL?.mediaType ? `Vaccination 2nd Dose.${vaccine2ndL?.mediaType}` : '',
        url: vaccine2ndL?.uri
      },
      {
        name: vaccineBL?.mediaType ? `Vaccination Booster Dose.${vaccineBL?.mediaType}` : '',
        url: vaccineBL?.uri
      }
    ]
  };
  const handleDownload = () => {
    const folders = [
      aadhar,
      passport,
      pan,
      visa,
      travel,
      additionalDocs1,
      additionalDocs2,
      health,
      vaccination
    ].filter((item) => {
      return item?.name !== '';
    });

    if (folders?.length > 0) {
      DownloadMultipleFolderAsZip(
        folders,
        `${docsData?.fullName || docsData?.name} (Id-${
          docsData?.agentId || docsData?.employeeId || docsData?.uniqueId
        }) Docs`
      );
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'No Data Found For Download'
      });
    }
  };

  const handleLargeImageDownload = () => {
    let folders = [];
    switch (name) {
      case 'Passport':
        folders = [passport];
        break;
      case 'Vaccination':
        folders = [vaccination];
        break;
      case 'Aadhaar Card':
        folders = [aadhar];
        break;
      case 'Pan Card':
        folders = [pan];
        break;
      case 'Visa':
        folders = [visa];
        break;
      case 'Travel Insurance':
        folders = [travel];
        break;
      case 'Health Insurance':
        folders = [health];
        break;
      case 'Add. Document 1':
        folders = [additionalDocs1];
        break;
      case 'Photo':
        folders = [additionalDocs2];
        break;
      default:
        folders = [];
        break;
    }

    if (folders?.length > 0 && folders?.[0]?.name !== '') {
      DownloadMultipleFolderAsZip(
        folders,
        `${docsData?.fullName || docsData?.name} (Id-${
          docsData?.agentId || docsData?.employeeId || docsData?.uniqueId
        }) Docs`
      );
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'No Data Found For Download'
      });
    }
  };

  return (
    <Container direction="column">
      <Item className={classes.title}>
        <Typography sx={{ fontWeight: '600', fontSize: '1.1rem' }}>
          {`${docsStatus}-${
            docsData?.role === 'agent'
              ? docsData?.agentId
              : docsData?.employeeId || docsData?.uniqueId
          }`}
        </Typography>
        <Item sx={{ display: 'flex', gap: '10px' }}>
          <DownloadForOfflineIcon
            sx={{ cursor: 'pointer', fontSize: '25px' }}
            onClick={isOpenLarge ? handleLargeImageDownload : handleDownload}
          />
          <Typography>
            <CancelIcon sx={{ cursor: 'pointer' }} onClick={() => handleClose()} />
          </Typography>
        </Item>
      </Item>

      {isOpenLarge ? (
        <Item>
          <Item onClick={handlecloseLarge} mt={1} ml={2}>
            <ArrowBackIosIcon sx={{ cursor: 'pointer' }} />
          </Item>
          {render(name)}
        </Item>
      ) : (
        <Item style={gridStyle}>
          {docsArr.map((doc, index) => (
            <Item
              style={{ ...gridItemStyle, backgroundColor: doc.color }}
              key={index}
              onClick={() => handleDetails(doc)}>
              <img
                width={'150px'}
                height={'100px'}
                src={require(`../../../../Assets/EmpDocIcons/${doc.imageName}.gif`).default}
                alt="icon"
              />
              {doc?.name}
            </Item>
          ))}
        </Item>
      )}
    </Container>
  );
}

export default withStyles(styles)(EmpDocModal);
