import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import { getDocsDate } from '../../utils/moment';
// import theme from '../../utils/theme';
// import { useStateValue } from '../../utils/store';

const { withStyles, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

const DocumentConfirm = ({ classes, indiviualUserData }) => {
  // const [store] = useStateValue();
  const passportDoc = {
    country: indiviualUserData?.passportDocs && indiviualUserData?.passportDocs[0]?.country,
    passportNo: indiviualUserData?.passportDocs && indiviualUserData?.passportDocs[0]?.passportNo,
    issueDate: indiviualUserData?.passportDocs && indiviualUserData?.passportDocs[0]?.issueDate,
    expiryDate: indiviualUserData?.passportDocs && indiviualUserData?.passportDocs[0]?.expiryDate,
    passportFrontLink: [
      indiviualUserData?.passportDocs &&
        indiviualUserData?.passportDocs[0]?.links &&
        indiviualUserData?.passportDocs[0]?.links[0]
    ],
    passportBackLink: [
      indiviualUserData?.passportDocs &&
        indiviualUserData?.passportDocs[0]?.links &&
        indiviualUserData?.passportDocs[0]?.links[1]
    ]
  };

  const aadhaarCardDocs = {
    aadhaarNo:
      indiviualUserData?.aadhaarCardDocs && indiviualUserData?.aadhaarCardDocs[0]?.aadhaarNo,
    aadhaarAddress:
      indiviualUserData?.aadhaarCardDocs && indiviualUserData?.aadhaarCardDocs[0]?.aadhaarAddress,
    dateOfBirth:
      indiviualUserData?.aadhaarCardDocs && indiviualUserData?.aadhaarCardDocs[0]?.dateOfBirth,
    aadhaarFrontLink: [
      indiviualUserData?.aadhaarCardDocs &&
        indiviualUserData?.aadhaarCardDocs[0]?.links &&
        indiviualUserData?.aadhaarCardDocs[0]?.links[0]
    ],
    aadhaarBackLink: [
      indiviualUserData?.aadhaarCardDocs &&
        indiviualUserData?.aadhaarCardDocs[0]?.links &&
        indiviualUserData?.aadhaarCardDocs[0]?.links[1]
    ]
  };

  // const visaDocs = {
  //   country: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.country,
  //   visaNo: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.visaNo,
  //   visaType: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.visaType,
  //   entry: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.entry,
  //   issueDate: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.issueDate,
  //   expiryDate: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.expiryDate,
  //   visaLink: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.links
  // };

  // const travelInsuranceDocs = {
  //   country:
  //     indiviualUserData?.travelInsuranceDocs && indiviualUserData?.travelInsuranceDocs[0]?.country,
  //   registerationNo:
  //     indiviualUserData?.travelInsuranceDocs &&
  //     indiviualUserData?.travelInsuranceDocs[0]?.registerationNo,
  //   issueDate:
  //     indiviualUserData?.travelInsuranceDocs &&
  //     indiviualUserData?.travelInsuranceDocs[0]?.issueDate,
  //   expiryDate:
  //     indiviualUserData?.travelInsuranceDocs &&
  //     indiviualUserData?.travelInsuranceDocs[0]?.expiryDate,
  //   travelInsuranceLink:
  //     indiviualUserData?.travelInsuranceDocs && indiviualUserData?.travelInsuranceDocs[0]?.links
  // };

  // const healthInsuranceDocs = {
  //   companyName:
  //     indiviualUserData?.healthInsuranceDocs &&
  //     indiviualUserData?.healthInsuranceDocs[0]?.companyName,
  //   registerationNo:
  //     indiviualUserData?.healthInsuranceDocs &&
  //     indiviualUserData?.healthInsuranceDocs[0]?.registerationNo,
  //   issueDate:
  //     indiviualUserData?.healthInsuranceDocs &&
  //     indiviualUserData?.healthInsuranceDocs[0]?.issueDate,
  //   expiryDate:
  //     indiviualUserData?.healthInsuranceDocs &&
  //     indiviualUserData?.healthInsuranceDocs[0]?.expiryDate,
  //   healthInsuranceLink:
  //     indiviualUserData?.healthInsuranceDocs && indiviualUserData?.healthInsuranceDocs[0]?.links
  // };

  const panCardDocs = {
    panNumber: indiviualUserData?.panCardDocs && indiviualUserData?.panCardDocs[0]?.panNumber,
    dateOfBirth: indiviualUserData?.panCardDocs && indiviualUserData?.panCardDocs[0]?.dateOfBirth,
    panCardLink: indiviualUserData?.panCardDocs && indiviualUserData?.panCardDocs[0]?.links
  };

  // const vaccinationDocs = {
  //   vaccination1stDocs: {
  //     vaccinationStatus:
  //       indiviualUserData?.vaccinationDocs &&
  //       indiviualUserData?.vaccinationDocs[0]?.vaccinationStatus,
  //     vaccineType:
  //       indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[0]?.vaccineType,
  //     nextDate:
  //       indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[0]?.nextDate,
  //     currentDate:
  //       indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[0]?.currentDate,
  //     links: indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[0]?.links
  //   },
  //   vaccination2ndDocs: {
  //     vaccinationStatus:
  //       indiviualUserData?.vaccinationDocs &&
  //       indiviualUserData?.vaccinationDocs[1]?.vaccinationStatus,
  //     vaccineType:
  //       indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[1]?.vaccineType,
  //     nextDate:
  //       indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[1]?.nextDate,
  //     currentDate:
  //       indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[1]?.currentDate,
  //     links: indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[1]?.links
  //   },
  //   vaccinationBoosterDocs: {
  //     vaccinationStatus:
  //       indiviualUserData?.vaccinationDocs &&
  //       indiviualUserData?.vaccinationDocs[2]?.vaccinationStatus,
  //     vaccineType:
  //       indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[2]?.vaccineType,
  //     nextDate:
  //       indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[2]?.nextDate,
  //     currentDate:
  //       indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[2]?.currentDate,
  //     links: indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[2]?.links
  //   }
  // };

  return (
    <Item sx={{ mt: 2, maxHeight: '65vh', overflow: 'auto', minWidth: '60vw' }}>
      <Item
        sx={{
          flex: 1,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: '10px'
        }}
        md={12}
        xs={12}
        lg={12}>
        <Item mt={2} md={12}>
          <Typography>Passport</Typography>
          <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
            <Item md={5.8} xs={12} lg={3.9} mt={1}>
              <Typography>Passport No</Typography>
              <Typography className={classes.textColor}>
                {passportDoc?.passportNo || '-'}
              </Typography>
            </Item>
            <Item md={5.8} xs={12} lg={3.9} mt={1}>
              <Typography>Passport Country</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(passportDoc?.country) || '-'}
              </Typography>
            </Item>

            {/* <Item md={5.8} xs={12} lg={3.9} mt={1}>
              <Typography>Issue date</Typography>
              <Typography className={classes.textColor}>{passportDoc?.issueDate || '-'}</Typography>
      </Item> */}
            <Item md={5.8} xs={12} lg={3.9} mt={1}>
              <Typography>Expiry Date</Typography>
              <Typography className={classes.textColor}>
                {!passportDoc?.expiryDate ? '-' : getDocsDate(passportDoc?.expiryDate)}
              </Typography>
            </Item>
          </Item>
        </Item>

        <Item mt={2} md={12}>
          <Typography>Aadhaar Card</Typography>
          <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
            <Item md={5.8} xs={12} lg={4.2} mt={1}>
              <Typography>Aadhaar No</Typography>
              <Typography className={classes.textColor}>
                {aadhaarCardDocs?.aadhaarNo || '-'}
              </Typography>
            </Item>
            <Item lg={7.5} md={5.8} xs={12}></Item>
            {/* <Item md={5.8} xs={12} lg={7.5} mt={1}>
              <Typography>Aadhaar Address</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(aadhaarCardDocs?.aadhaarAddress) || '-'}
    </Typography>
    </Item> */}
          </Item>
        </Item>

        {/* <Item mt={2} md={12}>
          <Typography>Visa</Typography>
          <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
            <Item md={5.8} xs={12} lg={3.8} mt={1}>
              <Typography>Visa No</Typography>
              <Typography className={classes.textColor}>{visaDocs?.visaNo || '-'}</Typography>
            </Item>
            <Item md={5.8} xs={12} lg={3.8} mt={1}>
              <Typography>Country</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(visaDocs?.country) || '-'}
              </Typography>
            </Item>
          
            <Item md={5.8} xs={12} lg={3.8} mt={1}>
              <Typography>Expiry Date</Typography>
              <Typography className={classes.textColor}>
                {!visaDocs?.expiryDate ? '-' : getDocsDate(visaDocs?.expiryDate)}
              </Typography>
            </Item>

            <Item md={5.8} xs={12} lg={3.8} mt={1}>
              <Typography>Visa Type</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(visaDocs?.visaType) || '-'}
              </Typography>
            </Item>
            <Item md={5.8} xs={12} lg={3.8} mt={1}>
              <Typography>Entry Level</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(visaDocs?.entry) || '-'}
              </Typography>
            </Item>
          </Item>
        </Item> */}

        {/* {store?.corporateId !== theme.corporate.mliCorporateId && (
          <Item mt={2} md={12}>
            <Typography>Travel Insurance</Typography>
            <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
              <Item md={5.8} xs={12} lg={5.8}>
                <Typography>Registeration No</Typography>
                <Typography className={classes.textColor}>
                  {travelInsuranceDocs?.registerationNo || '-'}
                </Typography>
              </Item>

              <Item md={5.8} xs={12} lg={5.8}>
                <Typography>Expiry Date</Typography>
                <Typography className={classes.textColor}>
                  {!travelInsuranceDocs?.expiryDate
                    ? '-'
                    : getDocsDate(travelInsuranceDocs?.expiryDate)}
                </Typography>
              </Item>
              <Item md={5.8} xs={12} lg={5.8}>
                <Typography>Country</Typography>
                <Typography className={classes.textColor}>
                  {ConvertToUpperCase(travelInsuranceDocs?.country) || '-'}
                </Typography>{' '}
              </Item>
              <Item md={12} xs={12} lg={5.8}>
                <Typography>Company Name</Typography>
                <Typography className={classes.textColor}>
                  {ConvertToUpperCase(travelInsuranceDocs?.companyName) || '-'}
                </Typography>
              </Item>
            </Item>
          </Item>
        )} */}

        {/* {store?.corporateId !== theme.corporate.mliCorporateId && (
          <Item mt={2} md={12}>
            <Typography>Health Insurance</Typography>
            <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
              <Item md={5.8} xs={12} lg={2.9} mt={1}>
                <Typography>Registeration No</Typography>
                <Typography className={classes.textColor}>
                  {healthInsuranceDocs?.registerationNo || '-'}
                </Typography>
              </Item>
              <Item md={5.8} xs={12} lg={2.9} mt={1}>
                <Typography>Company Name</Typography>
                <Typography className={classes.textColor}>
                  {ConvertToUpperCase(healthInsuranceDocs?.companyName) || '-'}
                </Typography>
              </Item>

              <Item md={5.8} xs={12} lg={2.9} mt={1}>
                <Typography>Expiry Date</Typography>
                <Typography className={classes.textColor}>
                  {!healthInsuranceDocs?.expiryDate
                    ? '-'
                    : getDocsDate(healthInsuranceDocs?.expiryDate)}
                </Typography>
              </Item>
            </Item>
          </Item>
        )} */}

        {/* <Item mt={2} md={12}>
          <Typography>Vaccination</Typography>
          <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
            <Item md={5.8} xs={12} lg={2.9} mt={1}>
              <Typography>Vaccination Status</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(vaccinationDocs?.vaccination1stDocs?.vaccinationStatus) || '-'}
              </Typography>
            </Item>
            <Item md={5.8} xs={12} lg={2.9} mt={1}>
              <Typography>Vaccine Type</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(vaccinationDocs?.vaccination1stDocs?.vaccineType) || '-'}
              </Typography>
            </Item>

            <Item md={5.8} xs={12} lg={2.9} mt={1}>
              <Typography>Vaccine Date</Typography>
              <Typography className={classes.textColor}>
                {!vaccinationDocs?.vaccination1stDocs?.currentDate
                  ? '-'
                  : getDocsDate(vaccinationDocs?.vaccination1stDocs?.currentDate)}
              </Typography>
            </Item>
          </Item>
          <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12} mt={1}>
            <Item md={5.8} xs={12} lg={2.9} mt={1}>
              <Typography>Vaccination Status</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(vaccinationDocs?.vaccination2ndDocs?.vaccinationStatus) || '-'}
              </Typography>
            </Item>
            <Item md={5.8} xs={12} lg={2.9} mt={1}>
              <Typography>Vaccine Type</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(vaccinationDocs?.vaccination2ndDocs?.vaccineType) || '-'}
              </Typography>
            </Item>

            <Item md={5.8} xs={12} lg={2.9} mt={1}>
              <Typography>Vaccine Date</Typography>
              <Typography className={classes.textColor}>
                {!vaccinationDocs?.vaccination2ndDocs?.currentDate
                  ? '-'
                  : getDocsDate(vaccinationDocs?.vaccination2ndDocs?.currentDate)}
              </Typography>
            </Item>
          </Item>
          <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12} mt={1}>
            <Item md={5.8} xs={12} lg={2.9} mt={1}>
              <Typography>Vaccination Status</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(vaccinationDocs?.vaccinationBoosterDocs?.vaccinationStatus) ||
                  '-'}
              </Typography>
            </Item>
            <Item md={5.8} xs={12} lg={2.9} mt={1}>
              <Typography>Vaccine Type</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(vaccinationDocs?.vaccinationBoosterDocs?.vaccineType) || '-'}
              </Typography>
            </Item>

            <Item md={5.8} xs={12} lg={2.9} mt={1}>
              <Typography>Vaccine Date</Typography>
              <Typography className={classes.textColor}>
                {!vaccinationDocs?.vaccinationBoosterDocs?.currentDate
                  ? '-'
                  : getDocsDate(vaccinationDocs?.vaccinationBoosterDocs?.currentDate)}
              </Typography>
            </Item>
          </Item>
        </Item> */}

        <Item mt={2} md={12}>
          <Typography>PAN Card</Typography>
          <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
            <Item md={5.8} xs={12} lg={5.8} mt={1}>
              <Typography>Pan No</Typography>
              <Typography className={classes.textColor}>
                {ConvertToUpperCase(panCardDocs?.panNumber) || '-'}
              </Typography>
            </Item>
            <Item md={5.8} xs={12} lg={5.8} mt={1}>
              <Typography>DOB</Typography>
              <Typography className={classes.textColor}>
                {getDocsDate(panCardDocs?.dateOfBirth) || '-'}
              </Typography>
            </Item>
          </Item>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(DocumentConfirm);
