const styles = (theme) => {
  return {
    newUser: {
      width: '500px',
      height: 'auto',
      padding: theme.spacing(2)
    },
    cardHeading: {
      padding: '7px 20px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px 5px 0 0',
      color: theme.palette.common.white
    },
    textBody: {
      fontSize: '19px',
      textAlign: 'center',
      margin: '16px 0'
    }
  };
};

export default styles;
