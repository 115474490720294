const styles = (theme) => {
  return {
    root: {
      width: '100%',
      margin: 'auto', // paddingTop: theme.spacing(0.5),
      marginTop: theme.spacing(1)
    },
    container: {
      height: 560,
      overflow: 'auto'
    },
    recurring: {
      backgroundColor: '#D5F0D8',
      color: '#0F851D',
      padding: theme.spacing(0.5),
      borderRadius: '15px',
      textAlign: 'center'
    },
    oneTime: {
      textAlign: 'center',
      backgroundColor: '#D0E3FB',
      color: '#0E4FA4',
      padding: theme.spacing(0.5),
      borderRadius: '15px'
    }
  };
};

export default styles;
