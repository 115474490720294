import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import incidentColumns from './head';
import alertColumns from './head1';
import { useStateValue } from '../../../../../utils/store';
import { Components } from '../../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR } from '../../../../../utils/api';
import { getDateTime } from '../../../../../utils/moment';
import theme from '../../../../../utils/theme';
import NoActionModal from '../../../Modal/NoActionModal';
import Dialog from '../../../../atom/Dialog';
import ForwardActionModal from '../../../Modal/ForwardActionModal';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import DeatilsViewModel from '../../../Modal/DeatilsViewModel';
import tableColor from '../../../../../utils/tableColor';
import IncidentForwardModel from '../../../Modal/IncidentForwardModel/dummyIndex';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Button
} = Components;
// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const UnassignedSafetyDistribution = ({
  classes,
  severity,
  media,
  selectedOffice,
  selectedFilterCategory,
  isIncident,
  selectedFromDate,
  selectedToDate,
  search,
  name
}) => {
  const [page, setPage] = React.useState(0);
  const [columnHeader, setColumnHeader] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRejectedNews, setTotalRejectedNews] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectId, setRejectId] = useState('');
  const [verifyModal, setVerifyModal] = useState(false);
  const [verifyId, setVerifyId] = useState();
  const [isOpenMoreDetailModal, setIsOpenMoreDetailModal] = useState(false);
  const [moreDetails, setMoreDetails] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (isIncident) {
      setColumnHeader(alertColumns);
    } else {
      setColumnHeader(incidentColumns);
    }
  }, [isIncident]);
  // to reset page on search
  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getUnassignedIncident();
  }, [
    dispatch,
    page,
    rowsPerPage,
    media,
    selectedOffice,
    severity,
    verifyModal,
    rejectModal,
    isIncident,
    selectedFilterCategory,
    selectedFromDate,
    selectedToDate,
    moreDetails,
    isOpenMoreDetailModal,
    search
  ]);

  const getUnassignedIncident = () => {
    const severityLevel = severity === 0 ? '' : `emergencySeverityLevel=${severity}`;
    const emergencyCat = !isIncident ? '' : `emergencySeverity=${selectedFilterCategory}`;
    const resultSearch = name
      ? ''
      : search !== undefined && search !== ''
      ? `searchTag=${search}`
      : `fromDate=${selectedFromDate}&toDate=${selectedToDate}`;

    const resultOffice = selectedOffice?.map((item) => {
      return item?._id;
    });
    let params = '';
    resultOffice.forEach((element) => {
      params += `&officeIds[]=${element}`;
    });

    API_GET(
      `incident/unAssignedIncidents?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&mediaTypeFlag=${media}&${params}&${severityLevel}&alertNewsStatus=${isIncident}&${emergencyCat}&${resultSearch}&incidentActionStatus=pending`
    )
      .then((res) => {
        setRows(res?.unassignedIncidents);
        setTotalRejectedNews(res?.countUnassigned);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleClose = () => {
    setRejectModal(false);
    setVerifyModal(false);
    setIsOpenMoreDetailModal(false);
  };

  const handleMore = (row) => {
    setIsOpenMoreDetailModal(true);
    setMoreDetails(row);
  };

  const handleReject = (item) => {
    setRejectModal(true);
    setRejectId(item?._id);
  };
  const handleVerify = (item) => {
    setVerifyModal(true);
    setVerifyId(item?._id);
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columnHeader &&
                columnHeader.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columnHeader.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(index) }}>
                    {columnHeader &&
                      columnHeader.map((column) => {
                        const value = row[column.id] || '--';
                        if (column.id === 'publishDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.publishedDate) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'date') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.date) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'category') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item sx={{ display: 'flex', gap: '10px' }}>
                                <Item>
                                  <Button
                                    style={{
                                      ...theme.button.tableSeverityButton,
                                      backgroundColor: row?.backgroundAlertColor,
                                      color: row?.alertColor
                                    }}>
                                    {row?.emergencySeverity}
                                  </Button>
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'event') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item
                                onClick={() => handleMore(row)}
                                sx={{
                                  cursor: 'pointer',
                                  textDecoration: 'underline'
                                }}>
                                <Item>{row?.incidentTitle}</Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'safetyConcern') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item
                                onClick={() => handleMore(row)}
                                sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                <Item>{row?.incidentTitle}</Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'eventDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.eventDate) || '--'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'safetyCategory') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item sx={{ textDecoration: 'underline' }}>
                                {row?.category || '-'}
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'officeAddress') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {ConvertToUpperCase(row?.officeAddress) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'emergencySeverityLevel') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {isIncident
                                ? row?.emergencySeverityLevel === 0
                                  ? ''
                                  : row?.emergencySeverityLevel
                                : row?.emergencySeverityLevel}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'action') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item sx={{ display: 'flex', gap: '10px' }}>
                                <Item>
                                  <Button
                                    style={theme.button.initiate}
                                    onClick={() => handleVerify(row)}>
                                    FORWARD
                                  </Button>
                                </Item>
                                <Item>
                                  <Button
                                    style={theme.button.trash}
                                    onClick={() => handleReject(row)}>
                                    Suspend
                                  </Button>
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(ConvertToUpperCase(value))
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalRejectedNews && totalRejectedNews ? totalRejectedNews : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        isOpen={verifyModal}
        children={
          isIncident ? (
            <ForwardActionModal
              handleClose={handleClose}
              verifyId={verifyId}
              isIncident={isIncident}
              status="unassigend"
            />
          ) : (
            <IncidentForwardModel
              handleClose={handleClose}
              verifyId={verifyId}
              isIncident={isIncident}
              status="unassigend"
            />
          )
        }></Dialog>
      <Dialog
        isOpen={rejectModal}
        children={
          <NoActionModal
            handleClose={handleClose}
            rejectId={rejectId}
            isIncident={isIncident}
            status="unassigned"
          />
        }></Dialog>
      <Dialog
        isOpen={isOpenMoreDetailModal}
        children={
          <DeatilsViewModel handleClose={handleClose} data={moreDetails} isIncident={isIncident} />
        }></Dialog>
    </Card>
  );
};

export default withStyles(styles)(UnassignedSafetyDistribution);
