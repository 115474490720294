const styles = (theme) => {
  return {
    root: {
      width: '100%',
      margin: 'auto', // paddingTop: theme.spacing(0.5),
      marginTop: theme.spacing(1)
    },
    container: {
      height: 470,
      overflow: 'auto'
    }
  };
};

export default styles;
