import React, { useState, useEffect } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import { API_WELLNESS_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';

const { withStyles, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

const UserEngagement = ({ classes }) => {
  // const data = [
  //   { name: 'DAU', value: 300 },
  //   { name: 'MAU', value: 1200 },
  //   { name: 'Sign up', value: 1000 },
  //   { name: 'Time spent on the app(in mins)', value: 500 }
  // ];

  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  console.log('rows', rows);

  useEffect(() => {
    API_WELLNESS_GET(`userEngagement/getStats`)
      .then((res) => {
        setRows(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch]);

  return (
    <Item md={12} xs={12} pt={2} pb={2}>
      <Item className={classes.fitnessContainer}>
        {rows?.map((item) => {
          return (
            <Item md={2.2} xs={5.8}>
              <Item
                md={12}
                xs
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                <Typography
                  sx={{
                    color: '#5C5B5B',
                    fontSize: '1.6rem',
                    fontWeight: '600'
                    // textAlign: 'center',
                  }}>
                  {Number(item?.value)?.toFixed()}
                </Typography>
                <Typography sx={{ color: '#979797', fontSize: '1.2rem' }}>{item?.name}</Typography>
              </Item>
            </Item>
          );
        })}
      </Item>
    </Item>
  );
};

export default withStyles(styles)(UserEngagement);
