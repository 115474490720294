import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../../utils/store';
import { Components, Icons } from '../../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR, API_DELETE, API_PATCH } from '../../../../../utils/api';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import tableColor from '../../../../../utils/tableColor';
import theme from '../../../../../utils/theme';
import Dialog from '../../../../atom/Dialog';
import BroadcastSentToModal from '../../../Modal/BroadcastModels/BroadcastSentToModal';
import { getDateTime } from '../../../../../utils/moment';
import ScheduledEdit from '../../../Modal/BroadcastModels/ScheduledEdit';
import { ApiDates } from '../../../../../utils/ApiDates';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Button,
  Checkbox
} = Components;

const { InfoIcon, EditIcon } = Icons;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const ScheduledBroadcastTable = ({
  classes,
  searchText,
  selectedFromDate,
  selectedToDate,
  sort,
  filtersCategory
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalScheduled, setTotalScheduled] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [checkboxData, setCheckboxData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [isOpenSentToModal, setIsOpenSentToModal] = useState(false);
  const [sentToData, setSentToData] = useState({});
  const [isOpenScheduledModelOpen, setIsOpenScheduledModelOpen] = useState(false);
  const [scheduledData, setScheduledData] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // to reset page on search
  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllScheduled();
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchText,
    filtersCategory,
    sort,
    selectedFromDate,
    selectedToDate
  ]);

  const getAllScheduled = () => {
    let result = '';
    for (let i = 0; i < filtersCategory?.length; i++) {
      if (filtersCategory[i]?.type === 'Sent via') {
        result += `&mediumFilter[]=${filtersCategory[i]?.name}`;
      }
      if (filtersCategory[i]?.type === 'Status') {
        result += `&statusFilter[]=${filtersCategory[i]?.name}`;
      }
      if (filtersCategory[i]?.type === 'Sent By') {
        result += `&sentByFilter[]=${filtersCategory[i]?.name}`;
      }
    }
    const resultsort = !sort ? 1 : sort;
    const dates = ApiDates(selectedFromDate, selectedToDate);

    API_GET(
      `broadCastMessage/getScheduledBroadCasts?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}${result}&sort=header&order=${resultsort}${dates}`
    )
      .then((res) => {
        const checkboxObject = {};
        res?.foundMessages?.map((item, index) => (checkboxObject[index] = false));
        setCheckboxData(checkboxObject);
        setSelectAll(false);
        setRows(res?.foundMessages);
        setTotalScheduled(res?.count);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  let selectedCount = 0;
  if (checkboxData) {
    Object.keys(checkboxData).map((item) =>
      checkboxData[`${item}`] === true ? selectedCount++ : selectedCount
    );
  }

  const handleSelectAll = (event) => {
    const boolean = event.target.checked;
    const keys = Object.keys(checkboxData);
    const object = {};
    keys.map((item) => {
      return (object[item] = boolean);
    });
    setCheckboxData(object);
    setSelectAll(boolean);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxData({
      ...checkboxData,
      [event.target.name]: event.target.checked
    });
    setSelectAll(false);
  };

  const handleOpenBroadcastSentToModal = (row) => {
    setIsOpenSentToModal(true);
    setSentToData(row);
  };

  const handleClose = () => {
    setIsOpenSentToModal(false);
    setIsOpenScheduledModelOpen(false);
  };

  const handleEdit = (row) => {
    setIsOpenScheduledModelOpen(true);
    setScheduledData(row);
  };

  const handleSendDelete = (status) => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const approveDeclinedIds = filteredKeys.map((item) => rows[item]._id).flat();
    if (approveDeclinedIds.length > 0) {
      if (status === 'send') {
        API_PATCH('broadCastMessage/sendScheduledBroadCasts', { broadcastIds: approveDeclinedIds })
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            getAllScheduled();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      } else {
        API_DELETE('broadCastMessage/deleteScheduledBroadCasts', {
          broadcastMessageIds: approveDeclinedIds
        })
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            getAllScheduled();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      }
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Select atleast one Entry !'
      });
    }
  };

  return (
    <React.Fragment>
      <Item sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} md={12} xs={12}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <Item sx={{ display: 'flex', gap: '10px' }}>
            <Item>
              <Button
                onClick={() => handleSendDelete('send')}
                style={{
                  ...theme.button.initiate,
                  padding: '7px',
                  fontSize: '13px',
                  borderRadius: 'none'
                }}
                className={classes.button}>
                Send
              </Button>
            </Item>
            <Item>
              <Button
                onClick={() => handleSendDelete('delete')}
                style={{
                  ...theme.button.trash,
                  padding: '7px',
                  fontSize: '13px',
                  borderRadius: 'none'
                }}>
                Delete
              </Button>
            </Item>
          </Item>
        </Item>
      </Item>
      <Card className={classes.root} sx={theme.tableBorder}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.thead}>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.id === 'select' ? (
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          style={{ color: theme.typography.tableHeading.heading }}
                        />
                      ) : (
                        column.label
                      )}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {'No Records found'}
                  </TableCell>
                </TableRow>
              )}
              {rows &&
                rows?.map((row, rowindex) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      style={{ backgroundColor: tableColor(rowindex) }}>
                      {columns &&
                        columns.map((column, index) => {
                          const value = row[column.id] ?? '--';

                          if (column.id === 'select') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <Checkbox
                                  color="primary"
                                  checked={checkboxData[`${rowindex}`]}
                                  onChange={handleCheckboxChange}
                                  name={`${rowindex}`}
                                />
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'sentVia') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.medium?.join(', ')}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'date') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.broadcastDateTime ? getDateTime(row?.broadcastDateTime) : '-'}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'status') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {'isRecurring' in row ? (
                                  <Item className={classes.recurring}>Recurring</Item>
                                ) : (
                                  <Item className={classes.oneTime}>One Time</Item>
                                )}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'ctaName') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.ctaArray
                                  ?.map((item) => {
                                    return ConvertToUpperCase(item?.ctaName);
                                  })
                                  ?.join(', ')}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'sentTo') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <InfoIcon
                                  onClick={() => handleOpenBroadcastSentToModal(row)}
                                  style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                />
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'action') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <EditIcon
                                  style={{ color: '#868686', cursor: 'pointer' }}
                                  onClick={() => handleEdit(row)}
                                />
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalScheduled && totalScheduled ? totalScheduled : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          isOpen={isOpenSentToModal}
          children={
            <BroadcastSentToModal handleClose={handleClose} sentToData={sentToData} />
          }></Dialog>
        <Dialog
          isOpen={isOpenScheduledModelOpen}
          children={
            <ScheduledEdit
              handleClose={handleClose}
              data={scheduledData}
              getAllScheduled={getAllScheduled}
            />
          }></Dialog>
      </Card>
    </React.Fragment>
  );
};

export default withStyles(styles)(ScheduledBroadcastTable);
