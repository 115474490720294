import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
// import theme from '../../utils/theme';

const { withStyles, Grid } = Components;
const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid container {...props} />;

function CountsCard(props) {
  const { item, onClick, selectedCard, classes } = props;

  const card = (lable) => {
    return (
      <Item
        className={classes.card}
        key={item._id}
        sx={{
          alignItems: 'center',
          margin: 'auto',
          backgroundColor: item?.bgColor,
          display: 'flex',
          p: 1
        }}
        // onClick={() => handleCategory(item)}
      >
        <Item
          sx={{
            width: '55%',
            height: '100%'
          }}>
          <img
            style={{ height: '100%', width: '100%' }}
            src={require(`../../Assets/ReportsIcons/${item.value}.gif`).default}
            alt="reports icon"
          />
        </Item>
        <Item
          sx={{
            width: '40%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <h2 className={classes.labelText}>{item?.label}</h2>
        </Item>
      </Item>
    );
  };

  return (
    <Container
      onClick={() => onClick(item)}
      sx={
        selectedCard.value === item.value
          ? {
              justifyContent: 'center',
              height: '90px'
            }
          : { justifyContent: 'center', height: '90px' }
      }>
      <Item style={{ height: '100%', width: '100%' }}>{card(item)}</Item>
    </Container>
  );
}

export default withStyles(styles)(CountsCard);
