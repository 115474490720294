import React from 'react';
import GoogleMapReact from 'google-map-react';
import LocationCard from './LocationCard';
import styles from './styles';
import { Components } from '../../utils/material-ui';

const { withStyles, Grid } = Components;

const Container = (props) => <Grid container {...props} />;

function MapComponent(props) {
  const { data, classes } = props;

  let lat = 28.7041;
  let lng = 77.1025;

  if (data) {
    lat = Number(data.lat);
    lng = Number(data.lng);
  }

  return (
    <Container className={classes.mapContainer}>
      <GoogleMapReact
        center={[lat, lng]}
        bootstrapURLKeys={{ key: 'AIzaSyBVpO7jJw7WOYC29PMXNpeYoKhdmK7dBPc' }}
        defaultCenter={[lat, lng]}
        defaultZoom={5}>
        {data && <LocationCard lat={lat} lng={lng} data={data} />}
      </GoogleMapReact>
    </Container>
  );
}

export default withStyles(styles)(MapComponent);
