const head = [
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'arrivalTime',
    label: 'Arrival Time',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'departureTime',
    label: 'Departure Time',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'hotelName',
    label: 'Hotel',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'place',
    label: 'Place',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'totalDays',
    label: 'Total Days',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  // {
  //   id: 'mealType',
  //   label: 'Meal (BLDS)',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 100,
  //   align: 'center'
  // },
  {
    id: 'modeOfTransport',
    label: 'Mode Of Transport',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  }
];

export default head;
