import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../utils/store';
import { Components } from '../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR, API_PATCH } from '../../../../utils/api';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { getDateTime, getDate } from '../../../../utils/moment';
import theme from '../../../../utils/theme';
import RejectAppointment from '../../Modal/RejectAppointment';
import Dialog from '../../../atom/Dialog';
import tableColor from '../../../../utils/tableColor';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Button
} = Components;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const PendingAppointment = ({ classes, selectedFromDate, selectedToDate, searchText }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRejectedNews, setTotalAppointment] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [rejectId, setRejectId] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllAlertDetail();
  }, [
    dispatch,
    page,
    rowsPerPage,
    isRejectModalOpen,
    selectedFromDate,
    selectedToDate,
    searchText
  ]);

  const getAllAlertDetail = () => {
    const resultSearch =
      searchText !== undefined
        ? `searchText=${searchText}`
        : `fromDate=${selectedFromDate}&toDate=${selectedToDate}`;
    API_GET(`appointment/getAllAppointments?appointmentStatus=pending&${resultSearch}`)
      .then((res) => {
        setRows(res?.appointments);
        setTotalAppointment(res?.appointmentsCount);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleClose = () => {
    setIsRejectModalOpen(false);
  };
  const handleReject = (item) => {
    setIsRejectModalOpen(true);
    setRejectId(item?._id);
  };

  const handleVerify = (item) => {
    API_PATCH(`appointment/${item?._id}`, { appointmentStatus: 'approved' })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        getAllAlertDetail();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.thead}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(index) }}>
                    {columns &&
                      columns.map((column, index) => {
                        const value = row[column.id] ?? '--';

                        if (column.id === 'date') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.date) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'appointmentDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDate(row?.dateOfBooking) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'action') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                <Item>
                                  <Button
                                    style={theme.button.initiate}
                                    onClick={() => handleVerify(row)}>
                                    Approve
                                  </Button>
                                </Item>

                                <Item>
                                  <Button
                                    style={theme.button.trash}
                                    onClick={() => handleReject(row)}>
                                    Decline
                                  </Button>
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(ConvertToUpperCase(value))
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalRejectedNews && totalRejectedNews ? totalRejectedNews : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        isOpen={isRejectModalOpen}
        children={<RejectAppointment handleClose={handleClose} rejectId={rejectId} />}></Dialog>
    </Card>
  );
};

export default withStyles(styles)(PendingAppointment);
