import React, { useState, useEffect } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import OfficewiseAppointment from './OfficewiseAppointment';
import DepartmentwiseAppointmentGraph from './DepartmentwiseAppointmentGraph';
import AppointmentAnalayticsTable from './appointmentAnayticsTable';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';

const { withStyles, Grid, MenuItem, Select, InputLabel, FormControl } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

// const categoryArray = ['All', 'health', 'economy', 'pandamic', 'political', 'weather'];

const AppointmentOverview = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const [allDepartments, setAllDepartments] = useState(['All']);
  const [location, setlocation] = React.useState('All');
  const [offices, setOffices] = React.useState([{ _id: { city: 'All', _id: 'all' } }]);

  const handleDate = (date) => {
    if (date) {
      setSelectedFromDate(moment(date[0]).format('YYYY-MM-DD'));
      setSelectedToDate(moment(date[1]).format('YYYY-MM-DD'));
    }
  };

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        setOffices([...offices, ...res?.citiesArray]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const getAllDepartments = () => {
    API_GET(`reports/getAllDepartments`)
      .then((res) => {
        setAllDepartments([...allDepartments, ...res?.departments]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllDepartments();
    getAllOffices();
  }, [dispatch]);

  return (
    <Container>
      <Item sx={{ display: 'flex', p: '5px 0px', gap: '10px', alignItems: 'center' }}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <DateRangePicker
            onChange={(date) => handleDate(date)}
            value={[new Date(selectedFromDate), new Date(selectedToDate)]}
          />
        </Item>
        <Item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> Location </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={location}
              sx={{ width: '120px' }}
              label=" Location "
              onChange={(e) => setlocation(e.target.value)}>
              {offices?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id?.city}>
                    {ConvertToUpperCase(item?._id?.city)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
        <Item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> Department </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={selectedDepartment}
              sx={{ width: '120px' }}
              label=" Department "
              onChange={(e) => setSelectedDepartment(e.target.value)}>
              {allDepartments?.sort()?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {ConvertToUpperCase(item)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
      </Item>
      <Item md={12}>
        <Item md={7} sx={theme.card} className={classes.graphContainer1}>
          <AppointmentAnalayticsTable
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
            location={location}
            selectedDepartment={selectedDepartment}
          />
        </Item>
      </Item>
      <Item md={12} sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between', mt: 1.5 }}>
        <Item md={6} sx={theme.card} className={classes.graphContainer}>
          <Item sx={{ p: 0.5 }}>
            <DepartmentwiseAppointmentGraph
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              location={location}
              selectedDepartment={selectedDepartment}
            />
          </Item>
        </Item>

        <Item md={6} sx={theme.card} className={classes.graphContainer}>
          <Item sx={{ p: 0.5 }}>
            <OfficewiseAppointment
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              location={location}
              selectedDepartment={selectedDepartment}
            />
          </Item>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(AppointmentOverview);
