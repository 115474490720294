const styles = (theme) => {
  return {
    newsPage: {
      backgroundColor: theme.palette.common.gray98,
      padding: '20px'
    },
    paper: {
      padding: theme.spacing(5, 8, 6, 8),
      [theme.breakpoints.up('sm')]: {
        minWidth: '520px'
      },
      borderRadius: '20px'
    },
    sectionHeading: {
      justifyContent: 'space-between',
      padding: theme.spacing(1, 0),
      '& .heading': {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.fontSize * 2.2,
        letterSpacing: 1
      },
      '& .brdcstBtn': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: theme.spacing(1, 2)
      },
      '& .brdcstBtn a': {
        textDecoration: 'none',
        color: theme.palette.common.white
      }
    },
    sectionDate: {
      padding: theme.spacing(1, 0),
      alignItems: 'flex-end'
    },
    datePicker: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 180
    },
    sectionTable: {
      padding: theme.spacing(1, 0)
    },
    tabWrapper: {
      maxWidth: '100%',
      width: '100%'
    },
    tableWrapper: {
      maxWidth: '100%'
    },
    incidentTable: {
      marginTop: '30px',
      width: '100%'
    },
    incidentTypeContainer: {
      display: 'flex',
      marginTop: '30px',
      width: '95%'
    },
    selectedCard: {
      width: '200px',
      height: '85px',
      color: 'white',
      backgroundColor: 'black',
      marginRight: '40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    unselectedCard: {
      cursor: 'pointer',
      width: '200px',
      height: '85px',
      color: 'black',
      backgroundColor: 'white',
      marginRight: '40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    tablesearchrelative: {
      position: 'relative',
      alignitems: 'center',
      display: 'flex',
      float: 'left'
    },
    tablesearchsearchIcon: {
      position: 'absolute',
      margin: '3px 2px',
      paddingleft: '2%'
    },
    tablesearch: {
      height: '35px',
      border: 'solid 0.5px #707070',
      padding: ' 0 0 0 35px',
      outline: 'none',
      color: '#000000',
      width: '300px'
    },
    searchContainer: {
      margin: '20px'
    }
  };
};

export default styles;
