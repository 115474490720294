import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';

const { withStyles } = Components;
const { FileDownloadIcon } = Icons;

const AppointmentAnalayticsTable = ({
  classes,
  selectedFromDate,
  selectedToDate,
  location,
  selectedDepartment
}) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    API_GET(
      `reports/getAppointmentAnalytics?fromDate=${selectedFromDate}&toDate=${selectedToDate}&city=${location}&department=${selectedDepartment}`
    )
      .then((res) => {
        setRows(res?.appointmentAnalyticsData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location, selectedDepartment]);

  const handleExport = () => {
    API_GET(
      `reports/downloadAppointmentAnalytics?fromDate=${selectedFromDate}&toDate=${selectedToDate}&city=${location}&department=${selectedDepartment}`
    )
      .then((res) => {
        if (res?.appointments.length === 0) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'No data found for download'
          });
        } else {
          const result = res?.appointments?.map((item) => {
            return {
              date: item?.date,
              ...item
            };
          });
          downloadDataToExcelFile(
            result,
            `Appointment Analt_${location}_${selectedDepartment}_(${selectedFromDate} To ${selectedToDate})`
          );
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <table style={{ width: '100%', borderRadius: '15px', overflow: 'auto' }}>
      <tr>
        <th className={classes.tableHead} style={{ textAlign: 'center' }}>
          Appointment Analytics
        </th>
        <th style={{ textAlign: 'right', marginRight: '10px' }}>
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </th>
      </tr>
      {rows?.map((item, index) => {
        return (
          <tr key={index}>
            <td className={classes.tablerow1}>{ConvertToUpperCase(item?.name)}</td>
            <td className={classes.tablerow}>{item?.value}</td>
          </tr>
        );
      })}
    </table>
  );
};
export default withStyles(styles)(AppointmentAnalayticsTable);
