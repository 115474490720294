import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';

async function DownloadMultipleNestedFolderAsZip(folders, folderName, handleLoader, handleClose) {
  // Create a new JSZip instance
  handleLoader(true);
  const zip = new JSZip();

  // Add each folder and its files to the zip file
  const zipFilename = `${folderName}.zip`;

  const folderPromises = folders.map(async (folder) => {
    // Create a new folder in the zip file
    const employeeFolder = folder?.folders?.map(async (item) => {
      const zipFolder = zip.folder(`${folder.name}(Id-${folder.id})/${item.name}`);

      // Add each file to the folder

      try {
        const promises = item.files.map(async (link) => {
          const file = await JSZipUtils.getBinaryContent(link.url);
          await zipFolder.file(`${link.name}`, file, { base64: true });
        });
        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      }
    });
    await Promise.all(employeeFolder);
  });
  await Promise.all(folderPromises);

  zip
    .generateAsync({ type: 'blob' })
    .then(function (content) {
      saveAs(content, zipFilename);
      handleLoader(false);
      handleClose();
    })
    .catch((err) => {
      console.log(err);
      handleLoader(false);
    });
}

export default DownloadMultipleNestedFolderAsZip;
