const styles = (theme) => {
  return {
    popupContainer: {
      borderRadius: theme.spacing(1)
    },
    remainderBell: {
      width: '100%',
      height: '130px',
      borderRadius: '0px 0px 150% 150%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
    },
    remainderText: {
      textAlign: 'center',
      fontSize: '13px',
      fontWeight: '600',
      marginTop: theme.spacing(3)
    },
    mediumContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    medium: {
      fontFamily: 'system-ui',
      width: '70px',
      height: '80px',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },

    iconConatinner: {
      height: '100px'
    },

    submitButton: {
      fontFamily: theme.palette.font.fontFamily
    },
    icon1: {
      padding: theme.spacing(0, 1),
      textAlign: 'center',
      fontFamily: theme.palette.font.fontFamily,
      borderRadius: '5px',
      marginTop: '-15px'
    },
    iconLabel1: {
      fontSize: '10px',
      marginTop: theme.spacing(-0.3),
      fontFamily: theme.palette.font.fontFamily,
      fontWeight: '500'
    }
  };
};

export default styles;
