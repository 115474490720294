import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Components } from '../../../../utils/material-ui';
import columns from './head';
import { StyledTableCell } from './StyledTableCell';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import theme from '../../../../utils/theme';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import tableColor from '../../../../utils/tableColor';
import RejectApprovalFlow from '../../Modal/RejectApprovalFlow';
import AcceptApprovalFlow from '../../Modal/AcceptApprovalFlow';
// import { getDate } from '../../../../utils/moment';

// import { Typography } from '@mui/material';
// import Dialog from '../../../atom/Dialog';
// import CloseIncident from '../../Modal/CloseIncident';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  // TablePagination,
  Grid,
  Button,
  TableRow,
  TableCell
} = Components;

const Item = (props) => <Grid item {...props} />;
const ApprovalStatusTable = ({ classes, handleClose, actionStatus, actionId }) => {
  const [store, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [isRejectStatus, setIsRejectStatus] = useState(false);
  const [rejectedId, setIsRejectedId] = useState('');
  const [budgetAction, setBudgetAction] = useState('');
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const [totalIncidents, settotalIncidents] = React.useState(0);
  // // const [selectedRow, setSelectedRow] = useState({});

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  const getallAction = () => {
    actionId &&
      API_GET(`budgetApproval/getApprovalData?incidentId=${actionId}`)
        .then((res) => {
          // settotalIncidents(res?.alertCount);
          setRows(res?.data);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  useEffect(() => {
    getallAction();
  }, [dispatch, actionId]);

  const handleAcceptReject = (status, id) => {
    if (status === 'accepted') {
      setIsRejectedId(id);
      setIsRejectStatus(true);
      setBudgetAction(status);
    } else {
      setBudgetAction(status);
      setIsRejectedId(id);
      setIsRejectStatus(true);
    }
  };

  const handleChangeStatus = () => {
    setIsRejectStatus(false);
  };

  const approvedActionButton = (row, column) => {
    if (actionStatus === 'accepted') {
      if (row?.actionStatus === 'pending') {
        if (row?.assignedToId === store?.personId) {
          return (
            <Item>
              <Item sx={{ display: 'flex', gap: '10px' }}>
                <Item>
                  <Button
                    style={theme.button.noAction}
                    onClick={() => handleAcceptReject('accepted', row)}>
                    Accept
                  </Button>
                </Item>
                <Item>
                  <Button
                    style={theme.button.forward}
                    onClick={() => handleAcceptReject('rejected', row?.incidentForwardId)}>
                    Reject
                  </Button>
                </Item>
              </Item>
            </Item>
          );
        }
      } else {
        return <Item>{row?.actionStatus || '-'}</Item>;
      }
      return <Item>{row?.actionStatus || '-'}</Item>;
    } else {
      return <Item>{row?.actionStatus || '-'}</Item>;
    }
  };

  return (
    <>
      {isRejectStatus ? (
        budgetAction === 'accepted' ? (
          <AcceptApprovalFlow
            handleChangeStatus={handleChangeStatus}
            data={rejectedId}
            getallAction={getallAction}
          />
        ) : (
          <RejectApprovalFlow
            handleChangeStatus={handleChangeStatus}
            rejectId={rejectedId}
            getallAction={getallAction}
          />
        )
      ) : (
        <Card className={classes.root} sx={theme.tableBorder}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.head}>
                <TableRow>
                  {columns &&
                    columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </StyledTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows == null || rows?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      {'No Records found'}
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                {rows &&
                  rows?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row?._id}
                        style={{ backgroundColor: tableColor(index) }}>
                        {columns &&
                          columns.map((column) => {
                            const value = row[column.id] || '--';

                            if (column.id === 'actionStatusByAssignee') {
                              return (
                                <StyledTableCell align="center" key={column.id}>
                                  {approvedActionButton(row, column)}
                                </StyledTableCell>
                              );
                            }
                            if (column.id === 'budgetEstimation') {
                              return (
                                <StyledTableCell align="center" key={column.id}>
                                  {row?.budgetEstimate
                                    ? `${row?.budgetEstimate} / ${
                                        !row?.budgetApproved ? '-' : row?.budgetApproved
                                      }`
                                    : '-'}
                                </StyledTableCell>
                              );
                            }

                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(ConvertToUpperCase(value))
                                  : ConvertToUpperCase(value)}
                              </StyledTableCell>
                            );
                          })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalIncidents && totalIncidents ? totalIncidents : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          {/* <Dialog
        isOpen={Modal}
        children={<CloseIncident row={selectedRow} handleClose={handleClose} />}></Dialog> */}
          <Item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1 }}>
            <Button
              size="small"
              onClick={() => handleClose()}
              style={{
                display: 'flex',
                backgroundColor: theme.palette.primary.tableHeadingColor,
                color: '#FFFFFF',
                fontSize: '12px'
              }}>
              Close
            </Button>
          </Item>
        </Card>
      )}
    </>
  );
};

export default withStyles(styles)(ApprovalStatusTable);
