const head = [
  {
    id: 'groupName',
    label: 'Group Name',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'createdOn',
    label: 'Created on',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'updatedOn',
    label: 'Updated on',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
