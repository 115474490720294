import { Typography } from '@mui/material';
import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import theme from '../../utils/theme';

const { withStyles, Grid } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const SideContain = ({ classes, data }) => {
  return (
    <Container direction={'column'} className={classes.side_panal}>
      <Item sx={{ p: theme.spacing(1, 3) }}>
        <Item className={classes.imgContainer}>
          <img
            style={{ height: '100%', width: '100%' }}
            src={require(`../../Assets/Login/${data.img}.svg`).default}
            alt="Login"
          />
        </Item>
        <Item mt={3} className={classes.textContainer}>
          <Typography variant="h4" sx={{ fontWeight: '600', fontSize: '1.7rem' }} mb={1}>
            {data.label}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: '1.2rem' }}>
            {data.description}
          </Typography>
          <Item>
            {data.subDescription?.map((item, index) => {
              return (
                <Item sx={{ display: 'flex', alignItem: 'center', gap: '15px' }} key={index}>
                  <CheckCircleRoundedIcon sx={{ mt: 0.5 }} />
                  <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                    {item}
                  </Typography>
                </Item>
              );
            })}
          </Item>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(SideContain);
