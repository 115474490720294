const styles = (theme) => {
  return {
    title: {
      backgroundColor: theme.palette.primary.tableHeadingColor,
      fontFamily: theme.palette.font.fontFamily,
      color: 'white',
      textAlign: 'center',
      borderRadius: '10px 10px 0 0',
      padding: '10px'
    },
    fromContainer: {
      padding: theme.spacing(2),
      border: '1px solid lightGrey',
      maxHeight: '80vh',
      overflow: 'auto'
    }
  };
};

export default styles;
