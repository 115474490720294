import React, { useState } from 'react';
import { Components, Icons } from '../../../../../utils/material-ui';
import styles from './styles';
import GroupTable from '../../../Table/BroadcastAllTables/GroupTable';
import Groupinfo from './groupinfo';
import BroadcastDeleteConfirmation from '../BroadcastDeleteConfirmation';
import { API_PATCH, API_HANDLE_ERROR } from '../../../../../utils/api';
import { useStateValue } from '../../../../../utils/store';

const { withStyles, Grid, TextField } = Components;

const { ArrowBackIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function GroupDetailsModel({ classes, handleClose }) {
  const [, dispatch] = useStateValue();
  const [searchText, setSearchText] = useState('');
  const [isOpenCreateGroup, setIsOpenCreateGroup] = useState(false);
  const [isOpenDeleteGroup, setIsOpenDeleteGroup] = useState(false);
  const [groupStatus, setGroupStatus] = useState('');
  const [editData, setEditData] = useState({});
  const [deleteId, setDeleteId] = useState('');

  const handleCreateGroup = (status, data) => {
    setIsOpenCreateGroup(true);
    setGroupStatus(status);
    setEditData(data);
  };

  const handleCloseGroupModal = () => {
    setIsOpenCreateGroup(false);
    setIsOpenDeleteGroup(false);
  };

  // handle delete
  const handleDeleteGroup = (row) => {
    setIsOpenDeleteGroup(true);
    setDeleteId(row?._id);
  };

  const onHandleDelete = (deletedId) => {
    API_PATCH(`broadCastGroup/deActivateGroupById/${deletedId}`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        handleCloseGroupModal();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      {isOpenDeleteGroup ? (
        <BroadcastDeleteConfirmation
          deleteId={deleteId}
          handleClose={handleCloseGroupModal}
          onHandleDelete={onHandleDelete}
        />
      ) : (
        <Container direction="column" className={classes.newUser}>
          {isOpenCreateGroup ? (
            <Groupinfo
              handleCloseGroupModal={handleCloseGroupModal}
              editData={editData}
              groupStatus={groupStatus}
            />
          ) : (
            <Item md={12} xs={12}>
              <Item md={12} xs={12} className={classes.title}>
                <Item sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                  <ArrowBackIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                  Group
                </Item>
              </Item>
              <Item
                sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
                md={12}
                xs={12}
                mt={2}>
                <Item>
                  <TextField
                    id="outlined-basic"
                    label="Search by Group Name"
                    size="small"
                    value={searchText}
                    variant="outlined"
                    sx={{ width: '300px' }}
                    fullWidth
                    type="text"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Item>
                <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
                  <Item onClick={() => handleCreateGroup('add', '')} sx={{ cursor: 'pointer' }}>
                    <img
                      style={{ height: '50px', width: '50px' }}
                      src={require(`../../../../../Assets/broadcast/CreateGroup.svg`).default}
                      alt="distribution icon"
                    />
                  </Item>
                </Item>
              </Item>

              <Item mt={2}>
                <GroupTable
                  searchText={searchText}
                  handleCreateGroup={handleCreateGroup}
                  handleDeleteGroup={handleDeleteGroup}
                />
              </Item>
            </Item>
          )}
        </Container>
      )}
    </React.Fragment>
  );
}

export default withStyles(styles)(GroupDetailsModel);
