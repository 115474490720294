/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';
import { useStateValue } from '../../utils/store';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import AddRecipient from '../../components/organism/Modal/BroadcastModels/AddRecipient';
import Dialog from '../../components/atom/Dialog';
import GroupDetailsModel from '../../components/organism/Modal/BroadcastModels/GroupDetailsModel';
import { API_POST, API_HANDLE_ERROR, API_GET } from '../../utils/api';
import { CreateMultipleTime } from '../../utils/moment';

const {
  withStyles,
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup
} = Components;

const { CancelRoundedIcon, AddCircleIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const lifeSpanArray = [
  { name: 'Forever', value: 'forever' },
  { name: 'For 1 hour', value: 1 },
  { name: 'For 2 hour', value: 2 },
  { name: 'For 4 hour', value: 4 },
  { name: 'For 6 hour', value: 6 },
  { name: 'For 8 hour', value: 8 }
];

const repeatArray = [
  { name: 'Daily', value: 'daily' },
  { name: 'Week Days', value: 'week' }
];

const NewBroadcast = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [headers, setAllHeaders] = useState([]);
  const [isOpenGroup, setIsOpenGroup] = useState(false);
  const [isOpenGroupDetailsModal, setIsOpenGroupDetailsModal] = useState(false);
  const [ctaArray, setCtaArray] = useState([{ position: 1, ctaName: '' }]);

  const [sendData, setSendData] = useState({
    groupName: [],
    location: [],
    department: [],
    currentCities: [],
    factory: [],
    templateMessage: '',
    isCustomMessage: false,
    header: '',
    customMessage: '',
    customMessage1: '',
    timeStatus: 'now',
    lifeSpan: 'forever',
    laterDate: '',
    laterTime: '',
    recurringRepeat: 'daily',
    recurringStartDate: '',
    recurringEndDate: '',
    recurringTime: ''
  });

  useEffect(() => {
    if (!sendData.isCustomMessage && sendData.templateMessage !== '') {
      setCtaArray(sendData?.templateMessage?.ctanames);
      setSendData({ ...sendData, customMessage: sendData?.templateMessage?.message });
    } else {
      setCtaArray([{ position: 1, ctaName: '' }]);
    }
  }, [sendData.isCustomMessage, sendData.templateMessage]);

  const [medium, setMedium] = useState({
    // email: false,
    whatsApp: false,
    app: false,
    msTeam: false
  });
  const [weekDays, setWeekDays] = useState({
    Sun: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thur: false,
    Fri: false,
    Sat: false
  });

  const [checkData, setCheckData] = useState({});

  useEffect(() => {
    if (sendData?.isCustomMessage) {
      setCheckData({
        'Send as Anonymous Message': false,
        'Key Management Employees': false,
        'Add to template': false,
        'Include Family member': false
      });
    } else {
      setCheckData({
        'Send as Anonymous Message': false,
        'Key Management Employees': false,
        'Include Family member': false
      });
    }
  }, [sendData.isCustomMessage]);

  // reset State

  const resetData = () => {
    setSendData({
      groupName: [],
      location: [],
      department: [],
      currentCities: [],
      factory: [],
      templateMessage: '',
      isCustomMessage: false,
      header: '',
      customMessage: '',
      customMessage1: '',
      cta1: '',
      cta2: '',
      cta3: '',
      timeStatus: 'now',
      lifeSpan: 1,
      laterDate: '',
      laterTime: '',
      recurringRepeat: 'daily',
      recurringStartDate: '',
      recurringEndDate: '',
      recurringTime: ''
    });
    setCheckData({
      'Send as Anonymous Message': false,
      'Key Management Employees': false,
      'Add to template': false,
      'Include Family member': false
    });
    setWeekDays({
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thur: false,
      Fri: false,
      Sat: false
    });
    setMedium((preState) => {
      return {
        ...preState,
        // email: false,
        whatsApp: false,
        app: false,
        msTeam: false
      };
    });
  };

  // handle state

  const handleStateChange = (key, value) => {
    setSendData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };
  const handleCheckbox = (key, value) => {
    setMedium((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };
  const handleCheckData = (key, value) => {
    setCheckData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };
  const handleDailyCheckData = (key, value) => {
    setWeekDays((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  // Cta add delete logic

  const handleCTAChange = (key, value, index) => {
    const result = ctaArray?.map((item, newIndex) => {
      return newIndex === index ? { ...item, ctaName: value } : item;
    });
    setCtaArray(result);
  };

  const handleAddMoreCTA = () => {
    setCtaArray([...ctaArray, { position: ctaArray?.length + 1, ctaName: '' }]);
  };

  const handleRemoveCta = (index) => {
    const result = ctaArray.filter((id, resindex) => {
      return resindex !== index;
    });
    setCtaArray(result);
  };

  // Get all Headers

  const getAllHeader = () => {
    API_GET('broadCastMessage/getAllHeader')
      .then((res) => {
        setAllHeaders(res?.headersByCrporateId);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllHeader();
  }, []);

  // handleCustomMessage

  const handleCustomMessage = () => {
    setSendData({ ...sendData, isCustomMessage: !sendData.isCustomMessage });
  };

  const handleClose = () => {
    setIsOpenGroup(false);
    setIsOpenGroupDetailsModal(false);
  };

  const handleOpenGroupModal = () => {
    setIsOpenGroup(true);
  };

  const handleOpenGroupDetails = () => {
    setIsOpenGroupDetailsModal(true);
  };

  const handleGroupIndiviualData = (data) => {
    const { location, factory, department, currentCities, groupData } = data;
    setSendData((preState) => {
      return {
        ...preState,
        location: [...location],
        department: [...department],
        currentCities: [...currentCities],
        factory: [...factory],
        groupName: [...groupData]
      };
    });
  };

  // post broadcast

  const handleSubmit = (e) => {
    e.preventDefault();

    const resultGroupIds = sendData?.groupName?.map((item) => {
      return item?._id;
    });

    const resultMedium = Object.keys(medium)?.filter((item) => {
      return medium[item] === true;
    });

    const deletedItem = [];

    const resultCta = ctaArray
      ?.filter((item) => {
        if (item?.ctaName === '') {
          deletedItem.push(item?._id);
        }
        return item?.ctaName !== '';
      })
      .map((item, index) => {
        return { ...item, position: index + 1 };
      });

    const resultOfficeIds = sendData?.factory?.map((item) => {
      return item?._id;
    });
    const resultDepartmentIds = sendData?.department?.map((item) => {
      return item?._id;
    });

    const resultSeduledTime = `${sendData.laterDate}T${sendData.laterTime}`;

    const resultweekNumber = [];
    Object.keys(weekDays)?.map((item, index) => {
      if (weekDays[item]) {
        resultweekNumber.push(index);
      }
      return null;
    });
    const resultRepeatOption = sendData.recurringRepeat === 'daily' ? ['daily'] : resultweekNumber;

    const sentBoth = resultGroupIds.length > 0 && resultOfficeIds.length > 0;

    const postData = {
      broadCastGroupIds: resultGroupIds,
      header: sendData?.isCustomMessage ? sendData?.header : sendData?.templateMessage?.header,
      message: sendData?.isCustomMessage ? sendData?.customMessage1 : sendData?.customMessage,
      medium: resultMedium,
      customMessage: sendData?.isCustomMessage,
      ctaArray: resultCta,
      addToTemplate: checkData['Add to template'],
      isAnonymous: checkData['Send as Anonymous Message'],
      includeFamilyMembers: checkData['Include Family member'],
      scheduled: sendData.timeStatus !== 'now',
      lifeSpan: sendData.lifeSpan === 'forever' ? '' : sendData.lifeSpan,
      scheduledDateTime: sendData.timeStatus === 'later' ? resultSeduledTime : '',
      isRecurring: sendData.timeStatus === 'recurring',
      repeatOptions: resultRepeatOption,
      startDate: sendData.timeStatus === 'recurring' ? sendData.recurringStartDate : '',
      endDate: sendData.timeStatus === 'recurring' ? sendData.recurringEndDate : '',
      sendTime: sendData.timeStatus === 'recurring' ? sendData.recurringTime : '',
      sendToBoth: sentBoth,
      individualData: {
        officeIds: resultOfficeIds,
        departmentIds: resultDepartmentIds,
        currentCities: sendData?.currentCities,
        oneTimeKeyManagement: checkData['Key Management Employees']
      }
    };
    if (resultMedium?.length > 0) {
      if (resultGroupIds.length > 0) {
        API_POST(`broadCastMessage/createMessage`, postData)
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            resetData();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      } else {
        if (resultOfficeIds.length > 0) {
          API_POST(`broadCastMessage/sendOneTimeMessage`, postData)
            .then((res) => {
              dispatch({
                type: 'ALERT_OPEN',
                severity: 'success',
                isOpen: true,
                label: res.data?.message ?? 'Success'
              });
              resetData();
            })
            .catch((err) => {
              API_HANDLE_ERROR(err, dispatch);
            });
        } else {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'Select At least one Recepient'
          });
        }
      }
    } else if (resultCta.length === 0) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Minimum One CTA Required'
      });
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Select At least notification medium'
      });
    }
  };

  const SmallCard = (item, type) => {
    let result = [];
    if (type === 'Office Location') {
      result = `${item?._id}, `;
    } else if (type === 'Office') {
      result = `(${item?.name}, ${item?.city}), `;
    } else if (type === 'Department') {
      result = `(${item?.department}), `;
    } else if (type === 'Current City') {
      result = `${item}, `;
    } else if (type === 'groups') {
      result = `${item?.groupName}, `;
    }
    return <Item sx={{ fontSize: '0.9rem', color: '#868686' }}>{result}</Item>;
  };

  const previewData = {
    'Office Location': sendData.location,
    Office: sendData.factory,
    Department: sendData.department,
    'Current City': sendData.currentCities
  };

  return (
    <Container direction="row">
      <Item md={12} xs={12}>
        <Paper className={classes.tableContainer} sx={{ ...theme.card, borderRadius: 'none' }}>
          <form onSubmit={handleSubmit}>
            <Item md={12} xs={12} className={classes.subFormContainer}>
              <Item md={5} xs={12}>
                <Typography className={classes.title}>Template Header</Typography>
                <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                  <Select
                    disabled={sendData.isCustomMessage}
                    value={sendData?.templateMessage}
                    required={!sendData?.isCustomMessage}
                    onChange={(e) => {
                      handleStateChange('templateMessage', e.target.value);
                    }}>
                    {headers?.map((item, index) => {
                      return (
                        <MenuItem value={item} key={index}>
                          {ConvertToUpperCase(item?.header)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button sx={{ float: 'right' }} onClick={handleCustomMessage}>
                  {sendData.isCustomMessage ? 'cancel' : 'Add New'}
                </Button>
              </Item>
              <Item md={5} xs={12}>
                <Typography className={classes.title}>Message</Typography>
                <TextField
                  sx={{ mt: 1 }}
                  variant="outlined"
                  fullWidth
                  disabled={sendData.isCustomMessage}
                  size="small"
                  value={sendData?.customMessage}
                  required={!sendData?.isCustomMessage}
                  placeholder="Type Message"
                  onChange={(e) => {
                    handleStateChange('customMessage', e.target.value);
                  }}
                />
              </Item>
            </Item>

            {sendData.isCustomMessage && (
              <Item md={12} xs={12} className={classes.subFormContainer} mt={2}>
                <Item md={5} xs={12}>
                  <Typography variant="h7" className={classes.label} sx={{ fontSize: '17px' }}>
                    Custom Header
                  </Typography>
                  <Item>
                    <TextField
                      sx={{ mt: 1 }}
                      variant="outlined"
                      fullWidth
                      size="small"
                      required={true}
                      onChange={(e) => {
                        handleStateChange('header', e.target.value);
                      }}
                    />
                  </Item>
                </Item>
                <Item md={5} xs={12}>
                  <Typography variant="h7" className={classes.label} sx={{ fontSize: '17px' }}>
                    Message
                  </Typography>

                  <TextField
                    sx={{ mt: 1 }}
                    variant="outlined"
                    required={true}
                    fullWidth
                    size="small"
                    value={sendData?.customMessage1}
                    placeholder="Type Message"
                    onChange={(e) => {
                      handleStateChange('customMessage1', e.target.value);
                    }}
                  />
                </Item>
              </Item>
            )}

            <Item md={12} xs={12} className={classes.formContainer} mt={2}>
              <Item
                md={12}
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
                <Item className={classes.label}>Add CTAs </Item>
                {ctaArray?.length < 3 && (
                  <Button
                    style={{
                      ...theme.button.submit,
                      backgroundColor: theme.palette.primary.main
                    }}
                    onClick={handleAddMoreCTA}>
                    Add More CTA
                  </Button>
                )}
              </Item>
              {ctaArray?.map((item, index) => {
                return (
                  <Item
                    style={{ position: 'relative' }}
                    md={ctaArray?.length <= 2 ? 5.8 : 3.6}
                    xs={12}
                    className={classes.subContainer}>
                    <Typography className={classes.label}>{index + 1}</Typography>
                    <CancelRoundedIcon
                      onClick={() => handleRemoveCta(index)}
                      style={{
                        position: 'absolute',
                        marginLeft: '98%',
                        marginTop: '-35px',
                        cursor: 'pointer',
                        zIndex: 1
                      }}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      value={item?.ctaName}
                      onChange={(e) => handleCTAChange('ctaName', e.target.value, index)}
                      type="text"
                      fullWidth
                    />
                  </Item>
                );
              })}
            </Item>

            <Item md={12} xs={12} className={classes.formContainer} mt={3}>
              <Item md={12} xs={12} className={classes.saveContainer}>
                <Item variant="h7" className={classes.label} sx={{ fontSize: '17px' }}>
                  Select Recepient
                </Item>
                <Item
                  className={classes.saveContainer}
                  sx={{ alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                  <Item onClick={handleOpenGroupDetails}>
                    <img
                      style={{ height: '30px', width: '30px' }}
                      src={require(`../../Assets/broadcast/AddGroupIcon.svg`).default}
                      alt="distribution icon"
                    />
                  </Item>
                  <AddCircleIcon
                    onClick={handleOpenGroupModal}
                    sx={{ cursor: 'pointer', color: theme.palette.primary.main, fontSize: '35px' }}
                  />
                </Item>
              </Item>

              <Item md={12} xs={12}>
                <Typography className={classes.title} mt={1}>
                  Individual
                </Typography>
                <Item md={12} xs={12} className={classes.indiviualSelectContainer}>
                  {Object.keys(previewData)?.map((item) => {
                    return (
                      <Item md={12} xs={12} sx={{ display: 'flex' }} mt={1}>
                        <Item md={1.5} xs={12} className={classes.text}>
                          {previewData[`${item}`]?.length > 0 && `${item} :`}
                        </Item>
                        <Item md={10.5} xs={12} className={classes.previewData}>
                          {previewData[`${item}`]?.length > 0 &&
                            previewData[`${item}`]?.map((newItem) => {
                              return <React.Fragment>{SmallCard(newItem, item)}</React.Fragment>;
                            })}
                        </Item>
                      </Item>
                    );
                  })}
                </Item>
                <Typography className={classes.title} mt={1}>
                  Group
                </Typography>
                <Item md={12} xs={12} className={classes.groupSelectContainer}>
                  <Item className={classes.previewData}>
                    {sendData.groupName?.length > 0 &&
                      sendData.groupName?.map((newItem) => {
                        return <React.Fragment>{SmallCard(newItem, 'groups')}</React.Fragment>;
                      })}
                  </Item>
                </Item>
              </Item>
            </Item>

            <Item md={12} xs={12} className={classes.formContainer} mt={3}>
              <Item md={12} xs={12}>
                <Typography variant="h7" className={classes.label} sx={{ fontSize: '17px' }}>
                  Select Medium
                </Typography>
              </Item>
              <Item md={12} xs={12}>
                {Object.keys(medium)?.map((item) => {
                  return (
                    <FormControlLabel
                      sx={{ color: '#868686' }}
                      control={
                        <Checkbox
                          size="medium"
                          value={medium[item]}
                          checked={medium[item]}
                          onChange={(e) => handleCheckbox(item, e.target.checked)}
                        />
                      }
                      label={ConvertToUpperCase(item)}
                    />
                  );
                })}
              </Item>
            </Item>

            <Item md={12} xs={12} className={classes.formContainer} mt={3}>
              <Item md={12} xs={12}>
                <Typography variant="h7" className={classes.label} sx={{ fontSize: '17px' }}>
                  Send & Save
                </Typography>
              </Item>
              <Item md={12} xs={12}>
                <Item className={classes.saveContainer}>
                  <Item>
                    <RadioGroup
                      className={classes.label}
                      row
                      value={sendData.timeStatus}
                      onChange={(e) => handleStateChange('timeStatus', e.target.value)}>
                      <FormControlLabel value="now" control={<Radio />} label="Now" />
                      <FormControlLabel value="later" control={<Radio />} label="Later" />
                      <FormControlLabel value="recurring" control={<Radio />} label="Recurring" />
                    </RadioGroup>
                  </Item>

                  <Item sx={{ display: 'flex', gap: '10px', width: '350px', alignItems: 'center' }}>
                    <Typography className={classes.title} sx={{ width: '100px' }}>
                      Life span
                    </Typography>
                    <FormControl fullWidth size="small">
                      <Select
                        value={sendData?.lifeSpan}
                        // required={true}
                        onChange={(e) => {
                          handleStateChange('lifeSpan', e.target.value);
                        }}>
                        {lifeSpanArray?.map((item, index) => {
                          return (
                            <MenuItem value={item.value} key={index}>
                              {ConvertToUpperCase(item.name)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>
                </Item>
              </Item>
              <Item md={12} xs={12}>
                {sendData.timeStatus === 'later' && (
                  <Item md={12} xs={12} className={classes.formContainer} mt={2}>
                    <Item md={3.6} xs={12} className={classes.subContainer}>
                      <Typography className={classes.label} mr={2}>
                        Date
                      </Typography>
                      <TextField
                        variant="outlined"
                        name="laterDate"
                        size="small"
                        value={sendData.laterDate}
                        required={true}
                        onChange={(e) => handleStateChange('laterDate', e.target.value)}
                        type="date"
                        fullWidth
                      />
                    </Item>
                    <Item md={3.6} xs={12} className={classes.subContainer}>
                      <Typography className={classes.label} mr={2}>
                        Time
                      </Typography>
                      <Select
                        value={sendData.laterTime}
                        size="small"
                        required={true}
                        fullWidth
                        onChange={(e) => {
                          handleStateChange('laterTime', e.target.value);
                        }}>
                        {CreateMultipleTime()?.map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Item>
                    <Item md={4} xs={12} className={classes.subContainer}></Item>
                  </Item>
                )}
                {sendData.timeStatus === 'recurring' && (
                  <React.Fragment>
                    <Item md={12} xs={12}>
                      <Item className={classes.saveContainer}>
                        <Item
                          mt={2}
                          sx={{
                            display: 'flex',
                            gap: '10px',
                            width: '350px',
                            alignItems: 'center'
                          }}>
                          <Typography className={classes.title} sx={{ width: '100px' }}>
                            Repeat
                          </Typography>
                          <FormControl fullWidth size="small">
                            <Select
                              value={sendData?.recurringRepeat}
                              required={true}
                              onChange={(e) => {
                                handleStateChange('recurringRepeat', e.target.value);
                              }}>
                              {repeatArray?.map((item, index) => {
                                return (
                                  <MenuItem value={item.value} key={index}>
                                    {ConvertToUpperCase(item.name)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Item>
                        {sendData.recurringRepeat === 'week' && (
                          <Item
                            mt={2}
                            sx={{ height: 'auto' }}
                            className={classes.reciepentContainer}
                            md={7}
                            xs={12}>
                            {Object.keys(weekDays)?.map((item) => {
                              return (
                                <FormControlLabel
                                  sx={{ color: '#868686' }}
                                  control={
                                    <Checkbox
                                      size="medium"
                                      checked={weekDays[item]}
                                      onChange={(e) => handleDailyCheckData(item, e.target.checked)}
                                    />
                                  }
                                  label={ConvertToUpperCase(item)}
                                />
                              );
                            })}
                          </Item>
                        )}
                      </Item>
                    </Item>
                    <Item md={12} xs={12} className={classes.formContainer} mt={3}>
                      <Item md={3.6} xs={12} className={classes.subContainer}>
                        <Typography className={classes.label} mr={1} sx={{ width: '100px' }}>
                          Start Date
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="recurringStartDate"
                          size="small"
                          value={sendData.recurringStartDate}
                          required={true}
                          onChange={(e) => handleStateChange('recurringStartDate', e.target.value)}
                          type="date"
                          fullWidth
                        />
                      </Item>
                      <Item md={3.6} xs={12} className={classes.subContainer}>
                        <Typography className={classes.label} mr={1} sx={{ width: '100px' }}>
                          End Date
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="recurringEndDate"
                          size="small"
                          value={sendData.recurringEndDate}
                          required={true}
                          onChange={(e) => handleStateChange('recurringEndDate', e.target.value)}
                          type="date"
                          fullWidth
                        />
                      </Item>
                      <Item md={3.6} xs={12} className={classes.subContainer}>
                        <Typography className={classes.label} mr={2} sx={{ width: '100px' }}>
                          Time
                        </Typography>
                        <Select
                          value={sendData.recurringTime}
                          size="small"
                          required={true}
                          fullWidth
                          onChange={(e) => {
                            handleStateChange('recurringTime', e.target.value);
                          }}>
                          {CreateMultipleTime()?.map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Item>
                    </Item>
                  </React.Fragment>
                )}
              </Item>
              <Item md={12} xs={12} className={classes.saveContainer} mt={2}>
                {Object.keys(checkData)?.map((item) => {
                  return (
                    <FormControlLabel
                      sx={{ color: '#868686' }}
                      control={
                        <Checkbox
                          size="medium"
                          value={checkData[item]}
                          checked={checkData[item]}
                          onChange={(e) => handleCheckData(item, e.target.checked)}
                        />
                      }
                      label={ConvertToUpperCase(item)}
                    />
                  );
                })}
              </Item>
            </Item>

            <Item mt={2} sx={{ display: 'flex' }}>
              <Button
                style={{
                  marginLeft: 'auto',
                  backgroundColor: theme.palette.primary.tableHeadingColor,
                  color: '#FFFFFF',
                  width: '100px'
                }}
                type="submit"
                variant="contained"
                fullWidth>
                Send
              </Button>
            </Item>
          </form>
        </Paper>
      </Item>
      <Dialog
        isOpen={isOpenGroup}
        children={
          <AddRecipient
            status={
              sendData?.groupName?.length > 0 ||
              sendData?.location?.length > 0 ||
              sendData?.broadcast?.length > 0 ||
              sendData?.factory?.length > 0
                ? 'edit'
                : 'add'
            }
            resLocation={sendData.location}
            resGroupName={sendData.groupName}
            resFactory={sendData.factory}
            resDepartment={sendData.department}
            resCurrentCities={sendData.currentCities}
            handleClose={handleClose}
            handleGroupIndiviualData={handleGroupIndiviualData}
          />
        }></Dialog>
      <Dialog
        isOpen={isOpenGroupDetailsModal}
        children={<GroupDetailsModel handleClose={handleClose} />}></Dialog>
    </Container>
  );
};

export default withStyles(styles)(NewBroadcast);
