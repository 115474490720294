import { ResponsivePieCanvas } from '@nivo/pie';
import React, { useState, useEffect } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from '../styles';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';

const { withStyles, Grid } = Components;
const Item = (props) => <Grid item {...props} />;

// const data = [
//   { label: 'Fully', value: 60 / 100, color: '#1A9518', id: 'Fully' },
//   { label: 'Single Dose', value: 5 / 100, color: '#E32F1E', id: 'Single Dose' },
//   {
//     label: 'No Dose',
//     value: 15 / 100,
//     color: '#FCB94D',
//     id: 'No Dose'
//   },
//   { label: 'Booster', value: 20 / 100, color: '#6C7BC4', id: 'Booster' }
// ];

const MyResponsivePieCanvas = ({
  classes,
  selectedFromDate,
  selectedToDate,
  location,
  selectedDepartment
}) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;
    const resultDepartment =
      selectedDepartment === 'all' ? '' : `departmentId=${selectedDepartment}`;

    API_GET(
      `reports/getVaccineStatus?fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultLocation}&${resultDepartment}`
    )
      .then((res) => {
        const result = res?.data?.map((item) => {
          return {
            label: `${item?._id}`,
            color: item?.color,
            value: item?.percentage / 100,
            id: `${item?._id}`
          };
        });
        setRows(result);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location, selectedDepartment]);

  return (
    <React.Fragment>
      <Item sx={{ height: '220px' }}>
        <ResponsivePieCanvas
          theme={{
            fontSize: 14,
            fontWeight: '600'
          }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          valueFormat=" >-1.1%"
          data={rows}
          margin={{ top: 20, right: 160, bottom: 20, left: 20 }}
          innerRadius={0.7}
          // padAngle={0.0}
          cornerRadius={0}
          activeOuterRadiusOffset={8}
          colors={{ datum: 'data.color' }}
          arcLinkLabelsSkipAngle={0}
          arcLinkLabelsText={false}
          arcLinkLabelsTextColor="black"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={5}
          arcLabelsTextColor="black"
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 40,
              itemTextColor: 'black',
              itemDirection: 'left-to-right',
              symbolShape: 'circle',
              symbolSize: 18
            }
          ]}
        />
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
