import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import PandGHead from './PandGHead';
import { useStateValue } from '../../../../utils/store';
import { Components } from '../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR, API_PATCH, API_POST } from '../../../../utils/api';
import { getDateTime } from '../../../../utils/moment';
import Dialog from '../../../atom/Dialog';
import theme from '../../../../utils/theme';
import { useNavigate } from 'react-router';
import ActualIncidentNewsModal from '../../Modal/ActualIncidentNewsModal';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import ConvertForwardModal from '../../Modal/ConvertForwardModal';
import tableColor from '../../../../utils/tableColor';
import DeleteConfirmation from '../../Modal/DeleteConfirmation';
import { useLocation } from 'react-router-dom';

const {
  withStyles,
  // Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableCell,
  Button,
  Card
} = Components;

const Item = (props) => <Grid item {...props} />;

const AlertDetailsTable = ({
  classes,
  selectedFilterCategory,
  selectedMainCategory,
  selectedSubCategory,
  selectedFromDate,
  selectedToDate,
  getFilterCategory,
  getMainCategory,
  search,
  tab
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRejectedNews, setTotalRejectedNews] = React.useState(0);
  const [store, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [isOpenMoreModal, setIsOpenMoreModal] = useState(false);
  const [data, setData] = useState({});
  const [isForward, setIsForward] = useState(false);
  const [forwardId, setForwardId] = useState('');
  const [sort, setSort] = useState('all');
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState({});

  // check for P and G
  let resultHead;
  if (store?.corporateId === '63510bcc5ef0f87d095f5265') {
    resultHead = PandGHead;
  } else {
    resultHead = columns;
  }

  const navigate = useNavigate();
  const getSearch = useLocation().search;
  const fromNotify = new URLSearchParams(getSearch).get('fromNotify');

  useEffect(() => {
    if (!fromNotify) {
      setSort('all');
    } else {
      setSort('pending');
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // to reset page on search
  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllAlertDetail();
  }, [
    dispatch,
    page,
    rowsPerPage,
    selectedFilterCategory,
    selectedMainCategory,
    selectedSubCategory,
    isOpenMoreModal,
    selectedToDate,
    selectedFromDate,
    isForward,
    sort,
    search
  ]);

  const getAllAlertDetail = () => {
    const resultSort =
      sort !== 'all'
        ? !fromNotify
          ? `alertActionStatus=${sort}&fromNotify=false`
          : `alertActionStatus=${sort}&fromNotify=true`
        : 'fromNotify=false';
    const resultFilterCat = selectedFilterCategory === 'All' ? '' : selectedFilterCategory;
    const resultMainCat = selectedMainCategory === 'All' ? '' : selectedMainCategory;
    const resultSubCat = selectedSubCategory === undefined ? '' : selectedSubCategory;
    const resultSearch =
      search !== undefined && search !== ''
        ? `searchTag=${search}`
        : `fromDate=${selectedFromDate}&toDate=${selectedToDate}`;

    API_GET(
      `alert/getNewsByCond?alertCategory=${
        resultFilterCat === undefined ? '' : resultFilterCat
      }&categoryName=${resultMainCat}&subCategory=${resultSubCat}&limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&${resultSort}&${resultSearch}&tab=${tab}`
    )
      .then((res) => {
        setRows(res?.PickedNewsFeed);
        setTotalRejectedNews(res?.countNewsFeed);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const handleCloseMoreModal = () => {
    setIsOpenMoreModal(false);
  };

  const handleMore = (item) => {
    setData(item);
    setIsOpenMoreModal(true);
  };

  const handleChange = (e) => {
    setSort(e.target.value);
  };

  const handleClose = () => {
    setIsForward(false);
    setIsOpenDeleteModel(false);
  };

  const handleRemove = (item) => {
    setIsOpenDeleteModel(true);
    setDeleteId(item);
  };

  const onHandleCancel = (deletedItem) => {
    API_PATCH(`alert/removeAlertById/${deletedItem?._id}`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        getAllAlertDetail();
        getFilterCategory();
        getMainCategory();
        setIsOpenDeleteModel(false);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleForward = (row) => {
    setIsForward(true);
    setForwardId(row?._id);
  };

  const handleVerify = (item) => {
    API_POST(`alert/convertAlertToIncidentById/${item?._id}`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        getAllAlertDetail();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleMoveToIncident = () => {
    navigate('/incident-distribution');
    dispatch({
      type: 'IS_MOVE',
      isMove: true
    });
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Item className={classes.headers}>
        <Typography sx={theme.typography.tableHeading}>
          {`(${ConvertToUpperCase(selectedFilterCategory) || 'All'}) ${
            selectedMainCategory === 'All' ? '' : ConvertToUpperCase(selectedMainCategory)
          }`}
        </Typography>
        <Item sx={{ display: 'inline-flex', ml: 'auto', mr: 1 }}>
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
            <Select
              value={sort}
              sx={{ minWidth: '80px' }}
              label="Filter By"
              size="small"
              onChange={handleChange}>
              {[
                { label: 'All', value: 'all' },
                { label: 'Initiate', value: 'Marked As Incident' },
                { label: 'Non Initiate', value: 'pending' }
              ].map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Item>
      </Item>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {resultHead &&
                resultHead.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(rowIndex) }}>
                    {resultHead &&
                      resultHead.map((column, index) => {
                        const value = row[column.id] ?? '--';
                        if (column.id === 'rMSerialNumber') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {row?.rMSerialNumber || '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'category') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item sx={{ display: 'flex', gap: '10px' }}>
                                <Item>
                                  <Button
                                    style={{
                                      ...theme.button.tableSeverityButton,
                                      backgroundColor: row?.backgroundColor,
                                      color: row?.color
                                    }}>
                                    {row?.alertCategory}
                                  </Button>
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'title') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item
                                onClick={() => handleMore(row)}
                                sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                {ConvertToUpperCase(row?.title)}
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'action') {
                          if (row?.alertActionStatus) {
                            if (row?.alertActionStatus === 'Marked As Incident') {
                              return (
                                <StyledTableCell align={column.align} key={column.id}>
                                  <Button
                                    style={theme.button.trash}
                                    onClick={() => handleMoveToIncident()}>
                                    Initiated
                                  </Button>
                                </StyledTableCell>
                              );
                            } else {
                              return (
                                <StyledTableCell align="center" key={column.id}>
                                  <Item
                                    sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                    <Item>
                                      <Button
                                        style={theme.button.initiate}
                                        onClick={() => handleVerify(row)}>
                                        Initiate
                                      </Button>
                                    </Item>
                                    <Item>
                                      <Button
                                        style={theme.button.forward}
                                        onClick={() => handleForward(row)}>
                                        FORWARD
                                      </Button>
                                    </Item>
                                    <Item>
                                      <Button
                                        style={theme.button.trash}
                                        onClick={() => handleRemove(row)}>
                                        Trash
                                      </Button>
                                    </Item>
                                  </Item>
                                </StyledTableCell>
                              );
                            }
                          }
                        }
                        if (column.id === 'actionPandG') {
                          if (row?.alertActionStatus) {
                            if (row?.alertActionStatus === 'Marked As Incident') {
                              return (
                                <StyledTableCell align={column.align} key={column.id}>
                                  <Button style={theme.button.trash}>Initiated</Button>
                                </StyledTableCell>
                              );
                            } else {
                              return (
                                <StyledTableCell align="center" key={column.id}>
                                  <Item
                                    sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                    <Item>
                                      <Button
                                        style={theme.button.forward}
                                        onClick={() => handleVerify(row)}>
                                        Initiate
                                      </Button>
                                    </Item>
                                  </Item>
                                </StyledTableCell>
                              );
                            }
                          }
                        }

                        if (column.id === 'publishDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.createdAt)}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'eventDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {`${getDateTime(row?.eventDate)}`}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'cityFromDror') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {ConvertToUpperCase(
                                [row?.locationFromDror, row?.cityFromDror, row?.stateFromDror]
                                  .filter((item) => item !== '')
                                  .join(', ')
                              )}
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(ConvertToUpperCase(value))
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalRejectedNews && totalRejectedNews ? totalRejectedNews : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        isOpen={isOpenDeleteModel}
        children={
          <DeleteConfirmation
            handleClose={handleClose}
            deleteId={deleteId}
            onHandleDelete={onHandleCancel}
          />
        }></Dialog>
      <Dialog
        isOpen={isOpenMoreModal}
        children={
          <ActualIncidentNewsModal
            handleClose={handleCloseMoreModal}
            data={data}
            selectedFilterCategory={selectedFilterCategory}
          />
        }></Dialog>
      <Dialog
        isOpen={isForward}
        children={
          <ConvertForwardModal handleClose={handleClose} forwardId={forwardId} isIncident={true} />
        }></Dialog>
    </Card>
  );
};

export default withStyles(styles)(AlertDetailsTable);
