const styles = (theme) => {
  return {
    newUser: {
      height: 'auto',
      padding: theme.spacing(1, 1.5)
    }
  };
};

export default styles;
