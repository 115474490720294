import React, { useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import CountCard from './countCard';
import theme from '../../utils/theme';
import riskReport from './RiskReport/riskReport';
import revenueReport from './RevenueReport/revenueReport';
import employeeReport from './EmployeeReport/employeeReport';
import SosReport from './SosReport/SosReport';
// import BroadCastDetailTable from '../../components/organism/Table/BroadCastDetailTable';
import { useStateValue } from '../../utils/store';
import BroadcastReports from './BroadcastReports/BroadcastReports';

const { withStyles, Grid, Paper } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const CompanyProfile = ({ classes }) => {
  const [store] = useStateValue();

  // check for png
  let cards;
  if (store?.corporateId === '63510bcc5ef0f87d095f5265') {
    cards = [
      {
        value: 'sos',
        label: 'SOS Report',
        table: SosReport,
        bgColor: '#FF6760',
        bgImage: 'https://i.ibb.co/NjPH8hP/Rectangle-2.png'
      },
      {
        value: 'riskReport',
        label: 'Risk Report',
        table: riskReport,
        bgColor: '#3B9DF7',
        bgImage: 'https://i.ibb.co/ZBj3B4b/Rectangle.png'
      },
      {
        value: 'Broadcast',
        label: 'Broadcast',
        table: BroadcastReports,
        bgColor: '#5768BC'
      }
    ];
  } else {
    cards = [
      {
        value: 'employeeReport',
        label: 'Employee Report',
        table: employeeReport,
        bgColor: '#FFC127',
        bgImage: 'https://i.ibb.co/ZBj3B4b/Rectangle.png'
      },
      {
        value: 'riskReport',
        label: 'Risk Report',
        table: riskReport,
        bgColor: '#3B9DF7',
        bgImage: 'https://i.ibb.co/ZBj3B4b/Rectangle.png'
      },
      {
        value: 'revenueReport',
        label: 'Revenue Report',
        table: revenueReport,
        bgColor: '#4BA04B',
        bgImage: 'https://i.ibb.co/ZBj3B4b/Rectangle.png'
      },
      {
        value: 'sos',
        label: 'SOS Report',
        table: SosReport,
        bgColor: '#FF6760',
        bgImage: 'https://i.ibb.co/NjPH8hP/Rectangle-2.png'
      },
      {
        value: 'Broadcast',
        label: 'Broadcast',
        table: BroadcastReports,
        bgColor: '#5768BC'
      }
    ];
  }

  const [selectedCard, setSelectedCard] = useState(cards[0]);

  return (
    <Container spacing={1} className={classes.mainContainer}>
      <Item md={2.5} xs={12}>
        <Paper elevation={3} sx={{ ...theme.card, position: 'sticky', top: '70px' }}>
          <Item
            style={{
              padding: '20px',
              minHeight: '90vh',
              maxHeight: 'auto'
            }}>
            {cards.map((item) => (
              <Item key={item.value} sx={{ marginBottom: '20px', width: '100%' }}>
                <CountCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
              </Item>
            ))}
          </Item>
        </Paper>
      </Item>
      <Item md={9.5} xs={12}>
        <Paper sx={theme.card}>
          <selectedCard.table></selectedCard.table>
        </Paper>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(CompanyProfile);
