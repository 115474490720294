const styles = (theme) => {
  return {
    container: {
      width: '50vw'
    },
    mainContainer: {
      backgroundColor: theme.palette.common.gray98,
      padding: theme.spacing(2, 2)
    },
    selectedCard: {
      width: '100%',
      height: '30px',
      color: 'black',
      // display: 'flex',
      flexDirection: 'column',
      // backgroundColor: theme.palette.primary.secondtheme,
      textAlign: 'left',
      padding: theme.spacing(1),
      margin: theme.spacing(2, 0)
    },
    unselectedCard: {
      cursor: 'pointer',
      width: '100%',
      height: '30px',
      color: 'black',
      backgroundColor: 'white',
      // display: 'flex',
      textAlign: 'left',
      flexDirection: 'column',
      padding: theme.spacing(1),
      margin: theme.spacing(2, 0)
    },
    tableContainer: {
      width: '100%',
      height: '75vh',
      marginTop: theme.spacing(1)
      // padding: theme.spacing(5)
    },
    tableContainer1: {
      height: '75vh',
      marginTop: theme.spacing(1),
      padding: theme.spacing(2, 5)
    },
    newUser: {
      width: '650px',
      height: 'auto',
      boxShadow: '0px 1px 5px grey',
      borderRadius: theme.spacing(2)
    },
    cardHeading: {
      padding: '8px 20px',
      backgroundColor: theme.palette.primary.tableHeadingColor,
      borderRadius: '8px 8px 0 0',
      color: theme.palette.common.white,
      textAlign: 'center'
    },

    newUserForm: {
      display: 'flex',
      padding: theme.spacing(0, 2)
    },
    formContainer: {
      padding: theme.spacing(2)
    }
  };
};

export default styles;
