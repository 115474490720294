import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from '../styles';
// import moment from 'moment';
import theme from '../../../../utils/theme';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import { useNavigate } from 'react-router-dom';
// import { cookieRemove } from '../../../../utils/cookie';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Avatar,
  withStyles,
  Box,
  useMediaQuery
} = Components;
const { MenuIcon, NotificationsIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

const Header = ({
  classes,
  handleDrawerToggle,
  drawerWidth,
  label,
  trackNotification,
  handleNotification
}) => {
  const [store, dispatch] = useStateValue();
  const [data, setData] = useState({});
  const [corporateName, setCorportaeName] = useState('');
  const navigate = useNavigate();
  const [unreadNotification, setUnreadNotification] = useState();
  const url = window.location.href;

  const scrrenSizeSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const [role] = useState(store.role);

  const handleOpen = () => {
    navigate('/settings');
  };

  const getUserDetails = () => {
    API_GET('user/getUserDetails')
      .then((res) => {
        setData(res?.data?.user);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const getCorporateDetails = () => {
    API_GET('corporate/getCorporate')
      .then((res) => {
        setCorportaeName(res?.data?.name);
        dispatch({
          type: 'CORPORATE_ID',
          corporateId: res?.data?._id
        });
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const getNotificationCount = () => {
    API_GET(`notification/getPushNotification`)
      .then((res) => {
        setUnreadNotification(res?.data?.unreadNotificationCount);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getNotificationCount();
  }, [url, trackNotification]);

  useEffect(() => {
    getCorporateDetails();
    getUserDetails();
  }, [dispatch]);

  // const handleClick = () => {
  //   dispatch({
  //     type: 'IS_LOGGED_IN',
  //     isLogin: false,
  //     role: ''
  //   });
  //   dispatch({
  //     type: 'ALERT_OPEN',
  //     severity: 'success',
  //     isOpen: true,
  //     label: 'Logout Successfully'
  //   });
  //   cookieRemove();
  //   navigator.serviceWorker
  //     .getRegistrations()
  //     .then(function (registrations) {
  //       for (const registration of registrations) {
  //         registration.unregister();
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   navigate('/login');
  // };

  return (
    <React.Fragment>
      {scrrenSizeSmall ? (
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            backgroundColor: theme.palette.primary.secondarymain,
            color: theme.palette.primary.textColor,
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px'
          }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                fontSize: '25px',
                fontFamily: theme.palette.font.fontFamily,
                fontWeight: '600',
                color:
                  role === 'sosAdmin'
                    ? theme.palette.primary.sosDashboard
                    : theme.palette.primary.maintextColor
              }}>
              {label === 'Welcome' ? `Welcome ${corporateName}` : label}
            </Typography>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                fontSize: '2vw',
                fontFamily: theme.palette.font.fontFamily
              }}>
              {label === 'Welcome' ? `Welcome ${corporateName}` : label}
            </Typography>

            {/* <Typography className={classes.smallHeading} justifyContent="flex-end">
              {moment(new Date()).format('DD/MM/YYYY')}
            </Typography> */}
            {/*  <Typography className={classes.smallHeading}>
          <span> Time </span>
        </Typography> */}
            {role !== 'agent' && (
              <Box className={classes.smallHeading} mt={1} style={{ position: 'relative' }}>
                {unreadNotification > 0 && (
                  <Item
                    className={classes.notificationContainer}
                    sx={{ backgroundColor: theme.palette.primary.main }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                      {unreadNotification > 99 ? `99+` : unreadNotification}
                    </Typography>
                  </Item>
                )}

                <NotificationsNoneOutlinedIcon
                  onClick={() => handleNotification()}
                  sx={{ color: theme.typography.tableHeading.color, cursor: 'pointer' }}
                />
              </Box>
            )}

            <Tooltip onClick={() => handleOpen()} sx={{ ml: 2 }}>
              <IconButton>
                <Avatar
                  sx={{ bgcolor: data?.profileColor }}
                  alt={ConvertToUpperCase(data?.fullName)}
                  src={
                    data?.profileImage ||
                    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
                  }
                />
              </IconButton>
            </Tooltip>
            <Box sx={{ ml: 1 }}>
              <Typography
                className={classes.smallHeading}
                sx={{ ...theme.typography.tableHeading, p: 0, fontSize: '16px' }}>
                {ConvertToUpperCase(data?.fullName)}
              </Typography>
              <Typography className={classes.smallHeading} sx={{ color: '#818181' }}>
                {data?.email}
              </Typography>
              {/*  <Typography
                className={classes.smallHeading1}
                onClick={handleClick}
                sx={{ cursor: 'pointer' }}>
                {` Sign Out`}
                </Typography> */}
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            border: '1px solid white',
            width: { sm: `calc(99% - ${drawerWidth}px)` },
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.secondarymain
          }}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ fontSize: '35px' }}>
              <MenuIcon />
            </IconButton>
            <Item>
              <img
                src={
                  store?.corporateId === theme.corporate.mliCorporateId
                    ? `${require(`../../../../Assets/logo/maxlifeLogo.png`).default}`
                    : 'https://drorlabstorage.blob.core.windows.net/dror/lythouse-logo.png'
                }
                alt="logo"
                width="120px"
                height="34px"
                style={{ filter: 'brightness(0) invert(1)' }}
              />
            </Item>

            <Item sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <Box className={classes.smallHeading} mt={1} style={{ position: 'relative' }}>
                {unreadNotification > 0 && (
                  <Item
                    className={classes.notificationContainer}
                    sx={{ backgroundColor: theme.palette.primary.main }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                      {unreadNotification > 99 ? `99+` : unreadNotification}
                    </Typography>
                  </Item>
                )}

                <NotificationsIcon
                  onClick={() => handleNotification()}
                  sx={{ color: '#FFFFFF', cursor: 'pointer', fontSize: '25px' }}
                />
              </Box>
              <IconButton>
                <Avatar
                  sx={{ bgcolor: data?.profileColor }}
                  alt={ConvertToUpperCase(data?.fullName)}
                  src={
                    data?.profileImage ||
                    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
                  }
                />
              </IconButton>
            </Item>
          </Toolbar>
        </AppBar>
      )}
    </React.Fragment>
  );
};
export default withStyles(styles)(Header);
