import React, { useState } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import RiskCard from './riskCard';
import ExternalRiskGraphs from './ExternalRiskContainer';
import InternalRiskGraph from './InternalRiskContainer';
import moment from 'moment';
import { useStateValue } from '../../../utils/store';

const { withStyles, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const RiskReport = ({ classes }) => {
  const [store] = useStateValue();
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const handleGetDates = (fromDate, toDate) => {
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);
  };
  return (
    <Container direction="column" className={classes.newUser}>
      <Typography className={classes.cardHeading} sx={{ backgroundColor: '#3B9DF7' }} variant="h6">
        Risk Report
      </Typography>
      <Item className={classes.tableContainer1}>
        <Typography
          classsName={classes.containHeading}
          sx={{ ...theme.typography.tableHeading, color: theme.palette.primary.main, pl: 0 }}>
          Overview
        </Typography>
        <Item>
          <RiskCard selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} />
        </Item>
        <Item mt={1.5}>
          <Typography
            classsName={classes.containHeading}
            sx={{ ...theme.typography.tableHeading, color: theme.palette.primary.main, pl: 0 }}>
            External Risks
          </Typography>
          <p style={{ fontSize: '0.9rem' }}>
            A total of external risks, which are beyond the control of the company, faced depending
            on their categories based on severity, location-wise assessment, and area of concern for
            easier mitigation. You get in in depth analysis of all the risks including the severity
            of the risk and can track the status of completion of the task.
          </p>
        </Item>
        <Item sx={{ ...theme.card, height: '650px', mt: 1.5, p: 1.5 }}>
          <ExternalRiskGraphs handleGetDates={handleGetDates} />
        </Item>
        {/* check for P and G */}

        {store?.corporateId === '63510bcc5ef0f87d095f5265' ? (
          ''
        ) : (
          <React.Fragment>
            <Item mt={1.5}>
              <Typography
                classsName={classes.containHeading}
                sx={{ ...theme.typography.tableHeading, color: theme.palette.primary.main, pl: 0 }}>
                Internal Risks
              </Typography>
              <p style={{ fontSize: '0.9rem' }}>
                The risk faced due to normal operations of the company and their categorization on
                the basis of the department, locality, severity, and the status of completion. Track
                the status of completion and severity of risks 1 being the least severe and 10 being
                the most severe.
              </p>
            </Item>
            <Item sx={{ ...theme.card, height: '650px', mt: 1.5, p: 1.5 }}>
              <InternalRiskGraph />
            </Item>
          </React.Fragment>
        )}
      </Item>
    </Container>
  );
};

export default withStyles(styles)(RiskReport);
