const head = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'safetyCategory',
    label: 'Category',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'ticketNumber',
    label: 'Ticket No',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'event',
    label: 'Incident',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'forwardedOn',
    label: 'Fowarded On',
    minWidth: 130,
    width: 130,
    maxWidth: 130,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'forwardedTo',
    label: 'Incident Log',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'approvalStatus',
    label: 'Approval status',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'docs',
    label: 'Docs',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'severity',
    label: 'Severity',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'center'
  },

  {
    id: 'officeAddress',
    label: 'Location',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'forwardedBy',
    label: 'Forwarded By',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
