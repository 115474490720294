import React from 'react';
import { Components, Icons } from './material-ui';
import theme from './theme';

const { Grid, Button, Paper } = Components;
const { CancelIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

const smallContainer = {
  boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
  alignItems: 'center',
  color: theme.palette.primary.main,
  backgroundColor: theme.headingBgColor,
  minWidth: '100px',
  padding: theme.spacing(0.5),
  borderRadius: '5px'
};
const Filter = ({ filtersCategory, sort, handleClearAll, handleRemove }) => {
  const SmallCard = (item, status) => {
    return (
      <Item style={smallContainer}>
        {status === 'sort' ? (
          <Item sx={{ fontSize: '15px' }}>{`Sort By : Name, ${
            item === 1 ? 'Asc - Dec' : 'Dec - Asc'
          }`}</Item>
        ) : (
          <Item sx={{ fontSize: '14px' }}>{`Filter By : ${item?.type}, ${item?.label}`}</Item>
        )}
        <CancelIcon
          onClick={() => handleRemove(item, status)}
          sx={{ cursor: 'pointer', fontSize: '17px', color: theme.palette.primary.main }}
        />
      </Item>
    );
  };
  return (
    <Item md={12} xs={12} mt={1}>
      <Paper
        md={12}
        xs={12}
        sx={{
          ...theme.card,
          p: 1,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          minHeight: '55px',
          gap: '15px'
        }}>
        {(sort === 1 || sort === -1) && <Item>{SmallCard(sort, 'sort')}</Item>}
        {filtersCategory?.map((item) => {
          return <Item>{SmallCard(item, 'filter')}</Item>;
        })}
      </Paper>
      <Button
        sx={{ ...theme.button.cancel, p: '0.2 1', width: 'auto', mt: 1.8, fontWeight: '500' }}
        onClick={handleClearAll}>
        Clear all Filter
      </Button>
    </Item>
  );
};

export default Filter;
