const head = [
  {
    id: 'fullName',
    label: 'FullName',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'mobileNumber',
    label: 'Mobile Number',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'relation',
    label: 'Relation',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  }
];

export default head;
