import React from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import moment from 'moment';
import { getTime } from '../../utils/moment';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';

const { withStyles, Grid, Typography } = Components;
const { DeleteIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

const formatDate = (date) => {
  return moment(date).format('Do MMMM YYYY');
};

// const formatMealType = (mealType) => {
//   const result = mealType?.map((item) => {
//     return ConvertToUpperCase(item[0]);
//   });
//   return result;
// };

const PackageDetails = ({ classes, allPackageIds, data, familyDetails, handleDelete }) => {
  const ItiniearyDetails = (packagedata) => {
    const pacakgeDataDetails = {
      'Date  (From - To)': `${formatDate(packagedata?.arrivalTime)} - ${formatDate(
        packagedata?.departureTime
      )}`,
      'Arrival Time': getTime(packagedata?.arrivalTime),
      'Departure Time': getTime(packagedata?.arrivalTime),
      Hotel: packagedata?.hotelName || '-',
      Place: packagedata?.place || '-',
      'Total Days': packagedata?.totalDays || '-',
      'Mode of Transport': packagedata?.modeOfTransport.join(',') || '-'
    };
    return (
      <Item
        p={1}
        md={12}
        sx={{
          border: '1px solid #D0D0D0',
          borderRadius: '10px'
        }}>
        {Object.keys(pacakgeDataDetails)?.map((item, index) => {
          return (
            <Item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} key={index}>
              <Item xs={5}>{item}</Item>
              <Item>:</Item>
              <Item xs={6}>{pacakgeDataDetails[item]}</Item>
            </Item>
          );
        })}
      </Item>
    );
  };

  const UserDetails = (item) => {
    const pacakgeDataDetails = {
      Name: ConvertToUpperCase(item?.name),
      Relation: ConvertToUpperCase(item?.relation?.relation),
      'Phone No': item?.mobileNumber,
      'Pending docs': `${item?.docsCount?.pendingDocsCount}/7`,
      'To be Expire': `${item?.docsCount?.toBeExpiredDocsCount}/7`,
      Expired: `${item?.docsCount?.expiredDocsCount}/7`
    };
    return (
      <Item
        p={1}
        md={12}
        xs={12}
        sx={{
          border: '1px solid #D0D0D0',
          borderRadius: '10px'
        }}>
        <Item sx={{ float: 'right' }}>
          <DeleteIcon onClick={() => handleDelete(item?.userId)} style={{ cursor: 'pointer' }} />
        </Item>
        {Object.keys(pacakgeDataDetails)?.map((item, index) => {
          return (
            <Item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} key={index}>
              <Item xs={5}>{item}</Item>
              <Item> : </Item>
              <Item xs={6}>{pacakgeDataDetails[item]}</Item>
            </Item>
          );
        })}
      </Item>
    );
  };

  return (
    <Item>
      <Item md={12} xs={12} mt={0.5} className={classes.packageDetails_container}>
        <Item>
          <Typography
            className={classes.package_subHeading}
            sx={{ fontSize: '1rem', fontWeight: '600' }}>
            {`Itinieary Details (${data?.packageName}) `}
          </Typography>
          <Item mt={1} xs={12}>
            {data?.packageDetails?.map((packagedata, index) => {
              return ItiniearyDetails(packagedata);
            })}
          </Item>
        </Item>
        <Item mt={2}>
          <Typography
            className={classes.package_subHeading}
            sx={{ fontSize: '1rem', fontWeight: '600' }}>
            Family Members Details
          </Typography>
          <Item mt={1} xs={12}>
            {familyDetails?.map((item, index) => {
              return <Item key={index}>{UserDetails(item)}</Item>;
            })}
          </Item>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(PackageDetails);
