const styles = (theme) => {
  return {
    popupContainer: {
      padding: theme.spacing(2),
      minWidth: '40vw',
      maxWidth: '40vw'
    },
    contain: {
      height: 'auto',
      minHeight: '100px',
      maxheight: '150px',
      overflow: 'auto',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      marginTop: theme.spacing(1)
    },
    healthCard: {
      height: '250px',
      display: 'flex'
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    }
  };
};

export default styles;
