import React, { useState, useEffect, useRef } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import { useStateValue } from '../../../../utils/store';
import {
  API_GET,
  API_HANDLE_ERROR,
  API_POST,
  API_PUT,
  PRE_SEND_URL,
  API_DELETE
} from '../../../../utils/api';
import { getTimeInMins } from '../../../../utils/moment';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import ReplySection from './ReplySection';
import bytesTosize from '../../../../utils/bytesTosize';
import { io } from 'socket.io-client';
import { socket } from '../../../../config';
const axios = require('axios');

const { withStyles, Grid, Typography, Menu, MenuItem } = Components;

const { CloseIcon, ReplyIcon, AttachFileIcon, MoreVertIcon, ArrowBackIosIcon } = Icons; // EditIcon

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

let socketIO;

function ChatModal({ classes, handleClose, chatData }) {
  const [, dispatch] = useStateValue();
  const [inputText, setInputText] = useState('');

  const [commentsData, setCommentsData] = useState([]);
  const [replyData, setReplyData] = useState({});
  const [replyDate, setReplyDate] = useState({});
  const [currentUser, setCurrentUser] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isReplyModal, setIsReplyModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [trigger, setTrigger] = useState(false);

  const chatEndRef = useRef(null);

  const hiddenFileInput = useRef(null);

  const handleFileInput = (event) => {
    hiddenFileInput.current.click();
  };

  const handleTrigger = () => {
    setTrigger((prevState) => !prevState);
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleStateChange = (e) => {
    setInputText(e.target.value);
  };

  const handleReply = (comment, item) => {
    setReplyData(comment);
    setReplyDate(item?._id);
    setIsReplyModal(true);
  };
  const handleCloseReply = () => {
    setIsReplyModal(false);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    socketIO = io(`${process.env.REACT_APP_APP_URL}/incidentChat`, socket.config);

    socketIO.on('connect', () => {
      console.log('Chat Socket connected successfully!!');
    });

    socketIO.emit('initiate', { roomId: chatData?._id });

    socketIO.on('newReply', () => {
      handleTrigger();
    });
  }, []);

  const getAllComments = () => {
    let getUrl = `comment/${chatData?._id}?level=first`;
    if (isReplyModal) {
      getUrl = `comment/${chatData?._id}?level=second&parentCommentId=${replyData?._id}`;
    }

    chatData?._id &&
      API_GET(getUrl)
        .then((res) => {
          setCommentsData(res?.data);
          setCurrentUser(res?.currentUserId);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  useEffect(() => {
    getAllComments();
  }, [isReplyModal, trigger]);

  useEffect(scrollToBottom, [commentsData]);

  const preSignedUrl = async (type, name) => {
    try {
      const data = await PRE_SEND_URL('user/incident/preSignedMediaUrl', {
        fileType: type,
        fileName: name.split('.')[0],
        featureType: 'chatFiles'
      });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFiles = async () => {
    let imageArray = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const { name, type, extension, size, file } = selectedFiles[i];
      const url = await preSignedUrl(extension, name);

      const config = {
        method: 'put',
        url: url.signedUrl,
        headers: {
          'x-ms-blob-content-type': `${type}/${extension}`,
          'content-type': 'application/octet-stream',
          'x-ms-blob-type': 'BlockBlob'
        },
        data: file
      };

      try {
        const uploaded = await axios(config);
        if (uploaded.status === 201) {
          imageArray = [
            ...imageArray,
            {
              url: url.mediaUrl,
              name,
              type,
              extension,
              size
            }
          ];
        }
      } catch (e) {
        API_HANDLE_ERROR(e, dispatch);
      }
    }
    dispatch({
      type: 'ALERT_OPEN',
      severity: 'success',
      isOpen: true,
      label: 'upload Successfully'
    });

    return imageArray;
  };

  const handleAddBySearch = async (e) => {
    e.preventDefault();
    let files;

    if (selectedFiles.length > 0) {
      setIsLoading(true);
      files = await uploadFiles();
    }

    const postData = {
      content: inputText,
      level: 'first',
      files
    };

    if (isReplyModal) {
      postData.level = 'second';
      postData.responseTo = replyData?.writer;
      postData.parentCommentId = replyData?._id;
    }

    if (isEdit) {
      if (inputText) {
        API_PUT(`comment/${editId}?level=${isReplyModal ? 'second' : 'first'}`, {
          content: inputText,
          files
        })
          .then((res) => {
            setIsLoading(false);
            socketIO.emit('newReply', chatData?._id);
            setInputText('');
            getAllComments();
            setIsEdit(false);
            setEditId('');
            setSelectedFiles([]);
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
            setIsEdit(false);
          });
      }
    } else {
      if (inputText) {
        API_POST(`comment/${chatData?._id}`, postData)
          .then((res) => {
            setIsLoading(false);
            socketIO.emit('newReply', chatData?._id);
            setInputText('');
            getAllComments();
            setSelectedFiles([]);
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      }
    }
  };

  const handleDeleteComment = (comment) => {
    API_DELETE(`comment/${comment?._id}`)
      .then((res) => {
        socketIO.emit('newReply', chatData?._id);
        setInputText('');
        getAllComments();
        setSelectedFiles([]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleFileDelete = (comment, file) => {
    API_DELETE(`comment/deleteFile/${comment?._id}/${file._id}`)
      .then((res) => {
        socketIO.emit('newReply', chatData?._id);
        setIsEdit(false);
        setInputText('');
        getAllComments();
        setSelectedFiles([]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleEdit = (comment) => {
    setIsEdit(!isEdit);
    setInputText(isEdit ? '' : comment?.content);
    setEditId(comment?._id);
  };

  const checkFileErrors = (files) => {
    let error = false;

    if (files.length > 3) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Only Maximum of 3 files allowed'
      });
      return true;
    }

    for (const item of files) {
      const size = item.size;
      // if file size greater than 500MB throw error
      if (size > 500000000) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Max file size allowed is 500MB'
        });
        error = true;
      }
    }
    return error;
  };

  const handleFile = async (e) => {
    const arrayImage = [];
    const files = Array.from(e.target.files);

    const fileError = checkFileErrors(files);

    if (fileError) {
      return;
    }

    files.map((item) => {
      const docsType = item.type?.split('/');
      const name = item.name;
      const file = item;
      const size = item.size;

      arrayImage.push({
        name: name,
        type: docsType[0],
        extension: docsType[1],
        size,
        file: file
      });
      return null;
    });
    setSelectedFiles([...arrayImage]);
    e.target.value = null;
  };

  const handleFileDownload = async (imageUrl) => {
    // function to download image from link and convert it to objectUrl
    const dataToURL = (url) => {
      return fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          return URL.createObjectURL(blob);
        });
    };

    // logic to download to local
    const a = document.createElement('a');
    a.href = await dataToURL(imageUrl);
    a.download = '';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleFileRemove = (index) => {
    const data = selectedFiles;
    selectedFiles.splice(index, 1);
    setSelectedFiles([...data]);
  };

  const getHeader = () => {
    if (!isReplyModal) {
      return (
        <Item
          sx={{
            textAlign: 'center',
            color: 'Black',
            position: 'sticky',
            top: 0,
            width: 'auto',
            height: '30px',
            backgroundColor: '#FFFFFF',
            fontSize: '16px',

            p: 0.2
          }}>
          Status
        </Item>
      );
    } else {
      return (
        <Item
          sx={{
            display: 'flex',
            position: 'sticky',
            top: 0,
            mt: 0.5,
            // ml: '5%',
            backgroundColor: '#FFFFFF'
            // border: '1px solid black'
          }}>
          <Item>
            <ArrowBackIosIcon
              sx={{ fontSize: '22px', cursor: 'pointer' }}
              onClick={() => handleCloseReply()}
            />
          </Item>
          <Item
            sx={{
              textAlign: 'center',
              color: 'Black',
              width: 'auto',
              fontSize: '16px',
              ml: 15
            }}>
            Reply
          </Item>
        </Item>
      );
    }
  };

  const getReplyOriginMessage = () => {
    return (
      <Item>
        <Item sx={{ textAlign: 'center', color: '#9B9B9B' }}>{replyDate}</Item>
        <Item>
          <Item
            sx={{
              height: '40px',
              width: '100%',
              display: 'flex',
              gap: '10px',
              mt: 1.5
            }}>
            {replyData?.profileImage ? (
              <Item
                sx={{
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                  fontSize: '25px',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <img
                  src={replyData?.profileImage}
                  alt="profile"
                  style={{ height: '100%', width: '100%', borderRadius: '50%' }}
                />
              </Item>
            ) : (
              <Item
                sx={{
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                  backgroundColor: replyData?.usercolor,
                  fontSize: '22px',
                  display: 'flex',
                  justifyContent: 'center',
                  fontWeight: '500',
                  pt: 0.3
                }}>
                {(replyData?.fullName && ConvertToUpperCase(replyData?.fullName[0])) || 'J'}
              </Item>
            )}
            <Item sx={{ mt: 0.7, fontSize: '18px' }}>
              {ConvertToUpperCase(replyData?.fullName)}
            </Item>
            <Item sx={{ mt: 1.5, fontSize: '10px', color: 'grey' }}>12:45 PM</Item>
          </Item>
          <Item sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Item
              sx={{
                width: '220px',
                height: 'auto',
                border: '1px solid lightGrey',
                borderRadius: '10px',
                ml: 5.5,
                p: 1,
                fontSize: '11px',
                fontWeight: '600',
                lineHeight: '15px',
                backgroundColor: `${theme.palette.primary.main}`
              }}>
              {replyData?.content}
            </Item>
          </Item>
        </Item>
      </Item>
    );
  };

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: '350px', height: '75vh' }}>
      <Item className={classes.title} sx={{ display: 'flex', alignItems: 'center' }}>
        <Item>
          <Typography sx={{ fontSize: '18px' }}>{chatData?.ticketNumber}</Typography>
        </Item>
        <Item sx={{ display: 'inline-flex', ml: 'auto', cursor: 'pointer' }}>
          <CloseIcon onClick={() => handleClose()} />
        </Item>
      </Item>

      <Item
        sx={{
          height: '60vh',
          width: '94%',
          m: '2% 3%',
          overflow: 'auto'
        }}>
        {getHeader()}
        {isReplyModal && getReplyOriginMessage()}
        {commentsData?.map((item) => {
          return (
            <Item key={item._id}>
              <Item
                sx={{
                  textAlign: 'center',
                  color: '#9B9B9B',
                  position: 'sticky',
                  top: 25,
                  width: 'auto',
                  height: '30px',
                  backgroundColor: '#FFFFFF',
                  fontSize: '16px',
                  mt: 0.5,
                  p: 0.2
                }}>
                {item?._id}
              </Item>

              {item?.data?.map((comment) => {
                return comment.type === 'event' ? (
                  <Item>
                    <Typography sx={{ color: 'red', fontSize: '14px', textAlign: 'center' }}>
                      {comment.title}
                    </Typography>

                    <Item
                      sx={{
                        width: '230px',
                        height: 'auto',
                        border: '0.5px solid lightGrey',
                        // boxShadow: '0px 0px 4px lightGrey',
                        borderRadius: '10px',
                        ml: 5.5,
                        p: 1,
                        mt: 0.5,
                        backgroundColor: '#FFFFFF',
                        fontSize: '11px',
                        fontWeight: '600',
                        lineHeight: '15px',
                        display: 'flex',
                        m: 'auto'
                      }}>
                      {comment.content}
                    </Item>
                  </Item>
                ) : (
                  <Item>
                    <Item
                      sx={{
                        height: '40px',
                        width: '100%',
                        display: 'flex',
                        gap: '10px',
                        mt: 1
                      }}>
                      {comment?.profileImage ? (
                        <Item
                          sx={{
                            height: '40px',
                            width: '40px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center'
                          }}>
                          <img
                            src={comment?.profileImage}
                            alt="profile"
                            style={{ height: '100%', width: '100%', borderRadius: '50%' }}
                          />
                        </Item>
                      ) : (
                        <Item
                          sx={{
                            height: '40px',
                            width: '40px',
                            borderRadius: '50%',
                            backgroundColor: comment?.usercolor,
                            fontSize: '22px',
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: '500',
                            pt: 0.3
                          }}>
                          {(comment?.fullName && ConvertToUpperCase(comment?.fullName[0])) || '-'}
                        </Item>
                      )}
                      <Item sx={{ mt: 0.7, fontSize: '16px' }}>
                        {ConvertToUpperCase(comment?.fullName)}
                      </Item>
                      <Item sx={{ mt: 1.5, fontSize: '10px', color: 'grey' }}>
                        {getTimeInMins(comment?.createdAt)}
                      </Item>
                    </Item>
                    <Item
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px'
                      }}>
                      <Item
                        sx={{
                          width: '220px',
                          height: 'auto',
                          border: '1px solid lightGrey',
                          borderRadius: '10px',
                          ml: 5.5,
                          p: 1,
                          backgroundColor: currentUser === comment?.writer ? '#E0E0E0' : '#FFFFFF',
                          fontSize: '12px',
                          fontWeight: '600',
                          lineHeight: '15px',
                          display: 'flex'
                        }}>
                        <Container justifyContent={'space-between'} alignItems="center">
                          <Item>{comment?.content}</Item>

                          {currentUser === comment?.writer ? (
                            <Item>
                              <MoreVertIcon
                                sx={{
                                  ml: 'auto',
                                  fontSize: '16px',
                                  mb: 'auto',
                                  color: isEdit ? 'red' : '#585858',
                                  cursor: 'pointer'
                                }}
                                onClick={handleOptionClick}
                              />
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleOptionClose}>
                                <MenuItem
                                  onClick={() => {
                                    handleOptionClose();
                                    handleEdit(comment);
                                  }}>
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleOptionClose();
                                    handleDeleteComment(comment);
                                  }}>
                                  Delete
                                </MenuItem>
                              </Menu>
                            </Item>
                          ) : (
                            ''
                          )}
                        </Container>
                      </Item>
                      {!isReplyModal && (
                        <Item>
                          <ReplyIcon
                            sx={{ color: '#585858', cursor: 'pointer' }}
                            onClick={() => handleReply(comment, item)}
                          />
                        </Item>
                      )}
                    </Item>

                    <Item sx={{ display: 'flex', mb: 1 }}>
                      <Item
                        sx={{
                          display: 'inline-flex',
                          ml: 'auto',
                          mr: '62px',
                          fontSize: '11px',
                          color: '#9B9B9B',
                          mt: 0.3
                        }}>
                        {comment?.replyCount === 0 ? '' : `+${comment?.replyCount} reply`}
                      </Item>
                    </Item>
                    <Item sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                      <Container>
                        {comment.files &&
                          comment.files.map((item) => {
                            return (
                              <Item
                                sx={{
                                  width: '220px',
                                  height: 'auto',
                                  border: '1px solid lightGrey',
                                  borderRadius: '10px',
                                  ml: 5.5,
                                  p: 1,
                                  backgroundColor: '#FFFFFF',
                                  fontSize: '11px',
                                  fontWeight: '600',
                                  lineHeight: '15px',
                                  display: 'flex',
                                  cursor: 'pointer',
                                  marginBottom: '10px'
                                }}
                                gap={1}>
                                <Container gap={1} alignItems={'center'}>
                                  <AttachFileIcon style={{ fontSize: '20px' }} />
                                  <Item>
                                    <Typography
                                      onClick={() => handleFileDownload(item.url)}
                                      color={theme.palette.primary.main}
                                      variant={'subtitle2'}
                                      noWrap={true}
                                      sx={{ fontWeight: 'bold' }}>
                                      {item.name}
                                    </Typography>
                                    <Item>{bytesTosize(item.size)}</Item>
                                  </Item>
                                  {isEdit && (
                                    <Item onClick={() => handleFileDelete(comment, item)}>
                                      <CloseIcon />
                                    </Item>
                                  )}
                                </Container>
                              </Item>
                            );
                          })}
                      </Container>
                    </Item>
                  </Item>
                );
              })}
            </Item>
          );
        })}

        <div ref={chatEndRef}></div>
      </Item>

      <ReplySection
        selectedFiles={selectedFiles}
        handleFileRemove={handleFileRemove}
        chatData={chatData}
        handleAddBySearch={handleAddBySearch}
        handleFileInput={handleFileInput}
        hiddenFileInput={hiddenFileInput}
        handleFile={handleFile}
        handleStateChange={handleStateChange}
        inputText={inputText}
        isEdit={isEdit}
        isLoading={isLoading}
      />
    </Container>
  );
}

export default withStyles(styles)(ChatModal);
