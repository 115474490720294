import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import ActiveBroadcastTable from '../../components/organism/Table/BroadcastAllTables/ActiveBroadcastTable';
import moment from 'moment';
import { useStateValue } from '../../utils/store';
import { FilterButton } from '../../utils/ButtonDiff';
import theme from '../../utils/theme';
import Filter from '../../utils/filters';
// import dateDiffInDays from '../../utils/dateDiffernce';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { getDateTime } from '../../utils/moment';
import downloadDataToExcelFile from '../../utils/downloadDataToExcelFile';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import ActiveBroadcastFilter from './ActiveBroadcastFilter';
import { ApiDates } from '../../utils/ApiDates';

const { withStyles, Grid, TextField, InputAdornment } = Components;

const { SearchIcon, DownloadForOfflineIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const ActiveBroadcast = ({ classes }) => {
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [, dispatch] = useStateValue();
  const [searchText, setSearchText] = useState('');
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [totalBroadcast, setTotalBroadcast] = useState(0);
  const [filtersCategory, setFilterCategory] = useState([]);
  const [filterData, setFilterData] = useState([
    {
      label: 'WhatsApp',
      name: 'whatsApp',
      value: false,
      type: 'Sent via'
    },
    {
      label: 'In App',
      name: 'app',
      value: false,
      type: 'Sent via'
    },

    {
      label: 'Ms Team',
      name: 'msTeam',
      value: false,
      type: 'Sent via'
    },
    {
      label: 'Recurring',
      name: 'recurring',
      value: false,
      type: 'Status'
    },
    {
      label: 'One Time',
      name: 'oneTime',
      value: false,
      type: 'Status'
    }
  ]);

  useEffect(() => {
    API_GET(`broadCastMessage/getAllBroadcastSender`)
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return {
            label: ConvertToUpperCase(item?.name),
            name: item?._id,
            value: false,
            type: 'Sent By'
          };
        });

        setFilterData((prevData) => [...prevData, ...resultData]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  const handleClose = () => {
    setIsOpenFilterModal(false);
  };

  const handleOpenFilterModal = () => {
    setIsOpenFilterModal(!isOpenFilterModal);
  };

  const handleTotalBroadcast = (value) => {
    setTotalBroadcast(value);
  };

  const handleClearAll = () => {
    const result = filterData?.map((data) => {
      return { ...data, value: false };
    });
    setFilterData(result);
    setFilterCategory([]);
  };

  const handleRemove = (item, status) => {
    if (status === 'filter') {
      const result = filtersCategory?.filter((data) => {
        return data !== item;
      });
      setFilterCategory(result);
    }
  };

  const handleFilterCategory = (value) => {
    setFilterCategory(value);
  };

  const handleChange = (item) => {
    const result = filterData?.map((subItem) => {
      return subItem.name === item.name ? { ...subItem, value: !subItem?.value } : { ...subItem };
    });
    setFilterData(result);
  };

  // const onFromDateChange = (currentValue) => {
  //   const selectDate = new Date(currentValue.target.value);
  //   const today = new Date();
  //   if (selectDate > today) {
  //     dispatch({
  //       type: 'ALERT_OPEN',
  //       severity: 'error',
  //       isOpen: true,
  //       label: 'Selected Date Greater Than Today'
  //     });
  //     setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
  //   } else {
  //     const days = dateDiffInDays(selectedToDate, selectDate);
  //     if (days > 30) {
  //       dispatch({
  //         type: 'ALERT_OPEN',
  //         severity: 'error',
  //         isOpen: true,
  //         label: 'Date Difference Should not be greater than 30 Days'
  //       });
  //       setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
  //     } else {
  //       setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
  //     }
  //   }
  // };

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const handleExport = () => {
    const resutDate = ApiDates(selectedFromDate, selectedToDate);
    API_GET(
      `broadCastMessage/getBroadCasts?limit=${totalBroadcast}&skip=0&searchText=${searchText}&isDownload=true&${resutDate}`
    )
      .then((res) => {
        const downloadData = [];
        res?.broadCastMessages?.map((item) => {
          const obj = {
            Header: item?.header,
            Message: item?.message,
            'Sent By': item?.sentBy,
            Status: 'isRecurring' in item ? 'Recurring' : 'One Time',
            Medium: item?.medium?.join(', ')
          };
          if (item?.pieChart?.length === 0) {
            downloadData.push(obj);
          }
          return item?.pieChart?.map((data) => {
            const result = {
              ...obj,

              'Date and Time': getDateTime(data?.date),
              CTA1: Object.keys(data?.ctaInfo)?.[0],
              'Response of CTA 1': data?.ctaInfo[Object.keys(data?.ctaInfo)?.[0]]?.number || 0,
              CTA2: Object.keys(data?.ctaInfo)?.[1],
              'Response of CTA 2': data?.ctaInfo[Object.keys(data?.ctaInfo)?.[1]]?.number || 0,
              CTA3: Object.keys(data?.ctaInfo)?.[2],
              'Response of CTA 3': data?.ctaInfo[Object.keys(data?.ctaInfo)?.[2]]?.number || 0,
              'No Response': data?.noResCount
            };
            downloadData.push(result);
            return null;
          });
        });
        if (res?.broadCastMessages?.length === 0 || downloadData?.length === 0) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'No data for download in perticular date range'
          });
        } else {
          downloadDataToExcelFile(downloadData?.length > 0 && downloadData, `Broadcast details`);
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <Container>
      <Item
        md={12}
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
        <Item md={4} xs={12}>
          <TextField
            placeholder="Search by Header and Name"
            size="small"
            variant="outlined"
            fullWidth
            sx={theme.customTextFiled}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Item>
        <Item
          sx={{
            display: 'inline-flex',
            ml: 'auto',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}>
          <DownloadForOfflineIcon
            onClick={handleExport}
            className={classes.icon}
            fontSize="large"
            style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
          />
          <Item sx={{ position: 'relative' }}>
            <FilterButton handleOpenFilterModal={handleOpenFilterModal} />
            {isOpenFilterModal && (
              <ActiveBroadcastFilter
                filterData={filterData}
                handleClose={handleClose}
                handleChange={handleChange}
                handleFilterCategory={handleFilterCategory}
              />
            )}
          </Item>

          <Item>
            <form noValidate>
              <TextField
                id="datetime-local"
                label="From"
                type="date"
                size="small"
                value={selectedFromDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onFromDateChange(e);
                }}
              />
            </form>
          </Item>
          <Item>
            <form noValidate>
              <TextField
                id="date"
                label="To"
                type="date"
                size="small"
                value={selectedToDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onToDateChange(e);
                }}
              />
            </form>
          </Item>
        </Item>
      </Item>
      {filtersCategory?.length > 0 && (
        <Filter
          filtersCategory={filtersCategory}
          handleClearAll={handleClearAll}
          handleRemove={handleRemove}
        />
      )}
      <Item md={12} xs={12} style={{ width: '500px' }} mt={2}>
        <ActiveBroadcastTable
          searchText={searchText}
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
          filtersCategory={filtersCategory}
          handleExport={handleExport}
          handleTotalBroadcast={handleTotalBroadcast}
        />
      </Item>
    </Container>
  );
};

export default withStyles(styles)(ActiveBroadcast);
