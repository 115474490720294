/**
 * Icons
 */
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import ReportIcon from '@mui/icons-material/Report';
import RoomIcon from '@mui/icons-material/Room';
import Phone from '@mui/icons-material/Phone';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TextsmsIcon from '@mui/icons-material/Textsms';
import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BusinessIcon from '@mui/icons-material/Business';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CallIcon from '@mui/icons-material/Call';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import ReplyIcon from '@mui/icons-material/Reply';
import BookIcon from '@mui/icons-material/Book';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import WatchIcon from '@mui/icons-material/WatchLater';
import ShareIcon from '@mui/icons-material/IosShare';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import SortIcon from '@mui/icons-material/SwapVert';
import UploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InfoIcon from '@mui/icons-material/Info';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DirectionsIcon from '@mui/icons-material/Directions';
/**
 * Core
 */

import Dialog from '@mui/material/Dialog';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import MuiAlert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popper from '@mui/material/Popper';
import ButtonGroup from '@mui/material/ButtonGroup';
import InputAdornment from '@mui/material/InputAdornment';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CallRoundedIcon from '@mui/icons-material/CallRounded';

/**
 * Lab
 */

/**
 * styles
 */
import { withStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const Icons = {
  /**
   * Icons
   */
  MenuIcon,
  InboxIcon,
  MailIcon,
  AddIcon,
  DirectionsCarFilledIcon,
  HomeIcon,
  LinkIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  SearchIcon,
  ReportIcon,
  AddAlertIcon,
  EmergencyShareIcon,
  AccountCircleIcon,
  BackupTableIcon,
  SummarizeIcon,
  BusinessIcon,
  RoomIcon,
  Phone,
  AccessTimeIcon,
  AddCircleOutlineOutlinedIcon,
  EmailIcon,
  WhatsAppIcon,
  TextsmsIcon,
  StarIcon,
  CallIcon,
  CallSplitIcon,
  CloseIcon,
  LocationOnOutlinedIcon,
  LanguageOutlinedIcon,
  ArrowBackIcon,
  CancelRoundedIcon,
  GroupAddOutlinedIcon,
  GroupAddIcon,
  EditIcon,
  ArrowBackIosIcon,
  DeleteIcon,
  CancelIcon,
  ClearIcon,
  MoreVertIcon,
  SendIcon,
  ReplyIcon,
  BookIcon,
  LogoutIcon,
  SettingsIcon,
  NotificationsIcon,
  ExpandMoreIcon,
  FileDownloadIcon,
  AddCircleIcon,
  ApartmentIcon,
  ArrowForwardIosIcon,
  PersonIcon,
  AttachFileIcon,
  WatchIcon,
  ShareIcon,
  DownloadForOfflineIcon,
  SortIcon,
  UploadIcon,
  CheckCircleOutlineIcon,
  AssessmentIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  MailOutlineIcon,
  InfoIcon,
  DirectionsCarIcon,
  ContactEmergencyIcon,
  AccountBoxIcon,
  RoomRoundedIcon,
  CallRoundedIcon,
  AccessTimeFilledIcon,
  InfoOutlinedIcon,
  DirectionsIcon
};

export const Components = {
  /**
   * Components
   */
  Stepper,
  Step,
  StepLabel,
  createTheme,
  ThemeProvider,
  CssBaseline,
  withStyles,
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
  AppBar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  InputLabel,
  FormControl,
  Select,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
  Snackbar,
  RadioGroup,
  Radio,
  MuiAlert,
  Autocomplete,
  CircularProgress,
  Dialog,
  useMediaQuery,
  Popper,
  ButtonGroup,
  InputAdornment
};
