const styles = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(1, 2),
      margin: theme.spacing(0, 0, 2, 0)
    },
    mainSubConatiner: {
      padding: theme.spacing(0, 2)
    },
    subContainer: {
      padding: theme.spacing(1, 2)
    },
    inputContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      borderRadius: '12px',
      padding: theme.spacing(5, 5, 0, 5),
      minHeight: '60vh'
    },
    inputText: {
      color: theme.typography.tableHeading.color
    },
    inputText1: {
      color: '#818181',
      marginTop: theme.spacing(0.5)
    },
    errorText: {
      color: 'red'
    },
    cardsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: '25px'
    },
    colorPointer: {
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      cursor: 'pointer'
    },
    themeContainer: {
      height: '180px',
      border: '1px solid #DCDCDC',
      borderRadius: '10px'
    },
    themeImg: {
      height: '130px',
      objectFit: 'cover',
      width: '228px',
      borderTopLeftRadius: '9px',
      borderTopRightRadius: '9px',
      marginTop: '0.5px'
    },
    carousalContainer: {
      display: 'flex',
      maxWidth: '85vw',
      overflow: 'hidden',
      alignItems: 'center'
    },
    imageContain: {
      height: '120px',
      width: '120px',
      borderRadius: '25px',
      border: '2px dotted grey',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      cursor: 'pointer'
    },
    smallContainer: {
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '8px',
      alignItems: 'center',
      color: theme.palette.primary.main,
      backgroundColor: theme.headingBgColor,
      minWidth: '100px',
      padding: theme.spacing(0.5),
      borderRadius: '3px'
    },
    imgProfile: {
      objectFit: 'cover',
      height: '120px',
      width: '120px',
      borderRadius: '25px',
      border: `4px solid ${theme.headingBgColor}`
    },
    starIcon: {
      color: '#FF6760'
    }
  };
};

export default styles;
