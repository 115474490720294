import React, { useState } from 'react';
import { Components } from '../../../utils/material-ui';
import theme from '../../../utils/theme';
import moment from 'moment';
import { useStateValue } from '../../../utils/store';
import nominatedcss from './nominatedcss';
import CountsCard from './CountsCard';
import YetToTravelledTable from '../../../components/organism/Table/TravelManagementTables/YetToTravelledTable';
import TravelledTable from '../../../components/organism/Table/TravelManagementTables/TravelledTable';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';

const { withStyles, Grid, Paper, TextField, MenuItem, Select, FormControl, InputLabel, Box } =
  Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const PackagetypeArray = [
  'All',
  'Independent Tours',
  'Escorted Tours',
  'Hosted Tours',
  'Incentive Tours',
  'Freedom Tours'
];
const sortBy = [
  { name: 'Name', value: 'name' },
  { name: 'Country', value: 'country' },
  // { name: 'Place', value: 'place' },
  { name: 'Pending', value: 'pendingDocsCount' },
  { name: 'GroupType', value: 'packageType' },
  { name: 'To be Expire', value: 'toBeExpiredDocsCount' },
  { name: 'Expired', value: 'expiredDocsCount' }
];

const Pakages = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [sort, setSort] = useState('name');
  const [packageType, setPackageType] = useState('All');
  const [selectedFromDate, setSelectedFromDate] = useState('');
  const [selectedToDate, setSelectedToDate] = useState('');

  const cards = [
    { label: 'Yet to travel ', value: 'yetToTravel', table: YetToTravelledTable },
    { label: 'Travelled', value: 'travelled', table: TravelledTable }
  ];

  const [selectedCard, setSelectedCard] = useState(cards[0]);

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  return (
    <Container>
      <Item md={12} mt={2}>
        <Paper sx={{ ...theme.card, borderRadius: 'none' }}>
          <Item className={classes.filterContainer} md={12}>
            <Item>
              <form noValidate>
                <TextField
                  id="datetime-local"
                  label="From"
                  type="date"
                  size="small"
                  sx={{ width: '200px' }}
                  value={selectedFromDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onFromDateChange(e);
                  }}
                />
              </form>
            </Item>
            <Item>
              <form noValidate>
                <TextField
                  id="date"
                  label="To"
                  type="date"
                  size="small"
                  sx={{ width: '200px' }}
                  value={selectedToDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onToDateChange(e);
                  }}
                />
              </form>
            </Item>

            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Group Type </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={packageType}
                  sx={{ width: '200px' }}
                  label=" Group Type "
                  onChange={(e) => setPackageType(e.target.value)}>
                  {PackagetypeArray?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {ConvertToUpperCase(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Sort By </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={sort}
                  sx={{ width: '200px' }}
                  label="Sort By"
                  onChange={(e) => setSort(e.target.value)}>
                  {sortBy?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {ConvertToUpperCase(item?.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
          </Item>
        </Paper>
      </Item>
      <Box sx={{ flexGrow: 1 }}>
        <Container justify="space-between" alignItems="center" spacing={2} mt={0.1}>
          {cards.map((item) => (
            <Item key={item.value}>
              <CountsCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
            </Item>
          ))}
        </Container>
      </Box>
      <Item xs={12} mt={2} sx={{ width: '500px' }}>
        <selectedCard.table
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
          packageType={packageType}
          sort={sort}></selectedCard.table>
      </Item>
    </Container>
  );
};

export default withStyles(nominatedcss)(Pakages);
