const styles = (theme) => {
  return {
    title: {
      backgroundColor: theme.palette.primary.tableHeadingColor,
      fontFamily: theme.palette.font.fontFamily,
      color: 'white',
      padding: '10px',
      display: 'flex',
      justifyContent: 'space-between'
    },

    container: {
      display: 'flex',
      justifyContent: 'space-evenly'
    },
    tableContainer: {
      padding: theme.spacing(2),
      maxHeight: '70vh',
      maxWidth: '90vw',
      overflow: 'auto'
    },
    headingtext: {
      fontSize: '13px',
      fontWeight: '600'
    },
    tableHead: {
      width: '400px',
      padding: '10px',
      color: theme.palette.primary.tableHeadingColor
    },
    imgContainer: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    },
    equalWidth: {
      padding: theme.spacing(1.5),
      textAlign: 'center'
    }
  };
};

export default styles;
