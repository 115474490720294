const styles = (theme) => {
  return {
    cardHeading: {
      borderRadius: '8px 8px 0 0',
      color: theme.palette.primary.tableHeadingColor
    },
    popupContainer: {
      padding: theme.spacing(1.5, 2)
      // border: '1px solid red'
    },
    starIcon: {
      color: '#FF6760'
    }
  };
};

export default styles;
