const head = [
  {
    id: 'fullName',
    label: 'Doctor Name',
    minWidth: 130,
    width: 130,
    maxWidth: 130,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'mobileNumber',
    label: 'Phone No',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'category',
    label: 'Category',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'hospitalName',
    label: 'Hospital Name',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Activate',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
