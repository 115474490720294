import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../utils/store';
import { Components } from '../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import { getDateTime } from '../../../../utils/moment';
import ActualIncidentNewsModal from '../../Modal/ActualIncidentNewsModal';
import Dialog from '../../../atom/Dialog';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import tableColor from '../../../../utils/tableColor';
import theme from '../../../../utils/theme';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Typography,
  TableCell,
  Button
} = Components;

const Item = (props) => <Grid item {...props} />;

const AlertSummaryTable = ({
  classes,
  selectedFilterCategory,
  selectedMainCategory,
  selectedSubCategory,
  selectedFromDate,
  selectedToDate,
  getFilterCategory,
  getMainCategory,
  search,
  tab
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRejectedNews, setTotalRejectedNews] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [isOpenDataModal, setIsOpenMoreModal] = useState(false);
  const [openData, setOpenMore] = useState({});
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // to reset page on search
  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllAlertDetail();
  }, [
    dispatch,
    page,
    rowsPerPage,
    selectedFilterCategory,
    selectedMainCategory,
    selectedSubCategory,

    selectedToDate,
    selectedFromDate
  ]);

  const getAllAlertDetail = () => {
    const resultFilterCat = selectedFilterCategory === 'All' ? '' : selectedFilterCategory;
    const resultMainCat = selectedMainCategory === 'All' ? '' : selectedMainCategory;
    const resultSubCat = selectedSubCategory === undefined ? '' : selectedSubCategory;
    const resultSearch =
      search !== undefined && search !== ''
        ? `searchTag=${search}`
        : `fromDate=${selectedFromDate}&toDate=${selectedToDate}`;

    API_GET(
      `alert/getNewsByCond?alertCategory=${
        resultFilterCat === undefined ? '' : resultFilterCat
      }&categoryName=${resultMainCat}&subCategory=${resultSubCat}&limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&${resultSearch}&tab=${tab}&fromNotify=false`
    )
      .then((res) => {
        setRows(res?.PickedNewsFeed);
        setTotalRejectedNews(res?.countNewsFeed);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const handleClose = () => {
    setIsOpenMoreModal(false);
  };

  const handleExtraData = (row) => {
    setIsOpenMoreModal(true);
    setOpenMore(row);
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Typography sx={theme.typography.tableHeading}>
        {`${ConvertToUpperCase(selectedFilterCategory) || 'All'} ${
          selectedMainCategory === 'All' ? '' : ConvertToUpperCase(selectedMainCategory)
        }  Incidents`}
      </Typography>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(index) }}>
                    {columns &&
                      columns.map((column, index) => {
                        const value = row[column.id] ?? '--';

                        if (column.id === 'eventDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.eventDate) || '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'title') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item
                                sx={{
                                  display: 'flex',
                                  gap: '10px',
                                  cursor: 'pointer',
                                  textDecoration: 'underline'
                                }}
                                onClick={() => handleExtraData(row)}>
                                <Item>{row?.title}</Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'createdAt') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.createdAt) || '- '}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'location') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {[
                                ConvertToUpperCase(row?.locationFromDror),
                                ConvertToUpperCase(row?.cityFromDror),
                                ConvertToUpperCase(row?.stateFromDror)
                              ]
                                .filter((item) => item !== '')
                                .join(', ')}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'category') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item sx={{ display: 'flex', gap: '10px' }}>
                                <Item>
                                  <Button
                                    style={{
                                      backgroundColor: row?.backgroundColor,
                                      color: row?.color,
                                      ...theme.button.tableSeverityButton
                                    }}>
                                    {row?.alertCategory}
                                  </Button>
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalRejectedNews && totalRejectedNews ? totalRejectedNews : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        isOpen={isOpenDataModal}
        children={<ActualIncidentNewsModal handleClose={handleClose} data={openData} />}></Dialog>
    </Card>
  );
};

export default withStyles(styles)(AlertSummaryTable);
