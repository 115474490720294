const OnlineIcon = ({ offlineSos }) => {
  console.log(offlineSos);
  return (
    <div
      style={{
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        backgroundColor: offlineSos ? '#E51111' : '#098F4C'
      }}></div>
  );
};

export default OnlineIcon;
