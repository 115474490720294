import React, { useEffect, useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const { withStyles, Grid, Typography, FormControl, InputLabel, Select, MenuItem } = Components;
const Item = (props) => <Grid item {...props} />;

const categoryArray = ['all', 'social', 'health', 'economy', 'pandamic', 'political', 'weather'];
const BarChart1 = ({ classes, selectedFromDate, selectedToDate }) => {
  const [, dispatch] = useStateValue();
  const [category, setCategory] = useState('all');
  const [data, setData] = useState([]);

  useEffect(() => {
    API_GET(
      `alert/getOfficeWiseAlertGraph?impactCategory=${category}&fromDate=${selectedFromDate}&toDate=${selectedToDate}`
    )
      .then((res) => {
        const result = res?.map((item) => {
          return {
            ...item,
            _id: ConvertToUpperCase(item?._id)
          };
        });
        setData(result);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, category, selectedFromDate, selectedToDate]);

  return (
    <Item p={1}>
      <Item sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ ...theme.typography.tableHeading, p: 0.5 }}>
          Location wise alert distribution
        </Typography>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              size="small"
              value={category}
              sx={{ fontSize: '14px', width: '150px' }}
              label="Category"
              onChange={(e) => setCategory(e.target.value)}>
              {categoryArray?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {ConvertToUpperCase(item)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
      </Item>
      <Item md={12} xs={12} mt={1}>
        <Item sx={{ height: '35vh' }} md={12} xs={12}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width="100%"
              height="100%"
              data={data}
              margin={{
                top: 15,
                right: 10,
                left: 0,
                bottom: 50
              }}>
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="_id" tick={{ angle: -45, dy: 18, dx: -10 }} />
              <YAxis />
              <Tooltip cursor={false} />
              <Bar dataKey="emergency" stackId="a" barSize={20} fill="#E31B10" />
              <Bar dataKey="critical" stackId="a" barSize={20} fill="#F48811" />
              <Bar dataKey="warning" stackId="a" barSize={20} fill="#EDAF01" />
              <Bar dataKey="notices" stackId="a" barSize={20} fill="#5B80E0" />
              <Bar dataKey="info" stackId="a" barSize={20} fill="#4DAD57" />
            </BarChart>
          </ResponsiveContainer>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(BarChart1);
