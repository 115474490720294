import React, { useState, useEffect } from 'react';
import { Components } from '../../utils/material-ui';
import theme from '../../utils/theme';
import styles from './styles';
import { API_GET, API_HANDLE_ERROR, API_PUT } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import PackageDetails from './PackageDetails';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import PackageDetailsForSmallScrren from './PackageDetailsForSmallScrren';

const {
  withStyles,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  useMediaQuery
} = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const Packges = ({
  classes,
  formType,
  allPackageIds,
  resData,
  handleDecleartion,
  declerationData,
  handleIsOpenDecleartionModal,
  nominationData
}) => {
  const [packageId, setPackageId] = useState('');
  const [, dispatch] = useStateValue();
  const [familyDetails, setFamilyDetails] = useState([]);
  const [allDepedents, setAlldependents] = useState([]);
  const [data, setData] = useState({});
  const [maxDependent, setMaxDependent] = useState(0);
  const scrrenSizeSmall = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    setData(resData);
  }, [resData]);

  useEffect(() => {
    allPackageIds?.map((item) => {
      if (item?._id === nominationData?._id) {
        setPackageId(item);
        setMaxDependent(item?.numberOfdependents);
      }
      return null;
    });
  }, []);

  const handleSetPackageId = (value) => {
    setPackageId(value);
    setMaxDependent(value?.numberOfdependents);
  };

  // set all family members

  useEffect(() => {
    const resultId = data?.role === 'agent' ? data?.agentId : data?.employeeId || data?.uniqueId;
    resultId &&
      API_GET(`travelPackage/getUserInfo/${resultId}?role=${data?.role}`)
        .then((res) => {
          const result = res?.dataV2?.map((item) => {
            return { ...item, value: true };
          });
          setAlldependents(result);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  }, [data]);

  useEffect(() => {
    packageId?._id &&
      API_GET(`travelPackage/getGoingWith/${packageId?._id}`)
        .then((res) => {
          setFamilyDetails(res?.data);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
          setFamilyDetails([]);
        });
  }, [packageId]);

  const handleStateChange = (value) => {
    if (packageId) {
      let flag = false;
      for (let i = 0; i < familyDetails.length; i++) {
        if (familyDetails[i]?.userId === value?.userId) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'This family member already added'
          });
          flag = true;
          break;
        }
      }
      if (!flag) {
        if (value) {
          if (familyDetails?.length < maxDependent) {
            setFamilyDetails([...familyDetails, value]);
          } else {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: `Maximum dependent limit is ${maxDependent}`
            });
          }
        }
      }
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please select Group'
      });
    }
  };

  const handleDelete = (userId) => {
    const result = familyDetails?.filter((id) => id?.userId !== userId);
    setFamilyDetails(result);
  };

  const handleSubmit = () => {
    const resultFamily = familyDetails?.map((item) => {
      return {
        userId: item?.userId,
        status: true
      };
    });

    const Postdata = {
      packageId: packageId?.packageId,
      id: data?.agentId || data?.employeeId || data?.uniqueId,
      goingWith: resultFamily,
      role: data?.role
    };
    if (packageId?.packageId) {
      API_PUT(`travelPackage/updateNominatedEmployee/${packageId?._id}`, Postdata)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res?.message ?? 'Success'
          });
          if (packageId?.formData?.formStatus !== 'submitted') {
            handleIsOpenDecleartionModal(packageId?.formData);
          }
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Select Group Id'
      });
    }
  };

  const handleSelfDecleartion = (data) => {
    handleDecleartion(data);
  };

  return (
    <Container direction="column" wrap="nowrap" className={classes.mainContainer}>
      <Typography sx={{ fontSize: '1.3rem', fontWeight: '600' }}>{formType}</Typography>
      <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>
        Please select your family members against the Groups by selecting the Group Id.
      </Typography>
      <Item elevation={2} md={12} xs={12} lg={12} className={classes.groupFilterContainer}>
        <Item md={4.8} sx={{ mt: 2 }} xs={!scrrenSizeSmall ? 5.8 : 12} lg={3.8}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">Group Id</InputLabel>
            <Select
              name="region"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={packageId}
              onChange={(e) => handleSetPackageId(e.target.value)}
              label="Group Id">
              {allPackageIds?.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    {item?.packageId}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
        <Item sx={{ mt: 2 }} md={1.8} xs={!scrrenSizeSmall ? 5.8 : 12} lg={2}>
          <TextField
            label="Dependent"
            variant="outlined"
            type="number"
            fullWidth
            value={maxDependent}
            size="small"
          />
        </Item>
        <Item sx={{ mt: 2 }} md={4.8} xs={12} lg={3.8}>
          <Autocomplete
            id="combo-box-demo"
            onChange={(e, value) => {
              if (value === null) {
                handleStateChange('');
              }
              handleStateChange(value);
            }}
            options={allDepedents}
            color="#134CDF"
            size="small"
            getOptionLabel={(option) => ConvertToUpperCase(option?.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="Country"
                label="Family Member"
                variant="outlined"
                type="text"
              />
            )}
          />
        </Item>
      </Item>
      <Item mt={2} sx={{ maxHeight: '65vh', overflow: 'auto' }}>
        {allPackageIds?.map((item, index) => {
          return packageId?._id === item?._id ? (
            <React.Fragment>
              <Item sx={{ display: 'flex', alignItem: 'center' }} mt={1.5} mb={0.5}>
                <Typography
                  sx={{ fontSize: !scrrenSizeSmall ? '1rem' : '1.2rem', fontWeight: '600' }}>
                  {`Package Id - ${item?.packageId}`}
                </Typography>

                <Item
                  sx={{ display: 'inline-flex', ml: 'auto' }}
                  className={classes.declerationLink}
                  onClick={() => handleSelfDecleartion(item)}>
                  {item?.formData?.formStatus === 'submitted' ? 'View declaration form' : ''}
                </Item>
              </Item>

              {!scrrenSizeSmall ? (
                <PackageDetailsForSmallScrren
                  data={item}
                  key={index}
                  allPackageIds={allPackageIds}
                  familyDetails={familyDetails}
                  handleDelete={handleDelete}
                />
              ) : (
                <PackageDetails
                  data={item}
                  key={index}
                  allPackageIds={allPackageIds}
                  familyDetails={familyDetails}
                  handleDelete={handleDelete}
                />
              )}
            </React.Fragment>
          ) : (
            ''
          );
        })}
      </Item>
      <Item sx={{ float: 'right', m: '30px 0px 10px 0px' }}>
        <Button
          onClick={handleSubmit}
          style={{
            ...theme.button.submit,
            backgroundColor: theme.palette.primary.main
          }}>
          {'Submit'}
        </Button>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Packges);
