import React, { useState } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../../../utils/store';
import { API_HANDLE_ERROR, API_PATCH } from '../../../../utils/api';
import theme from '../../../../utils/theme';
import { Typography } from '@mui/material';

const { withStyles, Grid, Button, TextField, FormGroup, FormControlLabel, Checkbox } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function RejectApproval({ classes, handleChangeStatus, data, getallAction }) {
  const [, dispatch] = useStateValue();

  const [rejectData, setRejectData] = useState({
    bugetApproved: '',
    isSameEstimate: false
  });

  const handleStateChange = (key, value) => {
    setRejectData((prevState) => {
      if (key === 'isSameEstimate') {
        if (!value) {
          prevState.bugetApproved = '';
        } else {
          prevState.bugetApproved = Number(data?.budgetEstimate);
        }
      }
      prevState[`${key}`] = value;

      return { ...prevState };
    });
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    const resultBudget = !rejectData.bugetApproved ? 0 : rejectData.bugetApproved;
    data?.incidentForwardId &&
      API_PATCH(
        `budgetApproval/approveBudget?incidentForwardHistoryId=${data?.incidentForwardId}`,
        {
          action: 'accepted',
          budgetApproved: resultBudget
        }
      )
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          getallAction();
          handleChangeStatus();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: '380px' }}>
      <form onSubmit={onHandleConfirm}>
        <Item sx={{ p: 1 }}>
          <Item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkboxText}
                    checked={rejectData?.isSameEstimate}
                    onChange={(e) => handleStateChange('isSameEstimate', e.target.checked)}
                    color="info"
                    size="medium"
                  />
                }
                label={
                  <span
                    style={{
                      fontSize: '17px'
                    }}>{`Same as Estimated amount (₹${data?.budgetEstimate})`}</span>
                }
              />
            </FormGroup>
          </Item>
          <Item sx={{ textAlign: 'center', fontSize: '19px', mt: 1 }}>Or</Item>
          <Typography sx={{ fontSize: '14px' }}>Budget Estimation(₹)</Typography>
          {rejectData?.isSameEstimate === true ? (
            <Item>
              <TextField
                variant="outlined"
                value={rejectData?.bugetApproved}
                type="number"
                fullWidth
              />
            </Item>
          ) : (
            <Item>
              <TextField
                variant="outlined"
                className={classes.formControl}
                value={rejectData?.bugetApproved}
                onChange={(e) => handleStateChange('bugetApproved', e.target.value)}
                type="number"
                fullWidth
              />
            </Item>
          )}
        </Item>
        <Item sx={{ display: 'flex', gap: '10px', p: 1 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button
              type="submit"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              {`Ok`}
            </Button>
          </Item>
          <Item>
            <Button style={{ ...theme.button.cancel }} onClick={() => handleChangeStatus()}>
              {`Cancel`}
            </Button>
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(RejectApproval);
