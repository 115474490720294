const styles = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(2),
      justifyContent: 'space-between'
    },
    filterContainer: {
      width: '100%',
      height: 'auto'
    },

    tableContainer: {
      width: '100%',
      height: 'auto',
      paddingBottom: theme.spacing(1),
      marginTop: theme.spacing(2)
    },
    smallContainer: {
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '8px',
      alignItems: 'center',
      color: theme.palette.primary.main,
      backgroundColor: theme.headingBgColor,
      minWidth: '100px',
      padding: theme.spacing(0.5),
      borderRadius: '3px'
    }
  };
};

export default styles;
