const head = [
  {
    id: 'assignedByName',
    label: 'Assigned By',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'assignedToName',
    label: 'Assigned To',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'officeName',
    label: 'Office',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'forwardedOn',
    label: 'Forwarded On',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'proposedDate',
    label: 'Proposed Date',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'planDays',
    label: 'Plan Days',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'completionDate',
    label: 'Completion Date',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'totalDays',
    label: 'Total Days',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'extraDays',
    label: 'Extra Days',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'status',
    label: 'Local Status',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'right'
  }
];

export default head;
