import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './marker';
import mapStyle from './mapStyle.json';

export default function Map({ data }) {
  return (
    <GoogleMapReact
      center={[28.5651, 77.3723]}
      zoom={2}
      options={{ styles: mapStyle }} // pass your custom map style as options
      featureType="road.arterial"
      bootstrapURLKeys={{ key: 'AIzaSyBVpO7jJw7WOYC29PMXNpeYoKhdmK7dBPc' }}>
      {data?.map((item, index) => {
        return (
          <Marker
            lat={item?.location?.coordinates?.[1]}
            lng={item?.location?.coordinates?.[0]}
            key={index}
            showData={item}
          />
        );
      })}
    </GoogleMapReact>
  );
}
