import React from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import FamilyMembersDeatils from './FamilyMembersDeatils';
import EmergencyContacts from './EmergencyContact';
import { Typography } from '@mui/material';
import UserDeatils from './UserDeatils';

const { withStyles, Grid } = Components;
const { CancelIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function EmployeeDetailsModal({ classes, handleClose, data }) {
  return (
    <Container direction="column" className={classes.newUser}>
      <Item sx={{ display: 'flex' }} md={12} xs={12}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <CancelIcon
            sx={{ cursor: 'pointer', color: theme.typography.tableHeading.headingColor }}
            onClick={() => handleClose()}
          />
        </Item>
      </Item>
      <Item md={12} xs={12}>
        <Typography sx={{ ...theme.typography.tableHeading, p: '5px 0px' }}>
          Employee Details
        </Typography>
        <UserDeatils data={data} />
      </Item>
      <Item md={12} xs={12} mt={3}>
        <Typography sx={{ ...theme.typography.tableHeading, p: '5px 0px' }}>
          Emergency contacts
        </Typography>
        <EmergencyContacts sosId={data?._id} />
      </Item>
      <Item md={12} xs={12} mt={3}>
        <Typography sx={{ ...theme.typography.tableHeading, p: '5px 0px' }}>
          Family member
        </Typography>
        <FamilyMembersDeatils data={data?.familyMembers} />
      </Item>
    </Container>
  );
}

export default withStyles(styles)(EmployeeDetailsModal);
