import React, { useEffect, useState } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';

const { withStyles, Grid } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const RiskCard = ({ classes, selectedFromDate, selectedToDate }) => {
  const [, dispatch] = useStateValue();
  const [revenueData, setRevenueData] = useState();

  useEffect(() => {
    API_GET(`reports/getRiskReportsOverview?fromDate=${selectedFromDate}&toDate=${selectedToDate}`)
      .then((res) => {
        setRevenueData(res?.riskOverview && res?.riskOverview[0]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate]);

  const cards = [
    {
      value: `₹${revenueData !== undefined ? revenueData['Total Rev Loss']?.toFixed(1) : 0}`,
      name: 'Total Revenue Loss',
      color:
        'linear-gradient(300.19deg, #E12B19 22.11%, #E12B19 50.43%, #FF6D5F 92.24%, #FFFFFF 191.35%)'
    },
    {
      value: `${revenueData?.humanTotal?.toFixed(1) || 0}%`,
      name: 'Total Human Loss',
      color: 'linear-gradient(283.57deg, #EDAF01 57.73%, #FFFFFF 121.63%, #F3C953 151.76%)'
    },
    {
      value: `${revenueData?.infraTotal?.toFixed(1) || 0}%`,
      name: 'Total Infra loss',
      color: 'linear-gradient(285.78deg, #3B9DF7 34.17%, #FFFFFF 211.65%)'
    },
    {
      value: `₹${revenueData !== undefined ? revenueData['Total Rev Earned']?.toFixed(1) : 0}`,
      name: 'Total Revenue Earned',
      color: 'linear-gradient(291.57deg, #1A9518 45.8%, #80D17F 94.54%, #FFFFFF 123.24%)'
    }
  ];
  return (
    <Container direction="column" className={classes.newUser}>
      <Item
        md={12}
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
        {cards?.map((item) => {
          return (
            <Item
              md={4}
              style={{
                background: item?.color,
                height: '90px',
                borderRadius: '10px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
              <Item sx={{ ...theme.typography.tableHeading, fontSize: '18px', color: '#FFFFFF' }}>
                {item?.value}
              </Item>
              <Item sx={{ fontSize: '15px', color: '#FFFFFF', mt: -1 }}>{item?.name}</Item>
            </Item>
          );
        })}
      </Item>
    </Container>
  );
};

export default withStyles(styles)(RiskCard);
