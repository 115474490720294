import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';

const { withStyles, Typography, Grid } = Components;
const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid container {...props} />;

function CountsCard(props) {
  const { item, onClick, selectedCard } = props;

  const selected = {
    fontWeight: '600',
    color: theme.typography.tableHeading.color,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(0.5, 2),
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  };

  const unselected = {
    color: theme.typography.tableHeading.unSelect,
    cursor: 'pointer',
    display: 'flex',
    padding: theme.spacing(0.5, 2),
    justifyContent: 'center',
    borderBottom: '1px solid 	#E8E8E8'
  };

  const selectImg = selectedCard.value === item.value ? `${item.value}Black` : `${item.value}`;

  return (
    <Container
      onClick={() => onClick(item)}
      sx={selectedCard.value === item.value ? selected : unselected}>
      <Item>
        <Item sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <img
            style={{ height: '17px', width: '17px' }}
            src={require(`../../Assets/broadcast/${selectImg}.svg`).default}
            alt="icon"
          />
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: selectedCard.value === item.value ? '600' : '500'
            }}>
            {item.label}
          </Typography>
        </Item>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(CountsCard);
