import React from 'react';
import { Components } from '../../../utils/material-ui';
import theme from '../../../utils/theme';
import styles from './styles';

const { withStyles, Grid, Paper, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

const Card = ({ classes, data, handleMouseHover, isMoueHover }) => {
  return (
    <Paper
      sx={{
        height: '92px',
        ...theme.card1,
        border: `1px solid ${data?.color}`,
        padding: theme.spacing(1, 1.5),
        position: 'relative'
      }}
      onMouseEnter={() => handleMouseHover(true, data?.name, data?.hoverData)}
      onMouseLeave={() => handleMouseHover(false)}>
      <Item className={classes.cardText} sx={{ height: '50px' }}>
        <Typography className={classes.heading}>{data?.name}</Typography>
        <img
          style={{ height: '45px', width: '45px' }}
          src={require(`../../../Assets/RiskIcons/${data?.id}.svg`).default}
          alt="icon"
        />
      </Item>
      <Item className={classes.cardText} sx={{ height: '10px' }}>
        <Typography variant="h6" sx={{ color: '#454545' }}>
          {data?.total}
        </Typography>
        {/* <Item className={classes.motionContainer}>
          <Typography className={classes.heading}>
            {data?.percentage < 0 ? `${data?.percentage}%` : `+${data?.percentage}%`}
          </Typography>
          {
            <img
              style={{ height: '15px', width: '15px' }}
              src={
                require(`../../../Assets/RiskIcons/${
                  data?.percentage < 0 ? 'decreaseIcon' : 'IncreaseIcon'
                }.svg`).default
              }
              alt="icon"
            />
          }
        </Item> */}
      </Item>
    </Paper>
  );
};

export default withStyles(styles)(Card);
