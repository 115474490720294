import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';

async function DownloadMultipleFolderAsZip(folders, folderName) {
  // Create a new JSZip instance
  const zip = new JSZip();

  // Add each folder and its files to the zip file
  const zipFilename = `${folderName}.zip`;

  const folderPromises = folders.map(async (folder) => {
    // Create a new folder in the zip file
    const zipFolder = zip.folder(folder.name);

    // Add each file to the folder

    try {
      const promises = folder.files.map(async (link) => {
        const file = await JSZipUtils.getBinaryContent(link.url);
        await zipFolder.file(`${link.name}`, file, { base64: true });
      });
      await Promise.all(promises);
    } catch (error) {
      console.log(error);
    }
  });
  await Promise.all(folderPromises);

  zip
    .generateAsync({ type: 'blob' })
    .then(function (content) {
      saveAs(content, zipFilename);
    })
    .catch((err) => {
      console.log(err);
    });
}

export default DownloadMultipleFolderAsZip;
