import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './marker';
import mapStyle from './mapStyle.json';

import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
export default function Map({ selectedFromDate, selectedToDate }) {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    API_GET(
      `alert/getNewsByCond?limit=1000&skip=0&fromDate=${selectedFromDate}&toDate=${selectedToDate}&fromNotify=false`
    )
      .then((res) => {
        setRows(res?.PickedNewsFeed);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedToDate, selectedFromDate]);

  return (
    <GoogleMapReact
      center={[28.5651, 77.3723]}
      zoom={10}
      options={{ styles: mapStyle }} // pass your custom map style as options
      featureType="road.arterial"
      bootstrapURLKeys={{ key: 'AIzaSyBVpO7jJw7WOYC29PMXNpeYoKhdmK7dBPc' }}>
      {rows?.map((item, index) => {
        return (
          <Marker
            lat={item?.latitude}
            lng={item?.longitude}
            name={`${item?.title.substring(0, 20)}....`}
            color={item?.color}
            key={index}
            category={item?.alertCategory}
          />
        );
      })}
    </GoogleMapReact>
  );
}
