const head = [
  {
    id: 'rMSerialNumber',
    label: 'Serial Number',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: 30,
    width: 30,
    maxWidth: 30,
    align: 'center'
  },
  {
    id: 'title',
    label: 'Incident',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  // {
  //   id: 'more',
  //   label: '',
  //   minWidth: 80,
  //   width: 80,
  //   maxWidth: 80,
  //   align: 'center'
  // },
  {
    id: 'cityFromDror',
    label: 'Location',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  // {
  //   id: 'alertActionStatus',
  //   label: 'Status',
  //   minWidth: 120,
  //   width: 120,
  //   maxWidth: 120,
  //   align: 'center'
  // },

  {
    id: 'publishDate',
    label: 'Pub. Date&Time',
    minWidth: 160,
    width: 160,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'eventDate',
    label: 'Event Date&Time',
    minWidth: 160,
    width: 160,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'center'
  }
];

export default head;
