import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
// import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_HANDLE_ERROR, API_POST, API_PUT } from '../../../../utils/api';
import moment from 'moment';
import dateDiffInDays from '../../../../utils/dateDiffernce';
// import CountryList from '../../../../utils/ListOfAllCountry';

const {
  withStyles,
  Grid,
  Button,
  Typography,
  TextField,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  ListItemIcon,
  Autocomplete
} = Components;

const { AddCircleIcon, CancelIcon } = Icons;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  variant: 'menu'
};

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

// const PackagetypeArray = ['Incentive Tours', 'Internal Meet', 'Others'];
const mealPlanArray = ['Veg', 'Non Veg', 'Jain'];

const mealTypeArray = ['Breakfast', 'Lunch', 'Dinner', 'Snacks'];

const modeOfTransportArray = ['Flights', 'Train', 'Cab', 'Bus'];

function AddNominatedAgent({ classes, handleClose, status, editdata }) {
  const [, dispatch] = useStateValue();
  const [countryList, setCountryList] = useState([]);
  const [destination, setDestination] = useState([]);
  const [allGroups, setAllGroups] = useState([]);

  const [sendData, setSendData] = useState({
    packageId: status === 'editPackage' ? editdata?.packageId : '',
    packageName: status === 'editPackage' ? editdata?.packageName : '',
    packageType: status === 'editPackage' ? editdata?.packagetypesInfo?.name : '',
    country: status === 'editPackage' ? editdata?.country : '',
    countryCode: status === 'editPackage' ? editdata?.countryCode : '',
    destination: status === 'editPackage' ? editdata?.destination : '',
    otherPackageType: '',
    startDate:
      status === 'editPackage'
        ? moment(editdata?.startDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),
    endDate:
      status === 'editPackage'
        ? moment(editdata?.endDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),
    registrationStartDate:
      status === 'editPackage'
        ? moment(editdata?.registrationStartDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),
    registrationEndDate:
      status === 'editPackage'
        ? moment(editdata?.registrationEndDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),
    pax: status === 'editPackage' ? editdata?.pax : ''
    // mealPlan: status === 'editPackage' ? editdata?.mealPlan : ''
  });
  console.log('sendData', sendData);

  const getAllGroups = () => {
    API_GET(`travelPackage/getAllPackageTypes`)
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return item?.name;
        });
        setAllGroups([...resultData, 'Others']);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const getAllCountry = () => {
    API_GET(`countryStateCity/getCountryStateCity`)
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return {
            name: item?.name,
            value: item?.isoCode
          };
        });
        setCountryList(resultData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const getDesitination = () => {
    API_GET(
      `countryStateCity/getCountryStateCity?getCitiesOfCountry=true&countryCode=${sendData?.countryCode}`
    )
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return item?.name;
        });
        setDestination(resultData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getAllCountry();
    getAllGroups();
  }, []);

  useEffect(() => {
    getDesitination();
  }, [sendData?.country]);

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const selectedEndDate = new Date(sendData?.endDate);
    const today = new Date();
    if (selectDate > selectedEndDate) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please enter valid date'
      });
      setSendData({ ...sendData, startDate: moment(new Date(today)).format('YYYY-MM-DD') });
    } else {
      setSendData({ ...sendData, startDate: moment(new Date(selectDate)).format('YYYY-MM-DD') });
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSendData({ ...sendData, endDate: moment(selectDate).format('YYYY-MM-DD') });
    } else if (selectDate < new Date(sendData?.startDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please enter valid date'
      });
      setSendData({ ...sendData, endDate: moment(new Date(today)).format('YYYY-MM-DD') });
    } else {
      setSendData({ ...sendData, endDate: moment(new Date(selectDate)).format('YYYY-MM-DD') });
    }
  };
  const onRegistrationFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const selectedEndDate = new Date(sendData?.registrationEndDate);
    const today = new Date();
    if (selectDate > selectedEndDate) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please enter valid date'
      });
      setSendData({
        ...sendData,
        registrationStartDate: moment(new Date(today)).format('YYYY-MM-DD')
      });
    } else {
      setSendData({
        ...sendData,
        registrationStartDate: moment(new Date(selectDate)).format('YYYY-MM-DD')
      });
    }
  };

  const onRegistrationToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSendData({ ...sendData, registrationEndDate: moment(selectDate).format('YYYY-MM-DD') });
    } else if (selectDate < new Date(sendData?.registrationStartDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please enter valid date'
      });
      setSendData({
        ...sendData,
        registrationEndDate: moment(new Date(today)).format('YYYY-MM-DD')
      });
    } else {
      setSendData({
        ...sendData,
        registrationEndDate: moment(new Date(selectDate)).format('YYYY-MM-DD')
      });
    }
  };

  const packageDetails = {
    startDate: '',
    endDate: '',
    place: '',
    arrivalTime: '',
    departureTime: '',
    hotelName: '',
    modeOfTransport: [],
    mealType: [],
    isAllModeOfTransportSelected: false,
    isAllMealTypeSelected: false
  };

  const [pakages, setPakages] = useState([]);

  useEffect(() => {
    if (status === 'addPackage') {
      setPakages([packageDetails]);
    } else {
      const result = editdata?.packageDetails?.map((item) => {
        return {
          startDate: moment(item?.startDate).format('YYYY-MM-DD'),
          endDate: moment(item?.endDate).format('YYYY-MM-DD'),
          place: item?.place,
          arrivalTime: moment(item?.arrivalTime).format('hh:mm'),
          departureTime: moment(item?.departureTime).format('hh:mm'),
          hotelName: item?.hotelName,
          modeOfTransport: item?.modeOfTransport,
          mealType: item?.mealType
        };
      });
      setPakages(result);
    }
  }, [editdata]);

  const handleStateChange = (key, value) => {
    if (key === 'country') {
      setSendData({
        ...sendData,
        country: value.name,
        countryCode: value?.value
      });
    } else {
      setSendData((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  const getAllSelectedModeOfTrans = (value) => {
    const isAllModeOfTransportSelected =
      modeOfTransportArray.length > 0 && value.length === modeOfTransportArray.length;
    return isAllModeOfTransportSelected;
  };
  const getAllSelectedMealType = (value) => {
    const isAllModeOfTransportSelected =
      mealPlanArray.length > 0 && value.length === mealPlanArray.length;
    return isAllModeOfTransportSelected;
  };

  const handlePackageDetails = (key, value, index) => {
    setPakages((prevState) => {
      const newArr = [...prevState];

      if (key === 'modeOfTransport') {
        if (value[value.length - 1] === 'all') {
          const result = getAllSelectedModeOfTrans(value) ? [] : modeOfTransportArray;
          value = result;
        }
      } else if (key === 'mealType') {
        if (value[value.length - 1] === 'all') {
          const result = getAllSelectedMealType(value) ? [] : mealTypeArray;
          value = result;
        }
      } else if (key === 'startDate') {
        const selectDate = new Date(value);
        const selectedStartDate = new Date(sendData?.startDate);
        const selectedEndDate = new Date(sendData?.endDate);
        const selectedPackageEndDate = new Date(pakages[index].endDate);
        const today = new Date();
        if (selectDate < selectedStartDate || selectDate > selectedEndDate) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'Select range in between package start date and end date'
          });
          value = moment(new Date(today)).format('YYYY-MM-DD');
        } else {
          if (selectDate > selectedPackageEndDate) {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Please enter valid date'
            });
            value = moment(new Date(today)).format('YYYY-MM-DD');
          } else {
            value = moment(new Date(selectDate)).format('YYYY-MM-DD');
          }
        }
      } else if (key === 'endDate') {
        const selectDate = new Date(value);
        const selectedStartDate = new Date(sendData?.startDate);
        const selectedEndDate = new Date(sendData?.endDate);
        const selectedPackageStartDate = new Date(pakages[index].startDate);
        const today = new Date();
        if (selectDate < selectedStartDate || selectDate > selectedEndDate) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'Select range in between package start date and end date'
          });
          value = moment(new Date(today)).format('YYYY-MM-DD');
        } else {
          if (selectDate > today) {
            value = moment(selectDate).format('YYYY-MM-DD');
          } else if (selectDate < new Date(selectedPackageStartDate)) {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Please enter valid date'
            });
            value = moment(new Date(today)).format('YYYY-MM-DD');
          } else {
            value = moment(new Date(selectDate)).format('YYYY-MM-DD');
          }
        }
      }
      newArr[index][`${key}`] = value;
      return newArr;
    });
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();

    const resultPackge = pakages
      ?.map((item) => {
        const arrivalT = moment(
          `${item?.startDate} ${item?.arrivalTime}`,
          'YYYY-MM-DD HH:mm:ss'
        ).format();
        const departurT = moment(
          `${item?.endDate} ${item?.departureTime}`,
          'YYYY-MM-DD HH:mm:ss'
        ).format();

        return {
          startDate: item?.startDate,
          endDate: item?.endDate,
          place: item?.place,
          arrivalTime: arrivalT,
          departureTime: departurT,
          hotelName: item?.hotelName,
          modeOfTransport: item?.modeOfTransport,
          mealType: item?.mealType,
          totalDays: dateDiffInDays(item?.endDate, item?.startDate)
        };
      })
      .filter((obj) => {
        return obj.startDate !== '' && obj.endDate !== '';
      });

    const postData = {
      ...sendData,
      packageDetails: resultPackge,
      packageType:
        sendData?.packageType === 'Others' ? sendData?.otherPackageType : sendData?.packageType
    };
    if (status === 'addPackage') {
      API_POST(`travelPackage/addTravelPackage`, postData)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      editdata?._id &&
        API_PUT(`travelPackage/updateTravelPackage/${editdata?._id}`, postData)
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            handleClose();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
    }
  };

  // add and delete packges details

  const handlePackageDetail = () => {
    const newArr = [...pakages];
    newArr.push(packageDetails);
    setPakages(newArr);
  };

  const handleDelete = (id) => {
    const result = pakages.filter((item, index) => index !== id);
    setPakages(result);
  };
  const editCountry = { name: sendData?.country, value: sendData?.countryCode };
  return (
    <Container direction="column" wrap="nowrap" className={classes.popupContainer}>
      <Item>
        <Typography style={{ textAlign: 'left', fontWeight: '600', fontSize: '18px' }}>
          {status === 'addPackage' ? 'Add Group' : 'Edit Group'}
        </Typography>
      </Item>
      <form onSubmit={onHandleConfirm}>
        <Item>
          <Paper
            elevation={2}
            sx={{
              p: '10px',
              mt: 1,
              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
              borderRadius: '10px'
            }}>
            <Item className={classes.addPackageContainer} mt={1}>
              <Item>
                <TextField
                  variant="outlined"
                  name="packageId"
                  label="Group Id"
                  size="small"
                  required={true}
                  value={sendData?.packageId}
                  sx={{ width: '225px' }}
                  className={classes.formControl}
                  onChange={(e) => handleStateChange('packageId', e.target.value)}
                  type="text"
                  fullWidth
                />
              </Item>
              <Item>
                <TextField
                  variant="outlined"
                  name="packageName"
                  size="small"
                  label="Group Name"
                  required={true}
                  sx={{ width: '225px' }}
                  value={sendData?.packageName}
                  className={classes.formControl}
                  onChange={(e) => handleStateChange('packageName', e.target.value)}
                  type="text"
                  fullWidth
                />
              </Item>
              <Item>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(e, value) => {
                    if (value !== null) {
                      handleStateChange('packageType', value);
                    } else {
                      handleStateChange('packageType', '');
                    }
                  }}
                  options={allGroups}
                  value={sendData?.packageType}
                  color="#134CDF"
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="Group Type"
                      required={true}
                      label="Group Type"
                      variant="outlined"
                      type="text"
                      size="small"
                    />
                  )}
                />
              </Item>
              {/* <Item>
                <FormControl fullWidth size="small" required={true}>
                  <InputLabel id="demo-simple-select-label"> Group Type </InputLabel>
                  <Select
                    variant="outlined"
                    size="small"
                    required={true}
                    value={sendData.packageType}
                    label="Group Type"
                    onChange={(e) => handleStateChange('packageType', e.target.value)}>
                    {PackagetypeArray?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {ConvertToUpperCase(item)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Item> */}
              {sendData?.packageType === 'Others' && (
                <Item>
                  <TextField
                    variant="outlined"
                    name="otherPackageType"
                    label="Enter Package Type"
                    size="small"
                    required={true}
                    value={sendData?.otherPackageType}
                    sx={{ width: '225px' }}
                    className={classes.formControl}
                    onChange={(e) => handleStateChange('otherPackageType', e.target.value)}
                    type="text"
                    fullWidth
                  />
                </Item>
              )}
              <Item>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(e, value) => {
                    if (value !== null) {
                      handleStateChange('country', value);
                    } else {
                      handleStateChange('destination', '');
                    }
                  }}
                  options={countryList}
                  value={status === 'editPackage' ? editCountry : sendData.country?.name}
                  color="#134CDF"
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="Country"
                      required={true}
                      label="Country"
                      variant="outlined"
                      type="text"
                      size="small"
                    />
                  )}
                />
                {/*  <TextField
                  variant="outlined"
                  name="packageId"
                  size="small"
                  label="Country"
                  sx={{ width: '225px' }}
                  value={sendData?.country}
                  className={classes.formControl}
                  onChange={(e) => handleStateChange('country', e.target.value)}
                  type="text"
                  fullWidth
                  /> */}
              </Item>
              <Item>
                <TextField
                  id="datetime-local"
                  label="Start Date"
                  type="date"
                  size="small"
                  required={true}
                  sx={{ width: '225px' }}
                  value={sendData.startDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onFromDateChange(e);
                  }}
                />
              </Item>
              <Item>
                <TextField
                  id="datetime-local"
                  label="End Date"
                  type="date"
                  size="small"
                  required={true}
                  sx={{ width: '225px' }}
                  value={sendData.endDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onToDateChange(e);
                  }}
                />
              </Item>
              <Item>
                <TextField
                  variant="outlined"
                  name="pax"
                  size="small"
                  label="Pax"
                  sx={{ width: '225px' }}
                  value={sendData?.pax}
                  className={classes.formControl}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      handleStateChange('pax', 0);
                    } else {
                      handleStateChange('pax', e.target.value);
                    }
                  }}
                  type="number"
                  fullWidth
                />
              </Item>
              <Item>
                <TextField
                  id="datetime-local"
                  label="Registration Start Date"
                  type="date"
                  size="small"
                  required={true}
                  sx={{ width: '225px' }}
                  value={sendData.registrationStartDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onRegistrationFromDateChange(e);
                  }}
                />
              </Item>
              <Item>
                <TextField
                  id="datetime-local"
                  label="Registration End Date"
                  type="date"
                  size="small"
                  required={true}
                  sx={{ width: '225px' }}
                  value={sendData.registrationEndDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onRegistrationToDateChange(e);
                  }}
                />
              </Item>
              <Item>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={(e, value) => {
                    if (value !== null) {
                      handleStateChange('destination', value);
                    }
                  }}
                  options={destination}
                  value={sendData?.destination}
                  color="#134CDF"
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="Destination"
                      required={true}
                      label="Destination"
                      variant="outlined"
                      type="text"
                      size="small"
                    />
                  )}
                />
              </Item>
              {/* <Item>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Meal Type </InputLabel>
                  <Select
                    variant="outlined"
                    // size="small"
                    value={sendData.mealPlan}
                    label="Meal Type"
                    onChange={(e) => handleStateChange('mealPlan', e.target.value)}>
                    {mealPlanArray?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {ConvertToUpperCase(item)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> 
                  </Item> */}
            </Item>
          </Paper>
          <Item mt={2.3}>
            <Item sx={{ display: 'flex' }}>
              <Typography style={{ textAlign: 'left', fontWeight: '600', fontSize: '18px' }}>
                {status === 'addPackage' ? 'Add Group Details' : 'Edit Group Details'}
              </Typography>
              <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
                <AddCircleIcon
                  sx={{ color: 'red', fontSize: '35px', cursor: 'pointer' }}
                  onClick={handlePackageDetail}
                />
              </Item>
            </Item>
            <Item sx={{ maxHeight: '50vh', overflow: 'auto', overflowX: 'hidden' }}>
              {pakages?.map((item, pkindex) => {
                return (
                  <Paper
                    elevation={2}
                    key={pkindex}
                    sx={{
                      p: '10px',
                      mt: 2,
                      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
                      borderRadius: '10px',
                      position: 'relative',
                      border: '1px solid lightgrey'
                    }}>
                    <Item
                      onClick={() => handleDelete(pkindex)}
                      sx={{
                        position: 'absolute',
                        right: '-1px',
                        top: '-10px',
                        cursor: 'pointer'
                      }}>
                      <CancelIcon />
                    </Item>
                    <Item className={classes.addPackageContainer} mt={1}>
                      <Item>
                        <TextField
                          id="datetime-local"
                          label="Start Date"
                          type="date"
                          size="small"
                          sx={{ width: '225px' }}
                          value={item.startDate}
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={(e) => {
                            handlePackageDetails('startDate', e.target.value, pkindex);
                          }}
                        />
                      </Item>
                      <Item>
                        <TextField
                          id="datetime-local"
                          label="End Date"
                          type="date"
                          size="small"
                          sx={{ width: '225px' }}
                          value={item.endDate}
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={(e) => {
                            handlePackageDetails('endDate', e.target.value, pkindex);
                          }}
                        />
                      </Item>
                      <Item>
                        <TextField
                          variant="outlined"
                          name="place"
                          size="small"
                          label="Place"
                          sx={{ width: '225px' }}
                          value={item?.place}
                          className={classes.formControl}
                          onChange={(e) => handlePackageDetails('place', e.target.value, pkindex)}
                          type="text"
                          fullWidth
                        />
                      </Item>

                      <Item>
                        <TextField
                          id="datetime-local"
                          label="Arrival Time"
                          type="time"
                          size="small"
                          sx={{ width: '225px' }}
                          value={item.arrivalTime}
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={(e) => {
                            handlePackageDetails('arrivalTime', e.target.value, pkindex);
                          }}
                        />
                      </Item>

                      <Item>
                        <TextField
                          id="datetime-local"
                          label="Departure Time"
                          type="time"
                          size="small"
                          sx={{ width: '225px' }}
                          value={item.departureTime}
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={(e) => {
                            handlePackageDetails('departureTime', e.target.value, pkindex);
                          }}
                        />
                      </Item>
                      <Item>
                        <FormControl sx={{ width: '225px' }} variant="outlined" size="small">
                          <InputLabel id="mutiple-select-label">Mode of Transport</InputLabel>
                          <Select
                            labelId="mutiple-select-label"
                            multiple
                            value={item.modeOfTransport}
                            onChange={(e) =>
                              handlePackageDetails('modeOfTransport', e.target.value, pkindex)
                            }
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            size="small"
                            label="Mode of Transport">
                            {/*  <MenuItem
                              value="all"
                              classes={{
                                root: item.isAllModeOfTransportSelected ? classes.selectedAll : ''
                              }}>
                              <ListItemIcon>
                                <Checkbox
                                  classes={{ indeterminate: classes.indeterminateColor }}
                                  checked={item.modeOfTransport}
                                  indeterminate={
                                    item.modeOfTransport.length > 0 &&
                                    item.modeOfTransport.length < modeOfTransportArray.length
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText
                                classes={{ primary: classes.selectAllText }}
                                primary="Select All"
                              />
                                </MenuItem> */}
                            {modeOfTransportArray
                              .sort((a, b) => a.localeCompare(b))
                              .map((option) => (
                                <MenuItem key={option} value={option}>
                                  <ListItemIcon>
                                    <Checkbox checked={item.modeOfTransport.indexOf(option) > -1} />
                                  </ListItemIcon>
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Item>
                      {/*    <Item>
                        <FormControl sx={{ width: '225px' }} variant="outlined" size="small">
                          <InputLabel id="mutiple-select-label">Meal Plan</InputLabel>
                          <Select
                            labelId="mutiple-select-label"
                            multiple
                            value={item.mealType}
                            onChange={(e) =>
                              handlePackageDetails('mealType', e.target.value, pkindex)
                            }
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            size="small"
                            label="Reason">
                            {mealTypeArray
                              .sort((a, b) => a.localeCompare(b))
                              .map((option) => (
                                <MenuItem key={option} value={option}>
                                  <ListItemIcon>
                                    <Checkbox checked={item.mealType.indexOf(option) > -1} />
                                  </ListItemIcon>
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                              </Item> */}

                      <Item>
                        <TextField
                          variant="outlined"
                          name="hotelName"
                          sx={{ width: '225px' }}
                          value={item?.hotelName}
                          className={classes.formControl}
                          onChange={(e) =>
                            handlePackageDetails('hotelName', e.target.value, pkindex)
                          }
                          type="text"
                          size="small"
                          label="Hotel Name"
                          fullWidth
                        />
                      </Item>
                    </Item>
                  </Paper>
                );
              })}
            </Item>
          </Item>
        </Item>
        <Item sx={{ display: 'flex', gap: '10px', p: 1 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
              Cancel
            </Button>
          </Item>
          <Item>
            <Button
              type="submit"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              {`Submit`}
            </Button>
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(AddNominatedAgent);
