const styles = (theme) => {
  return {
    title: {
      backgroundColor: theme.palette.primary.tableHeadingColor,
      fontFamily: theme.palette.font.fontFamily,
      color: 'white',
      textAlign: 'center',
      borderRadius: '10px 10px 0 0',
      padding: '10px'
    },
    formControl: {
      width: '98%'
    },
    iconConatinner: {
      height: '100px'
    },

    submitButton: {
      fontFamily: theme.palette.font.fontFamily
    },
    icon1: {
      padding: theme.spacing(0, 1),
      textAlign: 'center',
      fontFamily: theme.palette.font.fontFamily,
      borderRadius: '5px',
      marginTop: '-15px'
    },
    iconLabel1: {
      fontSize: '10px',
      marginTop: theme.spacing(-0.3),
      fontFamily: theme.palette.font.fontFamily,
      fontWeight: '500'
    }
  };
};

export default styles;
