import React, { useState, useEffect } from 'react';
import AddDoctor from '../../components/organism/Modal/AddDoctor';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import Dialog from '../../components/atom/Dialog';
import DoctorCard from './Card';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import theme from '../../utils/theme';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

const { Grid, withStyles } = Components;

const Item = (props) => <Grid item {...props} />;

const DoctorsData = ({ classes, searchText }) => {
  const [, dispatch] = useStateValue();
  const [isAddDoctorModalOpen, setIsDoctorModalOpen] = useState(false);
  const [allDoctors, setAllDoctors] = useState([]);

  const handleClose = () => {
    setIsDoctorModalOpen(false);
  };

  const handleAddDoctor = () => {
    setIsDoctorModalOpen(true);
  };
  const getAllDoctors = () => {
    API_GET(`doctorProfile/getDoctors?searchText=${searchText}`)
      .then((res) => {
        setAllDoctors(res?.data?.doctors);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getAllDoctors();
  }, [isAddDoctorModalOpen, searchText]);

  return (
    <Item>
      <Item className={classes.doctorConatiner} md={12} xs={12}>
        <Item onClick={handleAddDoctor} className={classes.addDoctorCard} md={2.8} xs={12} mt={2.5}>
          <AddCircleRoundedIcon
            sx={{
              fontSize: '120px',
              color: `${theme.palette.primary.main}`
            }}
          />
        </Item>
        {allDoctors?.map((item, index) => {
          return (
            <Item md={2.8} xs={12} mt={2.5} className={classes.doctorInfoCard} key={index}>
              <DoctorCard data={item} getAllDoctors={getAllDoctors} />
            </Item>
          );
        })}
      </Item>

      <Dialog
        isOpen={isAddDoctorModalOpen}
        children={<AddDoctor handleClose={handleClose} />}></Dialog>
    </Item>
  );
};

export default withStyles(styles)(DoctorsData);
