import { ResponsivePieCanvas } from '@nivo/pie';
import React, { useEffect, useState } from 'react';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import styles from './styles';
import { Components } from '../../../utils/material-ui';
import theme from '../../../utils/theme';

const { withStyles, Typography, Grid } = Components;
const Item = (props) => <Grid item {...props} />;

const MyResponsivePieCanvas = ({ selectedFromDate, selectedToDate }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [flagStatus, setFlagStatus] = useState(false);
  const data = [
    {
      label: `No Data Found`,
      color: '#9F9F9F',
      value: 100 / 100,
      id: 'No Data Found'
    }
  ];
  useEffect(() => {
    API_GET(`graph/getIncidentsAnalytics?fromDate=${selectedFromDate}&toDate=${selectedToDate}`)
      .then((res) => {
        let flag = false;
        const result = res?.data?.map((item) => {
          if (item?.count !== 0) {
            flag = true;
          }
          return {
            label: `${item?._id}`,
            color: item?.color,
            value: item?.percentage / 100,
            id: `${item?._id}`
          };
        });
        if (flag === true) {
          setFlagStatus(flag);
        }
        setRows(result);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate]);

  const resultdata = flagStatus ? rows : data;

  const resultFlag = flagStatus
    ? {
        flexDirection: 'column'
      }
    : { alignItems: 'center' };

  return (
    <Item>
      <Item>
        <Typography sx={{ ...theme.typography.tableHeading }}>External risk analytics</Typography>
      </Item>
      <Item sx={{ display: 'flex' }}>
        <Item style={{ width: '60%', height: '150px' }}>
          <ResponsivePieCanvas
            theme={{
              fontSize: 14,
              fontWeight: '600'
            }}
            enableArcLabels={false}
            valueFormat=" >-1.1%"
            enableArcLinkLabels={false}
            data={flagStatus ? rows : data}
            margin={{ top: 0, right: 0, bottom: 15, left: 0 }}
            innerRadius={0.5}
            padAngle={1.5}
            cornerRadius={3}
            activeOuterRadiusOffset={2}
            colors={{ datum: 'data.color' }}
            arcLinkLabelsSkipAngle={5}
            arcLinkLabelsTextColor="black"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={5}
            arcLabelsTextColor="black"
          />
        </Item>

        <Item
          style={{
            display: 'flex',
            ...resultFlag
          }}>
          {resultdata?.map((item, index) => {
            return (
              <Item
                key={index}
                style={{
                  marginTop: '-20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flexDirection: 'row'
                }}>
                <Item
                  style={{
                    width: '0.8rem',
                    height: '0.8rem',
                    borderRadius: 15,
                    backgroundColor: item?.color
                  }}></Item>
                <Typography
                  style={{
                    ...theme.typography.tableHeading,
                    fontSize: '14px',
                    fontWeight: '400'
                  }}>
                  {item?.label}
                </Typography>
              </Item>
            );
          })}
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
