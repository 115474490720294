import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import theme from '../../../utils/theme';
import overviewcss from './overviewcss';
// import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';

const { withStyles, Typography, Grid, Select, MenuItem, FormControl, InputLabel } = Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

// const data = [
//   {
//     name: 'Total uploads',
//     value: 1376,
//     percentage: 45,
//     color: '#3B9DF7',
//     backgroundColor: '#EBF5FE'
//   },
//   {
//     name: 'Pending',
//     value: 1376,
//     percentage: 45,
//     color: '#FCB94D',
//     backgroundColor: '#FEF4E3'
//   },
//   {
//     name: 'Expired',
//     value: 12109,
//     percentage: 87,
//     color: '#ED3E3C',
//     backgroundColor: '#FEE5E8'
//   },
//   {
//     name: 'About to expire',
//     value: 137,
//     percentage: 10,
//     color: '#34B53A',
//     backgroundColor: '#E2FBD7'
//   }
// ];

const DocumentsGraph = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [selectedDocument, setSelectedDocument] = useState('passport');
  const [data, setData] = useState({});

  const resDocs = [
    // { name: 'All', value: 'all' },
    { name: 'Passport', value: 'passport' },
    { name: 'PanCard', value: 'panCard' },
    { name: 'Aadhaar Card', value: 'aadhaarCard' }
    // { name: 'Visa', value: 'visa' }
  ];

  // const resDocs =
  //   store.corporateId === theme.corporate.mliCorporateId
  //     ? documents
  //     : [
  //         ...documents,
  //         { name: 'Health Insurance', value: 'healthInsurance' },
  //         { name: 'Travel Insurance', value: 'travelInsurance' }
  //       ];

  useEffect(() => {
    API_GET(`travelPackage/getDocumtsInfo?docType=${selectedDocument}`)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [selectedDocument]);

  const handleDownload = () => {
    API_GET(`travelpackage/downloadDocumentReport`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res?.message || 'Success'
        });
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item>
        <Item
          sx={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'space-between',
            alignItems: 'center',
            pt: 1
          }}>
          <Item>
            <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
              Documents
            </Typography>
          </Item>
          <Item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"> Documents </InputLabel>
              <Select
                variant="outlined"
                size="small"
                style={{ height: 30, borderRadius: 10 }}
                value={selectedDocument}
                sx={{ width: '250px' }}
                label="Documents"
                onChange={(e) => setSelectedDocument(e.target.value)}>
                {resDocs?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {ConvertToUpperCase(item?.name)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Item>
          <Item>
            <FileDownloadIcon style={{ cursor: 'pointer' }} onClick={handleDownload} />
          </Item>
        </Item>
      </Item>

      <Item className={classes.documentGraphContainer}>
        {data &&
          Object.values(data)[0]?.map((item, index) => {
            return (
              <Item key={index}>
                <Item
                  mt={1}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <p style={{ fontSize: '14px' }}>{item?.name}</p>
                  <p style={{ color: item?.color, fontSize: '15px' }}>{`${
                    item?.value === null ? 0 : item?.value
                  }`}</p>
                </Item>
                <Item
                  style={{
                    height: '9px',
                    borderRadius: '5px',
                    width: '100%',
                    backgroundColor: item?.backgroundColor
                  }}>
                  <Item
                    style={{
                      height: '100%',
                      width: `${item?.percentage === null ? 0 : item?.percentage}%`,
                      backgroundColor: item?.color,
                      borderRadius: '5px'
                    }}></Item>
                </Item>
              </Item>
            );
          })}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(overviewcss)(DocumentsGraph);
