import React from 'react';
import { Components } from '../../utils/material-ui';
import Login from './login';
import SideContain from './sideContain';
import styles from './styles';
import Carousel from 'react-material-ui-carousel';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const { withStyles, Grid, IconButton } = Components;

const Item = (props) => <Grid item {...props} />;

const sideContainData = {
  riskAlerts: {
    label: 'Get advanced Risk alerts',
    img: 'riskAlert',
    description:
      'Our intelligent Alert system monitors and identifies external risks and safety concerns near your people, company locations,and assets.',
    subDescription: [
      'Advanced proprietary AI tool to analyse risk incidents from thousand of sources.',
      'Team of industry experts and risk analysts to generate relevant insights for predictive alerts'
      // 'Categorize risks based on the severity indications to help prioritize course of action.'
    ]
  },
  sosAlert: {
    label: 'Safety & Happiness',
    img: 'saftey',
    description: 'Access help with just one click during an emergency.',
    subDescription: [
      'A team of dedicated safety warriors is available 24X7 in the command center',
      'A network of 11000+ ambulances, Pan India'
      // 'On Road Assistance, Doctors, and Lawyers at your fingertips for emergency scenarios'
    ]
  },
  safehavens: {
    label: 'Safe havens',
    img: 'safehavens',
    description:
      'Feel safer in unknown locations, Network of safety locations to help you feel more safer even while travelling to unknown locations',
    subDescription: [
      'Accurate and detailed directories of safe locations around the country',
      'Get access to police stations, sulabh toilets, restaurants, bars, hospitals, Blood banks, Petrol pumps and more. '
    ]
  },
  broadcast: {
    label: 'Broadcast',
    img: 'broadcast',
    description:
      'Communication is key during critical events. Communicate with your employees with our advanced CUG Broadcast.',
    subDescription: [
      'Two way communications channel helps ensure which employees need help.',
      'Multichannel communication ensures fast and efficient delivery of emergency messages'
    ]
  }
};

const LoginMain = ({ classes }) => {
  function Dot(props) {
    return (
      <IconButton style={{ backgroundColor: 'red' }}>
        <FiberManualRecordIcon style={{ color: 'red' }} />
      </IconButton>
    );
  }
  return (
    <Item className={classes.mainContainer} md={12} xs={12}>
      <img
        style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
        src={require(`../../Assets/Login/LoginBackground.svg`).default}
        alt="Login"
      />
      <Item className={classes.inSideContainer} md={12} xs={12}>
        <Item sx={{ width: '54vw', minWidth: '360px' }} xs={12}>
          <Carousel
            autoPlay={1000}
            indicatorIconButtonProps={{
              children: <Dot />
            }}>
            {Object.keys(sideContainData)?.map((item) => {
              return <SideContain data={sideContainData[item]} />;
            })}
          </Carousel>
        </Item>
        <Item sx={{ width: '36vw', minWidth: '360px' }} xs={12}>
          <Login />
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(LoginMain);
