import { Typography } from '@mui/material';
import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';

const { withStyles, Grid, Button } = Components;

const Item = (props) => <Grid item {...props} />;

const Confirmation = ({ classes, handleDependent, handlePackage }) => {
  return (
    <Item
      md={12}
      sx={{
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto'
      }}>
      <Item
        md={7}
        style={{
          height: '300px',
          background: 'linear-gradient(178.85deg, #FF6760 -14.19%, rgba(255, 255, 255, 0) 184.77%)',
          boxShadow: '0px 0px 4.92391px 1.40683px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px'
        }}>
        <Item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <img
            style={{ height: '120px', width: '120px' }}
            src={require(`../../Assets/TravelIcon/Thanks.gif`).default}
            alt="verify"
          />
        </Item>
        <Item align="center" md={12}>
          <Typography sx={{ textAlign: 'center', fontSize: '22px', fontWeight: '600' }}>
            {`Thanks !`}
          </Typography>

          <Typography
            sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '500', color: '#5A5A5A' }}
            mt={1}>
            {`Your family Member has been added successfully. Do you want to add more family members?`}
          </Typography>
          <Item
            md={12}
            xs={12}
            mt={2}
            sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
            <Button
              style={{ ...theme.button.noAction, height: '40px', fontSize: '14px' }}
              onClick={() => handlePackage()}>
              No
            </Button>
            <Button
              style={{ ...theme.button.forward, height: '40px', fontSize: '14px' }}
              onClick={() => handleDependent()}>
              Yes
            </Button>
          </Item>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(Confirmation);
