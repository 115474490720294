import React from 'react';
import { Components } from '../../utils/material-ui';
import theme from '../../utils/theme';
import styles from './styles';

const { withStyles, Card, Typography } = Components;

const selectedCard = {
  width: '200px',
  height: '60px',
  // border: `3px solid ${theme.palette.primary.tableHeadingColor}`,
  color: 'white',
  marginRight: '40px',
  backgroundColor: theme.palette.primary.sosDashboard,
  margin: '20px 40px 20px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};
const unselectedCard = {
  cursor: 'pointer',
  width: '200px',
  height: '60px',
  color: 'black',
  margin: '20px 40px 20px 0px',
  backgroundColor: 'white',
  marginRight: '40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

function IncidentTypeCard(props) {
  const { data, current, count, onClick } = props;

  return (
    <Card
      onClick={() => onClick(data.value)}
      // className={current === data.value ? classes.selectedCard : classes.unselectedCard}>
      style={current === data.value ? selectedCard : unselectedCard}>
      <Typography align="center" variant="h6">
        {count[`${data.value}`]}
      </Typography>
      <Typography align="center" variant="body1">
        {data.label}
      </Typography>
    </Card>
  );
}

export default withStyles(styles)(IncidentTypeCard);
