/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import theme from '../../utils/theme';
import PieChartTable from './assignedPieChart';
import styles from './styles';
import { useStateValue } from '../../utils/store';
import { API_GET } from '../../utils/api';
import AcceptedIncidentTable from '../../components/organism/Table/IncidentDistribuition/AcceptedIncidentTable';
import FilterCategory from './FilterCategory';
import IndiviualUserBarChart from './IndiviualUserBarChart';
import Chip from '@mui/material/Chip';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';

const { withStyles, Card, Grid, Paper, MenuItem, FormControl, Select, InputLabel, Typography } =
  Components;
const { LinkIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const priorityArray = [
  { name: 'All', value: 0 },
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
  { name: '7', value: 7 },
  { name: '8', value: 8 },
  { name: '9', value: 9 },
  { name: '10', value: 10 }
];

const AccepetedIncidentDistribution = ({
  classes,
  isIncident,
  selectedFromDate,
  selectedToDate,
  search,
  chat,
  paramId
}) => {
  const [, dispatch] = useStateValue();
  const [severity, setSeverity] = useState(0);
  const [offices, setOffices] = React.useState([]);
  const [selectedOffice, setSelectedOffice] = useState('');
  const [selectedFilterCategory, setSelectedFilterCategory] = useState('');
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  function getStyles(name, personName, theme) {
    return {
      backgroundColor: personName.indexOf(name) === -1 ? 'white' : theme.palette.primary.main
    };
  }

  const handleSelectChange = (value) => {
    if (value === null) {
      setSelectedOffice('');
    } else {
      setSelectedOffice(value?._id);
    }
  };

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        setOffices(res?.data);
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: err[0]?.message || 'Oops!!, Please try again'
        });
      });
  };
  useEffect(() => {
    getAllOffices();
  }, []);

  const handleSelectedCategory = (value) => {
    setSelectedFilterCategory(value);
  };

  return (
    <Container>
      <Container spacing={1}>
        <Item md={12} xs={12}>
          <Paper style={theme.card}>
            {!isIncident ? (
              <Item sx={{ display: 'flex', padding: '5px' }}>
                <Item className={classes.filterConatiner}>
                  <Item sx={{ display: 'flex' }}>
                    <div style={{ marginRight: '5px' }}>
                      <LinkIcon sx={{ fontSize: '16px', fontWeight: 'bold' }} />
                    </div>
                    <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
                      Safety Issue Summary Severity
                    </div>
                  </Item>
                  <Item sx={{ display: 'flex' }}>
                    <div>
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={severity}
                          sx={{ width: '80px' }}
                          className={classes.select}
                          onChange={(e) => setSeverity(e.target.value)}>
                          {priorityArray?.map((item, index) => {
                            return (
                              <MenuItem value={item?.value} key={index}>
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div></div>
                  </Item>
                </Item>

                <Item
                  className={classes.filterConatiner3}
                  sx={{ display: 'inline-flex', ml: 'auto' }}>
                  <FormControl style={{ width: '300px' }}>
                    <InputLabel id="demo-mutiple-chip-label">Location</InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      variant="outlined"
                      value={personName}
                      label="location"
                      onChange={handleChange}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value, index) => (
                            <Chip key={index} label={value.name} />
                          ))}
                        </div>
                      )}>
                      {offices.map((name, index) => (
                        <MenuItem
                          key={index}
                          value={name}
                          style={getStyles(name, personName, theme)}>
                          {`${name.name}, ${ConvertToUpperCase(name.city)}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Item>
              </Item>
            ) : (
              <FilterCategory handleSelectedCategory={handleSelectedCategory} />
            )}
          </Paper>
        </Item>
        <Item md={12} xs={12} mt={1} style={{ width: '500px' }}>
          <Card sx={{ ...theme.card, borderRadius: '10px' }}>
            <AcceptedIncidentTable
              severity={severity}
              selectedOffice={personName}
              isIncident={isIncident}
              selectedFilterCategory={selectedFilterCategory}
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              search={search}
              chat={chat}
              paramId={paramId}
            />
          </Card>
        </Item>
      </Container>
      <Container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Item md={12} xs={12} lg={4.5} sx={{ marginTop: '20px' }}>
          <Paper
            sx={{
              height: '250px',
              ...theme.card
            }}>
            <Item sx={{ height: '30px', width: '100%' }}>
              <Typography sx={theme.typography.tableHeading}>
                {`Overall status Distribution of ${
                  isIncident ? 'External Risk' : 'Internal Risk'
                } `}
              </Typography>
            </Item>
            <Item sx={{ height: '210px' }}>
              <PieChartTable
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                isIncident={isIncident}
              />
            </Item>
          </Paper>
        </Item>

        <Item md={12} lg={7.5} sx={{ marginTop: '20px' }}>
          <Paper
            sx={{
              height: '250px',
              ...theme.card
            }}>
            <Item sx={{ height: '30px', width: '100%' }}>
              <Typography sx={theme.typography.tableHeading}>
                {`Individual Status Distribution of ${
                  isIncident ? 'External Risk' : 'Internal Risk'
                } `}
              </Typography>
            </Item>
            <Item sx={{ height: '210px' }}>
              <IndiviualUserBarChart
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                isIncident={isIncident}
              />
            </Item>
          </Paper>
        </Item>
      </Container>
      ;
    </Container>
  );
};

export default withStyles(styles)(AccepetedIncidentDistribution);
