import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
// import theme from '../../utils/theme';

const { withStyles, Grid } = Components;

const Item = (props) => <Grid item {...props} />;

const Confirmation = ({ classes, resUserData, handleDependent }) => {
  const [userData, setUserData] = useState({});
  const [count, setCount] = useState(10);

  const handleMore = () => {
    handleDependent();
  };

  useEffect(() => {
    setUserData(resUserData);
  }, [resUserData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (count >= 1) {
        setCount(count - 1);
      }
      if (count === 0) {
        handleDependent();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [count]);

  return (
    <Item
      md={12}
      sx={{
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto'
      }}>
      <Item
        md={7}
        style={{
          height: '350px',
          background: 'linear-gradient(180deg, #FF6760 -22.04%, rgba(255, 255, 255, 0) 183.2%)',
          boxShadow: '0px 0px 4.92391px 1.40683px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px'
        }}>
        <Item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <img
            style={{ height: '130px', width: '130px' }}
            src={require(`../../Assets/TravelIcon/Welcome.gif`).default}
            alt="verify"
          />
        </Item>
        <Item align="center">
          <Typography sx={{ textAlign: 'center', fontSize: '22px', fontWeight: '600' }}>
            {`Welcome ${ConvertToUpperCase(userData?.name)} !`}
          </Typography>

          <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '500' }} mt={1}>
            {`You are Registered with us, We are excited to have you .`}
          </Typography>
          <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
            {`You’re being redirected to Family Member’s Registration Page, it may take upto ${count} seconds`}
          </Typography>
          <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '600' }}>
            {`OR`}
          </Typography>
          <Typography
            mt={1}
            onClick={handleMore}
            sx={{
              textAlign: 'center',
              fontSize: '17px',
              textDecoration: 'underline',
              cursor: 'pointer',
              fontWeight: '600',
              color: 'red'
            }}>
            click here to add family members
          </Typography>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(Confirmation);
