import React from 'react';
import { Components } from '../../../utils/material-ui';
import styles from './styles';

const { withStyles, Grid } = Components;

const Container = (props) => <Grid container {...props} />;
// const Item = (props) => <Grid item {...props} />;

const Subscription = ({ classes }) => {
  return (
    <Container spacing={1} className={classes.mainContainer}>
      Subscription
    </Container>
  );
};

export default withStyles(styles)(Subscription);
