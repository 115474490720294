import React, { useEffect, useState } from 'react';
import { Components } from '../../../../utils/material-ui';
import { useStateValue } from '../../../../utils/store';
import moment from 'moment';
import styles from './styles';
import { API_GET, API_POST, API_HANDLE_ERROR } from '../../../../utils/api';
import { Typography } from '@mui/material';

const { withStyles, Grid, TextField, Button } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const SuperAdminDashboard = ({ classes, handleClose, flow, selectedCorporate }) => {
  //   const [corporates, setCorporates] = useState([]);
  const [, dispatch] = useStateValue();

  const [sendData, setSendData] = useState({
    name: '',
    phone: '',
    emailId: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    website: '',
    logo: '',
    gstNumber: '',
    panNumber: '',
    numberOfEmployees: '',
    fiscalYearBegin: moment(new Date()).format('YYYY-MM-DD'),
    fiscalYearEnd: moment(new Date()).format('YYYY-MM-DD'),
    officeName: '',
    officeCode: '',
    superAdminName: '',
    superAdminPhone: '',
    superAdminEmail: ''
  });

  const handleStateChange = (key, value) => {
    setSendData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  useEffect(() => {
    if (flow === 'viewDetails') {
      API_GET(`corporate/getCorporate?corporateId=${selectedCorporate}`)
        .then((res) => {
          setSendData({ ...res.data });
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  }, [flow]);

  //   useEffect(() => {
  //     API_GET(`corporate/getCorporate?corporateId={}`)
  //       .then((res) => {
  //         setCorporates(res.allCorporates);
  //       })
  //       .catch((err) => {

  //         API_HANDLE_ERROR(err, dispatch);
  //       });
  //   }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    API_POST(`corporate/addCorporate`, sendData)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        handleClose();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <Container
      direction="column"
      style={{
        maxWidth: '50vw',
        height: '80vh',
        overflow: 'auto'
      }}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" align="center" mt={1}>
          {flow === 'viewDetails' ? 'Corporate Details' : 'Add Corporate'}
        </Typography>
        <Item container spacing={2} sx={{ padding: '20px' }}>
          <Item xs={6}>
            <TextField
              variant="outlined"
              name="name"
              label="Name"
              value={sendData.name}
              required={true}
              onChange={(e) => handleStateChange('name', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>
          <Item xs={6}>
            <TextField
              variant="outlined"
              name="phone"
              label="Phone"
              required={true}
              value={sendData.phone}
              onChange={(e) => handleStateChange('phone', e.target.value)}
              type="number"
              fullWidth
            />
          </Item>
          <Item xs={6}>
            <TextField
              variant="outlined"
              name="emailId"
              label="Email"
              required={true}
              value={sendData.emailId}
              onChange={(e) => handleStateChange('emailId', e.target.value)}
              type="email"
              fullWidth
            />
          </Item>
          <Item xs={6}>
            <TextField
              variant="outlined"
              name="website"
              label="Website"
              required={true}
              value={sendData.website}
              onChange={(e) => handleStateChange('website', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>
          <Item xs={6}>
            <TextField
              variant="outlined"
              name="address"
              label="Address"
              required={true}
              value={sendData.address}
              onChange={(e) => handleStateChange('address', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>
          <Item xs={6}>
            <TextField
              variant="outlined"
              name="city"
              label="City"
              required={true}
              value={sendData.city}
              onChange={(e) => handleStateChange('city', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>

          <Item xs={6}>
            <TextField
              variant="outlined"
              name="pincode"
              label="Pincode"
              required={true}
              value={sendData.pincode}
              onChange={(e) => handleStateChange('pincode', e.target.value)}
              type="number"
              fullWidth
            />
          </Item>
          <Item xs={6}>
            <TextField
              variant="outlined"
              name="state"
              label="State"
              required={true}
              value={sendData.state}
              onChange={(e) => handleStateChange('state', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>

          <Item xs={6}>
            <TextField
              variant="outlined"
              name="logo"
              label="Logo Link"
              required={true}
              value={sendData.logo}
              onChange={(e) => handleStateChange('logo', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>

          <Item xs={6}>
            <TextField
              variant="outlined"
              name="gstNumber"
              label="GST Number"
              value={sendData.gstNumber}
              onChange={(e) => handleStateChange('gstNumber', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>

          <Item xs={6}>
            <TextField
              variant="outlined"
              name="panNumber"
              label="Pan Number"
              value={sendData.panNumber}
              onChange={(e) => handleStateChange('panNumber', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>

          <Item xs={6}>
            <TextField
              variant="outlined"
              name="numberOfEmployees"
              label="Number Of Employees"
              value={sendData.numberOfEmployees}
              onChange={(e) => handleStateChange('numberOfEmployees', e.target.value)}
              type="number"
              fullWidth
            />
          </Item>

          <Item xs={6}>
            <TextField
              id="startDate"
              label="Fiscal Year Start Date"
              type="date"
              value={sendData?.fiscalYearBegin}
              className={classes.textField}
              onChange={(e) => {
                handleStateChange('fiscalYearBegin', e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Item>

          <Item xs={6}>
            <TextField
              id="endDate"
              label="Fiscal Year End Date"
              type="date"
              value={sendData?.fiscalYearEnd}
              className={classes.textField}
              onChange={(e) => {
                handleStateChange('fiscalYearEnd', e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Item>
          {flow !== 'viewDetails' && (
            <>
              <Item>Office Details</Item>
              <Item container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Item xs={6}>
                  <TextField
                    variant="outlined"
                    name="officeName"
                    label="Office Name"
                    value={sendData.officeName}
                    required={true}
                    onChange={(e) => handleStateChange('officeName', e.target.value)}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item xs={6}>
                  <TextField
                    variant="outlined"
                    name="officeCode"
                    label="Office Code"
                    required={true}
                    value={sendData.officeCode}
                    onChange={(e) => handleStateChange('officeCode', e.target.value)}
                    type="text"
                    fullWidth
                  />
                </Item>
              </Item>
              <Item>Admin Details</Item>
              <Item container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Item xs={6}>
                  <TextField
                    variant="outlined"
                    name="superAdminName"
                    label="Super Admin Name"
                    value={sendData.superAdminName}
                    required={true}
                    onChange={(e) => handleStateChange('superAdminName', e.target.value)}
                    type="text"
                    fullWidth
                  />
                </Item>
                <Item xs={6}>
                  <TextField
                    variant="outlined"
                    name="superAdminPhone"
                    label="Super Admin Phone"
                    required={true}
                    value={sendData.superAdminPhone}
                    onChange={(e) => handleStateChange('superAdminPhone', e.target.value)}
                    type="number"
                    fullWidth
                  />
                </Item>
                <Item xs={6}>
                  <TextField
                    variant="outlined"
                    name="superAdminEmail"
                    label="Super Admin Email"
                    required={true}
                    value={sendData.superAdminEmail}
                    onChange={(e) => handleStateChange('superAdminEmail', e.target.value)}
                    type="email"
                    fullWidth
                  />
                </Item>
              </Item>
            </>
          )}

          <Item>
            {flow !== 'viewDetails' ? (
              <>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
                <Button sx={{ ml: 1 }} onClick={handleClose} variant="contained" color="primary">
                  Close
                </Button>
              </>
            ) : (
              <Button sx={{ ml: 1 }} onClick={handleClose} variant="contained" color="primary">
                Close
              </Button>
            )}
          </Item>
        </Item>
      </form>
    </Container>
  );
};

export default withStyles(styles)(SuperAdminDashboard);
