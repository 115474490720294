import React, { useState, useEffect, useRef } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import { API_GET, API_HANDLE_ERROR, API_PATCH, PRE_SEND_URL } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import theme from '../../utils/theme';
import {
  isValidPincode,
  emailValidate,
  mobileNumberValidate,
  specialCharacterNotAllowed
} from '../../utils/formValidation';
import axios from 'axios';
import moment from 'moment';
import { GetAllCity, GetAllCountry, GetAllState } from '../../utils/getCountryStateCity';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import Chip from '@mui/material/Chip';

const { withStyles, Grid, Typography, Paper, TextField, Button, Autocomplete } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const currentDate = moment(new Date()).format('YYYY-MM-DD');

const currencyArray = [
  'India (INR)',
  'US dollar (USD)',
  'Euro (EUR)',
  'Japanese yen (JPY)',
  'Pound sterling (GBP)',
  'Australian dollar (AUD)',
  'Canadian dollar (CAD)',
  'Swiss franc (CHF)',
  'Chinese renminbi (CNH)',
  'Hong Kong dollar (HKD)',
  'New Zealand dollar (NZD)1'
];

const CompanyProfileDetail = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [isEdit, setIsEdit] = useState(false);
  const [revenueTotal, setRevenueTotal] = useState(0);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const hiddenDocumentInput = useRef(null);

  const [departmentArray, setDepartmentArray] = useState([]);
  const [sendData, setSendData] = useState({
    name: '',
    emailId: '',
    website: '',
    address: '',
    state: { name: '' },
    country: { name: '' },
    pincode: '',
    phone: '',
    city: '',
    logo: [],
    fiscalYearBegin: '',
    fiscalYearEnd: '',
    totalRevenue: '',
    Gst: '',
    panId: '',
    currency: '',
    latitude: '',
    longitude: '',
    companyImage: '',
    countryCode: '',
    stateCode: '',
    department: []
    // startDate: moment(new Date()).format('YYYY-MM-DD')
  });

  const getAllDepartments = () => {
    API_GET('department/getDepartments')
      .then((res) => {
        setDepartmentArray(res?.found);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllDepartments();
  }, []);

  const handleStateChange = (key, value) => {
    if (key === 'phone') {
      if (value < 0) {
        setSendData({ ...sendData, phone: '' });
      } else if (value.length <= 10) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'pincode') {
      if (value < 0) {
        setSendData({ ...sendData, pincode: '' });
      } else if (value.length <= 6) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'fiscalYearBegin') {
      if (new Date(value) > new Date(sendData?.fiscalYearEnd)) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Fiscal year Begin should be less than Fiscal year End'
        });
        setSendData({ ...sendData, fiscalYearBegin: currentDate });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'fiscalYearEnd') {
      if (new Date(value) < new Date(sendData?.fiscalYearBegin)) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Fiscal year End should be greather than Fiscal year Begin'
        });
        setSendData({ ...sendData, fiscalYearEnd: currentDate });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'Gst') {
      if (value !== '' && !specialCharacterNotAllowed(value)) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Special characters not allowed'
        });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'panId') {
      if (value !== '' && !specialCharacterNotAllowed(value)) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Special characters not allowed'
        });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else {
      setSendData((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  // get corporate Date

  useEffect(() => {
    API_GET('corporate/getCorporate')
      .then((res) => {
        setSendData({
          ...sendData,
          name: res?.data?.name,
          emailId: res?.data?.emailId,
          website: res?.data?.website,
          address: res?.data?.address,
          state: { name: res?.data?.state },
          country: { name: res?.data?.country },
          countryCode: res?.data?.countryCode,
          stateCode: res?.data?.stateCode,
          city: res?.data?.city,
          pincode: res?.data?.pincode,
          logo: [res?.data?.logo],
          phone: res?.data?.phone,
          panId: res?.data?.panNumber,
          Gst: res?.data?.gstNumber,
          fiscalYearBegin: moment(res?.data?.fiscalYearBegin).format('YYYY-MM-DD'),
          fiscalYearEnd: moment(res?.data?.fiscalYearEnd).format('YYYY-MM-DD'),
          currency: res?.data?.currency,
          latitude: res?.data?.location?.coordinates && res?.data?.location?.coordinates[1],
          longitude: res?.data?.location?.coordinates && res?.data?.location?.coordinates[0],
          companyImage: res?.data?.companyImage,
          totalRevenue: res?.data?.totalRevenue,
          department: res?.departments
        });
        setRevenueTotal(res?.data?.totalRevenue);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, isEdit]);

  useEffect(() => {
    const resultState = GetAllState(sendData?.countryCode);
    setStateData(resultState);

    const resultCity = GetAllCity(sendData?.countryCode, sendData?.stateCode);
    setCityData(resultCity);
  }, [sendData?.country, sendData?.state]);

  // upload image

  const handleInputClick = (item) => {
    hiddenDocumentInput.current.click();
  };

  const precenturl = async (type, name) => {
    try {
      const data = await PRE_SEND_URL('user/incident/preSignedMediaUrl', {
        fileType: type,
        fileName: name.split('.')[0],
        featureType: 'doctorProfileImage'
      });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleimage = async (status, img) => {
    const arrayImage = [];

    for (let i = 0; i < img.length; i++) {
      const docsType = img[i]?.type?.split('/');
      const name = img[i]?.name;
      const url = await precenturl(docsType[1], name);
      const file = img[i];

      const config = {
        method: 'put',
        url: url.signedUrl,
        headers: {
          'x-ms-blob-content-type': img[i].type,
          'content-type': 'application/octet-stream',
          'x-ms-blob-type': 'BlockBlob'
        },
        data: file
      };

      axios(config)
        .then(function (response) {
          arrayImage.push(url.mediaUrl);
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: response?.data?.message || 'upload Successfully'
          });
        })
        .catch(function (error) {
          API_HANDLE_ERROR(error, dispatch);
        });
    }
    handleStateChange('logo', arrayImage);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    const postData = {
      name: sendData.name,
      emailId: sendData.emailId,
      website: sendData.website,
      address: sendData.address,
      state: sendData.state?.name || '',
      country: sendData?.country?.name || '',
      city: sendData.city,
      pincode: sendData.pincode,
      logo: sendData?.logo && sendData.logo[0],
      phone: sendData.phone,
      panNumber: sendData?.panId,
      gstNumber: sendData?.Gst,
      fiscalYearBegin: sendData.fiscalYearBegin,
      fiscalYearEnd: sendData.fiscalYearEnd,
      currency: sendData.currency,
      totalRevenue: sendData?.totalRevenue,
      countryCode: sendData?.countryCode,
      stateCode: sendData?.stateCode,
      companyImage: sendData?.companyImage && sendData?.companyImage[0],
      location: {
        type: 'Point',
        coordinates: [sendData?.longitude, sendData?.latitude]
      },
      departmentArr: sendData?.department
    };
    if (emailValidate(sendData?.emailId) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct email'
      });
    } else if (mobileNumberValidate(sendData?.phone) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct mobile number'
      });
    } else if (isValidPincode(sendData?.pincode) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct pincode'
      });
    } else {
      API_PATCH(
        `corporate/updateCorporate?calculateRevenue=${revenueTotal !== sendData?.totalRevenue}`,
        postData
      )
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          setIsEdit(false);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };
  console.log('sendData', sendData);

  const renderObj = {
    'Company Name': ConvertToUpperCase(sendData?.name),
    'Email Id': sendData?.emailId,
    WebSite: sendData?.website,
    Address: ConvertToUpperCase(sendData?.address),
    Country: ConvertToUpperCase(sendData?.country?.name),
    State: ConvertToUpperCase(sendData?.state?.name),
    City: ConvertToUpperCase(sendData?.city),
    Pincode: sendData?.pincode,
    'Phone Number': sendData?.phone,
    'Pan Number': sendData?.panId,
    'GST Number': sendData?.Gst,
    'Fiscal Year Start':
      sendData?.fiscalYearBegin === 'Invalid date' ? '-' : sendData?.fiscalYearBegin,
    'Fiscal Year End': sendData?.fiscalYearEnd === 'Invalid date' ? '-' : sendData?.fiscalYearEnd,
    Currency: sendData?.currency,
    'Total Revenue': revenueTotal
  };

  const handleRemove = (option) => {
    option &&
      API_GET(`department/checkUserBeforeDelete?type=department&departmentId=${option}`)
        .then((res) => {
          if (res?.success) {
            const result = sendData?.department?.filter((item) => {
              return item?._id !== option;
            });
            setSendData({ ...sendData, department: result });
          } else {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Hi, before deletion, please re-assign the employees related to this branch.'
            });
          }
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  const countryData = GetAllCountry();
  const str = theme.palette.primary.main;
  const result = str.slice(1);

  return (
    <Container>
      <Item md={12} sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
        <img
          src={require(`../../Assets/settingsIcon/${result}.svg`).default}
          alt="icon"
          style={{ width: '100%' }}
        />
        <Item md={12} xs={12} sx={{ position: 'absolute', top: '100px' }}>
          <Paper
            sx={{
              ...theme.card,
              minHeight: '70vh',
              width: '75vw',
              display: 'flex',
              justifyContent: 'center',
              mb: 2
            }}>
            <Item
              sx={{
                position: 'absolute',
                right: 0,
                mt: 2,
                mr: 2
              }}>
              {!isEdit && (
                <Button sx={theme.button.cancel} onClick={handleEdit}>
                  Edit
                </Button>
              )}
            </Item>

            <Item
              sx={{
                position: 'absolute',
                m: 'auto',
                marginTop: '-70px'
              }}>
              {isEdit && (
                <Typography sx={{ ...theme.typography.tableHeading, mb: 1, p: 0, ml: -1 }}>
                  Your Logo
                </Typography>
              )}

              {!isEdit ? (
                <img src={sendData?.logo?.[0]} alt="profile" className={classes.imgProfile} />
              ) : (
                <Item>
                  {sendData?.logo?.[0] !== '' ? (
                    <Item onClick={() => handleInputClick()} sx={{ cursor: 'pointer' }}>
                      <img src={sendData?.logo?.[0]} alt="profile" className={classes.imgProfile} />
                      <Typography
                        sx={{
                          color: theme.typography.tableHeading.color,
                          fontSize: '12px',
                          textAlign: 'center',
                          mt: 1,
                          ml: -1
                        }}>
                        Upload your logo
                      </Typography>
                    </Item>
                  ) : (
                    <Item
                      className={classes.imageContain}
                      sx={{ flexDirection: 'column' }}
                      onClick={() => handleInputClick()}>
                      <React.Fragment>
                        <img
                          style={{ height: '35px', width: '35px' }}
                          src={require(`../../Assets/settingsIcon/profileUpload.svg`).default}
                          alt="icon"
                        />

                        <Typography
                          sx={{
                            color: theme.typography.tableHeading.color,
                            fontSize: '15px',
                            textAlign: 'center',
                            mt: 1
                          }}>
                          Upload your logo
                        </Typography>
                      </React.Fragment>
                    </Item>
                  )}
                  <input
                    ref={hiddenDocumentInput}
                    accept=".jpeg, .jpg, .png"
                    type="file"
                    multiple
                    size="small"
                    onChange={(e) => handleimage('logo', e.target.files)}
                    style={{ display: 'none' }}
                  />
                </Item>
              )}
            </Item>
            <form onSubmit={onHandleConfirm}>
              {isEdit ? (
                <Item className={classes.inputContainer} md={12} xs={12} mt={isEdit ? 7 : 4}>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Name</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="name"
                        placeholder="Please enter Company Name"
                        required={true}
                        size="small"
                        value={sendData.name}
                        onChange={(e) => handleStateChange('name', e.target.value)}
                        type="text"
                        fullWidth
                      />
                    </Item>
                  </Item>

                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Phone</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="phone"
                        placeholder="Please enter Company Phone"
                        size="small"
                        onBlur={(e) => {
                          if (
                            e.target.value !== '' &&
                            mobileNumberValidate(sendData?.phone) === false
                          ) {
                            dispatch({
                              type: 'ALERT_OPEN',
                              severity: 'error',
                              isOpen: true,
                              label: 'Please enter correct phone number'
                            });
                          }
                        }}
                        required={true}
                        value={sendData.phone}
                        onChange={(e) => handleStateChange('phone', e.target.value)}
                        type="number"
                        fullWidth
                      />
                    </Item>
                  </Item>

                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Email</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="emailId"
                        placeholder="Please enter email"
                        required={true}
                        size="small"
                        onBlur={(e) => {
                          if (e.target.value !== '' && emailValidate(sendData?.emailId) === false) {
                            dispatch({
                              type: 'ALERT_OPEN',
                              severity: 'error',
                              isOpen: true,
                              label: 'Email is not valid'
                            });
                          }
                        }}
                        value={sendData.emailId}
                        onChange={(e) => handleStateChange('emailId', e.target.value)}
                        type="email"
                        fullWidth
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Website</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="website"
                        placeholder="Please enter website"
                        size="small"
                        required={true}
                        value={sendData.website}
                        onChange={(e) => handleStateChange('website', e.target.value)}
                        type="text"
                        fullWidth
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Address</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="address"
                        placeholder="Please enter Company Address"
                        size="small"
                        required={true}
                        value={sendData.address}
                        onChange={(e) => handleStateChange('address', e.target.value)}
                        type="text"
                        fullWidth
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Country</Typography>
                    <Item mt={0.8}>
                      <Autocomplete
                        id="combo-box-demo"
                        onChange={(e, value) => {
                          if (value === null) {
                            handleStateChange('country', '');
                          }
                          setSendData({
                            ...sendData,
                            countryCode: value?.countryCode
                          });

                          handleStateChange('country', value);
                        }}
                        options={countryData}
                        size="small"
                        value={sendData?.country}
                        color="#134CDF"
                        getOptionLabel={(option) => {
                          return option?.name;
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="Country" variant="outlined" type="text" />
                        )}
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>State</Typography>
                    <Item mt={0.8}>
                      <Autocomplete
                        id="combo-box-demo"
                        onChange={(e, value) => {
                          if (value === null) {
                            handleStateChange('state', '');
                          }
                          setSendData({ ...sendData, stateCode: value?.stateCode });
                          handleStateChange('state', value);
                        }}
                        options={stateData}
                        size="small"
                        value={sendData?.state}
                        color="#134CDF"
                        getOptionLabel={(option) => {
                          return option?.name;
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="Country" variant="outlined" type="text" />
                        )}
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>City</Typography>
                    <Item mt={0.8}>
                      <Autocomplete
                        id="combo-box-demo"
                        onChange={(e, value) => {
                          if (value === null) {
                            handleStateChange('city', '');
                          }
                          handleStateChange('city', value);
                        }}
                        options={cityData}
                        size="small"
                        value={sendData?.city}
                        color="#134CDF"
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="Country" variant="outlined" type="text" />
                        )}
                      />
                    </Item>
                  </Item>

                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Pincode</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="pincode"
                        // label="Pincode"
                        size="small"
                        onBlur={(e) => {
                          if (
                            e.target.value !== '' &&
                            isValidPincode(sendData?.pincode) === false
                          ) {
                            dispatch({
                              type: 'ALERT_OPEN',
                              severity: 'error',
                              isOpen: true,
                              label: 'Pincode is not valid'
                            });
                          }
                        }}
                        required={true}
                        value={sendData.pincode}
                        onChange={(e) => handleStateChange('pincode', e.target.value)}
                        type="number"
                        fullWidth
                      />
                    </Item>
                  </Item>

                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>GST Number</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="Gst"
                        // label="GST"
                        value={sendData.Gst}
                        size="small"
                        onChange={(e) => {
                          if (e.target.value.length <= 20) {
                            handleStateChange('Gst', e.target.value);
                          }
                        }}
                        type="text"
                        fullWidth
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>PAN Number</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="panId"
                        value={sendData.panId}
                        size="small"
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            handleStateChange('panId', e.target.value);
                          }
                        }}
                        type="text"
                        fullWidth
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Fiscal Year Begin</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="fiscalYearBegin"
                        value={sendData.fiscalYearBegin}
                        size="small"
                        onChange={(e) => handleStateChange('fiscalYearBegin', e.target.value)}
                        type="date"
                        fullWidth
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Fiscal Year End</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="fiscalYearEnd"
                        value={sendData.fiscalYearEnd}
                        size="small"
                        onChange={(e) => handleStateChange('fiscalYearEnd', e.target.value)}
                        type="date"
                        fullWidth
                      />
                    </Item>
                  </Item>

                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Latitude</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="latitude"
                        value={sendData.latitude}
                        size="small"
                        onChange={(e) => {
                          if (e.target.value > 0) {
                            handleStateChange('latitude', e.target.value);
                          } else {
                            handleStateChange('latitude', '');
                          }
                        }}
                        type="number"
                        fullWidth
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Longitude</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="longitude"
                        value={sendData.longitude}
                        size="small"
                        onChange={(e) => {
                          if (e.target.value > 0) {
                            handleStateChange('longitude', e.target.value);
                          } else {
                            handleStateChange('longitude', '');
                          }
                        }}
                        type="number"
                        fullWidth
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Total Revenue</Typography>
                    <Item mt={0.8}>
                      <TextField
                        variant="outlined"
                        name="longitude"
                        value={sendData.totalRevenue}
                        size="small"
                        onChange={(e) => {
                          if (e.target.value > 0) {
                            handleStateChange('totalRevenue', e.target.value);
                          } else {
                            handleStateChange('totalRevenue', '');
                          }
                        }}
                        type="number"
                        fullWidth
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8} mt={3}>
                    <Typography className={classes.inputText}>Currency</Typography>
                    <Item mt={0.8}>
                      <Autocomplete
                        id="combo-box-demo"
                        onChange={(e, value) => {
                          if (value === null) {
                            handleStateChange('currency', '');
                          }
                          handleStateChange('currency', value);
                        }}
                        options={currencyArray}
                        size="small"
                        value={sendData?.currency}
                        color="#134CDF"
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField {...params} name="currency" variant="outlined" type="text" />
                        )}
                      />
                    </Item>
                  </Item>
                  <Item md={5.8} xs={12} lg={3.8}></Item>

                  <Item md={12} xs={12} lg={12} mt={3}>
                    <Typography className={classes.inputText}>Department</Typography>
                    <Autocomplete
                      multiple
                      id="combo-box-demo"
                      limitTags={5}
                      size="small"
                      value={sendData?.department}
                      onChange={(e, value) => {
                        handleStateChange('department', value);
                      }}
                      getOptionSelected={(option, value) => option?.department === value}
                      disableCloseOnSelect={sendData?.scale === 'department'}
                      options={departmentArray}
                      disableClearable
                      shouldItemRender={(item, value) => {
                        return !sendData?.department?.includes(item);
                      }}
                      getOptionLabel={(option) => option?.department}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option?.department}
                            {...getTagProps({ index })}
                            onDelete={() => handleRemove(option?._id)}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} name="city" variant="outlined" fullWidth />
                      )}
                    />
                  </Item>
                </Item>
              ) : (
                <Item className={classes.inputContainer} md={12} xs={12} mt={4}>
                  {Object.keys(renderObj).map((item, index) => {
                    return (
                      <Item md={3.8} xs={12} mt={3}>
                        <Typography className={classes.inputText}>{item}</Typography>
                        <Typography className={classes.inputText1}>{renderObj[item]}</Typography>
                      </Item>
                    );
                  })}

                  <Item md={12} lg={12} mt={1}>
                    <Typography className={classes.inputText}>Departments</Typography>
                    <Typography className={classes.inputText1}>
                      {sendData?.department
                        ?.map((item) => {
                          return ConvertToUpperCase(item?.department);
                        })
                        .join(', ')}
                    </Typography>
                  </Item>
                </Item>
              )}

              <Item sx={{ display: 'flex' }} mt={2}>
                {isEdit && (
                  <Item
                    sx={{
                      display: 'inline-flex',
                      ml: 'auto',
                      gap: '20px',
                      p: theme.spacing(2, 5)
                    }}>
                    <Item>
                      <Button
                        onClick={() => setIsEdit(false)}
                        sx={{
                          ...theme.button.cancel
                        }}>
                        Cancel
                      </Button>
                    </Item>
                    <Item>
                      <Button
                        type="submit"
                        style={{
                          ...theme.button.submit,
                          backgroundColor: theme.palette.primary.main
                        }}>
                        {`Update`}
                      </Button>
                    </Item>
                  </Item>
                )}
              </Item>
            </form>
          </Paper>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(CompanyProfileDetail);
