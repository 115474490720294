import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../../utils/store';
import { Components, Icons } from '../../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR, API_DELETE } from '../../../../../utils/api';
// import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import tableColor from '../../../../../utils/tableColor';
import theme from '../../../../../utils/theme';
import Dialog from '../../../../atom/Dialog';
import BroadcastSentToModal from '../../../Modal/BroadcastModels/BroadcastSentToModal';
import BroadcastGraphModal from '../../../Modal/BroadcastModels/BroadcastGraphModal';
import BroadcastDeleteConfirmation from '../../../Modal/BroadcastModels/BroadcastDeleteConfirmation';
import moment from 'moment';
import { getDateTime, getDate } from '../../../../../utils/moment';
import downloadDataToExcelFile from '../../../../../utils/downloadDataToExcelFile';
import ScheduledEdit from '../../../Modal/BroadcastModels/ScheduledEdit';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { ApiDates } from '../../../../../utils/ApiDates';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell
} = Components;

const { DownloadForOfflineIcon, InfoIcon, EditIcon } = Icons;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const ActiveBroadcastTable = ({
  classes,
  searchText,
  filtersCategory,
  handleTotalBroadcast,
  selectedFromDate,
  selectedToDate
}) => {
  const [, dispatch] = useStateValue();
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalBroadcast, setTotalBroadcast] = React.useState(0);
  const [isOpenSentToModal, setIsOpenSentToModal] = useState(false);
  const [isOpenGraphModal, setIsOpenGraphModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [sentToData, setSentToData] = useState({});
  const [pieChartData, setPieChartData] = useState([]);
  const [isOpenActiveModelOpen, setIsOpenActiveModelOpen] = useState(false);
  const [activeData, setActiveData] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // to reset page on search
  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllActiveBroadcast();
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchText,
    isOpenActiveModelOpen,
    filtersCategory,
    selectedFromDate,
    selectedToDate
  ]);

  const getAllActiveBroadcast = () => {
    let result = '';
    for (let i = 0; i < filtersCategory?.length; i++) {
      if (filtersCategory[i]?.type === 'Sent via') {
        result += `&mediumFilter[]=${filtersCategory[i]?.name}`;
      }
      if (filtersCategory[i]?.type === 'Status') {
        result += `&statusFilter[]=${filtersCategory[i]?.name}`;
      }
      if (filtersCategory[i]?.type === 'Sent By') {
        result += `&sentByFilter[]=${filtersCategory[i]?.name}`;
      }
    }

    const dates = ApiDates(selectedFromDate, selectedToDate);

    API_GET(
      `broadCastMessage/getBroadCasts?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}${result}${dates}`
    )
      .then((res) => {
        handleTotalBroadcast(res?.count);
        setRows(res?.broadCastMessages);
        setTotalBroadcast(res?.count);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleOpenBroadcastSentToModal = (row) => {
    setIsOpenSentToModal(true);
    setSentToData(row);
  };
  const handleOpenBroadcastGraphModal = (piechartData) => {
    setIsOpenGraphModal(true);
    setPieChartData(piechartData);
  };
  const handleClose = () => {
    setIsOpenSentToModal(false);
    setIsOpenGraphModal(false);
    setIsOpenDeleteModal(false);
    setIsOpenActiveModelOpen(false);
  };

  const handleDelete = (deletedId) => {
    setIsOpenDeleteModal(true);
    setDeleteId(deletedId);
  };

  const handleEdit = (row) => {
    setIsOpenActiveModelOpen(true);
    setActiveData(row);
  };

  const onHandleDelete = (id) => {
    const currentDate = moment(moment().format('YYYY-MM-DD'));
    const endDate = moment(deleteId?.recurringEndDate);
    if (currentDate.diff(endDate) > 0) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Can not delete this broadcast'
      });
    } else {
      API_DELETE('broadCastMessage/deleteScheduledBroadCasts', {
        broadcastMessageIds: [id]
      })
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          setIsOpenDeleteModal(false);

          getAllActiveBroadcast();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  const handleSingleDownload = (data) => {
    const isRecurringPresent = 'isRecurring' in data;

    if (data?.isAnonymous) {
      API_GET(
        `broadCastMessage/downloadAnonymousBroadcast?broadcastMessageId=${data?._id}&isRecurring=${isRecurringPresent}`
      )
        .then((res) => {
          const result = Array.isArray(res?.data) ? res.data : [res?.data];

          const resultData = result?.map((item, index) => {
            const obj = {
              // 'Sno.': index + 1,
              Header: item?.header,
              Message: item?.message,
              'Sent By': item?.messageBy?.fullName
            };
            return item?.notifications?.map((noti, index) => {
              const userObj = {
                ...obj,
                Date: noti?.date ? getDateTime(noti?.date) : '',
                'No. of CTA 1 Response': noti?.userCtaArray?.[0]?.resCount || 0,
                'No. of CTA 2 Response': noti?.userCtaArray?.[1]?.resCount || 0,
                'No. of CTA 3 Response': noti?.userCtaArray?.[2]?.resCount || 0,
                'No. of NO Response': noti?.userNoResponse
              };

              return userObj;
            });
          });

          const resultFamily = result?.map((item, index) => {
            const obj = {
              // 'Sno.': index + 1,
              Header: item?.header,
              Message: item?.message,
              'Sent By': item?.messageBy?.fullName
            };
            return item?.notifications?.map((noti, index) => {
              const userObj = {
                ...obj,
                Date: noti?.date ? getDateTime(noti?.date) : '',
                'No. of CTA 1 Response': noti?.familyMemberCtaArray?.[0]?.resCount || 0,
                'No. of CTA 2 Response': noti?.familyMemberCtaArray?.[1]?.resCount || 0,
                'No. of CTA 3 Response': noti?.familyMemberCtaArray?.[2]?.resCount || 0,
                'No. of NO Response': noti?.familyMemberNoResponse
              };

              return userObj;
            });
          });

          if (data?.includeFamilyMembers) {
            downloadDataToExcelFile(resultData.length > 0 && resultData[0], `Broadcast details`);
            downloadDataToExcelFile(
              resultFamily.length > 0 && resultFamily[0],
              `Broadcast  family details`
            );
          } else {
            downloadDataToExcelFile(resultData.length > 0 && resultData[0], `Broadcast details`);
          }
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      API_GET(
        `broadCastMessage/downloadBroadcast?broadcastMessageId=${data?._id}&isRecurring=${isRecurringPresent}`
      )
        .then((res) => {
          const resultData = res?.data?.map((item, index) => {
            return item?.users?.map((user) => {
              return {
                // 'Sno.': index + 1,
                Date: getDate(item?.date),
                'Employee ID': user?.employeeId || '',
                'Agent ID': user?.agentId || '',
                // 'Sent By': 'jayesh',
                Name: user?.fullName,
                'Office Name': user?.officeId?.name,
                'Office location': user?.officeId?.city,
                'Current City': user?.currentAddress?.city,
                Email: user?.email,
                'Phone Number': user?.mobileNumber,
                Header: user?.header,
                Message: user?.message,
                'CTA Response': user?.response?.ctaName || 'No Response'
              };
            });
          });

          const resultFamilyData = res?.data?.map((item, index) => {
            return item?.familyMembers?.map((user) => {
              return {
                // 'Sno.': '24-8-2022',
                Date: getDate(item?.date),
                'Family Member': user?.fullName,
                'Contact Number': user?.mobileNumber,
                'Refrance User ': user?.referanceUser?.fullName,
                'Refrance Contact Number': user?.referanceUser?.mobileNumber,
                'Ref Employee ID': user?.referanceUser?.employeeId || '',
                'Ref Agent ID': user?.referanceUser?.agentId,
                Header: user?.header,
                Message: user?.message,
                'CTA Response': user?.response?.ctaName || 'No Response'
              };
            });
          });

          if (data?.includeFamilyMembers) {
            downloadDataToExcelFile(resultData?.length > 0 && resultData?.[0], `Broadcast details`);
            downloadDataToExcelFile(
              resultFamilyData?.length > 0 && resultFamilyData?.[0],
              `Broadcast details`
            );
          } else {
            downloadDataToExcelFile(resultData?.length > 0 && resultData?.[0], `Broadcast details`);
          }
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.root} sx={theme.tableBorder}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.thead}>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {'No Records found'}
                  </TableCell>
                </TableRow>
              )}
              {rows &&
                rows?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      style={{ backgroundColor: tableColor(index) }}>
                      {columns &&
                        columns.map((column, index) => {
                          const value = row[column.id] ?? '--';

                          if (column.id === 'sentTo') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <InfoIcon
                                  onClick={() => handleOpenBroadcastSentToModal(row)}
                                  style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                />
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'createdAt') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.broadcastDateTime ? getDateTime(row?.broadcastDateTime) : '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'sentVia') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.medium?.join(', ')}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'status') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {'isRecurring' in row ? (
                                  <Item className={classes.recurring}>Recurring</Item>
                                ) : (
                                  <Item className={classes.oneTime}>One Time</Item>
                                )}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'response') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.pieChart?.length > 0 ? (
                                  <Item
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleOpenBroadcastGraphModal(row?.pieChart)}>
                                    <img
                                      style={{ height: '25px', width: '25px' }}
                                      src={
                                        require(`../../../../../Assets/broadcast/graphIcon.svg`)
                                          .default
                                      }
                                      alt="Icon"
                                    />
                                  </Item>
                                ) : (
                                  '-'
                                )}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'action') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <Item
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}>
                                  {'isRecurring' in row && row?.canDelete && (
                                    <Item sx={{ width: '30px' }}>
                                      <EditIcon
                                        style={{ color: '#868686', cursor: 'pointer' }}
                                        onClick={() => handleEdit(row)}
                                      />
                                    </Item>
                                  )}
                                  <Item sx={{ width: '30px' }}>
                                    {row?.canDelete && (
                                      <CancelSharpIcon
                                        onClick={() => handleDelete(row)}
                                        style={{
                                          color: '#FF6760',
                                          cursor: 'pointer'
                                        }}
                                      />
                                    )}
                                  </Item>

                                  <Item sx={{ width: '30px' }}>
                                    <DownloadForOfflineIcon
                                      onClick={() => handleSingleDownload(row)}
                                      style={{
                                        color: theme.palette.primary.main,
                                        cursor: 'pointer'
                                      }}
                                    />
                                  </Item>
                                </Item>
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={totalBroadcast && totalBroadcast ? totalBroadcast : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          isOpen={isOpenSentToModal}
          children={
            <BroadcastSentToModal handleClose={handleClose} sentToData={sentToData} />
          }></Dialog>
        <Dialog
          isOpen={isOpenGraphModal}
          children={
            <BroadcastGraphModal handleClose={handleClose} pieChartData={pieChartData} />
          }></Dialog>
        <Dialog
          isOpen={isOpenDeleteModal}
          children={
            <BroadcastDeleteConfirmation
              handleClose={handleClose}
              deleteId={deleteId?._id}
              onHandleDelete={onHandleDelete}
            />
          }></Dialog>
        <Dialog
          isOpen={isOpenActiveModelOpen}
          children={
            <ScheduledEdit
              type="Active"
              getAllActiveBroadcast={getAllActiveBroadcast}
              handleClose={handleClose}
              data={activeData}
            />
          }></Dialog>
      </Card>
    </React.Fragment>
  );
};

export default withStyles(styles)(ActiveBroadcastTable);
