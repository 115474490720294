import React, { useState, useEffect } from 'react';
import IncidentCard from './IncidentCard';
import MapComponent from './MapComponent';
import { Components } from '../../utils/material-ui';
import { useStateValue } from '../../utils/store';
import { API_GET } from '../../utils/api';
import styles from './styles';
import Timer from '../../components/atom/Timer';
// import Map from './Map';

const { withStyles, Typography, Grid } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function OngoingIncidents({ classes }) {
  const [current, setCurrent] = useState(0);
  const [incidents, setIncidents] = useState({});
  const [store, dispatch] = useStateValue();

  useEffect(() => {
    API_GET(`report-incident/active`)
      .then((res) => {
        const incidentObj = {};
        res.data.incidents.forEach((incident) => {
          incidentObj[`${incident.id}`] = {
            ...incident,
            timer: <Timer date={incident.timer}></Timer>
          };
        });

        const key = Object.keys(incidentObj)[0];
        setCurrent(key);
        setIncidents(incidentObj);
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: err[0]?.message || 'Oops!!, Please try again'
        });
      });
  }, [dispatch]);

  useEffect(() => {
    store.socketIO.on(
      'initiated',
      ({
        incidentId,
        attributes,
        createdAt,
        corporateId,
        corporateName,
        gender,
        role,
        internalId,
        parentData,
        vehicleData,
        offlineSos
      }) => {
        if (store.role === 'sosAdmin') {
          const incident = {
            id: incidentId,
            userId: attributes.user._id,
            name: attributes.user.fullName,
            number: `${attributes.user.countryCode}${attributes.user.mobileNumber}`,
            location: attributes.location.city,
            address: attributes.location.address,
            recieved: createdAt,
            timer: <Timer date={createdAt}></Timer>,
            lat: attributes.location.location.coordinates[0],
            lng: attributes.location.location.coordinates[1],
            emergencyContacts: attributes.emergencyContacts,
            corporateName: corporateName,
            gender: gender,
            role,
            internalId,
            parentData,
            vehicleData,
            offlineSos
          };
          setIncidents((prevState) => {
            if (Object.keys(prevState).length === 0) {
              setCurrent(incidentId);
            }
            return { [incidentId]: incident, ...prevState };
          });
        }
      }
    );

    store.socketIO.on('incidentEnded', ({ incidentId }) => {
      setIncidents((prevState) => {
        delete prevState[`${incidentId}`];
        return { ...prevState };
      });
    });
  }, [store]);

  function handleIncidentCard(index) {
    setCurrent(index);
  }

  return (
    <Container className={classes.ongoingPage}>
      <Item className={classes.incidentContainer}>
        <Typography variant="h6" className={classes.incidentHeading}>
          Incident Queue
        </Typography>

        {Object.values(incidents).map((item, index) => {
          return (
            <div
              onClick={() => {
                handleIncidentCard(item.id);
              }}
              onKeyPress={() => {
                handleIncidentCard(item.id);
              }}
              role="button"
              tabIndex={index}
              key={index}
              className={item.id === current ? classes.clickbleIncidentCard : classes.container}>
              <IncidentCard data={item} index={index} key={item.id}></IncidentCard>
            </div>
          );
        })}
      </Item>

      <Item style={{ flex: 1 }}>
        <MapComponent data={incidents[`${current}`]}></MapComponent>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(OngoingIncidents);
