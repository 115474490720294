import React, { useState, useEffect } from 'react';
import styles from './styles';
import { Components } from '../../../utils/material-ui';
import moment from 'moment';
import { useStateValue } from '../../../utils/store';
import { requestForFirebaseToken, onMessageListener } from '../../../utils/firebase';
import theme from '../../../utils/theme';
import SafetyIssueTable from '../../../components/organism/Table/safetyIssueTable';
import SosUsages from './sosUsages';
import Map from './map';
import OverallIncidentDistribution from './overallIncidentDistribution';
import Cards from './Card';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import ExternalRiskAnalytics from './externalRiskAnalyatics';

const { withStyles, Grid, TextField, Paper, Card, Typography } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Home = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [isMoueHover, setIsMouseHover] = useState(false);
  const [riskCardType, setRiskCardType] = useState('');
  const [data, setData] = useState([]);
  const [hoverData, setHoverData] = useState({});

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > new Date(selectedToDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Must be smaller Than or Equal To To Date'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  useEffect(() => {
    timeout();
  }, []);

  const sss = () => {
    requestForFirebaseToken();
    onMessageListener();
  };

  const timeout = () => {
    setTimeout(sss, 5000);
  };

  const handleMouseHover = (value, type, data) => {
    setIsMouseHover(value);
    setRiskCardType(type);
    setHoverData(data);
  };

  useEffect(() => {
    API_GET(`home/getDashboardCardsData?fromDate=${selectedFromDate}&toDate=${selectedToDate}`)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate]);

  const RiskCard = (data) => {
    return (
      <Paper
        sx={{
          ...theme.card1,
          backgroundColor: data?.backgroundColor,
          width: '250px',
          p: 1
        }}>
        <Typography sx={{ color: data?.color, fontSize: '14px', fontWeight: '600' }}>
          {data?.name}
        </Typography>
        {data?.data?.map((item, index) => {
          return (
            <Item sx={{ display: 'flex' }} className={classes.heading1} key={index}>
              <Item className={classes.motionContainer}>
                <div
                  style={{
                    height: '8px',
                    width: '8px',
                    backgroundColor: data?.color,
                    borderRadius: '10px',
                    marginRight: '5px'
                  }}></div>
                <Typography sx={{ fontSize: '13px' }}>{item?.name}</Typography>
              </Item>
              <Typography sx={{ fontSize: '14px', color: data?.color }}>{item?.value}</Typography>
            </Item>
          );
        })}
      </Paper>
    );
  };

  const SafeCard = (data) => {
    return (
      <Paper
        sx={{
          ...theme.card1,
          backgroundColor: data?.backgroundColor,
          width: '250px',
          p: 1
          // border: `1px solid ${data?.color}`
        }}>
        <Typography sx={{ color: data?.color, fontSize: '14px', fontWeight: '600' }}>
          {data?.name}
        </Typography>
        {data?.data?.map((item) => {
          return (
            <Item sx={{ display: 'flex' }} className={classes.heading1}>
              <Item className={classes.motionContainer}>
                <div
                  style={{
                    height: '8px',
                    width: '8px',
                    backgroundColor: data?.color,
                    borderRadius: '10px',
                    marginRight: '5px'
                  }}></div>
                <Typography sx={{ fontSize: '13px' }}>{item?.name}</Typography>
              </Item>
              <Typography sx={{ fontSize: '14px', color: data?.color }}>{item?.value}</Typography>
            </Item>
          );
        })}
      </Paper>
    );
  };

  const CardShow = (riskCardType) => {
    if (riskCardType === 'Total Risk') {
      return (
        <Item
          sx={{
            position: 'absolute',
            zIndex: 1
          }}>
          {RiskCard(hoverData)}
        </Item>
      );
    }
    if (riskCardType === 'Total Incident') {
      return (
        <Item
          sx={{
            position: 'absolute',
            top: '120px',
            zIndex: 1
          }}>
          {RiskCard(hoverData)}
        </Item>
      );
    }
    if (riskCardType === 'Unsafe Employees') {
      return (
        <Item
          sx={{
            position: 'absolute',
            top: '230px',
            zIndex: 1
          }}>
          {SafeCard(hoverData)}
        </Item>
      );
    }
    if (riskCardType === 'Revenue Saved') {
      return (
        <Item
          sx={{
            position: 'absolute',
            top: '330px',
            zIndex: 1
          }}>
          {SafeCard(hoverData)}
        </Item>
      );
    }
  };

  return (
    <Container direction="column" className={classes.mainContainer}>
      <Item md={12} sx={{ display: 'flex' }}>
        <Item className={classes.dateContainer} sx={{ ml: 'auto' }}>
          <Item>
            <TextField
              id="date"
              label="From Date"
              variant="outlined"
              type="date"
              size="small"
              fullWidth
              value={selectedFromDate}
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => {
                onFromDateChange(e);
              }}
            />
          </Item>
          <Item>
            <TextField
              id="date"
              label="To Date"
              type="date"
              size="small"
              fullWidth
              variant="outlined"
              value={selectedToDate}
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => {
                onToDateChange(e);
              }}
            />
          </Item>
        </Item>
      </Item>
      <Container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
        <Item md={2.5} lg={2.5} xs={12}>
          {data?.map((item, index) => {
            return (
              <Item md={12} xs={12} lg={12} mt={index !== 0 && 2} key={index}>
                <Cards data={item} handleMouseHover={handleMouseHover} isMoueHover={isMoueHover} />
              </Item>
            );
          })}
        </Item>

        <Item md={6} lg={6} xs={12} sx={{ position: 'relative' }}>
          <Card
            sx={{
              height: '415px',
              ...theme.card1
            }}>
            {isMoueHover && CardShow(riskCardType)}
            <Item sx={{ opacity: isMoueHover ? 0.6 : 2, height: '415px' }}>
              <Map selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} />
            </Item>
          </Card>
        </Item>
        <Item md={3.5} lg={3.5} xs={12}>
          <Item md={12} xs={12} lg={12}>
            <Paper
              sx={{
                height: '200px',
                ...theme.card1
              }}>
              <ExternalRiskAnalytics
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
              />
            </Paper>
          </Item>
          <Item md={12} xs={12} lg={12} mt={2}>
            <Paper
              sx={{
                height: '200px',
                ...theme.card1
              }}>
              <SosUsages selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} />
            </Paper>
          </Item>
        </Item>
      </Container>
      <Container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.1 }}>
        <Item md={6} lg={8.5} xs={12}>
          <Card sx={{ ...theme.card1, zIndex: 1 }}>
            <SafetyIssueTable selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} />
          </Card>
        </Item>

        <Item md={6} lg={3.5} xs={12}>
          <Paper
            sx={{
              height: '420px',
              ...theme.card1
            }}>
            <OverallIncidentDistribution
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
            />
          </Paper>
        </Item>
      </Container>
    </Container>
  );
};

export default withStyles(styles)(Home);
