import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';
import CardConatiner from './card';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';

const { Grid, withStyles, Paper, Typography, TextField, useMediaQuery, InputAdornment } =
  Components;
const { ArrowBackIosIcon, ArrowForwardIosIcon, SearchIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const hospitalTaging = [
  'ICU',
  'Orthopaedics',
  'Cardiology',
  'Gynaecology',
  'Radiology',
  'Neurology',
  'Trauma and Casuality',
  'CT scan',
  'MRI'
];

const latitude = JSON.parse(localStorage.getItem('lat'));
const longitude = JSON.parse(localStorage.getItem('lng'));

const IndiviualLocation = ({ classes, Id, handleClose, categoryList }) => {
  const [, dispatch] = useStateValue();
  const [selectedCategoryId, setSelectCategoryId] = useState(Id?._id);
  const [selectedCategoryImage, setSelectCategoryImage] = useState(Id?.badgeIcon);
  const [catgorylist] = useState(categoryList);
  const [safeCenter, setSafeCenter] = useState([]);
  const [nearCenters, setNearCenters] = useState([]);
  const [inputvalue, setInputValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [placeId, setPlaceId] = useState('');
  const [hospitalSubCat, setHospitalSubCat] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(30);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCategoryName, setSelectedCategoryIimage] = useState(Id?.name);
  const [selectedColor, setSelectedColor] = useState(Id?.bgColor);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrrenSizeSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const showDiv = scrrenSizeSmall ? 4 : 8;

  const handlePreCarosal = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? catgorylist.length : prevIndex - 1));
  };

  const handleNextCarosal = () => {
    setCurrentIndex((prevIndex) => (prevIndex === catgorylist.length ? 0 : prevIndex + 1));
  };
  // console.log('mapData', mapData);

  const handleCategory = (item) => {
    setSelectedColor(item?.bgColor);
    setSelectedCategoryIimage(item?.name);
    setSelectCategoryId(item?._id);
    setSelectCategoryImage(item?.badgeIcon);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInputValue(searchText);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [searchText]);

  useEffect(() => {
    const result = hospitalTaging?.map((item) => {
      return {
        name: item,
        value: false
      };
    });
    setHospitalSubCat(result);
  }, []);

  // handle pagination

  const handlePre = () => {
    if (skip === 0) {
      setSkip(0);
    } else {
      setSkip(skip - 1);
    }
  };
  const handleNext = () => {
    setSkip(skip + 1);
  };

  // get actualDistance if current location avialble

  useEffect(() => {
    const resultcat = hospitalSubCat
      ?.filter((item) => {
        return item?.value !== false;
      })
      .map((item) => {
        return item?.name;
      });

    let str = '';
    for (let i = 0; i < resultcat.length; i++) {
      str += `&subFilter[]=${resultcat[i]}`;
    }

    const resultSubCat = selectedCategoryId === '61f3ece5b043e6f7806312d5' ? str : '';

    if (placeId) {
      API_GET(
        `safeHavens/getSafeCenterDetails?distance=5&id=${selectedCategoryId}&placeId=${placeId}&${resultSubCat}&skip=${
          skip * limit
        }&limit=${limit}`
      )
        .then((res) => {
          // setMapData(res?.safeCentersList);
          setSafeCenter(res?.safeCentersList);
          setTotalCount(res?.totalCount);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      const resultLat = latitude || 28.6139;
      const resultLng = longitude || 77.209;
      API_GET(
        `safeHavens/getSafeCenterDetails?distance=5&lat=${resultLat}&lng=${resultLng}&id=${selectedCategoryId}&${resultSubCat}&skip=${
          skip * limit
        }&limit=${limit}`
      )
        .then((res) => {
          setSafeCenter(res?.safeCentersList);
          setTotalCount(res?.totalCount);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  }, [selectedCategoryId, placeId, hospitalSubCat, limit, skip]);

  // get suggested news id

  const getNearLocation = () => {
    if (inputvalue) {
      API_GET(`safeHavens/getPlaceSuggestions?address=${inputvalue}`)
        .then((res) => {
          setNearCenters(res?.data?.predictions);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  useEffect(() => {
    getNearLocation();
  }, [inputvalue]);

  const handlePlace = (id, name) => {
    setPlaceId(id);
    setSkip(0);
    setInputValue('');
  };

  // add hospital tagging

  const handleAddTaging = (data, index) => {
    const result = hospitalSubCat?.map((item) => {
      return item?.name === data?.name ? { ...item, value: !data.value } : item;
    });

    setHospitalSubCat(result);
  };

  const selected = {
    fontWeight: '600',
    color: selectedColor,
    borderBottom: `3px solid ${selectedColor}`,
    padding: theme.spacing(0.5, 1),
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  };

  const unselected = {
    color: theme.typography.tableHeading.unSelect,
    cursor: 'pointer',
    display: 'flex',
    padding: theme.spacing(0.5, 1),
    justifyContent: 'center',
    borderBottom: '1px solid 	#E8E8E8'
  };

  const GetImage = (item) => {
    return selectedCategoryId === item?._id
      ? `${item.name?.split(' ').join('')}Color`
      : `${item.name?.split(' ').join('')}`;
  };
  return (
    <Container direction={'row'} className={classes.mainContainer}>
      <Item
        md={12}
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}
        mt={1}>
        {currentIndex !== 0 ? (
          <ArrowBackIosIcon
            onClick={handlePreCarosal}
            sx={{ color: theme.typography.tableHeading.color, cursor: 'pointer' }}
          />
        ) : (
          <ArrowBackIosIcon sx={{ color: '#9F9F9F' }} />
        )}
        <Item className={classes.carousalContainer}>
          {catgorylist?.slice(currentIndex, currentIndex + 8).map((item) => (
            <Item className={classes.locationCardContainer}>
              <Container
                onClick={() => handleCategory(item)}
                sx={selectedCategoryId === item._id ? selected : unselected}>
                <Item>
                  <Item sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <img
                      style={{ height: '17px', width: '17px' }}
                      src={require(`../../Assets/SafeLocation/${GetImage(item)}.svg`).default}
                      alt="icon"
                    />
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: selectedCategoryId === item._id ? '600' : '500'
                      }}>
                      {item?.name}
                    </Typography>
                  </Item>
                </Item>
              </Container>
            </Item>
          ))}
        </Item>
        {currentIndex !== showDiv ? (
          <ArrowForwardIosIcon
            onClick={handleNextCarosal}
            sx={{ color: theme.typography.tableHeading.color, cursor: 'pointer' }}
          />
        ) : (
          <ArrowForwardIosIcon sx={{ color: '#9F9F9F' }} />
        )}
      </Item>

      {selectedCategoryId === '61f3ece5b043e6f7806312d5' && (
        <Item md={12} xs={12} mt={0.5} className={classes.tagingContainer}>
          {hospitalSubCat?.map((item, index) => {
            return (
              <Item
                key={index}
                className={classes.hospitalTag}
                onClick={() => handleAddTaging(item, index)}
                style={{
                  backgroundColor: item?.value ? '#73C2FB' : '#FFFFFF',
                  color: item?.value ? `#FFFFFF` : 'grey',
                  border: '1px solid lightgrey'
                }}>
                {`${item?.name}`}
              </Item>
            );
          })}
        </Item>
      )}

      <Item md={12} xs={12} mt={2}>
        <Item sx={{ display: 'flex', alignItems: 'center' }}>
          <Item md={4} xs={12}>
            <TextField
              placeholder="Search by Location"
              size="small"
              variant="outlined"
              fullWidth
              sx={theme.customTextFiled}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            {inputvalue !== '' && (
              <Paper
                elevation={5}
                style={{
                  width: '300px',
                  maxHeight: '290px',
                  position: 'absolute',
                  zIndex: 1,
                  marginTop: '10px',
                  marginLeft: '17px',
                  overflow: 'auto'
                }}>
                {nearCenters?.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      onClick={() =>
                        handlePlace(item?.place_id, item?.structured_formatting?.main_text)
                      }
                      sx={{
                        height: '50px',
                        width: '96%',
                        textAlign: 'left',
                        padding: '0px 5px',
                        marginTop: '5px',
                        border: '0.2px solid lightBlue',
                        cursor: 'pointer',
                        marginLeft: '1%',
                        borderRadius: '5px'
                      }}>
                      <Typography
                        style={{ fontSize: '14px', fontFamily: theme.palette.font.fontFamily }}>
                        {item?.structured_formatting?.main_text}
                      </Typography>
                    </Item>
                  );
                })}
              </Paper>
            )}
          </Item>

          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <button onClick={() => handleClose()} style={theme.button.submit}>{`Back`}</button>
          </Item>
        </Item>
        <Item className={classes.cardsContainer} md={12} xs={12}>
          {safeCenter?.map((item, index) => {
            return (
              <CardConatiner
                key={item?._id}
                data={item}
                index={index}
                selectedCategoryName={selectedCategoryName}
                selectedCategoryImage={selectedCategoryImage}
                selectedColor={selectedColor}
              />
            );
          })}
        </Item>
        <Item sx={{ display: 'flex', gap: '10px', m: 1, alignItems: 'center' }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            {totalCount === 0
              ? `${skip}-${0} of ${totalCount}`
              : `${skip * limit + 1}-${
                  skip * limit + limit >= totalCount ? totalCount : skip * limit + limit
                } of ${totalCount}`}
          </Item>
          <Item>
            {skip === 0 ? (
              <ArrowBackIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowBackIosIcon fontSize="10px" sx={{ cursor: 'pointer' }} onClick={handlePre} />
            )}
          </Item>
          <Item>
            {totalCount - skip * limit < 10 || (totalCount - skip * limit) % 10 === 0 ? (
              <ArrowForwardIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowForwardIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleNext}
              />
            )}
          </Item>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(IndiviualLocation);
