import React, { useEffect, useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import IndiviualLocation from '../IndiviualLocation';
import { useStateValue } from '../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';

const { withStyles, Grid } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const EmergencyLocation = ({ classes }) => {
  const [store, dispatch] = useStateValue();
  const [indiviualLocation, setIndiviualLocation] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [categoryList, setCategoryList] = useState([]);

  const handleCategory = (item) => {
    setIndiviualLocation(true);
    setSelectedCategoryId(item);
  };
  const handleClose = () => {
    setIndiviualLocation(false);
  };

  const getSafeCenterCategories = () => {
    API_GET(`safeHavens/getSafeCenterCategories?lat=18.558964292591593&lng=73.93110040905154`)
      .then((res) => {
        if (store?.corporateId === '63510bcc5ef0f87d095f5265') {
          const result = res?.safeCenterCategoriesList?.filter((item) => {
            return item?._id !== '61f3ece5b043e6f7806312db';
          });
          setCategoryList(result);
        } else {
          setCategoryList(res?.safeCenterCategoriesList);
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getSafeCenterCategories();
  }, []);

  return (
    <React.Fragment>
      {indiviualLocation ? (
        <Item>
          <IndiviualLocation
            Id={selectedCategoryId}
            categoryList={categoryList}
            handleClose={handleClose}
            // latitude={latitude}
            // longitude={longitude}
          />
        </Item>
      ) : (
        <Container className={classes.mainContainer}>
          <Item md={12} xs={12} className={classes.emergencyCardContainer}>
            {categoryList?.map((item, index) => {
              return (
                <Item
                  key={index}
                  md={3.8}
                  xs={12}
                  mt={2}
                  sx={{
                    height: '160px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <Item
                    className={classes.card}
                    key={item._id}
                    style={{
                      backgroundImage: `url(${item?.bgImage})`
                    }}
                    onClick={() => handleCategory(item)}>
                    <Item
                      sx={{
                        display: 'inline-flex',
                        ml: 'auto',
                        p: 2,
                        width: '150px',
                        mt: -5
                      }}>
                      <Item className={classes.labelText}>{item?.name}</Item>
                    </Item>
                  </Item>
                </Item>
              );
            })}
          </Item>
        </Container>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(EmergencyLocation);
