import './progressbar.css';
import React from 'react';

const ProgressBar = ({ valuePercentage, totalValue }) => {
  const fillerRelativePercentage = (valuePercentage / totalValue) * 100;

  console.log('fillerRelativePercentage', fillerRelativePercentage, totalValue);

  return (
    <div
      className="wrapper"
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={totalValue}
      aria-valuenow={valuePercentage}>
      <div className="barContainer">
        <div
          className="filler"
          style={{
            width: `${fillerRelativePercentage}%`,
            background:
              fillerRelativePercentage === 0
                ? '#FFFFFF'
                : `linear-gradient(
          90deg,
          #1a9518 -1.83%,
          #26d223 38.92%,
          #3feb3d 51.07%,
          #cffccf 105.93%
        )`,
            padding: '10px'
          }}>
          <div className="fillerBackground" style={{ width: `${fillerRelativePercentage}%` }} />
        </div>
      </div>
      <div className="textValue">{`$${
        valuePercentage === 0 ? 0 : valuePercentage?.toFixed(1)
      }`}</div>
      <div className="logo">
        <img
          src={require(`../../../Assets/logo/amico.svg`).default}
          height="100%"
          width="100%"
          alt="logo"
        />
      </div>
    </div>
  );
};

export default function App({ riskCount }) {
  const totalRevenue = riskCount && riskCount?.totalRevEarned + riskCount?.totalRevLoss;

  return (
    <div>
      <div style={{ width: '100%' }}>
        <ProgressBar
          valuePercentage={riskCount !== undefined ? riskCount?.totalRevEarned : 0}
          totalValue={totalRevenue || 100}
        />
      </div>
    </div>
  );
}
