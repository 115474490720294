import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import employeeHead from './head';
import superAdminHead from './superAdminHead';
import { useStateValue } from '../../../../utils/store';
import { Components, Icons } from '../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR, API_PATCH } from '../../../../utils/api';
import Dialog from '../../../atom/Dialog';
import AddEmployee from '../../Modal/AddEmployee';
import theme from '../../../../utils/theme';
import VehicleDetailsTable from '../VehicleDetailsTable';
import DeactivateUser from '../../Modal/DeactivateUser';
import ExportToExcel from '../../../../utils/downloadMergeColumnData';
import ExportTable from './exportTable';
import RsaTable from './rsaTable';
import SuperAdminRsaTable from './SuperAdminRsaTable';
import { getDate } from '../../../../utils/moment';
import SuperAdminExportTable from './superAdminExport';
import EmployeeDetailsModal from '../../Modal/EmployeeDetailsModal';
import downloadDataToExcelFile from '../../../../utils/downloadDataToExcelFile';
import { TotalCount } from '../../../../utils/ButtonDiff';
const {
  withStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Button,
  Checkbox,
  Card
} = Components;

const { InfoOutlinedIcon } = Icons;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const EmployeeDetailTable = ({
  classes,
  searchText,
  selectedFromDate,
  selectedToDate,
  sort,
  filtersCategory
}) => {
  const [store, dispatch] = useStateValue();
  const [page, setPage] = React.useState(0);
  const [columnHeader, setColumnHeader] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalEmployees, setTotalEmployees] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [isAddEmployeeModal, setIsAddEmployeeModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isOpenVehicleModal, setIsOpenVehicleDetails] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [vehicleDetailsData, setVehicleDetailsData] = useState([]);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const [exportData, setExportData] = useState([]);
  const [checkboxData, setCheckboxData] = useState('');
  const [isOpenEmployeeDeatilsModal, setIsOpenDetailsModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [employeeData, setEmployeeData] = useState();

  useEffect(() => {
    if (store?.role === 'superAdmin') {
      setColumnHeader(superAdminHead);
    } else {
      setColumnHeader(employeeHead);
    }
  }, [store.role]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let selectedCount = 0;
  if (checkboxData) {
    Object.keys(checkboxData).map((item) =>
      checkboxData[`${item}`] === true ? selectedCount++ : selectedCount
    );
  }

  const handleSelectAll = (event) => {
    const boolean = event.target.checked;
    const keys = Object.keys(checkboxData);
    const object = {};
    keys.map((item) => {
      return (object[item] = boolean);
    });
    setCheckboxData(object);
    setSelectAll(boolean);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxData({
      ...checkboxData,
      [event.target.name]: event.target.checked
    });
    setSelectAll(false);
  };

  useEffect(() => {
    getAllAlertDetail();
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchText,
    isEditModal,
    isAddEmployeeModal,
    sort,
    filtersCategory
  ]);

  const getAllAlertDetail = () => {
    const resultSuperAdmin = store?.role === 'superAdmin' && `superAdminDashboard=true`;
    let resultRole = '';
    for (let i = 0; i < filtersCategory?.length; i++) {
      if (filtersCategory[i]?.type === 'Role') {
        resultRole += `&roleFilter[]=${filtersCategory[i]?.name}`;
      }
      if (filtersCategory[i]?.type === 'Office') {
        resultRole += `&officeIds[]=${filtersCategory[i]?.name}`;
      }
    }
    API_GET(
      `user/getEmployees?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}&role=user&${resultSuperAdmin}&dynamicSort=name&order=${sort}${resultRole}`
    )
      .then((res) => {
        if (searchText) {
          setPage(0);
        }
        const checkboxObject = {};
        res?.data?.employees?.map((item, index) => (checkboxObject[index] = false));
        setCheckboxData(checkboxObject);
        setSelectAll(false);
        setRows(res?.data?.employees);
        setTotalEmployees(res?.data?.totalEmployees);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleClose = () => {
    setIsAddEmployeeModal(false);
    setIsEditModal(false);
    setIsOpenVehicleDetails(false);
    setIsOpenDeleteModel(false);
    setIsOpenDetailsModal(false);
  };

  const handleRemove = (item, status) => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const approveDeclinedIds = filteredKeys.map((item) => rows[item]?._id).flat();
    const deletedUserIds = status === 'all' ? approveDeclinedIds : [item?._id];

    if (deletedUserIds.length > 0) {
      setIsOpenDeleteModel(true);
      setDeleteId(deletedUserIds);
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Select atleast one user'
      });
    }
  };

  const onHandleCancel = (deletedItem, reason) => {
    deletedItem.length &&
      API_PATCH(`user/deleteUser`, { userIds: deletedItem, deactivatedReason: reason })
        .then((res) => {
          if (res?.activeIncidents?.length > 0) {
            const resultData = res?.activeIncidents?.map((item) => {
              return {
                'Ticket Number': item?.ticketNumber,
                'Assigned By': item?.assignedByEmployee,
                'Assigned To': item?.assignedToEmployee,
                Status: item?.incidentActionStatus
              };
            });
            downloadDataToExcelFile(resultData, 'Ticket Details');
          }
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.message ?? 'Success'
          });
          getAllAlertDetail();
          setIsOpenDeleteModel(false);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  const handleAddEmployee = () => {
    setIsAddEmployeeModal(true);
  };

  const handleEdit = (item) => {
    setIsEditModal(true);
    setEditedData(item);
  };

  const handleExport = (status) => {
    const resultSuperAdmin = store?.role === 'superAdmin' && `superAdminDashboard=true`;
    API_GET(`user/getEmployees?limit=${totalEmployees}&skip=0&role=user&${resultSuperAdmin}`)
      .then((res) => {
        setExportData(res?.data?.employees);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
    if (status === 'rsa') {
      setTimeout(() => {
        ExportToExcel('Employee_RSA_Table_Data', 'xlsx', `Employee_RSA_Details.xlsx`);
      }, 1000);
    } else {
      setTimeout(() => {
        ExportToExcel('Employee_Table_Data', 'xlsx', `Employee_Details.xlsx`);
      }, 1000);
    }
  };

  const TableData = () => {
    return (
      <div id="Employee_Table_Data" style={{ display: 'none' }}>
        {store?.role === 'superAdmin' ? (
          <SuperAdminExportTable data={exportData} />
        ) : (
          <ExportTable data={exportData} />
        )}
      </div>
    );
  };

  const RSATableData = () => {
    return (
      <div id="Employee_RSA_Table_Data" style={{ display: 'none' }}>
        {store?.role === 'superAdmin' ? (
          <SuperAdminRsaTable data={exportData} />
        ) : (
          <RsaTable data={exportData} />
        )}
      </div>
    );
  };

  const handleVehicleDetails = (item) => {
    setIsOpenVehicleDetails(true);
    setVehicleDetailsData(item?.vehiclesOwned);
  };

  const handleDetailsModal = (data) => {
    setIsOpenDetailsModal(true);
    setEmployeeData(data);
  };

  return (
    <React.Fragment>
      <Item sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Item>{TotalCount('Employees', `Total Employees - ${totalEmployees}`)}</Item>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          {RSATableData()}
          <Button
            onClick={() => handleExport('rsa')}
            size="small"
            style={{
              ...theme.button.filterButton
            }}>
            <img
              className={classes.filterIcon}
              src={require(`../../../../Assets/export.svg`).default}
              alt="icon"
            />
            RSA Download
          </Button>
        </Item>

        <Item>
          {TableData()}
          <Button
            onClick={() => handleExport('main')}
            size="small"
            style={{
              ...theme.button.filterButton
            }}>
            <img
              className={classes.filterIcon}
              src={require(`../../../../Assets/export.svg`).default}
              alt="icon"
            />
            Export
          </Button>
        </Item>
        <Item>
          {TableData()}
          <Button
            onClick={() => handleRemove('', 'all')}
            size="small"
            style={{
              ...theme.button.filterButton
            }}>
            Delete
          </Button>
        </Item>
        {store?.role !== 'superAdmin' && (
          <Item>
            <Button
              size="small"
              onClick={handleAddEmployee}
              style={{
                ...theme.button.filterButton
              }}>
              Add Employee
            </Button>
          </Item>
        )}
      </Item>

      <Card className={classes.root} sx={theme.tableBorder}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.head}>
              <TableRow>
                {columnHeader &&
                  columnHeader.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.id === 'select' ? (
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          style={{ color: theme.typography.tableHeading.heading }}
                        />
                      ) : (
                        column.label
                      )}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columnHeader.length} align="center">
                    {'No Records found'}
                  </TableCell>
                </TableRow>
              )}
              {rows &&
                rows.map((row, rowindex) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      {columnHeader &&
                        columnHeader.map((column, index) => {
                          const value = row[column.id] ?? '--';

                          if (column.id === 'select') {
                            return (
                              <StyledTableCell align="left" key={column.id}>
                                <Checkbox
                                  color="primary"
                                  checked={checkboxData[`${rowindex}`]}
                                  onChange={handleCheckboxChange}
                                  name={`${rowindex}`}
                                />
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'action') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <Item sx={{ display: 'flex', gap: '10px' }}>
                                  <Item
                                    onClick={() => handleEdit(row)}
                                    sx={{
                                      cursor: 'pointer',
                                      color: theme.typography.tableHeading.color
                                    }}>
                                    <img
                                      className={classes.filterIcon1}
                                      src={require(`../../../../Assets/edit.svg`).default}
                                      alt="icon"
                                    />
                                    {/* <EditIcon
                                      onClick={() => handleEdit(row)}
                                      sx={{
                                        cursor: 'pointer',
                                        color: theme.typography.tableHeading.color
                                      }}
                                    /> */}
                                  </Item>
                                  <Item
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleRemove(row, 'indiviual')}>
                                    <img
                                      className={classes.filterIcon1}
                                      src={require(`../../../../Assets/delete.svg`).default}
                                      alt="icon"
                                    />
                                    {/*  <DeleteIcon
                                      onClick={() => handleRemove(row, 'indiviual')}
                                      sx={{ cursor: 'pointer', color: 'red' }}
                                    /> */}
                                  </Item>
                                </Item>
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'vehicle') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.hasVehicles === true ? (
                                  <Item
                                    onClick={() => handleVehicleDetails(row)}
                                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                    Yes
                                  </Item>
                                ) : (
                                  <Item>No</Item>
                                )}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'dob') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.dateOfBirth !== null ? getDate(row?.dateOfBirth) : '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'details') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <InfoOutlinedIcon
                                  onClick={() => handleDetailsModal(row)}
                                  style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                />
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'officeCity') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.officeName
                                  ? [(row?.officeName, row?.officeCity)]
                                      .filter((item) => item !== undefined)
                                      .join(', ')
                                  : '-'}
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalEmployees && totalEmployees ? totalEmployees : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          isOpen={isOpenDeleteModel}
          children={
            <DeactivateUser
              handleClose={handleClose}
              deleteId={deleteId}
              onHandleDelete={onHandleCancel}
            />
          }></Dialog>
        <Dialog
          isOpen={isAddEmployeeModal}
          children={<AddEmployee handleClose={handleClose} />}></Dialog>
        <Dialog
          isOpen={isOpenVehicleModal}
          children={
            <VehicleDetailsTable handleClose={handleClose} data={vehicleDetailsData} />
          }></Dialog>
        <Dialog
          isOpen={isOpenEmployeeDeatilsModal}
          children={
            <EmployeeDetailsModal handleClose={handleClose} data={employeeData} />
          }></Dialog>

        <Dialog
          isOpen={isEditModal}
          children={
            <AddEmployee handleClose={handleClose} data={editedData} isEditModal={isEditModal} />
          }></Dialog>
      </Card>
    </React.Fragment>
  );
};

export default withStyles(styles)(EmployeeDetailTable);
