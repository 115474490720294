import React, { useState } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import TimeConversion from '../../utils/TimeConversion';
import Dialog from '../../components/atom/Dialog';
import AddDoctor from '../../components/organism/Modal/AddDoctor';
import { useStateValue } from '../../utils/store';
import { API_HANDLE_ERROR, API_DELETE, API_PATCH } from '../../utils/api';
import theme from '../../utils/theme';
import SlotModal from '../../components/organism/Modal/SlotModal';

const { Grid, withStyles, Typography, FormControlLabel } = Components;
const { DeleteIcon, EditIcon, RoomRoundedIcon, CallRoundedIcon, AccessTimeFilledIcon } = Icons;
const Item = (props) => <Grid item {...props} />;
// const Container = (props) => <Grid item {...props} />;

const comment = {
  profileImage:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/800px-Circle-icons-profile.svg.png'
};

const DoctorCard = ({ classes, data, getAllDoctors }) => {
  const [greenSwitch, setGreenSwitch] = useState(data?.available || false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [slotData, setSlotData] = useState({});
  const [isOpenSlotModal, setIsOpenSlotModal] = useState(false);
  const [, dispatch] = useStateValue();

  const handleClose = () => {
    setIsEditModalOpen(false);
    setIsOpenSlotModal(false);
  };
  const handleEdit = (data) => {
    setIsEditModalOpen(true);
    setEditData(data);
  };
  const handleAvailabel = () => {
    API_PATCH(`doctorProfile/changeAvailability/${data?._id}`, { available: !greenSwitch })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res?.message ?? 'Success'
        });
        setGreenSwitch(!greenSwitch);
        getAllDoctors();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const handleDelete = (data) => {
    API_DELETE(`doctorProfile/deleteDoctor/${data?._id}`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        handleClose();
        getAllDoctors();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleMoreTiming = (data) => {
    setSlotData(data);
    setIsOpenSlotModal(true);
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2
    }
  }));

  return (
    <Item p={1}>
      <Item className={classes.logoContainer}>
        <Item md={3} xs={3}>
          <Typography
            sx={{
              fontSize: '13px',
              color: theme.typography.tableHeading.color
            }}>
            {data?.available ? 'Available' : 'Unavailable'}
          </Typography>

          <FormControlLabel
            control={
              <Android12Switch
                checked={greenSwitch}
                onChange={() => handleAvailabel()}
                name="loading"
                size="medium"
                sx={{ ml: 1 }}
              />
            }
          />
        </Item>
        <Item
          md={7}
          xs={7}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          <Item>
            <Item className={classes.imgContainer}>
              <img
                src={data?.profileImage || comment?.profileImage}
                alt="profile"
                style={{ height: '100%', width: '100%', borderRadius: '50%' }}
              />
            </Item>
          </Item>

          <Item>
            <Typography
              sx={{
                mt: 1.2,
                fontSize: '15px',
                fontWeight: 'bold',
                textAlign: 'center',
                color: theme.typography.tableHeading.color
              }}>
              {ConvertToUpperCase(data?.fullName)}
            </Typography>
            <Item className={classes.subHeading}>
              {data?.category === 'generalphysician'
                ? 'Genral Physician'
                : ConvertToUpperCase(data?.category)}
            </Item>
          </Item>
        </Item>
        <Item md={2.5} xs={2.5} sx={{ display: 'flex', gap: '5px' }}>
          <EditIcon onClick={() => handleEdit(data)} className={classes.icons} />
          <DeleteIcon onClick={() => handleDelete(data)} className={classes.icons} />
        </Item>
      </Item>
      <Item>
        <Item mt={2} md={12} xs={12}>
          <Item md={12} xs={12} sx={{ display: 'flex', gap: '10px' }}>
            <Item className={classes.containIcon} md={1} xs={1}>
              <RoomRoundedIcon sx={{ fontSize: '14px' }} />
            </Item>
            <Item className={classes.subHeading1} md={11} xs={11}>
              {ConvertToUpperCase(data?.hospitalName)}
            </Item>
          </Item>
          <Item md={12} xs={12} sx={{ display: 'flex', gap: '10px' }} mt={1}>
            <Item className={classes.containIcon} md={1} xs={1}>
              <CallRoundedIcon sx={{ fontSize: '14px' }} />
            </Item>

            <Item className={classes.subHeading1} md={11} xs={11}>
              {data?.mobileNumber}
            </Item>
          </Item>
          <Item md={12} xs={12} sx={{ display: 'flex', gap: '10px' }} mt={1}>
            <Item>
              <AccessTimeFilledIcon sx={{ color: theme.palette.primary.main, ml: -0.3 }} />
            </Item>
            <Item sx={{ display: 'flex', flexDirection: 'column' }} md={11} xs={11}>
              <Item className={classes.subHeading1} md={12} xs={12}>
                {data?.slots?.[0]?.day}
              </Item>
              <Item className={classes.subHeading1} md={12} xs={12}>
                {TimeConversion(data?.slots?.[0]?.intervals?.[0]?.startTime) +
                  ' - ' +
                  TimeConversion(data?.slots?.[0]?.intervals?.[0]?.endTime)}
              </Item>
              <Item
                onClick={() => handleMoreTiming(data?.slots)}
                className={classes.subHeading1}
                sx={{ cursor: 'pointer', color: theme.typography.tableHeading.unSelect }}
                md={12}
                xs={12}
                mt={0.5}>
                View all timings
              </Item>
            </Item>
          </Item>
        </Item>
      </Item>
      <Dialog
        isOpen={isEditModalOpen}
        children={
          <AddDoctor handleClose={handleClose} editData={editData} getAllDoctors={getAllDoctors} />
        }></Dialog>
      <Dialog
        isOpen={isOpenSlotModal}
        children={<SlotModal handleClose={handleClose} slotData={slotData} />}></Dialog>
    </Item>
  );
};

export default withStyles(styles)(DoctorCard);
