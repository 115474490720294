import React, { useEffect, useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import CountsCard from './countsCard';
import AlertSummary from './alertSummary';
import AlertDetails from './alertDetails';
import moment from 'moment';
import { useStateValue } from '../../utils/store';
import { useLocation } from 'react-router-dom';
import Prediction from './prediction';

const { withStyles, Grid, TextField, Box } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Alerts = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const cards = [
    { value: 'assigned', label: 'Alert Summary', table: AlertDetails },
    { value: 'completed', label: 'Alert Details', table: AlertSummary },
    { value: 'prediction', label: 'Predictions', table: Prediction }
  ];

  const [selectedCard, setSelectedCard] = useState(cards[0]);
  const [search, setSearch] = useState('');
  const getSearch = useLocation().search;
  const searchByParams = new URLSearchParams(getSearch).get('searchBy');
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const name = new URLSearchParams(getSearch).get('tab');

  useEffect(() => {
    if (name) {
      cards?.map((item) => {
        if (item?.value === name) {
          setSelectedCard(item);
        }
        return null;
      });
    }
    if (searchByParams) {
      setSearch(searchByParams);
    }
  }, []);

  return (
    <Container direction="column" className={classes.mainContainer}>
      <Container justifyContent="space-between" alignItems="center" alignContent="center">
        <Box sx={{ flexGrow: 1 }}>
          <Container justify="space-between" alignItems="center" spacing={2} sx={{ mt: 0.5 }}>
            {cards.map((item) => (
              <Item key={item.value}>
                <CountsCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
              </Item>
            ))}
          </Container>
        </Box>

        <Item sx={{ display: 'flex', gap: '10px', mt: 2, flexWrap: 'wrap' }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            {selectedCard?.value !== 'completed' && (
              <TextField
                type="tablesearch"
                className={classes.tablesearch}
                value={search}
                size="small"
                sx={{ width: '200px' }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search by incident"
              />
            )}
          </Item>
          <Item>
            <form noValidate>
              <TextField
                id="datetime-local"
                label="From"
                type="date"
                size="small"
                value={selectedFromDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onFromDateChange(e);
                }}
              />
            </form>
          </Item>

          <Item>
            <form noValidate>
              <TextField
                id="date"
                label="To"
                type="date"
                size="small"
                value={selectedToDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onToDateChange(e);
                }}
              />
            </form>
          </Item>
        </Item>
      </Container>

      <Item xs={12} mt={2}>
        <selectedCard.table
          selectedFromDate={selectedFromDate === 'Invalid date' ? '' : selectedFromDate}
          search={search}
          tab={selectedCard?.table}
          selectedToDate={
            selectedToDate === 'Invalid date' ? '' : selectedToDate
          }></selectedCard.table>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Alerts);
