const styles = (theme) => {
  return {
    newUser: {
      minWidth: '25vw',
      maxWidth: '40vw',
      padding: theme.spacing(2)
    },
    icon: {
      color: theme.typography.tableHeading.color,
      cursor: 'pointer'
    }
  };
};

export default styles;
