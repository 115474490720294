const styles = (theme) => {
  return {
    mainContainer: {
      backgroundColor: theme.palette.common.gray98,
      maxWidth: '75vw'
    },
    title: {
      backgroundColor: theme.palette.primary.tableHeadingColor,
      fontFamily: theme.palette.font.fontFamily,
      color: 'white',
      textAlign: 'center',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },
    head: {
      cursor: 'pointer'
    },
    formContainer1: {
      padding: theme.spacing(2, 1),
      border: '1px solid #EDEDED',
      boxShadow: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
      borderRadius: theme.spacing(1)
    },
    stepperContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      maxHeight: '78vh',
      overflow: 'auto'
    },
    stepper: {
      display: 'flex',
      padding: '5px',
      justifyContent: 'center',
      cursor: 'pointer',
      alignItems: 'center',
      flexWrap: 'wrap',
      border: '1px solid #E1E1E1'
    },
    stepperBlock: {
      marginRight: theme.spacing(1),
      width: '25px',
      height: '25px'
    },
    tshirtContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
      gap: '10px'
    },

    tshirtStyles: {
      // color: red;
      display: 'flex',
      width: '32px',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      border: '0.5px solid',
      fontSize: '12px',
      fontWeight: '600',
      cursor: 'pointer'
    },
    formDetailContainer: {
      maxHeight: '60vh',
      width: '60vw',
      overflow: 'auto'
    },
    inputBoxContainer: {
      padding: theme.spacing(2, 1),
      boxShadow: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))',
      border: '1px solid #E5E5E5',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
      gap: '10px',
      alignItems: 'center'
    },
    docContain: {
      maxWidth: '60vw',
      minWidth: '50vw',
      maxHeight: '65vh',
      overflow: 'auto'
    },
    docContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    uploadDocContainer: {
      maxHeight: '200px',
      minHeight: '100px',
      boxShadow: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))',
      border: '1px solid #E5E5E5',
      padding: theme.spacing(1, 2)
    },
    tableRow: {
      padding: theme.spacing(1),
      border: '1px solid #EDEDED',
      width: '15vw',
      fontSize: '0.9rem',
      textAlign: 'center'
    },
    tableRow1: {
      padding: theme.spacing(1),
      border: '1px solid #EDEDED',
      width: '15vw',
      backgroundColor: '#FEEFDD',
      fontSize: '0.9rem',
      textAlign: 'center',
      top: 0,
      position: 'sticky'
    },

    indeterminateColor: {
      color: '#f50057'
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    textColor: {
      color: '#868686',
      fontSize: '15px'
    },
    documentView: {
      padding: theme.spacing(2),
      border: '1px solid #EDEDED',
      backgroundColor: '#FEEFDD',
      top: 0,
      position: 'sticky'
    },
    docsButton: {
      top: 0,
      position: 'sticky',
      marginBottom: theme.spacing(1)
    },
    groupFilterContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: theme.spacing(1)
    },
    packageDetails_container: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      border: '1px solid #EDEDED',
      borderRadius: '10px',
      maxHeight: '55vh',
      overflow: 'auto'
    },
    declerationLink: {
      color: 'red',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    package_subHeading: {
      color: '#5A5A5A'
    }, //
    selfDeclerationContainer: {
      height: '98vh'
    },
    selfDecleartionContain: {
      height: '80vh',
      overflow: 'auto'
    },
    addPackageContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: '20px',
      justify: 'space-around'
    },
    sampleFileText: {
      color: 'red',
      cursor: 'pointer',
      textAlign: 'left'
    },
    previewImg: {
      height: '200px',
      border: '1px solid #E5E5E5',
      borderRadius: '10px'
    },
    docImage: {
      borderRadius: '10px',
      width: '100%',
      height: '100%'
    }
  };
};

export default styles;
