import React, { useState } from 'react';
import styled from 'styled-components';
import { useLayer, Arrow } from 'react-laag';
import { Components } from '../../utils/material-ui';
import theme from '../../utils/theme';

const InfoBox = styled.div`
  box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
`;

const StyledMarker = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 0;
  }
`;

const { Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

export default function Marker({ showData }) {
  const [isOpen, setOpen] = useState(false);
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: false,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false)
  });

  return (
    <React.Fragment>
      <StyledMarker {...triggerProps} onClick={() => setOpen((prev) => !prev)}>
        <img
          alt="img"
          width="30px"
          height="30px"
          src={require(`../../Assets/RiskIcons/emergency.svg`).default}
        />
      </StyledMarker>

      {isOpen &&
        renderLayer(
          <InfoBox {...layerProps}>
            <Item style={{ width: '250px', textAlign: 'center' }}>
              <img
                alt="logo"
                src={
                  showData?.branchImage ||
                  'https://img.freepik.com/free-photo/modern-office-space-interior_158595-5206.jpg'
                }
                width="100%"
                height="100%"
                style={{
                  borderRadius: '10px',
                  objectFit: 'cover'
                }}
              />
              <Typography
                sx={{
                  color: theme.typography.tableHeading.color,
                  fontSize: '14px',
                  fontWeight: '600'
                }}>
                {showData?.name}
              </Typography>
              <Typography
                sx={{
                  color: theme.typography.tableHeading.lightColor,
                  fontSize: '13px'
                }}>{`${showData?.name} | Branch Code - ${showData?.officeCode}`}</Typography>
            </Item>

            <Arrow {...arrowProps} />
          </InfoBox>
        )}
    </React.Fragment>
  );
}
