import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import EmployeeDetailTable from '../../components/organism/Table/EmployeeDetailTable';

const { withStyles, Grid } = Components;

const Container = (props) => <Grid container {...props} />;

const AllEmployees = ({ classes }) => {
  return (
    <Container direction="column" style={{ padding: '20px' }}>
      <EmployeeDetailTable />
    </Container>
  );
};

export default withStyles(styles)(AllEmployees);
