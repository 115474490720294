import React from 'react';
import { Components } from '../../../utils/material-ui';
import overviewcss from './overviewcss';
import theme from '../../../utils/theme';
import DocumentsGraph from './documentsGraph';
import MismatchDocumentsGraph from './mismatchDocumentsGraph';

const { Paper, withStyles, Grid } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid container {...props} />;

const GraphContainer = ({ classes, location, region }) => {
  return (
    <Container className={classes.RiskCardContainer} spacing={2} mt={0.5}>
      <Item xs>
        <Paper className={classes.ContainerCard} sx={theme.card}>
          <DocumentsGraph location={location} region={region} />
        </Paper>
      </Item>
      <Item xs>
        <Paper className={classes.ContainerCard} sx={theme.card}>
          <MismatchDocumentsGraph location={location} region={region} />
        </Paper>
      </Item>
    </Container>
  );
};

export default withStyles(overviewcss)(GraphContainer);
