import React, { useState, useEffect } from 'react';
import { Components } from '../../../../utils/material-ui';
import theme from '../../../../utils/theme';
import styles from '../styles';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useStateValue } from '../../../../utils/store';
//

const { Toolbar, List, ListItem, Drawer, withStyles, Grid, Button } = Components;

const Item = (props) => <Grid item {...props} />;

// const {
// DirectionsCarFilledIcon,
// HomeIcon,
// ReportIcon,
// AddAlertIcon,
// AccountCircleIcon,
// BackupTableIcon,
// BusinessIcon,
// LogoutIcon
// AssessmentIcon
// LocationOnOutlinedIcon
// SettingsIcon,
// } = Icons;

// const { Sos } = SvgIcons;

const SideBar = (props) => {
  const [store] = useStateValue();
  const { window, handleDrawerToggle, mobileOpen, drawerWidth } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [role] = useState(store.role);
  const [corporateId] = useState(store?.corporateId);
  const [items, setItems] = useState([]);
  const container = window !== undefined ? () => window().document.body : undefined;

  const gotoBroadcast = () => {
    navigate('/broadcast');
  };

  const selectedKey = {
    fontSize: '16px',
    margin: theme.spacing(0.7, 2, 0.7, 0),
    padding: theme.spacing(1, 3),
    borderRadius: '0px 10px 10px 0px',
    width: '230px',
    fontWeight: theme.palette.font.fontWeightText,
    backgroundColor: theme.palette.primary.tableHeadingColor,
    color: theme.palette.primary.secondarymain
  };
  const notSelectedKey = {
    margin: theme.spacing(0.7, 2, 0.7, 0),
    padding: theme.spacing(1, 3),
    fontSize: '16px',
    width: '230px',
    fontWeight: theme.palette.font.fontWeightText,
    color: theme.palette.primary.textSecondColor
  };

  if (role === 'sosAdmin') {
    selectedKey.backgroundColor = theme.palette.primary.sosDashboard;
  }

  const CustomListItem = ({ to, primary, Icon, type }) => {
    const resultImg = to === location.pathname ? `${Icon}White` : Icon;
    return (
      <ListItem
        button
        id={primary}
        component={Link}
        to={to}
        style={to === location.pathname ? selectedKey : notSelectedKey}
        selected={to === location.pathname}>
        {type === 'svg' ? (
          <React.Fragment>
            <img
              style={{
                height: '23px',
                width: '23px',
                marginRight: theme.spacing(1.5)
              }}
              src={require(`../../../../Assets/SidebarIcons/${resultImg}.svg`).default}
              alt="icon"
            />
            {primary}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Icon sx={{ mr: 1.5 }} />
            {primary}
          </React.Fragment>
        )}
      </ListItem>
    );
  };

  useEffect(() => {
    setItems(navItems(role, corporateId));
  }, []);

  const getBroadcastButton = (role) => {
    if (
      role !== 'superAdmin' &&
      role !== 'sosAdmin' &&
      role !== 'agent' &&
      role !== 'user' &&
      store?.corporateId !== theme.corporate.mliCorporateId &&
      store?.corporateId !== theme.corporate.novo &&
      store?.corporateId !== theme.corporate.identityDigital
    ) {
      return (
        <Button
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.secondarymain,
            padding: theme.spacing(1, 2)
          }}>
          <img
            style={{
              height: '23px',
              width: '23px',
              marginRight: theme.spacing(0.8)
            }}
            src={require(`../../../../Assets/SidebarIcons/broadcast.svg`).default}
            alt="icon"
          />
          {'BROADCAST'}
        </Button>
      );
    } else {
      return '';
    }
  };

  const drawer = (
    <div>
      <Toolbar
        sx={{
          height: '5vh',
          cursor: 'pointer'
        }}>
        <img
          src={
            store?.corporateId === '63510bcc5ef0f87d095f5265'
              ? 'https://drorlabstorage.blob.core.windows.net/dror/riskmatic/misc/pg-lythouse.png'
              : store?.corporateId === theme.corporate.novo
              ? 'https://drorlabstorage.blob.core.windows.net/dror/riskmatic/misc/novo-lythouse.png'
              : store?.corporateId === theme.corporate.identityDigital
              ? 'https://drorlabstorage.blob.core.windows.net/dror/riskmatic/misc/identityDigital-lythouse.png'
              : store?.corporateId === theme.corporate.mliCorporateId
              ? `${require(`../../../../Assets/logo/maxlifeLogo.png`).default}`
              : 'https://drorlabstorage.blob.core.windows.net/dror/lythouse-logo.png'
          }
          alt="lythouse"
          width={
            store?.corporateId === theme.corporate.novo ||
            store?.corporateId === theme.corporate.identityDigital ||
            store?.corporateId === theme.corporate.mliCorporateId ||
            store?.corporateId === '63510bcc5ef0f87d095f5265'
              ? '210vw'
              : '160vw'
          }
          height="58vh"
          style={{ objectFit: 'contain' }}
        />
      </Toolbar>

      <List
        sx={{
          mt: role === 'agent' ? 8 : 0,
          height: '90vh',
          overflow: 'auto',
          backgroundColor: theme.palette.primary.secondarymain,
          color: theme.palette.primary.textColor,
          overflowX: 'hidden'
        }}>
        {items?.map((item, index) => (
          <CustomListItem
            to={item.link}
            primary={item.label}
            Icon={item.Icon}
            key={index}
            type={item?.type}
          />
        ))}
        <Item
          mt={2.5}
          onClick={() => gotoBroadcast()}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}>
          {getBroadcastButton(role)}
        </Item>
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth
          }
        }}
        open>
        {drawer}
      </Drawer>
    </React.Fragment>
  );
};

function navItems(role, corporateId) {
  switch (role) {
    case 'superAdmin':
      return superAdmin();
    case 'admin':
      return admin(corporateId);
    case 'sosAdmin':
      return sosAdmin();
    case 'user':
      return User();
    case 'agent':
      return Agent();
    default:
      return null;
  }
}

function sosAdmin() {
  return [
    {
      label: 'SOS Dashboard',
      link: '/sosDashboard',
      Icon: 'overview',
      type: 'svg'
    },
    {
      label: 'Safe Locations',
      link: '/emergency-locations',
      Icon: 'safeLocation',
      type: 'svg'
    },
    {
      label: 'Logout',
      link: '/logout',
      Icon: 'logout',
      type: 'svg'
    }
  ];
}

function Agent() {
  return [
    {
      label: 'Agent Form',
      link: '/Agent-Panel',
      Icon: 'overview',
      type: 'svg'
    },
    {
      label: 'Logout',
      link: '/logout',
      Icon: 'logout',
      type: 'svg'
    }
  ];
}
function User() {
  return [
    {
      label: 'Employee Form',
      link: '/Employee-Panel',
      Icon: 'overview',
      type: 'svg'
    },
    {
      label: 'Logout',
      link: '/logout',
      Icon: 'logout',
      type: 'svg'
    }
  ];
}

function superAdmin() {
  return [
    {
      label: 'Dashboard',
      link: '/dashboard',
      Icon: 'overview',
      type: 'svg'
    },
    {
      label: 'User',
      link: '/allEmployees',
      Icon: 'user',
      type: 'svg'
    },
    {
      label: 'Logout',
      link: '/logout',
      Icon: 'logout',
      type: 'svg'
    }
  ];
}

function admin(corporateId) {
  return corporateId === '63510bcc5ef0f87d095f5265'
    ? [
        {
          label: 'Dashboard',
          link: '/home',
          Icon: 'overview',
          type: 'svg'
        },
        {
          label: 'Alerts',
          link: '/alerts',
          Icon: 'alert',
          type: 'svg'
        },
        {
          label: 'Safe Haven',
          link: '/emergency-locations',
          Icon: 'safeLocation',
          type: 'svg'
        },
        {
          label: 'Reports',
          link: '/reports',
          Icon: 'reports',
          type: 'svg'
        },
        {
          label: 'Company Profile',
          link: '/company-profile',
          Icon: 'companyProfile',
          type: 'svg'
        },
        {
          label: 'User',
          link: '/user',
          Icon: 'user',
          type: 'svg'
        },
        {
          label: 'Settings',
          link: '/settings',
          Icon: 'settings',
          type: 'svg'
        },
        {
          label: 'Logout',
          link: '/logout',
          Icon: 'logout',
          type: 'svg'
        }
      ]
    : corporateId === theme.corporate.mliCorporateId
    ? [
        {
          label: 'Travel Management',
          link: '/travel-management',
          Icon: 'travel',
          type: 'svg'
        },
        {
          label: 'User',
          link: '/user',
          Icon: 'user',
          type: 'svg'
        },
        {
          label: 'Settings',
          link: '/settings',
          Icon: 'settings',
          type: 'svg'
        },

        {
          label: 'Logout',
          link: '/logout',
          Icon: 'logout',
          type: 'svg'
        }
      ]
    : corporateId === theme.corporate.novo || corporateId === theme.corporate.identityDigital
    ? [
        {
          label: 'Home',
          link: '/home',
          Icon: 'overview',
          type: 'svg'
        },
        {
          label: 'Alerts',
          link: '/alerts',
          Icon: 'alert',
          type: 'svg'
        },

        {
          label: 'Safe Haven',
          link: '/emergency-locations',
          Icon: 'safeLocation',
          type: 'svg'
        },

        {
          label: 'Happiness assessment index',
          link: '/Happiness-assessment',
          Icon: 'happinessIndex',
          type: 'svg'
        },

        {
          label: 'Company Profile',
          link: '/company-profile',
          Icon: 'companyProfile',
          type: 'svg'
        },

        {
          label: 'User',
          link: '/user',
          Icon: 'user',
          type: 'svg'
        },

        {
          label: 'Logout',
          link: '/logout',
          Icon: 'logout',
          type: 'svg'
        }
      ]
    : [
        {
          label: 'Home',
          link: '/home',
          Icon: 'overview',
          type: 'svg'
        },
        {
          label: 'Alerts',
          link: '/alerts',
          Icon: 'alert',
          type: 'svg'
        },
        {
          label: 'External & Internal Risk Distribution',
          Icon: 'Incident',
          link: '/incident-distribution',
          type: 'svg'
        },
        {
          label: 'Safe Haven',
          link: '/emergency-locations',
          Icon: 'safeLocation',
          type: 'svg'
        },
        {
          label: 'Reports',
          link: '/reports',
          Icon: 'reports',
          type: 'svg'
        },
        {
          label: 'Happiness assessment index',
          link: '/Happiness-assessment',
          Icon: 'happinessIndex',
          type: 'svg'
        },
        {
          label: 'Appointments',
          link: '/appointments',
          Icon: 'appointment',
          type: 'svg'
        },
        {
          label: 'Company Profile',
          link: '/company-profile',
          Icon: 'companyProfile',
          type: 'svg'
        },
        {
          label: 'Travel Management',
          link: '/travel-management',
          Icon: 'travel',
          type: 'svg'
        },
        {
          label: 'User',
          link: '/user',
          Icon: 'user',
          type: 'svg'
        },
        {
          label: 'Settings',
          link: '/settings',
          Icon: 'settings',
          type: 'svg'
        },

        {
          label: 'Logout',
          link: '/logout',
          Icon: 'logout',
          type: 'svg'
        }
      ];
}

export default withStyles(styles)(SideBar);
