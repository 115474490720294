const styles = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(2, 2, 8, 2)
    },
    dateContainer: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      gap: '15px'
    },
    headingContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0.2, 0.5)
      // flexWrap: 'wrap'
    },
    cardText: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    motionContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.4)
    },
    heading: {
      fontSize: '14px',
      color: '#565555'
    },
    heading1: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0.2, 0.5),
      fontSize: '14px',
      color: '#565555'
      // flexWrap: 'wrap'
    }
  };
};

export default styles;
