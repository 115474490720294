const styles = (theme) => {
  return {
    mainContainer: {
      width: '100vw',
      position: 'relative'
    },
    inSideContainer: {
      position: 'absolute',
      top: '5vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap'
    },

    login_page: {
      backgroundColor: theme.palette.common.gray98,
      height: '90vh',
      maxHeight: '90vh',
      overflow: 'auto',
      padding: theme.spacing(4, 5),
      borderRadius: theme.spacing(2),
      boxShadow: '-28.892px 0px 52.0947px rgba(0, 0, 0, 0.05)'
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
      // border: '1px solid red'
    },
    side_panal: {
      height: '84vh',
      justifyContent: 'center',
      color: '#FFFFFF'
    },
    imgContainer: {
      height: '35vh',
      width: '100%'
    },
    textContainer: {
      maxHeight: '50vh',
      overflow: 'auto'
    },

    login_label: {
      justifyContent: 'center',
      letterSpacing: '5px',
      color: '#555555'
    },

    button: {
      padding: theme.spacing(1),
      borderRadius: 10,
      fontSize: '12px',
      fontWeight: '600',
      boxShadow: '0px 12.5027px 25.0054px rgba(255, 103, 96, 0.12)'
    },
    inputBox: {
      borderRadius: '15px',
      '& .MuiInputBase-input': {
        fontSize: '1rem', // set the font size to 1.5rem
        padding: '0.8rem' // add extra padding
      },
      '& .MuiInputBase-root': {
        '& fieldset': {
          // borderColor: 'lightGrey',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '7px'
        },
        '&:hover fieldset': {
          borderColor: '#f3f3f3',
          // boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '7px'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#f3f3f3',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '7px'
        },
        '&.Mui-focused:after': {
          borderRightColor: '#FF0000',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '7px'
        }
      }
    },
    dot: {
      color: 'red' // Change dot color here
    },
    indoIcon: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: '#FF6760',
      height: 50,
      width: 70,
      marginRight: '-15px',
      borderRadius: '8px'
    }
  };
};

export default styles;
