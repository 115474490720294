/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import TemplateBroadcastTable from '../../components/organism/Table/BroadcastAllTables/TemplateBroadcastTable';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';
import { useStateValue } from '../../utils/store';
import moment from 'moment';
import SortButtonModal from '../../utils/sortButton';
import { SortButton } from '../../utils/ButtonDiff';
import Filter from '../../utils/filters';

const { withStyles, Grid, TextField, InputAdornment } = Components;
const { SearchIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const sortArray = [
  { name: 'Asc - Des', value: 1 },
  { name: 'Des- Asc', value: -1 }
];

const ActiveBroadcast = ({ classes }) => {
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [, dispatch] = useStateValue();
  const [sort, setSort] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isOpenSortModal, setIsOpenSortModal] = useState(false);

  const handleOpenSortModal = () => {
    setIsOpenSortModal(!isOpenSortModal);
  };

  const handleSort = (value) => {
    setSort(value);
    setIsOpenSortModal(false);
  };

  const handleClearAll = () => {
    setSort('');
  };

  const handleRemove = (item, status) => {
    if (status === 'sort') {
      setSort('');
    }
  };

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  return (
    <Container>
      <Item
        md={12}
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
        <Item md={4} xs={12}>
          <TextField
            placeholder="Search by Header"
            size="small"
            variant="outlined"
            fullWidth
            sx={theme.customTextFiled}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Item>
        <Item
          sx={{
            display: 'inline-flex',
            ml: 'auto',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}>
          <Item sx={{ position: 'relative' }}>
            <SortButton handleOpenSortModal={handleOpenSortModal} />
            {isOpenSortModal && (
              <SortButtonModal handleSort={handleSort} sortArray={sortArray} sort={sort} />
            )}
          </Item>

          <Item>
            <form noValidate>
              <TextField
                id="datetime-local"
                label="From"
                type="date"
                size="small"
                value={selectedFromDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onFromDateChange(e);
                }}
              />
            </form>
          </Item>
          <Item>
            <form noValidate>
              <TextField
                id="date"
                label="To"
                type="date"
                size="small"
                value={selectedToDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onToDateChange(e);
                }}
              />
            </form>
          </Item>
        </Item>
      </Item>
      {sort !== '' && (
        <Filter sort={sort} handleClearAll={handleClearAll} handleRemove={handleRemove} />
      )}
      <Item md={12} xs={12} mt={2} style={{ width: '500px' }}>
        <TemplateBroadcastTable
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
          sort={sort}
          searchText={searchText}
        />
      </Item>
    </Container>
  );
};

export default withStyles(styles)(ActiveBroadcast);
