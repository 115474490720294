import React from 'react';
import { Components } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import AttendanceOverview from './attendanceOverview';
import VaccinationOverview from './vaccinationOverview';
import AppointmentOverview from './appointmentOverview';

const { withStyles, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const EmployeeReport = ({ classes }) => {
  return (
    <Container direction="column" className={classes.newUser}>
      <Typography className={classes.cardHeading} sx={{ backgroundColor: '#FFC127' }} variant="h6">
        Employee Report
      </Typography>
      <Item className={classes.tableContainer1}>
        <Typography
          classsName={classes.containHeading}
          sx={{ ...theme.typography.tableHeading, color: theme.palette.primary.main, pl: 0 }}>
          Heading
        </Typography>
        <p style={{ fontSize: '0.9rem', marginTop: '-5px' }}>
          Employee status is important for the smooth functioning of the organization. The Health
          and attendance of the employees play a crucial role here. Track office-wise attendance and
          status of appointments and vaccines.
        </p>
        <Item mt={1.5}>
          <Typography
            classsName={classes.containHeading}
            sx={{ ...theme.typography.tableHeading, color: theme.palette.primary.main, pl: 0 }}>
            Attendance Overview
          </Typography>
          <p style={{ fontSize: '0.9rem', marginTop: '-5px' }}>
            Check attendance status of all the employees categorized on the basis of department,
            locations and types of leaves.
          </p>
        </Item>
        <Item sx={{ ...theme.card, p: 1.5, m: '15px 0px' }}>
          <AttendanceOverview />
        </Item>

        <Item>
          <Typography
            classsName={classes.containHeading}
            sx={{ ...theme.typography.tableHeading, color: theme.palette.primary.main, pl: 0 }}>
            Appointment Overview
          </Typography>
          <p style={{ fontSize: '0.9rem', marginTop: '-5px' }}>
            Appointment status of the employees helps you get an health analysis displayed on the
            basis of type of apponintment and categorized on the basis of location and department.
          </p>
        </Item>
        <Item sx={{ ...theme.card, p: 1, m: '15px 0px' }}>
          <AppointmentOverview />
        </Item>
        <Item mt={1.5}>
          <Typography
            classsName={classes.containHeading}
            sx={{ ...theme.typography.tableHeading, color: theme.palette.primary.main, pl: 0 }}>
            Vaccination overview
          </Typography>
        </Item>
        <p style={{ fontSize: '0.9rem', marginTop: '-5px' }}>
          Vaccination status of all the employees including fully vaccinated, single dose, no dose
          and booster dose.These are categorized on the basis of office location and you can filter
          the data on the basis of date range.
        </p>
        <Item sx={{ ...theme.card, p: 1.5, m: '15px 0px' }}>
          <VaccinationOverview />
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(EmployeeReport);
