import React, { useState } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../../../utils/store';
import { API_HANDLE_ERROR, API_POST, API_PUT } from '../../../../utils/api';
import theme from '../../../../utils/theme';

const {
  withStyles,
  Grid,
  Button,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
  // Checkbox
} = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function AddNominatedAgent({ classes, handleClose, editData, status }) {
  const [, dispatch] = useStateValue();

  const [rejectData, setRejectData] = useState({
    id: status === 'edit' ? editData?.employeeId || editData?.agentId || editData?.uniqueId : '',
    packageId: status === 'edit' ? editData?.packageId : '',
    numberOfdependents: status === 'edit' ? editData?.numberOfdependents : '',
    type: status === 'edit' ? editData?.role : 'user',
    addFamily: status === 'edit' || false
  });

  // const [familyDetails, setFamilyDetails] = useState([]);

  const handleStateChange = (key, value) => {
    setRejectData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  // const handlefamily = (resultName, check) => {
  //   const result = familyDetails?.map((item) => {
  //     return item?.name === resultName ? { ...item, value: check } : item;
  //   });
  //   setFamilyDetails(result);
  // };

  // const getAllFamilyMembers = () => {
  //   if (status === 'add') {
  //     rejectData?.id &&
  //       API_GET(`travelPackage/getUserInfo/${rejectData.id}?role=${rejectData?.type}`)
  //         .then((res) => {
  //           const result = res?.data?.map((item) => {
  //             return {
  //               name: item?.fullName,
  //               _id: item?._id,
  //               value: true
  //             };
  //           });
  //           setFamilyDetails(result);
  //         })
  //         .catch((err) => {
  //           API_HANDLE_ERROR(err, dispatch);
  //           setRejectData({ ...rejectData, addFamily: false });
  //           setFamilyDetails([]);
  //         });
  //   } else {
  //     editData?._id &&
  //       API_GET(`travelPackage/getGoingWith/${editData?._id}`)
  //         .then((res) => {
  //           const result = res?.data?.map((item) => {
  //             return {
  //               name: item?.name,
  //               _id: item?.userId,
  //               value: item?.status
  //             };
  //           });
  //           setFamilyDetails(result);
  //         })
  //         .catch((err) => {
  //           API_HANDLE_ERROR(err, dispatch);
  //           setRejectData({ ...rejectData, addFamily: false });
  //           setFamilyDetails([]);
  //         });
  //   }
  // };

  // useEffect(() => {
  //   getAllFamilyMembers();
  // }, [dispatch, rejectData.addFamily]);

  const onHandleConfirm = (e) => {
    e.preventDefault();

    // const resultFamily = familyDetails?.map((item) => {
    //   return {
    //     userId: item?._id,
    //     status: item?.value
    //   };
    // });

    const Postdata = {
      packageId: rejectData?.packageId,
      id: rejectData?.id,
      role: rejectData?.type,
      numberOfdependents: Number(rejectData?.numberOfdependents)
      // goingWith: resultFamily,
    };
    if (status === 'add') {
      API_POST(`travelPackage/addNominatedEmployee`, Postdata)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      API_PUT(`travelPackage/updateNominatedEmployee/${editData?._id}`, Postdata)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  return (
    <Container direction="column" wrap="nowrap" className={classes.popupContainer}>
      <Item className={classes.title}>
        <Typography style={{ textAlign: 'center' }}>
          {status === 'edit' ? 'Edit' : 'Add'}
        </Typography>
      </Item>
      <form onSubmit={onHandleConfirm}>
        <Item sx={{ p: 1.5 }}>
          <Item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={rejectData.type}
                onChange={(e) => handleStateChange('type', e.target.value)}>
                <FormControlLabel value="user" control={<Radio />} label="Employee" />
                <FormControlLabel value="agent" control={<Radio />} label="Agent" />
              </RadioGroup>
            </FormControl>
          </Item>
          <Item sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }} mt={1}>
            <Item>
              <TextField
                variant="outlined"
                name="agentId"
                label="Id"
                size="small"
                required={true}
                value={rejectData?.id}
                sx={{ width: '250px' }}
                className={classes.formControl}
                onChange={(e) => handleStateChange('id', e.target.value)}
                type="text"
                fullWidth
              />
            </Item>
            <Item>
              <TextField
                variant="outlined"
                name="packageId"
                size="small"
                label="Group Id"
                required={true}
                sx={{ width: '250px' }}
                value={rejectData?.packageId}
                className={classes.formControl}
                onChange={(e) => handleStateChange('packageId', e.target.value)}
                type="text"
                fullWidth
              />
            </Item>
            <Item>
              <TextField
                variant="outlined"
                name="agentId"
                label="Number of dependents"
                size="small"
                required={true}
                value={rejectData?.numberOfdependents}
                sx={{ width: '250px' }}
                className={classes.formControl}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleStateChange('numberOfdependents', e.target.value);
                  } else {
                    handleStateChange('numberOfdependents', 0);
                  }
                }}
                type="Number"
                fullWidth
              />
            </Item>
          </Item>
          {/*   <React.Fragment>
            {rejectData?.numberOfdependents === '' || rejectData?.numberOfdependents < 1 ? (
              ''
            ) : (
              <Item mt={1}>
                <Item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rejectData.addFamily}
                        onChange={(e) => handleStateChange('addFamily', e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Add Family members"
                  />
                </Item>

                <Item sx={{ maxHeight: '200px', overflow: 'auto' }} p={1}>
                  {familyDetails?.map((item) => {
                    return (
                      <Item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item?.value}
                              onChange={(e) => handlefamily(item?.name, e.target.checked)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label={item?.name}
                        />
                      </Item>
                    );
                  })}
                </Item>
              </Item>
            )}
                </React.Fragment> */}
        </Item>
        <Item sx={{ display: 'flex', gap: '10px', p: 1 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
              Cancel
            </Button>
          </Item>
          <Item>
            <Button
              type="submit"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              {`Submit`}
            </Button>
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(AddNominatedAgent);
