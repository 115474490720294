const styles = (theme) => {
  return {
    mainContainer: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }
  };
};

export default styles;
