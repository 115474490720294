import React from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';

const { withStyles, Typography, Grid, Button } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function DeleteModal({ classes, handleClose, handleConfirmationClick, step }) {
  return (
    <Container direction="column" className={classes.newUser}>
      <Item>
        <Typography variant="body2" sx={{ fontSize: '19px', p: 1 }}>
          {step === 2
            ? 'Are you sure you want to move on to the next page without uploading other docs ?'
            : 'Are you sure you want to move on to the next page without providing any other information?'}
        </Typography>
      </Item>
      <Item>
        <Item sx={{ display: 'flex', gap: '15px', mt: 2 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button onClick={() => handleClose()} style={{ ...theme.button.cancel }}>
              NO
            </Button>
          </Item>
          <Item>
            <Button
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}
              onClick={() => handleConfirmationClick()}>
              YES
            </Button>
          </Item>
        </Item>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(DeleteModal);
