import { getDateTime } from '../../utils/moment';

const ExportTable = ({ data }) => {
  return (
    <table border="1">
      <thead>
        <tr>
          <th>SOS ID</th>
          <th>Company Name</th>
          <th>Event Time</th>
          <th>Attended At</th>
          <th>Attended By</th>
          <th>Type</th>
          <th>Name</th>
          <th>Gender</th>
          <th>Phone Number</th>
          <th>Role</th>
          <th>Internal Id</th>
          <th>City</th>
          <th>Address</th>
          <th>Escalated To</th>
          <th>Comments</th>
          <th>Category</th>
          <th>Service</th>
          <th colSpan="2">Emergency Contact</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th> </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>Name</th>
          <th>Phone Number</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, reIndex) => {
          const resEmer = item?.emergencyContact !== undefined ? item?.emergencyContact : [];
          return (
            <>
              <tr key={reIndex}>
                <td rowSpan={resEmer?.length}>{item?.incidentAutoId || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.corporateName || '-'}</td>
                <td rowSpan={resEmer?.length}> {getDateTime(item?.incomingTime) || '-'}</td>
                <td rowSpan={resEmer?.length}>{getDateTime(item?.closureTime) || '-'}</td>
                <td rowSpan={resEmer?.length}> {item?.attendedBy || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.type || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.name || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.gender || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.phoneNumber || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.role || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.internalId || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.city || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.address || '-'}</td>
                {/*   <td rowSpan={resEmer?.length}>
                  {`https://maps.google.com?q=${item?.lat},${item?.lng}`}
                </td>  */}
                <td rowSpan={resEmer?.length}> {item?.informedTo || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.comment || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.category || '-'}</td>
                <td rowSpan={resEmer?.length}>
                  {Array.isArray(item?.service) && item?.service.join(', ')}
                </td>
                <td>{resEmer && resEmer[0]?.fullName}</td>
                <td>{resEmer && resEmer[0]?.mobileNumber}</td>
              </tr>
              {resEmer?.map((item, index) => {
                return index !== 0 ? (
                  <tr key={index}>
                    <td>{item?.fullName}</td>
                    <td>{item?.mobileNumber}</td>
                  </tr>
                ) : (
                  ''
                );
              })}
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExportTable;
