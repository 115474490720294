import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';
import PropTypes from 'prop-types';
import { ThreeDots } from 'react-loader-spinner';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../../utils/store';

const { withStyles, Grid } = Components;

const Container = (props) => <Grid container {...props} />;
// const Item = (props) => <Grid item {...props} />;

const NotFound = ({ classes }) => {
  const [store] = useStateValue();
  const navigate = useNavigate();

  const handleBack = () => {
    if (
      store?.isLogin &&
      store?.role === 'admin' &&
      store?.corporateId === theme.corporate.mliCorporateId
    ) {
      navigate('/travel-management');
    } else if (store?.isLogin && store?.role === 'superAdmin') {
      navigate('/dashboard');
    } else if (store?.isLogin && store?.role === 'sosAdmin') {
      navigate('/sosDashboard');
    } else if (store?.isLogin && store?.role === 'agent') {
      navigate('/Agent-Panel');
    } else if (store?.isLogin && store?.role === 'user') {
      navigate('/Employee-Panel');
    } else if (store?.isLogin && store?.role === 'admin') {
      navigate('/home');
    } else {
      navigate('/login');
    }
  };

  return (
    <Container direction={'column'} className={classes.notFoundPage}>
      <ThreeDots
        height="80"
        width="80"
        radius="10"
        color={theme.palette.primary.tableHeadingColor}
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
      <button className="go-back" onClick={handleBack}>
        Go Back
      </button>
    </Container>
  );
};

NotFound.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotFound);
