const styles = (theme) => {
  return {
    popupContainer: {
      padding: theme.spacing(2)
    },

    addPackageContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: '20px',
      justify: 'space-around'
    }
  };
};

export default styles;
