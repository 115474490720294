import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import { Components } from '../../../../utils/material-ui';
import { getDate } from '../../../../utils/moment';
import { Typography } from '@mui/material';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import ActualIncidentNewsModal from '../../Modal/ActualIncidentNewsModal';
import Dialog from '../../../atom/Dialog';
// import tableColor from '../../../../utils/tableColor';
import theme from '../../../../utils/theme';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  Grid,
  Button
} = Components;
const Item = (props) => <Grid item {...props} />;

const SafetyIssueTable = ({ classes, selectedFromDate, selectedToDate }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalIncidents, settotalIncidents] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [isOpenDataModal, setIsOpenMoreModal] = useState(false);
  const [openData, setOpenMore] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    API_GET(
      `alert/getNewsByCond?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&fromDate=${selectedFromDate}&toDate=${selectedToDate}&fromNotify=false`
    )
      .then((res) => {
        settotalIncidents(res?.countNewsFeed);
        setRows(res?.PickedNewsFeed);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, page, rowsPerPage, selectedToDate, selectedFromDate]);

  const handleClose = () => {
    setIsOpenMoreModal(false);
  };

  const handleExtraData = (row) => {
    setIsOpenMoreModal(true);
    setOpenMore(row);
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Typography sx={{ ...theme.typography.tableHeading, pb: 0 }}>External Risk</Typography>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ border: 'none' }}
                    // style={{ backgroundColor: tableColor(index) }}
                  >
                    {columns &&
                      columns.map((column) => {
                        const value = row[column.id] || '--';

                        if (column.id === 'category') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Button
                                style={{
                                  ...theme.button.tableSeverityButton,
                                  backgroundColor: row?.backgroundColor,
                                  color: row?.color
                                }}>
                                {row?.alertCategory}
                              </Button>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'title') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item
                                sx={{ display: 'flex', gap: '10px', cursor: 'pointer' }}
                                onClick={() => handleExtraData(row)}>
                                <Item>{row?.title}</Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'date') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {getDate(row?.createdAt)}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'prediction') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {row?.newsType === 'predictive' ? 'Yes' : 'No'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'revenue') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              <Item sx={{ color: theme.palette.primary.main, ml: 1 }}>
                                {row?.revenue > 0 ? `$${row?.revenue}` : row?.revenue}
                              </Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'locationFromDror') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {ConvertToUpperCase(
                                [row?.locationFromDror, row?.cityFromDror, row?.stateFromDror]
                                  .filter((item) => item !== '')
                                  .join(', ')
                              )}
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(ConvertToUpperCase(value))
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
        count={totalIncidents && totalIncidents ? totalIncidents : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        isOpen={isOpenDataModal}
        children={<ActualIncidentNewsModal handleClose={handleClose} data={openData} />}></Dialog>
    </Card>
  );
};

export default withStyles(styles)(SafetyIssueTable);
