import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../utils/store';
import { Components } from '../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { getDateTime, getDate } from '../../../../utils/moment';
import theme from '../../../../utils/theme';
import tableColor from '../../../../utils/tableColor';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Button
} = Components;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const DeclinedAppointment = ({ classes, selectedFromDate, selectedToDate, searchText }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRejectedNews, setTotalAppointment] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // to reset page on search
  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllApprovedAppointment();
  }, [dispatch, page, rowsPerPage, selectedFromDate, selectedToDate, searchText]);

  const getAllApprovedAppointment = () => {
    const resultSearch =
      searchText !== undefined
        ? `searchText=${searchText}`
        : `fromDate=${selectedFromDate}&toDate=${selectedToDate}`;
    API_GET(`appointment/getAllAppointments?appointmentStatus=declined&${resultSearch}`)
      .then((res) => {
        setRows(res?.appointments);
        setTotalAppointment(res?.appointmentsCount);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  // const handleCloseMoreModal = () => {
  //   setIsOpenMoreModal(false);
  // };

  // const handleMore = (item) => {
  //   setData(item);
  //   setIsOpenMoreModal(true);
  //   // window.open(item?.sourceWebsite);
  // };

  // const handleClose = () => {
  //   // setRejectModal(false);
  //   // setVerifyModal(false);
  //   setIsForward(false);
  // };

  // const handleRemove = (item) => {
  //   API_PATCH(`alert/removeAlertById/${item?._id}`)
  //     .then((res) => {
  //       dispatch({
  //         type: 'ALERT_OPEN',
  //         severity: 'success',
  //         isOpen: true,
  //         label: res.data?.message ?? 'Success'
  //       });
  //       getAllApprovedAppointment();
  //       getFilterCategory();
  //       getMainCategory();
  //     })
  //     .catch((err) => {
  //       API_HANDLE_ERROR(err, dispatch);
  //     });
  // };

  // const handleForward = (row) => {
  //   setIsForward(true);
  //   setForwardId(row?._id);
  // };

  // const handleVerify = (item) => {
  //   API_POST(`alert/convertAlertToIncidentById/${item?._id}`)
  //     .then((res) => {
  //       dispatch({
  //         type: 'ALERT_OPEN',
  //         severity: 'success',
  //         isOpen: true,
  //         label: res.data?.message ?? 'Success'
  //       });

  //       setVerifyModal(true);
  //       // setVerifyId(res?.incidentCreated?._id);
  //     })
  //     .catch((err) => {
  //       API_HANDLE_ERROR(err, dispatch);
  //     });
  // };

  // const handleMoveToIncident = () => {
  //   // navigate('/incident-distribution');
  //   navigate('/incident-distribution');
  //   dispatch({
  //     type: 'IS_MOVE',
  //     isMove: true
  //   });
  // };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.thead}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {rows &&
              rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(index) }}>
                    {columns &&
                      columns.map((column, index) => {
                        const value = row[column.id] ?? '--';

                        if (column.id === 'date') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              {getDateTime(row?.date) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'appointmentDate') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {getDate(row?.dateOfBooking) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'actionTime') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              {getDateTime(row?.actionTime) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'action') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                <Item>
                                  <Button
                                    style={{
                                      backgroundColor: '#FFEFD5',
                                      color: `${theme.palette.primary.main}`,
                                      width: '90px',
                                      border: `1px solid ${theme.palette.primary.main}`,
                                      height: '25px',
                                      fontSize: '10px',
                                      fontWeight: '600'
                                    }}
                                    // onClick={() => handleReject(row)}
                                  >
                                    Approve
                                  </Button>
                                </Item>

                                <Item>
                                  <Button
                                    style={{
                                      backgroundColor: theme.palette.primary.tableHeadingColor,
                                      color: theme.palette.common.white,
                                      // border: '1px solid Blue',
                                      height: '25px',
                                      width: '90px',
                                      fontSize: '10px',
                                      fontWeight: '600'
                                    }}
                                    // onClick={() => handleVerify(row)}
                                  >
                                    Decline
                                  </Button>
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(ConvertToUpperCase(value))
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalRejectedNews && totalRejectedNews ? totalRejectedNews : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* <Dialog
          isOpen={genuineModal}
          children={
            <GenuineModal
              handleModal={handleModal}
              selectedIncident={currentIncident}
            />
          }
        ></Dialog> */}

      {/*  <Dialog
        isOpen={rejectModal}
        children={
          <NoActionModal handleClose={handleClose} rejectId={rejectId} isIncident={true} />
        }></Dialog> */}
    </Card>
  );
};

export default withStyles(styles)(DeclinedAppointment);
