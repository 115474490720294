import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import { ResponsiveBar } from '@nivo/bar';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import theme from '../../../utils/theme';
import ExportToExcel from '../../../utils/downloadMergeColumnData';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon, ArrowBackIosIcon, ArrowForwardIosIcon } = Icons;
const Item = (props) => <Grid item {...props} />;
// const Container = (props) => <Grid item {...props} />;

// const data = [
//   {
//     Psychiatric: 120,
//     Physician: 100,
//     time: 'Delhi'
//   },
//   {
//     Psychiatric: 90,
//     Physician: 30,
//     time: 'Chennai'
//   },
//   {
//     Psychiatric: 78,
//     Physician: 52,
//     time: 'Gurgoan'
//   },
//   {
//     Psychiatric: 55,
//     Physician: 23,
//     time: 'Bengaluru'
//   },
//   {
//     Psychiatric: 33,
//     Physician: 52,
//     time: 'pune'
//   }
// ];

const MyResponsivePieCanvas = ({
  classes,
  selectedFromDate,
  selectedToDate,
  selectedDepartment
}) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [count] = React.useState(8);
  const [totalCount, setTotalCount] = useState(0);
  const [exportResultData, setExportResultData] = useState([]);
  const [reportLocation, setReportsLocation] = useState([]);

  const handleForward = () => {
    setPage(page + 1);
  };

  const handleBackward = () => {
    if (page === 0) {
      setPage(0);
    } else {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    const skip = count * page > totalCount ? (count * page) % count : count * page;

    API_GET(
      `reports/getOfficeWiseAppointment?fromDate=${selectedFromDate}&toDate=${selectedToDate}&limit=${count}&skip=${skip}&department=${selectedDepartment}`
    )
      .then((res) => {
        setTotalCount(2);
        setRows(res?.officeWiseAppointmentData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, count, page, selectedDepartment]);

  const handleExport = () => {
    API_GET(
      `reports/downloadOfficeWiseAppointment?fromDate=${selectedFromDate}&toDate=${selectedToDate}&department=${selectedDepartment}`
    )
      .then((res) => {
        setExportResultData(res?.data);
        setReportsLocation(res?.locations);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    setTimeout(() => {
      ExportToExcel(
        'Off. wise appointment_dept',
        'xlsx',
        `Off. wise appointment_${selectedDepartment}_(${selectedFromDate} To ${selectedToDate}).xlsx`
      );
    }, 450);
  };

  const TableData = () => {
    return (
      <div id="Off. wise appointment_dept" style={{ display: 'none' }}>
        <table border="1">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Date</th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return <th colSpan={2}>{item}</th>;
                })}
            </tr>
            <tr>
              <th></th>
              <th></th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return (
                    <React.Fragment>
                      <th>Physician</th>
                      <th>Psychiatric</th>
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {exportResultData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.SrNo}</td>
                  <td>{item?.date}</td>
                  {Object.keys(item).map((subData) => {
                    return (
                      <React.Fragment>
                        {subData !== 'SrNo' && subData !== 'date' ? (
                          <React.Fragment>
                            <td>{item[subData]?.physician}</td>
                            <td>{item[subData]?.psychiatric}</td>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Office Wise Appointment
          </Typography>
        </Item>
        <Item>
          {TableData()}
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '210px' }}>
        <ResponsiveBar
          indexBy="time"
          style={{
            fontSize: '14px'
          }}
          groupMode="grouped"
          data={rows}
          keys={['Physician', 'Psychiatric']}
          colors={['#EC7A1A', '#4BA04B']}
          index="time"
          margin={{
            top: 30,
            right: 10,
            bottom: 40,
            left: 40
          }}
          padding={0.3}
          labelSkipWidth={10}
          labelSkipHeight={10}
          labelTextColor="inherit:darker(1.6)"
          animate={true}
          motionStiffness={90}
          motionDamping={20}
          axisBottom={{
            tickSize: 10,
            tickPadding: 6,
            tickRotation: -25,
            legendPosition: 'middle',
            legendOffset: 45
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'row',
              justify: false,
              translateX: -20,
              translateY: -155,
              itemsSpacing: 5,
              itemWidth: 80,
              itemHeight: 5,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20
            }
          ]}
        />
      </Item>
      <Item md={12} sx={{ display: 'flex' }}>
        {rows?.length !== 0 ? (
          <Item sx={{ display: 'inline-flex', ml: 'auto', mr: 1 }}>
            {page === 0 ? (
              <ArrowBackIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowBackIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleBackward}
              />
            )}
            <Typography sx={{ fontSize: '12px' }}>
              {`${rows && rows.length}-${totalCount}`}
            </Typography>
            {count * page >= totalCount ? (
              <ArrowForwardIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowForwardIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleForward}
              />
            )}
          </Item>
        ) : (
          ''
        )}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
