import React, { useState, useEffect, useRef } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { Components, Icons } from '../../../../../utils/material-ui';
import tableColor from '../../../../../utils/tableColor';
import RemainderModel from '../../../Modal/RemainderModel';
import Dialog from '../../../../atom/Dialog';
import PackageDataWithIdTable from '../PackageDataWithIdTable';
import AddAgent from '../../../Modal/AddNominatedAgent';
import { useStateValue } from '../../../../../utils/store';
import { API_DELETE, API_GET, API_HANDLE_ERROR, API_UPLOAD_FILE } from '../../../../../utils/api';
import { getDate } from '../../../../../utils/moment';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import DeleteConfirmation from '../../../Modal/DeleteConfirmation';
import theme from '../../../../../utils/theme';
// import downloadDataToExcelFile from '../../../../../utils/downloadDataToExcelFile';
import '../ListOfAgents/style.css';
import DocsDownloadModel from '../../../Modal/DocsDownloadModel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AgentsDocsDetailsModel from '../../../Modal/AgentsDocsDetailsModel';
import DownloadTextFile from '../../../../../utils/DownloadTextFile';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Checkbox,
  Typography,
  TextField,
  Button,
  CircularProgress
} = Components;
const { WatchIcon, AddCircleIcon, ShareIcon, EditIcon, DeleteIcon } = Icons;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const YetToTravelledTable = ({ classes, selectedFromDate, selectedToDate, packageType, sort }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalEmployees, setTotalEmployees] = React.useState(0);
  const [checkboxData, setCheckboxData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isRemainderModelOpen, setIsRemainderModelOpen] = useState(false);
  const [isOpenPackageModel, setIsOpenPackageModel] = useState(false);
  const [isOpenAddAgentModel, setIsOpenAddAgentModel] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [editAgentData, setEditAgentData] = useState();
  const [status, setStatus] = useState('');
  const [pakagesDetailsData, setPackageDetailsData] = useState('');
  const [remainderIds, setRemainderIds] = useState([]);
  const [isOpenMultipleDocsModel, setIsOpenMultipleDocsModel] = useState(false);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [isLoadingDocs, setIsLoadingDocs] = useState(false);
  const [docsData, setDocsData] = useState({});
  const [isOpenDocsModel, setIsOpenDocsModel] = useState(false);
  const [docsStatus, setDocsStatus] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setIsRemainderModelOpen(false);
    setIsOpenPackageModel(false);
    setIsOpenAddAgentModel(false);
    setIsOpenDeleteModel(false);
    setIsOpenDocsModel(false);
    setIsOpenMultipleDocsModel(false);
  };
  const handleRemove = (item) => {
    setIsOpenDeleteModel(true);
    setDeleteId(item?._id);
  };

  const handleRemainder = () => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const approveDeclinedIds = filteredKeys.map((item) => rows[item].userId).flat();
    if (approveDeclinedIds.length > 0) {
      setIsRemainderModelOpen(true);
      setRemainderIds(approveDeclinedIds);
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Select atleast one Entry !'
      });
    }
  };

  const handlePackageData = (data) => {
    setIsOpenPackageModel(true);
    setPackageDetailsData(data);
  };
  const handleAddAgent = () => {
    setIsOpenAddAgentModel(true);
    setStatus('add');
  };

  const GetYetToTrvalledEmp = () => {
    const resultPackage = packageType === 'All' ? '' : `packageType=${packageType}`;
    selectedFromDate = selectedFromDate === 'Invalid date' ? '' : selectedFromDate;
    selectedToDate = selectedToDate === 'Invalid date' ? '' : selectedToDate;
    API_GET(
      `travelPackage/getAllNominatedEmployees?travelled=false&limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}&fromDate=${selectedFromDate}&toDate=${selectedToDate}&dynamicSort=${sort}&${resultPackage}`
    )
      .then((res) => {
        const checkboxObject = {};
        res?.data?.map((item, index) => (checkboxObject[index] = false));
        setCheckboxData(checkboxObject);
        setSelectAll(false);
        setRows(res?.data);
        setTotalEmployees(res?.count);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    GetYetToTrvalledEmp();
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchText,
    selectedFromDate,
    selectedToDate,
    packageType,
    sort,
    isOpenAddAgentModel
  ]);

  let selectedCount = 0;
  if (checkboxData) {
    Object.keys(checkboxData).map((item) =>
      checkboxData[`${item}`] === true ? selectedCount++ : selectedCount
    );
  }

  const handleSelectAll = (event) => {
    const boolean = event.target.checked;
    const keys = Object.keys(checkboxData);
    const object = {};
    keys.map((item) => {
      return (object[item] = boolean);
    });
    setCheckboxData(object);
    setSelectAll(boolean);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxData({
      ...checkboxData,
      [event.target.name]: event.target.checked
    });
    setSelectAll(false);
  };

  const handleEdit = (row) => {
    setIsOpenAddAgentModel(true);
    setEditAgentData(row);
    setStatus('edit');
  };

  const onHandleCancel = (deletedItem) => {
    deletedItem &&
      API_DELETE(`travelPackage/deleteNominatedEmployee/${deletedItem}`)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          GetYetToTrvalledEmp();
          setIsOpenDeleteModel(false);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    uploadfile(fileUploaded);
    event.target.value = '';
  };

  const uploadfile = (fileUploaded) => {
    setLoading(true);
    API_UPLOAD_FILE(`travelPackage/importNominatedEmployees`, fileUploaded)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success',
          time: 9000
        });
        GetYetToTrvalledEmp();
        setLoading(false);
        if (res?.errors.length > 0) {
          const errorText = res?.errors
            ?.map((error) => `Row ${error.row}: ${error.errorMessage}\n`)
            .join('');
          DownloadTextFile(errorText);
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const selectedEmployeeData = filteredKeys.map((item) => rows[item]?._id).flat();

    let str = '';
    for (const id of selectedEmployeeData) {
      str += `&employeeIds[]=${id}`;
    }
    API_GET(`travelpackage/downloadNominatedReport?${str}&searchText=${searchText}`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res?.message || 'Success'
        });
        setIsActive(false);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
        setIsActive(false);
      });
  };

  // docs loader

  const handleLoader = (status) => {
    setIsLoadingDocs(status);
  };

  // handleMultiple docs Download

  const handleMultipleDocsDownload = () => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const selectedEmployeeData = filteredKeys.map((item) => rows[item]).flat();
    if (selectedEmployeeData.length === 0) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Select atleast one Entry !'
      });
      setIsActive(false);
    } else if (selectedEmployeeData.length > 20) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'For documents download min. 1 and max. 20 employees/agents must be selected.'
      });
      setIsActive(false);
    } else {
      setIsOpenMultipleDocsModel(true);
      setIsActive(false);
    }
  };

  // handle indiviual employee docs

  const handleDocsModel = (status, data) => {
    setIsOpenDocsModel(true);
    setDocsData(data);
    setDocsStatus(status);
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Item className={classes.filterBar}>
        <Item>
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: '600'
            }}>{`List of Agents `}</Typography>
          <Typography sx={{ fontSize: '12px' }} variant="h4">
            {`Total Nominated Agent (${totalEmployees})`}
          </Typography>
        </Item>
        <Item>
          <TextField
            id="outlined-basic"
            label="Search by Group Id and Group Name"
            size="small"
            value={searchText}
            variant="outlined"
            sx={{ width: '325px' }}
            fullWidth
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Item>
        <Item sx={{ display: 'flex', gap: '10px' }}>
          <WatchIcon className={classes.icon} fontSize="large" onClick={handleRemainder} />

          {loading ? (
            <Button
              size="small"
              style={{
                backgroundColor: theme.palette.primary.tableHeadingColor,
                color: theme.palette.common.white
              }}>
              <CircularProgress sx={{ color: '#FFFFFF' }} size={20} />
              Importing
            </Button>
          ) : (
            <ShareIcon className={classes.icon} fontSize="large" onClick={handleClick} />
          )}
          <AddCircleIcon className={classes.icon} fontSize="large" onClick={handleAddAgent} />

          <div className="menu-container">
            <button
              onClick={() => setIsActive(!isActive)}
              className="menu-trigger"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`
              }}>
              <span>Download</span>
              <div
                className="menu-Icon"
                style={{ color: '#FFFFFF', backgroundColor: theme.palette.primary.main }}>
                {isActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </div>
            </button>
            <Item ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
              <ul>
                <li>
                  <Item className="list" onClick={handleDownload}>
                    Excel File
                  </Item>
                </li>
                <li>
                  <Item className="list" onClick={handleMultipleDocsDownload}>
                    Documents
                  </Item>
                </li>
              </ul>
            </Item>
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept=".xlsx, .xls, .csv"
          />
        </Item>
      </Item>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.id === 'select' ? (
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        style={{ color: 'white' }}
                      />
                    ) : (
                      column.label
                    )}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows?.map((row, rowindex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(rowindex) }}>
                    {columns &&
                      columns.map((column, index) => {
                        const value = row[column.id] || '--';
                        if (column.id === 'packageId') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item
                                sx={{ textDecoration: 'underline black', cursor: 'pointer' }}
                                onClick={() => handlePackageData(row?.packageDetails)}>
                                {row?.packageId}
                              </Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'startDate') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>{getDate(row?.startDate)}</Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'uniqueId') {
                          return (
                            <StyledTableCell key={index} align="center">
                              {row?.role !== 'agent' ? (
                                <Item
                                  sx={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => handleDocsModel('Emp Id', row)}>
                                  {row?.employeeId || row?.uniqueId}
                                </Item>
                              ) : (
                                <Item>-</Item>
                              )}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'agentId') {
                          return (
                            <StyledTableCell key={index} align="center">
                              {row?.role === 'agent' ? (
                                <Item
                                  sx={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => handleDocsModel('Agent Id', row)}>
                                  {row?.agentId}
                                </Item>
                              ) : (
                                <Item>-</Item>
                              )}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'endDate') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>{getDate(row?.endDate)}</Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'pendingDocs') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.pendingDocsCount}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'name') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {ConvertToUpperCase(row?.name)}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'expiredDocs') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.expiredDocsCount}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'tobeExpire') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.toBeExpiredDocsCount}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'gender') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>
                                {[ConvertToUpperCase(row?.gender), row?.age].join('/') || '-'}
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'select') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              <Checkbox
                                color="primary"
                                checked={checkboxData[`${rowindex}`]}
                                onChange={handleCheckboxChange}
                                name={`${rowindex}`}
                              />
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'action') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item sx={{ display: 'flex', gap: '7px' }}>
                                <EditIcon
                                  onClick={() => handleEdit(row)}
                                  sx={{ cursor: 'pointer' }}
                                />

                                <DeleteIcon
                                  onClick={() => handleRemove(row)}
                                  sx={{ cursor: 'pointer', color: 'red' }}
                                />
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalEmployees && totalEmployees ? totalEmployees : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        isOpen={isOpenPackageModel}
        children={
          <PackageDataWithIdTable handleClose={handleClose} data={pakagesDetailsData} />
        }></Dialog>
      <Dialog
        isOpen={isRemainderModelOpen}
        children={
          <RemainderModel handleClose={handleClose} remainderIds={remainderIds} />
        }></Dialog>
      <Dialog
        isOpen={isOpenAddAgentModel}
        children={
          <AddAgent handleClose={handleClose} status={status} editData={editAgentData} />
        }></Dialog>
      <Dialog
        isOpen={isOpenDeleteModel}
        children={
          <DeleteConfirmation
            handleClose={handleClose}
            deleteId={deleteId}
            onHandleDelete={onHandleCancel}
          />
        }></Dialog>
      <Dialog
        isOpen={isOpenDocsModel}
        children={
          <AgentsDocsDetailsModel
            handleClose={handleClose}
            docsData={docsData}
            docsStatus={docsStatus}
          />
        }></Dialog>
      <Dialog
        isOpen={isOpenMultipleDocsModel}
        children={
          <DocsDownloadModel
            handleClose={handleClose}
            checkboxData={checkboxData}
            rows={rows}
            handleLoader={handleLoader}
            isLoadingDocs={isLoadingDocs}
          />
        }></Dialog>
    </Card>
  );
};

export default withStyles(styles)(YetToTravelledTable);
