const styles = (theme) => {
  return {
    blueDataCard: {
      width: '20vw',
      height: '19vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: theme.spacing(1.5, 1.5, 3, 0),
      padding: theme.spacing(0, 2)
    },
    mainContainer: {
      padding: theme.spacing(1, 2)
    },
    topContainer: {
      width: '100%',
      height: '7vh',
      marginTop: theme.spacing(2)
    },
    tableContainer: {
      width: '100%',
      height: '100%',
      marginTop: theme.spacing(1)
    },
    dropDownMenu: {
      height: '30px',
      width: '100%',
      display: 'flex',
      padding: theme.spacing(2)
    },
    menuName: {
      width: '70%',
      fontSize: '14px'
    },
    menuNumber: {
      width: '15%',
      height: '20px',
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      fontSize: '12px',
      fontFamily: theme.palette.font.fontFamily,
      borderRadius: '9px',
      paddingTop: '2px',

      color: 'white'
    },
    menuIcon: {
      marginLeft: '2%',
      cursor: 'pointer'
    },
    middleContainer: {
      width: '100%',
      height: '78vh',
      marginTop: theme.spacing(1),
      padding: theme.spacing(2)
    },

    middleContainer1: {
      width: '100%',
      height: '28vh',
      marginTop: theme.spacing(1)
    },

    lastContainer: {
      width: '100%',
      height: 'auto',
      marginTop: theme.spacing(2)
    },

    pieChartHeading: {
      fontSize: '13px',
      fontFamily: theme.palette.font.fontFamily,
      padding: theme.spacing(0, 2),
      fontWeight: 400
    }
  };
};

export default styles;
