const RenderDocuments = (resultDocs, item) => {
  const passportD = {
    passportFrontLink: [
      item?.passportDocs && item?.passportDocs[0]?.links && item?.passportDocs[0]?.links[0]
    ],
    passportBackLink: [
      item?.passportDocs && item?.passportDocs[0]?.links && item?.passportDocs[0]?.links[1]
    ]
  };
  const AaadhaarD = {
    aadhaarFrontLink: [
      item?.aadhaarCardDocs && item?.aadhaarCardDocs[0]?.links && item?.aadhaarCardDocs[0]?.links[0]
    ],
    aadhaarBackLink: [
      item?.aadhaarCardDocs && item?.aadhaarCardDocs[0]?.links && item?.aadhaarCardDocs[0]?.links[1]
    ]
  };
  const VisaD = {
    visaLink: item?.visaDocs && item?.visaDocs[0]?.links
  };
  const TravelD = {
    travelInsuranceLink: item?.travelInsuranceDocs && item?.travelInsuranceDocs[0]?.links
  };
  const HealthD = {
    healthInsuranceLink: item?.healthInsuranceDocs && item?.healthInsuranceDocs[0]?.links
  };
  const PanD = {
    panCardLink: item?.panCardDocs && item?.panCardDocs[0]?.links
  };
  const AdditionalDocs1D = {
    additionalDocsLink: [item?.additionalDoc1]
  };
  const AdditionalDocs2D = {
    additionalDocsLink: [item?.additionalDoc2]
  };

  const VaccinationD = {
    vaccination1stDocs: {
      links: item?.vaccinationDocs && item?.vaccinationDocs[0]?.links
    },
    vaccination2ndDocs: {
      links: item?.vaccinationDocs && item?.vaccinationDocs[1]?.links
    },
    vaccinationBoosterDocs: {
      links: item?.vaccinationDocs && item?.vaccinationDocs[2]?.links
    }
  };
  const aadhaarF = AaadhaarD?.aadhaarFrontLink && AaadhaarD?.aadhaarFrontLink[0];
  const aadhaarB = AaadhaarD?.aadhaarBackLink && AaadhaarD?.aadhaarBackLink[0];
  const passportF = passportD?.passportFrontLink && passportD?.passportFrontLink[0];
  const passportB = passportD?.passportBackLink && passportD?.passportBackLink[0];
  const visaL = VisaD?.visaLink && VisaD?.visaLink[0];
  const panL = PanD?.panCardLink && PanD?.panCardLink[0];
  const additionalDocs1L =
    AdditionalDocs1D?.additionalDocsLink && AdditionalDocs1D?.additionalDocsLink[0];
  const additionalDocs2L =
    AdditionalDocs2D?.additionalDocsLink && AdditionalDocs2D?.additionalDocsLink[0];
  const travelL = TravelD?.travelInsuranceLink && TravelD?.travelInsuranceLink[0];
  const healthL = HealthD?.healthInsuranceLink && HealthD?.healthInsuranceLink[0];
  const vaccine1stL =
    VaccinationD?.vaccination1stDocs?.links && VaccinationD?.vaccination1stDocs?.links[0];
  const vaccine2ndL =
    VaccinationD?.vaccination2ndDocs?.links && VaccinationD?.vaccination2ndDocs?.links[0];
  const vaccineBL =
    VaccinationD?.vaccinationBoosterDocs?.links && VaccinationD?.vaccinationBoosterDocs?.links[0];

  const downloadDocsData = [
    {
      id: 'Aadhar card',
      name: aadhaarF?.mediaType || aadhaarB?.mediaType ? 'Adhar Card' : '',
      files: [
        {
          name: aadhaarF?.mediaType ? `AdharFront.${aadhaarF?.mediaType}` : '',
          url: aadhaarF?.uri
        },
        {
          name: aadhaarB?.mediaType ? `AdharBack.${aadhaarB?.mediaType}` : '',
          url: aadhaarB?.uri
        }
      ]
    },
    {
      id: 'Passport',
      name: passportF?.mediaType || passportB?.mediaType ? 'Passport' : '',
      files: [
        {
          name: passportF?.mediaType ? `Passport Front.${passportF?.mediaType}` : '',
          url: passportF?.uri
        },
        {
          name: passportB?.uri ? `Passport Back.${passportB?.mediaType}` : '',
          url: passportB?.uri
        }
      ]
    },
    {
      id: 'PAN Card',
      name: panL?.mediaType ? 'Pan card' : '',
      files: [
        {
          name: panL?.mediaType ? `Pan Card.${panL?.mediaType}` : '',
          url: panL?.uri
        }
      ]
    },
    {
      id: 'Additional Docs 1',
      name: additionalDocs1L?.mediaType ? 'Additional Docs 1' : '',
      files: [
        {
          name: additionalDocs1L?.mediaType
            ? `Additional Docs 1.${additionalDocs1L?.mediaType}`
            : '',
          url: additionalDocs1L?.uri
        }
      ]
    },
    {
      id: 'Photo',
      name: additionalDocs2L?.mediaType ? 'Photo' : '',
      files: [
        {
          name: additionalDocs2L?.mediaType
            ? `Additional Docs 2.${additionalDocs2L?.mediaType}`
            : '',
          url: additionalDocs2L?.uri
        }
      ]
    },
    {
      id: 'Visa',
      name: visaL?.mediaType ? 'Visa' : '',
      files: [
        {
          name: visaL?.mediaType ? `Visa.${visaL?.mediaType}` : '',
          url: visaL?.uri
        }
      ]
    },
    {
      id: 'Travel Insurance',
      name: travelL?.mediaType ? 'Travelled Insurance' : '',
      files: [
        {
          name: travelL?.mediaType ? `Travel Insurance.${travelL?.mediaType}` : '',
          url: travelL?.uri
        }
      ]
    },
    {
      id: 'Health Insurance',
      name: healthL?.mediaType ? 'Health Insurance' : '',
      files: [
        {
          name: healthL?.mediaType ? `Health Insurance.${healthL?.mediaType}` : '',
          url: healthL?.uri
        }
      ]
    },
    {
      id: 'Vaccination',
      name:
        vaccine1stL?.mediaType || vaccine2ndL?.mediaType || vaccineBL?.mediaType
          ? 'Vaccination'
          : '',
      files: [
        {
          name: vaccine1stL?.mediaType ? `Vaccination 1st Dose.${vaccine1stL?.mediaType}` : '',
          url: vaccine1stL?.uri
        },
        {
          name: vaccine2ndL?.mediaType ? `Vaccination 2nd Dose.${vaccine2ndL?.mediaType}` : '',
          url: vaccine2ndL?.uri
        },
        {
          name: vaccineBL?.mediaType ? `Vaccination Booster Dose.${vaccineBL?.mediaType}` : '',
          url: vaccineBL?.uri
        }
      ]
    }
  ];

  const resultDocsData = downloadDocsData?.filter((obj) => resultDocs?.includes(obj.id));
  return resultDocsData;
};

export default RenderDocuments;
