import { Typography } from '@mui/material';
import React from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';
import StarIcon from '@mui/icons-material/Star';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';

const { withStyles, Grid, Button } = Components;
const { LocationOnOutlinedIcon } = Icons;
const Item = (props) => <Grid item {...props} />;

const CardConatiner = ({
  classes,
  data,
  selectedCategoryImage,
  selectedCategoryName,
  index,
  selectedColor
}) => {
  const lng = data?.location?.coordinates[0];
  const lat = data?.location?.coordinates[1];

  const handleMapClick = (lat, lng) => {
    window.open(`https://maps.google.com?q=${lat},${lng}`);
  };

  const image = selectedCategoryName
    ? `${selectedCategoryName?.split(' ')?.join('')}BgColor`
    : 'BloodBanksBgColor';

  return (
    <Item
      md={3.7}
      xs={12}
      p={2}
      mt={3}
      className={classes.cardDataConatiner}
      style={{
        background: `no-repeat center/100% url(${
          require(`../../Assets/SafeLocation/${image}.svg`).default
        })`
      }}>
      <Item sx={{ display: 'flex', height: '80px', position: 'relative' }} md={12} xs={12}>
        <Item sx={{ position: 'absolute', right: '38%' }} md={3.7} xs={12}>
          <img
            src={
              selectedCategoryImage ||
              'https://drorlabstorage.blob.core.windows.net/dror/b2c/assets/safeHaven/cardIcon/police-badge.png'
            }
            alt="Icon"
            width="80px"
            height="80px"
          />
        </Item>
        <Item
          sx={{
            position: 'absolute',
            right: 0,
            top: -35,
            backgroundColor: '#0F851D',
            color: '#FFFFFF',
            fontSize: '10px',
            padding: '3px',
            borderRadius: '10px',
            marginTop: '35px',
            width: '50px',
            textAlign: 'center',
            fontWeight: '600'
          }}>
          Verified
        </Item>
      </Item>

      <Item md={12} xs={12} mt={0.5}>
        <Item
          sx={{
            height: '170px',
            overflow: 'auto'
          }}>
          <Typography
            sx={{
              fontSize: '13px',
              fontFamily: theme.palette.font.fontFamily,
              color: theme.typography.tableHeading.color,
              fontWeight: '600',
              textAlign: 'center'
            }}>
            {data?.name}
          </Typography>
          <Item
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px' }}
            mt={0.4}>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: '600',
                fontSize: '13px',
                fontFamily: theme.palette.font.fontFamily
              }}>
              {Number(data?.ratings)?.toFixed(1)}
            </Typography>

            <StarIcon sx={{ color: '#FFD700', fontSize: '18px' }} />
          </Item>
          <Item className={classes.addressFiled} md={12} xs={12} mt={0.6}>
            <Item className={classes.iconContain} sx={{ backgroundColor: selectedColor }}>
              <LocationOnOutlinedIcon className={classes.icon} fontSize="12px" />
            </Item>
            <Item md={11} xs={11}>
              {data?.address}
            </Item>
          </Item>
          <Item className={classes.addressFiled} md={12} xs={12} mt={0.6}>
            <Item className={classes.iconContain} sx={{ backgroundColor: selectedColor }}>
              <CallRoundedIcon className={classes.icon} fontSize="12px" />
            </Item>
            <Item md={11} xs={11}>
              {data?.mobileNumber?.[0] || '-'}
            </Item>
          </Item>
          <Item className={classes.addressFiled} md={12} xs={12} mt={0.6}>
            <Item className={classes.iconContain} sx={{ backgroundColor: selectedColor }}>
              <SocialDistanceIcon className={classes.icon} fontSize="12px" />
            </Item>
            <Item md={11} xs={11}>
              {data?.indivisualDistance?.toFixed(2)} Km
            </Item>
          </Item>
          <Item className={classes.addressFiled} md={12} xs={12} mt={0.6}>
            {data?.speciality?.join(' | ')}
          </Item>
        </Item>

        <Item
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Button
            onClick={() => handleMapClick(lat, lng)}
            style={{
              color: `#FFFFFF`,
              width: '45%',
              fontWeight: 'bold',
              borderRadius: '10px',
              textTransform: 'none',
              backgroundColor: selectedColor,
              fontFamily: theme.palette.font.fontFamily
            }}>
            {`Directions`}
          </Button>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(CardConatiner);
