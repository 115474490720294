import { Typography } from '@mui/material';
import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';

const { withStyles, Grid, Button } = Components;

const Item = (props) => <Grid item {...props} />;

const SorryFrom = ({ classes, handleCloseSorryFrom }) => {
  return (
    <Item
      md={12}
      sx={{
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto'
      }}>
      <Item
        md={12}
        xs={12}
        style={{
          height: '300px',
          background: 'linear-gradient(178.85deg, #FF6760 -14.19%, rgba(255, 255, 255, 0) 184.77%)',
          boxShadow: '0px 0px 4.92391px 1.40683px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px'
        }}>
        <Item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          md={12}
          xs={12}
          mt={1}>
          <img
            style={{ height: '120px', width: '120px' }}
            src={require(`../../Assets/TravelIcon/Sorry.gif`).default}
            alt="verify"
          />
        </Item>
        <Item align="center" md={12} xs={12} mt={2}>
          <Typography sx={{ textAlign: 'center', fontSize: '22px', fontWeight: '600' }}>
            {`Sorry !`}
          </Typography>

          <Typography
            mt={1}
            sx={{
              textAlign: 'center',
              fontSize: '17px',
              fontWeight: '600',
              color: 'black',
              minWidth: '380px'
            }}>
            You have not nominated in any group
          </Typography>
        </Item>
        <Item
          md={12}
          xs={12}
          mt={2}
          sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
          <Button
            style={{ ...theme.button.forward, height: '40px', fontSize: '14px' }}
            onClick={() => handleCloseSorryFrom()}>
            Back
          </Button>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(SorryFrom);
