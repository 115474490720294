import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../../utils/store';
import { Components, Icons } from '../../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR } from '../../../../../utils/api';
import { getDateTime } from '../../../../../utils/moment';
import theme from '../../../../../utils/theme';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  // Grid,
  TableCell
  // TextField
} = Components;

const { EditIcon, DeleteIcon } = Icons;

// const Item = (props) => <Grid item {...props} />;

const GroupTable = ({ classes, searchText, handleCreateGroup, handleDeleteGroup }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalGroups, setTotalGroups] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // to reset page on search
  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Get All groups
  const getAllGroups = () => {
    API_GET(
      `broadCastGroup/getAllGroup?searchText=${searchText}&limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }`
    )
      .then((res) => {
        setRows(res?.groupsByCrporateId);
        setTotalGroups(res?.groupsCount);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllGroups();
  }, [dispatch, page, rowsPerPage, searchText]);

  return (
    <React.Fragment>
      <Card className={classes.root} sx={theme.tableBorder}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.thead}>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {'No Records found'}
                  </TableCell>
                </TableRow>
              )}
              {rows &&
                rows?.map((row, rowindex) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      {columns &&
                        columns?.map((column, index) => {
                          const value = row[column.id] ?? '--';

                          if (column.id === 'date') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.createdAt ? getDateTime(row?.createdAt) : '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'createdOn') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.createdAt ? getDateTime(row?.createdAt) : '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'updatedOn') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.updatedAt ? getDateTime(row?.updatedAt) : '-'}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'action') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <EditIcon
                                  style={{ color: 'black', cursor: 'pointer' }}
                                  onClick={() => handleCreateGroup('edit', row)}
                                />
                                <DeleteIcon
                                  style={{
                                    color: `${theme.palette.primary.main}`,
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => handleDeleteGroup(row)}
                                />
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column?.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalGroups && totalGroups ? totalGroups : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </React.Fragment>
  );
};

export default withStyles(styles)(GroupTable);
