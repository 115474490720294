const styles = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(3, 2)
    },

    tableContainer: {
      height: 'auto',
      width: '100%',
      marginTop: theme.spacing(1),
      padding: theme.spacing(1, 0)
    },
    operation1: {
      height: 'auto',
      marginTop: theme.spacing(2)
    },
    pieChartHeading: {
      fontSize: '16px',
      fontFamily: theme.palette.font.fontFamily,
      padding: theme.spacing(0, 2),
      fontWeight: 'bold'
    },
    blueDataCard: {
      width: '30%',
      height: '16vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: theme.spacing(0.2, 1.5, 3, 0)
    },
    filterConatiner: {
      width: '400px',
      padding: theme.spacing(0.5)
    },
    filterConatiner3: {
      padding: theme.spacing(1, 2)
    },
    filterHeading: {
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.palette.font.fontFamily
    },
    select: {
      height: '5vh',
      marginRight: theme.spacing(3),
      fontFamily: theme.palette.font.fontFamily
    },
    checkboxText: {
      width: '50%',
      paddingTop: theme.spacing(-5)
    },
    formControl: {
      margin: theme.spacing(2),
      maxWidth: 200,
      width: 180
    },
    indeterminateColor: {
      color: '#f50057'
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    }
  };
};

export default styles;
