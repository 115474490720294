import { ResponsivePieCanvas } from '@nivo/pie';
import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;
const Item = (props) => <Grid item {...props} />;

const MyResponsivePieCanvas = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    API_GET(
      `reports/getSeverityWiseIncidentGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}&officeId=${location}`
    )
      .then((res) => {
        setRows(res?.severityWiseIncidents);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location]);

  const handleExport = () => {
    API_GET(
      `reports/downloadSeverityWiseIncidentGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}&officeId=${location}`
    )
      .then((res) => {
        const resultData = res?.severityWiseIncidents?.map((item) => {
          return {
            'Sr.No': item?.SrNo,
            Date: item?.Date,
            'Severity 10': item['Severity 10'],
            'Severity 9': item['Severity 9'],
            'Severity 8': item['Severity 8'],
            'Severity 7': item['Severity 7'],
            'Severity 6': item['Severity 6'],
            'Severity 5': item['Severity 5'],
            'Severity 4': item['Severity 4'],
            'Severity 3': item['Severity 3'],
            'Severity 2': item['Severity 2'],
            'Severity 1': item['Severity 1']
          };
        });
        downloadDataToExcelFile(
          resultData,
          `Int.Severity wise Analysis_location (${selectedFromDate} To ${selectedToDate})`
        );
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Severity Wise Incidents
          </Typography>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '230px' }}>
        <ResponsivePieCanvas
          theme={{
            fontSize: 14,
            fontWeight: '600'
          }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          // valueFormat=" >-1.1%"
          data={rows}
          margin={{ top: 10, right: 170, bottom: 20, left: 10 }}
          innerRadius={0}
          // padAngle={0.0}
          cornerRadius={0}
          activeOuterRadiusOffset={8}
          colors={{ datum: 'data.color' }}
          arcLinkLabelsSkipAngle={0}
          arcLinkLabelsText={false}
          arcLinkLabelsTextColor="black"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={5}
          arcLabelsTextColor="black"
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 80,
              itemHeight: 35,
              itemTextColor: 'black',
              itemDirection: 'left-to-right',

              symbolSize: 15
            }
          ]}
        />
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
