/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DeclinedAppointment from '../../components/organism/Table/DeclinedAppointment';
import { Components } from '../../utils/material-ui';
import theme from '../../utils/theme';
import styles from './styles';
import { useStateValue } from '../../utils/store';
import moment from 'moment';
import { useLocation } from 'react-router';

const { withStyles, Grid, Paper, TextField, Select } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const DeclinedStatus = ({ classes, searchParams }) => {
  const [, dispatch] = useStateValue();
  const [searchText, setSearchText] = useState(searchParams || '');

  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const getSearch = useLocation().search;
  const searchByParams = new URLSearchParams(getSearch).get('searchBy');

  useEffect(() => {
    if (searchByParams) {
      setSearchText(searchByParams);
    }
  }, []);

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  return (
    <Container>
      <Item md={12} mt={2}>
        <Paper className={classes.filterContainer} sx={theme.card}>
          <Item sx={{ display: 'flex', alignItems: 'center', p: 2, flexWrap: 'wrap' }} md={12}>
            <Item mt={1}>
              <TextField
                id="outlined-basic"
                label="Search by  name or phone"
                size="small"
                type="text"
                value={searchText}
                variant="outlined"
                sx={{ width: '275px' }}
                fullWidth
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Item>
            <Item sx={{ display: 'inline-flex', ml: 'auto', mr: 1, mt: 1 }}>
              <Item mr={2}>
                <form noValidate>
                  <TextField
                    id="datetime-local"
                    label="From"
                    type="date"
                    size="small"
                    value={selectedFromDate}
                    className={classes.datePicker}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => {
                      onFromDateChange(e);
                    }}
                  />
                </form>
              </Item>
              <Item>
                <form noValidate>
                  <TextField
                    id="date"
                    label="To"
                    type="date"
                    size="small"
                    value={selectedToDate}
                    className={classes.datePicker}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => {
                      onToDateChange(e);
                    }}
                  />
                </form>
              </Item>
            </Item>
          </Item>
        </Paper>
      </Item>
      <Item md={12} style={{ width: '500px' }} mt={1}>
        <DeclinedAppointment
          searchText={searchText}
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
        />
      </Item>
    </Container>
  );
};

export default withStyles(styles)(DeclinedStatus);
