import { ResponsivePieCanvas } from '@nivo/pie';
import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import theme from '../../../utils/theme';
import ExportToExcel from '../../../utils/downloadMergeColumnData';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;
const Item = (props) => <Grid item {...props} />;

// const data = [
//   { label: 'Fully', value: 60 / 100, color: '#DB3480', id: 'Fully' },
//   { label: 'Single Dose', value: 20 / 100, color: '#2C9AFF', id: 'Single Dose' },
//   {
//     label: 'No Dose',
//     value: 20 / 100,
//     color: '#1A9518',
//     id: 'No Dose'
//   },
//   { label: 'Booster', value: 10 / 100, color: '#EC7A1A', id: 'Booster' }
// ];

const MyResponsivePieCanvas = ({
  classes,
  selectedFromDate,
  selectedToDate,
  location,
  offices
}) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [exportResultData, setExportResultData] = useState([]);
  const [reportLocation, setReportsLocation] = useState([]);
  const [cityName, setCityName] = useState('All');

  useEffect(() => {
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;

    API_GET(
      `reports/getVaccineStatus?fromDate=${selectedFromDate}&todate=${selectedToDate}&${resultLocation}`
    )
      .then((res) => {
        const result = res?.data?.map((item) => {
          return {
            label: `${item?._id}`,
            color: item?.color,
            value: item?.percentage / 100,
            id: `${item?._id}`
          };
        });
        setRows(result);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location]);

  useEffect(() => {
    offices.map((item) => {
      return item?._id === location ? setCityName(item?.city) : '';
    });
  }, [location]);

  const handleExport = () => {
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;

    API_GET(
      `reports/downloadDeptWiseVaccineReport?fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultLocation}`
    )
      .then((res) => {
        setExportResultData(res?.data);
        setReportsLocation(res?.departments);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    setTimeout(() => {
      ExportToExcel(
        'Overall Vac. Status_location',
        'xlsx',
        `Overall Vac. Status_${cityName}_(${selectedFromDate} To ${selectedToDate}).xlsx`
      );
    }, 450);
  };

  const TableData = () => {
    return (
      <div id="Overall Vac. Status_location" style={{ display: 'none' }}>
        <table border="1">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Date</th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return <th colSpan={4}>{item}</th>;
                })}
            </tr>
            <tr>
              <th></th>
              <th></th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return (
                    <React.Fragment>
                      <th>1st dose</th>
                      <th>2nd dose</th>
                      <th>No dose</th>
                      <th>Booster</th>
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {exportResultData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.SrNo}</td>
                  <td>{item?.date}</td>
                  {Object.keys(item).map((subData) => {
                    return (
                      <React.Fragment>
                        {subData !== 'SrNo' && subData !== 'date' ? (
                          <React.Fragment>
                            <td>{item[subData]['1st dose']}</td>
                            <td>{item[subData]['2nd dose']}</td>
                            <td>{item[subData]['No dose']}</td>
                            <td>{item[subData].Booster}</td>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Overall Vaccination Status
          </Typography>
        </Item>
        <Item>
          {TableData()}
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '220px' }}>
        <ResponsivePieCanvas
          theme={{
            fontSize: 14,
            fontWeight: '600'
          }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          valueFormat=" >-1.1%"
          data={rows}
          margin={{ top: 20, right: 160, bottom: 20, left: 20 }}
          innerRadius={0.5}
          // padAngle={0.0}
          cornerRadius={0}
          activeOuterRadiusOffset={8}
          colors={{ datum: 'data.color' }}
          arcLinkLabelsSkipAngle={0}
          arcLinkLabelsText={false}
          arcLinkLabelsTextColor="black"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={5}
          arcLabelsTextColor="black"
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 40,
              itemTextColor: 'black',
              itemDirection: 'left-to-right',
              symbolShape: 'circle',
              symbolSize: 18
            }
          ]}
        />
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
