import React, { useEffect, useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import CountsCard from './countsCard';
import UnassignedDistribution from './unassignedDistribution';
import AssignedDistribution from './assignedDistribution';
import PendingIncidentDistribution from './PendingIncidentDistribution';
import AcceptedIncidentDistribution from './AcceptedIncidentDistribution';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';
import { useStateValue } from '../../utils/store';
import Completed from './Completed';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import suspended from './suspended';
import theme from '../../utils/theme';

const { withStyles, Grid, FormControlLabel, TextField, Box } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const IncidentDistribution = ({ classes }) => {
  const cards = [
    { value: 'unassigned', label: 'Unassigned', table: UnassignedDistribution },
    { value: 'assigned', label: 'Assigned', table: AssignedDistribution },
    { value: 'pending', label: 'Pending', table: PendingIncidentDistribution },
    { value: 'accepted', label: 'Accepted', table: AcceptedIncidentDistribution },
    { value: 'completed', label: 'Completed', table: Completed },
    { value: 'suspended', label: 'Suspended', table: suspended }
  ];
  const [store, dispatch] = useStateValue();
  const [selectedCard, setSelectedCard] = useState(cards[0]);
  const [loading, setLoading] = React.useState(false);
  const [chat, setChat] = React.useState(false);
  const [paramId, setParamId] = React.useState('');
  const [search, setSearch] = useState('');
  const [tempValue, setTempValue] = useState('');
  const getSearch = useLocation().search;

  const nobe = new URLSearchParams(getSearch).get('nobe');
  const name = new URLSearchParams(getSearch).get('tab');
  const searchByParams = new URLSearchParams(getSearch).get('searchBy');
  const chatopen = new URLSearchParams(getSearch).get('isOpenchat');
  const id = new URLSearchParams(getSearch).get('id');

  useEffect(() => {
    if (name) {
      cards?.map((item) => {
        if (item?.value === name) {
          setSelectedCard(item);
        }
        return null;
      });
    }
    if (searchByParams) {
      setSearch(searchByParams);
    }
    if (chatopen === true) {
      setChat(chatopen);
    } else {
      setChat(chatopen);
    }
    if (id) {
      setParamId(id);
    }
  }, []);

  useEffect(() => {
    if (nobe === 'true') {
      setLoading(true);
    } else {
      setLoading(store.isMove);

      dispatch({
        type: 'IS_MOVE',
        isMove: false
      });
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTempValue(search);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [search]);

  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  return (
    <Container direction="column" className={classes.mainContainer}>
      <Item sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          type="tablesearch"
          className={classes.tablesearch}
          value={search}
          size="small"
          sx={{ width: '320px' }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search by Ticket Number and Incident"
        />

        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <Item sx={{ display: 'flex', gap: '10px' }}>
            <form noValidate>
              <TextField
                id="datetime-local"
                label="From"
                type="date"
                size="small"
                value={selectedFromDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onFromDateChange(e);
                }}
              />
            </form>

            <Item>
              <form noValidate>
                <TextField
                  id="date"
                  label="To"
                  type="date"
                  size="small"
                  value={selectedToDate}
                  className={classes.datePicker}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onToDateChange(e);
                  }}
                />
              </form>
            </Item>

            <Typography
              onClick={() => setLoading(false)}
              sx={{
                mt: 1,
                color: `${loading ? 'black' : theme.palette.primary.main}`,
                cursor: 'pointer'
              }}>
              Internal Risk
            </Typography>
            <FormControlLabel
              sx={{
                display: 'block'
              }}
              control={
                <Switch
                  checked={loading}
                  onChange={() => setLoading(!loading)}
                  name="loading"
                  color="primary"
                />
              }
              label={
                <span style={{ color: `${loading ? theme.palette.primary.main : 'black'}` }}>
                  External Risk
                </span>
              }
            />
          </Item>
        </Item>
      </Item>

      <Box sx={{ flexGrow: 1 }}>
        <Container justify="space-between" alignItems="center" spacing={2} sx={{ mt: 0.3 }}>
          {cards.map((item) => (
            <Item key={item.value}>
              <CountsCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
            </Item>
          ))}
        </Container>
      </Box>

      <Item md={12} mt={2}>
        <selectedCard.table
          isIncident={loading}
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
          search={tempValue}
          chat={chat}
          name={name}
          paramId={paramId}></selectedCard.table>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(IncidentDistribution);
