import { Components, Icons } from '../../../utils/material-ui';
import React, { useState, useEffect } from 'react';
import theme from '../../../utils/theme';
import overviewcss from './overviewcss';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
// import { API_HANDLE_ERROR, API_GET } from '../../../utils/api';
import { BarChart, Bar, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
// import theme from '../../../utils/theme';
// import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

// const data = [
//   {
//     value: 12,
//     name: 'Fully',
//     fill: '#009688'
//   },
//   {
//     // name: 'Page B',
//     value: 8,
//     fill: '#3B9DF7',
//     name: 'Single Dose'
//   },
//   {
//     // name: 'Page C',
//     value: 3,
//     fill: '#7493D8',
//     name: 'Booster'
//   },
//   {
//     // name: 'Page D',
//     value: 16,
//     fill: '#EF7FA5',
//     name: 'No dose'
//   }
// ];

// const exportData = [
//   {
//     'Sr No': 1,
//     Date: '22-05-2022',
//     Completed: 10,
//     Pending: 12,
//     Delayed: 15,
//     'Non-Initiated': 6,
//     Suspended: 17,
//     Unassigned: 10,
//     Ongoing: 15,
//     'Completed with Delay': 15,
//     Initiated: 10
//   },
//   {
//     'Sr No': 2,
//     Date: '23-05-2022',
//     Completed: 10,
//     Pending: 12,
//     Delayed: 15,
//     'Non-Initiated': 6,
//     Suspended: 17,
//     Unassigned: 10,
//     Ongoing: 15,
//     'Completed with Delay': 15,
//     Initiated: 10
//   }
// ];

const Barcharts = ({ classes, location, region }) => {
  const [data, setData] = useState([]);
  const [, dispatch] = useStateValue();

  const getVaccinationStatus = () => {
    const resultRegion = region === 'all' ? '' : `region=${region}`;
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;
    API_GET(`travelPackage/getVaccineStatus?${resultRegion}&${resultLocation}`)
      .then((res) => {
        const result = res?.data?.map((item) => {
          return {
            ...item,
            fill: item?.color
          };
        });
        setData(result);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getVaccinationStatus();
  }, [location, region]);

  const handleDownload = () => {
    API_GET(`travelpackage/downloadVaccinationStatusReport?region=${region}&officeId=${location}`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res?.message || 'Success'
        });
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Vaccination Status
          </Typography>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer' }} onClick={handleDownload} />
        </Item>
      </Item>
      <Item sx={{ width: '100%', display: 'flex' }} md={12}>
        <Item md={9} sx={{ height: '200px', p: 1 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              height={450}
              data={data}
              margin={{
                top: 17,
                right: 10,
                left: -20,
                bottom: 0
              }}>
              <CartesianGrid strokeDasharray="2 2" />
              <YAxis />

              <Bar
                dataKey="count"
                stackId="count"
                fill="fill"
                barSize={50}
                radius={[0, 0, 0, 0]}
                // unit="%"
                label={{ position: 'top', fontSize: '14px' }}
              />
            </BarChart>
          </ResponsiveContainer>
        </Item>
        <Item
          md={3}
          style={{
            height: '200px'
          }}>
          {data?.map((item) => {
            return (
              <Item md={12} mt={1.2}>
                <Item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    flexDirection: 'row',
                    height: '25px'
                  }}>
                  <Item
                    style={{
                      width: '1rem',
                      height: '1rem',
                      borderRadius: 2,
                      backgroundColor: item?.fill
                    }}></Item>
                  <h4 style={{ fontSize: '14px', marginLeft: '0.3rem' }}>{item?._id}</h4>
                </Item>
              </Item>
            );
          })}
        </Item>
      </Item>
    </React.Fragment>
  );
};

export default withStyles(overviewcss)(Barcharts);
