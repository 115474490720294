const styles = (theme) => {
  return {
    mainContainer: {
      // backgroundColor: theme.palette.common.gray98,
      padding: theme.spacing(2)
    },
    subContainer: {
      // backgroundColor: theme.palette.common.gray98,
      padding: theme.spacing(0, 2)
    },

    newUser: {
      height: 'auto',
      borderRadius: theme.spacing(2)
    },

    cardHeading: {
      padding: '7px 20px',
      backgroundColor: theme.palette.primary.tableHeadingColor,
      borderRadius: '10px 10px 0 0',
      color: theme.palette.common.white,
      textAlign: 'left'
    },

    newUserForm: {
      display: 'flex',
      padding: theme.spacing(0, 2)
    },

    formContainer: {
      height: 'auto',
      minHeight: '69vh',
      display: 'flex',
      justifyContent: 'center'
    },

    form1: {
      borderRadius: '8px',
      boxShadow: '0px 0px 4px lightGrey',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '15px',
      maxHeight: '62vh'
    },
    formContainer1: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    branchContainer: {
      maxHeight: '75vh',
      overflow: 'auto',
      padding: theme.spacing(0.5)
    },
    editContainer: {
      cursor: 'pointer',
      height: '30px',
      width: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      p: 1
    },

    inputContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      borderRadius: '12px',
      padding: theme.spacing(5, 5, 0, 5),
      minHeight: '60vh'
    },
    inputText: {
      color: theme.typography.tableHeading.color
    },
    inputText1: {
      color: '#818181',
      marginTop: theme.spacing(0.5)
    },
    errorText: {
      color: 'red'
    },
    cardsContainer: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },
    colorPointer: {
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      cursor: 'pointer'
    },
    themeContainer: {
      height: '180px',
      border: '1px solid #DCDCDC',
      borderRadius: '10px'
    },
    themeImg: {
      height: '130px',
      objectFit: 'cover',
      width: '228px',
      borderTopLeftRadius: '9px',
      borderTopRightRadius: '9px',
      marginTop: '0.5px'
    },
    carousalContainer: {
      display: 'flex',
      maxWidth: '85vw',
      overflow: 'hidden',
      alignItems: 'center'
    },
    imageContain: {
      height: '120px',
      width: '120px',
      borderRadius: '25px',
      border: '2px dotted grey',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      cursor: 'pointer'
    },
    smallContainer: {
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '8px',
      alignItems: 'center',
      color: theme.palette.primary.main,
      backgroundColor: theme.headingBgColor,
      minWidth: '100px',
      padding: theme.spacing(0.5),
      borderRadius: '3px'
    },
    imgProfile: {
      objectFit: 'cover',
      height: '120px',
      width: '120px',
      borderRadius: '25px',
      border: `4px solid ${theme.headingBgColor}`
    },
    branchCard: {
      width: '100%',
      display: 'flex'
    },
    addressFiled: {
      display: 'flex',
      alignItems: 'center',
      gap: '7px',
      fontSize: '14px'
    },
    iconContain: {
      height: '18px',
      width: '18px',
      borderRadius: '10px',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    icon: {
      color: '#FFFFFF'
    }
  };
};

export default styles;
