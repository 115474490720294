import React, { useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import CountsCard from './countsCard';
import OngoingIncidents from '../OngoingIncidents';
import PendingIncidents from '../PendingIncidents';
import ClosedIncidents from '../ClosedIncidents';
import theme from '../../utils/theme';

const { withStyles, Grid, Paper } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Alerts = ({ classes }) => {
  // const [, dispatch] = useStateValue();
  const cards = [
    { value: 'onGoing', label: 'On Going', table: OngoingIncidents },
    { value: 'pending', label: 'Pending', table: PendingIncidents },
    { value: 'closed', label: 'Closed', table: ClosedIncidents }
  ];

  const [selectedCard, setSelectedCard] = useState(cards[0]);

  return (
    <Container spacing={1} className={classes.mainContainer}>
      <Item md={1.75}>
        <Paper elevation={3} sx={theme.card}>
          <Container direction="column" sx={{ padding: '20px', height: '85vh' }}>
            {cards.map((item) => (
              <Item key={item.value} sx={{ marginBottom: '20px', width: '100%' }}>
                <CountsCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
              </Item>
            ))}
          </Container>
        </Paper>
      </Item>
      <Item md={10.25}>
        <Paper sx={theme.card}>
          <selectedCard.table></selectedCard.table>
        </Paper>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Alerts);
