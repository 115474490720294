const head = [
  {
    id: 'publishDate',
    label: 'Pub. Date & Time',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'eventDate',
    label: 'Event Date & Time',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'ticketNumber',
    label: 'Ticket No',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center'
  },
  {
    id: 'event',
    label: 'Event',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },

  {
    id: 'incidentLocation',
    label: 'Location',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
