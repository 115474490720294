import React, { useState } from 'react';
import { Components, Icons } from '../../../../../utils/material-ui';
import styles from './styles';
import { ResponsivePieCanvas } from '@nivo/pie';
import { getDateTime } from '../../../../../utils/moment';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';

const { withStyles, Grid, Select, MenuItem, FormControl, Typography, InputLabel } = Components;
const { ClearIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

// const data = [
//   {
//     label: '14% Yes',
//     value: 14,
//     color: '#BEB3FB',
//     id: 'Yes'
//   },
//   {
//     value: 56,
//     id: 'No',
//     color: '#C0E5FF',
//     label: '56%  No'
//   },
//   {
//     value: 6,
//     color: '#FFC1D6',
//     id: 'May be',
//     label: '6%  May be'
//   },
//   {
//     value: 10,
//     color: '#B6ECC5',
//     id: 'No response',
//     label: '10%  No response'
//   }
// ];

function BroadcastGraphModel({ classes, handleClose, pieChartData }) {
  const [selectedValue, setSelectedValue] = useState(pieChartData[0]);

  const noResponseObj = {
    value: selectedValue?.noResPercentage?.toFixed(1),
    color: '#BEB3FB',
    id: 'No Response',
    label: `${selectedValue?.noResPercentage?.toFixed(1)}% No Response`
  };

  const result = Object.keys(selectedValue?.ctaInfo)?.map((item, index) => {
    return {
      value: selectedValue?.ctaInfo[item]?.percentage?.toFixed(1),
      color: index === 0 ? '#B6ECC5' : index === 1 ? '#C0E5FF' : index === 2 ? '#FFC1D6' : 'grey',
      id: ConvertToUpperCase(item),
      label: `${selectedValue?.ctaInfo[item]?.percentage?.toFixed(1)}% ${ConvertToUpperCase(item)}`
    };
  });

  const resultGraphData = [...result, noResponseObj];

  return (
    <Container direction="column" wrap="nowrap" className={classes.popupContainer}>
      <Item sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ ml: '30%', fontWeight: '600' }} variant="h6">
          Broadcast Response
        </Typography>
        <Item sx={{ ml: 'auto' }}>
          <ClearIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
        </Item>
      </Item>
      <Item mt={2} md={12} xs={12}>
        <Item className={classes.filterContainer}>
          <Typography sx={{ fontSize: '17px' }}>Employees Safety</Typography>
          <Item sx={{ ml: 'auto' }}>
            <FormControl variant="outlined" size="small" sx={{ width: '300px' }}>
              <InputLabel id="demo-simple-select-label">Date</InputLabel>
              <Select
                name="Date"
                fullWidth
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
                label="Date">
                {pieChartData?.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {getDateTime(item?.date)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Item>
        </Item>

        <Item md={12} xs={12} mt={1} p={1}>
          <Item className={classes.healthCard}>
            <Item sx={{ height: '100%', width: '65%' }}>
              <ResponsivePieCanvas
                theme={{
                  fontSize: 15,
                  fontWeight: '600'
                }}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                data={resultGraphData}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                innerRadius={0}
                padAngle={0}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                colors={{ datum: 'data.color' }}
                arcLinkLabelsSkipAngle={2}
                arcLinkLabelsText={false}
                arcLinkLabelsTextColor="black"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={5}
                arcLabelsTextColor="black"
              />
            </Item>
            <Item
              sx={{
                width: '35%',
                display: 'flex',
                alignItem: 'center',
                flexDirection: 'column'
              }}
              pt={5}>
              {resultGraphData?.map((item) => {
                return (
                  <Item style={{ display: 'flex', alignItem: 'center', gap: '10px' }} mt={2}>
                    <Item
                      sx={{ height: '22px', width: '22px', backgroundColor: item?.color }}></Item>
                    <Typography sx={{ fontSize: '0.9rem', fontWeight: '600' }}>
                      {item?.label}
                    </Typography>
                  </Item>
                );
              })}
            </Item>
          </Item>
        </Item>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(BroadcastGraphModel);
