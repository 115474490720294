const styles = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(4, 2),
      height: 'auto'
      // border: '1px solid red'
    },

    emergencyCardContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      borderRadius: '15px',
      gap: '2%',
      flexWrap: 'wrap'
    },

    card: {
      height: '140px',
      width: '280px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '15px',
      cursor: 'pointer',
      margin: 'auto',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
    labelText: {
      fontSize: '20px',
      textAlign: 'center',
      color: '#FFFFFF',
      fontFamily: theme.palette.font.fontFamily,
      fontWeight: '600'
    }
  };
};

export default styles;
