import React, { useState } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../../../utils/store';
import { API_HANDLE_ERROR, API_POST } from '../../../../utils/api';
import theme from '../../../../utils/theme';

const { withStyles, Grid, Button, Typography, TextField } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function NoActionModal({ classes, handleClose, rejectId, isIncident, status }) {
  const [, dispatch] = useStateValue();

  const [rejectData, setRejectData] = useState({
    reasonsToReject: ''
  });

  const handleStateChange = (key, value) => {
    setRejectData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();

    if (status === 'completed') {
      const postdata = {
        actionMessage: rejectData.reasonsToReject,
        incidentActionStatus: 'completed',
        employeeIdsArray: []
      };
      API_POST(
        `incident/dynamicIncidentAssignment/${rejectId}?historyType=${
          isIncident ? 'alert' : 'incident'
        }`,
        postdata
      )
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else if (status === 'unassigned') {
      const postdata = {
        suspendedRemark: rejectData.reasonsToReject,
        incidentActionStatus: 'suspended'
      };
      API_POST(
        `incident/dynamicIncidentAssignment/${rejectId}?historyType=${
          isIncident ? 'alert' : 'incident'
        }`,
        postdata
      )
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      const postdata = {
        actionMessage: rejectData.reasonsToReject,
        incidentActionStatus: 'noAction'
      };
      API_POST(
        `incident/dynamicIncidentAssignment/${rejectId}?historyType=${
          isIncident ? 'alert' : 'incident'
        }`,
        postdata
      )
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: '350px' }}>
      <Item className={classes.title}>
        <Typography style={{ textAlign: 'center' }}>
          {status === 'completed' ? 'Completed' : status === 'unassigned' ? 'Suspend' : 'No Action'}
        </Typography>
      </Item>
      <form onSubmit={onHandleConfirm}>
        <Item sx={{ p: 1 }}>
          <Item>
            <TextField
              variant="outlined"
              name="reasonsToReject"
              label={status === 'completed' || status === 'unassigned' ? 'Remark' : 'Reason'}
              required={true}
              className={classes.formControl}
              multiline
              rows={5}
              onChange={(e) => handleStateChange('reasonsToReject', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>
        </Item>
        <Item sx={{ display: 'flex', gap: '10px', p: 1 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
              Cancel
            </Button>
          </Item>
          <Item>
            {status === 'completed' ? (
              <Button
                type="submit"
                style={{
                  ...theme.button.submit,
                  backgroundColor: theme.palette.primary.main
                }}>
                {`Complete`}
              </Button>
            ) : (
              <Button
                type="submit"
                style={{
                  ...theme.button.submit,
                  backgroundColor: theme.palette.primary.main
                }}>
                {`Submit`}
              </Button>
            )}
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(NoActionModal);
