const styles = (theme) => {
  return {
    root: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px'
    },
    container: {
      height: 330,
      overflow: 'auto'
      // padding: theme.spacing(0)
    },

    tableHeader: {},
    actionBtn: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& a': {
        textDecoration: 'none',
        color: theme.palette.common.white
      }
    },

    boldFont: {
      fontWeight: 'bold'
    },
    heading: {
      fontSize: '12px',
      color: theme.palette.primary.textTableColor,
      fontWeight: '500',
      padding: theme.spacing(1)
    }
  };
};

export default styles;
