const head = [
  {
    id: 'select',
    label: 'Select',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'left'
  },
  {
    id: 'header',
    label: 'Header',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'message',
    label: 'Message',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'date',
    label: 'Created On',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'createdBy',
    label: 'Created By',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },

  {
    id: 'ctanames',
    label: 'CTA Name',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 70,
    width: 70,
    maxWidth: 70,
    align: 'center'
  }
];

export default head;
