import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { Components } from '../../../../utils/material-ui';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { Typography } from '@mui/material';
import theme from '../../../../utils/theme';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import { useStateValue } from '../../../../utils/store';
import { getDate } from '../../../../utils/moment';

const {
  withStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  Card
} = Components;

const headData = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center'
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'title',
    label: 'External Incident',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'cityFromDror',
    label: 'Location',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'rPridection',
    label: 'Risk Prediction',
    minWidth: 160,
    width: 160,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'status',
    label: 'Status',
    minWidth: 160,
    width: 160,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'revEarned',
    label: 'Rev. Earned',
    minWidth: 160,
    width: 160,
    maxWidth: 200,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

const RiskEvaluationTable = ({
  classes,
  selectedFromDate,
  selectedToDate,
  getRiskData,
  category,
  location
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRiskEvaluation, setTotalRiskEvaluation] = useState(0);
  const [data, setData] = useState([]);
  const [, dispatch] = useStateValue();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getBudgetEvaluation = () => {
    const resultCategory = category === 'All' ? '' : `category=${category}`;
    const resultLocation = location === 'All' ? '' : `city=${location}`;
    API_GET(
      `reports/getRiskEvaluation?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultCategory}&${resultLocation}`
    )
      .then((res) => {
        setTotalRiskEvaluation(res?.count);
        getRiskData(res);
        setData(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getBudgetEvaluation();
  }, [selectedFromDate, selectedToDate, category, dispatch, page, rowsPerPage, location]);

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Typography
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.primary.tableHeadingColor,
          p: 1,
          color: 'white',
          fontWeight: 'bold',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        }}>
        Risk Evaluation
      </Typography>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {headData &&
                headData.map((column, index) => (
                  <StyledTableCell
                    colSpan={column.id === 'rPridection' ? 3 : 1}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>

            <TableRow>
              {columns &&
                columns.map((column, index) => (
                  <StyledTableCell
                    colSpan={column.id === 'rPridection' ? 3 : 1}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, padding: theme.spacing(0.5) }}>
                    {column.id === 'revenue' ||
                    column.id === 'humanWeightage' ||
                    column.id === 'infraWeightage'
                      ? column.label
                      : ''}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {data &&
              data.map((row, rowIndex) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    {columns &&
                      columns.map((column, index) => {
                        const value = row[column.id] ?? '--';
                        if (column.id === 'date') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {getDate(row?.createdAt) || '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'revenue') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.revenue ? `$${row?.revenue?.toFixed(1)}` : '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'humanWeightage') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.humanWeightage ? `${row?.humanWeightage?.toFixed(1)}%` : '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'infraWeightage') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.infraWeightage ? `${row?.infraWeightage?.toFixed(1)}%` : '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'revEarned') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.revEarned ? `$${row?.revEarned}` : '-'}
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(ConvertToUpperCase(value))
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalRiskEvaluation && totalRiskEvaluation ? totalRiskEvaluation : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};
export default withStyles(styles)(RiskEvaluationTable);
