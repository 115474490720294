import { getDate, getTime } from '../../../../utils/moment';
import React from 'react';

const SuperAdminRsaTable = ({ data }) => {
  return (
    <table border="1">
      <thead>
        <tr>
          <th>Name</th>
          <th> Phone Number</th>
          <th>Email ID</th>
          <th>City</th>
          <th>State</th>
          <th>Corporate Name</th>
          <th>Internal Id</th>

          <th colSpan="12" align="center">
            Vehicles Details
          </th>
        </tr>
        <tr>
          <th> </th>
          <th></th>
          <th></th>
          <th> </th>
          <th></th>
          <th></th>
          <th></th>

          <th>Serial Number</th>
          <th>Veh Edit On Date</th>
          <th>Veh Edit On</th>
          <th>Reg No</th>
          <th>Brand</th>
          <th>Model</th>
          <th>Fuel Type</th>
          <th>Transmission</th>
          <th>Chassis No</th>
          <th>Vehicle Type</th>
          <th>Vehicle Registration Date</th>
          <th>Vehicle Manufacturing Date</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, reIndex) => {
          // item.vehiclesOwned?.reverse();
          return item?.hasVehicles === true ? (
            <React.Fragment>
              <tr key={reIndex}>
                <td rowSpan={item?.vehiclesOwned?.length}>{item?.fullName}</td>
                <td rowSpan={item?.vehiclesOwned?.length}>{item?.mobileNumber}</td>
                <td rowSpan={item?.vehiclesOwned?.length}> {item?.email}</td>
                <td rowSpan={item?.vehiclesOwned?.length}>{item?.currentAddress?.city}</td>
                <td rowSpan={item?.vehiclesOwned?.length}>{item?.currentAddress?.state}</td>
                <td rowSpan={item?.vehiclesOwned?.length}>{item?.corporateName}</td>
                <td rowSpan={item?.vehiclesOwned?.length}>{item?.internalId}</td>

                <td>{item?.vehiclesOwned && 'Veh 1'}</td>
                <td>
                  {item?.vehiclesOwned && item.vehiclesOwned[0]?.updatedAt
                    ? getDate(item?.vehiclesOwned[0]?.updatedAt)
                    : '-'}
                </td>
                <td>
                  {item?.vehiclesOwned && item.vehiclesOwned[0]?.updatedAt
                    ? getTime(item?.vehiclesOwned[0]?.updatedAt)
                    : '-'}
                </td>
                <td>{item?.vehiclesOwned && item.vehiclesOwned[0]?.regNumber}</td>
                <td>{item?.vehiclesOwned && item.vehiclesOwned[0]?.brand}</td>
                <td>{item?.vehiclesOwned && item.vehiclesOwned[0]?.model}</td>
                <td>{item?.vehiclesOwned && item.vehiclesOwned[0]?.fuelType}</td>
                <td>{item?.vehiclesOwned && item.vehiclesOwned[0]?.transmission}</td>
                <td>{item?.vehiclesOwned && item.vehiclesOwned[0]?.chassisNumber}</td>
                <td>{item?.vehiclesOwned && item.vehiclesOwned[0]?.vehicleType}</td>
                <td>
                  {item?.vehiclesOwned && item.vehiclesOwned[0]?.registrationDate
                    ? getDate(item.vehiclesOwned[0]?.registrationDate)
                    : '-'}
                </td>
                <td>
                  {item?.vehiclesOwned && item.vehiclesOwned[0]?.manufactureDate
                    ? getDate(item.vehiclesOwned[0]?.manufactureDate)
                    : '-'}
                </td>
              </tr>
              {item?.vehiclesOwned?.map((item, index) => {
                return index !== 0 ? (
                  <tr key={index}>
                    <td>{`Veh ${index + 1}`}</td>
                    <td>{item?.updatedAt ? getDate(item?.updatedAt) : '-'}</td>
                    <td>{item?.updatedAt ? getTime(item?.updatedAt) : '-'}</td>
                    <td>{item?.regNumber}</td>
                    <td>{item?.brand}</td>
                    <td>{item?.model}</td>
                    <td>{item?.fuelType}</td>
                    <td>{item?.transmission}</td>
                    <td>{item?.chassisNumber}</td>
                    <td>{item?.vehicleType}</td>
                    <td>{item?.registrationDate ? getDate(item?.registrationDate) : '-'}</td>
                    <td>{item?.manufactureDate ? getDate(item?.manufactureDate) : '-'}</td>
                  </tr>
                ) : (
                  ''
                );
              })}
            </React.Fragment>
          ) : (
            <tr>
              <td>{item?.fullName}</td>
              <td>{item?.mobileNumber}</td>
              <td> {item?.email}</td>
              <td>{item?.currentAddress?.city}</td>
              <td>{item?.currentAddress?.state}</td>
              <td>{item?.corporateName}</td>
              <td>{item?.internalId}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SuperAdminRsaTable;
