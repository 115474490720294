const head = [
  {
    id: 'publishedDate',
    label: 'Pub. Date&Time',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'eventDate',
    label: 'Event Date&Time',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'ticketNumber',
    label: 'Ticket No',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'event',
    label: 'Event',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'forwardedTo',
    label: 'Incident Log',
    minWidth: 130,
    width: 130,
    maxWidth: 130,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'incidentLocation',
    label: 'Location',
    minWidth: 130,
    width: 130,
    maxWidth: 130,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'actualDate',
    label: 'Completion Date',
    minWidth: 130,
    width: 130,
    maxWidth: 130,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'incidentActionStatus',
    label: 'Global Status',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
