import { useState } from 'react';
// import Dialog from '../../../atom/Dialog';
import { Components } from '../../../../utils/material-ui';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from '@mui/styles';
import theme from '../../../../utils/theme';

const { Grid, Button } = Components;
const Item = (props) => <Grid item {...props} />;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    boxShadow: theme.shadows[5],
    borderRadius: '10px'
  }
}));

export default function InfoModal({ component, item }) {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();

  return (
    <Item
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        setModalOpen(!modalOpen);
      }}>
      {item}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Backdrop in={modalOpen}>
          <Item className={classes.paper}>
            {component}
            <Item sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
              <Button
                style={{ backgroundColor: theme.palette.primary.main, color: '#FFFFFF' }}
                onClick={() => setModalOpen(false)}>
                close
              </Button>
            </Item>
          </Item>
        </Backdrop>
      </Modal>
    </Item>
  );
}
