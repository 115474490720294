const agentscss = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(0, 2),
      justifyContent: 'space-between'
    },
    misContainer: {
      minHeight: '80vh',
      padding: theme.spacing(1)
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5, 1),
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      gap: '20px'
    }
  };
};

export default agentscss;
