import React, { useState, useEffect } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from './styles';
import CountCard from './countCard';
import profile from './profile';
import password from './password';
import notification from './notification';
import customization from './customization';
import subscription from './subscription';
import safeLocation from './safeLocation';
import appointment from './appointment';
import deactivatedUser from './deactivatedUser';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import theme from '../../../utils/theme';

const { withStyles, Grid, useMediaQuery } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const CompanyProfile = ({ classes }) => {
  const [store] = useStateValue();

  const cards =
    store?.corporateId === theme.corporate.mliCorporateId
      ? [
          { value: 'profile', label: 'Profile', table: profile },
          {
            value: 'password',
            label: 'Password',
            table: password
          },
          { value: 'customize', label: 'Customize', table: customization },
          { value: 'dactivatedUsers', label: 'Deactivated users', table: deactivatedUser }
        ]
      : [
          { value: 'profile', label: 'Profile', table: profile },
          {
            value: 'password',
            label: 'Password',
            table: password
          },
          { value: 'notification', label: 'Notification', table: notification },
          { value: 'customize', label: 'Customize', table: customization },
          { value: 'subscriptionAndPayment', label: 'Subscription', table: subscription },
          { value: 'safeLocation', label: 'Safe Location', table: safeLocation },
          { value: 'appointment', label: 'Appointment', table: appointment },
          { value: 'dactivatedUsers', label: 'Deactivated users', table: deactivatedUser }
        ];

  const [, dispatch] = useStateValue();
  const [selectedCard, setSelectedCard] = useState(cards[0]);
  const [data, setData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrrenSizeSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? cards.length : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === cards.length ? 0 : prevIndex + 1));
  };

  const getUserData = () => {
    API_GET('user/getUserDetailsV2')
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getUserData();
  }, [dispatch]);

  const showDiv = scrrenSizeSmall ? 3 : 7;

  return (
    <React.Fragment>
      <Container className={classes.mainContainer}>
        <Item
          md={12}
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}
          mt={1}>
          {currentIndex !== 0 && cards?.length > 4 ? (
            <ArrowBackIosIcon
              onClick={handlePrevious}
              sx={{ color: theme.typography.tableHeading.color, cursor: 'pointer' }}
            />
          ) : (
            <ArrowBackIosIcon sx={{ color: '#9F9F9F' }} />
          )}
          <Item className={classes.carousalContainer}>
            {cards?.slice(currentIndex, currentIndex + 7).map((item) => (
              <Item key={item.value}>
                <CountCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
              </Item>
            ))}
          </Item>
          {currentIndex !== showDiv && cards?.length > 4 ? (
            <ArrowForwardIosIcon
              onClick={handleNext}
              sx={{ color: theme.typography.tableHeading.color, cursor: 'pointer' }}
            />
          ) : (
            <ArrowForwardIosIcon sx={{ color: '#9F9F9F' }} />
          )}
        </Item>
      </Container>

      <Item md={12} xs={12}>
        <selectedCard.table data={data} getUserData={getUserData}></selectedCard.table>
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(CompanyProfile);
