const styles = (theme) => {
  return {
    root: {
      width: '100%',
      margin: 'auto',
      // paddingTop: theme.spacing(0.5),
      marginTop: theme.spacing(0.5)
    },
    container: {
      height: 560,
      overflow: 'auto'
    },
    tableHeader: {},
    actionBtn: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& a': {
        textDecoration: 'none',
        color: theme.palette.common.white
      }
    },

    boldFont: {
      fontWeight: 'bold'
    },
    heading: {
      fontSize: '16px',
      color: theme.palette.primary.textTableColor,
      fontWeight: '500',
      padding: theme.spacing(2, 1)
    }
    // thead: {
    //   backgroundColor: 'lightgray',
    //   '& th:first-child': {
    //     borderRadius: '0.8em 0 0 0.8em'
    //   },
    //   '& th:last-child': {
    //     borderRadius: '0 0.8em 0.8em 0'
    //   }
    // }
  };
};

export default styles;
