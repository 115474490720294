import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useStateValue } from './utils/store';
import { io } from 'socket.io-client';
import { socket } from './config';
import Alert from './components/organism/Snackbar';

// ******************** Pages ********************
import { PrivateRoute } from './utils/route';
import Login from './pages/Login';
import PasswordReset from './pages/PasswordReset';
import NotFound from './pages/NotFound/index.js';
import Screen from './utils/screen.js';

// ******************** Style ********************
import './App.css';
import 'rsuite/dist/rsuite.css';
// import 'rsuite/lib/styles/themes/default/index.less';

// import 'rsuite/lib/styles/index.less';
import Alerts from './pages/Alerts';
import Home from './pages/LatestPages/Home';
import IncidentDistribution from './pages/IncidentDistribution';
import EmergencyLocation from './pages/EmergencyLocation';
import SosDashboard from './pages/SosDashboard';
import ErrorBoundary from './components/ErrorBoundary.jsx';
import SuperAdminDashboard from './pages/SuperAdminDashboard/SuperAdminDashboard';
import Appointments from './pages/Appointments';
import Logout from './pages/Logout';
import ReportsLatest from './pages/ReportsLatest';
import TravelManagement from './pages/TravelManagement';
import AgentFrom from './pages/AgentFrom';
import CompanyProfileDetails from './pages/CompanyProfileDetails';
import Happiness from './pages/Happiness';
import BroadcastNew from './pages/BroadcastNew';
import AllEmployees from './pages/SuperAdminDashboard/AllEmployees';
import UserSetting from './pages/UserSetting';
import Settings from './pages/LatestPages/settings';
import { API_GET, API_HANDLE_ERROR } from './utils/api';
import theme from './utils/theme';
import { usePosition } from './pages/IndiviualLocation/geoLoaction';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js';

const App = () => {
  const [store, dispatch] = useStateValue();
  const [role] = useState(store.role);
  const [routes, setRoutes] = useState([]);
  const boxRef = useRef();
  const { latitude, longitude } = usePosition();

  useEffect(() => {
    if (latitude && longitude) {
      localStorage.setItem('lat', JSON.stringify(latitude));
      localStorage.setItem('lng', JSON.stringify(longitude));
    }
  }, [latitude, longitude]);

  const path = window.location.pathname;

  // idle timer logic

  const onIdle = () => {
    console.log('entered idle');
    // navigate('/logout');
    window.location.href = '/logout';
  };
  const timer = useIdleTimer({
    onIdle,
    // onActive,
    // onAction,
    timeout: 60000 * 20
    // promptBeforeIdle: 5000
    // throttle: 500
  });

  console.log(timer);

  useEffect(() => {
    setRoutes(routeComponent(role));
    const socketIO = io(`${process.env.REACT_APP_APP_URL}/report-incident`, socket.config);
    socketIO.on('connect', () => {
      console.log('Socket connected successfully!!');
    });
    dispatch({
      type: 'SOCKET',
      socketIO: socketIO
    });
  }, [role]);

  const getTheme = () => {
    path !== '/' &&
      path !== '/login' &&
      path !== '/login/forgetPassword' &&
      API_GET(`corporateThemeConfig/getCorporateTheme`)
        .then((res) => {
          const globalColor = res?.themeColor;
          localStorage.setItem('color', JSON.stringify(globalColor || 'FF6760'));
          theme.button.noAction.color = `#${globalColor}`;
          theme.headingBgColor = `#${res?.lightThemeColor}`;
          theme.button.noAction.border = `1px solid #${globalColor}`;
          theme.button.forward.color = `#${globalColor}`;
          theme.button.initiate.backgroundColor = `#${globalColor}`;
          theme.button.trash.color = `#${globalColor}`;
          theme.button.trash.border = `1px solid #${globalColor}`;
          theme.button.submit.backgroundColor = `#${globalColor}`;
          theme.button.cancel.color = `#${globalColor}`;
          theme.button.cancel.border = `1px solid #${globalColor}`;
          theme.palette.primary.main = `#${globalColor}`;
          theme.palette.primary.secondtheme = `#${globalColor}`;
          theme.palette.primary.tableHeadingColor = `#${globalColor}`;
          theme.palette.primary.maintextColor = `#${globalColor}`;
          theme.countsCard.selectedCardColor.backgroundColor = `#${globalColor}`;
        })

        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  useEffect(() => {
    // window.onclick = (event) => {
    //   if (event.target.contains(boxRef.current) && event.target !== boxRef.current) {
    //     console.log(`You clicked Outside the box!`);
    //   } else {
    getTheme();
    // }
    // };
  }, []);

  return (
    <ErrorBoundary>
      <Alert />
      <Router>
        {/* <NotificationHandler /> */}
        <Routes ref={boxRef}>
          <Route exact path="/" key="/" element={<Login />} />
          <Route path="/login" key="/login" element={<Login />} />
          <Route path="/login/forgetPassword" key="/forgetPassword" element={<Login />} />
          <Route path="/logout" key="/logout" element={<Logout />} />
          <Route
            exact
            path="/passwordReset"
            key="/passwordReset"
            element={<PasswordReset />}></Route>
          ,{routes}
          <Route path="*" key="" element={<NotFound />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
};

function routeComponent(role) {
  switch (role) {
    case 'superAdmin':
      return superAdmin();
    case 'admin':
      return admin();
    case 'sosAdmin':
      return sosAdmin();
    case 'agent':
      return Agent(role);
    case 'user':
      return User(role);
    default:
      return null;
  }
}

function superAdmin() {
  return [
    <Route
      exact
      path="/dashboard"
      key="/dashboard"
      element={<PrivateRoute element={Screen('Dashboard', <SuperAdminDashboard />)} />}></Route>,
    <Route
      exact
      path="/allEmployees"
      key="/allEmployees"
      element={<PrivateRoute element={Screen('User', <AllEmployees />)} />}></Route>
  ];
}

function sosAdmin() {
  return [
    <Route
      exact
      path="/sosDashboard"
      key="/sosDashboard"
      element={<PrivateRoute element={Screen('SOS Dashboard', <SosDashboard />)} />}></Route>,
    <Route
      exact
      path="/emergency-locations"
      key="/emergency-locations"
      element={<PrivateRoute element={Screen('Safe Locations', <EmergencyLocation />)} />}></Route>
  ];
}
function Agent() {
  return [
    <Route
      exact
      path="/Agent-Panel"
      key="/Agent-Panel"
      element={<PrivateRoute element={Screen('Agent', <AgentFrom />)} />}></Route>
  ];
}
function User() {
  return [
    <Route
      exact
      path="/Employee-Panel"
      key="/Employee-Panel"
      element={<PrivateRoute element={Screen('Employee', <AgentFrom />)} />}></Route>
  ];
}

function admin() {
  return [
    <Route
      exact
      path="/user"
      key="/user"
      element={<PrivateRoute element={Screen('User', <UserSetting />)} />}></Route>,
    <Route
      exact
      path="/alerts"
      key="/alerts"
      element={<PrivateRoute element={Screen('Alerts', <Alerts />)} />}></Route>,
    <Route
      exact
      path="/home"
      key="/home"
      element={<PrivateRoute element={Screen(`Welcome`, <Home />)} />}></Route>,
    <Route
      exact
      path="/Happiness-assessment"
      key="/Happiness-assessment"
      element={
        <PrivateRoute element={Screen(`Happiness Index Dashboard`, <Happiness />)} />
      }></Route>,

    <Route
      exact
      path="/incident-distribution"
      key="/incident-distribution"
      element={
        <PrivateRoute
          element={Screen('External & Internal Risk Distribution', <IncidentDistribution />)}
        />
      }></Route>,
    <Route
      exact
      path="/emergency-locations"
      key="/emergency-locations"
      element={<PrivateRoute element={Screen('Safe Haven', <EmergencyLocation />)} />}></Route>,
    <Route
      exact
      path="/reports"
      key="/reports"
      element={<PrivateRoute element={Screen('Reports', <ReportsLatest />)} />}></Route>,
    <Route
      exact
      path="/company-profile"
      key="/company-profile"
      element={
        <PrivateRoute element={Screen('Company Profile', <CompanyProfileDetails />)} />
      }></Route>,
    <Route
      exact
      path="/broadcast"
      key="/broadcast"
      element={<PrivateRoute element={Screen('Broadcast', <BroadcastNew />)} />}></Route>,
    <Route
      exact
      path="/appointments"
      key="/appointments"
      element={<PrivateRoute element={Screen('Appointments', <Appointments />)} />}></Route>,
    <Route
      exact
      path="/travel-management"
      key="/travel-management"
      element={
        <PrivateRoute element={Screen('Travel Management', <TravelManagement />)} />
      }></Route>,
    <Route
      exact
      path="/settings"
      key="/settings"
      element={<PrivateRoute element={Screen('Settings', <Settings />)} />}></Route>
  ];
}

export default App;
