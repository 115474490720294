const head = [
  {
    id: 'select',
    label: 'Select',
    minWidth: 70,
    width: 70,
    maxWidth: 70,
    align: 'center'
  },
  {
    id: 'agentId',
    label: 'Agent Id',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'uniqueId',
    label: 'Emp Id',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'packageId',
    label: 'Group Id',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'packageName',
    label: 'Group Name',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
  },
  {
    id: 'gender',
    label: 'Gender/Age',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'emailId',
    label: 'Email Id/Alt.email id',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'phoneNumber',
    label: 'Phone no/Alt.Ph.no',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'country',
    label: 'Country',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  // {
  //   id: 'place',
  //   label: 'Place',
  //   minWidth: 120,
  //   width: 120,
  //   maxWidth: 120,
  //   align: 'center'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'pax',
    label: 'Dependents',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'packageType',
    label: 'Group Type',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'pendingDocs',
    label: 'Pending Docs',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'expiredDocs',
    label: 'Expired Docs',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'tobeExpire',
    label: 'To Be Expire Docs',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  }
];

export default head;
