import React, { useState, useEffect, useRef } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { Components, Icons } from '../../../../../utils/material-ui';
import tableColor from '../../../../../utils/tableColor';
import Dialog from '../../../../atom/Dialog';
import PackageDataWithIdTable from '../PackageDataWithIdTable';
import { getDate } from '../../../../../utils/moment';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import downloadDataToExcel from '../../../../../utils/downloadDataToExcelFile';
import { useStateValue } from '../../../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../../../utils/api';
import DocsDownloadModel from '../../../Modal/DocsDownloadModel';
import theme from '../../../../../utils/theme';
const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Checkbox,
  Typography,
  TextField
} = Components;
const { KeyboardArrowUpIcon, KeyboardArrowDownIcon } = Icons;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const TravelledTable = ({ classes, selectedFromDate, selectedToDate, packageType, sort }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalEmployees, setTotalEmployees] = React.useState(0);
  const [checkboxData, setCheckboxData] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isOpenPackageModel, setIsOpenPackageModel] = useState(false);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [isOpenMultipleDocsModel, setIsOpenMultipleDocsModel] = useState(false);
  const [isLoadingDocs, setIsLoadingDocs] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setIsOpenPackageModel(false);
    setIsOpenMultipleDocsModel(false);
  };

  const handlePackageData = () => {
    setIsOpenPackageModel(true);
  };

  const getAllPendings = () => {
    const resultPackage = packageType === 'All' ? '' : `packageType=${packageType}`;
    selectedFromDate = selectedFromDate === 'Invalid date' ? '' : selectedFromDate;
    selectedToDate = selectedToDate === 'Invalid date' ? '' : selectedToDate;
    API_GET(
      `travelPackage/getAllNominatedEmployees?travelled=true&limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}&fromDate=${selectedFromDate}&toDate=${selectedToDate}&dynamicSort=${sort}&${resultPackage}`
    )
      .then((res) => {
        const checkboxObject = {};
        res?.data?.map((item, index) => (checkboxObject[index] = false));
        setCheckboxData(checkboxObject);
        setSelectAll(false);
        setRows(res?.data);
        setTotalEmployees(res?.count);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllPendings();
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchText,
    selectedFromDate,
    selectedToDate,
    packageType,
    sort
  ]);

  let selectedCount = 0;
  if (checkboxData) {
    Object.keys(checkboxData).map((item) =>
      checkboxData[`${item}`] === true ? selectedCount++ : selectedCount
    );
  }

  const handleSelectAll = (event) => {
    const boolean = event.target.checked;
    const keys = Object.keys(checkboxData);
    const object = {};
    keys.map((item) => {
      return (object[item] = boolean);
    });
    setCheckboxData(object);
    setSelectAll(boolean);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxData({
      ...checkboxData,
      [event.target.name]: event.target.checked
    });
    setSelectAll(false);
  };

  const mapData = (data) => {
    const resultData = data?.map((item) => {
      return {
        'Agent Id': item?.agentId,
        'Emp Id': item?.employeeId,
        'Group Name': item?.packageName,
        'Group Id': item?.packageId,
        Name: item?.name,
        Gender: item?.gender,
        Age: item?.age,
        'Email Id/Alt.email id': item?.emailId,
        'Phone no/Alt.Ph.no': item?.phoneNumber,
        Country: item?.country,
        Place: item?.place,
        'Start Date': getDate(item?.startDate),
        'End Date': getDate(item?.endDate),
        Pax: item?.pax,
        'Group Type': item?.packageType
      };
    });
    return resultData;
  };

  const handleDownload = () => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const approveDeclinedIds = filteredKeys.map((item) => rows[item]).flat();

    if (approveDeclinedIds.length > 0) {
      const resultData = mapData(approveDeclinedIds) || [];
      downloadDataToExcel(
        resultData,
        `Travelled Employee GroupType-(${packageType} (${selectedFromDate} To ${selectedToDate}))`
      );
      setIsActive(false);
    } else {
      const resultPackage = packageType === 'All' ? '' : `packageType=${packageType}`;
      selectedFromDate = selectedFromDate === 'Invalid date' ? '' : selectedFromDate;
      selectedToDate = selectedToDate === 'Invalid date' ? '' : selectedToDate;
      API_GET(
        `travelPackage/getAllNominatedEmployees?travelled=true&searchText=${searchText}&fromDate=${selectedFromDate}&toDate=${selectedToDate}&dynamicSort=${sort}&${resultPackage}`
      )
        .then((res) => {
          const resultData = mapData(res?.data);
          if (resultData.length >= 1) {
            downloadDataToExcel(
              resultData,
              `Travelled Employee GroupType-(${packageType} (${selectedFromDate} To ${selectedToDate}))`
            );
            setIsActive(false);
          } else {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'No data found for download'
            });
          }
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  // handleMultiple docs Download

  const handleMultipleDocsDownload = () => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const selectedEmployeeData = filteredKeys.map((item) => rows[item]).flat();
    if (selectedEmployeeData.length === 0) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Select atleast one Entry !'
      });
      setIsActive(false);
    } else if (selectedEmployeeData.length > 20) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'For documents download min. 1 and max. 20 employees/agents must be selected.'
      });
      setIsActive(false);
    } else {
      setIsOpenMultipleDocsModel(true);
      setIsActive(false);
    }
  };
  const handleLoader = (status) => {
    setIsLoadingDocs(status);
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Item className={classes.filterBar}>
        <Item>
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: '600'
            }}>{`List of Agents `}</Typography>
          <Typography sx={{ fontSize: '12px' }} variant="h4">
            {`Total Nominated Agent (${totalEmployees})`}
          </Typography>
        </Item>
        <Item>
          <TextField
            id="outlined-basic"
            label="Search by Group Id and Group Name"
            size="small"
            value={searchText}
            variant="outlined"
            sx={{ width: '325px' }}
            fullWidth
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Item>
        <div className="menu-container">
          <button
            onClick={() => setIsActive(!isActive)}
            className="menu-trigger"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.primary.main}`
            }}>
            <span>Download</span>
            <div
              className="menu-Icon"
              style={{ color: '#FFFFFF', backgroundColor: theme.palette.primary.main }}>
              {isActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
          </button>
          <Item ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
            <ul>
              <li>
                <Item className="list" onClick={handleDownload}>
                  Excel File
                </Item>
              </li>
              <li>
                <Item className="list" onClick={handleMultipleDocsDownload}>
                  Documents
                </Item>
              </li>
            </ul>
          </Item>
        </div>

        {/* <Item sx={{ display: 'flex', gap: '10px' }}>
          <DownloadForOfflineIcon
            className={classes.icon}
            fontSize="large"
            onClick={handleDownload}
          />
          </Item> */}
      </Item>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.id === 'select' ? (
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        style={{ color: 'white' }}
                      />
                    ) : (
                      column.label
                    )}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows.map((row, rowindex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(rowindex) }}>
                    {columns &&
                      columns.map((column) => {
                        const value = row[column.id] ?? '--';

                        if (column.id === 'select') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              <Checkbox
                                color="primary"
                                checked={checkboxData[`${rowindex}`]}
                                onChange={handleCheckboxChange}
                                name={`${rowindex}`}
                              />
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'uniqueId') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.role !== 'agent' ? row?.employeeId || row?.uniqueId : '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'name') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {ConvertToUpperCase(row?.name)}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'startDate') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>{getDate(row?.startDate)}</Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'endDate') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>{getDate(row?.endDate)}</Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'gender') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>
                                {[ConvertToUpperCase(row?.gender), row?.age].join('/') || '-'}
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'packageId') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item
                                sx={{ textDecoration: 'underline black', cursor: 'pointer' }}
                                onClick={() => handlePackageData()}>
                                {row?.packageId}
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalEmployees && totalEmployees ? totalEmployees : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        isOpen={isOpenPackageModel}
        children={<PackageDataWithIdTable handleClose={handleClose} />}></Dialog>
      <Dialog
        isOpen={isOpenMultipleDocsModel}
        children={
          <DocsDownloadModel
            handleClose={handleClose}
            checkboxData={checkboxData}
            rows={rows}
            handleLoader={handleLoader}
            isLoadingDocs={isLoadingDocs}
          />
        }></Dialog>

      {/*  <Dialog
        isOpen={isRemainderModelOpen}
            children={<RemainderModel handleClose={handleClose} />}></Dialog> */}
    </Card>
  );
};

export default withStyles(styles)(TravelledTable);
