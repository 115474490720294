import React, { useEffect, useState } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { Components } from '../../../../utils/material-ui';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { Typography } from '@mui/material';
import theme from '../../../../utils/theme';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import { useStateValue } from '../../../../utils/store';

const {
  withStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  Card
} = Components;

const BudgetEvaluationTable = ({
  classes,
  selectedFromDate,
  selectedToDate,
  getBudgetData,
  location,
  category
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalBudgetEvaluation, setTotalBudgetEvaluation] = useState(0);
  const [data, setData] = useState([]);
  const [, dispatch] = useStateValue();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getBudgetEvaluation = () => {
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;
    const resultCategory = category === 'All' ? '' : `category=${category}`;
    API_GET(
      `reports/getBudgetEvaluation?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultLocation}&${resultCategory}`
    )
      .then((res) => {
        setTotalBudgetEvaluation(res?.count);
        getBudgetData(res);
        setData(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getBudgetEvaluation();
  }, [selectedFromDate, selectedToDate, dispatch, page, rowsPerPage, category, location]);

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Typography
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.primary.tableHeadingColor,
          p: 1,
          color: 'white',
          fontWeight: 'bold',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        }}>
        Budget Evaluation
      </Typography>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {data &&
              data.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    // style={{ backgroundColor: tableColor(rowIndex) }}
                  >
                    {columns &&
                      columns.map((column, index) => {
                        const value = row[column.id] ?? '--';

                        if (column.id === 'srNo') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              {rowIndex + 1}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'department') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              {row?.category || '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'title') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.title || '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'requestedBudget') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.requestedBudget || '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'approvedBudget') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.approvedBudget || '-'}
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(ConvertToUpperCase(value))
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalBudgetEvaluation && totalBudgetEvaluation ? totalBudgetEvaluation : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};
export default withStyles(styles)(BudgetEvaluationTable);
