import React, { useState, useEffect, useRef } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import { API_GET, API_HANDLE_ERROR, API_UPLOAD_FILE } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import AddOffices from '../../components/organism/Modal/AddOffices';
import Dialog from '../../components/atom/Dialog';
import theme from '../../utils/theme';
import DownloadDataToExcelFile from '../../utils/downloadDataToExcelFile';
import Map from './map';
import CallRoundedIcon from '@mui/icons-material/CallRounded';

const {
  withStyles,
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress,
  Card,
  TextField,
  InputAdornment
} = Components;
const { LocationOnOutlinedIcon, LanguageOutlinedIcon, EditIcon, SearchIcon, DirectionsIcon } =
  Icons;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const Branches = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState('');
  const [loading, setLoading] = useState(false);
  const [corporateName, setCorporateName] = useState('');
  const hiddenFileInput = useRef(null);
  const [searchText, setSearchText] = useState('');

  const getAllOffices = () => {
    API_GET('corporate/getCorporate')
      .then((res) => {
        setCorporateName(res?.data?.name);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const getCorporate = () => {
    API_GET(`office/getAllOffices?searchText=${searchText}`)
      .then((res) => {
        setData(res?.allOffices);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getCorporate();
  }, [dispatch, isOpen, isEdit, searchText]);

  useEffect(() => {
    getAllOffices();
  }, [dispatch, loading]);

  const handleAddOfficeModal = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsEdit(false);
  };
  const handleMapView = (lat, lng) => {
    window.open(`https://maps.google.com?q=${lat},${lng}`);
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setEditId(item);
  };

  const getAddress = (type1, type2, type3) => {
    let str = '';
    if (type1) {
      str += type1 + ', ';
    }
    if (type2) {
      str += type2 + ', ';
    }
    if (type3) {
      str += type3;
    }

    return str;
  };

  // import file
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    uploadfile(fileUploaded);
    event.target.value = '';
  };

  const uploadfile = (fileUploaded) => {
    setLoading(true);
    API_UPLOAD_FILE(`office/importOffices`, fileUploaded)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        setLoading(false);
        getCorporate();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);

        setLoading(false);
      });
  };

  // export Data

  const handleDownload = () => {
    const resultData = data?.map((item, index) => {
      const coordinates = item?.location?.coordinates;
      const long = coordinates && coordinates[1];
      const lat = coordinates && coordinates[0];
      return {
        SrNo: index + 1,
        'Company Name': item?.name,
        'Type Of Office': item?.officeType,
        'Branch Code': item?.officeCode,
        Email: item?.emailId,
        Website: item?.website,
        'Company Address': item?.address,
        Country: item?.country,
        State: item?.state,
        City: item?.city,
        'Pin Code': item?.pincode,
        'Contact Number': item?.phone,
        Location: `${long},${lat}`,
        'Profile Img': item?.branchImage,
        Departments: item?.office
      };
    });
    DownloadDataToExcelFile(resultData, 'Offices Details');
  };

  return (
    <Container direction="column" className={classes.subContainer}>
      <Item
        md={12}
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
        <Item md={4} xs={12}>
          <TextField
            placeholder="Search by Location and Office Name"
            size="small"
            variant="outlined"
            fullWidth
            sx={theme.customTextFiled}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Item>
        <Item
          sx={{
            display: 'inline-flex',
            ml: 'auto',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}>
          {loading ? (
            <Button
              size="small"
              style={{
                ...theme.button.filterButton
              }}>
              <CircularProgress sx={{ color: 'red' }} size={20} />
              Importing
            </Button>
          ) : (
            <Button
              size="small"
              style={{
                ...theme.button.filterButton
              }}
              onClick={handleClick}>
              Import
            </Button>
          )}
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept=".xlsx, .xls, .csv"
          />
          <Button
            onClick={handleDownload}
            size="small"
            style={{
              ...theme.button.filterButton
            }}>
            Export
          </Button>

          <Button
            onClick={handleAddOfficeModal}
            size="small"
            style={{
              ...theme.button.filterButton
            }}>
            Add Office
          </Button>
        </Item>
      </Item>
      <Item className={classes.formContainer1} md={12} xs={12} mt={2}>
        <Item md={5.9} xs={12} className={classes.branchContainer}>
          {data?.map((item, index) => {
            return (
              <Paper
                className={classes.branchCard}
                key={index}
                style={{
                  ...theme.card,
                  marginTop: index !== 0 && '20px',
                  padding: theme.spacing(1),
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)'
                }}>
                <Item
                  sx={{
                    width: '42%',
                    borderRadius: '10px'
                  }}>
                  <img
                    alt="logo"
                    src={
                      item?.branchImage ||
                      'https://img.freepik.com/free-photo/modern-office-space-interior_158595-5206.jpg'
                    }
                    width="100%"
                    height="100%"
                    style={{
                      borderRadius: '10px',
                      objectFit: 'cover'
                    }}
                  />
                </Item>
                <Item sx={{ width: '63%', ml: 1.5 }}>
                  <Item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Item sx={{ height: '30%', width: '100%' }}>
                      <Typography
                        style={{
                          fontWeight: '600',
                          fontSize: '20px',
                          color: theme.typography.tableHeading.color
                        }}>
                        {corporateName}
                      </Typography>
                    </Item>
                    <Item className={classes.editContainer} onClick={() => handleEdit(item)}>
                      <EditIcon sx={{ color: theme.typography.tableHeading.lightColor }} />
                    </Item>
                  </Item>

                  <Item sx={{ color: theme.typography.tableHeading.lightColor }}>
                    <Typography
                      sx={{
                        fontSize: '17px'
                      }}>{`${item?.name} | Branch Code - ${item?.officeCode}`}</Typography>
                    <Item className={classes.addressFiled} sx={{ fontSize: '14px' }}>
                      <Item sx={{ mt: 0.5 }} className={classes.iconContain}>
                        <LocationOnOutlinedIcon className={classes.icon} fontSize="12px" />
                      </Item>
                      <Item>
                        <Typography>{getAddress(item?.address, item?.city)}</Typography>
                        <Typography>
                          {getAddress(item?.state, item?.country, item?.pincode)}
                        </Typography>
                      </Item>
                    </Item>
                  </Item>
                  <Item
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      mt: 0.5,
                      fontSize: '14px',
                      color: theme.typography.tableHeading.lightColor
                    }}>
                    <Item className={classes.addressFiled}>
                      <Item className={classes.iconContain}>
                        <CallRoundedIcon className={classes.icon} fontSize="12px" />
                      </Item>
                      <Item>{item?.phone}</Item>
                    </Item>

                    <Item className={classes.addressFiled} mt={0.5}>
                      <Item className={classes.iconContain}>
                        <LanguageOutlinedIcon className={classes.icon} fontSize="12px" />
                      </Item>
                      <a
                        style={{
                          color: theme.typography.tableHeading.lightColor,
                          textDecoration: 'underlined'
                        }}
                        href={
                          !item?.website?.includes('https')
                            ? `https://${item?.website}`
                            : item?.website
                        }
                        target="_blank">
                        {item?.website}
                      </a>
                    </Item>
                    <Button
                      style={{
                        ...theme.button.submit,
                        borderRadius: '5px',
                        width: '130px',
                        marginTop: theme.spacing(1)
                      }}
                      onClick={() =>
                        handleMapView(
                          item?.location?.coordinates && item?.location?.coordinates[1],
                          item?.location?.coordinates && item?.location?.coordinates[0]
                        )
                      }>
                      <DirectionsIcon sx={{ mr: 1 }} />
                      Direction
                    </Button>
                  </Item>
                </Item>
              </Paper>
            );
          })}
        </Item>
        <Item md={5.9} xs={12} p={0.5}>
          <Card
            style={{
              ...theme.card,
              width: '100%',
              height: '75vh',
              borderRadius: '10px'
            }}>
            <Map data={data} />
          </Card>
        </Item>
      </Item>
      <Dialog isOpen={isOpen} children={<AddOffices handleClose={handleClose} />}></Dialog>
      <Dialog
        isOpen={isEdit}
        children={<AddOffices handleClose={handleClose} editData={editId} edit={true} />}></Dialog>
    </Container>
  );
};

export default withStyles(styles)(Branches);
