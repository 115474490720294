import { Components, Icons } from '../../../utils/material-ui';
import React, { useEffect, useState } from 'react';
import theme from '../../../utils/theme';
import styles from '../styles';
import { useStateValue } from '../../../utils/store';
import { API_HANDLE_ERROR, API_GET } from '../../../utils/api';
import { useTooltipContext } from './CustomToolTip/customToolTip';
import { BarChart, Bar, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

const Barcharts = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState();
  const { openTooltip, closeTooltip } = useTooltipContext();

  const tooltipContent = (e) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <div style={{ height: '16px', width: '16px', backgroundColor: e.fill }}></div>
        {`${e.name} :` + e.count}
      </div>
    );
  };

  const tooltipStyle = {
    backgroundColor: 'white',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '1%',
    fontFamily: 'helvetica, sans-serif',
    fontSize: '16px',
    padding: '.5%'
  };

  useEffect(() => {
    const resultLocation = location === 'All' ? '' : `city=${location}`;
    API_GET(
      `reports/externalIncidentWorkFlow?fromDate=${selectedFromDate}&toDate=${selectedToDate}&downloadReport=false&${resultLocation}`
    )
      .then((res) => {
        setRows(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location]);

  const handleExport = () => {
    const resultLocation = location === 'All' ? '' : `city=${location}`;
    API_GET(
      `reports/externalIncidentWorkFlow?fromDate=${selectedFromDate}&toDate=${selectedToDate}&downloadReport=true&${resultLocation}`
    )
      .then((res) => {
        if (res?.data.length === 0) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'No data found for download'
          });
        } else {
          downloadDataToExcelFile(
            res?.data,
            `Ext. work flow_location (${selectedFromDate} To ${selectedToDate})`
          );
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            External Incident work-flow Status
          </Typography>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '130px', width: '100%' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            // width={600}
            height={350}
            data={rows}
            margin={{
              top: 20,
              right: 10,
              left: -20,
              bottom: 0
            }}>
            <CartesianGrid strokeDasharray="2 2" />
            <YAxis />

            <Bar
              dataKey="count"
              stackId="count"
              fill="fill"
              barSize={30}
              radius={[0, 0, 0, 0]}
              // unit="%"
              label={{ position: 'top' }}
              onMouseEnter={(e) =>
                openTooltip({
                  content: tooltipContent(e),
                  style: tooltipStyle
                })
              }
              onMouseLeave={() => closeTooltip()}
            />
          </BarChart>
        </ResponsiveContainer>
      </Item>

      <Item
        md={12}
        style={{
          height: '90px',
          width: '100%',
          overflow: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          padding: '5px 10px 5px 40px'
        }}>
        {rows?.map((item) => {
          return (
            <Item md={4}>
              <Item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  flexDirection: 'row',
                  height: '25px'
                }}>
                <Item
                  style={{
                    width: '0.9rem',
                    height: '0.9rem',
                    borderRadius: 2,
                    backgroundColor: item?.fill
                  }}></Item>
                <h4 style={{ fontSize: '11px', marginLeft: '0.2rem' }}>{item?.name}</h4>
              </Item>
            </Item>
          );
        })}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(Barcharts);
