import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../utils/store';
import { Components } from '../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import theme from '../../../../utils/theme';
import Dialog from '../../../atom/Dialog';
import AddEmployee from '../../Modal/AddEmployee';
import { Android12Switch, TotalCount } from '../../../../utils/ButtonDiff';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  FormControlLabel,
  Checkbox
  // Button
} = Components;

const Item = (props) => <Grid item {...props} />;

const DeactivatedUserTable = ({
  classes,
  searchText,
  selectedFromDate,
  selectedToDate,
  sort,
  filtersCategory
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalEmployess, setTotalEnployees] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [checkboxData, setCheckboxData] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isOpenEmployeeModal, setIsOpenEmployeeModal] = useState(false);
  const [editData, setEditData] = useState();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let selectedCount = 0;
  if (checkboxData) {
    Object.keys(checkboxData).map((item) =>
      checkboxData[`${item}`] === true ? selectedCount++ : selectedCount
    );
  }

  const handleSelectAll = (event) => {
    const boolean = event.target.checked;
    const keys = Object.keys(checkboxData);
    const object = {};
    keys.map((item) => {
      return (object[item] = boolean);
    });
    setCheckboxData(object);
    setSelectAll(boolean);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxData({
      ...checkboxData,
      [event.target.name]: event.target.checked
    });
    setSelectAll(false);
  };

  useEffect(() => {
    getAllUsers();
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchText,
    sort,
    selectedFromDate,
    selectedToDate,
    filtersCategory,
    isOpenEmployeeModal
  ]);

  const getAllUsers = () => {
    let resultRole = '';
    for (let i = 0; i < filtersCategory?.length; i++) {
      if (filtersCategory[i]?.type === 'Role') {
        resultRole += `&roleFilter[]=${filtersCategory[i]?.name}`;
      }
      if (filtersCategory[i]?.type === 'Deactivated By') {
        resultRole += `&fullNames[]=${filtersCategory[i]?.name}`;
      }
    }
    API_GET(
      `user/getEmployees?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}&activated=false&dynamicSort=name&order=${sort}${resultRole}`
    )
      .then((res) => {
        const checkboxObject = {};
        res?.data?.employees?.map((item, index) => (checkboxObject[index] = false));
        const result = res?.data?.employees?.map((data) => {
          return { ...data, value: false };
        });
        setCheckboxData(checkboxObject);
        setSelectAll(false);
        setRows(result);
        setTotalEnployees(res?.data?.totalEmployees);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleClose = () => {
    setIsOpenEmployeeModal(false);
    const result = rows?.map((snew) => {
      return { ...snew, value: false };
    });
    setRows(result);
  };

  const handleActivate = (item) => {
    setIsOpenEmployeeModal(true);
    setEditData(item);
    const result = rows?.map((snew) => {
      return snew?._id === item?._id ? { ...snew, value: true } : { ...snew, value: false };
    });
    setRows(result);
  };

  return (
    <React.Fragment>
      <Item mt={1.5}>{TotalCount('Deactivate Users', `Total Users -${totalEmployess}`)}</Item>

      <Card className={classes.root} sx={theme.tableBorder}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                position: 'sticky',
                top: 0,
                // background: 'blue',
                zIndex: 1,
                backgroundColor: theme.headingBgColor
              }}>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.id === 'select' ? (
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          style={{ color: theme.typography.tableHeading.heading }}
                        />
                      ) : (
                        column.label
                      )}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {'No Records found'}
                  </TableCell>
                </TableRow>
              )}
              {rows &&
                rows.map((row, rowindex) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      {columns &&
                        columns.map((column, index) => {
                          const value = row[column.id] ?? '--';

                          if (column.id === 'select') {
                            return (
                              <StyledTableCell align="left" key={column.id}>
                                <Checkbox
                                  color="primary"
                                  checked={checkboxData[`${rowindex}`]}
                                  onChange={handleCheckboxChange}
                                  name={`${rowindex}`}
                                />
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'action') {
                            return (
                              <StyledTableCell align="center" key={column.id}>
                                <Item>
                                  <FormControlLabel
                                    control={
                                      <Android12Switch
                                        checked={row?.value}
                                        onChange={() => handleActivate(row)}
                                        size="medium"
                                        sx={{ ml: 1.5 }}
                                      />
                                    }
                                  />
                                </Item>
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(ConvertToUpperCase(value))
                                : ConvertToUpperCase(value)}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalEmployess && totalEmployess ? totalEmployess : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          isOpen={isOpenEmployeeModal}
          children={
            <AddEmployee
              handleClose={handleClose}
              data={editData}
              isEditModal={isOpenEmployeeModal}
              activated={true}
            />
          }></Dialog>
      </Card>
    </React.Fragment>
  );
};

export default withStyles(styles)(DeactivatedUserTable);
