const overviewcss = (theme) => {
  return {
    graphs: {
      height: '100%',
      width: '100%',
      borderRadius: '16px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
    },
    ContainerCard: {
      height: '260px'
    },
    ContainerCard1: {
      height: '230px'
    },
    riskGraph: {
      height: '200px',
      width: '100%',
      margin: 'auto'
      // border: '1px solid red'
    },
    cardsContain: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      padding: '0px 10px'
    },
    lengendContainer: {
      height: '90px',
      width: '100%',
      overflow: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: '5px 10px 5px 40px'
    },
    headingContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0.2, 0.5)
      // flexWrap: 'wrap'
    },
    documentGraphContainer: {
      height: '200px',
      maxHeight: '200px',
      overflow: 'auto',
      padding: theme.spacing(0, 1)
    },

    allocationCardImg: {
      height: '100%',
      width: '80px',
      border: '1px solid blue'
    },
    centerAlign: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    cardsPieText: {
      width: '14px',
      height: '14px',
      borderRadius: '50%'
    }
  };
};

export default overviewcss;
