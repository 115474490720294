import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';

import { useStateValue } from '../../../../utils/store';
import styles from './styles';
import { getTime } from '../../../../utils/moment';
import { API_GET, API_PATCH } from '../../../../utils/api';
import { sosServices } from '../../../../config';
import theme from '../../../../utils/theme';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import OnlineIcon from '../../../../pages/OngoingIncidents/OnlineIcon';

const {
  withStyles,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Button,
  InputLabel,
  Select,
  MenuItem
} = Components;
const {
  RoomIcon,
  Phone,
  WatchIcon,
  AccountCircleIcon,
  ApartmentIcon,
  PersonIcon,
  DirectionsCarIcon,
  ContactEmergencyIcon,
  AccountBoxIcon
} = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function AcknowledgementForm({ classes, data, handleClose }) {
  const [store, dispatch] = useStateValue();
  const [confirmationData, setConfirmation] = useState({
    type: 'fake',
    category: '',
    service: [],
    comment: '',
    informedTo: 'none'
  });
  const serviceObject = {};

  sosServices.map((item) => {
    serviceObject[`${item}`] = false;
    return null;
  });

  const [services, setServices] = useState(serviceObject);
  const [categories, setCategories] = useState([]);

  const showInMapClicked = (lat, lng) => {
    window.open(`https://maps.google.com?q=${lat},${lng}`);
  };

  useEffect(() => {
    API_GET('category/categoryList')
      .then((res) => {
        setCategories(
          res.data.categories.map((label) => {
            return {
              label
            };
          })
        );
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: err[0]?.message || 'Oops!!, Please try again'
        });
      });
  }, [dispatch]);

  const handleStateChange = (key, value) => {
    if (key === 'service') {
      setServices({ ...services, [value]: !services[`${value}`] });
      setConfirmation((prevState) => {
        const indx = prevState[`${key}`].indexOf(value);
        if (indx === -1) {
          prevState[`${key}`].push(value);
        } else {
          prevState[`${key}`].splice(indx, 1);
        }
        return { ...prevState };
      });
    } else {
      setConfirmation((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    API_PATCH('report-incident/confirmation', {
      incidentId: data.id,
      userId: data.userId,
      ...confirmationData
    })
      .then((res) => {
        store.socketIO.emit('ended', {
          incidentId: data.id,
          messageData: res.data.messageData
        });
        handleClose();
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: err.data.errors[0]?.message || 'Oops!!, Please try again'
        });
      });
  };

  let resultVehicle = [
    data?.vehicleData?.regNumber,
    data?.vehicleData?.vehicleType,
    data?.vehicleData?.fuelType
  ].filter((item) => item);

  let resultParent = [
    ConvertToUpperCase(data?.parentData?.fullName),
    data?.parentData?.internalId
  ].filter((item) => item);

  console.log('resultParent', resultParent);

  resultVehicle = resultVehicle.length >= 1 ? resultVehicle.join(', ') : '-';
  resultParent = resultParent.length >= 1 ? resultParent.join(', ') : '-';

  const resultData = [
    {
      name: `${data.name}, ${data.gender[0].toUpperCase() + data.gender.slice(1)} `,
      Icon: AccountCircleIcon
    },
    {
      name: data?.internalId,
      Icon: AccountBoxIcon
    },
    {
      name: getTime(data.recieved),
      Icon: WatchIcon
    },
    {
      name: ConvertToUpperCase(data?.role),
      Icon: PersonIcon
    },
    {
      name: resultParent,
      Icon: ContactEmergencyIcon
    },
    {
      name: data.number,
      Icon: Phone
    },
    {
      name: data.address,
      type: 'address',
      Icon: RoomIcon
    },
    {
      name: resultVehicle,
      Icon: DirectionsCarIcon
    },
    {
      name: data.corporateName,
      Icon: ApartmentIcon
    }
  ].filter((item) => {
    return item?.name !== '-';
  });
  return (
    <Container direction="column" className={classes.acknowledgementModal}>
      <Item className={classes.cardHeading}>
        <Item sx={{ display: 'flex', alignItems: 'center' }}>
          <OnlineIcon offlineSos={data.offlineSos}></OnlineIcon>
          <Typography ml={1} variant="h6">
            Incident
          </Typography>
        </Item>

        <Item>{data.timer}</Item>
      </Item>
      <Item className={classes.middleContainer}>
        <Container direction="row" className={classes.formRow}>
          {resultData?.map((item, index) => {
            const isAddress = item.type === 'address';
            return (
              <Item xs={12} md={4} mt={1.5} key={index}>
                <Item
                  style={{ cursor: isAddress ? 'pointer' : 'default' }}
                  onClick={() => {
                    console.log(item);
                    if (isAddress) {
                      showInMapClicked(data.lat, data.lng);
                    }
                  }}
                  sx={{ display: 'flex', gap: '5px' }}>
                  <Item>
                    <item.Icon
                      size="medium"
                      style={{
                        color: theme.palette.primary.sosDashboard,
                        fontSize: '28px'
                      }}></item.Icon>
                  </Item>
                  <Item>
                    <Typography
                      sx={{
                        fontWeight: index === 0 ? '500' : '400',
                        fontSize: index === 0 ? '18px' : '16px'
                      }}>
                      {item?.name}
                    </Typography>
                  </Item>
                </Item>
              </Item>
            );
          })}

          <Item md={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }} mt={2}>
            <Item md={5} xs={12}>
              <Typography variant="h7" sx={{ fontSize: '19px' }}>
                Emergency Contacts
              </Typography>
            </Item>
            <Item md={7} xs={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Emergency Contacts</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="emergency select"
                  label="Emergency Contacts">
                  {data &&
                    data.emergencyContacts &&
                    data.emergencyContacts.map((item) => {
                      return (
                        <MenuItem
                          style={{
                            cursor: 'auto'
                          }}>{`${item.fullName}: ${item.mobileNumber}`}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Item>
          </Item>
        </Container>

        <Divider />

        <form onSubmit={onHandleConfirm}>
          <Container alignItems="center" className={classes.formRow}>
            <Item md={5}>
              <Typography>Is this Incident fake or genuine?*</Typography>
            </Item>
            <Item md={7}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="incidentType"
                  name="incidentType"
                  value={confirmationData.type}
                  onChange={(e) => handleStateChange('type', e.target.value)}>
                  <FormControlLabel
                    value="fake"
                    control={<Radio style={{ color: theme.palette.primary.sosDashboard }} />}
                    label="Fake"
                  />
                  <FormControlLabel
                    value="genuine"
                    control={<Radio style={{ color: theme.palette.primary.sosDashboard }} />}
                    label="Genuine"
                  />
                </RadioGroup>
              </FormControl>
            </Item>
          </Container>

          {confirmationData.type !== 'fake' && (
            <div>
              <Container alignItems="center" className={classes.formRow}>
                <Item md={5}>
                  <Typography>Incident Category*</Typography>
                </Item>
                <Item md={7}>
                  <Autocomplete
                    id="combo-box-demo"
                    inputValue={confirmationData.category}
                    onInputChange={(e, value) => handleStateChange('category', value)}
                    options={categories}
                    fullWidth={true}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="category"
                        required={true}
                        label="Type and Select Incident Category"
                        variant="outlined"
                        type="text"
                        size="small"
                      />
                    )}
                  />
                </Item>
              </Container>

              <Container alignItems="center" className={classes.formRow}>
                <Item md={5}>
                  <Typography>Which service is needed?*</Typography>
                </Item>
                <Item md={7}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={services.ambulance}
                          onChange={(e) => handleStateChange('service', e.target.name)}
                          name="ambulance"
                          style={{ color: theme.palette.primary.sosDashboard }}
                        />
                      }
                      label="Ambulance"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={services.police}
                          onChange={(e) => handleStateChange('service', e.target.name)}
                          name="police"
                          style={{ color: theme.palette.primary.sosDashboard }}
                        />
                      }
                      label="Police"
                    />

                    <FormControlLabel
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={services['fire brigade']}
                          onChange={(e) => handleStateChange('service', e.target.name)}
                          name="fire brigade"
                          style={{ color: theme.palette.primary.sosDashboard }}
                        />
                      }
                      label="Fire Brigade"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={services['road assistance']}
                          onChange={(e) => handleStateChange('service', e.target.name)}
                          name="road assistance"
                          style={{ color: theme.palette.primary.sosDashboard }}
                        />
                      }
                      label="Road Assistance"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={services.doctor}
                          onChange={(e) => handleStateChange('service', e.target.name)}
                          name="doctor"
                          style={{ color: theme.palette.primary.sosDashboard }}
                        />
                      }
                      label="Doctor"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={services.lawyer}
                          onChange={(e) => handleStateChange('service', e.target.name)}
                          name="lawyer"
                          style={{ color: theme.palette.primary.sosDashboard }}
                        />
                      }
                      label="Lawyer On Call"
                    />
                  </FormGroup>
                </Item>
              </Container>

              <Container className={classes.formRow}>
                <Item md={5}>
                  <Typography>Comments*</Typography>
                </Item>
                <Item md={7}>
                  <TextField
                    id="outlined-multiline-flexible"
                    multiline
                    rows={2}
                    rowsMax={3}
                    required={true}
                    value={confirmationData.comment}
                    fullWidth={true}
                    onChange={(e) => handleStateChange('comment', e.target.value)}
                    variant="outlined"
                  />
                </Item>
              </Container>

              <Container alignItems={'center'} className={classes.formRow}>
                <Item md={5}>
                  <Typography>Inform?*</Typography>
                </Item>
                <Item md={7}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="inform"
                      name="inform"
                      value={confirmationData.informedTo}
                      onChange={(e) => handleStateChange('informedTo', e.target.value)}>
                      <FormControlLabel
                        value="none"
                        control={<Radio style={{ color: theme.palette.primary.sosDashboard }} />}
                        label="None"
                      />
                      <FormControlLabel
                        value="l1"
                        control={<Radio style={{ color: theme.palette.primary.sosDashboard }} />}
                        label="L1"
                      />
                      <FormControlLabel
                        value="l1&l2"
                        control={<Radio style={{ color: theme.palette.primary.sosDashboard }} />}
                        label="L1&L2"
                      />
                    </RadioGroup>
                  </FormControl>
                </Item>
              </Container>
            </div>
          )}
          <Item sx={{ display: 'flex', gap: '10px', p: 2 }}>
            <Item sx={{ diplay: 'inline-flex', ml: 'auto' }}>
              <Button
                onClick={() => handleClose()}
                variant="contained"
                style={{
                  backgroundColor: '#FFFFFF',
                  color: theme.palette.primary.sosDashboard,
                  border: `1px solid ${theme.palette.primary.sosDashboard}`
                }}>
                Close
              </Button>
            </Item>
            <Item>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: theme.palette.primary.sosDashboard, color: 'white' }}>
                {confirmationData.type === 'fake' || confirmationData.informedTo === 'none'
                  ? 'Confirm'
                  : 'Confirm & Send SMS'}
              </Button>
            </Item>
          </Item>
        </form>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(AcknowledgementForm);
