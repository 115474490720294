const styles = (theme) => {
  return {
    title: {
      backgroundColor: theme.palette.primary.tableHeadingColor,
      fontFamily: theme.palette.font.fontFamily,
      color: 'white',
      textAlign: 'center',
      borderRadius: '10px 10px 0 0',
      padding: theme.spacing(1.5)
    }
  };
};

export default styles;
