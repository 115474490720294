const styles = (theme) => {
  return {
    newUser: {
      width: '400px',
      height: 'auto'
    },
    cardHeading: {
      padding: '7px 20px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px 5px 0 0',
      color: theme.palette.common.white
    },
    newUserForm: {
      padding: theme.spacing(2, 2, 0, 2)
    },
    action: {
      margin: theme.spacing(2, 0)
    },
    tableCell: {
      minWidth: 120,
      width: 120,
      maxWidth: 120
    }
  };
};

export default styles;
