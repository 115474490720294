import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect, useState } from 'react';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import theme from '../../../utils/theme';
import { Components } from '../../../utils/material-ui';

const { Typography, Grid, Paper } = Components;

const Item = (props) => <Grid item {...props} />;

const OverallIncidentDistribution = ({ selectedFromDate, selectedToDate }) => {
  const [, dispatch] = useStateValue();
  const [data, setData] = useState([]);

  const getPieChartData = () => {
    API_GET(
      `incident/getStatusDistributionPercentage?fromDate=${selectedFromDate}&toDate=${selectedToDate}&alertNewsStatus=true`
    )
      .then((res) => {
        console.log('res', res);
        const resultArray = [];
        res?.data?.map((item) => {
          if (item?._id === 'Pending') {
            const obj = {
              Category: 'Pending',
              Pending: item?.count
            };

            resultArray.push(obj);
          }
          if (item?._id === 'Unassigned') {
            const obj = {
              Category: 'Unassigned',
              Unassigned: item?.count
            };
            resultArray.push(obj);
          }
          if (item?._id === 'Delayed') {
            const obj = {
              Category: 'Delayed',
              Delayed: item?.count
            };
            resultArray.push(obj);
          }
          if (item?._id === 'Ongoing') {
            const obj = {
              Category: 'Ongoing',
              Ongoing: item?.count
            };
            resultArray.push(obj);
          }

          return null;
        });
        setData(resultArray);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getPieChartData();
  }, [selectedToDate, selectedFromDate]);

  const CustomTooltip = (item) => {
    return (
      <Paper sx={{ ...theme.card1, padding: theme.spacing(1, 2) }}>
        <Typography sx={{ fontSize: '14px' }}>Status : {item?.id}</Typography>
        <Typography sx={{ fontSize: '14px' }}> Incidents : {item?.value}</Typography>
      </Paper>
    );
  };

  return (
    <React.Fragment>
      <Item>
        <Typography sx={{ ...theme.typography.tableHeading }}>
          Overall incident distribution
        </Typography>
      </Item>

      <ResponsiveBar
        data={(data && data) || []}
        theme={{
          fontSize: 11,
          fontWeight: '600',
          axis: {
            ticks: {
              text: {
                // fill: '#565555' // color of the tick labels
              }
            },
            legend: {
              text: {
                fill: '#9F9F9F' // color of the x-axis label
              }
            }
          }
        }}
        keys={['Pending', 'Unassigned', 'Delayed', 'Ongoing']}
        indexBy="Category"
        margin={{ top: 30, right: 0, bottom: 115, left: 65 }}
        padding={0.7}
        borderRadius={10}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={['#FE8F50', '#FFC453', '#2DB23D', '#1672EC']}
        enableLabel={false}
        enableGridY={data.length > 0}
        gridYValues={data.length > 0 && 1}
        tooltip={(point) => {
          return CustomTooltip(point);
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'No. of incidents',
          legendPosition: 'middle',
          legendOffset: -45,
          color: '#9F9F9F'
        }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: 'Status',
          legendPosition: 'middle',
          legendOffset: 45,
          color: '#9F9F9F'
        }}
      />
    </React.Fragment>
  );
};

export default OverallIncidentDistribution;
