import React, { useState, useEffect } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
// import { useStateValue } from '../../../../utils/store';
import theme from '../../../../utils/theme';
// import countryList from '../../../../utils/ListOfAllCountry';

const { withStyles, Grid, Typography, TextField, Button } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

// const officeType = [
//   {
//     name: 'Branch Office',
//     value: 'Branch Office'
//   },
//   {
//     name: 'Head Office',
//     value: 'Head Office'
//   }
// ];

const AddRevenueData = ({
  classes,
  handleClose,
  status,
  selectedData,
  totolRevenue,
  setStateData,
  id
}) => {
  // const [, dispatch] = useStateValue();

  const [sendData, setSendData] = useState({
    location: status === 'edit' ? selectedData?.city : '',
    office: status === 'edit' ? selectedData?.officeName : '',
    employeeRatio: status === 'edit' ? selectedData?.employeePercentage : '',
    revenueGenration: status === 'edit' ? selectedData?.revenuePercentage : '',
    infraDistrution: status === 'edit' ? selectedData?.infrastructurePercentage : '',
    revenueTotal: status === 'edit' ? selectedData?.perDayRevenue : ''
  });

  const handleStateChange = (key, value) => {
    setSendData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  useEffect(() => {
    const percent = sendData?.revenueGenration / 100;
    setSendData((pre) => {
      return { ...pre, revenueTotal: totolRevenue * percent };
    });
  }, [sendData?.revenueGenration]);

  const onHandleConfirm = (e) => {
    e.preventDefault();
    setStateData(id, sendData);
    handleClose();
  };

  return (
    <Container direction="column" className={classes.newUser}>
      <Typography className={classes.cardHeading} variant="h6">
        {status === 'edit' ? 'Update Revenue' : ' Add Revenue'}
      </Typography>

      <Item p={1}>
        <form onSubmit={onHandleConfirm}>
          <Item className={classes.formContainer}>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="location"
                label="Location"
                sx={{ width: '300px' }}
                required={true}
                value={sendData.location}
                // onChange={(e) => handleStateChange('location', e.target.value)}
                type="text"
                size="small"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="office"
                label="Office"
                sx={{ width: '300px' }}
                required={true}
                size="small"
                value={sendData.office}
                // onChange={(e) => handleStateChange('office', e.target.value)}
                type="text"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="employeeRatio"
                label="Employee Ratio"
                sx={{ width: '300px' }}
                required={true}
                size="small"
                value={sendData.employeeRatio}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleStateChange('employeeRatio', e.target.value);
                  } else {
                    handleStateChange('employeeRatio', '');
                  }
                }}
                type="number"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="revenueGenration"
                label="Revenue Ratio"
                sx={{ width: '300px' }}
                required={true}
                size="small"
                value={sendData.revenueGenration}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleStateChange('revenueGenration', e.target.value);
                  } else {
                    handleStateChange('revenueGenration', '');
                  }
                }}
                type="number"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="infraDistrution"
                label="Infra Ratio"
                sx={{ width: '300px' }}
                required={true}
                size="small"
                value={sendData.infraDistrution}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleStateChange('infraDistrution', e.target.value);
                  } else {
                    handleStateChange('infraDistrution', '');
                  }
                }}
                type="number"
                fullWidth
              />
            </Item>
            <Item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                name="revenueTotal"
                label="Revenue"
                sx={{ width: '300px' }}
                required={true}
                size="small"
                value={sendData.revenueTotal}
                type="number"
                fullWidth
              />
            </Item>
          </Item>

          <Container container>
            <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '20px', pt: 2 }}>
              <Item>
                <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
                  Cancel
                </Button>
              </Item>
              <Item>
                <Button
                  type="submit"
                  style={{
                    ...theme.button.submit,
                    backgroundColor: theme.palette.primary.main
                  }}>
                  {`Submit`}
                </Button>
              </Item>
            </Item>
          </Container>
        </form>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(AddRevenueData);
