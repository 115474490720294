import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import { useStateValue } from '../../../../utils/store';
import { API_PATCH, API_HANDLE_ERROR } from '../../../../utils/api';
import { nameValidate, mobileNumberValidate } from '../../../../utils/formValidation';
import PrefiledSelfDecleration from './PrefiledSelfDecleration';
const {
  withStyles,
  Grid,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Paper,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox
} = Components;

const { AddCircleIcon, CancelIcon } = Icons;
const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const relationArray = ['Father', 'Mother', 'Sibling', 'Spouse', 'Others'];

function SelfDecleartionFrom({ classes, handleClose, declerationData, getAllPackageId }) {
  const [, dispatch] = useStateValue();
  const [disclimberValue, setDisClimberValue] = useState(false);
  const [value, setValue] = React.useState(declerationData?.questions);

  const handleChange = (e, data, index) => {
    const result = value?.map((item, index) => {
      return item?.questionId === data?.questionId ? { ...item, answer: e.target.value } : item;
    });
    setValue(result);
  };

  // add and delete Emergency Contact

  const emergencyContactDetails = {
    name: '',
    relation: '',
    mobileNumber: '',
    city: '',
    state: '',
    pinCode: ''
  };

  const [emergencyContacts, setEmergencyContacts] = useState([]);

  useEffect(() => {
    if (declerationData?.emergencyContacts?.length > 0) {
      setEmergencyContacts(declerationData?.emergencyContacts);
    } else {
      setEmergencyContacts([emergencyContactDetails]);
    }
  }, []);

  const handlePackageDetails = (key, value, index) => {
    setEmergencyContacts((prevState) => {
      const newArr = [...prevState];
      if (key === 'name') {
        if (nameValidate(value) === false) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'Special charaters are not allowed'
          });
        } else {
          newArr[index][`${key}`] = value;
        }
      } else if (key === 'mobileNumber') {
        if (value < 0) {
          newArr[index][`${key}`] = '';
        } else if (value.length <= 10) {
          newArr[index][`${key}`] = value;
        }
      } else if (key === 'pinCode') {
        if (value < 0) {
          newArr[index][`${key}`] = '';
        } else if (value.length <= 6) {
          newArr[index][`${key}`] = value;
        }
      } else {
        newArr[index][`${key}`] = value;
      }
      return newArr;
    });
  };

  const handlePackageDetail = () => {
    const newArr = [...emergencyContacts];
    newArr.push(emergencyContactDetails);
    setEmergencyContacts(newArr);
  };

  const handleDelete = (id) => {
    const result = emergencyContacts.filter((item, index) => index !== id);
    setEmergencyContacts(result);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const resultData = value?.map((item) => {
      return {
        answer: item?.answer,
        questionId: item?._id
      };
    });

    const check = resultData.filter((item) => {
      return item?.answer === '';
    });
    if (check.length !== 0) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please select an option for each question before continuing.'
      });
    } else {
      API_PATCH(`travelPackage/updateQuestions`, {
        answers: resultData,
        emergencyContacts: emergencyContacts,
        formId: declerationData?.formId
      })
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
          getAllPackageId();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.selfDeclerationContainer}
      sx={{ width: '75vw' }}>
      <Item className={classes.title}>
        <Item style={{ margin: 'auto', display: 'flex', alignItems: 'center', gap: '20px' }}>
          <img
            style={{ height: '70px', width: '70px' }}
            src={require(`../../../../Assets/TravelIcon/selfDecleration.png`).default}
            alt="Self Decleration"
          />
          <Typography sx={{ fontSize: '1.5rem', fontWeight: '600' }}>
            Self Declaration Form
          </Typography>
        </Item>
      </Item>
      {declerationData?.formStatus === 'submitted' ? (
        <PrefiledSelfDecleration
          value={value}
          declerationData={declerationData}
          emergencyContacts={emergencyContacts}
          disclimberValue={disclimberValue}
          handleClose={handleClose}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <Item className={classes.selfDecleartionContain} md={12} p={2}>
            <Typography sx={{ fontSize: '0.9rem', fontWeight: '500' }}>
              {declerationData?.description}
            </Typography>
            <Typography sx={{ fontSize: '1rem', fontWeight: '800' }} mt={1.5}>
              I declare that (Please tick the box)
            </Typography>
            <ol style={{ padding: '10px 15px' }}>
              {value &&
                value?.map((item, index) => {
                  return (
                    <li key={index}>
                      <Typography sx={{ fontSize: '0.9rem', fontWeight: '500' }}>
                        {item?.questionText}
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={item?.answer}
                          onChange={(e) => handleChange(e, item, index)}>
                          {item?.options?.map((itemOption) => {
                            return (
                              <FormControlLabel
                                value={itemOption?.optionText}
                                control={<Radio />}
                                label={itemOption?.optionText}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </li>
                  );
                })}
            </ol>
            <Item mt={3}>
              <Item sx={{ display: 'flex' }}>
                <Typography style={{ textAlign: 'left', fontWeight: '600', fontSize: '18px' }}>
                  People can be contacted in case of emergency
                </Typography>
                <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
                  <AddCircleIcon
                    sx={{ color: 'red', fontSize: '35px', cursor: 'pointer' }}
                    onClick={handlePackageDetail}
                  />
                </Item>
              </Item>
              <Item sx={{ overflowX: 'hidden' }}>
                {emergencyContacts?.map((item, pkindex) => {
                  return (
                    <Paper
                      elevation={2}
                      key={pkindex}
                      sx={{
                        p: '10px',
                        mt: 2,
                        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
                        borderRadius: '10px',
                        position: 'relative',
                        border: '1px solid lightgrey'
                      }}>
                      {pkindex === 0 ? (
                        ''
                      ) : (
                        <Item
                          onClick={() => handleDelete(pkindex)}
                          sx={{
                            position: 'absolute',
                            right: '-1px',
                            top: '-10px',
                            cursor: 'pointer'
                          }}>
                          <CancelIcon />
                        </Item>
                      )}
                      <Item className={classes.addPackageContainer} mt={1}>
                        <Item>
                          <TextField
                            variant="outlined"
                            name="name"
                            size="small"
                            label="Name"
                            required={true}
                            sx={{ width: '225px' }}
                            value={item?.name}
                            className={classes.formControl}
                            onChange={(e) => handlePackageDetails('name', e.target.value, pkindex)}
                            type="text"
                            fullWidth
                          />
                        </Item>
                        <Item>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            sx={{ width: '225px' }}
                            size="small">
                            <InputLabel id="demo-simple-select-outlined-label">Relation</InputLabel>
                            <Select
                              name="relation"
                              label="Relation"
                              required={true}
                              sx={{ width: '225px' }}
                              value={item?.relation}
                              onChange={(e) =>
                                handlePackageDetails('relation', e.target.value, pkindex)
                              }>
                              {relationArray?.map((item, index) => {
                                return (
                                  <MenuItem value={item} key={index}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Item>
                        <Item>
                          <TextField
                            variant="outlined"
                            name="mobileNumber"
                            size="small"
                            label="Phone"
                            required={true}
                            onBlur={(e) => {
                              if (
                                e.target.value !== '' &&
                                mobileNumberValidate(item?.mobileNumber) === false
                              ) {
                                dispatch({
                                  type: 'ALERT_OPEN',
                                  severity: 'error',
                                  isOpen: true,
                                  label: 'Please enter correct phone number'
                                });
                              }
                            }}
                            sx={{ width: '225px' }}
                            value={item?.mobileNumber}
                            className={classes.formControl}
                            onChange={(e) =>
                              handlePackageDetails('mobileNumber', e.target.value, pkindex)
                            }
                            type="number"
                            fullWidth
                          />
                        </Item>
                        <Item>
                          <TextField
                            variant="outlined"
                            name="city"
                            size="small"
                            label="City"
                            required={true}
                            sx={{ width: '225px' }}
                            value={item?.city}
                            className={classes.formControl}
                            onChange={(e) => handlePackageDetails('city', e.target.value, pkindex)}
                            type="text"
                            fullWidth
                          />
                        </Item>
                        <Item>
                          <TextField
                            variant="outlined"
                            name="state"
                            size="small"
                            label="State"
                            required={true}
                            sx={{ width: '225px' }}
                            value={item?.state}
                            className={classes.formControl}
                            onChange={(e) => handlePackageDetails('state', e.target.value, pkindex)}
                            type="text"
                            fullWidth
                          />
                        </Item>
                        <Item>
                          <TextField
                            variant="outlined"
                            name="pincode"
                            size="small"
                            label="PinCode"
                            required={true}
                            sx={{ width: '225px' }}
                            value={item?.pinCode}
                            className={classes.formControl}
                            onChange={(e) => {
                              if (e.target.value >= 0) {
                                handlePackageDetails('pinCode', e.target.value, pkindex);
                              }
                            }}
                            type="number"
                            fullWidth
                          />
                        </Item>
                      </Item>
                    </Paper>
                  );
                })}
              </Item>
            </Item>
            <Item>
              <Item sx={{ mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={disclimberValue}
                      required={true}
                      onChange={(e) => setDisClimberValue(e.target.checked)}
                      name="disclimberValue"
                    />
                  }
                  label={declerationData?.disclaimer}
                />
              </Item>
            </Item>
          </Item>

          <Item sx={{ display: 'flex' }}>
            <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '10px', p: 1, mt: -2 }}>
              <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
                Cancel
              </Button>

              <Button
                style={{
                  ...theme.button.submit,
                  backgroundColor: theme.palette.primary.main
                }}
                type="submit">
                {`Save changes`}
              </Button>
            </Item>
          </Item>
        </form>
      )}
    </Container>
  );
}

export default withStyles(styles)(SelfDecleartionFrom);
