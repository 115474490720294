import React, { useState } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../../../utils/store';
import { API_HANDLE_ERROR, API_PATCH, API_POST } from '../../../../utils/api';
import theme from '../../../../utils/theme';

const { withStyles, Grid, Button, Typography, TextField } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function NoActionModal({ classes, handleClose, data }) {
  const [, dispatch] = useStateValue();

  const [sendData, setSendData] = useState({
    email: data ? data.email : ''
  });

  const handleStateChange = (key, value) => {
    setSendData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    if (data) {
      API_PATCH(`onBoarding/updateOnBoardUser/${data?._id}`, sendData)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      API_POST(`onBoarding/addEmail`, sendData)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    }
  };

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: '350px' }}>
      <Typography className={classes.cardHeading} variant="h6">
        {data ? 'Edit User' : 'Add User'}
      </Typography>

      <form onSubmit={onHandleConfirm}>
        <Item mt={2}>
          <Typography className={classes.inputText}>
            Email<span className={classes.starIcon}>*</span>
          </Typography>
          <Item mt={0.8}>
            <TextField
              variant="outlined"
              name="email"
              size="small"
              value={sendData?.email}
              className={classes.formControl}
              onChange={(e) => handleStateChange('email', e.target.value)}
              type="email"
              fullWidth
            />
          </Item>
        </Item>
        <Item sx={{ display: 'flex', gap: '10px' }} mt={4} mb={1}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
              Cancel
            </Button>
          </Item>
          <Item>
            <Button
              type="submit"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              {`Submit`}
            </Button>
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(NoActionModal);
