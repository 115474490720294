import React, { useState, useEffect } from 'react';
import styles from './styles';
import incidentColumns from './head';
import alertColumns from './head1';
import { StyledTableCell } from './StyledTableCell';
import { useStateValue } from '../../../../../utils/store';
import { Components } from '../../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR } from '../../../../../utils/api';
import { getDateTime, getDate } from '../../../../../utils/moment';
import theme from '../../../../../utils/theme';
import NoActionModal from '../../../Modal/NoActionModal';
import ForwardActionModal from '../../../Modal/ForwardActionModal';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import DeatilsViewModel from '../../../Modal/DeatilsViewModel';
import ChatModal from '../../../Modal/ChatModal';
import tableColor from '../../../../../utils/tableColor';
import IncidentForwardModel from '../../../Modal/IncidentForwardModel/dummyIndex';
import ApprovalStatusTable from '../../ApprovalStatusTable';
import DocumentModel from '../../../Modal/DocumentsModel';
import EmployeeInfo from '../../EmployeeInfo';
import Dialog from '../../../../atom/Dialog';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Button
} = Components;
// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const AcceptedIncidentTable = ({
  classes,
  severity,
  selectedOffice,

  isIncident,
  selectedFilterCategory,
  selectedFromDate,
  selectedToDate,
  search,
  chat
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRejectedNews, setTotalRejectedNews] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectId, setRejectId] = useState('');
  const [verifyModal, setVerifyModal] = useState(false);
  const [verifyId, setVerifyId] = useState();
  const [status, setStatus] = useState();
  const [columnHeader, setColumnHeader] = useState([]);
  const [isOpenDataModal, setIsOpenMoreModal] = useState(false);
  const [openData, setOpenMore] = useState({});
  const [isOpenChatModal, setIsOpenChatModal] = useState(chat || false);
  const [chatData, setChatData] = useState({});
  const [isActionStatusModelOpen, setIsActionStatusModelOpen] = useState(false);
  const [isOpenDocumentModel, setIsOpenDocumentModel] = useState(false);
  const [actionId, setActionId] = useState('');
  const [documentId, setDocumentId] = useState('');
  const [isOpenMoreDetailModal, setIsOpenMoreDetailModal] = useState(false);
  const [moreDetail, setMoreDetail] = useState();
  const [newsId, setNewsId] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (isIncident) {
      setColumnHeader(alertColumns);
    } else {
      setColumnHeader(incidentColumns);
    }
  }, [isIncident]);

  // to reset page on search
  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getUnassignedIncident();
  }, [
    dispatch,
    page,
    rowsPerPage,
    isIncident,
    selectedOffice,
    // media,
    severity,
    selectedFilterCategory,
    selectedFromDate,
    selectedToDate,
    verifyModal,
    rejectModal,
    isOpenDocumentModel,
    search,
    isOpenMoreDetailModal
  ]);

  const getUnassignedIncident = () => {
    const severityLevel = severity === 0 ? '' : `emergencySeverityLevel=${severity}`;
    const emergencyCat = !isIncident ? '' : `emergencySeverity=${selectedFilterCategory}`;
    const resultSearch =
      search !== undefined && search !== ''
        ? `searchTag=${search}`
        : `fromDate=${selectedFromDate}&toDate=${selectedToDate}`;
    const resultOffice = selectedOffice?.map((item) => {
      return item?._id;
    });
    let params = '';
    resultOffice.forEach((element) => {
      params += `&officeIds[]=${element}`;
    });

    API_GET(
      `incident/getPendingOrAccepted?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&incidentActionStatus=accepted&${params}&${severityLevel}&${emergencyCat}&alertNewsStatus=${isIncident}&${resultSearch}&historyType=${
        isIncident ? 'alert' : 'incident'
      }`
    )
      .then((res) => {
        setRows(res?.data);
        setTotalRejectedNews(res?.count);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const handleClose = () => {
    setRejectModal(false);
    setVerifyModal(false);
    setIsOpenMoreModal(false);
    setIsOpenChatModal(false);
    setIsActionStatusModelOpen(false);
    setIsOpenDocumentModel(false);
    setIsOpenMoreDetailModal(false);
  };

  const handleActionStatus = (id) => {
    setIsActionStatusModelOpen(true);
    setActionId(id);
  };
  const handleMoreDetail = (data, id) => {
    setIsOpenMoreDetailModal(true);
    setMoreDetail(data);
    setNewsId(id);
  };

  const handleDocumentModel = (id) => {
    setIsOpenDocumentModel(true);
    setDocumentId(id);
  };

  const handleOpenChatModal = (itemData) => {
    setIsOpenChatModal(true);
    setChatData(itemData);
  };

  const handleExtraData = (row) => {
    setIsOpenMoreModal(true);
    setOpenMore(row);
  };

  const handleReject = (status, item) => {
    setRejectModal(true);
    setRejectId(item?._id);
    setStatus(status);
  };
  const handleVerify = (item) => {
    setVerifyModal(true);
    setVerifyId(item?._id);
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead className={classes.head}>
            <TableRow>
              {columnHeader &&
                columnHeader.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columnHeader.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(index) }}>
                    {columnHeader &&
                      columnHeader.map((column) => {
                        const value = row[column.id] || '--';
                        if (column.id === 'date') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.createdAt) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'publishedDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.publishedDate) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'emergencySeverityLevel') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {row?.emergencySeverityLevel || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'eventDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.eventDate) || '--'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'proposedDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDate(row?.proposedDate) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'category') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item sx={{ display: 'flex', gap: '10px' }}>
                                <Item>
                                  <Button
                                    style={{
                                      ...theme.button.tableSeverityButton,
                                      backgroundColor: row?.backgroundAlertColor,
                                      color: row?.alertColor
                                    }}>
                                    {row?.emergencySeverity}
                                  </Button>
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'forwardedTo') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item
                                onClick={() => handleMoreDetail(row?.forwardedTo, row?._id)}
                                sx={{ cursor: 'pointer' }}>
                                <img
                                  src="https://img.icons8.com/ios/50/undefined/info--v1.png"
                                  height="22px"
                                  width="22px"
                                  alt="logo"
                                />
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'title') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item
                                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={() => handleExtraData(row)}>
                                <Item>{row?.title}</Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'actualDate') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.completionDate) || '--'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'ticketNumber') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleOpenChatModal(row)}>
                                <Item sx={{ textDecoration: 'underline' }}>
                                  {ConvertToUpperCase(row?.ticketNumber)}
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'acceptedOn') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.acceptedOn) || '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'forwardedOn') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {getDateTime(row?.forwardedOn) || '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'safetyCategory') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {row?.category || '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'severity') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              {isIncident ? '' : row?.severity}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'action') {
                          return (
                            <StyledTableCell align={column.align} key={column.id}>
                              <Item sx={{ display: 'flex', gap: '10px' }}>
                                <Item>
                                  <Button
                                    style={theme.button.initiate}
                                    onClick={() => handleReject('completed', row)}>
                                    Complete
                                  </Button>
                                </Item>

                                <Item>
                                  {row?.incidentActionStatus === 'delayed' ? (
                                    <Button
                                      disabled
                                      style={{
                                        ...theme.button.trash,
                                        backgroundColor: 'lightgrey'
                                      }}>
                                      FORWARD
                                    </Button>
                                  ) : (
                                    <Button
                                      style={theme.button.trash}
                                      onClick={() => handleVerify(row)}>
                                      FORWARD
                                    </Button>
                                  )}
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'docs') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.budgetApprovalRequired ? (
                                <Item
                                  onClick={() => handleDocumentModel(row?._id)}
                                  sx={{ cursor: 'pointer' }}>
                                  <img
                                    src="https://img.icons8.com/ios/50/undefined/info--v1.png"
                                    height="22px"
                                    width="22px"
                                    alt="logo"
                                  />
                                </Item>
                              ) : (
                                '-'
                              )}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'approvalStatus') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.budgetApprovalRequired ? (
                                <Item
                                  onClick={() => handleActionStatus(row?._id)}
                                  sx={{ cursor: 'pointer' }}>
                                  <img
                                    src="https://img.icons8.com/ios/50/undefined/info--v1.png"
                                    height="22px"
                                    width="22px"
                                    alt="logo"
                                  />
                                </Item>
                              ) : (
                                '-'
                              )}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'docs') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item
                                // onClick={() => handleMoreDetail(row?.forwardedTo, row?._id)}
                                sx={{ cursor: 'pointer' }}>
                                <img
                                  src="https://img.icons8.com/ios/50/undefined/info--v1.png"
                                  height="22px"
                                  width="22px"
                                  alt="logo"
                                />
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(ConvertToUpperCase(value))
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalRejectedNews && totalRejectedNews ? totalRejectedNews : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        isOpen={isOpenDocumentModel}
        children={<DocumentModel handleClose={handleClose} documentId={documentId} />}></Dialog>
      <Dialog
        isOpen={isActionStatusModelOpen}
        children={
          <ApprovalStatusTable
            handleClose={handleClose}
            actionId={actionId}
            actionStatus="accepted"
          />
        }></Dialog>
      <Dialog
        isOpen={verifyModal}
        children={
          isIncident ? (
            <ForwardActionModal
              handleClose={handleClose}
              verifyId={verifyId}
              isIncident={isIncident}
              status="accepted"
            />
          ) : (
            <IncidentForwardModel
              handleClose={handleClose}
              verifyId={verifyId}
              isIncident={isIncident}
              status="accepted"
            />
          )
        }></Dialog>
      <Dialog
        isOpen={rejectModal}
        children={
          <NoActionModal
            handleClose={handleClose}
            rejectId={rejectId}
            status={status}
            isIncident={isIncident}
          />
        }></Dialog>
      <Dialog
        isOpen={isOpenDataModal}
        children={
          <DeatilsViewModel handleClose={handleClose} data={openData} isIncident={isIncident} />
        }></Dialog>
      <Dialog
        isOpen={isOpenChatModal}
        children={
          <ChatModal handleClose={handleClose} chatData={chat ? rows && rows[0] : chatData} />
        }></Dialog>
      <Dialog
        isOpen={isOpenMoreDetailModal}
        children={
          <EmployeeInfo
            handleClose={handleClose}
            data={moreDetail}
            newsId={newsId}
            isIncident={isIncident}
          />
        }></Dialog>
    </Card>
  );
};

export default withStyles(styles)(AcceptedIncidentTable);
