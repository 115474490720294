import React from 'react';
import theme from '../../../utils/theme';
import { Components } from '../../../utils/material-ui';

const { Grid, Paper, Typography, Button, FormControlLabel, Checkbox } = Components;

const Item = (props) => <Grid item {...props} />;

const EmployeeFilter = ({ handleClose, handleFilterCategory, filterData, handleChange }) => {
  const handleSubmit = () => {
    const result = filterData?.filter((item) => {
      return item.value;
    });

    handleFilterCategory(result);
    handleClose();
  };

  return (
    <Paper
      sx={{
        position: 'absolute',
        top: 48,
        right: 0,
        zIndex: 99,
        p: 1.5,
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        width: '45vw',
        minWidth: '300px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
      }}>
      <Item sx={{ maxHeight: '60vh', overflow: 'auto' }}>
        <Typography sx={{ ...theme.typography.tableHeading, p: 0, fontSize: '18px' }}>
          Filter By
        </Typography>
        <Typography
          sx={{
            ...theme.typography.tableHeading,
            p: 0,
            fontSize: '15px',
            color: theme.typography.tableHeading.lightColor
          }}
          mt={1}>
          Role
        </Typography>
        <Item mt={1}>
          {filterData
            ?.filter((filterItem) => filterItem?.type === 'Role')
            ?.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={item?.value}
                    onChange={() => handleChange(item)}
                    name={item?.label}
                    color="primary"
                  />
                }
                style={{ color: theme.typography.tableHeading.lightColor }}
                label={item?.label}
              />
            ))}
        </Item>
        <Typography
          sx={{
            ...theme.typography.tableHeading,
            p: 0,
            fontSize: '15px',
            color: theme.typography.tableHeading.lightColor
          }}
          mt={1}>
          Office
        </Typography>
        <Item mt={1}>
          {filterData
            ?.filter((filterItem) => filterItem?.type === 'Office')
            ?.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={item?.value}
                    onChange={() => handleChange(item)}
                    name={item?.label}
                    color="primary"
                  />
                }
                sx={{ color: theme.typography.tableHeading.lightColor }}
                label={item?.label}
              />
            ))}
        </Item>
      </Item>
      <Item sx={{ display: 'flex', gap: '10px' }}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <Button style={{ ...theme.button.cancel }} onClick={handleClose}>
            Cancel
          </Button>
        </Item>
        <Item>
          <Button
            onClick={handleSubmit}
            style={{
              ...theme.button.submit,
              backgroundColor: theme.palette.primary.main
            }}>
            {`Apply`}
          </Button>
        </Item>
      </Item>
    </Paper>
  );
};

export default EmployeeFilter;
