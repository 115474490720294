import React, { useState, useEffect, useRef } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { Components, Icons } from '../../../../../utils/material-ui';
import tableColor from '../../../../../utils/tableColor';
import PackageDataWithIdTable from '../PackageDataWithIdTable';
import Dialog from '../../../../atom/Dialog';
import { API_GET, API_HANDLE_ERROR, API_UPLOAD_FILE, API_DELETE } from '../../../../../utils/api';
import theme from '../../../../../utils/theme';
import { useStateValue } from '../../../../../utils/store';
import DeleteConfirmation from '../../../Modal/DeleteConfirmation';
import { getDate } from '../../../../../utils/moment';
// import downloadDataToExcelFile from '../../../../../utils/downloadDataToExcelFile';
import AddPackage from '../../../Modal/AddPackage';
import DownloadTextFile from '../../../../../utils/DownloadTextFile';
// import ExportToExcel from '../../../../../utils/downloadMergeColumnData';
import ExportTable from './exportTable';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Checkbox
} = Components;

const { ShareIcon, DownloadForOfflineIcon, AddCircleIcon, EditIcon, DeleteIcon } = Icons;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const PakagesTable = ({ classes, selectedFromDate, selectedToDate, packageType, sort }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalEmployees, setTotalEmployees] = React.useState(0);
  const [searchText, setSearchText] = useState('');
  const [isOpenPackageModel, setIsOpenPackageModel] = useState(false);
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isOpenAddPackage, setIsOpenAddPackage] = useState(false);
  const [status, setStatus] = useState('addPackage');
  const [editdata, setEditdata] = useState('');
  const [pakagesDetailsData, setPackageDetailsData] = useState('');
  const [checkboxData, setCheckboxData] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [exportData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePackageData = (data) => {
    setIsOpenPackageModel(true);
    setPackageDetailsData(data);
  };
  const handleClose = () => {
    setIsOpenPackageModel(false);
    setIsOpenDeleteModel(false);
    setIsOpenAddPackage(false);
  };
  const handleRemove = (item) => {
    setIsOpenDeleteModel(true);
    setDeleteId(item?._id);
  };

  const handleAddPackage = (resStatus, row) => {
    setIsOpenAddPackage(true);
    setEditdata(row);
    setStatus(resStatus);
  };

  const getAllPackages = () => {
    const resultPackageType = packageType === 'all' ? '' : `packageType=${packageType}`;
    selectedFromDate = selectedFromDate === 'Invalid date' ? '' : selectedFromDate;
    selectedToDate = selectedToDate === 'Invalid date' ? '' : selectedToDate;
    API_GET(
      `travelPackage/getAllTravelPackages?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}&fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultPackageType}&dynamicSort=${sort}`
    )
      .then((res) => {
        const checkboxObject = {};
        res?.packages?.map((item, index) => (checkboxObject[index] = false));
        setCheckboxData(checkboxObject);
        setSelectAll(false);
        setRows(res?.packages);
        setTotalEmployees(res?.totalPackages);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllPackages();
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchText,
    selectedFromDate,
    selectedToDate,
    packageType,
    sort,
    isOpenAddPackage
  ]);

  let selectedCount = 0;
  if (checkboxData) {
    Object.keys(checkboxData).map((item) =>
      checkboxData[`${item}`] === true ? selectedCount++ : selectedCount
    );
  }

  const handleSelectAll = (event) => {
    const boolean = event.target.checked;
    const keys = Object.keys(checkboxData);
    const object = {};
    keys.map((item) => {
      return (object[item] = boolean);
    });
    setCheckboxData(object);
    setSelectAll(boolean);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxData({
      ...checkboxData,
      [event.target.name]: event.target.checked
    });
    setSelectAll(false);
  };

  // file upload code

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    uploadfile(fileUploaded);
    event.target.value = '';
  };

  const uploadfile = (fileUploaded) => {
    setLoading(true);
    API_UPLOAD_FILE(`travelPackage/importPackages`, fileUploaded)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        getAllPackages();
        setLoading(false);
        if (res?.errors.length > 0) {
          const errorText = res?.errors
            ?.map((error) => `Row ${error.row}: ${error.errorMessage}\n`)
            .join('');
          DownloadTextFile(errorText);
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
        setTimeout(() => {
          window.location.reload();
        }, 400);

        setLoading(false);
      });
  };

  // Delete data with id

  const onHandleCancel = (deletedItem) => {
    deletedItem &&
      API_DELETE(`travelPackage/deleteTravelPackage/${deletedItem}`)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          getAllPackages();
          setIsOpenDeleteModel(false);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  // export report download

  // const handleDownload = () => {
  //   const resultPackage = packageType === 'All' ? '' : `packageType=${packageType}`;
  //   API_GET(
  //     `travelPackage/getAllTravelPackages?searchText=${searchText}&fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultPackage}&dynamicSort=${sort}&limit=${totalEmployees}&skip=0`
  //   )
  //     .then((res) => {
  //       const resultData = res?.packages?.map((item) => {
  //         return {
  //           'Group Id': item?.packageId,
  //           'Group Name': item?.packageName,
  //           Country: item?.country,
  //           'Start Date': getDate(item?.startDate),
  //           'End Date': getDate(item?.endDate),
  //           'Registration Start Date': getDate(item?.registrationStartDate),
  //           'Registration End Date': getDate(item?.registrationEndDate),
  //           'Nominated Employee': item?.totalNominated,
  //           Pax: item?.pax,
  //           'Trip Type': item?.tripType,
  //           'Group Type': item?.packageType
  //         };
  //       });
  //       if (resultData.length >= 1) {
  //         downloadDataToExcelFile(
  //           resultData,
  //           `Groups Details GroupType-(${packageType} (${selectedFromDate} To ${selectedToDate}))`
  //         );
  //       } else {
  //         dispatch({
  //           type: 'ALERT_OPEN',
  //           severity: 'error',
  //           isOpen: true,
  //           label: 'No data found for download'
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       API_HANDLE_ERROR(err, dispatch);
  //     });
  // };

  const handleExport1 = () => {
    // handleDownload();
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const selectedEmployeeData = filteredKeys.map((item) => rows[item]?._id).flat();
    let str = '';

    for (const id of selectedEmployeeData) {
      str += `&groupIds[]=${id}`;
    }

    selectedFromDate = selectedFromDate === 'Invalid date' ? '' : selectedFromDate;
    selectedToDate = selectedToDate === 'Invalid date' ? '' : selectedToDate;
    API_GET(
      `travelpackage/downloadTravelPackageReport?searchText=${searchText}${str}&fromDate=${selectedFromDate}&toDate=${selectedToDate}`
    )
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res?.message || 'Success'
        });
        // setExportData(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    // setTimeout(() => {
    //   ExportToExcel('Group_Details_Table', 'xlsx', `Group_Details.xlsx`);
    // }, 1000);
  };

  const TableData = () => {
    return (
      <div id="Group_Details_Table" style={{ display: 'none' }}>
        <ExportTable data={exportData} />
      </div>
    );
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Item className={classes.filterBar}>
        <Item>
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: '600'
            }}>{`Total Groups (${totalEmployees})`}</Typography>
        </Item>
        <Item>
          <TextField
            id="outlined-basic"
            label="Search by Group Id, Group Name"
            size="small"
            value={searchText}
            variant="outlined"
            sx={{ width: '300px' }}
            fullWidth
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Item>
        <Item sx={{ display: 'flex', gap: '10px' }}>
          {loading ? (
            <Button
              size="small"
              style={{
                backgroundColor: theme.palette.primary.tableHeadingColor,
                color: theme.palette.common.white
              }}>
              <CircularProgress sx={{ color: '#FFFFFF' }} size={20} />
              Importing
            </Button>
          ) : (
            <ShareIcon className={classes.icon} fontSize="large" onClick={handleClick} />
          )}
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept=".xlsx, .xls, .csv"
          />
          {TableData()}

          <DownloadForOfflineIcon
            className={classes.icon}
            fontSize="large"
            onClick={handleExport1}

            // onClick={handleDownload}
          />
          <AddCircleIcon
            className={classes.icon}
            fontSize="large"
            onClick={() => handleAddPackage('addPackage', '')}
          />
        </Item>
      </Item>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.id === 'select' ? (
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        style={{ color: 'white' }}
                      />
                    ) : (
                      column.label
                    )}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows?.map((row, rowindex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(rowindex) }}>
                    {columns &&
                      columns.map((column) => {
                        const value = row[column.id] ?? '--';

                        if (column.id === 'startDate') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>{getDate(row?.startDate)}</Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'endDate') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>{getDate(row?.endDate)}</Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'packageType') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>{row?.packagetypesInfo?.name}</Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'select') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              <Checkbox
                                color="primary"
                                checked={checkboxData[`${rowindex}`]}
                                onChange={handleCheckboxChange}
                                name={`${rowindex}`}
                              />
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'packageId') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              <Item
                                sx={{ textDecoration: 'underline black', cursor: 'pointer' }}
                                onClick={() => handlePackageData(row?.packageDetails)}>
                                {row?.packageId}
                              </Item>
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'action') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item sx={{ display: 'flex', gap: '7px' }}>
                                <Item>
                                  <EditIcon
                                    onClick={() => handleAddPackage('editPackage', row)}
                                    sx={{ cursor: 'pointer' }}
                                  />
                                </Item>
                                <Item>
                                  <DeleteIcon
                                    onClick={() => handleRemove(row)}
                                    sx={{ cursor: 'pointer', color: 'red' }}
                                  />
                                </Item>
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalEmployees && totalEmployees ? totalEmployees : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        isOpen={isOpenPackageModel}
        children={
          <PackageDataWithIdTable handleClose={handleClose} data={pakagesDetailsData} />
        }></Dialog>
      <Dialog
        isOpen={isOpenAddPackage}
        children={
          <AddPackage handleClose={handleClose} status={status} editdata={editdata} />
        }></Dialog>
      <Dialog
        isOpen={isOpenDeleteModel}
        children={
          <DeleteConfirmation
            handleClose={handleClose}
            deleteId={deleteId}
            onHandleDelete={onHandleCancel}
          />
        }></Dialog>
    </Card>
  );
};

export default withStyles(styles)(PakagesTable);
