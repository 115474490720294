import { ResponsivePieCanvas } from '@nivo/pie';
import React, { useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import moment from 'moment';
// import ConvertToUpperCase from '../../utils/ConvertToUpperCase';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

// const data = [
//   { label: 'Emergency', value: 14 / 100, color: '#E32F1E', id: 'Emergency' },
//   { label: 'Warning', value: 6 / 100, color: '#EC7A1A', id: 'Warning' },
//   {
//     label: 'Critical',
//     value: 14 / 100,
//     color: '#EDAF01',
//     id: 'Critical'
//   },
//   { label: 'Info', value: 56 / 100, color: '#2DC02B', id: 'Info' },
//   { label: 'Notice', value: 10 / 100, color: '#2C9AFF', id: 'Notice' }
// ];

const MyResponsivePieCanvas = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    const resultLocation = location === 'All' ? '' : `city=${location}`;
    API_GET(
      `reports/getIncidentAnalysisGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultLocation}`
    )
      .then((res) => {
        setRows(res?.incidentAnalysisData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location]);

  const handleExport = () => {
    // const ExportData = [
    //   {
    //     'Sr No': 1,
    //     Date: '22-05-2022',
    //     Emergency: 10,
    //     Critical: 12,
    //     Warning: 15,
    //     Notice: 6,
    //     Info: 17
    //   },
    //   {
    //     'Sr No': 2,
    //     Date: '23-05-2022',
    //     Emergency: 10,
    //     Critical: 12,
    //     Warning: 15,
    //     Notice: 6,
    //     Info: 17
    //   }
    // ];

    API_GET(
      `reports/downloadIncidentAnalysisGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}`
    )
      .then((res) => {
        const resultData = res?.incidentAnalysisData?.map((item, index) => {
          return {
            'Sr No': item?.SrNo,
            Date: moment(item?.Date).format('DD-MM-YYYY'),
            Emergency: item?.emergency,
            Critical: item?.critical,
            Warning: item?.warning,
            Notice: item?.notices,
            Info: item?.info
          };
        });

        downloadDataToExcelFile(
          resultData,
          `Ext. Incident Analytics (${selectedFromDate} To ${selectedToDate})`
        );
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Incident Analysis
          </Typography>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '230px' }}>
        <ResponsivePieCanvas
          theme={{
            fontSize: 14,
            fontWeight: '600'
          }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          // valueFormat=" >-1.1%"
          data={rows}
          margin={{ top: 10, right: 170, bottom: 20, left: 10 }}
          innerRadius={0.5}
          // padAngle={0.0}
          cornerRadius={0}
          activeOuterRadiusOffset={8}
          colors={{ datum: 'data.color' }}
          arcLinkLabelsSkipAngle={0}
          arcLinkLabelsText={false}
          arcLinkLabelsTextColor="black"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={5}
          arcLabelsTextColor="black"
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 80,
              itemHeight: 38,
              itemTextColor: 'black',
              itemDirection: 'left-to-right',

              symbolSize: 16
            }
          ]}
        />
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
