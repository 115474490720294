const styles = (theme) => {
  return {
    root: {
      width: '100%'
    },
    container: {
      maxHeight: 500,
      overflow: 'auto'
    },
    tableHeader: {},
    actionBtn: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& a': {
        textDecoration: 'none',
        color: theme.palette.common.white
      }
    },

    boldFont: {
      fontWeight: 'bold'
    },
    heading: {
      fontSize: '16px',
      color: theme.palette.primary.textTableColor,
      fontWeight: '500',
      padding: theme.spacing(2, 1)
    },
    tablesearch: {
      height: '35px',
      border: 'solid 0.5px #707070',
      padding: ' 0 0 0 35px',
      outline: 'none',
      color: '#000000',
      width: '300px'
    },
    filterBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      flexWrap: 'wrap'
    },
    icon: {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
    popupContainer: {
      // padding: theme.spacing(2),
      fontFamily: theme.palette.font.fontFamily,
      width: '400px'
    },
    title: {
      backgroundColor: theme.palette.primary.tableHeadingColor,
      fontFamily: theme.palette.font.fontFamily,
      color: 'white',
      textAlign: 'center',
      borderRadius: '10px 10px 0 0',
      width: '100%',
      padding: theme.spacing(1)
    }
  };
};

export default styles;
