import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';

const { withStyles, Typography, Grid } = Components;
const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid container {...props} />;

const selectedCardColor = {
  color: '#FFFFFF',
  backgroundColor: theme.palette.primary.sosDashboard,
  borderRadius: '8px',
  justifyContent: 'center',
  boxShadow: '0px 0px 4px 0px #00000040',
  fontSize: '15px'
};

function CountsCard(props) {
  const { item, onClick, selectedCard } = props;

  return (
    <Container
      onClick={() => onClick(item)}
      sx={
        selectedCard.value === item.value
          ? { ...selectedCardColor, padding: theme.spacing(0.4, 1) }
          : { ...theme.countsCard.unselectedCardColor, padding: theme.spacing(0.4, 1) }
      }>
      <Item>
        <Typography align="center" variant="h7">
          {item.label}
        </Typography>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(CountsCard);
