import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import { ResponsiveBar } from '@nivo/bar';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import theme from '../../../utils/theme';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import ExportToExcel from '../../../utils/downloadMergeColumnData';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;
const Item = (props) => <Grid item {...props} />;
// const Container = (props) => <Grid item {...props} />;

const MyResponsivePieCanvas = ({ classes, selectedFromDate, selectedToDate }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [exportResultData, setExportResultData] = useState([]);

  useEffect(() => {
    API_GET(
      `reports/getBudgetRequiredVsApprovedGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}`
    )
      .then((res) => {
        const resultData =
          res &&
          res?.graphData?.map((item) => {
            return {
              Requested: item?.requested / 1000,
              Approved: item?.approved / 1000,
              time: item?._id
            };
          });
        setRows(resultData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate]);

  const handleExport = () => {
    API_GET(
      `reports/downloadBudgetRequiredVsApprovedGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}&queryType=cityfromdror`
    )
      .then((res) => {
        setExportResultData(res?.graphData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    setTimeout(() => {
      ExportToExcel(
        'Department_wise_requested_Budget',
        'xlsx',
        `Req. Vs Approved budget_Location (${selectedFromDate} To ${selectedToDate}).xlsx`
      );
    }, 300);
  };

  const TableData = () => {
    return (
      <div id="Department_wise_requested_Budget" style={{ display: 'none' }}>
        <table border="1">
          <thead>
            <tr>
              {exportResultData.length > 0 &&
                Object.keys(exportResultData[0]).map((item) => {
                  return (
                    <th colSpan={item === 'Sr No' || item === 'Date' ? '1' : '2'}>
                      {ConvertToUpperCase(item)}
                    </th>
                  );
                })}
            </tr>
            <tr>
              {exportResultData.length > 0 &&
                Object.keys(exportResultData[0]).map((item) => {
                  return (
                    <React.Fragment>
                      {item === 'Sr No' || item === 'Date' ? (
                        <th></th>
                      ) : (
                        <React.Fragment>
                          <th>Approved</th>
                          <th>Rejected</th>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {exportResultData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item['Sr No']}</td>
                  <td>{item.Date}</td>
                  {Object.keys(item).map((subData) => {
                    return (
                      <React.Fragment>
                        {subData !== 'Sr No' && subData !== 'Date' ? (
                          <React.Fragment>
                            <td>{item[subData].Approved}</td>
                            <td>{item[subData].Rejected}</td>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Requested Vs Approved Budget
          </Typography>
        </Item>
        <Item>
          {TableData()}
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '240px' }}>
        <ResponsiveBar
          indexBy="time"
          style={{
            fontSize: '14px'
          }}
          groupMode="grouped"
          data={rows}
          keys={['Approved', 'Requested']}
          colors={['#086DD2', '#FF73B3']}
          index="time"
          margin={{
            top: 40,
            right: 20,
            bottom: 60,
            left: 80
          }}
          padding={0.7}
          labelSkipWidth={10}
          labelSkipHeight={-10}
          labelTextColor="#FFFFFF"
          animate={true}
          axisBottom={{
            orient: 'bottom',
            tickSize: 10,
            tickPadding: 5,
            tickRotation: -20,
            legendPosition: 'center',
            legendOffset: 80
          }}
          axisLeft={{
            orient: 'bottom',
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'In Rupees',
            legendPosition: 'middle',
            legendOffset: -60,
            format: (v) => `${v}K`
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -160,
              itemsSpacing: 1,
              itemWidth: 100,
              itemHeight: 10,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20
            }
          ]}
        />
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
