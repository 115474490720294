import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../utils/store';
import { API_GET } from '../../../../utils/api';
import { Components } from '../../../../utils/material-ui';
import { getDateTime } from '../../../../utils/moment';
import Dialog from '../../../atom/Dialog';
import CloseIncident from '../../Modal/CloseIncident';
import tableColor from '../../../../utils/tableColor';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import EmergencyContacts from '../../Modal/EmergencyContacts';
import theme from '../../../../utils/theme';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  TableCell,
  Grid
} = Components;

const Item = (props) => <Grid item {...props} />;

const PendingIncidentTable = ({
  classes,
  selectedType,
  incidentDate,
  incidentToDate,
  informedTo,
  filter,
  type,
  searchText,
  refreshPage,
  corporate,
  city,
  attendedBy,
  service
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalIncidents, settotalIncidents] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [Modal, setModal] = useState(false);
  const [isOpenEmergencyContactModel, setIsOpenEmergencyContactModel] = useState(false);
  const [emergencyContactId, setEmergencyContactId] = useState('');
  const [selectedRow, setSelectedRow] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setModal(false);
    setPage(0);
    refreshPage();
  };

  useEffect(() => {
    API_GET(
      `report-incident/ended?incidentType=${selectedType}&incidentDate=${incidentDate}&incidentToDate=${incidentToDate}&informedTo=${filter}&limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&pendingFilter=${true}&searchText=${searchText}&corporate=${corporate}&city=${city}&attendedBy=${attendedBy}&service=${service}`
    )
      .then((res) => {
        settotalIncidents(res.data.count);
        setRows(res.data.incidents);
      })
      .catch((err) => {
        err.status === 404
          ? dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Network Error'
            })
          : dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: err.data.errors[0]?.message ?? 'Oops!!, Please try again'
            });
      });
  }, [
    dispatch,
    page,
    rowsPerPage,
    incidentDate,
    incidentToDate,
    selectedType,
    informedTo,
    type,
    filter,
    searchText,
    Modal,
    corporate,
    city,
    attendedBy,
    service
  ]);

  const handleCloseIncident = (row) => {
    setSelectedRow(row);
    setModal(true);
  };

  const showInMapClicked = (lat, lng) => {
    window.open(`https://maps.google.com?q=${lat},${lng}`);
  };

  const handleMoreDetail = (id) => {
    setIsOpenEmergencyContactModel(true);
    setEmergencyContactId(id);
  };
  const handleCloseEmergencyContact = () => {
    setIsOpenEmergencyContactModel(false);
  };

  const getType = (type, closedBy) => {
    if (type) {
      return type;
    } else if (closedBy === 'user') {
      return 'End by User';
    }
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {rows &&
              rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    style={{ backgroundColor: tableColor(index) }}>
                    {columns &&
                      columns.map((column) => {
                        const value = row[column.id] ?? '--';
                        if (column.id === 'incomingTime' || column.id === 'closureTime') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.id === 'closureTime' && selectedType === 'unknown'
                                ? '-'
                                : getDateTime(value)}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'acknowledgedAt') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {row[column.id] ? getDateTime(row[column.id]) : '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'service') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {Array.isArray(value) && value.join(', ')}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'viewOnMap') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              <Button
                                className={classes.viewButton}
                                style={{ color: theme.palette.primary.sosDashboard }}
                                onClick={() => showInMapClicked(row.lat, row.lng)}>
                                View
                              </Button>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'offlineSos') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {row.offlineSos ? 'true' : 'false'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'type') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {getType(value, row.closedBy)}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'informedTo') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {ConvertToUpperCase(row?.informedTo) || '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'emergencyContact') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              <Item
                                onClick={() => handleMoreDetail(row?.id)}
                                sx={{ cursor: 'pointer' }}>
                                <img
                                  src="https://img.icons8.com/ios/50/undefined/info--v1.png"
                                  height="22px"
                                  width="22px"
                                  alt="logo"
                                />
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'action') {
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              <Button
                                onClick={() => handleCloseIncident(row)}
                                style={{ color: theme.palette.primary.sosDashboard }}
                                className={classes.actionBtn}>
                                Close
                              </Button>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalIncidents && totalIncidents ? totalIncidents : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        isOpen={Modal}
        children={<CloseIncident row={selectedRow} handleClose={handleClose} />}></Dialog>
      <Dialog
        isOpen={isOpenEmergencyContactModel}
        children={
          <EmergencyContacts
            handleClose={handleCloseEmergencyContact}
            emergencyContactId={emergencyContactId}
          />
        }></Dialog>
    </Card>
  );
};

export default withStyles(styles)(PendingIncidentTable);
