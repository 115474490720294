const data = [
  {
    id: 'Festivals',
    color: '#ED3E3C',
    data: [
      {
        x: '2023-01-01',
        y: 0
      }
    ]
  },
  {
    id: 'Injury',
    color: '#0CAF74',
    data: [
      {
        x: '2023-01-01',
        y: 0
      }
    ]
  },
  {
    id: 'Strike',
    color: '#FCB94D',
    data: [
      {
        x: '2023-01-01',
        y: 0
      }
    ]
  },
  {
    id: 'Others',
    color: '#3B9DF7',
    data: [
      {
        x: '2023-01-01',
        y: 0
      }
    ]
  },
  {
    id: 'Riots',
    color: '#6B8E23',
    data: [
      {
        x: '2023-01-01',
        y: 0
      }
    ]
  },
  {
    id: 'Public unrest',
    color: '#8B4513',
    data: [
      {
        x: '2023-01-01',
        y: 0
      }
    ]
  }
];

export default data;
