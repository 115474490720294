const styles = (theme) => {
  return {
    mainContainer: {
      backgroundColor: theme.palette.common.gray98,
      padding: theme.spacing(2, 2)
    },

    selectedCard: {
      width: '100%',
      height: '30px',
      color: 'black',
      // display: 'flex',
      flexDirection: 'column',
      // backgroundColor: theme.palette.primary.secondtheme,
      textAlign: 'left',
      padding: theme.spacing(1),
      margin: theme.spacing(2, 0)
    },
    unselectedCard: {
      cursor: 'pointer',
      width: '100%',
      height: '30px',
      color: 'black',
      backgroundColor: 'white',
      // display: 'flex',
      textAlign: 'left',
      flexDirection: 'column',
      padding: theme.spacing(1),
      margin: theme.spacing(2, 0)
    },

    newUser: {
      boxShadow: '0px 1px 5px grey',
      borderRadius: theme.spacing(2)
    },
    cardHeading: {
      padding: '7px 20px',
      backgroundColor: theme.palette.primary.tableHeadingColor,
      borderRadius: '8px 8px 0 0',
      color: theme.palette.common.white,
      textAlign: 'left'
    },

    newUserForm: {
      display: 'flex',
      padding: theme.spacing(0, 2)
    },
    formContainer: {
      minWidth: '320px',
      width: '320px',
      maxHeight: '60vh',
      overflow: 'auto',
      display: 'flex',
      gap: '15px',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly'
    }
  };
};

export default styles;
