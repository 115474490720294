const head = [
  {
    id: 'srno',
    label: 'Sr.No',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'dateTime',
    label: 'Date & Time',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'city',
    label: 'Location',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },
  {
    id: 'gender',
    label: 'Gender (M/F)',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'adminAction',
    label: 'Fake/Genuine/End By User',
    minWidth: 40,
    width: 40,
    maxWidth: 40,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'assistance',
    label: 'Services',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
