const styles = (theme) => {
  return {
    ongoingPage: {
      display: 'flex'
    },
    incidentContainer: {
      // width: '20vw',
      // height: '91vh',
      maxWidth: '320px',
      height: '85vh',
      borderRight: '1px solid black',
      overflow: 'auto'
    },
    incidentHeading: {
      borderBottom: '1px solid grey',
      padding: '16px'
    },
    userCard: {
      position: 'absolute',
      top: '-110px',
      left: '30px',
      backgroundColor: 'white',
      width: '370px',
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)'
    },
    cardHeading: {
      padding: '7px 15px',
      backgroundColor: theme.palette.primary.sosDashboard,
      borderRadius: '5px 5px 0 0',
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    // mapContainer: {
    //   height: '91vh',
    //   width: '75%',
    //   position: 'relative'
    // },
    cardContent: {
      padding: '10px'
    },
    container: {
      padding: theme.spacing(1.5),
      borderBottom: '1px solid grey',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: theme.palette.common.hover
      }
    },
    clickbleIncidentCard: {
      backgroundColor: theme.palette.common.hover,
      padding: theme.spacing(1.5),
      borderBottom: '1px solid grey'
    },
    timerDiv: {
      backgroundColor: theme.palette.common.green,
      padding: '6px 10px',
      borderRadius: '25px',
      textAlign: 'center',
      color: 'white'
    },
    recievedTime: {
      color: 'grey',
      marginRight: '12px'
    },
    phoneNumber: {
      fontSize: '16px',
      fontWeight: 'bold'
    },
    blue: {
      color: 'blue'
    },
    flex: {
      display: 'flex'
    },
    occupancyChart: {
      height: '45vh',
      width: '100%',
      padding: theme.spacing(0.5)
    },
    mapContainer: {
      height: '85vh',
      // width: 'auto',
      position: 'relative'
      // height: '100%',
      // width: '100%'
    },
    icon: {
      color: theme.palette.primary.sosDashboard,
      fontSize: '25px'
    },
    logoTextContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px'
    }
  };
};

export default styles;
