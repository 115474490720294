import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';

const { withStyles, Button, Grid } = Components;

const Item = (props) => <Grid container {...props} />;

const filterArray = [
  { value: '', label: 'All', color: 'black', backgroundColor: '#FFFFFF', count: '' },
  {
    value: 'emergency',
    label: 'Emergency',
    color: '#EC443C',
    backgroundColor: '#F4D2D1',
    count: ''
  },
  { value: 'critical', label: 'Critical', color: '#E88344', backgroundColor: '#FFD9C1', count: '' },
  { value: 'warning', label: 'Warning', color: '#EDAF01', backgroundColor: '#FFEFC1', count: '' },
  { value: 'notices', label: 'Notices', color: '#0F78CD', backgroundColor: '#B2DAFA', count: '' },
  { value: 'info', label: 'Info', color: '#30AA35', backgroundColor: '#C4ECC5', count: '' }
];

const FilterCategory = ({ handleSelectedCategory }) => {
  const handleCategory = (catgeoryValue) => {
    handleSelectedCategory(catgeoryValue);
  };

  return (
    <Item
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px'
      }}>
      {filterArray?.map((item, index) => {
        return (
          <Button
            onClick={() => handleCategory(item?.value)}
            key={index}
            style={{
              color: item?.color,
              backgroundColor: item?.backgroundColor,
              width: '150px',
              margin: '1% 0%',
              fontSize: '14px',
              fontWeight: '600',
              borderRadius: '15px',
              boxShadow: item?.label === 'All' && '0px 0px 2px grey'
            }}>
            {item?.label}
          </Button>
        );
      })}
    </Item>
  );
};

export default withStyles(styles)(FilterCategory);
