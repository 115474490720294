import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../utils/store';
import { Components } from '../../../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR, API_PATCH } from '../../../../utils/api';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { ApiDates } from '../../../../utils/ApiDates';
import { Android12Switch } from '../../../../utils/ButtonDiff';
import theme from '../../../../utils/theme';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  // Button,
  FormControlLabel
} = Components;

const Item = (props) => <Grid item {...props} />;

const DeletedDoctor = ({
  classes,
  searchText,
  selectedFromDate,
  selectedToDate,
  sort,
  filtersCategory
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRejectedNews, setTotalAppointment] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllDoctors();
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchText,
    sort,
    selectedFromDate,
    selectedToDate,
    filtersCategory
  ]);

  const getAllDoctors = () => {
    const resultSearch = searchText !== undefined ? `searchText=${searchText}` : ``;
    let resultFilter = '';
    for (let i = 0; i < filtersCategory?.length; i++) {
      resultFilter += `&category[]=${filtersCategory[i]?.name}`;
    }

    const resultDate = ApiDates(selectedFromDate, selectedToDate);
    API_GET(
      `doctorProfile/getDoctors?status=false&${resultSearch}&order=${sort}${resultDate}&${resultFilter}`
    )
      .then((res) => {
        setRows(res?.data?.doctors);
        setTotalAppointment(res?.data?.totalDoctors);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleActivate = (item) => {
    API_PATCH(`doctorProfile/restoreDoctor/${item?._id}`)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        getAllDoctors();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.thead}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    {columns &&
                      columns.map((column, index) => {
                        const value = row[column.id] ?? '--';

                        if (column.id === 'action') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      onChange={() => handleActivate(row)}
                                      size="medium"
                                      sx={{ ml: 1.5 }}
                                    />
                                  }
                                />
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(ConvertToUpperCase(value))
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalRejectedNews && totalRejectedNews ? totalRejectedNews : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default withStyles(styles)(DeletedDoctor);
