import { ResponsivePieCanvas } from '@nivo/pie';
import React, { useEffect, useState } from 'react';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';

const MyResponsivePieCanvas = ({ selectedFromDate, selectedToDate, isIncident }) => {
  const [, dispatch] = useStateValue();
  const [data, setData] = useState([]);
  const getPieChartData = () => {
    API_GET(
      `incident/getStatusDistributionPercentage?fromDate=${selectedFromDate}&toDate=${selectedToDate}&alertNewsStatus=${isIncident}`
    )
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return {
            label: `${item?._id}-${item?.count}`,
            color: item?.color,
            value: item?.count,
            id: `${item?._id}`
          };
        });
        setData(resultData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getPieChartData();
  }, [selectedToDate, selectedFromDate, isIncident]);

  return (
    <ResponsivePieCanvas
      theme={{
        fontSize: 11.2,
        fontWeight: '600'
      }}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      data={data}
      margin={{ top: 15, right: 150, bottom: 15, left: 0 }}
      innerRadius={0.5}
      padAngle={0.2}
      cornerRadius={1}
      activeOuterRadiusOffset={5}
      colors={{ datum: 'data.color' }}
      arcLinkLabelsSkipAngle={5}
      arcLinkLabelsTextColor="black"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={5}
      arcLabelsTextColor="black"
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 70,
          translateY: 10,
          itemsSpacing: 2,
          itemWidth: 80,
          itemHeight: 25,
          itemTextColor: 'black',
          itemDirection: 'left-to-right',
          itemOpacity: 0,
          symbolSize: 20,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 3
              }
            }
          ]
        }
      ]}
    />
  );
};

export default MyResponsivePieCanvas;
