import React, { useState, useEffect } from 'react';
import { Components } from '../../../utils/material-ui';
import theme from '../../../utils/theme';
import moment from 'moment';
import { useStateValue } from '../../../utils/store';
import pakagescss from './pakagescss';
import PakagesTable from '../../../components/organism/Table/TravelManagementTables/PakagesTable';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
const { withStyles, Grid, Paper, TextField, MenuItem, Select, FormControl, InputLabel } =
  Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

// const pakageArray = ['All', 'Family Time', 'Public'];
const sortBy = [
  { name: 'Start Date', value: 'startDate' },
  { name: 'Pax', value: 'paxNum' },
  { name: 'Country', value: 'country' },
  { name: 'Destination', value: 'destination' }
];

const Pakages = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [sort, setSort] = useState('startDate');
  const [allGroups, setAllGroups] = useState([{ name: 'All', value: 'all' }]);
  const [packageType, setPackageType] = useState('all');
  const [selectedFromDate, setSelectedFromDate] = useState('');
  const [selectedToDate, setSelectedToDate] = useState('');

  const getAllGroups = () => {
    API_GET(`travelPackage/getAllPackageTypes`)
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return {
            name: item?.name,
            value: item?._id
          };
        });
        setAllGroups([...allGroups, ...resultData]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  console.log('allGroups', allGroups);

  // const handleStateChange = (value) => {
  //   console.log('value', value);
  //   // setPackageType(value);
  // };

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  return (
    <Container>
      <Item md={12} mt={2}>
        <Paper sx={{ ...theme.card, borderRadius: 'none' }}>
          <Item className={classes.filterContainer} md={12}>
            <Item>
              <form noValidate>
                <TextField
                  id="datetime-local"
                  label="From"
                  type="date"
                  size="small"
                  sx={{ width: '200px' }}
                  value={selectedFromDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onFromDateChange(e);
                  }}
                />
              </form>
            </Item>
            <Item>
              <form noValidate>
                <TextField
                  id="date"
                  label="To"
                  type="date"
                  size="small"
                  sx={{ width: '200px' }}
                  value={selectedToDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onToDateChange(e);
                  }}
                />
              </form>
            </Item>
            {/* <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Package Name </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={packageName}
                  sx={{ width: '200px' }}
                  label=" Package Name "
                  onChange={(e) => setPackageName(e.target.value)}>
                  {pakageArray?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {ConvertToUpperCase(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
                </Item> */}
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Group Type </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={packageType}
                  sx={{ width: '200px' }}
                  label="Group Type "
                  onChange={(e) => {
                    setPackageType(e.target.value);
                  }}>
                  {allGroups?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {ConvertToUpperCase(item?.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Sort By </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={sort}
                  sx={{ width: '200px' }}
                  label="Sort By"
                  onChange={(e) => setSort(e.target.value)}>
                  {sortBy?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {ConvertToUpperCase(item?.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
          </Item>
        </Paper>
      </Item>
      <Item md={12} style={{ width: '500px' }} mt={1}>
        <PakagesTable
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
          // packageName={packageName}
          packageType={packageType}
          sort={sort}
        />
      </Item>
    </Container>
  );
};

export default withStyles(pakagescss)(Pakages);
