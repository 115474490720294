import { ResponsivePieCanvas } from '@nivo/pie';
import React, { useEffect } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
// import moment from 'moment';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon } = Icons;
const Item = (props) => <Grid item {...props} />;

// const data = [
//   { label: 'Economy', value: 14 / 100, color: '#E53422', id: 'Economy' },
//   { label: 'Social', value: 6 / 100, color: '#EC7A1A', id: 'Social' },
//   {
//     label: 'Political',
//     value: 14 / 100,
//     color: '#EDAF01',
//     id: 'Political'
//   },
//   { label: 'Pandemic', value: 10 / 100, color: '#1F8A34', id: 'Pandemic' },
//   { label: 'Health', value: 56 / 100, color: '#2DC02B', id: 'Health' },
//   { label: 'Weather', value: 52 / 100, color: '#5768BC', id: 'Weather' },
//   { label: 'Others', value: 44 / 100, color: '#2C9AFF', id: 'Others' }
// ];

// const exportData = [
//   {
//     'Sr No': 1,
//     Date: '22-05-2022',
//     Economy: 10,
//     Social: 12,
//     Political: 15,
//     Pandemic: 6,
//     Health: 17,
//     Weather: 10
//   },
//   {
//     'Sr No': 2,
//     Date: '23-05-2022',
//     Economy: 10,
//     Social: 12,
//     Political: 15,
//     Pandemic: 6,
//     Health: 17,
//     Weather: 10
//   }
// ];

const MyResponsivePieCanvas = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    const resultLocation = location === 'All' ? '' : `city=${location}`;
    API_GET(
      `reports/getAreasOfConcernGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultLocation}`
    )
      .then((res) => {
        setRows(res?.areasOfConcernData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location]);

  const handleExport = () => {
    API_GET(
      `reports/downloadAreasOfConcernGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}`
    )
      .then((res) => {
        const resultData = res?.areasOfConcernData?.map((item) => {
          return {
            SrNo: item?.SrNo,
            Date: item?.Date,
            ...item
          };
        });

        downloadDataToExcelFile(
          resultData,
          `Ext.Incident_AOC (${selectedFromDate} To ${selectedToDate})`
        );
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Area of Concern
          </Typography>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '230px' }}>
        <ResponsivePieCanvas
          theme={{
            fontSize: 14,
            fontWeight: '600'
          }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          // valueFormat=" >-1.1%"
          data={rows}
          margin={{ top: 10, right: 170, bottom: 20, left: 10 }}
          innerRadius={0.4}
          // padAngle={0.0}
          cornerRadius={0}
          activeOuterRadiusOffset={8}
          colors={{ datum: 'data.color' }}
          arcLinkLabelsSkipAngle={0}
          arcLinkLabelsText={false}
          arcLinkLabelsTextColor="black"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={5}
          arcLabelsTextColor="black"
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 130,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 90,
              itemHeight: 28,
              itemTextColor: 'black',
              itemDirection: 'left-to-right',

              symbolSize: 15
            }
          ]}
        />
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(MyResponsivePieCanvas);
