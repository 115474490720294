import React, { useState } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import { API_PATCH } from '../../../../utils/api';
import { useStateValue } from '../../../../utils/store';
import theme from '../../../../utils/theme';

const { withStyles, Grid, Button, TextField } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function CloseIncident({ classes, handleClose, row }) {
  const [closeComment, setCloseComment] = useState('');
  const [, dispatch] = useStateValue();
  const handleCloseIncident = (e) => {
    e.preventDefault();
    API_PATCH(`report-incident/endDashboardPending?incidentId=${row.id}`, {
      closeComment
    })
      .then((res) => {
        handleClose();
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data.message
        });
      })
      .catch((err) => {
        err.status === 404
          ? dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Network Error'
            })
          : dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: err.data.errors[0]?.message ?? 'Oops!!, Please try again'
            });
      });
  };
  return (
    <Container direction="column" className={classes.newUser}>
      <form onSubmit={handleCloseIncident}>
        <Item xs={12}>
          <TextField
            style={{ width: '100%' }}
            id="outlined-number"
            label="Reason"
            type="text"
            variant="outlined"
            size="large"
            required
            value={closeComment}
            onChange={(e) => setCloseComment(e.target.value)}
          />
        </Item>
        <Item>
          <Container spacing={2} justify="center" style={{ marginTop: '10px' }}>
            <Item>
              <Button
                style={{ backgroundColor: theme.palette.primary.sosDashboard, color: 'white' }}
                onClick={() => handleClose()}
                variant="contained"
                color="primary">
                Cancel
              </Button>
            </Item>
            <Item>
              <Button
                style={{ backgroundColor: theme.palette.primary.sosDashboard, color: 'white' }}
                type="submit"
                variant="contained"
                color="primary">
                OK
              </Button>
            </Item>
          </Container>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(CloseIncident);
