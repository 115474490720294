import React from 'react';
import UploadDataTable from '../../components/organism/Table/UploadDataTable';
import { Components } from '../../utils/material-ui';
import styles from './styles';

const { withStyles, Grid } = Components;
const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const UploadData = ({ classes }) => {
  return (
    <Container direction="column">
      <Item md={12} xs={12} lg={12}>
        <UploadDataTable />
      </Item>
    </Container>
  );
};

export default withStyles(styles)(UploadData);
