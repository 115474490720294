import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import ConvertToMinutes from '../../../../utils/ConvertToMinutes';
import { useStateValue } from '../../../../utils/store';
import { API_HANDLE_ERROR, API_POST, PRE_SEND_URL, API_PUT } from '../../../../utils/api';
import TimeConversionWithoutAM from '../../../../utils/TimeConversionWithoutAm';
import axios from 'axios';
import {
  nameValidate,
  mobileNumberValidate,
  // mobileNumberValidateOnSubmit,
  emailValidate
} from '../../../../utils/formValidation';

const {
  withStyles,
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem
  // FormControl,
  // ListItemIcon,
  // ListItemText,
  // Checkbox
} = Components;

const { AddCircleOutlineOutlinedIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const categoryArray = [
  { name: 'General Physician', value: 'generalphysician' },
  { name: 'Psychiatric', value: 'psychiatric' }
  // { name: 'Other', value: 'Other' }
];
const daysArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function AddDoctor({ classes, handleClose, editData, getAllDoctors }) {
  const [, dispatch] = useStateValue();

  const [sendData, setSendData] = useState({
    name: '',
    category: 'generalphysician',
    phone: '',
    address: '',
    day: 'Monday',
    startTime: '',
    endTime: '',
    email: '',
    description: '',
    profileImg: []
  });

  const [data, setData] = useState({
    Monday: [{ start: '', end: '' }],
    Tuesday: [{ start: '', end: '' }],
    Wednesday: [{ start: '', end: '' }],
    Thursday: [{ start: '', end: '' }],
    Friday: [{ start: '', end: '' }],
    Saturday: [{ start: '', end: '' }],
    Sunday: [{ start: '', end: '' }]
  });

  useEffect(() => {
    setSendData({
      name: editData?.fullName,
      category: editData?.category,
      phone: editData?.mobileNumber,
      address: editData?.hospitalName,
      day: 'Monday',
      startTime: '',
      endTime: '',
      email: editData?.email,
      description: editData?.description,
      profileImg: [editData?.profileImage]
    });

    // const editResult = editData?.slots?.reduce(({ accumulator, value }) => {

    //   return {
    //     ...accumulator,
    //     [value?.day]: value?.intervals?.map((item) => {
    //       return {
    //         start: item?.startTime,
    //         end: item?.endTime
    //       };
    //     })
    //   };
    // });
    const obj = {};
    editData?.slots?.forEach((element) => {
      obj[`${element.day}`] = element?.intervals?.map((item) => {
        return {
          start: TimeConversionWithoutAM(item?.startTime),
          end: TimeConversionWithoutAM(item?.endTime)
        };
      });
    });
    if (editData) {
      setData(obj);
    }
  }, [editData]);

  // useEffect(() => {
  //   const slotObj = {
  //     start: sendData?.startTime,
  //     end: sendData?.endTime
  //   };
  //   setData();
  // }, [sendData.day]);

  const handleStateChange = (key, value) => {
    if (key === 'name') {
      if (nameValidate(value) === false) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Special charaters are not allowed'
        });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'phone') {
      if (value < 0) {
        setSendData({ ...sendData, phone: '' });
      } else if (value.length <= 10) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else {
      setSendData((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  const handleimage = async (status, img) => {
    const arrayImage = [];

    for (let i = 0; i < img.length; i++) {
      const docsType = img[i]?.type?.split('/');
      const name = img[i]?.name;
      const url = await precenturl(docsType[1], name);
      const file = img[i];

      const config = {
        method: 'put',
        url: url.signedUrl,
        headers: {
          'x-ms-blob-content-type': img[i].type,
          'content-type': 'application/octet-stream',
          'x-ms-blob-type': 'BlockBlob'
        },
        data: file
      };

      axios(config)
        .then(function (response) {
          arrayImage.push(url.mediaUrl);
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: response?.data?.message || 'upload Successfully'
          });
        })
        .catch(function (error) {
          API_HANDLE_ERROR(error, dispatch);
        });
    }
    handleStateChange('profileImg', arrayImage);
  };

  const precenturl = async (type, name) => {
    try {
      const data = await PRE_SEND_URL('user/incident/preSignedMediaUrl', {
        fileType: type,
        fileName: name.split('.')[0],
        featureType: 'doctorProfileImage'
      });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    const resultData = [];
    for (const key in data) {
      if (key !== 'result') {
        const result = {
          day: key,
          intervals: data[key]?.map((item) => {
            return {
              startTime: ConvertToMinutes(item?.start),
              endTime: ConvertToMinutes(item?.end),
              available: true
            };
          })
        };
        resultData.push(result);
      }
    }

    const postData = {
      fullName: sendData?.name,
      category: sendData?.category,
      hospitalName: sendData?.address,
      description: sendData?.description,
      mobileNumber: sendData?.phone,
      email: sendData?.email,
      status: true,
      slots: resultData,
      profileImage: sendData?.profileImg[0]
    };

    if (emailValidate(sendData?.email) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct email'
      });
    } else if (mobileNumberValidate(sendData?.phone) === false) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Enter correct mobile number'
      });
    } else {
      if (editData) {
        API_PUT(`doctorProfile/updateDoctor/${editData?._id}`, postData)
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            handleClose();
            getAllDoctors();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      } else {
        API_POST(`doctorProfile/addDoctor`, postData)
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            handleClose();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      }
    }
  };
  const handleStartChange = (timestatus, value, index) => {
    if (timestatus === 'startTime') {
      const result = data[`${sendData.day}`].map((item, itemIndex) => {
        return itemIndex === index ? (item.start = value) : '';
      });
      return setData({ ...data, result });
    } else {
      const result = data[`${sendData.day}`].map((item, itemIndex) => {
        return itemIndex === index ? (item.end = value) : '';
      });
      return setData({ ...data, result });
    }
  };

  const handleAddMoreSlot = (index) => {
    const PostData = {
      start: '',
      end: ''
    };
    const slotsData = { ...data };
    slotsData[`${sendData.day}`].push(PostData);
    setData(slotsData);
  };

  // const imageAvaialble = (images) => {
  //   if (images[0] !== undefined) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: 'auto' }}>
      <Item className={classes.title}>
        <Typography style={{ textAlign: 'center' }}>
          {editData ? 'Update Doctor Profile' : 'Add Doctor'}
        </Typography>
      </Item>
      <form onSubmit={onHandleConfirm}>
        <Item className={classes.fromContainer}>
          <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '150px' }}>
              <Typography>PROFILE IMG</Typography>
            </Item>

            <Item
              sx={{
                width: '250px',
                height: '40px',
                border: '1px solid grey',
                p: 1,
                display: 'flex',
                flexwrap: 'nowrap'
              }}>
              <input
                variant="outlined"
                // required={imageAvaialble(sendData?.profileImg)}
                accept=".jpeg, .jpg, .png"
                type="file"
                multiple
                size="small"
                onChange={(e) => handleimage('profile', e.target.files)}
              />
            </Item>
            <Item sx={{ height: '40px', width: '40px', border: '1px solid lightGrey', ml: 1 }}>
              <img src={sendData?.profileImg[0]} alt="" style={{ height: '100%', width: '100%' }} />
            </Item>
          </Item>
          <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '150px' }}>
              <Typography>NAME*</Typography>
            </Item>

            <Item>
              <TextField
                variant="outlined"
                name="name"
                sx={{ width: '300px' }}
                size="small"
                required={true}
                value={sendData?.name}
                className={classes.formControl}
                onChange={(e) => handleStateChange('name', e.target.value)}
                type="text"
              />
            </Item>
          </Item>

          <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '150px' }}>
              <Typography>CATEGORY*</Typography>
            </Item>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              sx={{ width: '300px' }}
              value={sendData?.category}
              fullWidth
              onChange={(e) => handleStateChange('category', e.target.value)}>
              {categoryArray?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.value}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Item>

          <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '150px' }}>
              <Typography>PHONE*</Typography>
            </Item>

            <Item>
              <TextField
                variant="outlined"
                name="phone"
                sx={{ width: '300px' }}
                size="small"
                required={true}
                onBlur={(e) => {
                  if (e.target.value !== '' && mobileNumberValidate(sendData?.phone) === false) {
                    dispatch({
                      type: 'ALERT_OPEN',
                      severity: 'error',
                      isOpen: true,
                      label: 'Please enter correct phone number'
                    });
                  }
                }}
                value={sendData?.phone}
                className={classes.formControl}
                onChange={(e) => handleStateChange('phone', e.target.value)}
                type="number"
              />
            </Item>
          </Item>
          <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '150px' }}>
              <Typography>EMAIL*</Typography>
            </Item>

            <Item>
              <TextField
                variant="outlined"
                name="phone"
                sx={{ width: '300px' }}
                size="small"
                required={true}
                value={sendData?.email}
                className={classes.formControl}
                onBlur={(e) => {
                  if (e.target.value !== '' && emailValidate(sendData?.email) === false) {
                    dispatch({
                      type: 'ALERT_OPEN',
                      severity: 'error',
                      isOpen: true,
                      label: 'Email is not valid'
                    });
                  }
                }}
                onChange={(e) => handleStateChange('email', e.target.value)}
                type="email"
              />
            </Item>
          </Item>
          <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '150px' }}>
              <Typography>DESCRPTION*</Typography>
            </Item>

            <Item>
              <TextField
                variant="outlined"
                name="phone"
                sx={{ width: '300px' }}
                size="small"
                required={true}
                value={sendData?.description}
                className={classes.formControl}
                onChange={(e) => handleStateChange('description', e.target.value)}
                type="text"
              />
            </Item>
          </Item>
          <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '150px' }}>
              <Typography>HOSPITAL ADDRESS*</Typography>
            </Item>

            <Item>
              <TextField
                variant="outlined"
                name="address"
                sx={{ width: '300px' }}
                size="small"
                required={true}
                value={sendData?.address}
                className={classes.formControl}
                onChange={(e) => handleStateChange('address', e.target.value)}
                type="text"
              />
            </Item>
          </Item>
          <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '150px' }}>
              <Typography>DAYS*</Typography>
            </Item>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sendData?.day}
              size="small"
              sx={{ width: '300px' }}
              fullWidth
              onChange={(e) => handleStateChange('day', e.target.value)}>
              {daysArray?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Item>
          <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '160px' }}>
              <Typography>TIMINGS*</Typography>
            </Item>
            <Item
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '150px',
                overflow: 'auto'
              }}>
              {data[`${sendData.day}`]?.map((item, index) => {
                return (
                  <Item sx={{ display: 'flex', gap: '12px' }}>
                    <Item>
                      <TextField
                        variant="outlined"
                        name="name"
                        sx={{ width: '120px' }}
                        size="small"
                        ampm={false}
                        required={true}
                        value={item?.start}
                        className={classes.formControl}
                        onChange={(e) => handleStartChange('startTime', e.target.value, index)}
                        type="time"
                      />
                    </Item>

                    <Item>
                      <TextField
                        variant="outlined"
                        name="name"
                        sx={{ width: '120px' }}
                        size="small"
                        ampm={false}
                        required={true}
                        value={item?.end}
                        className={classes.formControl}
                        onChange={(e) => handleStartChange('endTime', e.target.value, index)}
                        type="time"
                      />
                    </Item>
                  </Item>
                );
              })}
            </Item>
            <Item sx={{ pt: 0.5, ml: 1 }}>
              <AddCircleOutlineOutlinedIcon
                onClick={() => handleAddMoreSlot()}
                sx={{
                  fontSize: '30px',
                  color: theme.palette.primary.tableHeadingColor,
                  cursor: 'pointer'
                }}
              />
            </Item>
          </Item>

          {/* <Item sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Item sx={{ width: '150px' }}>
              <Typography>DAYS</Typography>
            </Item>
            <FormControl sx={{ width: "300px" }}>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={sendData?.days}
                sx={{ width: "300px" }}
                size="small"
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}>
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : ''
                  }}>
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        sendData?.days.length > 0 && sendData?.days.length < options.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={sendData?.days.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
                </Item> */}
        </Item>
        <Item sx={{ display: 'flex', gap: '10px', p: 1 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button size="small" style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
              Cancel
            </Button>
          </Item>
          <Item sx={{ mr: 0.5 }}>
            <Button
              type="submit"
              size="small"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              {`Submit`}
            </Button>
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(AddDoctor);
