import React, { useState, useEffect } from 'react';
import { Components } from '../../../utils/material-ui';
import theme from '../../../utils/theme';
import styles from './styles';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';

const { withStyles, Typography, Grid } = Components;

const Item = (props) => <Grid item {...props} />;

const Progressbar = ({ classes, selectedFromDate, selectedToDate }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  console.log('rows', rows);

  useEffect(() => {
    API_GET(`report-incident/getSosUsage?fromDate=${selectedFromDate}&toDate=${selectedToDate}`)
      .then((res) => {
        setRows(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate]);

  return (
    <React.Fragment>
      <Item>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading }}>SOS Usage</Typography>
        </Item>
      </Item>

      <Item p="0px 15px">
        {rows?.map((item, index) => {
          return (
            <Item key={index} mt={index !== 0 && 1}>
              <Item
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ fontSize: '14px' }}>{item?.name}</p>
                <p style={{ color: item?.color, fontSize: '14px' }}>{`${
                  item?.count === null ? 0 : item?.count
                }/${item?.total}`}</p>
              </Item>
              <Item
                style={{
                  height: '9px',
                  borderRadius: '5px',
                  width: '100%',
                  backgroundColor: item?.backgroundColor
                }}>
                <Item
                  style={{
                    height: '100%',
                    width: `${item?.percentage === null ? 0 : item?.percentage}%`,
                    backgroundColor: item?.color,
                    borderRadius: '5px'
                  }}></Item>
              </Item>
            </Item>
          );
        })}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(Progressbar);
