const head = [
  {
    id: 'publishDate',
    label: 'Pub. Date&Time',
    minWidth: 140,
    width: 140,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'eventDate',
    label: 'Event Date&Time',
    minWidth: 140,
    width: 140,
    maxWidth: 150,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'ticketNumber',
    label: 'Ticket No',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'event',
    label: 'Event',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'left'
  },

  {
    id: 'region',
    label: 'Location',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'forwardedBy',
    label: 'Pri. Initiator',
    minWidth: 140,
    width: 140,
    maxWidth: 150,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'forwardedTo',
    label: 'Incident Log',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'actualDate',
    label: 'Completion Date',
    minWidth: 140,
    width: 140,
    maxWidth: 150,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'planDays',
    label: 'Plan Days',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'totalDays',
    label: 'Total Days',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'extraDays',
    label: 'Extra Days',
    minWidth: 140,
    width: 140,
    maxWidth: 150,
    align: 'center'
  },

  {
    id: 'incidentActionStatus',
    label: 'Global Status',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
