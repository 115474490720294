import React from 'react';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import { getDate } from '../../utils/moment';

const { withStyles, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

const DependentViewPersonal = ({ classes, indiviualUserData }) => {
  const {
    fullName,
    relation,
    mobileNumber,
    dateOfBirth,
    gender,
    mealpreference,
    // smokingStatus,
    freefield1,
    freefield2,
    freefield3
  } = indiviualUserData;

  const DependentDetails = {
    Name: ConvertToUpperCase(fullName) || '-',
    Relation: ConvertToUpperCase(relation) || '-',
    'Mobile Number': mobileNumber || '-',
    DOB: dateOfBirth ? getDate(dateOfBirth) : '-',
    Gender: ConvertToUpperCase(gender) || '-',
    'Meal Preference': ConvertToUpperCase(mealpreference) || '-',
    // 'Smoking Status': ConvertToUpperCase(smokingStatus) || '-',
    'Free Field 1': ConvertToUpperCase(freefield1) || '-',
    'Free Field 2': ConvertToUpperCase(freefield2) || '-',
    'Free Field 3': ConvertToUpperCase(freefield3) || '-'
  };
  return (
    <Item sx={{ mt: 2, maxHeight: '65vh', overflow: 'auto' }}>
      <Item
        sx={{
          flex: 1,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: '10px'
        }}
        md={12}
        xs={12}
        lg={12}>
        {Object.keys(DependentDetails).map((item) => {
          return (
            <Item sx={{ mt: 2 }} md={5.8} xs={12} lg={3.6}>
              <Typography>{item}</Typography>
              <Typography className={classes.textColor}>{DependentDetails[item] || '-'}</Typography>
            </Item>
          );
        })}
      </Item>
    </Item>
  );
};

export default withStyles(styles)(DependentViewPersonal);
