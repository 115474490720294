/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import theme from '../../utils/theme';
import AlertMenuList from './alertMenuList';
import ScatterPlot from './ScatterPlot';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import styles from './styles';
import AlertSummaryTable from '../../components/organism/Table/AlertSummaryTable';

import BarChart1 from './barChart1';

const { withStyles, Grid, Paper, Button, Card } = Components;
const { LinkIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const data = [
  { name: 'Health', count: 4 },
  { name: 'Economy', count: 4 },
  { name: 'Weather', count: 2 },
  { name: 'Pandemic', count: 6 },
  { name: 'Political', count: 4 },
  { name: 'Social', count: 10 }
];

const AlertSummary = ({ classes, selectedToDate, selectedFromDate, search }) => {
  const [, dispatch] = useStateValue();
  const [filterCategory, setFilterCategory] = useState([]);
  const [mainCategory, setMainCategory] = useState([]);
  const [isRoomOpen, setIsRoomOpen] = useState(0);
  const [selectedMainCategory, setSelectedMainCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedFilterCategory, setSelectedFilterCategory] = useState('');

  const handleRoomOpen = (index) => {
    setIsRoomOpen(index);
  };

  const handleAllOnclose = (closeIndex) => {
    setIsRoomOpen(closeIndex);
  };

  const getFilterCategory = () => {
    API_GET(
      `alert/getSeveritySummary?fromDate=${selectedFromDate}&toDate=${selectedToDate}&tab=alert`
    )
      .then((res) => {
        let count = 0;
        res.NewsSeveritySummary.forEach((element) => {
          count += element.alertCount;
        });
        setFilterCategory([
          { alertCount: count, alertCategory: 'All', color: 'black', backgroundColor: '#FFFFFF' },
          ...res?.NewsSeveritySummary
        ]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const getMainCategory = () => {
    API_GET(`alert/getNewsSummary?fromDate=${selectedFromDate}&toDate=${selectedToDate}&tab=alert`)
      .then((res) => {
        setMainCategory(res?.corporateNewsSummary);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getFilterCategory();
    getMainCategory();
  }, [dispatch, selectedToDate, selectedFromDate]);

  const handleCategory = (category, subCategory) => {
    setSelectedSubCategory(subCategory);
    setSelectedMainCategory(category);
  };

  const handleFilterCategory = (value) => {
    setSelectedFilterCategory(value);
  };

  const handleSelectedMainCategory = (category) => {
    setSelectedMainCategory(category);
  };

  return (
    <Container direction={'row'}>
      <Item
        md={12}
        xs={12}
        className={classes.topContainer}
        sx={{ ...theme.card, borderRadius: '8px' }}>
        <Item className={classes.filterCategoryContainer}>
          {filterCategory?.map((item, index) => {
            return (
              <Button
                key={index}
                onClick={() => handleFilterCategory(item?.alertCategory)}
                style={{
                  color: item?.color,
                  backgroundColor: item?.backgroundColor,
                  width: '150px',
                  margin: '4px 0px',
                  fontSize: '14px',
                  fontWeight: '600',
                  borderRadius: '15px',
                  boxShadow: item?.alertCategory === 'All' && '0px 0px 2px grey'
                }}>
                {`${item?.alertCategory} (${item?.alertCount}) `}
              </Button>
            );
          })}
        </Item>
      </Item>
      <Container spacing={2}>
        <Item md={2.2} xs={12} mt={2}>
          <Paper className={classes.tableContainer} sx={{ ...theme.card, borderRadius: '8px' }}>
            <Item>
              {mainCategory.map((item, index) => {
                return (
                  <AlertMenuList
                    item={item}
                    key={index}
                    roomIndex={index}
                    isRoomOpen={isRoomOpen}
                    handleRoomOpen={handleRoomOpen}
                    mainCategory={mainCategory}
                    handleCategory={handleCategory}
                    handleSelectedMainCategory={handleSelectedMainCategory}
                  />
                );
              })}
            </Item>
          </Paper>
        </Item>
        <Item md={9.8} xs={12} mt={2} className={classes.middleContainer} wrap>
          <Item md={12} xs={12}>
            <Paper sx={{ ...theme.card, borderRadius: '8px' }} className={classes.middleContainer2}>
              <ScatterPlot selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} />
            </Paper>
          </Item>
          <Item xs={12} style={{ display: 'flex', gap: '10px' }}>
            <Item md={12} xs={12} mt={2}>
              <Paper
                sx={{ ...theme.card, borderRadius: '8px' }}
                className={classes.middleContainer1}>
                <BarChart1 selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} />
              </Paper>
            </Item>
          </Item>

          <Item md={12} mt={2}>
            <Card sx={{ ...theme.card, borderRadius: '8px' }} className={classes.middleContainer2}>
              <AlertSummaryTable
                selectedFilterCategory={selectedFilterCategory}
                selectedMainCategory={selectedMainCategory}
                selectedSubCategory={selectedSubCategory}
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                getFilterCategory={getFilterCategory}
                getMainCategory={getMainCategory}
                search={search}
                tab="alert"
              />
            </Card>
          </Item>
        </Item>
      </Container>
    </Container>
  );
};

export default withStyles(styles)(AlertSummary);
