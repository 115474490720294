const head = [
  {
    id: 'date',
    label: 'Pub. Date&Time',
    minWidth: 60,
    width: 60,
    maxWidth: 60,
    align: 'left'
  },
  {
    id: 'safetyCategory',
    label: 'Category',
    minWidth: 60,
    width: 60,
    maxWidth: 60,
    align: 'left'
  },
  {
    id: 'ticketNumber',
    label: 'Ticket No',
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'employeeName',
    label: 'Employee',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'safetyConcern',
    label: 'Incident',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'left'
  },
  {
    id: 'emergencySeverityLevel',
    label: 'Severity',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'officeAddress',
    label: 'Location',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
