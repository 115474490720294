import React, { useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';
// import { useStateValue } from '../../utils/store';

const { withStyles, Grid, Button } = Components;

const Item = (props) => <Grid item {...props} />;

const DependentDocs = ({ classes, indiviualUserData }) => {
  const [largetImage, setLargeImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  // const [store] = useStateValue();

  const sampledata = [
    {
      name: 'Passport',
      links: indiviualUserData?.passportDocs?.[0]?.links
    },
    {
      name: 'Aadhaar',
      links: indiviualUserData?.aadhaarCardDocs?.[0]?.links
    },
    // {
    //   name: 'Visa',
    //   links: indiviualUserData?.visaDocs?.[0]?.links
    // },

    {
      name: 'Pan Card',
      links: indiviualUserData?.panCardDocs?.[0]?.links
    }
    // {
    //   name: 'Vaccination Status',
    //   links: [
    //     indiviualUserData?.vaccinationDocs?.[0]?.links?.[0],
    //     indiviualUserData && indiviualUserData?.vaccinationDocs?.[1]?.links?.[0],
    //     indiviualUserData && indiviualUserData?.vaccinationDocs?.[2]?.links?.[0]
    //   ]
    // },
    // {
    //   name: 'Addititonal Doc 1',
    //   links: [indiviualUserData?.additionalDoc1]
    // },
    // {
    //   name: 'Photo',
    //   links: [indiviualUserData?.additionalDoc2]
    // },
    // {
    //   name: 'Traval Insurance',
    //   links: indiviualUserData?.travelInsuranceDocs?.[0]?.links
    // },
    // {
    //   name: 'Health Insurance',
    //   links: indiviualUserData?.healthInsuranceDocs?.[0]?.links
    // }
  ];

  const resultDocs = sampledata;

  const handleLargeDoc = (url) => {
    setLargeImage(true);
    setImageUrl(url);
  };
  const handleBack = () => {
    setLargeImage(false);
  };

  return (
    <Item
      sx={{
        mt: 2,
        maxHeight: '65vh',
        overflow: 'auto',
        minWidth: '60vw',
        display: 'flex',
        flexWrap: 'wrap'
      }}>
      {largetImage === true ? (
        <Item sx={{ width: '100%', height: '60vw' }}>
          <Button
            onClick={handleBack}
            className={classes.docsButton}
            style={{
              ...theme.button.submit,
              backgroundColor: theme.palette.primary.main
            }}>
            Back
          </Button>
          <img src={imageUrl} alt="docs" height="80%" width="100%" />
        </Item>
      ) : (
        <table style={{ width: '100%' }}>
          <tr>
            <th>
              <Item md={12} xs={12} align="center">
                <Item className={classes.documentView}>Document</Item>
              </Item>
            </th>
            <th>
              <Item md={12} xs={12} align="center">
                <Item className={classes.documentView}>Images</Item>
              </Item>
            </th>
          </tr>
          <tr>
            <td>
              <Item>
                {resultDocs?.map((item) => {
                  return (
                    <Item
                      className={classes.documentView}
                      sx={{
                        backgroundColor: '#FFFFFF',
                        height: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      {item?.name}
                    </Item>
                  );
                })}
              </Item>
            </td>
            <td>
              <Item>
                {resultDocs?.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      className={classes.documentView}
                      sx={{
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        height: '100px'
                      }}>
                      {item?.links?.length > 0 &&
                        item?.links
                          ?.filter((x) => x !== null && x !== '')
                          ?.map((img, index) => {
                            return (
                              img?.uri && (
                                <Item
                                  onClick={() => handleLargeDoc(img?.uri)}
                                  sx={{ cursor: 'pointer' }}>
                                  <img
                                    key={index}
                                    src={img?.uri}
                                    alt="docs"
                                    width="50px"
                                    height="50px"
                                  />
                                </Item>
                              )
                            );
                          })}
                    </Item>
                  );
                })}
              </Item>
            </td>
          </tr>
        </table>
      )}
    </Item>
  );
};

export default withStyles(styles)(DependentDocs);
