import { Components, Icons } from '../../../utils/material-ui';
import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import styles from '../styles';
import theme from '../../../utils/theme';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import ExportToExcel from '../../../utils/downloadMergeColumnData';

import { useStateValue } from '../../../utils/store';
import { API_HANDLE_ERROR, API_GET } from '../../../utils/api';

const { Grid, Typography, withStyles } = Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
// const Container = (props) => <Grid container {...props} />;

const data = [
  {
    room: 'Emergency',
    'Rev Earned': 0,
    'Rev Loss': 0
  },
  {
    room: 'Critical',
    'Rev Earned': 0,
    'Rev Loss': 0
  },
  {
    room: 'Warning',
    'Rev Earned': 0,
    'Rev Loss': 0
  }
];

const axisBottom = {
  tickSize: 10,
  tickPadding: 6,
  tickRotation: -25,
  legendPosition: 'middle',
  legendOffset: 45
};

const axisLeft = {
  tickSize: 13,
  tickPadding: 0,
  tickRotation: 0,
  legendPosition: 'middle',
  legendOffset: -45,
  format: (v) => `${v}K`
};

const themeGraph = {
  background: '#FFFFFF',
  axis: {
    fontSize: '18px',
    tickColor: 'black'
  },
  grid: {
    line: {
      stroke: '#555555'
    }
  }
};

const legends = [
  {
    dataFrom: 'keys',
    anchor: 'top-center',
    direction: 'row',
    justify: false,
    translateX: 150,
    translateY: -40,
    itemsSpacing: 10,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: 'left-to-right',
    itemOpacity: 1,
    itemTextColor: 'black',
    symbolSize: 20,
    // symbolShape: '',
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1
        }
      }
    ]
  }
];

const Barcharts = ({ selectedFromDate, selectedToDate, classes }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [exportResultData, setExportResultData] = useState([]);

  useEffect(() => {
    API_GET(
      `reports/getLocationWiseRevenueData?fromDate=${selectedFromDate}&toDate=${selectedToDate}&queryType=alertCategory`
    )
      .then((res) => {
        const result = res?.queryWiseRevenueData.map((item) => {
          return {
            room: ConvertToUpperCase(item?.room),
            'Rev Earned': item['Rev Earned'] / 1000,
            'Rev Loss': item['Rev Loss'] / 1000
          };
        });

        setRows(result);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate]);

  const handleExport = () => {
    API_GET(
      `reports/downloadLoationWiseRevenueGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}&queryType=alertcategory`
    )
      .then((res) => {
        const resultData = res?.queryWiseRevenueData?.map((item) => {
          return {
            SrNo: item.SrNo,
            Date: item.Date,
            ...item
          };
        });
        setExportResultData(resultData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    setTimeout(() => {
      ExportToExcel(
        'category_wise_revenue',
        'xlsx',
        `Cat. wise Rev. Earn_loss (${selectedFromDate} To ${selectedToDate}).xlsx`
      );
    }, 500);
  };

  const TableData = () => {
    return (
      <div id="category_wise_revenue" style={{ display: 'none' }}>
        <table border="1">
          <thead>
            <tr>
              {exportResultData.length > 0 &&
                Object.keys(exportResultData[0]).map((item) => {
                  return (
                    <th colSpan={item === 'SrNo' || item === 'Date' ? '1' : '2'}>
                      {ConvertToUpperCase(item)}
                    </th>
                  );
                })}
            </tr>
            <tr>
              {exportResultData.length > 0 &&
                Object.keys(exportResultData[0]).map((item) => {
                  return (
                    <React.Fragment>
                      {item === 'SrNo' || item === 'Date' ? (
                        <th></th>
                      ) : (
                        <React.Fragment>
                          <th>Rev Earned</th>
                          <th>Rev Loss</th>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {exportResultData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.SrNo}</td>
                  <td>{item.Date}</td>
                  {Object.keys(item).map((subData) => {
                    return (
                      <React.Fragment>
                        {subData !== 'SrNo' && subData !== 'Date' ? (
                          <React.Fragment>
                            <td>{item[subData].revGain}</td>
                            <td>{item[subData].revLoss}</td>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Category wise Revenue Earned & Loss
          </Typography>
        </Item>
        <Item>
          {TableData()}
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '240px', width: '100%', display: 'flex' }}>
        <ResponsiveBar
          margin={{ top: 40, right: 10, bottom: 50, left: 50 }}
          data={rows || data}
          keys={['Rev Loss', 'Rev Earned']}
          colors={['#A17FDC', '#F5C400']}
          indexBy="room"
          labelSkipWidth={5}
          labelSkipHeight={10}
          labelTextColor="#FFFFFF"
          animate={true}
          motionStiffness={90}
          motionDamping={20}
          enableGridX={false}
          // enableGridY={true}
          // gridYValues={3}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          // colors={({ id }) => (id === 'Booked' ? '#ED3E3C' : '#0BAFA0')}
          theme={themeGraph}
          legends={legends}
          padding={0.6}
          borderRadius="2px"
          enableLabel={false}
        />
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(Barcharts);
