import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { Components, Icons } from '../../../../utils/material-ui';
// import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { Typography } from '@mui/material';
import theme from '../../../../utils/theme';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import { useStateValue } from '../../../../utils/store';
import { getDateTime } from '../../../../utils/moment';
import downloadDataToExcelFile from '../../../../utils/downloadDataToExcelFile';

const {
  withStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Button,
  Card
} = Components;

const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

// const rows = [
//   {
//     srNo: '1',
//     dateTime: '22-10-2022,5PM',
//     header: 'Safety',
//     cta1: 'Yes',
//     cta1Value: 100,
//     cta2: 'No',
//     cta2Value: 50,
//     cta3: 'Manali',
//     cta3Value: 100,
//     noResponse: 50
//   }
// ];
const sortBy = [
  { name: 'Ascending', value: '1' },
  { name: 'Descending', value: '-1' }
];

const BroadCastDetailTable = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sort, setsort] = React.useState('-1');
  const [totalBroadcast, setTotalBroadcast] = useState(0);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selectedToDate1, setSelectedToDate1] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedFromDate1, setSelectedFromDate1] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );

  const handleDate1 = (date) => {
    if (date) {
      setSelectedFromDate1(moment(date[0]).format('YYYY-MM-DD'));
      setSelectedToDate1(moment(date[1]).format('YYYY-MM-DD'));
    }
  };

  const getAllBraodCastDetails = () => {
    API_GET(
      `reports/getBoradcastReport?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&fromDate=${selectedFromDate1}&toDate=${selectedToDate1}&sortOrder=${sort}`
    )
      .then((res) => {
        setRows(res?.data);
        setTotalBroadcast(res?.count);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllBraodCastDetails();
  }, [selectedFromDate1, selectedToDate1, dispatch, page, rowsPerPage, sort]);

  const handleExport = () => {
    API_GET(
      `reports/getBoradcastReport?fromDate=${selectedFromDate1}&toDate=${selectedToDate1}&sortOrder=${sort}`
    )
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          const cta1 =
            item?.ctaInfo && Object.keys(item?.ctaInfo)[0] !== undefined
              ? Object.keys(item?.ctaInfo)[0]
              : '-';
          const cta2 =
            item?.ctaInfo && Object.keys(item?.ctaInfo)[1] !== undefined
              ? Object.keys(item?.ctaInfo)[1]
              : '-';
          const cta3 =
            item?.ctaInfo && Object.keys(item?.ctaInfo)[2] !== undefined
              ? Object.keys(item?.ctaInfo)[2]
              : '-';
          const cta1value =
            item?.ctaInfo && Object.values(item?.ctaInfo)[0] !== undefined
              ? Object.values(item?.ctaInfo)[0]
              : '-';
          const cta2value =
            item?.ctaInfo && Object.values(item?.ctaInfo)[1] !== undefined
              ? Object.values(item?.ctaInfo)[1]
              : '-';
          const cta3value =
            item?.ctaInfo && Object.values(item?.ctaInfo)[2] !== undefined
              ? Object.values(item?.ctaInfo)[2]
              : '-';

          return {
            'Date and Time': getDateTime(item?.timeOfBroadcast),
            Header: item?.header,
            CTA1: cta1,
            'CTA1 Value': cta1value,
            CTA2: cta2,
            'CTA2 Value': cta2value,
            CTA3: cta3,
            'CTA3 Value': cta3value,
            'No Response': item?.noResCount
          };
        });
        downloadDataToExcelFile(
          resultData,
          `Broadcast details (${selectedFromDate1} To ${selectedToDate1}) `
        );
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const handleIndiviualDownload = (broadcastData) => {
    broadcastData?._id &&
      API_GET(`reports/getHeaderWiseReport?notificationId=${broadcastData?._id}`)
        .then((res) => {
          const resulData = res?.data?.map((item, index) => {
            return {
              'Sr no': index + 1,
              'Emp ID': item?.empId,
              Name: item?.name,
              Location: item?.officeLocation,
              Email: item?.email,
              Phone: item?.phone,
              'CTA Response': item?.ctaResponse
            };
          });
          downloadDataToExcelFile(resulData, `Broadcast ${broadcastData?.header} Details`);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Item xs={12} md={12} sx={{ display: 'flex', gap: '10px', p: 1 }}>
        <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '10px', flexWrap: 'wrap' }}>
          <DateRangePicker
            onChange={(date) => handleDate1(date)}
            value={[new Date(selectedFromDate1), new Date(selectedToDate1)]}
          />
        </Item>
        <Item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> Sort By </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={sort}
              sx={{ fontSize: '12px', width: '200px' }}
              label=" Sort By "
              onChange={(e) => setsort(e.target.value)}>
              {sortBy?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.value}>
                    {ConvertToUpperCase(item?.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer' }} onClick={handleExport} />
        </Item>
      </Item>
      <Typography
        sx={{
          textAlign: 'center',
          backgroundColor: '#5768BC',
          p: 1,
          color: 'white',
          fontWeight: 'bold',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          fontSize: '19px'
        }}>
        Broadcast
      </Typography>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column, index) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {rows &&
              rows?.map((row, rowIndex) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    {columns &&
                      columns.map((column, index) => {
                        const value = row[column.id] ?? '--';
                        if (column.id === 'srNo') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              {rowIndex + 1}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'dateTime') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {getDateTime(row?.timeOfBroadcast) || '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'noResponse') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.noResCount || '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'cta1') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.ctaInfo && Object.keys(row?.ctaInfo)[0] !== undefined
                                ? ConvertToUpperCase(Object.keys(row?.ctaInfo)[0])
                                : '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'cta1Value') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.ctaInfo && Object.values(row?.ctaInfo)[0] !== undefined
                                ? Object.values(row?.ctaInfo)[0]
                                : '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'cta2') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.ctaInfo && Object.keys(row?.ctaInfo)[1] !== undefined
                                ? ConvertToUpperCase(Object.keys(row?.ctaInfo)[1])
                                : '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'cta2Value') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.ctaInfo && Object.values(row?.ctaInfo)[1] !== undefined
                                ? Object.values(row?.ctaInfo)[1]
                                : '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'cta3') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.ctaInfo && Object.keys(row?.ctaInfo)[2] !== undefined
                                ? ConvertToUpperCase(Object.keys(row?.ctaInfo)[2])
                                : '-'}
                            </StyledTableCell>
                          );
                        }
                        if (column.id === 'cta3Value') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              {row?.ctaInfo && Object.values(row?.ctaInfo)[2] !== undefined
                                ? Object.values(row?.ctaInfo)[2]
                                : '-'}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'action') {
                          return (
                            <StyledTableCell align="center" key={column.id}>
                              <Item>
                                {row?.isAnonymous && row?.isAnonymous === true ? (
                                  '--'
                                ) : (
                                  <Button
                                    style={theme.button.noAction}
                                    onClick={() => handleIndiviualDownload(row)}>
                                    Download
                                  </Button>
                                )}
                              </Item>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalBroadcast && totalBroadcast ? totalBroadcast : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};
export default withStyles(styles)(BroadCastDetailTable);
