const styles = (theme) => {
  return {
    popupContainer: {
      padding: theme.spacing(2),
      minWidth: '40vw',
      color: '#868686',
      maxWidth: '70vw'
    },
    contain: {
      height: 'auto',
      minHeight: '100px',
      maxheight: '150px',
      overflow: 'auto',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      marginTop: theme.spacing(1)
    },
    title: {
      color: '#868686',
      fontWeight: '500',
      fontSize: '1.4rem'
    },
    indiviualSelectContainer: {
      height: 'auto',
      maxHeight: '250px',
      minHeight: '250px',
      overflow: 'auto',
      padding: theme.spacing(0.5),
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      border: '0.2px solid lightGrey'
    },
    groupSelectContainer: {
      height: 'auto',
      maxHeight: '100px',
      minHeight: '100px',
      overflow: 'auto',
      padding: theme.spacing(0.5),
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      border: '0.2px solid lightGrey'
    },
    smallContainer: {
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '8px',
      alignItems: 'center',
      color: theme.palette.primary.main,
      minWidth: '100px',
      padding: theme.spacing(0.5),
      borderRadius: '10px'
    },
    previewData: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap'
    }
  };
};

export default styles;
