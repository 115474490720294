const head = [
  {
    id: 'select',
    label: 'Select',
    minWidth: 70,
    width: 70,
    maxWidth: 70,
    align: 'center'
  },
  {
    id: 'packageId',
    label: 'Group Id',
    minWidth: 120,
    width: 120,
    maxWidth: 120
  },
  {
    id: 'packageName',
    label: 'Group Name',
    minWidth: 100,
    width: 100,
    maxWidth: 100
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'destination',
    label: 'Destination',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'totalNominated',
    label: 'Number Of Nominated Agent/Employee',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  // {
  //   id: 'registeredUsersCount',
  //   label: 'No. Of Registered',
  //   minWidth: 120,
  //   width: 120,
  //   maxWidth: 120,
  //   align: 'center'
  // },
  // {
  //   id: 'totalTravellingCount',
  //   label: 'No. Of Travelling',
  //   minWidth: 80,
  //   width: 80,
  //   maxWidth: 80,
  //   align: 'center'
  // },
  {
    id: 'paxNum',
    label: 'Pax',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },

  {
    id: 'packageType',
    label: 'Group Type',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  // {
  //   id: 'mealPlan',
  //   label: 'Meal Plan',
  //   minWidth: 90,
  //   width: 90,
  //   maxWidth: 90,
  //   align: 'center'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },

  {
    id: 'action',
    label: 'Action',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'center'
  }
];

export default head;
