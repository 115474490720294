const head = [
  // {
  //   id: 'select',
  //   label: 'Select',
  //   minWidth: 50,
  //   width: 50,
  //   maxWidth: 50,
  //   align: 'left'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: 'employeeId',
    label: 'Emp Id',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'fullName',
    label: 'Name',
    minWidth: 130,
    width: 130,
    maxWidth: 130,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'gender',
    label: 'Gender',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'mobileNumber',
    label: 'Phone No',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },

  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'role',
    label: 'Role',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'deactivatedReason',
    label: 'Reason',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'deactivatedBy',
    label: 'Deactivated By',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'action',
    label: 'Activate',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  }
];

export default head;
