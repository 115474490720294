const head = [
  {
    id: 'select',
    label: 'Select',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'employeeId',
    label: 'Emp ID',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'left'
  },
  {
    id: 'fullName',
    label: 'Name',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'mobileNumber',
    label: 'Phone Number',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'center'
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'left'
  },
  {
    id: 'officeCity',
    label: 'Office',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'deptName',
    label: 'Department',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'role',
    label: 'Role',
    minWidth: 60,
    width: 60,
    maxWidth: 60,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'vehicle',
    label: 'Vehicle',
    minWidth: 70,
    width: 70,
    maxWidth: 70,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'details',
    label: 'Details',
    minWidth: 70,
    width: 70,
    maxWidth: 70,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'action',
    label: 'Action',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    align: 'center'
  }
  // {
  //   id: 'gender',
  //   label: 'Gender',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 100,
  //   align: 'left'
  // },
  // {
  //   id: 'dob',
  //   label: 'DOB',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 100,
  //   align: 'center'
  // },

  // {
  //   id: 'designation',
  //   label: 'Designation',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 100,
  //   align: 'left'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },

  // {
  //   id: 'address',
  //   label: 'Address',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 100,
  //   align: 'left'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  // {
  //   id: 'repName',
  //   label: 'Reporting To',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 100,
  //   align: 'left'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },

  // {
  //   id: 'sos',
  //   label: 'SOS',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 100,
  //   align: 'left'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  // {
  //   id: 'familyMemberDetails',
  //   label: 'Family Members',
  //   minWidth: 100,
  //   width: 100,
  //   maxWidth: 100,
  //   align: 'center'
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
];

export default head;
