const styles = (theme) => {
  return {
    newUser: {
      width: 'auto',
      height: 'auto',
      padding: theme.spacing(1, 2)
    },
    cardHeading: {
      padding: '7px 20px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px 5px 0 0',
      color: theme.palette.common.white
    }
  };
};

export default styles;
