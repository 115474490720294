import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from './styles';
import EmployeeDetailTable from '../../../components/organism/Table/EmployeeDetailTable';
import theme from '../../../utils/theme';
import moment from 'moment';
import SortButtonModal from '../../../utils/sortButton';
import { useStateValue } from '../../../utils/store';
import { SortButton, FilterButton } from '../../../utils/ButtonDiff';
import Filter from '../../../utils/filters';
import EmployeeFilter from './EmployeeFilter';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';

const { withStyles, Grid, TextField, InputAdornment } = Components;
const { SearchIcon } = Icons;

const sortArray = [
  { name: 'Asc - Des', value: 1 },
  { name: 'Des- Asc', value: -1 }
];

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const EmployeeInfo = ({ classes }) => {
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [, dispatch] = useStateValue();
  const [sort, setSort] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isOpenSortModal, setIsOpenSortModal] = useState(false);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [filtersCategory, setFilterCategory] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const getAllRoles = () => {
    API_GET(`user/getEmployees?limit=999&skip=0`)
      .then((res) => {
        const result =
          res?.data?.userRoles?.map((item) => {
            return { label: ConvertToUpperCase(item), name: item, value: false, type: 'Role' };
          }) || [];

        result && setFilterData((prevData) => [...prevData, ...result]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const getAllOffices = () => {
    API_GET('office/getAllOffices')
      .then((res) => {
        const resultOffice = res?.allOffices?.map((item) => {
          return {
            label: `${ConvertToUpperCase(item?.name)}, ${item?.city}`,
            name: item?._id,
            value: false,
            type: 'Office'
          };
        });

        setFilterData((prevData) => [...prevData, ...resultOffice]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllRoles();
    getAllOffices();
  }, []);

  const handleOpenSortModal = () => {
    setIsOpenSortModal(!isOpenSortModal);
    setIsOpenFilterModal(false);
  };

  const handleSort = (value) => {
    setSort(value);
    setIsOpenSortModal(false);
  };

  const handleClose = () => {
    setIsOpenFilterModal(false);
  };

  const handleOpenFilterModal = () => {
    setIsOpenFilterModal(!isOpenFilterModal);
    setIsOpenSortModal(false);
  };

  const handleClearAll = () => {
    setSort('');
    const result = filterData?.map((data) => {
      return { ...data, value: false };
    });
    setFilterData(result);
    setFilterCategory([]);
  };

  const handleRemove = (item, status) => {
    if (status === 'sort') {
      setSort('');
    }
    if (status === 'filter') {
      const result = filtersCategory?.filter((data) => {
        return data !== item;
      });
      setFilterCategory(result);
    }
  };

  const handleFilterCategory = (value) => {
    setFilterCategory(value);
  };

  const handleChange = (item) => {
    const result = filterData?.map((subItem) => {
      return subItem.name === item.name ? { ...subItem, value: !subItem?.value } : { ...subItem };
    });
    setFilterData(result);
  };

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  return (
    <Container>
      <Item
        md={12}
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          m: theme.spacing(1, 0)
        }}>
        <Item md={4} xs={12} mt={1}>
          <TextField
            placeholder="Search by Name, Email and Phone Number"
            size="small"
            variant="outlined"
            fullWidth
            sx={theme.customTextFiled}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Item>
        <Item
          sx={{
            display: 'inline-flex',
            ml: 'auto',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
          mt={1}>
          <Item sx={{ position: 'relative' }}>
            <SortButton handleOpenSortModal={handleOpenSortModal} />

            {isOpenSortModal && (
              <SortButtonModal handleSort={handleSort} sortArray={sortArray} sort={sort} />
            )}
          </Item>
          <Item sx={{ position: 'relative' }}>
            <FilterButton handleOpenFilterModal={handleOpenFilterModal} />

            {isOpenFilterModal && (
              <EmployeeFilter
                filterData={filterData}
                handleClose={handleClose}
                handleChange={handleChange}
                handleFilterCategory={handleFilterCategory}
              />
            )}
          </Item>

          <Item>
            <form noValidate>
              <TextField
                id="datetime-local"
                label="From"
                type="date"
                size="small"
                value={selectedFromDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onFromDateChange(e);
                }}
              />
            </form>
          </Item>
          <Item>
            <form noValidate>
              <TextField
                id="date"
                label="To"
                type="date"
                size="small"
                value={selectedToDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onToDateChange(e);
                }}
              />
            </form>
          </Item>
        </Item>
      </Item>
      {(filtersCategory?.length > 0 || sort !== '') && (
        <Filter
          filtersCategory={filtersCategory}
          sort={sort}
          handleClearAll={handleClearAll}
          handleRemove={handleRemove}
        />
      )}
      <Item md={12} style={{ width: '500px' }} mt={1}>
        <EmployeeDetailTable
          searchText={searchText}
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
          sort={sort}
          filtersCategory={filtersCategory}
        />
      </Item>
    </Container>
  );
};

export default withStyles(styles)(EmployeeInfo);
