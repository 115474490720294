const head = [
  {
    id: 'srNo',
    label: 'S.No',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },
  {
    id: 'fullName',
    label: 'Name',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  },
  {
    id: 'mobileNumber',
    label: 'Phone Number',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  }
];

export default head;
