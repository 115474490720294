import React from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import Timeconversion from '../../../../utils/TimeConversion';

const { withStyles, Grid, Typography } = Components;
const { CancelRoundedIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function SlotModal({ classes, handleClose, slotData }) {
  console.log('data', slotData);
  return (
    <Container direction="column" className={classes.newUser}>
      <Item sx={{ display: 'flex' }} md={12} xs={12}>
        <Typography sx={{ color: theme.typography.tableHeading, p: 0 }}>Timings</Typography>
        <Item sx={{ float: 'inline-flex', ml: 'auto' }}>
          <CancelRoundedIcon onClick={handleClose} className={classes.icon} />
        </Item>
      </Item>
      <Item md={12} xs={12}>
        {slotData?.map((item) => {
          return (
            <Item md={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }} mt={1}>
              <Item md={3.5} xs={3.5}>
                <Typography sx={{ color: theme.typography.tableHeading, p: 0, fontWeight: '500' }}>
                  {item?.day}
                </Typography>
              </Item>
              <Item md={1} xs={1}>{` : `}</Item>
              <Item md={7.5} xs={7.5}>
                {item?.intervals?.map((slot) => {
                  return (
                    <Item md={12} xs={12} mt={0.5}>
                      <Typography
                        sx={{ color: theme.typography.tableHeading, p: 0, fontWeight: '500' }}>
                        {Timeconversion(slot?.startTime) + ' - ' + Timeconversion(slot?.endTime)}
                      </Typography>
                    </Item>
                  );
                })}
              </Item>
            </Item>
          );
        })}
      </Item>
    </Container>
  );
}

export default withStyles(styles)(SlotModal);
