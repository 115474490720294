import React, { useState } from 'react';
import styles from './styles';
import { Components, Icons } from '../../utils/material-ui';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import theme from '../../utils/theme';

const { withStyles, Grid } = Components;
const { KeyboardArrowDownIcon, KeyboardArrowUpIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

const AlertMenuList = ({
  classes,
  item,
  roomIndex,
  isRoomOpen,
  handleRoomOpen,
  handleCategory,
  handleSelectedMainCategory
}) => {
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const handleClick = (mainCategory) => {
    handleRoomOpen(roomIndex);
    handleSelectedMainCategory(mainCategory);
    handleSubcategory(mainCategory, '');
  };

  const handleSubcategory = (category, subCategory) => {
    handleCategory(category, subCategory);
    setSelectedSubCategory(subCategory);
  };

  return (
    <React.Fragment>
      <Item
        className={classes.dropDownMenu}
        onClick={() => handleClick(item?._id)}
        sx={{ ...theme.card, cursor: 'pointer', mt: 2, p: 1, borderRadius: '10px' }}>
        <div className={classes.menuName}>{ConvertToUpperCase(item?._id)}</div>
        <div className={classes.menuNumber}>{item?.count}</div>
        <div className={classes.menuIcon}>
          {roomIndex === isRoomOpen ? (
            <KeyboardArrowUpIcon style={{ cursor: 'auto' }} />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
      </Item>
      {roomIndex === isRoomOpen && (
        <Item className={classes.subMenuContainer} sx={{ ...theme.card, margin: '0px 5%' }}>
          {item?.subCategory?.map((subCate) => {
            return (
              <React.Fragment>
                <Item
                  className={classes.sumMenu}
                  sx={theme.Card}
                  style={{
                    border: `${
                      subCate === selectedSubCategory
                        ? `2px solid ${theme.palette.primary.main}`
                        : '0.2px solid grey'
                    }`,
                    backgroundColor: `${subCate === selectedSubCategory ? `#ffe1e6` : '#FFFFFF'}`,
                    borderRadius: '10px',
                    cursor: 'pointer',
                    padding: '4px 10px'
                  }}
                  onClick={() => handleSubcategory(item?._id, subCate)}>
                  {ConvertToUpperCase(subCate)}
                </Item>
              </React.Fragment>
            );
          })}
        </Item>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(AlertMenuList);
