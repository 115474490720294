import React, { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Components, Icons } from '../../../utils/material-ui';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import styles from '../styles';
import ExportToExcel from '../../../utils/downloadMergeColumnData';
import theme from '../../../utils/theme';

const { withStyles, Grid, Typography } = Components;
const { FileDownloadIcon, ArrowBackIosIcon, ArrowForwardIosIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
// const Container = (props) => <Grid container {...props} />;

// const data = [
//   {
//     room: 'Marketing',
//     Psychiatric: 20,
//     Physician: 40
//   },
//   {
//     room: 'Finance',
//     Psychiatric: 10,

//     Physician: 15
//   },
//   {
//     room: 'Sales',
//     Psychiatric: 40,
//     Physician: 40
//   },
//   {
//     room: 'Operation',
//     Psychiatric: 30,

//     Physician: 40
//   },
//   {
//     room: 'Product',
//     Psychiatric: 25,

//     Physician: 55
//   }
// ];

const axisBottom = {
  tickSize: 10,
  tickPadding: 6,
  tickRotation: -25,
  legendPosition: 'middle',
  legendOffset: 45
};

const axisLeft = {
  tickSize: 10,
  tickPadding: 0,
  tickRotation: 0,
  legend: 'No. of Hours',
  legendPosition: 'middle',
  legendOffset: -45
};

const themer = {
  background: '#FFFFFF',
  axis: {
    fontSize: '18px',
    tickColor: 'black'
  },
  grid: {
    line: {
      stroke: '#555555'
    }
  }
};

const legends = [
  {
    dataFrom: 'keys',
    anchor: 'top-center',
    direction: 'row',
    justify: false,
    translateX: 150,
    translateY: -40,
    itemsSpacing: 10,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: 'left-to-right',
    itemOpacity: 1,
    itemTextColor: 'black',
    symbolSize: 20,
    // symbolShape: '',
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1
        }
      }
    ]
  }
];

const Barcharts = ({ selectedFromDate, selectedToDate, classes, location }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  const [page, setPage] = React.useState(0);
  const [count] = React.useState(4);
  const [totalCount, setTotalCount] = useState(0);
  const [exportResultData, setExportResultData] = useState([]);
  const [reportLocation, setReportsLocation] = useState([]);

  const handleForward = () => {
    setPage(page + 1);
  };

  const handleBackward = () => {
    if (page === 0) {
      setPage(0);
    } else {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    const skip = count * page > totalCount ? (count * page) % count : count * page;

    API_GET(
      `reports/getDepartmentWiseAppointment?fromDate=${selectedFromDate}&toDate=${selectedToDate}&limit=${count}&skip=${skip}&city=${location}`
    )
      .then((res) => {
        setRows(res?.appointments);
        setTotalCount(2);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, count, page, location]);

  const handleExport = () => {
    API_GET(
      `reports/downloadDepartmentWiseAppointment?fromDate=${selectedFromDate}&toDate=${selectedToDate}&city=${location}`
    )
      .then((res) => {
        setExportResultData(res?.data);
        setReportsLocation(res?.departments);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    setTimeout(() => {
      ExportToExcel(
        'Dept. wise appointment_location',
        'xlsx',
        `Dept. wise appointment_${location}_(${selectedFromDate} To ${selectedToDate}).xlsx`
      );
    }, 450);
  };

  const TableData = () => {
    return (
      <div id="Dept. wise appointment_location" style={{ display: 'none' }}>
        <table border="1">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Date</th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return <th colSpan={2}>{item}</th>;
                })}
            </tr>
            <tr>
              <th></th>
              <th></th>
              {reportLocation &&
                reportLocation?.map((item) => {
                  return (
                    <React.Fragment>
                      <th>Physician</th>
                      <th>Psychiatric</th>
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {exportResultData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.SrNo}</td>
                  <td>{item?.date}</td>
                  {Object.keys(item).map((subData) => {
                    return (
                      <React.Fragment>
                        {subData !== 'SrNo' && subData !== 'date' ? (
                          <React.Fragment>
                            <td>{item[subData]?.physician}</td>
                            <td>{item[subData]?.psychiatric}</td>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Department Wise Appointment
          </Typography>
        </Item>
        <Item>
          {TableData()}
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>
      <Item sx={{ height: '210px', width: '100%', display: 'flex' }}>
        <ResponsiveBar
          margin={{ top: 40, right: 10, bottom: 40, left: 40 }}
          data={rows}
          keys={['Physician', 'Psychiatric']}
          colors={['#2C9AFF', '#FCB94D']}
          indexBy="room"
          labelTextColor="inherit:darker(2.1)"
          labelSkipWidth={5}
          labelSkipHeight={10}
          enableGridX={false}
          // enableGridY={true}
          // gridYValues={3}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          // colors={({ id }) => (id === 'Booked' ? '#ED3E3C' : '#0BAFA0')}
          theme={themer}
          legends={legends}
          padding={0.6}
          borderRadius="2px"
          enableLabel={false}
        />
      </Item>
      <Item md={12} sx={{ display: 'flex' }}>
        {rows?.length !== 0 ? (
          <Item sx={{ display: 'inline-flex', ml: 'auto', mr: 1 }}>
            {page === 0 ? (
              <ArrowBackIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowBackIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleBackward}
              />
            )}
            <Typography sx={{ fontSize: '12px' }}>
              {`${rows && rows.length}-${totalCount}`}
            </Typography>
            {count * page >= totalCount ? (
              <ArrowForwardIosIcon fontSize="10px" sx={{ color: '#E0E0E0' }} />
            ) : (
              <ArrowForwardIosIcon
                fontSize="10px"
                sx={{ cursor: 'pointer' }}
                onClick={handleForward}
              />
            )}
          </Item>
        ) : (
          ''
        )}
      </Item>

      {/* <Item sx={{ height: '210px', width: '25%', overflow: 'auto', p: 1.5 }}>
          {data?.map((item) => {
            return (
              <Item>
                <Item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    flexDirection: 'row',
                    height: '35px'
                  }}>
                  <Item
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      borderRadius: '50%',
                      backgroundColor: item?.fill
                    }}></Item>
                  <h4 style={{ fontSize: '0.9rem', marginLeft: '0.5rem' }}>{item?.name}</h4>
                </Item>
              </Item>
            );
          })}
        </Item> */}
    </React.Fragment>
  );
};

export default withStyles(styles)(Barcharts);
