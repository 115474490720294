import React, { useEffect, useState } from 'react';
import { Components } from '../../../utils/material-ui';
import AllocationCard from './AllocationCard';
import RiskCard from './riskCard';
import overviewcss from './overviewcss';
import CardsDetailContainer from './CardsDetailContainer';
import { useStateValue } from '../../../utils/store';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
// import moment from 'moment';

const { withStyles, Grid, FormControl, MenuItem, Select, InputLabel } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const regionArray = ['all', 'east', 'west', 'north', 'south'];
const Overview = ({ classes }) => {
  const [location, setlocation] = React.useState('all');
  const [offices, setOffices] = React.useState([{ city: 'All', _id: 'all' }]);
  const [, dispatch] = useStateValue();
  const [region, setRegion] = useState('all');
  const [cardsData, setCardsData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  // const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  // const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  // const onFromDateChange = (currentValue) => {
  //   const selectDate = new Date(currentValue.target.value);
  //   const today = new Date();
  //   if (selectDate > new Date(selectedToDate)) {
  //     dispatch({
  //       type: 'ALERT_OPEN',
  //       severity: 'error',
  //       isOpen: true,
  //       label: 'Selected Date Must be smaller Than or Equal To To Date'
  //     });
  //     setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
  //   } else {
  //     setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
  //   }
  // };

  // const onToDateChange = (currentValue) => {
  //   const selectDate = new Date(currentValue.target.value);
  //   if (selectDate < new Date(selectedFromDate)) {
  //     dispatch({
  //       type: 'ALERT_OPEN',
  //       severity: 'error',
  //       isOpen: true,
  //       label: 'To date must be greater than from date'
  //     });
  //   } else {
  //     setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
  //   }
  // };

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return {
            city: item.city,
            _id: item._id
          };
        });

        setOffices([...offices, ...resultData]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  const getUserStatitics = () => {
    const resultRegion = region === 'all' ? '' : `region=${region}`;
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;
    API_GET(`travelPackage/getUsersStatistics?${resultRegion}&${resultLocation}`)
      .then((res) => {
        setCardsData(res?.cards);

        setGraphData(res?.otherData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllOffices();
  }, []);

  useEffect(() => {
    getUserStatitics();
  }, [location, region]);

  return (
    <Container direction="column" className={classes.mainContainer}>
      <Item md={12} sx={{ display: 'flex', gap: '15px', alignItems: 'center' }} mt={1.2}>
        {/*   <div>
        
          <Item sx={{ display: 'inline-flex', flexWrap: 'wrap', ml: 'auto', gap: '10px' }}>
            <form noValidate>
              <TextField
                id="date"
                label="From Date"
                variant="outlined"
                type="date"
                size="small"
                value={selectedFromDate}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onFromDateChange(e);
                }}
              />
            </form>
          </Item>
          <Item>
            <form noValidate>
              <TextField
                id="date"
                label="To Date"
                type="date"
                size="small"
                variant="outlined"
                value={selectedToDate}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onToDateChange(e);
                }}
              />
            </form>
          </Item> 
              </div> */}

        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> Branch </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={location}
              sx={{ width: '200px' }}
              label="Branch"
              onChange={(e) => setlocation(e.target.value)}>
              {offices?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {ConvertToUpperCase(item?.city)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
        <Item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> Region </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={region}
              sx={{ width: '200px' }}
              label="Region"
              onChange={(e) => setRegion(e.target.value)}>
              {regionArray?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {ConvertToUpperCase(item)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
      </Item>

      <Item md={12}>
        <AllocationCard cardsData={cardsData} />
      </Item>
      <Item md={12}>
        <RiskCard location={location} region={region} />
      </Item>
      <Item md={12}>
        <CardsDetailContainer graphData={graphData} location={location} region={region} />
      </Item>
    </Container>
  );
};

export default withStyles(overviewcss)(Overview);
