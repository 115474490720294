import React from 'react';
import theme from './theme';
import { Components } from './material-ui';

const { Grid, Paper, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

const sortButton = ({ handleSort, sortArray, sort }) => {
  return (
    <Paper
      sx={{
        position: 'absolute',
        top: 40,
        right: 0,
        // pb: 2,
        pt: 2,
        zIndex: 99,
        width: '250px',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
      }}>
      <Typography sx={{ ...theme.typography.tableHeading, p: 0, pl: 2, fontSize: '16px' }}>
        Sort By
      </Typography>
      {sortArray?.map((item) => {
        return (
          <Item>
            <Item onClick={() => handleSort(item.value)}>
              <Typography
                sx={{
                  ...theme.typography.tableHeading,
                  fontSize: '16px',
                  fontWeight: '500',
                  cursor: 'pointer',
                  color: item.value === sort ? theme.palette.primary.main : '#9F9F9F'
                }}>
                {`Name,  ${item.name}`}
              </Typography>
            </Item>
            <Item sx={{ borderBottom: `1px solid #DCDCDC` }}></Item>
          </Item>
        );
      })}
    </Paper>
  );
};

export default sortButton;
