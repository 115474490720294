import React, { useState, useEffect, useRef } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_POST, API_HANDLE_ERROR, PRE_SEND_URL } from '../../../../utils/api';
import moment from 'moment';
import axios from 'axios';
import theme from '../../../../utils/theme';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import ImageNameFromUrl from '../../../../utils/ImageNameFromUrl';
import Chip from '@mui/material/Chip';

const {
  withStyles,
  Grid,
  Button,
  Typography,
  TextField,
  Checkbox,
  Autocomplete,
  Paper,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} = Components;

const { EmailIcon, WhatsAppIcon, TextsmsIcon, CloseIcon } = Icons;

const documentArray = [
  { name: 'Invoice', value: 'invoice' },
  { name: 'Estimate ', value: 'estimate' },
  { name: 'Quotation', value: 'quotation' },
  { name: 'PO', value: 'po' },
  { name: 'Other', value: 'other' }
];

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const currentDate = moment(new Date()).format('YYYY-MM-DD');
function AcceptAndForwardByIncidentModel({
  classes,
  handleClose,
  verifyId,
  status,
  isIncident,
  data
}) {
  const [, dispatch] = useStateValue();
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployeeArray, setSelectedEmployeeArray] = useState([]);
  const [offices, setOffices] = React.useState([]);
  const hiddenVisaInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [personName, setPersonName] = React.useState([]);
  const suggestedDate = moment(data?.suggestedDate).format('YYYY-MM-DD');

  const [sendData, setSendData] = useState({
    remark: '',
    email: false,
    whatsApp: false,
    textm: false,
    app: false,
    // selectedOffice: '',
    approvalStatus: false,
    budgetEstimate: 0,
    docType: '',
    startDate: suggestedDate < currentDate ? suggestedDate : moment(new Date()).format('YYYY-MM-DD')
  });

  useEffect(() => {
    verifyId &&
      sendData.approvalStatus &&
      API_GET(`budgetApproval/isBudgetApprovalFlowCompleted?incidentId=${verifyId}`)
        .then((res) => {
          if (res?.isBudgetApprovalFlowCompleted === true) {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Bugdget flow already completed'
            });
            setSendData({ ...sendData, approvalStatus: false });
          } else {
            setSendData({ ...sendData, approvalStatus: true });
          }
        })
        .catch((err) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: err[0]?.message || 'Oops!!, Please try again'
          });
        });
  }, [sendData.approvalStatus]);

  const handleStateChange = (key, value) => {
    if (key === 'startDate') {
      if (value < currentDate) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Proposed Date must be greater than or equal to current Date'
        });
        setSendData((prevState) => {
          prevState[`${key}`] = currentDate;
          return { ...prevState };
        });
      } else if (value > suggestedDate) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: `Proposed Date must be smaller than or equal to completion Date`
        });
        setSendData((prevState) => {
          prevState[`${key}`] = currentDate;
          return { ...prevState };
        });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'budgetEstimate') {
      if (value < 0) {
        setSendData({ ...sendData, budgetEstimate: 0 });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else {
      setSendData((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  const getEmployeeDetails = () => {
    const resultOffice = personName?.map((item) => {
      return item?._id;
    });

    let params = '';
    resultOffice.forEach((element) => {
      params += `&officeIdsArray[]=${element}`;
    });
    resultOffice.length > 0 &&
      API_GET(`incident/getAllEmployees?skip=0&limit=900000&${params}`)
        .then((res) => {
          setEmployeeList(res?.data);
        })
        .catch((err) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: err[0]?.message || 'Oops!!, Please try again'
          });
        });
  };
  useEffect(() => {
    getEmployeeDetails();
  }, [personName]);
  // approvedData Flow

  const [documents, setDocuments] = useState({
    invoice: [],
    estimate: [],
    quotation: [],
    po: [],
    other: []
  });

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleVisa = (event) => {
    if (sendData?.docType === '') {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Select document type'
      });
    } else {
      hiddenVisaInput.current.click();
    }
  };

  const precenturl = async (type, name) => {
    try {
      const data = await PRE_SEND_URL('user/incident/preSignedMediaUrl', {
        fileType: type,
        fileName: name.split('.')[0],
        featureType: 'budgetDocs'
      });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleimage = async (img) => {
    const arrayImage = [];

    setIsLoading(true);
    for (let i = 0; i < img.length; i++) {
      const docsType = img[i]?.type?.split('/');
      const name = img[i]?.name;
      const url = await precenturl(docsType[1], name);
      const file = img[i];

      const config = {
        method: 'put',
        url: url.signedUrl,
        headers: {
          'x-ms-blob-content-type': img[i].type,
          'content-type': 'application/octet-stream',
          'x-ms-blob-type': 'BlockBlob'
        },
        data: file
      };

      axios(config)
        .then(function (response) {
          arrayImage.push(url.mediaUrl);
          const temp = { ...documents };
          temp[sendData?.docType] = [
            ...(temp[sendData?.docType] ? temp[sendData?.docType] : []),
            ...arrayImage
          ];

          setDocuments(temp);
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: response?.data?.message || 'upload Successfully'
          });
          setIsLoading(false);
        })
        .catch(function (error) {
          API_HANDLE_ERROR(error, dispatch);
          setIsLoading(false);
        });
    }
  };

  const handleRemoveLink = (index, selectedCategory) => {
    const temp = { ...documents };
    temp[sendData?.docType] = temp[sendData?.docType].filter((item, id) => {
      return id !== index;
    });
    setDocuments(temp);
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();

    const filteredKeys = [];

    const object = {
      email: sendData.email,
      whatsApp: sendData.whatsApp,
      textm: sendData.textm,
      app: sendData.app
    };

    for (const key in object) {
      if (object[key] === true) {
        filteredKeys.push(key);
      }
    }

    const postdata = {
      employeeIdsArray: selectedEmployeeArray.map((item) => item._id),
      proposedDate: sendData?.startDate,
      notificationMedium: filteredKeys,
      actionMessage: sendData.remark,
      // officeId: sendData?.selectedOffice,
      incidentActionStatus: 'accepted',
      budgetApprovalRequired: sendData?.approvalStatus,
      documents: documents,
      acceptWithForward: 'true'
    };

    if (status === 'accepted') {
      const resultPostdata = {
        proposedDate: sendData?.startDate,
        employeeIdsArray: [],
        incidentActionStatus: 'accepted',
        actionMessage: sendData.remark
      };
      API_POST(
        `incident/dynamicIncidentAssignment/${verifyId}?historyType=${
          isIncident ? 'alert' : 'incident'
        }`,
        resultPostdata
      )
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
        })
        .catch((err) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: err?.data?.errors[0]?.message || 'Oops!!, Please try again'
          });
        });
    } else {
      const resultData = sendData?.approvalStatus
        ? { ...postdata, budgetEstimate: Number(sendData?.budgetEstimate) }
        : postdata;

      if (resultData.budgetApprovalRequired === true && resultData.employeeIdsArray.length !== 1) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Please Select Only One Recipient'
        });
      } else {
        API_POST(
          `incident/dynamicIncidentAssignment/${verifyId}?historyType=${
            isIncident ? 'alert' : 'incident'
          }`,
          resultData
        )
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            handleClose();
          })
          .catch((err) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: err?.data?.errors[0]?.message || 'Oops!!, Please try again'
            });
          });
      }
    }
  };

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        setOffices(res?.data);
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: err?.data?.errors[0]?.message || 'Oops!!, Please try again'
        });
      });
  };
  useEffect(() => {
    getAllOffices();
  }, []);

  const handleSelectChange = (value) => {
    if (value !== null) {
      const existData = selectedEmployeeArray.includes(value);
      if (existData) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'employee already selected'
        });
      } else {
        if (sendData?.approvalStatus) {
          if (selectedEmployeeArray.length >= 1) {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Select Only one user'
            });
          } else {
            setSelectedEmployeeArray([...selectedEmployeeArray, value]);
          }
        } else {
          if (selectedEmployeeArray.length >= 2) {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Can not select multiple user'
            });
          } else {
            setSelectedEmployeeArray([...selectedEmployeeArray, value]);
          }
        }
      }
    }
  };

  const handleDelete = (data) => {
    const resultArray = selectedEmployeeArray.filter((item) => {
      return item._id !== data._id;
    });
    setSelectedEmployeeArray(resultArray);
  };

  function getStyles(name, personName, theme) {
    return {
      backgroundColor: personName.indexOf(name) === -1 ? 'white' : theme.palette.primary.main
    };
  }

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{
        width: 'auto',
        opacity: isLoading ? 0.5 : 1,
        zIndex: isLoading ? 0 : 1,
        position: 'relative'
      }}>
      <Item className={classes.title}>
        <Typography style={{ textAlign: 'left' }}>
          {status === 'accepted' ? 'Accepted' : 'Accept&Forward'}
        </Typography>
      </Item>
      {isLoading ? (
        <Item
          sx={{
            ml: '45%',
            mt: '50%',
            zIndex: 1,
            position: 'absolute',
            opacity: 1
          }}>
          <CircularProgress size={50} style={{ color: theme.palette.primary.tableHeadingColor }} />
        </Item>
      ) : (
        ''
      )}
      <form onSubmit={onHandleConfirm}>
        <Item sx={{ maxHeight: '80vh', overflow: 'auto' }}>
          <Item sx={{ display: 'flex' }} md={12}>
            <Item sx={{ width: '400px', p: 1.5 }}>
              {status === 'accepted' ? (
                ''
              ) : (
                <Item mt={1}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">Location</InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      variant="outlined"
                      value={personName}
                      // required="true"
                      label="location"
                      onChange={handleChange}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip key={value} label={value.name} />
                          ))}
                        </div>
                      )}>
                      {offices.map((name, index) => (
                        <MenuItem
                          key={index}
                          value={name}
                          style={getStyles(name, personName, theme)}>
                          {`${name.name}, ${ConvertToUpperCase(name.city)}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Item>
              )}

              <Item mt={1.5}>
                <TextField
                  id="date"
                  label="Suggested Completion Date"
                  value={moment(data?.suggestedDate).format('DD-MM-YYYY')}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                />
              </Item>
              {suggestedDate < currentDate ? (
                <Item mt={1.5}>
                  <TextField
                    id="date"
                    label="Proposed Date"
                    // type="date"
                    // size="medium"
                    value={moment(suggestedDate).format('DD-MM-YYYY')}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                </Item>
              ) : (
                <Item mt={1.5}>
                  <TextField
                    id="date"
                    label="Proposed Date"
                    type="date"
                    size="medium"
                    value={sendData?.startDate}
                    className={classes.textField}
                    onChange={(e) => {
                      handleStateChange('startDate', e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                </Item>
              )}

              <Item mt={1}>
                <Item>
                  <TextField
                    variant="outlined"
                    name="remark"
                    label="Remark"
                    required={true}
                    multiline
                    rows={2}
                    size="small"
                    onChange={(e) => handleStateChange('remark', e.target.value)}
                    type="text"
                    fullWidth
                  />
                </Item>
              </Item>

              {status === 'accepted' ? (
                ''
              ) : (
                <Item>
                  <Item
                    sx={{
                      pt: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <Item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendData.approvalStatus}
                            onChange={(e) => handleStateChange('approvalStatus', e.target.checked)}
                          />
                        }
                        label="Request for approval"
                      />
                    </Item>
                  </Item>
                </Item>
              )}
              {status !== 'accepted' ? (
                sendData?.approvalStatus === true ? (
                  <Item
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    mt={1}>
                    <Item sx={{ fontSize: '14px' }}>Budget Estimation(₹)</Item>
                    <Item>
                      <TextField
                        variant="outlined"
                        name="remark"
                        size="small"
                        sx={{ width: '230px' }}
                        required={true}
                        onChange={(e) => handleStateChange('budgetEstimate', e.target.value)}
                        type="number"
                        fullWidth
                      />
                    </Item>
                  </Item>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <Item mt={1}>
                {status === 'accepted' ? (
                  ''
                ) : (
                  <Item>
                    <Autocomplete
                      id="combo-box-demo"
                      className={classes.number}
                      onChange={(e, value) => {
                        handleSelectChange(value);
                      }}
                      options={employeeList}
                      color="#134CDF"
                      size="small"
                      getOptionLabel={(option) => `${option?.fullName}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={true}
                          label="Recipient List"
                          variant="outlined"
                          type="text"
                          size="medium"
                        />
                      )}
                    />
                  </Item>
                )}
                <Paper sx={{ maxHeight: '200px', mt: 1, overflow: 'auto' }}>
                  {selectedEmployeeArray?.map((item) => {
                    return (
                      <Item
                        sx={{
                          height: '40px',
                          border: '1px solid grey',
                          mt: 1,
                          display: 'flex',
                          p: 1,
                          justifyContent: 'space-between'
                        }}>
                        <Item>{item?.fullName.toUpperCase()}</Item>
                        <Item>
                          <CloseIcon
                            onClick={() => handleDelete(item)}
                            sx={{ fontSize: '16px', cursor: 'pointer' }}
                          />
                        </Item>
                      </Item>
                    );
                  })}
                </Paper>
              </Item>

              {status === 'accepted' ? (
                ''
              ) : (
                <Item
                  sx={{
                    display: 'flex',
                    pt: 1,
                    justifyContent: 'space-between',
                    mt: 1
                  }}>
                  <Item>
                    <div
                      style={{
                        fontFamily: 'system-ui',
                        width: '70px',
                        height: '80px',
                        border: sendData.email ? `2px solid  #227BE5` : '1px solid #251515',
                        borderRadius: '5px'
                      }}>
                      <Item sx={{ ml: '44px', mt: '-9px' }}>
                        <Checkbox
                          size="extraSmall"
                          checked={sendData.email}
                          onChange={(e) => handleStateChange('email', e.target.checked)}
                        />
                      </Item>

                      <Item className={classes.icon1}>
                        <EmailIcon
                          fontSize="small"
                          sx={{
                            mt: -2,
                            color: sendData.email ? `#227BE5` : '#251515'
                          }}
                        />
                        <p
                          className={classes.iconLabel1}
                          style={{ color: sendData.email ? `#227BE5` : '#251515' }}>
                          Email
                        </p>
                      </Item>
                    </div>
                  </Item>
                  <Item>
                    <Item>
                      <div
                        style={{
                          fontFamily: 'system-ui',
                          width: '70px',
                          height: '80px',
                          borderRadius: '5px',
                          border: sendData.whatsApp ? `2px solid  #227BE5` : '1px solid #251515'
                        }}>
                        <Item sx={{ ml: '43px', mt: '-9px' }}>
                          <Checkbox
                            size="extraSmall"
                            checked={sendData.whatsApp}
                            onChange={(e) => handleStateChange('whatsApp', e.target.checked)}
                          />
                        </Item>

                        <Item className={classes.icon1}>
                          <WhatsAppIcon
                            fontSize="small"
                            sx={{
                              mt: -2,
                              color: sendData.whatsApp ? `#227BE5` : '#251515'
                            }}
                          />
                          <p
                            className={classes.iconLabel1}
                            style={{
                              color: sendData.whatsApp ? `#227BE5` : '#251515'
                            }}>
                            WhatsApp
                          </p>
                        </Item>
                      </div>
                    </Item>
                  </Item>
                  <Item>
                    <Item>
                      <div
                        style={{
                          fontFamily: 'system-ui',
                          width: '70px',
                          height: '80px',
                          border: sendData.textm ? `2px solid  #227BE5` : '1px solid #251515',
                          borderRadius: '5px'
                        }}>
                        <Item sx={{ ml: '43px', mt: '-9px' }}>
                          <Checkbox
                            size="extraSmall"
                            checked={sendData.textm}
                            onChange={(e) => handleStateChange('textm', e.target.checked)}
                          />
                        </Item>

                        <Item className={classes.icon1}>
                          <TextsmsIcon
                            fontSize="small"
                            sx={{
                              mt: -2,
                              color: sendData.textm ? `#227BE5` : '#251515'
                            }}
                          />
                          <p
                            className={classes.iconLabel1}
                            style={{
                              color: sendData.textm ? `#227BE5` : '#251515'
                            }}>
                            SMS
                          </p>
                        </Item>
                      </div>
                    </Item>
                  </Item>
                  <Item>
                    <Item>
                      <div
                        style={{
                          fontFamily: 'system-ui',
                          width: '70px',
                          height: '80px',
                          border: sendData.app ? `2px solid  #227BE5` : '1px solid #251515',
                          borderRadius: '5px'
                        }}>
                        <Item sx={{ ml: '43px', mt: '-9px' }}>
                          <Checkbox
                            size="extraSmall"
                            checked={sendData.app}
                            onChange={(e) => handleStateChange('app', e.target.checked)}
                          />
                        </Item>

                        <Item className={classes.icon1}>
                          <img src="./lythouse.png" alt="lythouse" width="15px" height="20px" />

                          <p
                            className={classes.iconLabel1}
                            style={{
                              color: sendData.app ? `#227BE5` : '#251515'
                            }}>
                            In App
                          </p>
                        </Item>
                      </div>
                    </Item>
                  </Item>
                </Item>
              )}
            </Item>
            {sendData?.approvalStatus ? (
              <Item sx={{ width: '400px', borderLeft: '1px solid grey', p: 1.5 }}>
                <Item
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 1,
                    gap: '10px'
                  }}>
                  <Item>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Choose Document</InputLabel>
                      <Select
                        sx={{ width: '270px' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sendData.docType}
                        label="Choose Document"
                        size="medium"
                        //   onChange={(e) => handleCategoryChange(index, e.target.value, 'documentType')}
                        onChange={(e) => handleStateChange('docType', e.target.value)}>
                        {documentArray?.map((item, index) => {
                          return (
                            <MenuItem value={item?.value} key={index}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Item>

                  <Item>
                    <Item onClick={handleVisa} sx={{ cursor: 'pointer' }}>
                      <img
                        src="https://i.ibb.co/vZtcJQ7/Group-5893983-1.jpg"
                        alt="upload"
                        style={{
                          height: '35px',
                          width: '35px'
                        }}
                      />
                    </Item>
                    <input
                      type="file"
                      ref={hiddenVisaInput}
                      multiple
                      accept="image/*,.pdf"
                      onChange={
                        (e) => handleimage(e.target.files)
                        //   handleimage(index, e.target.files, 'documentLink')
                      }
                      style={{ display: 'none' }}
                    />
                  </Item>
                </Item>
                <Item
                  sx={{
                    maxHeight: '50vh',
                    m: 'auto',
                    overflow: 'auto'
                  }}>
                  {Object.keys(documents).map((item) => {
                    return (
                      <Item
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          mt: 1.5
                        }}>
                        <Item sx={{ width: '80px', fontSize: '14px' }}>
                          {documents[item].length !== 0 ? ConvertToUpperCase(item) : ''}
                        </Item>
                        <Item>
                          {documents[item]?.map((links, index) => {
                            return (
                              <Item
                                sx={{
                                  ...theme.card,
                                  fontSize: '14px',
                                  p: 1,
                                  width: '250px',
                                  wrap: 'noWrap',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  mt: 1
                                }}>
                                <Item>{ImageNameFromUrl(links)}</Item>
                                <Item sx={{ p: 0.2 }}>
                                  <CloseIcon
                                    onClick={() => handleRemoveLink(index, item?.category)}
                                    style={{ fontSize: '15px' }}
                                  />
                                </Item>
                              </Item>
                            );
                          })}
                        </Item>
                      </Item>
                    );
                  })}
                </Item>
              </Item>
            ) : (
              ''
            )}
          </Item>
        </Item>
        <Item sx={{ display: 'flex', p: 1, gap: 1 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button style={{ ...theme.button.cancel }} onClick={() => handleClose()}>
              Cancel
            </Button>
          </Item>

          <Item>
            <Button
              type="submit"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              {status === 'accepted' ? 'Accept' : 'Accept&Forward'}
            </Button>
          </Item>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(AcceptAndForwardByIncidentModel);
