import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
// import { Typography } from '@mui/material';
import InfiniteList from './infiniteList2';

const { withStyles, Grid } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Notifications = ({ classes, handleNotification, handleTractNotifation }) => {
  return (
    <Container xs={12} className={classes.incidentAlert}>
      <Item
        sx={{
          padding: 0.5,
          width: '100%'
        }}>
        <InfiniteList
          handleNotification={handleNotification}
          handleTractNotifation={handleTractNotifation}
        />
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Notifications);
