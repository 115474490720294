import React, { useState } from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';

const { withStyles, TextField, Grid, Button } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function DeactivateUser({ classes, handleClose, deleteId, onHandleDelete }) {
  const [sendData, setSendData] = useState({
    deactivatedReason: ''
  });

  const handleStateChange = (key, value) => {
    setSendData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const handleDelete = () => {
    onHandleDelete(deleteId, sendData?.deactivatedReason);
  };

  return (
    <Container direction="column" className={classes.newUser}>
      <Item md={12} xs={12} sx={{ width: '300px' }}>
        <TextField
          variant="outlined"
          label="Reason Of Deactivation"
          required={true}
          value={sendData?.deactivatedReason}
          className={classes.formControl}
          onChange={(e) => handleStateChange('deactivatedReason', e.target.value)}
          type="text"
          fullWidth
        />
      </Item>
      <Item sx={{ display: 'flex', gap: '15px', mt: 2 }}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <Button onClick={() => handleClose()} style={{ ...theme.button.cancel }}>
            NO
          </Button>
        </Item>
        <Item>
          <Button
            type="submit"
            style={{
              ...theme.button.submit,
              backgroundColor: theme.palette.primary.main
            }}
            onClick={handleDelete}>
            YES
          </Button>
        </Item>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(DeactivateUser);
