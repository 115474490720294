const styles = (theme) => {
  return {
    title: {
      backgroundColor: theme.palette.primary.tableHeadingColor,
      fontFamily: theme.palette.font.fontFamily,
      color: 'white',
      textAlign: 'center',
      borderRadius: '10px 10px 0 0',
      padding: '10px'
    },
    formControl: {
      width: '98%'
    },
    icon: {
      padding: theme.spacing(0, 1),
      textAlign: 'center',
      fontFamily: theme.palette.font.fontFamily,
      borderRadius: '5px'
    },
    iconLabel: {
      fontSize: '12px',
      marginTop: theme.spacing(-0.2),
      fontFamily: theme.palette.font.fontFamily,
      fontWeight: '600'
    }
  };
};

export default styles;
