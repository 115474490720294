import React, { useState } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from './styles';
import theme from '../../../utils/theme';
import { useStateValue } from '../../../utils/store';
import { API_HANDLE_ERROR, API_POST } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { cookieRemove } from '../../../utils/cookie';

const { withStyles, Grid, Paper, Typography, TextField, Button, InputAdornment, IconButton } =
  Components;
const { VisibilityOffIcon, VisibilityIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Password = ({ classes, data }) => {
  const [, dispatch] = useStateValue();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const [sendData, setSendData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleTogglePassword = (status) => {
    if (status === 'current') {
      setShowPassword1(!showPassword1);
    } else if (status === 'new') {
      setShowPassword2(!showPassword2);
    } else if (status === 'confirm') {
      setShowPassword3(!showPassword3);
    }
  };

  const handleStateChange = (key, value) => {
    setSendData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    const postData = {
      oldPassword: sendData?.currentPassword?.trim(),
      newPassword: sendData?.confirmPassword?.trim()
    };
    const newpass = sendData?.newPassword?.trim();
    const confirmPass = sendData?.confirmPassword?.trim();
    if (newpass === confirmPass) {
      API_POST(`user/changePassword`, postData)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res?.message ?? 'Success'
          });
          setSendData({ ...sendData, currentPassword: '', newPassword: '', confirmPassword: '' });
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: `Passwords Mismatch`
      });
    }
  };

  const navigate = useNavigate();

  const handleForgetPass = () => {
    dispatch({
      type: 'IS_LOGGED_IN',
      isLogin: false,
      role: ''
    });
    setTimeout(() => {
      cookieRemove();
      navigate('/login/forgetPassword');
    }, 1000);
  };

  const str = theme.palette.primary.main;
  const result = str.slice(1);

  return (
    <Container sx={{ padding: theme.spacing(2, 0) }}>
      <Item md={12} sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
        <img
          src={require(`../../../Assets/settingsIcon/${result}.svg`).default}
          alt="icon"
          style={{ width: '100%' }}
        />

        <Item md={12} xs={12} sx={{ position: 'absolute', top: '80px' }}>
          <Paper
            sx={{
              ...theme.card,
              minHeight: '70vh',
              width: '75vw',
              display: 'flex',
              justifyContent: 'center',
              mb: 2
            }}>
            <Item
              sx={{
                position: 'absolute',
                m: 'auto',
                marginTop: '-50px'
              }}>
              <img
                src={data?.profileImage}
                alt="profile"
                style={{
                  objectFit: 'cover',
                  height: '120px',
                  width: '120px',
                  borderRadius: '25px',
                  border: '5px solid lightpink'
                }}
              />
            </Item>
            <Item md={12} xs={12}>
              <form onSubmit={onHandleConfirm}>
                <Item className={classes.inputContainer} md={12} xs={12} mt={5}>
                  <Item md={12} xs={12}>
                    <Typography
                      sx={{
                        ...theme.typography.tableHeading,
                        fontWeight: '600',
                        p: 0,
                        fontSize: '17px'
                      }}>
                      Modify Password
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '15px',
                        color: '#565555',
                        mt: 1
                      }}>
                      Changing the login password frequently prevents unauthorized users from
                      modifying configurations.
                    </Typography>
                  </Item>
                  <Item md={12} xs={12} mt={2}>
                    <Typography className={classes.inputText}>Current Password</Typography>

                    <Item mt={1.2} md={5.8}>
                      <TextField
                        variant="outlined"
                        name="name"
                        size="small"
                        onChange={(e) => handleStateChange('currentPassword', e.target.value)}
                        placeholder="Please enter your current password"
                        value={sendData.currentPassword}
                        type={showPassword1 ? 'text' : 'password'}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <div className={classes.indoIcon}>
                              <InputAdornment position="end">
                                <IconButton onClick={() => handleTogglePassword('current')}>
                                  {showPassword1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            </div>
                          )
                        }}
                      />
                      <Typography
                        onClick={handleForgetPass}
                        className={classes.inputText}
                        sx={{ float: 'right', fontSize: '13px', mt: 1, cursor: 'pointer' }}>
                        Forgot password
                      </Typography>
                    </Item>
                  </Item>
                  <Item md={12} xs={12} mt={2}>
                    <Typography className={classes.inputText}>New Password</Typography>

                    <Item mt={1.2} md={5.8}>
                      <TextField
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleStateChange('newPassword', e.target.value)}
                        placeholder="Please enter your new password"
                        value={sendData.newPassword}
                        type={showPassword2 ? 'text' : 'password'}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <div className={classes.indoIcon}>
                              <InputAdornment position="end">
                                <IconButton onClick={() => handleTogglePassword('new')}>
                                  {showPassword2 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            </div>
                          )
                        }}
                      />
                    </Item>
                    {sendData?.newPassword?.trim()?.length < 8 && (
                      <Typography className={classes.errorText} sx={{ fontSize: '13px' }} mt={0.5}>
                        Password must be of minimum 8 characters
                      </Typography>
                    )}
                  </Item>
                  <Item md={12} xs={12} mt={2}>
                    <Typography className={classes.inputText}>Confirm Password</Typography>

                    <Item mt={1.2} md={5.8}>
                      <TextField
                        variant="outlined"
                        name="name"
                        size="small"
                        placeholder="Please enter your confirm password"
                        value={sendData.confirmPassword}
                        onChange={(e) => handleStateChange('confirmPassword', e.target.value)}
                        type={showPassword3 ? 'text' : 'password'}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <div className={classes.indoIcon}>
                              <InputAdornment position="end">
                                <IconButton onClick={() => handleTogglePassword('confirm')}>
                                  {showPassword3 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            </div>
                          )
                        }}
                      />
                    </Item>
                    {sendData?.confirmPassword?.trim()?.length < 8 && (
                      <Typography className={classes.errorText} sx={{ fontSize: '13px' }} mt={0.5}>
                        Password must be of minimum 8 characters
                      </Typography>
                    )}
                  </Item>
                </Item>
                <Container>
                  <Item
                    sx={{
                      display: 'inline-flex',
                      ml: 'auto',
                      gap: '20px',
                      p: theme.spacing(2, 5)
                    }}>
                    <Item>
                      <Button
                        sx={{
                          ...theme.button.cancel
                        }}>
                        Cancel
                      </Button>
                    </Item>
                    <Item>
                      <Button
                        type="submit"
                        style={{
                          ...theme.button.submit,
                          backgroundColor: theme.palette.primary.main
                        }}>
                        {`Save`}
                      </Button>
                    </Item>
                  </Item>
                </Container>
              </form>
            </Item>
          </Paper>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Password);
