const head = [
  {
    id: 'assignedBy',
    label: 'Assigned By',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
  },
  {
    id: 'assignedTo',
    label: 'Assigned To',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
  },
  {
    id: 'officeName',
    label: 'Office',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
  },
  {
    id: 'budgetEstimation',
    label: 'Req. budget/approved',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
  },
  {
    id: 'forwardedOn',
    label: 'Forwarded On',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'center'
  },

  {
    id: 'actionStatusByAssignee',
    label: 'Action',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    align: 'center'
  }
];

export default head;
