import React, { useState, useEffect, useRef } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../utils/store';
import { Components, Icons } from '../../../../utils/material-ui';
import { API_HANDLE_ERROR, API_UPLOAD_FILE, API_GET, API_DELETE } from '../../../../utils/api';
import Dialog from '../../../atom/Dialog';
import theme from '../../../../utils/theme';
import tableColor from '../../../../utils/tableColor';
import DeleteConfirmation from '../../Modal/DeleteConfirmation';
import { getDateTime } from '../../../../utils/moment';
import EditOnboarding from '../../Modal/EditOnboarding';
import downloadDataToExcelFile from '../../../../utils/downloadDataToExcelFile';
import DownloadTextFile from '../../../../utils/DownloadTextFile';
import { TotalCount } from '../../../../utils/ButtonDiff';
const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Button,
  CircularProgress
} = Components;

const { EditIcon, DeleteIcon } = Icons;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const OnboardingEmployee = ({ classes, searchText, selectedFromDate, selectedToDate }) => {
  const [, dispatch] = useStateValue();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalEmployees, setTotalEmployees] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isOpenEditModel, setIsOpenEditModel] = useState(false);
  const [editId, setEditId] = useState('');

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleDownload = () => {
    const sampleArray = [
      'sample1@gmail.com',
      'sample2@gmail.com',
      'sample3@gmail.com',
      'sample4@gmail.com'
    ];
    const exportData = sampleArray.map((item) => {
      return {
        Email: item
      };
    });
    downloadDataToExcelFile(exportData, 'User Onboarding Sample File');
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    uploadfile(fileUploaded);
    event.target.value = '';
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllOnboardingUser();
  }, [dispatch, page, rowsPerPage, searchText, selectedFromDate, selectedToDate, isOpenEditModel]);

  const getAllOnboardingUser = () => {
    API_GET(
      `onBoarding/getAllOnBoarding?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}&fromDate=${selectedFromDate}&toDate=${selectedToDate}`
    )
      .then((res) => {
        if (searchText) {
          setPage(0);
        }
        setRows(res?.onBoardingUsers);
        setTotalEmployees(res?.onBoardingUserCount);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const uploadfile = (fileUploaded) => {
    setLoading(true);
    API_UPLOAD_FILE(`onBoarding/importEmails`, fileUploaded)
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        getAllOnboardingUser();
        setLoading(false);
        if (res?.errors.length > 0) {
          const errorText = res?.errors
            ?.map((error) => `Row ${error.row}: ${error.errorMessage}\n`)
            .join('');
          DownloadTextFile(errorText);
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setIsOpenEditModel(false);
    setIsOpenDeleteModel(false);
  };

  const handleRemove = (item) => {
    setIsOpenDeleteModel(true);
    setDeleteId(item?._id);
  };

  const onHandleCancel = (deletedItem) => {
    deletedItem &&
      API_DELETE(`onBoarding/deleteOnBoardUser/${deletedItem}`)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          getAllOnboardingUser();
          setIsOpenDeleteModel(false);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  const handleEdit = (item) => {
    setIsOpenEditModel(true);
    setEditId(item);
  };
  const handleAddUser = () => {
    setIsOpenEditModel(true);
  };

  return (
    <React.Fragment>
      <Item sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Item>{TotalCount('Onboard Users', `Total Users - ${totalEmployees}`)}</Item>
        <Item sx={{ display: 'inline-flex', ml: 'auto', gap: '12px' }}>
          <Button
            onClick={handleClick}
            size="small"
            className={classes.button}
            style={{
              ...theme.button.filterButton,
              width: '130px'
            }}>
            <img
              className={classes.filterIcon}
              src={require(`../../../../Assets/import.svg`).default}
              alt="icon"
            />
            {loading && <CircularProgress sx={{ color: 'black' }} size={20} />}
            {loading ? 'Processing' : 'Import'}
          </Button>
          <Button
            onClick={handleDownload}
            size="small"
            className={classes.button}
            style={{
              ...theme.button.filterButton,
              width: '130px'
            }}>
            <img
              style={{ height: '19px', width: '19px', marginRight: '10px' }}
              src={require(`../../../../Assets/export.svg`).default}
              alt="icon"
            />
            Export
          </Button>
          <Button
            onClick={handleAddUser}
            size="small"
            className={classes.button}
            style={{
              ...theme.button.filterButton,
              width: '130px'
            }}>
            Onboard User
          </Button>

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept=".xlsx, .xls, .csv"
          />
        </Item>
      </Item>

      <Card className={classes.root} sx={theme.tableBorder}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.head}>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {'No Records found'}
                  </TableCell>
                </TableRow>
              )}
              {rows &&
                rows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      style={{ backgroundColor: tableColor(index) }}>
                      {columns &&
                        columns.map((column, index) => {
                          const value = row[column.id] ?? '';

                          if (column.id === 'date') {
                            return (
                              <StyledTableCell align="left" key={column.id}>
                                {getDateTime(row.createdAt)}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'action') {
                            return (
                              <StyledTableCell align="center" key={column.id}>
                                <Item>
                                  <EditIcon
                                    onClick={() => handleEdit(row)}
                                    sx={{ cursor: 'pointer' }}
                                  />
                                  <DeleteIcon
                                    onClick={() => handleRemove(row)}
                                    sx={{ cursor: 'pointer', color: 'red' }}
                                  />
                                </Item>
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalEmployees && totalEmployees ? totalEmployees : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          isOpen={isOpenDeleteModel}
          children={
            <DeleteConfirmation
              handleClose={handleClose}
              deleteId={deleteId}
              onHandleDelete={onHandleCancel}
            />
          }></Dialog>
        <Dialog
          isOpen={isOpenEditModel}
          children={<EditOnboarding handleClose={handleClose} data={editId} />}></Dialog>
      </Card>
    </React.Fragment>
  );
};

export default withStyles(styles)(OnboardingEmployee);
