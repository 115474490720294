import React, { useEffect, useState } from 'react';
import { Components } from '../../../utils/material-ui';
import { StyledEngineProvider } from '@mui/material/styles';
import { useStateValue } from '../../../utils/store';
import reportIncidentSound from './reportIncidentSound.mp3';
// import ambulanceSiren from './ambulanceSiren.wav';

// import DrawerHome from './Drawer';
// import Header from './Header';
import styles from './styles';
import SideBar from './Drawer';
import Header from './Header';
import Notifications from '../../../pages/Notifications';

const { withStyles, Box, Grid, Typography } = Components;
// const { ErrorOutlineIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const HeaderWithDrawer = withStyles(styles)(({ classes, label, drawerWidth }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [userDetails, SetUserDetails] = useState({});
  const [store] = useStateValue();
  const [alertOpen, setAlertOpen] = useState(false);
  const [isOpenNotification, setIsOpenNotificication] = useState(false);
  const [trackNotification, setTractNotifications] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    store.socketIO.on('initiated', (message) => {
      if (store.role === 'sosAdmin') {
        SetUserDetails(message);
        setAlertOpen(true);
        document.getElementById('reportIncidentAlertSound').play();
        setTimeout(() => {
          setAlertOpen(false);
        }, 10000);
      }
    });
  }, [store]);

  const handleNotification = () => {
    setIsOpenNotificication(!isOpenNotification);
  };

  const handleTractNotifation = () => {
    setTractNotifications(!trackNotification);
  };

  return (
    <StyledEngineProvider injectFirst>
      <Box>
        <Header
          handleDrawerToggle={handleDrawerToggle}
          handleNotification={handleNotification}
          isOpenNotification={isOpenNotification}
          drawerWidth={drawerWidth}
          label={label}
          trackNotification={trackNotification}
        />
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            boxShadow: '1px 0px 3px black'
          }}
          aria-label="mailbox folders">
          <SideBar
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
            drawerWidth={drawerWidth}
          />
        </Box>
      </Box>
      {alertOpen && (
        <Container xs={12} spacing={1} alignItems="center" className={classes.incidentAlert}>
          {/* <Item alignItems="flex-end">
            <ErrorOutlineIcon className={classes.alertIcon}></ErrorOutlineIcon>
          </Item> */}
          <Item>
            <Typography>
              {`SOS REPORTED BY  - ${userDetails.fullName}, ${userDetails.countryCode}${userDetails.mobileNumber} FROM ${userDetails.city}`}
            </Typography>
          </Item>
        </Container>
      )}
      {isOpenNotification && (
        <Notifications
          handleNotification={handleNotification}
          handleTractNotifation={handleTractNotifation}
        />
      )}
      <audio id="reportIncidentAlertSound">
        <source src={reportIncidentSound} type="audio/mpeg"></source>
        <track kind="captions" />
      </audio>
    </StyledEngineProvider>
  );
});

export default HeaderWithDrawer;
