import React, { useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import CountsCard from './countsCard';
import ActiveBroadcast from './ActiveBroadcast';
import TempleteBroadcast from './TempleteBroadcast';
import ScheduledBroadcast from './ScheduledBroadcast';
import NewBroadcast from './NewBroadcast';

const { withStyles, Grid, Box } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const BroadcastNew = ({ classes }) => {
  const cards = [
    { value: 'broadcast', label: 'New Broadcast', table: NewBroadcast },
    { value: 'active', label: 'Active/history', table: ActiveBroadcast },
    { value: 'scheduled', label: 'Scheduled', table: ScheduledBroadcast },
    { value: 'templete', label: 'Templates', table: TempleteBroadcast }
  ];

  const [selectedCard, setSelectedCard] = useState(cards[0]);

  return (
    <Container direction="column" className={classes.mainContainer}>
      <Box>
        <Container justify="space-between" alignItems="center">
          {cards.map((item) => (
            <Item key={item.value}>
              <CountsCard item={item} selectedCard={selectedCard} onClick={setSelectedCard} />
            </Item>
          ))}
        </Container>
      </Box>

      <Item xs={12} mt={3}>
        <selectedCard.table></selectedCard.table>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(BroadcastNew);
