import React, { useState, useEffect } from 'react';
import { Components } from '../../../utils/material-ui';
import agentscss from './agentcss';
import ListOfAgents from '../../../components/organism/Table/TravelManagementTables/ListOfAgents';
import theme from '../../../utils/theme';
import { useStateValue } from '../../../utils/store';
// import moment from 'moment';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';

const { withStyles, Grid, FormControl, InputLabel, Select, MenuItem, Paper } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const regionArray = [
  { name: 'All', value: 'all' },
  { name: 'East', value: 'east' },
  { name: 'West', value: 'west' },
  { name: 'North', value: 'north' },
  { name: 'South', value: 'south' }
];
const sortBy = [
  { name: 'Name', value: 'name' },
  { name: 'Pending', value: 'pendingDocsCount' },
  { name: 'To be Expire', value: 'toBeExpiredDocsCount' },
  { name: 'Expired', value: 'expiredDocsCount' },
  { name: 'Nominated', value: 'nominatedCount' },
  { name: 'Latest', value: 'createdAt' }
];
const filterBy = [
  { name: 'All', value: 'all' },
  { name: 'Agent', value: 'agent' },
  { name: 'Employee', value: 'user' }
];

const Overview = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [region, setRegion] = React.useState('all');
  const [sort, setSort] = useState('createdAt');
  const [filter, setFilter] = useState('all');
  // const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  // const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [location, setlocation] = React.useState('all');
  const [offices, setOffices] = React.useState([{ city: 'All', _id: 'all' }]);

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        const resultData = res?.data?.map((item) => {
          return {
            city: item.city,
            _id: item._id
          };
        });

        setOffices([...offices, ...resultData]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllOffices();
  }, []);

  // const onFromDateChange = (currentValue) => {
  //   const selectDate = new Date(currentValue.target.value);
  //   const today = new Date();
  //   if (selectDate > today) {
  //     dispatch({
  //       type: 'ALERT_OPEN',
  //       severity: 'error',
  //       isOpen: true,
  //       label: 'Selected Date Greater Than Today'
  //     });
  //     setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
  //   } else {
  //     setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
  //   }
  // };

  // const onToDateChange = (currentValue) => {
  //   const selectDate = new Date(currentValue.target.value);
  //   const today = new Date();
  //   if (selectDate > today) {
  //     setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
  //   } else if (selectDate < new Date(selectedFromDate)) {
  //     dispatch({
  //       type: 'ALERT_OPEN',
  //       severity: 'error',
  //       isOpen: true,
  //       label: 'To date must be greater than from date'
  //     });
  //     setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
  //   } else {
  //     setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
  //   }
  // };

  return (
    <Container>
      <Item md={12} mt={2}>
        <Paper sx={{ ...theme.card, borderRadius: 'none' }}>
          <Item className={classes.filterContainer} md={12}>
            {/*  <Item>
              <form noValidate>
                <TextField
                  id="datetime-local"
                  label="From"
                  type="date"
                  size="small"
                  sx={{ width: '200px' }}
                  value={selectedFromDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onFromDateChange(e);
                  }}
                />
              </form>
                </Item> */}
            {/*  <Item>
              <form noValidate>
                <TextField
                  id="date"
                  label="To"
                  type="date"
                  size="small"
                  sx={{ width: '200px' }}
                  value={selectedToDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onToDateChange(e);
                  }}
                />
              </form>
                </Item> */}
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Zone </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={region}
                  sx={{ width: '200px' }}
                  label=" Zone "
                  onChange={(e) => setRegion(e.target.value)}>
                  {regionArray?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Branch </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={location}
                  sx={{ width: '200px' }}
                  label=" Branch "
                  onChange={(e) => setlocation(e.target.value)}>
                  {offices?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?._id}>
                        {ConvertToUpperCase(item?.city)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Sort By </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={sort}
                  sx={{ width: '200px' }}
                  label="Sort By"
                  onChange={(e) => setSort(e.target.value)}>
                  {sortBy?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Filter By </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  value={filter}
                  sx={{ width: '200px' }}
                  label="Filter By"
                  onChange={(e) => setFilter(e.target.value)}>
                  {filterBy?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Item>
          </Item>
        </Paper>
      </Item>
      <Item md={12} style={{ width: '500px' }} mt={1}>
        <ListOfAgents
          // selectedFromDate={selectedFromDate}
          // selectedToDate={selectedToDate}
          location={location}
          sort={sort}
          region={region}
          filter={filter}
        />
      </Item>
    </Container>
  );
};

export default withStyles(agentscss)(Overview);
