import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { useStateValue } from '../../../../../utils/store';
import { Components, Icons } from '../../../../../utils/material-ui';
import { API_DELETE, API_GET, API_HANDLE_ERROR } from '../../../../../utils/api';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import tableColor from '../../../../../utils/tableColor';
import theme from '../../../../../utils/theme';
import Dialog from '../../../../atom/Dialog';
import AddTemplate from '../../../Modal/BroadcastModels/AddTemplate';
import { getDateTime } from '../../../../../utils/moment';
import { ApiDates } from '../../../../../utils/ApiDates';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TableCell,
  Button,
  Checkbox
} = Components;

const { EditIcon } = Icons;

// const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const TemplateBroadcastTable = ({
  classes,
  searchText,
  selectedFromDate,
  selectedToDate,
  sort
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalTemplate, setTotalTemplate] = React.useState(0);
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [checkboxData, setCheckboxData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [isOpenAddTemplate, setIsOpenAddTemplate] = useState(false);
  const [templateStatus, setTemplateStatus] = useState(false);
  const [editData, setEditData] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // to reset page on search
  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // checkboxData logic

  let selectedCount = 0;
  if (checkboxData) {
    Object.keys(checkboxData).map((item) =>
      checkboxData[`${item}`] === true ? selectedCount++ : selectedCount
    );
  }

  const handleSelectAll = (event) => {
    const boolean = event.target.checked;
    const keys = Object.keys(checkboxData);
    const object = {};
    keys.map((item) => {
      return (object[item] = boolean);
    });
    setCheckboxData(object);
    setSelectAll(boolean);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxData({
      ...checkboxData,
      [event.target.name]: event.target.checked
    });
    setSelectAll(false);
  };

  const getAllTemplate = () => {
    const resultsort = !sort ? 1 : sort;
    const dates = ApiDates(selectedFromDate, selectedToDate);

    API_GET(
      `broadCastMessage/getAllHeader?limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&searchText=${searchText}&sort=header&order=${resultsort}${dates}`
    )
      .then((res) => {
        const checkboxObject = {};
        res?.headersByCrporateId?.map((item, index) => (checkboxObject[index] = false));
        setCheckboxData(checkboxObject);
        setSelectAll(false);
        setRows(res?.headersByCrporateId);
        setTotalTemplate(res?.headerCount);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllTemplate();
  }, [dispatch, page, rowsPerPage, searchText, sort, selectedFromDate, selectedToDate]);

  const handleAddTemplate = (status, data) => {
    setTemplateStatus(status);
    setIsOpenAddTemplate(true);
    setEditData(data);
  };

  const handleClose = () => {
    setIsOpenAddTemplate(false);
  };

  const handleDelete = () => {
    const keys = Object.keys(checkboxData);
    const filteredKeys = keys.filter((item) => checkboxData[item] === true);
    const approveDeclinedIds = filteredKeys.map((item) => rows[item]._id).flat();
    if (approveDeclinedIds.length > 0) {
      API_DELETE('broadCastMessage/deleteHeader', { headerIds: approveDeclinedIds })
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          handleClose();
          getAllTemplate();
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Select atleast one Entry !'
      });
    }
  };

  return (
    <React.Fragment>
      <Item sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} md={12} xs={12}>
        <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
          <Item sx={{ display: 'flex', gap: '10px' }}>
            <Item>
              <Button
                onClick={() => handleAddTemplate('add', '')}
                style={{
                  ...theme.button.initiate,

                  padding: '7px',
                  fontSize: '13px',
                  borderRadius: 'none',
                  width: 'auto'
                }}>
                Add Template
              </Button>
            </Item>
            <Item>
              <Button
                onClick={handleDelete}
                style={{
                  ...theme.button.trash,
                  padding: '7px',
                  fontSize: '13px',
                  borderRadius: 'none'
                }}
                className={classes.button}>
                Delete
              </Button>
            </Item>
          </Item>
        </Item>
      </Item>
      <Card className={classes.root} sx={theme.tableBorder}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.thead}>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.id === 'select' ? (
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          style={{ color: theme.typography.tableHeading.heading }}
                        />
                      ) : (
                        column.label
                      )}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {'No Records found'}
                  </TableCell>
                </TableRow>
              )}
              {rows &&
                rows?.map((row, rowindex) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      style={{ backgroundColor: tableColor(rowindex) }}>
                      {columns &&
                        columns?.map((column, index) => {
                          const value = row[column.id] ?? '--';

                          if (column.id === 'select') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <Checkbox
                                  color="primary"
                                  checked={checkboxData[`${rowindex}`]}
                                  onChange={handleCheckboxChange}
                                  name={`${rowindex}`}
                                />
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'date') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.createdAt ? getDateTime(row?.createdAt) : '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'createdBy') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.createdBy?.fullName
                                  ? ConvertToUpperCase(row?.createdBy?.fullName)
                                  : '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'ctanames') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                {row?.ctanames.length > 0
                                  ? row?.ctanames
                                      ?.map((item) => {
                                        return ConvertToUpperCase(item?.ctaName);
                                      })
                                      ?.join(', ')
                                  : '-'}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'action') {
                            return (
                              <StyledTableCell align={column.align} key={column.id}>
                                <EditIcon
                                  style={{ color: '#868686', cursor: 'pointer' }}
                                  onClick={() => handleAddTemplate('edit', row)}
                                />
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column?.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalTemplate && totalTemplate ? totalTemplate : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          isOpen={isOpenAddTemplate}
          children={
            <AddTemplate
              handleClose={handleClose}
              status={templateStatus}
              getAllTemplate={getAllTemplate}
              editData={editData}
            />
          }></Dialog>
      </Card>
    </React.Fragment>
  );
};

export default withStyles(styles)(TemplateBroadcastTable);
