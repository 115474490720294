import React, { useState, useEffect } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import { ResponsivePieCanvas } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { API_WELLNESS_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';

const { withStyles, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;

// const data = [
//   {
//     label: '20% Like',
//     value: 10,
//     color: '#C0E5FF',
//     id: 'Like'
//   },
//   {
//     value: 40,
//     color: '#FFC1D6',
//     id: 'Dislike',
//     label: '30% Helpful'
//   },
//   {
//     value: 20,
//     color: '#B6ECC5',
//     id: 'Helpful',
//     label: '20% Helpful'
//   },
//   {
//     value: 30,
//     color: '#BEB3FB',
//     id: 'Similar Content',
//     label: '30% Similar Content'
//   }
// ];

// const graphData = [
//   {
//     Label: 'Motivation',
//     Motivation: 10
//   },
//   {
//     Country: 'Physical',
//     Physical: 12
//   },
//   {
//     Country: 'Financial',
//     Financial: 34
//   },
//   {
//     Country: 'Yoga',
//     Yoga: 43
//   },
//   {
//     Country: 'Meditation',
//     Meditation: 11
//   },
//   {
//     Country: 'Spiritual',
//     Spiritual: 34
//   }
// ];

const HealthContent = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [labels, setLabels] = useState([]);
  const [contentFeedback, setContentFeedback] = useState([]);

  useEffect(() => {
    API_WELLNESS_GET(`wellness/getHealthContentActivity`)
      .then((res) => {
        setContentFeedback(res?.contentFeedback);
        setRows(res?.data);
        const resultLabel = res?.data?.map((item) => {
          return item?.Label;
        });
        setLabels(resultLabel);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch]);

  return (
    <Item md={12} xs={12} pt={2} pb={2}>
      <Item className={classes.fitnessContainer}>
        <Item md={5.8} xs={12} mt={1}>
          <Typography sx={{ fontSize: '1rem', fontWeight: '500', color: '#797979' }}>
            Category wise content consumption
          </Typography>
          <Item className={classes.healthCard} mt={2}>
            <ResponsiveBar
              data={(rows && rows) || []}
              keys={labels}
              indexBy="Label"
              margin={{ top: 30, right: 0, bottom: 40, left: 70 }}
              padding={0.5}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={['#C0E5FF', '#FCC183', '#FD9D99', '#BEB3FB', '#B6ECC5', '#FFC1D6']}
              enableLabel={false}
              borderRadius={3}
              enableGridY={false}
              axisLeft={{
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'No Of. Mins',
                legendPosition: 'middle',
                legendOffset: -50
              }}
              // labelFormat={(d) => <tspan y={0}>{d}</tspan>}
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: 12,
                      fill: '#001F45'
                    }
                  }
                }
              }}
            />
          </Item>
        </Item>
        <Item md={5.8} xs={12} mt={1}>
          <Typography sx={{ fontSize: '1rem', fontWeight: '500', color: '#797979' }}>
            Content Feedback
          </Typography>
          <Item className={classes.healthCard} mt={2}>
            <Item sx={{ height: '100%', width: '60%' }}>
              <ResponsivePieCanvas
                theme={{
                  fontSize: 15,
                  fontWeight: '600'
                }}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                data={contentFeedback}
                margin={{ top: 30, right: 20, bottom: 30, left: 20 }}
                innerRadius={0.5}
                padAngle={2}
                cornerRadius={7}
                activeOuterRadiusOffset={8}
                colors={{ datum: 'data.color' }}
                arcLinkLabelsSkipAngle={2}
                arcLinkLabelsText={false}
                arcLinkLabelsTextColor="black"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={5}
                arcLabelsTextColor="black"
              />
            </Item>
            <Item
              sx={{
                width: '40%',
                display: 'flex',
                alignItem: 'center',
                flexDirection: 'column'
              }}
              pt={5}>
              {contentFeedback?.map((item) => {
                return (
                  <Item style={{ display: 'flex', alignItem: 'center', gap: '10px' }} mt={2}>
                    <Item
                      sx={{ height: '20px', width: '20px', backgroundColor: item?.color }}></Item>
                    <Typography sx={{ fontSize: '0.9rem' }}>{item?.label}</Typography>
                  </Item>
                );
              })}
            </Item>
          </Item>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(HealthContent);
