import React from 'react';
import styles from './styles';
import { Components } from '../../../../../utils/material-ui';
import columns from './head';
import { StyledTableCell } from './StyledTableCell';
import theme from '../../../../../utils/theme';
import { getTime, getDate } from '../../../../../utils/moment';
import tableColor from '../../../../../utils/tableColor';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  // TablePagination,
  Grid,
  Button,
  TableRow,
  TableCell
} = Components;

// const sampleData = [
//   {
//     date: '12-22-2022',
//     arrivalTime: '16:30',
//     departureTime: '20:30',
//     hotel: 'Country Inn',
//     place: 'Paris',
//     totalDays: 12,
//     meal: 'BLD',
//     modeOfTransport: 'Flight'
//   },
//   {
//     date: '12-22-2022',
//     arrivalTime: '16:30',
//     departureTime: '20:30',
//     hotel: 'Country Inn',
//     place: 'Paris',
//     totalDays: 12,
//     meal: 'BLD',
//     modeOfTransport: 'Flight'
//   },
//   {
//     date: '12-22-2022',
//     arrivalTime: '16:30',
//     departureTime: '20:30',
//     hotel: 'Country Inn',
//     place: 'Paris',
//     totalDays: 12,
//     meal: 'BLD',
//     modeOfTransport: 'Flight'
//   },
//   {
//     date: '12-22-2022',
//     arrivalTime: '16:30',
//     departureTime: '20:30',
//     hotel: 'Country Inn',
//     place: 'Paris',
//     totalDays: 12,
//     meal: 'BLD',
//     modeOfTransport: 'Flight'
//   },
//   {
//     date: '12-22-2022',
//     arrivalTime: '16:30',
//     departureTime: '20:30',
//     hotel: 'Country Inn',
//     place: 'Paris',
//     totalDays: 12,
//     meal: 'BLD',
//     modeOfTransport: 'Flight'
//   },
//   {
//     date: '12-22-2022',
//     arrivalTime: '16:30',
//     departureTime: '20:30',
//     hotel: 'Country Inn',
//     place: 'Paris',
//     totalDays: 12,
//     meal: 'BLD',
//     modeOfTransport: 'Flight'
//   },
//   {
//     date: '12-22-2022',
//     arrivalTime: '16:30',
//     departureTime: '20:30',
//     hotel: 'Country Inn',
//     place: 'Paris',
//     totalDays: 12,
//     meal: 'BLD',
//     modeOfTransport: 'Flight'
//   }
// ];

const Item = (props) => <Grid item {...props} />;
const PackageDataWithId = ({ classes, data, handleClose }) => {
  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data == null || data?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {data && data !== null
              ? data?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row?._id}
                      style={{ backgroundColor: tableColor(index) }}>
                      {columns &&
                        columns.map((column) => {
                          const value = row && row !== null ? row[column.id] : '';

                          if (column.id === 'startDate') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {getDate(row?.startDate) || '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'endDate') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {getDate(row?.endDate) || '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'arrivalTime') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {getTime(row?.arrivalTime) || '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'departureTime') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {getTime(row?.departureTime) || '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'totalDays') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.totalDays || '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'mealType') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.mealType.join(', ') || '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'modeOfTransport') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.modeOfTransport.join(', ') || '-'}
                              </StyledTableCell>
                            );
                          }
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })
              : ''}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalIncidents && totalIncidents ? totalIncidents : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}

      <Item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1 }}>
        <Button
          size="small"
          onClick={() => handleClose()}
          style={{
            display: 'flex',
            backgroundColor: theme.palette.primary.tableHeadingColor,
            color: '#FFFFFF',
            fontSize: '12px'
          }}>
          Close
        </Button>
      </Item>
    </Card>
  );
};

export default withStyles(styles)(PackageDataWithId);
