import React, { useEffect, useState } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from './styles';
import { API_GET, API_HANDLE_ERROR, API_PATCH } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';
import theme from '../../../utils/theme';
import { Android12Switch } from '../../../utils/ButtonDiff';

const { withStyles, Grid, Typography, FormControlLabel } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const SafeLocation = ({ classes }) => {
  const [, dispatch] = useStateValue();
  const [cardsData, setCardData] = useState([]);

  const getUserData = () => {
    API_GET('safeLocationConfig/getSafecentercategories')
      .then((res) => {
        setCardData(res);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getUserData();
  }, [dispatch]);

  const handleActiveStatus = (item) => {
    API_PATCH(`safeLocationConfig/mutateSafeLocationCategory/${item?.safeCenterCategoryId}`, {
      active: !item?.active
    })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res?.message ?? 'Success'
        });
        getUserData();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const Card = (item) => {
    return (
      <Item sx={{ display: 'flex' }} md={12} xs={12} p={1.5}>
        <Item md={2.5} xs={2.5}>
          <img src={item?.badgeIcon} alt="tab" style={{ width: '50px', height: '50px' }} />
        </Item>
        <Item md={8} xs={8}>
          <Typography
            sx={{
              ...theme.typography.tableHeading,
              fontWeight: '600',
              p: 0,
              fontSize: '16px'
            }}>
            {item?.name}
          </Typography>
          <Typography sx={{ color: '#565555', fontSize: '14px' }}>
            {`All the ${item?.name?.toLowerCase()} that are nearby to you`}
          </Typography>
        </Item>
        <Item md={1.5} xs={1.5} mt={-0.8}>
          <FormControlLabel
            sx={{
              display: 'block'
            }}
            control={
              <Android12Switch
                checked={item?.active}
                onChange={() => handleActiveStatus(item)}
                name="loading"
                color="primary"
              />
            }
          />
        </Item>
      </Item>
    );
  };

  return (
    <Container className={classes.mainContainer}>
      <Item md={12} xs={12} ml={1}>
        <Typography
          sx={{
            ...theme.typography.tableHeading,
            fontWeight: '600',
            p: 0,
            fontSize: '17px'
          }}>
          Safe Haven
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            color: '#565555',
            mt: 1
          }}>
          Safe havens refer to places, situations, or assets that are perceived to provide a high
          degree of safety and protection from harm, danger, or risk.
        </Typography>
      </Item>
      <Item md={12} xs={12} className={classes.cardsContainer}>
        {cardsData?.map((item) => {
          return (
            <Item
              md={3.7}
              xs={12}
              sx={{
                ...theme.card,
                minHeight: '100px',
                mt: 4,
                minWidth: '300px'
              }}>
              {Card(item)}
            </Item>
          );
        })}
      </Item>
    </Container>
  );
};

export default withStyles(styles)(SafeLocation);
