import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../../utils/theme';
import { API_GET, API_HANDLE_ERROR } from '../../../../../utils/api';
import { useStateValue } from '../../../../../utils/store';
import { Typography } from '@mui/material';

const {
  withStyles,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  Checkbox,
  InputLabel,
  ListItemText
} = Components;

const { CancelIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  variant: 'menu'
};

function AddRecipient({
  classes,
  handleClose,
  handleGroupIndiviualData,
  status,
  resLocation,
  resGroupName,
  resFactory,
  resDepartment,
  resCurrentCities
}) {
  const [, dispatch] = useStateValue();
  const [offices, setOffices] = useState([]);
  const [selectedLocationAllOffices, setSelectedLocationAllOffices] = useState([]);
  const [allDepartment, setAllDepartment] = useState([]);
  const [currentCity, setCurrentCity] = useState([]);
  const [groups, setGroups] = useState([]);

  // state
  const [location, setLocation] = useState([]);
  const [factory, setFactory] = useState([]);
  const [department, setDepartment] = useState([]);
  const [currentCities, setCurrentCities] = useState([]);
  const [groupName, setGroupName] = useState([]);

  useEffect(() => {
    if (status === 'edit') {
      setLocation(resLocation);
      setFactory(resFactory);
      setDepartment(resDepartment);
      setCurrentCities(resCurrentCities);
      setGroupName(resGroupName);
    }
  }, [status]);

  // Get All Offices From city
  const getAllLocation = () => {
    API_GET(`broadCastMessage/getAllLocations`)
      .then((res) => {
        setOffices(res?.officesByCrporateId);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  // Get All Current cities
  const GetAllCurrentCities = () => {
    API_GET(`broadCastGroup/getCurrentCities`)
      .then((res) => {
        setCurrentCity(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  // Get All groups
  const getAllGroups = () => {
    API_GET('broadCastGroup/getAllGroup')
      .then((res) => {
        setGroups(res?.groupsByCrporateId);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllLocation();
    GetAllCurrentCities();
    getAllGroups();
  }, []);

  // get all office from location
  useEffect(() => {
    let resultIds = '';
    for (let i = 0; i < location.length; i++) {
      resultIds += `&cities[]=${location[i]?._id}`;
    }

    API_GET(`broadCastGroup/getCityWiseOffices?${resultIds}`)
      .then((res) => {
        setSelectedLocationAllOffices(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [location]);

  // get All Department

  useEffect(() => {
    let resultIds = '';
    for (let i = 0; i < factory?.length; i++) {
      resultIds += `&officeIds[]=${factory[i]?._id}`;
    }
    API_GET(`department/getAllDepartment?${resultIds}`)
      .then((res) => {
        const result = res?.found?.map((item) => {
          return {
            _id: item?._id,
            department: item?.department,
            officeId: item?.officeId
          };
        });
        setAllDepartment(result);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [factory]);

  // select all logic

  const isAllSelectedLocation = offices?.length > 0 && location.length === offices?.length;
  const isAllSelectedGroup = groups?.length > 0 && groupName.length === groups?.length;
  const isAllSelectedCurrentCities =
    currentCity?.length > 0 && currentCities.length === currentCity?.length;
  const isAllSelectedDepartment =
    allDepartment?.length > 0 && department.length === allDepartment?.length;
  const isAllSelectedFactory =
    selectedLocationAllOffices?.length > 0 && factory.length === selectedLocationAllOffices?.length;

  const handleChange = (event, key) => {
    const value = event.target.value;
    if (key === 'location') {
      if (value[value.length - 1] === 'all') {
        setLocation(location.length === offices.length ? [] : offices);
        return;
      }
      setLocation(value);
    } else if (key === 'factory') {
      if (value[value.length - 1] === 'all') {
        setFactory(
          factory.length === selectedLocationAllOffices.length ? [] : selectedLocationAllOffices
        );
        return;
      }
      setFactory(value);
    } else if (key === 'department') {
      if (value[value.length - 1] === 'all') {
        setDepartment(department.length === allDepartment.length ? [] : allDepartment);
        return;
      }
      setDepartment(value);
    } else if (key === 'currentCities') {
      if (value[value.length - 1] === 'all') {
        setCurrentCities(currentCities.length === currentCity.length ? [] : currentCity);
        return;
      }
      setCurrentCities(value);
    } else if (key === 'group') {
      if (value[value.length - 1] === 'all') {
        setGroupName(groupName.length === groups.length ? [] : groups);
        return;
      }
      setGroupName(value);
    }
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    const data = {
      location,
      factory,
      department,
      currentCities,
      groupData: groupName
    };
    handleGroupIndiviualData(data);
    handleClose();
  };

  const handleRemoveLocation = (data) => {
    const resultLocation = location?.filter((item) => {
      return item?._id !== data?._id;
    });
    const resultFactory = factory?.filter((item) => {
      return item?.city !== data?._id;
    });
    const resultDepartment = department?.filter((item) => {
      return !item?.department?.includes(data?._id);
    });
    setLocation(resultLocation);
    setDepartment(resultDepartment);
    setFactory(resultFactory);
  };

  const handleRemoveFactory = (data) => {
    const resultFactory = factory?.filter((item) => {
      return item?._id !== data?._id;
    });
    const resultDepartment = department?.filter((item) => {
      return !item?.department?.includes(data?.city);
    });
    setDepartment(resultDepartment);
    setFactory(resultFactory);
  };

  const handleRemoveCurrentCity = (data) => {
    const resultCurrentCities = currentCities?.filter((item) => {
      return item !== data;
    });
    setCurrentCities(resultCurrentCities);
  };

  const handleRemoveDepartment = (data) => {
    const resultDepartment = department?.filter((item) => {
      return item?._id !== data?._id;
    });
    setDepartment(resultDepartment);
  };

  const handleRemoveGroup = (data) => {
    const resultRemoveGroup = groupName?.filter((item) => {
      return item?._id !== data?._id;
    });
    setGroupName(resultRemoveGroup);
  };

  const SmallCard = (item, type) => {
    let result;
    if (type === 'Office Location') {
      result = item?._id;
    } else if (type === 'Office') {
      result = `${item?.name}, ${item?.city}`;
    } else if (type === 'Department') {
      result = `${item?.department}`;
    } else if (type === 'Current City') {
      result = item;
    } else if (type === 'groups') {
      result = item?.groupName;
    }
    return (
      <Item className={classes.smallContainer}>
        <Item sx={{ fontSize: '0.9rem' }}>{result}</Item>
        <CancelIcon
          onClick={() =>
            type === 'Office Location'
              ? handleRemoveLocation(item)
              : type === 'Office'
              ? handleRemoveFactory(item)
              : type === 'Department'
              ? handleRemoveDepartment(item)
              : type === 'Current City'
              ? handleRemoveCurrentCity(item)
              : type === 'groups'
              ? handleRemoveGroup(item)
              : null
          }
          sx={{ cursor: 'pointer', fontSize: '15px', color: '#868686' }}
        />
      </Item>
    );
  };

  const previewData = {
    'Office Location': location,
    Office: factory,
    Department: department,
    'Current City': currentCities
  };

  return (
    <Container direction="column" className={classes.newUser}>
      <form onSubmit={onHandleConfirm}>
        <Item md={12} xs={12} className={classes.formContainer}>
          <Item md={12} xs={12} className={classes.title}>
            Individual
          </Item>
          <Item md={12} xs={12} className={classes.saveContainer}>
            <Item md={5.6} xs={12} sx={{ width: '35vw' }} mt={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Office Location</InputLabel>
                <Select
                  multiple
                  label="Office Location"
                  value={location}
                  required={groupName.length === 0}
                  onChange={(e) => handleChange(e, 'location')}
                  renderValue={(selected) => {
                    return selected
                      ?.map((item) => {
                        return item?._id;
                      })
                      .join(', ');
                  }}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelectedLocation ? classes.selectedAll : ''
                    }}>
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelectedLocation}
                        indeterminate={location.length > 0 && location.length < offices.length}
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {offices.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={location.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option?._id} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
            <Item md={5.6} xs={12} sx={{ width: '35vw' }} mt={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Office</InputLabel>
                <Select
                  multiple
                  label="Office"
                  required={groupName.length === 0}
                  value={factory}
                  onChange={(e) => handleChange(e, 'factory')}
                  renderValue={(selected) => {
                    return selected
                      ?.map((item) => {
                        return `(${item?.name},${item?.city})`;
                      })
                      .join(', ');
                  }}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelectedFactory ? classes.selectedAll : ''
                    }}>
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelectedFactory}
                        indeterminate={
                          factory.length > 0 && factory.length < selectedLocationAllOffices.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {selectedLocationAllOffices?.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={factory.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={`${option?.name}, ${option?.city}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>

            <Item md={5.6} xs={12} sx={{ width: '35vw' }} mt={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Department</InputLabel>
                <Select
                  multiple
                  label="Department"
                  value={department}
                  onChange={(e) => handleChange(e, 'department')}
                  renderValue={(selected) => {
                    return selected
                      ?.map((item) => {
                        return `(${item?.department})`;
                      })
                      .join(', ');
                  }}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelectedDepartment ? classes.selectedAll : ''
                    }}>
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelectedDepartment}
                        indeterminate={
                          department.length > 0 && department.length < allDepartment.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {allDepartment?.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={department.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={`${option?.department}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
            <Item md={5.6} xs={12} sx={{ width: '35vw' }} mt={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Current City</InputLabel>
                <Select
                  multiple
                  label="Current City"
                  value={currentCities}
                  onChange={(e) => handleChange(e, 'currentCities')}
                  renderValue={(selected) => {
                    return selected.join(', ');
                  }}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelectedCurrentCities ? classes.selectedAll : ''
                    }}>
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelectedCurrentCities}
                        indeterminate={
                          currentCities.length > 0 && currentCities.length < currentCity.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {currentCity?.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={currentCities.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={`${option}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Item>
          <Item md={12} xs={12} sx={{ border: '0.5px solid lightGrey' }} mt={3} mb={3}></Item>
          <Item className={classes.title}>Group</Item>
          <Item md={12} xs={12} className={classes.saveContainer}>
            <Item md={5.6} xs={12} sx={{ width: '35vw' }} mt={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Choose Group</InputLabel>
                <Select
                  multiple
                  label="Choose Group"
                  value={groupName}
                  onChange={(e) => handleChange(e, 'group')}
                  renderValue={(selected) => {
                    return selected
                      ?.map((item) => {
                        return item?.groupName;
                      })
                      .join(', ');
                  }}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelectedGroup ? classes.selectedAll : ''
                    }}>
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelectedGroup}
                        indeterminate={groupName.length > 0 && groupName.length < groups.length}
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {groups.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={groupName.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option?.groupName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Item>
          <Item mt={1.5}>
            <Item className={classes.title}>Preview</Item>
            <Typography className={classes.title} mt={1}>
              Individual
            </Typography>
            <Item md={12} xs={12} className={classes.indiviualSelectContainer}>
              {Object.keys(previewData)?.map((item) => {
                return (
                  <Item md={12} xs={12} sx={{ display: 'flex' }} mt={1}>
                    <Item md={1.5} xs={12} className={classes.text}>
                      {previewData[`${item}`]?.length > 0 && `${item} :`}
                    </Item>
                    <Item md={10.5} xs={12} className={classes.previewData}>
                      {previewData[`${item}`]?.length > 0 &&
                        previewData[`${item}`]?.map((newItem) => {
                          return <React.Fragment>{SmallCard(newItem, item)}</React.Fragment>;
                        })}
                    </Item>
                  </Item>
                );
              })}
            </Item>
            <Typography className={classes.title} mt={1}>
              Group
            </Typography>
            <Item md={12} xs={12} className={classes.groupSelectContainer}>
              <Item className={classes.previewData}>
                {groupName?.length > 0 &&
                  groupName?.map((newItem) => {
                    return <React.Fragment>{SmallCard(newItem, 'groups')}</React.Fragment>;
                  })}
              </Item>
            </Item>
          </Item>
        </Item>
        <Item sx={{ float: 'right' }} mt={0.5}>
          <Button
            onClick={handleClose}
            style={{
              border: `1px solid ${theme.palette.primary.tableHeadingColor}`,
              color: theme.palette.primary.tableHeadingColor,
              backgroundColor: '#FFFFFF',
              width: '100px',
              marginRight: '10px'
            }}
            variant="contained"
            fullWidth>
            cancel
          </Button>
          <Button
            style={{
              backgroundColor: theme.palette.primary.tableHeadingColor,
              color: '#FFFFFF',
              width: '100px'
            }}
            type="submit"
            variant="contained"
            fullWidth>
            Submit
          </Button>
        </Item>
      </form>
    </Container>
  );
}

export default withStyles(styles)(AddRecipient);
