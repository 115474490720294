import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
import { useStateValue } from '../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import ConvertToUpperCase from '../../../utils/ConvertToUpperCase';
import ExportToExcel from '../../../utils/downloadMergeColumnData';

const { Grid, withStyles, Typography } = Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

const LocationWiseRevenue = ({ classes, selectedFromDate, selectedToDate }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);
  const [exportResultData, setExportResultData] = useState([]);

  useEffect(() => {
    API_GET(
      `reports/getLocationWiseRevenueData?fromDate=${selectedFromDate}&toDate=${selectedToDate}&queryType=cityFromDror`
    )
      .then((res) => {
        const resultArr = res?.queryWiseRevenueData?.map((item) => {
          const earnPercentage =
            item['Rev Earned'] !== 0 && item.total !== 0
              ? (item['Rev Earned'] / item?.total) * 100
              : 0;
          const nonEmptyEarn = { ...item, earnPercentage: earnPercentage };
          const emptyEarn = {
            ...item,
            backgroundColor: '#D8D8D8',
            color: '#707070',
            earnPercentage: 0
          };
          return item['Rev Earned'] !== 0 && item.total !== 0 ? nonEmptyEarn : emptyEarn;
        });

        setRows(resultArr);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate]);

  const handleExport = () => {
    API_GET(
      `reports/downloadLoationWiseRevenueGraph?fromDate=${selectedFromDate}&toDate=${selectedToDate}&queryType=cityfromdror`
    )
      .then((res) => {
        const resultData = res?.queryWiseRevenueData?.map((item) => {
          return {
            SrNo: item.SrNo,
            location: item.location,
            ...item
          };
        });
        setExportResultData(resultData);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });

    setTimeout(() => {
      ExportToExcel(
        'location_wise_revenue',
        'xlsx',
        `Loc. wise Rev. Earn_Loss (${selectedFromDate} To ${selectedToDate}).xlsx`
      );
    }, 500);
  };

  const TableData = () => {
    return (
      <div id="location_wise_revenue" style={{ display: 'none' }}>
        <table border="1">
          <thead>
            <tr>
              {exportResultData.length > 0 &&
                Object.keys(exportResultData[0]).map((item) => {
                  return (
                    <th colSpan={item === 'SrNo' || item === 'location' ? '1' : '2'}>
                      {ConvertToUpperCase(item)}
                    </th>
                  );
                })}
            </tr>
            <tr>
              {exportResultData.length > 0 &&
                Object.keys(exportResultData[0]).map((item) => {
                  return (
                    <React.Fragment>
                      {item === 'SrNo' || item === 'location' ? (
                        <th></th>
                      ) : (
                        <React.Fragment>
                          <th>Rev Earned</th>
                          <th>Rev Loss</th>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {exportResultData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.SrNo}</td>
                  <td>{item.location}</td>
                  {Object.keys(item).map((subData) => {
                    return (
                      <React.Fragment>
                        {subData !== 'SrNo' && subData !== 'location' ? (
                          <React.Fragment>
                            <td>{item[subData].revGain}</td>
                            <td>{item[subData].revLoss}</td>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Item>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Location wise Revenue Earned/Loss
          </Typography>
        </Item>
        <Item>
          {TableData()}
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>

      <Item sx={{ maxHeight: '250px', overflow: 'auto', p: 1.5 }}>
        {rows?.map((item, index) => {
          return (
            <Item key={index}>
              <Item
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ fontSize: '13px' }}>{ConvertToUpperCase(item?.city)}</p>
                <p
                  style={{
                    color: item?.color,
                    fontSize: '13px'
                  }}>{`$${item['Rev Earned'] !== 0 ? item['Rev Earned'].toFixed(1) : 0} / $${
                  item?.total !== 0 ? item?.total.toFixed(1) : 0
                }`}</p>
              </Item>
              <Item
                style={{
                  height: '8px',
                  borderRadius: '5px',
                  width: '100%',
                  backgroundColor: item?.backgroundColor
                }}>
                <Item
                  style={{
                    height: '100%',
                    width: `${item?.earnPercentage}%`,
                    backgroundColor: item?.color,
                    borderRadius: '5px'
                  }}></Item>
              </Item>
            </Item>
          );
        })}
      </Item>
    </Item>
  );
};

export default withStyles(styles)(LocationWiseRevenue);
