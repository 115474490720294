import React, { useState } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import styles from './styles';
import OnboardingEmployee from '../../../components/organism/Table/OnboardingEmployee';
import moment from 'moment';
import theme from '../../../utils/theme';
import { useStateValue } from '../../../utils/store';

const { withStyles, Grid, InputAdornment, TextField } = Components;
const { SearchIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const LatestOnbarding = ({ classes }) => {
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [, dispatch] = useStateValue();
  const [searchText, setSearchText] = useState('');

  const onFromDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Selected Date Greater Than Today'
      });
      setSelectedFromDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedFromDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  const onToDateChange = (currentValue) => {
    const selectDate = new Date(currentValue.target.value);
    const today = new Date();
    if (selectDate > today) {
      setSelectedToDate(moment(selectDate).format('YYYY-MM-DD'));
    } else if (selectDate < new Date(selectedFromDate)) {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'To date must be greater than from date'
      });
      setSelectedToDate(moment(new Date(today)).format('YYYY-MM-DD'));
    } else {
      setSelectedToDate(moment(new Date(selectDate)).format('YYYY-MM-DD'));
    }
  };

  return (
    <Container>
      <Item
        md={12}
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          m: theme.spacing(1, 0)
        }}>
        <Item md={4} xs={12} mt={1}>
          <TextField
            placeholder="Search by Email"
            size="small"
            variant="outlined"
            fullWidth
            sx={theme.customTextFiled}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Item>
        <Item
          sx={{
            display: 'inline-flex',
            ml: 'auto',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
          mt={1}>
          <Item>
            <form noValidate>
              <TextField
                id="datetime-local"
                label="From"
                type="date"
                size="small"
                value={selectedFromDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onFromDateChange(e);
                }}
              />
            </form>
          </Item>
          <Item>
            <form noValidate>
              <TextField
                id="date"
                label="To"
                type="date"
                size="small"
                value={selectedToDate}
                className={classes.datePicker}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  onToDateChange(e);
                }}
              />
            </form>
          </Item>
        </Item>
      </Item>

      <Item md={12} xs={12} mt={1}>
        <OnboardingEmployee
          searchText={searchText}
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
        />
      </Item>
    </Container>
  );
};

export default withStyles(styles)(LatestOnbarding);
