import { Button, Typography } from '@mui/material';
import React from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';

const { withStyles, Grid } = Components;
const { CancelRoundedIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function ActualIncidentNewsModal({ classes, handleClose, data, selectedFilterCategory }) {
  const handleMore = () => {
    window.open(data?.sourceWebsite);
  };

  return (
    <Container direction="column" wrap="nowrap" sx={{ width: '65vw', height: 'auto' }}>
      <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
        <Button onClick={() => handleClose()}>
          <CancelRoundedIcon sx={{ cursor: 'pointer', color: '#DCDCDC' }} />
        </Button>
      </Item>
      <Item
        sx={{
          display: 'flex',
          p: 2,
          gap: '15px'
        }}>
        <Item sx={{ height: '35vh' }}>
          <img
            src={data?.imageURL || 'https://i.ibb.co/tZQWWY8/download.jpg'}
            alt="news"
            width="300px"
            height="100%"
            style={{ borderRadius: '10px' }}
          />
        </Item>
        <Item>
          <Typography
            sx={{
              ...theme.typography.tableHeading,
              fontSize: '22px',
              p: 0,
              fontFamily: theme.palette.font.fontFamily
            }}>
            {data?.title}
          </Typography>
          <Item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              style={{
                ...theme.button.tableSeverityButton,
                backgroundColor: data?.backgroundColor || 'grey',
                color: data?.color || '#FFFFFF'
              }}>
              {data?.alertCategory || 'All'}
            </Button>
            <Item sx={{ fontSize: '15px', fontWeight: '600', ...theme.typography.tableHeading }}>
              Location-
              {ConvertToUpperCase(
                [data?.locationFromDror, data?.cityFromDror, data?.stateFromDror]
                  .filter((item) => item !== '')
                  .join(', ')
              )}
            </Item>
          </Item>
          <Item mt={1} sx={{ maxHeight: '50vh', overflow: 'auto' }}>
            <Typography
              sx={{
                fontSize: '15px',
                fontFamily: theme.palette.font.fontFamily,
                color: '#818181'
              }}>
              {data?.content}
              <Item onClick={() => handleMore()}>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                    fontSize: '14px',
                    cursor: 'pointer'
                  }}>
                  Read More
                </Typography>
              </Item>
            </Typography>
          </Item>
        </Item>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(ActualIncidentNewsModal);
