const styles = (theme) => {
  return {
    mainContainer: {
      // backgroundColor: theme.palette.common.gray98,
      padding: theme.spacing(1, 1.5)
    },

    tableContainer1: {
      minHeight: '80vh',
      padding: theme.spacing(1, 1.5)
    },
    containHeading: {
      fontSize: '16px',
      paddingTop: theme.spacing(1)
    },

    cardHeading: {
      padding: '5px 20px',
      borderRadius: '10px 10px 0 0',
      color: theme.palette.common.white,
      textAlign: 'left',
      fontSize: '13px'
    },

    card: {
      width: '100%',
      height: '90px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '13px',
      cursor: 'pointer'

      // boxShadow: `0px 0px 8px ${theme.palette.primary.main}`
    },
    labelText: {
      fontSize: '15px',
      textAlign: 'center',
      color: '#FFFFFF',
      lineHeight: '25px',
      fontWeight: '500'
    },
    heading: {
      textAlign: 'center',
      fontSize: '19px',
      fontWeight: '600'
    },
    subHeading: {
      fontSize: '14px',
      fontWeight: '600',
      padding: 1
    },
    headingContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0.2, 0.5)
      // flexWrap: 'wrap'
    },
    graphContainer: {
      height: '275px'
    },
    graphContainer1: {
      maxHeight: '275px'
    },
    tablerow: {
      textAlign: 'center',
      padding: '8px',
      border: '1.5px solid #E0E0E0',
      width: '50%'
    },
    tablerow1: {
      textAlign: 'left',
      padding: '8px 50px',
      border: '1.5px solid #E0E0E0',
      width: '50%',
      fontWeight: '600',
      fontSize: '14px'
    },
    tablerow2: {
      textAlign: 'left',
      padding: '4px 50px',
      border: '1.5px solid #E0E0E0',
      width: '50%',
      fontWeight: '600',
      fontSize: '14px'
    },
    tablerow4: {
      textAlign: 'center',
      padding: '4px',
      border: '1.5px solid #E0E0E0',
      width: '50%'
    },
    tableHead: {
      textAlign: 'center',
      padding: '8px'
    },
    revenueTextColor: {
      color: 'black'
    }
  };
};

export default styles;
