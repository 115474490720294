import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Components } from '../../../../../utils/material-ui';
import columns from './head';
import { StyledTableCell } from './StyledTableCell';
import ConvertToUpperCase from '../../../../../utils/ConvertToUpperCase';
import { API_GET, API_HANDLE_ERROR } from '../../../../../utils/api';
import { useStateValue } from '../../../../../utils/store';

const { withStyles, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } =
  Components;

const UserDetails = ({ classes, data }) => {
  const [, dispatch] = useStateValue();
  const [reportingToData, setReportingToData] = useState('');
  data = [data];

  const getAllReportingManegers = () => {
    API_GET(`user/getEmployees?onlyManagers=true`)
      .then((res) => {
        res?.data?.employees?.map((item) => {
          if (item?.reportingTo === data?.[0]?.reportingTo) {
            setReportingToData(item?.name);
          }
          return null;
        });
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  useEffect(() => {
    getAllReportingManegers();
  }, []);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            )}
            {data &&
              data?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row?._id}>
                    {columns &&
                      columns.map((column) => {
                        const value = row && row !== null ? row[column.id] : '';

                        if (column.id === 'address') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              {[
                                row?.currentAddress?.address,
                                row?.currentAddress?.city,
                                row?.currentAddress?.state,
                                row?.currentAddress?.country,
                                row?.currentAddress?.pinCode
                              ]
                                ?.filter((x) => x)
                                .join(', ')}
                            </StyledTableCell>
                          );
                        }

                        if (column.id === 'reportingTo') {
                          return (
                            <StyledTableCell align="left" key={column.id}>
                              {reportingToData}
                            </StyledTableCell>
                          );
                        }
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : ConvertToUpperCase(value)}
                          </StyledTableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default withStyles(styles)(UserDetails);
