import React from 'react';
import { Components } from '../../../utils/material-ui';
import styles from '../styles';
import theme from '../../../utils/theme';
// import BugetEvaluationTable from '../../../components/organism/Table/BugetEvaluationTable';
// import DepartmentBudgetNivo from '../RevenueReport/departmentBudgetNivo';
import BroadCastDetailTable from '../../../components/organism/Table/BroadCastDetailTable';

const { withStyles, Grid, Typography } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const BroadcastReports = ({ classes }) => {
  return (
    <Container direction="column" className={classes.newUser}>
      <Typography className={classes.cardHeading} sx={{ backgroundColor: '#5768BC' }} variant="h6">
        Broadcast Report
      </Typography>
      <Item className={classes.tableContainer1}>
        <p style={{ fontSize: '1rem', marginTop: '8px' }}>
          Communication plays a key role during critical events. Communicate with your employees
          with our advanced CUG Broadcast.
          <li> Two-way communication channel helps ensure which employees need help</li>
          <li>
            Multichannel communication ensures fast and efficient delivery of emergency messages
          </li>
        </p>

        <Item md={12} xs={12} sx={{ ...theme.card, height: 'auto', p: 1, mt: 2 }}>
          <BroadCastDetailTable />
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(BroadcastReports);
