import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import PendingIncidentTable from '../../components/organism/Table/PendingIncidentTable';
import moment from 'moment';
import IncidentTypeCard from './IncidentTypeCard';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import theme from '../../utils/theme';
import ExportToExcel from '../../utils/downloadMergeColumnData';
import ExportTable from './exportTable';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import { sosServices } from '../../config/index';

const { withStyles, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } =
  Components;

const { SearchIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const incidentType = [
  { label: 'Genuine', value: 'genuine' },
  { label: 'End by User', value: 'unknown' }
];

const Incidents = ({ classes }) => {
  const [selectedIncidentType, setIncidenType] = useState('genuine');
  const [counts, setCounts] = useState({});
  const [, dispatch] = useStateValue();
  const [incidentDate, setIncidentDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [incidentToDate, setIncidentToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [informedTo, setInformedTo] = useState('');
  const [exportData, setExportData] = useState([]);
  const [allCities, setAllCities] = useState(['all']);
  const [location, setlocation] = useState('all');
  const [allCorporates, setAllCorporates] = useState([{ label: 'all', id: 'all' }]);
  const [corporate, setCorporate] = useState('all');
  const [sosAdmins, setSosAdmins] = useState([{ fullName: 'all', _id: 'all' }]);
  const [attendedBy, setAttendedBy] = useState('all');
  const [service, setService] = useState('all');

  // const [type, setType] = useState('genuine');
  const [filter, setFilter] = useState('l1');

  const [searchText, setSearchText] = useState('');

  const [refreshPage, setRefreshPage] = useState(false);

  // const types = [
  //   { name: 'Genuine', value: 'genuine' },
  //   { name: 'End by User', value: 'endByUser' },
  // ];

  const filters = [
    { name: 'L1', value: 'l1' },
    { name: 'L1&L2', value: 'l1l2' },
    { name: 'None', value: 'none' }
  ];

  useEffect(() => {
    API_GET(`report-incident/getSosCitiesForCorporate?disableCorporateMatch=true`)
      .then((res) => {
        setAllCities([...allCities, ...res?.data?.cities]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  useEffect(() => {
    API_GET(`corporate/getAllCorporate`)
      .then((res) => {
        const data = res.allCorporates.map((item) => {
          return { label: item.name, id: item._id };
        });
        setAllCorporates([...allCorporates, ...data]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  useEffect(() => {
    API_GET(`report-incident/getSosAdmins`)
      .then((res) => {
        setSosAdmins([...sosAdmins, ...res?.data?.sosAdmins]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  const getCounts = () => {
    API_GET(
      `report-incident/counts?incidentDate=${incidentDate}&incidentToDate=${incidentToDate}&filter=${filter}&pendingFilter=${true}&searchText=${searchText}&corporate=${corporate}`
    )
      .then((res) => {
        setCounts(res.data.counts);
      })
      .catch((err) => {
        err.status === 404
          ? dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: 'Network Error'
            })
          : dispatch({
              type: 'ALERT_OPEN',
              severity: 'error',
              isOpen: true,
              label: err.data.errors[0]?.message ?? 'Oops!!, Please try again'
            });
      });
  };

  const changeCount = () => {
    getCounts();
  };

  useEffect(() => {
    getCounts();
  }, [dispatch, incidentDate, incidentToDate, filter, searchText, refreshPage]);

  const onCardChange = (type) => {
    setInformedTo('');
    setIncidenType(type);
  };

  const onFromDateChange = (date) => {
    setIncidentDate(moment(date.target.value).format('YYYY-MM-DD'));
  };

  const onToDateChange = (date) => {
    setIncidentToDate(moment(date.target.value).format('YYYY-MM-DD'));
  };

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleRefreshPage = () => {
    setRefreshPage(!refreshPage);
  };

  const handleExport = () => {
    API_GET(
      `report-incident/ended?incidentType=${selectedIncidentType}&incidentDate=${incidentDate}&incidentToDate=${incidentToDate}&informedTo=${filter}&pendingFilter=${true}&searchText=${searchText}`
    )
      .then((res) => {
        setExportData(res?.data?.incidents);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
    setTimeout(() => {
      ExportToExcel(
        'SOS_Pending_Data_table',
        'xlsx',
        `Sos_${selectedIncidentType}_(${incidentDate} To ${incidentToDate})) .xlsx`
      );
    }, 300);
  };

  const TableData = () => {
    return (
      <div id="SOS_Pending_Data_table" style={{ display: 'none' }}>
        <ExportTable data={exportData} />
      </div>
    );
  };

  return (
    <Container className={classes.newsPage}>
      <Container direction="row" alignItems="center">
        <Item xs={4}>
          <div className={classes.tablesearchrelative}>
            <SearchIcon className={classes.tablesearchsearchIcon} />
            <input
              type="tablesearch"
              className={classes.tablesearch}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Search by Name or PhoneNumber"
            />
          </div>
        </Item>
        <Item xs={8}>
          <Item sx={{ display: 'flex', gap: '10px' }}>
            <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
              <form noValidate>
                <TextField
                  id="date"
                  label="From Date"
                  type="date"
                  size="small"
                  defaultValue={incidentDate}
                  className={classes.datePicker}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onFromDateChange(e);
                  }}
                />
              </form>
            </Item>
            <Item>
              <form noValidate>
                <TextField
                  id="date"
                  label="To Date"
                  type="date"
                  size="small"
                  defaultValue={incidentToDate}
                  className={classes.datePicker}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    onToDateChange(e);
                  }}
                />
              </form>
            </Item>
          </Item>
        </Item>
      </Container>

      <Container md={12} direction="row" alignItems="center" spacing={2}>
        <Item xs={6} className={classes.incidentTypeContainer}>
          {incidentType.map((item) => (
            <IncidentTypeCard
              data={item}
              count={counts}
              key={item.label}
              current={selectedIncidentType}
              onClick={onCardChange}
            />
          ))}
        </Item>

        {selectedIncidentType === 'genuine' && (
          <Item xs={3}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth="true"
              className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Escalated To</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="organization-select"
                value={filter}
                label="Escalated To"
                onChange={onFilterChange}>
                {filters.map((item) => (
                  <MenuItem key={item.name} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        )}
        <Item xs={2}>
          {TableData()}
          <Button
            style={{
              backgroundColor: 'white',
              color: theme.palette.primary.sosDashboard,
              border: `1px solid ${theme.palette.primary.sosDashboard}`
            }}
            onClick={handleExport}>
            Export
          </Button>
        </Item>
      </Container>

      <Container spacing={1}>
        <Item>
          <FormControl>
            <InputLabel id="demo-simple-select-label"> Corporate </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={corporate}
              sx={{ fontSize: '12px', width: '200px' }}
              label="Corporate"
              onChange={(e) => setCorporate(e.target.value)}>
              {allCorporates?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {ConvertToUpperCase(item.label)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
        <Item>
          <FormControl>
            <InputLabel id="demo-simple-select-label"> Location </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={location}
              sx={{ fontSize: '12px', width: '200px' }}
              label=" Location "
              onChange={(e) => setlocation(e.target.value)}>
              {allCities?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {ConvertToUpperCase(item)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>

        <Item>
          <FormControl>
            <InputLabel id="demo-simple-select-label"> Attended By </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={attendedBy}
              sx={{ fontSize: '12px', width: '200px' }}
              label=" Attended By "
              onChange={(e) => setAttendedBy(e.target.value)}>
              {sosAdmins?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item._id}>
                    {ConvertToUpperCase(item.fullName)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
        <Item ml={1}>
          <FormControl>
            <InputLabel id="demo-simple-select-label"> Service </InputLabel>
            <Select
              variant="outlined"
              size="small"
              value={service}
              sx={{ fontSize: '12px', width: '200px' }}
              label=" Service "
              onChange={(e) => setService(e.target.value)}>
              {['all', ...sosServices].map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {ConvertToUpperCase(item)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Item>
      </Container>

      <Item mt={1} className={classes.incidentTable}>
        <Container>
          <PendingIncidentTable
            selectedType={selectedIncidentType}
            incidentDate={incidentDate}
            incidentToDate={incidentToDate}
            informedTo={informedTo}
            filter={filter}
            searchText={searchText}
            changeCount={changeCount}
            refreshPage={handleRefreshPage}
            corporate={corporate}
            city={location}
            attendedBy={attendedBy}
            service={service}
          />
        </Container>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Incidents);
