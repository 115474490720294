import React from 'react';
import { Components } from '../../../utils/material-ui';
import nominatedcss from './nominatedcss';
import theme from '../../../utils/theme';

const { withStyles, Card, Typography, Grid } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const selectedCardColor = {
  color: '#FFFFFF',
  fontFamily: theme.palette.font.fontFamily,
  width: '160px',
  padding: theme.spacing(0.5),
  cursor: 'pointer',
  fontWeight: '600'
};

const unselectedCardColor = {
  cursor: 'pointer',
  color: 'black',
  backgroundColor: 'white',
  fontFamily: theme.palette.font.fontFamily,
  width: '160px',
  padding: theme.spacing(0.5),
  fontWeight: '600'
};

function CountsCard(props) {
  const { item, onClick, selectedCard } = props;

  return (
    <Card
      onClick={() => onClick(item)}
      style={
        selectedCard.value === item.value
          ? { ...selectedCardColor, backgroundColor: theme.palette.primary.tableHeadingColor }
          : unselectedCardColor
      }>
      <Container>
        <Item
          xs
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Typography sx={{ fontWeight: '500' }} align="center">
            {item.label}
          </Typography>
        </Item>
      </Container>
    </Card>
  );
}

export default withStyles(nominatedcss)(CountsCard);
