import React, { useState } from 'react';
import { Components } from '../../../utils/material-ui';
import styles from './styles';
import theme from '../../../utils/theme';
import DoctorProfile from '../../Appointments/DoctorProfile';
import DeletedDoctor from '../../Appointments/DeletedDoctor';

const { withStyles, Grid, Paper } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Appointment = ({ classes }) => {
  const cards = [
    { value: 'doctorProfile', label: 'Doctors Profile', table: DoctorProfile },
    { value: 'deleteDoctor', label: 'Deleted Doctors', table: DeletedDoctor }
  ];

  const [selectedCard, setSelectedCard] = useState(cards[0]);

  const selected = {
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '150px',
    justifyContent: 'center',
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '20px',
    fontSize: '15px',
    fontWeight: '600'
  };
  const unselected = {
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '150px',
    justifyContent: 'center',
    color: theme.typography.tableHeading.color,
    borderRadius: '20px',
    border: `1px solid #DCDCDC`,
    fontSize: '15px'
    // fontWeight: '600'
  };

  return (
    <Container className={classes.mainSubConatiner} mt={-3}>
      <Item md={12} xs={12}>
        <Paper
          sx={{
            ...theme.card,
            minHeight: '50px',
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            p: '0px 20px',
            flexWrap: 'wrap'
          }}>
          {cards.map((item) => {
            return (
              <Item
                onClick={() => setSelectedCard(item)}
                sx={item.value === selectedCard.value ? { ...selected } : { ...unselected }}>
                {item.label}
              </Item>
            );
          })}
        </Paper>
        <Item xs={12} md={12}>
          <selectedCard.table></selectedCard.table>
        </Item>
      </Item>
    </Container>
  );
};

export default withStyles(styles)(Appointment);
