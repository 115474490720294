import React from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import theme from '../../utils/theme';
import HappinessActivity from './happinessActivity';
import FitenessActivity from './fitenessActivity';
import HealthContent from './healthContent';
import UserEngagement from './userEngagement';
import { useStateValue } from '../../utils/store';

const { withStyles, Grid, Typography, Paper } = Components;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const Happiness = ({ classes }) => {
  const [store] = useStateValue();
  const renderData =
    store?.corporateId === theme.corporate.novo ||
    store?.corporateId === theme.corporate.identityDigital
      ? [
          {
            name: 'Fitness Activity',
            component: FitenessActivity
          },

          {
            name: 'User Engagement',
            component: UserEngagement
          }
        ]
      : [
          {
            name: 'Happiness Activity',
            component: HappinessActivity
          },
          {
            name: 'Fitness Activity',
            component: FitenessActivity
          },
          {
            name: 'Happiness Library Content Consumption',
            component: HealthContent
          },
          {
            name: 'User Engagement',
            component: UserEngagement
          }
        ];

  return (
    <Container className={classes.mainContainer}>
      {renderData?.map((item, index) => {
        return (
          <Item md={12} xs={12} key={index} mt={index === 0 ? 1 : 3}>
            <Typography sx={{ fontSize: '1.2rem', fontWeight: '600' }} m={1}>
              {item.name}
            </Typography>
            <Paper
              sx={{
                height: 'auto',
                ...theme.card,
                border: '8px',
                p: 1.5,
                width: '100%',
                pb: 3
              }}>
              <item.component></item.component>
            </Paper>
          </Item>
        );
      })}
    </Container>
  );
};

export default withStyles(styles)(Happiness);
