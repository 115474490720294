import { getDateTime } from '../../utils/moment';

const ExportTable = ({ data, selectedIncidentType }) => {
  const getAllDates = (item) => {
    if (selectedIncidentType === 'fake') {
      return '-';
    } else {
      return getDateTime(item);
    }
  };
  return (
    <table border="1">
      <thead>
        <tr>
          <th>SOS ID</th>
          <th>Company Name</th>
          <th>Event Time</th>
          <th>Attended At</th>
          <th>Attended By</th>
          <th>Closure Time</th>
          <th>Closed By</th>
          <th>Type</th>
          <th>Comments</th>
          {/* <th>Close Comment</th> */}
          <th>Name</th>
          <th>Gender</th>
          <th>Phone Number</th>
          <th>Role</th>
          <th>Internal Id</th>
          <th>City</th>
          <th>Address</th>
          <th>Escalated To</th>
          <th>Category</th>
          <th>Service</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, reIndex) => {
          const resEmer = [];
          return (
            <>
              <tr key={reIndex}>
                <td rowSpan={resEmer?.length}>{item?.incidentAutoId || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.corporateName || '-'}</td>
                <td rowSpan={resEmer?.length}> {getDateTime(item?.incomingTime) || '-'}</td>
                <td rowSpan={resEmer?.length}>{getDateTime(item?.closureTime) || '-'}</td>
                <td rowSpan={resEmer?.length}> {item?.attendedBy || '-'}</td>
                <td rowSpan={resEmer?.length}> {getAllDates(item?.closureTime) || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.closedBy || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.type || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.comment || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.name || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.gender || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.phoneNumber || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.role || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.internalId || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.city || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.address || '-'}</td>
                <td rowSpan={resEmer?.length}> {item?.informedTo || '-'}</td>
                <td rowSpan={resEmer?.length}>{item?.category || '-'}</td>
                <td rowSpan={resEmer?.length}>
                  {Array.isArray(item?.service) && item?.service.join(', ')}
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExportTable;

// <tr>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// {/* <th></th> */}
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// <th></th>
// </tr>
