import React, { useState } from 'react';
import spot from './spot.svg';
import { Components, Icons } from '../../utils/material-ui';
import styles from './styles';
import { useStateValue } from '../../utils/store';
import AcknowledgementForm from '../../components/organism/Modal/AcknowledgementForm';
import { API_PATCH } from '../../utils/api';
import Dialog from '../../components/atom/Dialog';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';
import theme from '../../utils/theme';
import OnlineIcon from './OnlineIcon';

const { withStyles, Typography, Button, Grid } = Components;
const {
  RoomIcon,
  Phone,
  AccountCircleIcon,
  AccountBoxIcon,
  ApartmentIcon,
  PersonIcon,
  ContactEmergencyIcon
} = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function LocationCard(props) {
  const { data, classes } = props;
  const [store, dispatch] = useStateValue();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const showInMapClicked = (lat, lng) => {
    window.open(`https://maps.google.com?q=${lat},${lng}`);
  };

  function acknowledge() {
    API_PATCH(`report-incident/acknowledge`, { incidentId: data.id })
      .then((res) => {
        setModalOpen(!isModalOpen);
        store.socketIO.emit('acknowledged', { incidentId: data.id });
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: err[0]?.message || 'Oops!!, Please try again'
        });
      });
  }

  return (
    <div className={classes.flex}>
      <img style={{ transform: 'translate(-50%, -50%)' }} src={spot} alt="spot"></img>
      <div className={classes.userCard}>
        <Item className={classes.cardHeading}>
          <Item sx={{ display: 'flex', alignItems: 'center' }}>
            <OnlineIcon offlineSos={data.offlineSos}></OnlineIcon>
            <Typography ml={1} variant="h6">
              Incident
            </Typography>
          </Item>

          <Item>{data.timer}</Item>
        </Item>

        <Container spacing={1} className={classes.cardContent} direction="column">
          <Item>
            <Item sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Item className={classes.logoTextContainer}>
                <Item>
                  <AccountCircleIcon className={classes.icon}></AccountCircleIcon>
                </Item>
                <Item mt="-2px">
                  <Typography variant="h6">{`${data?.name}, ${ConvertToUpperCase(
                    data.gender
                  )}`}</Typography>
                </Item>
              </Item>

              <Item>
                <Container spacing={1}>
                  <Item>
                    <AccountBoxIcon className={classes.icon} />
                  </Item>
                  <Item>
                    <Typography>{data.internalId}</Typography>
                  </Item>
                </Container>
              </Item>
            </Item>
          </Item>

          <Item>
            <Item sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Item className={classes.logoTextContainer}>
                <Item>
                  <PersonIcon className={classes.icon} />
                </Item>
                <Item>
                  <Typography>{ConvertToUpperCase(data.role)}</Typography>
                </Item>
              </Item>
              {data?.parentData?.fullName && (
                <Item className={classes.logoTextContainer}>
                  <Item>
                    <ContactEmergencyIcon className={classes.icon} />
                  </Item>
                  <Item>
                    <Typography>{`${ConvertToUpperCase(data?.parentData?.fullName)}, ${
                      data?.parentData?.internalId
                    }`}</Typography>
                  </Item>
                </Item>
              )}
            </Item>
          </Item>

          <Item>
            <Container
              onClick={() => showInMapClicked(data.lat, data.lng)}
              spacing={1}
              wrap="nowrap">
              <Item>
                <RoomIcon className={classes.icon}></RoomIcon>
              </Item>
              <Item>
                <Typography variant="caption" sx={{ fontSize: '14px' }}>
                  {data?.address}
                </Typography>
              </Item>
            </Container>
          </Item>
          <Item>
            <Container spacing={1} alignItems="center">
              <Item>
                <Phone fontSize="small" className={classes.icon}></Phone>
              </Item>
              <Item>
                <Typography className={classes.phoneNumber}>{data?.number}</Typography>
              </Item>
            </Container>
          </Item>
          <Item>
            <Container spacing={1} alignItems="center">
              <Item>
                <ApartmentIcon className={classes.icon}></ApartmentIcon>
              </Item>
              <Item>
                <Typography>{data?.corporateName}</Typography>
              </Item>
            </Container>
          </Item>
          <Item sx={{ display: 'flex' }}>
            <Button
              sx={{ diplay: 'inline-flex', ml: 'auto' }}
              onClick={acknowledge}
              variant="contained"
              style={{ color: 'white', backgroundColor: theme.palette.primary.sosDashboard }}>
              ACKNOWLEDGE
            </Button>
          </Item>
        </Container>
        <Dialog
          children={<AcknowledgementForm handleClose={handleClose} data={data} />}
          isOpen={isModalOpen}></Dialog>
      </div>
    </div>
  );
}

export default withStyles(styles)(LocationCard);
