import { Components } from './material-ui';

const { createTheme } = Components;

const color = JSON.parse(localStorage.getItem('color')) || 'FF6760';
const getLightColor = JSON.parse(localStorage.getItem('lightcolor')) || 'FFD1CF';

const globalColor = color;

const theme = createTheme({
  tableRowColor: '#FF67601A',
  headingBgColor: `#${getLightColor}`,
  customTextFiled: {
    backgroundColor: '#FEFEFE',
    borderRadius: '10px'
    // boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    // border: 'none'
  },
  tableBorder: {
    borderRadius: '8px'
  },

  typography: {
    tableHeading: {
      fontSize: '15px',
      fontWeight: '600',
      padding: '15px',
      color: '#565555',
      heading: '#4545454',
      unSelect: '#9F9F9F',
      lightColor: '#818181'
    }
  },
  card: {
    borderRadius: '10px',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    margin: 'auto',
    backgroundColor: '#FFFFFF'
  },
  card1: {
    borderRadius: '10px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.20)',
    margin: 'auto',
    backgroundColor: '#FFFFFF'
  },

  button: {
    noAction: {
      backgroundColor: 'white',
      color: `#${globalColor}`,
      width: '90px',
      border: `1px solid #${globalColor}`,
      height: '25px',
      fontSize: '12px',
      textTransform: 'none'
    },
    forward: {
      backgroundColor: '#FFCAC8',
      color: `#${globalColor}`,
      width: '90px',
      fontSize: '11px',
      padding: '4px',
      fontWeight: '600',
      borderRadius: '8px',
      textTransform: 'none'
    },
    initiate: {
      backgroundColor: `#${globalColor}`,
      color: '#FFFFFF',
      width: '90px',
      fontSize: '11px',
      padding: '4px',
      fontWeight: '600',
      borderRadius: '8px',
      textTransform: 'none'
    },
    trash: {
      backgroundColor: '#FFFFFF',
      color: `#${globalColor}`,
      width: '90px',
      fontSize: '11px',
      fontWeight: '600',
      padding: '4px',
      border: `1px solid #${globalColor}`,
      borderRadius: '8px',
      textTransform: 'none'
    },
    submit: {
      backgroundColor: `#${globalColor}`,
      color: 'white',
      height: '37px',
      fontWeight: '600',
      padding: '5px 10px',
      textTransform: 'none',
      width: 'auto',
      minWidth: '125px',
      fontSize: '16px'
    },
    cancel: {
      backgroundColor: '#FFFFFF',
      color: `#${globalColor}`,
      border: `1px solid #${globalColor}`,
      height: '37px',
      fontWeight: '600',
      padding: '5px 10px',
      textTransform: 'none',
      width: 'auto',
      minWidth: '125px',

      fontSize: '16px'
    },
    tableSeverityButton: {
      height: '25px',
      cursor: 'auto',
      width: '100px',
      padding: '10px 15px',
      borderRadius: '10px',
      fontSize: '12px',
      fontWeight: '600',
      textTransform: 'none'
    },
    filterButton: {
      color: '#9F9F9F',
      border: '1px solid #9F9F9F',
      // backgroundColor: 'white',
      width: 'auto',
      minWidth: '85px',
      fontWeight: '500',
      fontSize: '15.8px',
      borderRadius: '4px',
      padding: '4.5px',
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: `#${globalColor}`,
      textSecondColor: '#565555',
      secondarymain: '#FFFFFF',
      sosDashboard: '#2e17b4',
      textColor: 'black',
      secondtheme: `#${globalColor}`,
      tableHeadingColor: `#${globalColor}`,
      textTableColor: '#01386F',
      maintextColor: `#${globalColor}`
    },
    common: {
      gray90: '#e5e5e5',
      gray98: '#fafafa',
      green: '#00c453',
      orange: '#c47900',
      red: '#ff0000',
      white: '#FFFFFF'
      // hover: 'rgb(158, 158, 247, 0.1)'
    },
    font: {
      fontWeightSmallText: '500',
      fontWeightHead: '500',
      fontWeightText: '500',
      tableHeadFontSize: '0.9rem'
    }
  },
  countsCard: {
    selectedCardColor: {
      color: '#FFFFFF',
      backgroundColor: `#${globalColor}`,
      borderRadius: '8px',
      justifyContent: 'center',
      boxShadow: '0px 0px 4px 0px #00000040',
      fontSize: '15px'
    },

    unselectedCardColor: {
      cursor: 'pointer',
      color: 'black',
      backgroundColor: '#FFFFFF',
      justifyContent: 'center',
      borderRadius: '8px',
      boxShadow: '0px 0px 4px 0px #00000040',
      fontSize: '15px'
    }
  },
  corporate: {
    mliCorporateId: '63995e788cacea60d59a4398',
    novo: '64a535a32d897d4aa73400db',
    identityDigital: '64d9a6cf454eebb38d7867b3'
  }
});

export default theme;
