import { getDate } from '../../../../../utils/moment';
const ExportTable = ({ data }) => {
  return (
    <table border="1">
      <thead>
        <tr>
          <th>Group ID</th>
          <th>Group Name</th>
          <th>Country</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Pax</th>
          <th>Group Type</th>
          <th>Number Of Nominated Agent/Employee</th>
          <th colSpan="5" align="center">
            Submitted
          </th>
          <th colSpan="5" align="center">
            Pending
          </th>
          <th colSpan="2" align="center">
            Expired
          </th>
          <th colSpan="2" align="center">
            To Be Expired
          </th>
        </tr>
        <tr>
          <th> </th>
          <th></th>
          <th></th>
          <th> </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>Aadhaar</th>
          <th>Pan Card</th>
          <th>Passport</th>
          <th>Visa</th>
          <th>Vaccination</th>
          <th>Aadhaar</th>
          <th>Pan Card</th>
          <th>Passport</th>
          <th>Visa</th>
          <th>Vaccination</th>
          <th>Passport</th>
          <th>Visa</th>
          <th>Passport</th>
          <th>Visa</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, reIndex) => {
          return (
            <tr key={reIndex}>
              <td>{item?.groupId}</td>
              <td>{item?.groupName}</td>
              <td>{item?.Country}</td>
              <td>{item?.StartDate ? getDate(item?.StartDate) : '-'}</td>
              <td>{item?.EndDate ? getDate(item?.EndDate) : '-'}</td>
              <td>{item?.paxNum}</td>
              <td>{item?.packageType}</td>
              <td>{item?.totalNominated}</td>
              <td>{item?.sumbmittedTotal?.Aadhaar}</td>
              <td>{item?.sumbmittedTotal?.PanCard}</td>
              <td>{item?.sumbmittedTotal?.Passport}</td>
              <td>{item?.sumbmittedTotal?.Visa}</td>
              <td>{item?.sumbmittedTotal?.Vaccination}</td>
              <td>{item?.pendingTotal?.Aadhaar}</td>
              <td>{item?.pendingTotal?.PanCard}</td>
              <td>{item?.pendingTotal?.Passport}</td>
              <td>{item?.pendingTotal?.Visa}</td>
              <td>{item?.pendingTotal?.Vaccination}</td>
              <td>{item?.expiredTotal?.Passport}</td>
              <td>{item?.expiredTotal?.Visa}</td>
              <td>{item?.toBeExpiredTotal?.Passport}</td>
              <td>{item?.toBeExpiredTotal?.Visa}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExportTable;
