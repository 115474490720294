import React, { useState } from 'react';
import styled from 'styled-components';
import { useLayer, Arrow } from 'react-laag';

const InfoBox = styled.div`
  padding: 1em;
  box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
`;

const StyledMarker = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 0;
  }
`;

export default function Marker({ name, category }) {
  const [isOpen, setOpen] = useState(false);
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false)
  });

  return (
    <React.Fragment>
      <StyledMarker {...triggerProps} onClick={() => setOpen((prev) => !prev)}>
        <img
          alt="img"
          width="30px"
          height="30px"
          src={require(`../../../Assets/RiskIcons/${category}.svg`).default}
        />
      </StyledMarker>

      {isOpen &&
        renderLayer(
          <InfoBox {...layerProps}>
            {name}
            <Arrow {...arrowProps} />
          </InfoBox>
        )}
    </React.Fragment>
  );
}
