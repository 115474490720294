/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import AssignSafetyDistribution from '../../components/organism/Table/IncidentDistribuition/AssignSafetyDistribution';
import { Components, Icons } from '../../utils/material-ui';
import theme from '../../utils/theme';
import PieChartTable from './assignedPieChart';
import styles from './styles';
import { useStateValue } from '../../utils/store';
import FilterCategory from './FilterCategory';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import IndiviualBarChart from './IndiviualUserBarChart';
import Chip from '@mui/material/Chip';
import ConvertToUpperCase from '../../utils/ConvertToUpperCase';

const {
  withStyles,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  FormGroup,
  Checkbox,
  InputLabel,
  Typography,
  Card
} = Components;
const { LinkIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const priorityArray = [
  { name: 'All', value: 0 },
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
  { name: '7', value: 7 },
  { name: '8', value: 8 },
  { name: '9', value: 9 },
  { name: '10', value: 10 }
];

const AssignedDistribution = ({
  classes,
  isIncident,
  selectedFromDate,
  selectedToDate,
  search,
  chat,
  paramId
}) => {
  const [, dispatch] = useStateValue();
  const [mediaChecked, setMediaChecked] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [outgoing, setOutgoing] = React.useState(false);
  const [delay, setDelay] = React.useState(false);
  const [severity, setSeverity] = useState(0);
  const [offices, setOffices] = React.useState([]);
  const [selectedOffice, setSelectedOffice] = useState('');
  const [selectedFilterCategory, setSelectedFilterCategory] = useState('');
  const [personName, setPersonName] = React.useState([]);

  const handleSelectChange = (value) => {
    if (value === null) {
      setSelectedOffice('');
    } else {
      setSelectedOffice(value?._id);
    }
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  function getStyles(name, personName, theme) {
    return {
      backgroundColor: personName.indexOf(name) === -1 ? 'white' : theme.palette.primary.main
    };
  }

  const getAllOffices = () => {
    API_GET('incident/getAllOffices')
      .then((res) => {
        setOffices(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getAllOffices();
  }, []);
  const handleSelectedCategory = (value) => {
    setSelectedFilterCategory(value);
  };

  return (
    <Container direction={'row'}>
      <Container spacing={1}>
        <Item md={12} xs={12}>
          <Paper style={theme.card}>
            {!isIncident ? (
              <Item sx={{ display: 'flex', padding: '5px' }}>
                <Item xs>
                  <Item
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    <div>
                      <LinkIcon sx={{ fontSize: '16px', mr: 0.5, mb: -0.3 }} />
                    </div>
                    <div>Safety Issue Summary Severity</div>
                  </Item>
                  <Item sx={{ display: 'flex' }}>
                    <div>
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={severity}
                          sx={{ width: '80px' }}
                          className={classes.select}
                          onChange={(e) => setSeverity(e.target.value)}>
                          {priorityArray?.map((item, index) => {
                            return (
                              <MenuItem value={item?.value} key={index}>
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    {/*  <div>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkboxText}
                              checked={mediaChecked}
                              onChange={(e) => setMediaChecked(e.target.checked)}
                              color="info"
                              size="small"
                            />
                          }
                          label={<span style={{ fontSize: '0.9vw' }}>Media</span>}
                        />
                      </FormGroup>
                        </div> */}
                  </Item>
                </Item>
                <Item xs>
                  <div style={{ fontWeight: 'bold', fontSize: '15px' }}>Status</div>
                  <div>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkboxText}
                            checked={completed}
                            onChange={(e) => setCompleted(e.target.checked)}
                            color="info"
                            size="small"
                            fontSize="14px"
                          />
                        }
                        label={<span style={{ fontSize: '12px' }}>Completed</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkboxText}
                            checked={pending}
                            onChange={(e) => setPending(e.target.checked)}
                            color="info"
                            size="small"
                            fontSize="14px"
                          />
                        }
                        label={<span style={{ fontSize: '12px' }}>Pending</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkboxText}
                            checked={outgoing}
                            onChange={(e) => setOutgoing(e.target.checked)}
                            color="info"
                            size="small"
                            fontSize="14px"
                          />
                        }
                        label={<span style={{ fontSize: '12px' }}>Ongoing</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkboxText}
                            checked={delay}
                            onChange={(e) => setDelay(e.target.checked)}
                            color="info"
                            size="small"
                            fontSize="14px"
                          />
                        }
                        label={<span style={{ fontSize: '12px' }}>Delay</span>}
                      />
                    </FormGroup>
                  </div>
                </Item>
                <Item className={classes.filterConatiner3}>
                  <FormControl style={{ width: '300px' }}>
                    <InputLabel id="demo-mutiple-chip-label">Location</InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      variant="outlined"
                      value={personName}
                      // required="true"
                      label="location"
                      onChange={handleChange}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value, index) => (
                            <Chip key={index} label={value.name} />
                          ))}
                        </div>
                      )}>
                      {offices.map((name, index) => (
                        <MenuItem
                          key={index}
                          value={name}
                          style={getStyles(name, personName, theme)}>
                          {`${name.name}, ${ConvertToUpperCase(name.city)}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Item>
              </Item>
            ) : (
              <FilterCategory handleSelectedCategory={handleSelectedCategory} />
            )}
          </Paper>
        </Item>

        <Item md={12} mt={1} xs={12} style={{ width: '500px' }}>
          <Card sx={{ ...theme.card, borderRadius: '10px' }}>
            <AssignSafetyDistribution
              severity={severity}
              selectedOffice={personName}
              mediaChecked={mediaChecked}
              completed={completed}
              pending={pending}
              outgoing={outgoing}
              delay={delay}
              isIncident={isIncident}
              selectedFilterCategory={selectedFilterCategory}
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              search={search}
              chat={chat}
              paramId={paramId}
            />
          </Card>
        </Item>
      </Container>
      <Container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Item md={12} xs={12} lg={4.5} mt={2}>
          <Paper
            sx={{
              height: '250px',
              ...theme.card
            }}>
            <Item sx={{ height: '30px', width: '100%' }}>
              <Typography sx={theme.typography.tableHeading}>
                {`Overall status Distribution of ${
                  isIncident ? 'External Risk' : 'Internal Risk'
                } `}
              </Typography>
            </Item>
            <Item sx={{ height: '210px' }}>
              <PieChartTable
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                isIncident={isIncident}
              />
            </Item>
          </Paper>
        </Item>

        <Item md={12} lg={7.5} xs={12} mt={2}>
          <Paper
            sx={{
              height: '250px',
              ...theme.card
            }}>
            <Item sx={{ height: '30px', width: '100%' }}>
              <Typography sx={theme.typography.tableHeading}>
                {`Individual Status Distribution of ${
                  isIncident ? 'External Risk' : 'Internal Risk'
                } `}
              </Typography>
            </Item>
            <Item sx={{ height: '210px' }}>
              <IndiviualBarChart
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                isIncident={isIncident}
              />
            </Item>
          </Paper>
        </Item>
      </Container>
    </Container>
  );
};

export default withStyles(styles)(AssignedDistribution);
