import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Components } from '../../../../utils/material-ui';
import columns from './head';
import { StyledTableCell } from './StyledTableCell';
import theme from '../../../../utils/theme';
import { getDate, getDateTime } from '../../../../utils/moment';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import tableColor from '../../../../utils/tableColor';

const {
  withStyles,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  // TablePagination,
  Grid,
  Button,
  TableRow,
  TableCell
} = Components;

const Item = (props) => <Grid item {...props} />;
const EmployeeInfo = ({ classes, data, handleClose, newsId, isIncident }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const [totalIncidents, settotalIncidents] = React.useState(0);
  // // const [Modal, setModal] = useState(false);
  // // const [selectedRow, setSelectedRow] = useState({});

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  useEffect(() => {
    newsId &&
      API_GET(
        `incident/getOverallForwardHistory?incidentId=${newsId}&historyType=${
          isIncident ? 'alert' : 'incident'
        }`
      )
        .then((res) => {
          // settotalIncidents(res?.alertCount);
          setRows(res?.data);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  }, [dispatch, newsId, isIncident]);

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows == null || rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {rows && rows !== null
              ? rows?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row?._id}
                      style={{ backgroundColor: tableColor(index) }}>
                      {columns &&
                        columns.map((column) => {
                          const value = row && row !== null ? row[column.id] : '';

                          if (column.id === 'proposedDate') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row && row !== null
                                  ? row?.proposedDate
                                    ? getDate(row?.proposedDate)
                                    : '-'
                                  : ''}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'completionDate') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {getDateTime(row?.localCompletionDate) || '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'forwardedOn') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row && row !== null
                                  ? row?.forwardedOn
                                    ? getDate(row?.forwardedOn)
                                    : '-'
                                  : ''}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'totalDays') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row && row !== null ? (row?.totalDays ? row?.totalDays : 0) : ''}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'planDays') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row && row !== null ? (row?.planDays ? row?.planDays : 0) : ''}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'extraDays') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row && row !== null ? (row?.extraDays ? row?.extraDays : 0) : ''}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'status') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.status === 'forwarded' ? 'pending' : row?.status || '--'}
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })
              : ''}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalIncidents && totalIncidents ? totalIncidents : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}

      <Item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1 }}>
        <Button
          size="small"
          onClick={() => handleClose()}
          style={{
            display: 'flex',
            backgroundColor: theme.palette.primary.tableHeadingColor,
            color: '#FFFFFF',
            fontSize: '12px'
          }}>
          Close
        </Button>
      </Item>
    </Card>
  );
};

export default withStyles(styles)(EmployeeInfo);
