const styles = (theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(2),
      justifyContent: 'space-between'
    },
    tableContainer: {
      width: '100%',
      height: 'auto',
      padding: theme.spacing(4, 3)
    },
    subFormContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px',
      flexWrap: 'wrap'
    },

    label: {
      color: '#868686',
      fontSize: '1.1rem',
      fontWeight: '600'
    },
    formContainer: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    subContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px'
    },
    reciepentContainer: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      height: 'auto',
      display: 'flex',
      gap: '10px',
      overflow: 'auto',
      flexWrap: 'wrap',
      padding: theme.spacing(1)
    },
    reciepentCardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      boxShadow: ' 0px 0px 4px rgba(0, 0, 0, 0.25)',
      minWidth: '150px',
      height: '35px',
      alignItem: 'center',
      padding: theme.spacing(0.5),
      color: theme.palette.primary.main
    },
    saveContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItem: 'center',
      flexWrap: 'wrap'
    },
    subButtons: {
      padding: theme.spacing(1),
      textAlign: 'center',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
      fontSize: '1.1rem',
      fontWeight: '600',
      color: '#868686'
    },
    previewContainer: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      height: '100%'
    },
    groupContainer: {
      maxHeight: '65vh',
      height: '65vh',
      overflow: 'auto'
    },
    title: {
      color: '#868686',
      fontWeight: '500',
      fontSize: '1.2rem'
    },
    text: {
      color: '#868686',
      fontWeight: '600',
      fontSize: '0.9rem'
    },
    indiviualSelectContainer: {
      height: 'auto',
      maxHeight: '180px',
      minHeight: '180px',
      overflow: 'auto',
      padding: theme.spacing(0.5),
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      border: '0.2px solid lightGrey'
    },
    groupSelectContainer: {
      height: 'auto',
      maxHeight: '80px',
      minHeight: '80px',
      overflow: 'auto',
      padding: theme.spacing(0.5),
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      border: '0.2px solid lightGrey'
    },
    smallContainer: {
      boxShadow: '0px 0px 2.81136px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '8px',
      alignItems: 'center',
      color: theme.palette.primary.main,
      minWidth: '100px',
      padding: theme.spacing(0.5),
      borderRadius: '10px'
    },
    previewData: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap'
    }
  };
};

export default styles;
