import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../../utils/material-ui';
import theme from '../../../utils/theme';
import styles from '../styles';
import downloadDataToExcelFile from '../../../utils/downloadDataToExcelFile';
import { API_GET, API_HANDLE_ERROR } from '../../../utils/api';
import { useStateValue } from '../../../utils/store';

const { withStyles, Typography, Grid } = Components;
const { FileDownloadIcon } = Icons;

const Item = (props) => <Grid item {...props} />;

// const data = [
//   {
//     name: 'Human Safety',
//     value: 45,
//     color: '#3B9DF7',
//     backgroundColor: '#EBF5FE'
//   },
//   {
//     name: 'Repair & Maintenance',
//     value: 30,
//     color: '#FCB94D',
//     backgroundColor: '#FEF4E3'
//   },
//   {
//     name: 'Procurement',
//     value: 35,
//     color: '#ED3E3C',
//     backgroundColor: '#FEE5E8'
//   },
//   {
//     name: 'Infrastructure',
//     value: 55,
//     color: '#34B53A',
//     backgroundColor: '#E2FBD7'
//   },
//   {
//     name: 'House Keeping',
//     value: 75,
//     color: '#92519D',
//     backgroundColor: '#92519D33'
//   },
//   {
//     name: 'Logistic',
//     value: 20,
//     color: '#FF6B84',
//     backgroundColor: '#FF6B8433'
//   }
// ];

const Progressbar = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;
    API_GET(
      `reports/depWiseIncidents?fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultLocation}`
    )
      .then((res) => {
        setRows(res?.data);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, selectedFromDate, selectedToDate, location]);

  const handleExport = () => {
    const resultLocation = location === 'all' ? '' : `officeId=${location}`;

    API_GET(
      `reports/downloadDeptWiseIncidents?fromDate=${selectedFromDate}&toDate=${selectedToDate}&${resultLocation}`
    )
      .then((res) => {
        if (res?.data.length === 0) {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'error',
            isOpen: true,
            label: 'No data found for download'
          });
        } else {
          downloadDataToExcelFile(
            res?.data,
            `Dept wise Incident Analysis_location (${selectedFromDate} To ${selectedToDate})`
          );
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <React.Fragment>
      <Item className={classes.headingContainer}>
        <Item>
          <Typography sx={{ ...theme.typography.tableHeading, fontSize: '14px' }}>
            Department wise Incident
          </Typography>
        </Item>
        <Item>
          <FileDownloadIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={handleExport} />
        </Item>
      </Item>

      <Item sx={{ height: '230px', maxHeight: '230px', overflow: 'auto' }}>
        {rows?.map((item, index) => {
          return (
            <Item key={index}>
              <Item
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ fontSize: '13px' }}>{item?.name}</p>
                <p style={{ color: item?.color, fontSize: '13px' }}>{`${
                  item?.percentage === null ? 0 : item?.percentage
                }%`}</p>
              </Item>
              <Item
                style={{
                  height: '8px',
                  borderRadius: '5px',
                  width: '100%',
                  backgroundColor: item?.backgroundColor
                }}>
                <Item
                  style={{
                    height: '100%',
                    width: `${item?.percentage === null ? 0 : item?.percentage}%`,
                    backgroundColor: item?.color,
                    borderRadius: '5px'
                  }}></Item>
              </Item>
            </Item>
          );
        })}
      </Item>
    </React.Fragment>
  );
};

export default withStyles(styles)(Progressbar);
