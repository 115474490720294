const head = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },

  {
    id: 'ticketNumber',
    label: 'Ticket No',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'title',
    label: 'Incident',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'suspendedBy',
    label: 'Suspended By',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'designation',
    label: 'Designation',
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'emergencySeverityLevel',
    label: 'Severity',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'center'
  },
  {
    id: 'suspendedRemark',
    label: 'Remarks',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    align: 'center'
  },

  {
    id: 'action',
    label: 'Action',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  }
];

export default head;
