import React, { useEffect, useState } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import styles from './styles';
import Dialog from '../../components/atom/Dialog';
import AddCorporate from '../../components/organism/Modal/AddCorporate';
import theme from '../../utils/theme';

const { withStyles, Grid, Paper, Typography } = Components;

const { MoreVertIcon, AddIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const SuperAdminDashboard = ({ classes }) => {
  const [corporates, setCorporates] = useState([]);
  const [, dispatch] = useStateValue();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flow, setFlow] = useState();
  const [selectedCorporate, setSelectedCorporate] = useState();

  useEffect(() => {
    API_GET(`corporate/getAllCorporate`)
      .then((res) => {
        setCorporates(res.allCorporates);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  const handleViewDetails = (corporate) => {
    setFlow('viewDetails');
    setSelectedCorporate(corporate);
    setIsModalOpen(true);
  };

  const Card = ({ item }) => {
    if (item.name === 'Add Corporate') {
      return (
        <Paper className={classes.addCompanyCard}>
          {
            <Container direction="column" justifyContent="center" alignItems="center">
              <Item mt={4}>
                <AddIcon style={{ color: `${theme.palette.primary.main}` }} fontSize="large" />
              </Item>

              <Item mt={1} style={{ fontWeight: 'bold', color: `${theme.palette.primary.main}` }}>
                {item.name}
              </Item>
            </Container>
          }
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.card}>
          {
            <Container spacing={4} style={{ height: '85px' }}>
              <Item xs={8} style={{ fontWeight: 'bold' }}>
                {item.name}
              </Item>
              <Item xs={4}>
                <MoreVertIcon />
              </Item>
            </Container>
          }
          <Container>
            <Item onClick={() => handleViewDetails(item._id)} style={{ fontSize: '13px' }}>
              View Details
            </Item>
          </Container>
        </Paper>
      );
    }
  };

  const handleAddCompany = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setFlow('');
  };

  return (
    <Container direction="column">
      <Item>
        <Typography variant="h3" ml={2}>
          Corporates
        </Typography>
      </Item>
      <Item mt={3}>
        <Container>
          {[
            <Item onClick={handleAddCompany}>
              <Card item={{ name: 'Add Corporate' }} />
            </Item>,
            ...corporates.map((item) => <Card key={item.name} item={item} />)
          ]}
        </Container>
      </Item>
      <Dialog
        isOpen={isModalOpen}
        children={
          <AddCorporate
            handleClose={handleClose}
            flow={flow}
            selectedCorporate={selectedCorporate}
            // selectedFilterCategory={selectedFilterCategory}
          />
        }></Dialog>
    </Container>
  );
};

export default withStyles(styles)(SuperAdminDashboard);
