import React, { useState, useEffect } from 'react';
import styles from './styles';
import { StyledTableCell } from './StyledTableCell';
import columns from './head';
import { Components } from '../../../../utils/material-ui';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { Typography } from '@mui/material';
import theme from '../../../../utils/theme';
import { useStateValue } from '../../../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../../../utils/api';
import { getDateTime } from '../../../../utils/moment';

const {
  withStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  Grid,
  Card
} = Components;

const Item = (props) => <Grid item {...props} />;

const SosTable = ({ classes, selectedFromDate, selectedToDate, location }) => {
  const [, dispatch] = useStateValue();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [rows, setRows] = useState([]);
  const [incidentCount, setIncidentCount] = useState(0);
  const [allCities, setAllCities] = useState(['All']);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const resultLocation = location === 'all' ? 'all' : location;
    API_GET(
      `reports/getSosReport?fromDate=${selectedFromDate}&toDate=${selectedToDate}&limit=${rowsPerPage}&skip=${
        page * rowsPerPage
      }&city=${resultLocation}`
    )
      .then((res) => {
        setRows(res?.data?.incidents);
        setIncidentCount(res?.data?.incidentCount);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, page, rowsPerPage, selectedToDate, selectedFromDate, location]);

  useEffect(() => {
    API_GET(`report-incident/getSosCitiesForCorporate`)
      .then((res) => {
        setAllCities([...allCities, ...res?.data?.cities]);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, []);

  return (
    <Card className={classes.root} sx={theme.tableBorder}>
      <Item>
        <Typography
          sx={{
            textAlign: 'center',
            backgroundColor: theme.palette.primary.tableHeadingColor,
            p: 1,
            color: 'white',
            fontWeight: 'bold',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            fontSize: '19px'
          }}>
          SOS Detail
        </Typography>
      </Item>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {'No Records found'}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {rows && rows !== null
              ? rows?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row?._id}>
                      {columns &&
                        columns.map((column) => {
                          const value = row[column.id] || '-';

                          if (column.id === 'dateTime') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {getDateTime(row?.dateTime) || '-'}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'srno') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.srno}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === 'assistance') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row?.assistance.length > 0
                                  ? row?.assistance
                                      ?.map((item) => {
                                        return ConvertToUpperCase(item);
                                      })
                                      .join(', ')
                                  : '-'}
                              </StyledTableCell>
                            );
                          }

                          if (column.id === 'adminAction') {
                            return (
                              <StyledTableCell key={column.id} align={column.align}>
                                {row.adminAction
                                  ? ConvertToUpperCase(row.adminAction)
                                  : 'End By User'}
                              </StyledTableCell>
                            );
                          }

                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : ConvertToUpperCase(value) || '-'}
                            </StyledTableCell>
                          );
                        })}
                    </TableRow>
                  );
                })
              : ''}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={incidentCount && incidentCount ? incidentCount : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};
export default withStyles(styles)(SosTable);
