const head = [
  {
    id: 'publishedDate',
    label: 'Pub. Date&Time',
    minWidth: 140,
    width: 140,
    maxWidth: 150,
    align: 'left'
  },

  {
    id: 'eventDate',
    label: 'Event Date&Time',
    minWidth: 140,
    width: 140,
    maxWidth: 150,
    align: 'center'
  },
  {
    id: 'ticketNumber',
    label: 'Ticket No',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: 80,
    width: 80,
    maxWidth: 80,
    align: 'center'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'title',
    label: 'Event',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'suspendedBy',
    label: 'Suspended By',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'incidentLocation',
    label: 'Location',
    minWidth: 130,
    width: 130,
    maxWidth: 130,
    align: 'left'
    // format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'suspendedRemark',
    label: 'Remarks',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'left'
  },

  {
    id: 'action',
    label: 'Action',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    align: 'center'
  }
];

export default head;
