import React, { useState } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import theme from '../../../../utils/theme';
import { Typography } from '@mui/material';
import { API_POST, API_HANDLE_ERROR } from '../../../../utils/api';
import { useStateValue } from '../../../../utils/store';

const { withStyles, Grid, Button, Checkbox } = Components;

const { EmailIcon, WhatsAppIcon, TextsmsIcon } = Icons;

const mediumArray = [
  { name: 'Email', value: 'email', Icon: EmailIcon },
  { name: 'WhatsApp', value: 'whatsApp', Icon: WhatsAppIcon },
  { name: 'SMS', value: 'textm', Icon: TextsmsIcon }
];

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

function RemainderModel({ classes, handleClose, remainderIds }) {
  const [, dispatch] = useStateValue();
  const [sendData, setSendData] = useState({
    email: false,
    whatsApp: false,
    textm: false,
    app: false
  });

  const handleStateChange = (key, value) => {
    setSendData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();

    const filteredKeys = [];

    const object = {
      email: sendData.email,
      whatsApp: sendData.whatsApp,
      textm: sendData.textm,
      app: sendData.app
    };

    for (const key in object) {
      if (object[key] === true) {
        filteredKeys.push(key);
      }
    }
    if (filteredKeys.length > 0) {
      remainderIds.length > 0 &&
        API_POST('travelPackage/sendNotifications', {
          ids: remainderIds,
          notificationMedium: filteredKeys
        })
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res?.message ?? 'Success'
            });
            handleClose();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Select at least one medium'
      });
    }
  };

  return (
    <Container
      direction="column"
      wrap="nowrap"
      className={classes.popupContainer}
      sx={{ width: '400px' }}>
      <Item
        className={classes.remainderBell}
        sx={{
          backgroundColor: theme.palette.primary.main
        }}>
        <img
          style={{ height: '70px', width: '70px' }}
          src={require(`../../../../Assets/TravelIcon/bell.png`).default}
          alt="distribution icon"
        />
      </Item>
      <Typography className={classes.remainderText} mt={3}>
        Please choose the Medium for Reminder
      </Typography>
      <form onSubmit={onHandleConfirm}>
        <Item sx={{ p: 1, m: 0.5 }}>
          <Item className={classes.mediumContainer}>
            {mediumArray?.map(({ name, value, Icon }, index) => {
              return (
                <Item key={index}>
                  <div
                    className={classes.medium}
                    style={{
                      border: sendData[`${value}`] ? `2px solid  #227BE5` : '1px solid #251515'
                    }}>
                    <Item ml={5} mt={-3}>
                      <Checkbox
                        size="extraSmall"
                        checked={sendData[`${value}`]}
                        onChange={(e) => handleStateChange(`${value}`, e.target.checked)}
                      />
                    </Item>

                    <Item className={classes.icon1}>
                      <Icon
                        fontSize="small"
                        sx={{
                          mt: -2,
                          color: sendData[`${value}`] ? `#227BE5` : '#251515'
                        }}
                      />
                      <p
                        className={classes.iconLabel1}
                        style={{ color: sendData[`${value}`] ? `#227BE5` : '#251515' }}>
                        {name}
                      </p>
                    </Item>
                  </div>
                </Item>
              );
            })}

            <Item>
              <Item>
                <div
                  className={classes.medium}
                  style={{
                    border: sendData.app ? `2px solid  #227BE5` : '1px solid #251515'
                  }}>
                  <Item ml={5} mt={-3}>
                    <Checkbox
                      size="extraSmall"
                      checked={sendData.app}
                      onChange={(e) => handleStateChange('app', e.target.checked)}
                    />
                  </Item>

                  <Item className={classes.icon1}>
                    <img src="./lythouse.png" alt="lythouse" width="15px" height="20px" />

                    <p
                      className={classes.iconLabel1}
                      style={{
                        color: sendData.app ? `#227BE5` : '#251515'
                      }}>
                      In App
                    </p>
                  </Item>
                </div>
              </Item>
            </Item>
          </Item>
        </Item>

        <Container sx={{ display: 'flex', gap: '20px', p: 1 }}>
          <Item sx={{ display: 'inline-flex', ml: 'auto' }}>
            <Button style={theme.button.cancel} onClick={() => handleClose()}>
              Cancel
            </Button>
          </Item>
          <Item>
            <Button
              type="submit"
              style={{
                ...theme.button.submit,
                backgroundColor: theme.palette.primary.main
              }}>
              send
            </Button>
          </Item>
        </Container>
      </form>
    </Container>
  );
}

export default withStyles(styles)(RemainderModel);
