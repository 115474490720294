import { Components } from '../../../../utils/material-ui';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

const { TableCell } = Components;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.sosDashboard,
    color: theme.palette.common.white,
    padding: theme.spacing(1)
    // fontFamily: theme.palette.font.fontFamily
    // borderRadius: '10px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: theme.spacing(1.5),
    fontWeight: theme.palette.font.fontWeight
    // fontFamily: theme.palette.font.fontFamily
  }
}));

export default StyledTableCell;
