import { Typography } from '@mui/material';
import React from 'react';
import { Components } from '../../../../utils/material-ui';
import styles from './styles';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import theme from '../../../../utils/theme';

const { withStyles, Grid } = Components;

const Item = (props) => <Grid item {...props} />;

const Confirmation = ({ classes, userData, handleDependent }) => {
  const handleMore = () => {
    handleDependent();
  };
  return (
    <Item
      md={12}
      sx={{
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto'
      }}>
      <Item
        md={9}
        sx={{
          height: '270px',
          backgroundColor: theme.palette.primary.main,
          boxShadow: '0px 0px 4.92391px 1.40683px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px'
        }}>
        <Item
          sx={{
            height: '120px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <img
            style={{ height: '80px', width: '80px' }}
            src={require(`../../../../Assets/logo/verify.png`).default}
            alt="verify"
          />
        </Item>
        <Item align="center" sx={{ color: '#FFFFFF' }}>
          <Typography sx={{ textAlign: 'center', fontSize: '22px', fontWeight: '600' }}>
            Thanks ! You are Registered with us
          </Typography>
          <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '600' }} mt={1}>
            {`${ConvertToUpperCase(userData?.name)} , We are excited to have you .`}
          </Typography>
          <Typography
            mt={1.5}
            onClick={handleMore}
            sx={{
              textAlign: 'center',
              fontSize: '14px',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>
            click here to add family members
          </Typography>
        </Item>
      </Item>
    </Item>
  );
};

export default withStyles(styles)(Confirmation);
