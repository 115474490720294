import React, { useState, useEffect } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import { useStateValue } from '../../../../utils/store';
import { API_GET } from '../../../../utils/api';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import theme from '../../../../utils/theme';

import styles from './styles';

const {
  withStyles,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} = Components;
const { CancelIcon } = Icons;

const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

// const arrayType = [
//   { mobile: '9890417560', name: 'Jayesh' },
//   { mobile: '9890417560', name: 'Roahan' },
//   { mobile: '9890417560', name: 'Mubashir' },
//   { mobile: '9890417560', name: 'All' },
//   { mobile: '9890417560', name: 'All' },
//   { mobile: '9890417560', name: 'All' },
//   { mobile: '9890417560', name: 'All' },
//   { mobile: '9890417560', name: 'All' },
//   { mobile: '9890417560', name: 'All' }
// ];

function EmergencyContacts({ classes, handleClose, emergencyContactId, sosId }) {
  const [store, dispatch] = useStateValue();
  const [data, setData] = useState([]);

  const getallInvitedEmployee = () => {
    const resultId = sosId !== undefined ? `userId=${sosId}` : `sosId=${emergencyContactId}`;
    API_GET(`emergencyContact/getEmergencyContactById?${resultId}`)
      .then((res) => {
        setData(res?.data?.emergencyContact);
      })
      .catch((err) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: err[0]?.message ?? 'Oops!!, Please try again'
        });
      });
  };

  useEffect(() => {
    getallInvitedEmployee();
  }, [dispatch]);

  return (
    <Container direction="column" className={classes.newUser}>
      <Item
        style={
          store.role === 'sosAdmin'
            ? {
                backgroundColor: theme.palette.primary.sosDashboard,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '5px 10px'
              }
            : {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '5px 10px'
              }
        }
        className={classes.cardHeading}>
        <Item
          style={
            store.role === 'sosAdmin'
              ? {
                  padding: '7px 20px',
                  borderRadius: '5px 5px 0 0',
                  color: 'white',
                  backgroudColor: theme.palette.primary.sosDashboard
                }
              : {}
          }>
          <Typography variant="h6">{`Emergency Contacts`}</Typography>
        </Item>
        <Item>
          <CancelIcon onClick={() => handleClose()} style={{ color: '#FFFFFF' }} />
        </Item>
      </Item>
      <Item>
        <Container style={{ maxHeight: '60vh', overflow: 'auto' }}>
          <TableContainer>
            <Table className={classes.table} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Sr No</TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Name
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Number
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {ConvertToUpperCase(row?.fullName)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {row?.mobileNumber}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Item>
    </Container>
  );
}

export default withStyles(styles)(EmergencyContacts);
