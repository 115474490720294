import React, { useState } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import { API_PATCH } from '../../utils/api';
import styles from './styles';
// import theme from '../../utils/theme';
import { useNavigate } from 'react-router-dom';
import { getDateTime } from '../../utils/moment';

const { withStyles, Grid, Typography } = Components;
const {
  KeyboardArrowUpIcon,
  ExpandMoreIcon
  // CloseIcon
} = Icons;

const Item = (props) => <Grid item {...props} />;

const ListItem = ({ classes, item, index, handleNotification, handleRemove }) => {
  const [isExpand, setIsExpand] = useState(false);
  const navigate = useNavigate();

  const handleRedirect = () => {
    const resultRedirect = item?.redirect.split('/');
    navigate('/' + resultRedirect[3]);
    API_PATCH(`notification/markNotificationAsRead?notificationId=${item?._id}`);
    handleNotification();
  };
  return (
    <Item
      className={classes.subContainer}
      key={index}
      mt={1}
      sx={{
        backgroundColor: '#FFFFFF',
        height: isExpand ? 'auto' : '70px'
      }}>
      <Item className={classes.img}>
        <img
          src={item?.image || 'https://i.ibb.co/VLCfQGx/Group-5894448.png'}
          alt="alert"
          width="30px"
          height="30px"
        />
      </Item>
      <Item className={classes.titleContainer} sx={{ p: 0.5 }} onClick={handleRedirect}>
        <Typography
          className={classes.title}
          style={{
            fontSize: '15px',
            fontWeight: item?.read === true ? '400' : '600',
            color: item?.read === true ? '#696969' : 'black'
          }}>
          {isExpand ? item?.title : `${item?.title.substring(0, 35)}....`}
        </Typography>
        <Typography
          className={classes.title}
          style={{ fontSize: '12px', color: item?.read === true ? '#696969' : 'black' }}>
          {isExpand ? item?.body.substring(0, 300) : item?.body.substring(0, 40)}
        </Typography>
        <Typography className={classes.title} sx={{ color: '#A5ACB8', fontSize: '12px' }}>
          {`Last ${getDateTime(item?.createdAt)}`}
        </Typography>
      </Item>
      <Item className={classes.dropdown}>
        {isExpand ? (
          <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} onClick={() => setIsExpand(false)} />
        ) : (
          <ExpandMoreIcon sx={{ cursor: 'pointer' }} onClick={() => setIsExpand(true)} />
        )}
      </Item>
      <Item className={classes.action}>
        {/* <CloseIcon
          sx={{ color: theme.palette.primary.tableHeadingColor }}
          onClick={() => handleRemove(item)}
        /> */}
      </Item>
    </Item>
  );
};

export default withStyles(styles)(ListItem);
